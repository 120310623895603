import { SvgIconProps } from '../Icon';

const QuestionIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...otherProps}>
    <g opacity={0.5}>
      <path
        d="M8 15.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.003 13a1 1 0 100-2 1 1 0 000 2z" fill={color} />
      <path
        d="M6.5 3.577c.953-.86 3.018-.845 3.423.635C10.453 6.134 8 6.142 8 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default QuestionIcon;
