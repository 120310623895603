/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddPlaidTokenToConnectInvoiceResult": [
      "AddPlaidTokenToConnectInvoiceFailure",
      "AddPlaidTokenToConnectInvoiceSuccess"
    ],
    "AllInOneRequestFormResult": [
      "AllInOneRequestFormFailure",
      "AllInOneRequestFormSuccess"
    ],
    "ArchiveHomeownerResult": [
      "ArchiveHomeownerFailure",
      "ArchiveHomeownerSuccess"
    ],
    "AttachExternalAccountResult": [
      "AttachExternalAccountFailure",
      "AttachExternalAccountSuccess"
    ],
    "AttachPlaidBankAccountToConnectAccountResult": [
      "AttachPlaidBankAccountToConnectAccountFailure",
      "AttachPlaidBankAccountToConnectAccountSuccess"
    ],
    "CancelInvoiceResult": [
      "CancelInvoiceFailure",
      "CancelInvoiceSuccess"
    ],
    "ClaimHearthRewardsGiftcardResult": [
      "ClaimHearthRewardsGiftcardFailure",
      "ClaimHearthRewardsGiftcardSuccess"
    ],
    "ClickCtaResult": [
      "ClickCtaFailure",
      "ClickCtaSuccess"
    ],
    "ConnectInvoiceRequestOnlinePaymentsResult": [
      "ConnectInvoiceRequestOnlinePaymentsFailure",
      "ConnectInvoiceRequestOnlinePaymentsSuccess"
    ],
    "ConnectWistiaResult": [
      "ConnectWistiaFailure",
      "ConnectWistiaSuccess"
    ],
    "ContractServiceAccountCreateResult": [
      "ContractServiceAccountCreateFailure",
      "ContractServiceAccountCreateSuccess"
    ],
    "ContractServiceCancelContractResult": [
      "ContractServiceCancelContractFailure",
      "ContractServiceCancelContractSuccess"
    ],
    "ContractServiceCreateContractResult": [
      "ContractServiceCreateContractFailure",
      "ContractServiceCreateContractSuccess"
    ],
    "ContractServiceMarkDefaultTemplateResult": [
      "ContractServiceMarkDefaultTemplateFailure",
      "ContractServiceMarkDefaultTemplateSuccess"
    ],
    "ContractServiceResendProjectContractResult": [
      "ContractServiceResendProjectContractFailure",
      "ContractServiceResendProjectContractSuccess"
    ],
    "ContractServiceSignTermsResult": [
      "ContractServiceSignTermsFailure",
      "ContractServiceSignTermsSuccess"
    ],
    "ContractServiceSigningLinkFetchResult": [
      "ContractServiceSigningLinkFetchFailure",
      "ContractServiceSigningLinkFetchSuccess"
    ],
    "ContractServiceTemplateDeleteByNameResult": [
      "ContractServiceTemplateDeleteByNameFailure",
      "ContractServiceTemplateDeleteByNameSuccess"
    ],
    "ContractServiceTemplateDeleteResult": [
      "ContractServiceTemplateDeleteFailure",
      "ContractServiceTemplateDeleteSuccess"
    ],
    "ContractServiceTemplateUpsertResult": [
      "ContractServiceTemplateUpsertFailure",
      "ContractServiceTemplateUpsertSuccess"
    ],
    "ContractServiceTemplateVersionUpsertResult": [
      "ContractServiceTemplateVersionUpsertFailure",
      "ContractServiceTemplateVersionUpsertSuccess"
    ],
    "CreateConnectInvoiceResult": [
      "CreateConnectInvoiceFailure",
      "CreateConnectInvoiceSuccess"
    ],
    "CreateConnectPayoutResult": [
      "CreateConnectPayoutFailure",
      "CreateConnectPayoutSuccess"
    ],
    "CreateHomeownerAndSendFinancingInfoResult": [
      "CreateHomeownerAndSendFinancingInfoFailure",
      "CreateHomeownerAndSendFinancingInfoSuccess"
    ],
    "CreateInsightsFeedbackResult": [
      "CreateInsightsFeedbackFailure",
      "CreateInsightsFeedbackSuccess"
    ],
    "CreateLoanInquiryFromExistingResult": [
      "CreateLoanInquiryFromExistingFailure",
      "CreateLoanInquiryFromExistingSuccess"
    ],
    "CreateLoginLinkResult": [
      "CreateLoginLinkFailure",
      "CreateLoginLinkSuccess"
    ],
    "DeactivateContractorResult": [
      "DeactivateContractorFailure",
      "DeactivateContractorSuccess"
    ],
    "DeletePersonResult": [
      "DeletePersonFailure",
      "DeletePersonSuccess"
    ],
    "Error": [
      "InternalError",
      "ValidationError"
    ],
    "ExportPaymentDataResult": [
      "ExportPaymentDataFailure",
      "ExportPaymentDataSuccess"
    ],
    "GenerateApiTokenResult": [
      "GenerateApiTokenFailure",
      "GenerateApiTokenSuccess"
    ],
    "GetStripeAccountLinkResult": [
      "GetStripeAccountLinkFailure",
      "GetStripeAccountLinkSuccess"
    ],
    "HomeownerResendOffersEmailResult": [
      "HomeownerResendOffersEmailFailure",
      "HomeownerResendOffersEmailSuccess"
    ],
    "ManuallyPayInvoiceResult": [
      "ManuallyPayInvoiceFailure",
      "ManuallyPayInvoiceSuccess"
    ],
    "MarkConnectInvoiceFirstViewedAtResult": [
      "MarkConnectInvoiceFirstViewedAtFailure",
      "MarkConnectInvoiceFirstViewedAtSuccess"
    ],
    "MarkHomeownerFinancingLinkSentResult": [
      "MarkHomeownerFinancingLinkSentFailure",
      "MarkHomeownerFinancingLinkSentSuccess"
    ],
    "MarkReadAllNotificationsResult": [
      "MarkReadAllNotificationsFailure",
      "MarkReadAllNotificationsSuccess"
    ],
    "ModifyPaymentsRoleResult": [
      "ModifyPaymentsRoleFailure",
      "ModifyPaymentsRoleSuccess"
    ],
    "PayConnectInvoiceResult": [
      "PayConnectInvoiceFailure",
      "PayConnectInvoiceSuccess"
    ],
    "PurchasePaymentsAddOnResult": [
      "PurchasePaymentsAddOnFailure",
      "PurchasePaymentsAddOnSuccess"
    ],
    "QuoteAccountCreateResult": [
      "QuoteAccountCreateFailure",
      "QuoteAccountCreateSuccess"
    ],
    "QuoteAssociateContractResult": [
      "QuoteAssociateContractFailure",
      "QuoteAssociateContractSuccess"
    ],
    "QuoteCancelResult": [
      "QuoteCancelFailure",
      "QuoteCancelSuccess"
    ],
    "QuoteCreateResult": [
      "QuoteCreateFailure",
      "QuoteCreateSuccess"
    ],
    "QuoteHomeownerAcceptResult": [
      "QuoteHomeownerAcceptFailure",
      "QuoteHomeownerAcceptSuccess"
    ],
    "QuoteHomeownerDeclineResult": [
      "QuoteHomeownerDeclineFailure",
      "QuoteHomeownerDeclineSuccess"
    ],
    "QuoteHomeownerRequestChangesResult": [
      "QuoteHomeownerRequestChangesFailure",
      "QuoteHomeownerRequestChangesSuccess"
    ],
    "QuoteResendResult": [
      "QuoteResendFailure",
      "QuoteResendSuccess"
    ],
    "QuoteTemplateCreateResult": [
      "QuoteTemplateCreateFailure",
      "QuoteTemplateCreateSuccess"
    ],
    "QuoteTemplateDestroyResult": [
      "QuoteTemplateDestroyFailure",
      "QuoteTemplateDestroySuccess"
    ],
    "QuoteTemplateUpdateResult": [
      "QuoteTemplateUpdateFailure",
      "QuoteTemplateUpdateSuccess"
    ],
    "QuoteUploadCreateResult": [
      "QuoteUploadCreateFailure",
      "QuoteUploadCreateSuccess"
    ],
    "QuoteUploadDocumentResult": [
      "QuoteUploadDocumentFailure",
      "QuoteUploadDocumentSuccess"
    ],
    "QuoteUploadPhotoResult": [
      "QuoteUploadPhotoFailure",
      "QuoteUploadPhotoSuccess"
    ],
    "QuoteViewedResult": [
      "QuoteViewedFailure",
      "QuoteViewedSuccess"
    ],
    "RecordHearthOnboardingPersonalizeDashboardResponseResult": [
      "RecordHearthOnboardingPersonalizeDashboardResponseFailure",
      "RecordHearthOnboardingPersonalizeDashboardResponseSuccess"
    ],
    "RecordQuickPollResponseResult": [
      "RecordQuickPollResponseFailure",
      "RecordQuickPollResponseSuccess"
    ],
    "RefundConnectInvoiceResult": [
      "RefundConnectInvoiceFailure",
      "RefundConnectInvoiceSuccess"
    ],
    "RemovePlaidTokenFromConnectInvoiceResult": [
      "RemovePlaidTokenFromConnectInvoiceFailure",
      "RemovePlaidTokenFromConnectInvoiceSuccess"
    ],
    "ResendMemberInviteEmailResult": [
      "ResendMemberInviteEmailFailure",
      "ResendMemberInviteEmailSuccess"
    ],
    "ScheduleWebinarResult": [
      "ScheduleWebinarFailure",
      "ScheduleWebinarSuccess"
    ],
    "SendDefaultFinancingInfoResult": [
      "SendDefaultFinancingInfoFailure",
      "SendDefaultFinancingInfoSuccess"
    ],
    "SendEmailLeadResult": [
      "SendEmailLeadFailure",
      "SendEmailLeadSuccess"
    ],
    "SendHearthAppDownloadLinkResult": [
      "SendHearthAppDownloadLinkFailure",
      "SendHearthAppDownloadLinkSuccess"
    ],
    "SendInviteAndScheduleWebinarResult": [
      "SendInviteAndScheduleWebinarFailure",
      "SendInviteAndScheduleWebinarSuccess"
    ],
    "SendMemberInvitesResult": [
      "SendMemberInvitesFailure",
      "SendMemberInvitesSuccess"
    ],
    "SendResetPasswordLinkResult": [
      "SendResetPasswordLinkFailure",
      "SendResetPasswordLinkSuccess"
    ],
    "SendSamplePaymentRequestResult": [
      "SendSamplePaymentRequestFailure",
      "SendSamplePaymentRequestSuccess"
    ],
    "SignAccountContractResult": [
      "SignAccountContractFailure",
      "SignAccountContractSuccess"
    ],
    "SubmitApplicationResult": [
      "SubmitApplicationFailure",
      "SubmitApplicationSuccess"
    ],
    "SubmitBusinessInfoResult": [
      "SubmitBusinessInfoFailure",
      "SubmitBusinessInfoSuccess"
    ],
    "SubmitPersonInfoResult": [
      "SubmitPersonInfoFailure",
      "SubmitPersonInfoSuccess"
    ],
    "SubmitVerificationDocumentsResult": [
      "SubmitVerificationDocumentsFailure",
      "SubmitVerificationDocumentsSuccess"
    ],
    "SuggestedActionClickResult": [
      "SuggestedActionClickFailure",
      "SuggestedActionClickSuccess"
    ],
    "SuggestedActionDismissResult": [
      "SuggestedActionDismissFailure",
      "SuggestedActionDismissSuccess"
    ],
    "SwitchEnterpriseOrgResult": [
      "SwitchEnterpriseOrgFailure",
      "SwitchEnterpriseOrgSuccess"
    ],
    "TransferDestination": [
      "StripeBankAccount",
      "StripeCard"
    ],
    "UpdateAcceptedPaymentsResult": [
      "UpdateAcceptedPaymentsFailure",
      "UpdateAcceptedPaymentsSuccess"
    ],
    "UpdateConnectInvoicePaymentsCapabilityStatusResult": [
      "UpdateConnectInvoicePaymentsCapabilityStatusFailure",
      "UpdateConnectInvoicePaymentsCapabilityStatusSuccess"
    ],
    "UpdateHackathonHomeownerLeadResult": [
      "UpdateHackathonHomeownerLeadFailure",
      "UpdateHackathonHomeownerLeadSuccess"
    ],
    "UpdateHomeownerResult": [
      "UpdateHomeownerFailure",
      "UpdateHomeownerSuccess"
    ],
    "UpdateQuickbooksSyncSettingsResult": [
      "UpdateQuickbooksSyncSettingsFailure",
      "UpdateQuickbooksSyncSettingsSuccess"
    ],
    "UpdateReminderSettingResult": [
      "UpdateReminderSettingFailure",
      "UpdateReminderSettingSuccess"
    ],
    "UpdateStripeConnectAccountResult": [
      "UpdateStripeConnectAccountFailure",
      "UpdateStripeConnectAccountSuccess"
    ],
    "UpsertArticleInteractionResult": [
      "UpsertArticleInteractionFailure",
      "UpsertArticleInteractionSuccess"
    ],
    "UpsertContractorResult": [
      "UpsertContractorFailure",
      "UpsertContractorSuccess"
    ],
    "UpsertCustomizedInvoiceTemplateResult": [
      "UpsertCustomizedInvoiceTemplateFailure",
      "UpsertCustomizedInvoiceTemplateSuccess"
    ],
    "UpsertHomeownerFinancingFeedbackResult": [
      "UpsertHomeownerFinancingFeedbackFailure",
      "UpsertHomeownerFinancingFeedbackSuccess"
    ],
    "UpsertHomeownerPaymentsFeedbackResult": [
      "UpsertHomeownerPaymentsFeedbackFailure",
      "UpsertHomeownerPaymentsFeedbackSuccess"
    ],
    "UpsertHomeownerResult": [
      "UpsertHomeownerFailure",
      "UpsertHomeownerSuccess"
    ],
    "UpsertNotificationResult": [
      "UpsertNotificationFailure",
      "UpsertNotificationSuccess"
    ],
    "UpsertOrganizationResult": [
      "UpsertOrganizationFailure",
      "UpsertOrganizationSuccess"
    ],
    "UpsertTemplateResult": [
      "UpsertTemplateFailure",
      "UpsertTemplateSuccess"
    ]
  }
};
      export default result;
    