import {
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';

export function isServerError(error: ErrorResponse['networkError']): error is ServerError {
  return (error as ServerError).statusCode !== undefined;
}

export function isServerParseError(error: ErrorResponse['networkError']): error is ServerParseError {
  return (error as ServerParseError).bodyText !== undefined;
}
