import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Button, Link } from 'common/components/material-ui';
import { ToggleRow } from 'common/components/widgets';
import { useUrl } from 'common/hooks';
import { useSettingsActions, useSettings } from './useSettings';

const ProjectIntentContract = (): JSX.Element | null => {
  const { marketingUrl } = useUrl();
  const { organization } = useSettings();
  const { editOrganizationSettings } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'project_intent_contract' });
  });

  if (!organization) return null;

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Project Intent Agreement
      </Typography>
      <Box mt={3} px={3} py={2} borderRadius="8px" bgcolor="common.basic300">
        <ToggleRow
          prompt="Active"
          name="requireContract"
          value={organization.requireContract}
          showLabel={false}
          onChange={() => {
            itly.changeSetting({ fields: ['require_contract'] });
            editOrganizationSettings({ requireContract: !organization.requireContract });
          }}
        />
      </Box>
      <Box my={3} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          buttonType="common.primary"
          type="submit"
          href="https://s3-us-west-2.amazonaws.com/hearth-documents/%5BTemplate%5D+Project+Intent+Contract.doc"
        >
          Preview Sample Agreement
        </Button>
      </Box>
      <Typography variant="caption">
        Hearth has provided this form for general information purposes as a
        courtesy to our contractor partners. It is not protected by attorney-client
        privilege or as attorney work product. Hearth is not a law firm or a
        substitute for an attorney. Hearth cannot provide any kind of advice,
        explanation, opinion, or recommendation about possible legal rights or
        remedies. You should consult with an attorney to ensure that this agreement
        is appropriate for your use and complies with applicable law. Your use of
        the form is subject to Hearth’s Terms of Use available at{' '}
        <Link
          style={{ textDecoration: 'underline', color: 'common.primary500' }}
          variant="caption"
          target="_blank"
          rel="noopener noreferrer"
          href={marketingUrl('/terms')}
        >
          {marketingUrl('/terms')}
        </Link>
      </Typography>
    </Box>
  );
};

export default ProjectIntentContract;
