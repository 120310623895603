import { useMemo } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import { CreateOutlined, GetApp, SendOutlined } from '@mui/icons-material';

import { useEditContractAdminCheckQuery } from 'types';
import { useLogEvent } from 'common/hooks';
import { Button } from 'common/components/material-ui';

const useStyles = makeStyles(theme => ({
  contractButton: {
    backgroundColor: theme.palette.common.purple500,
    color: theme.palette.common.basic100,
    '&:hover, &:active': {
      color: theme.palette.common.basic100,
      backgroundColor: theme.palette.common.purple700,
      border: 0,
      textDecoration: 'none',
    },
    '&:link, &:visited': {
      color: theme.palette.common.basic100,
      backgroundColor: theme.palette.common.purple500,
      border: 0,
      textDecoration: 'none',
    },
  },
}));

type Props = {
  base64PDF: string;
}

const ContractTemplateBanner = ({ base64PDF }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { data } = useEditContractAdminCheckQuery();
  const itly = useLogEvent();

  const lastUpdatedCopy = useMemo(() => {
    const updatedAt = data?.contractServiceContractTemplate?.updatedAt;
    if (!updatedAt) return null;

    const days = differenceInDays(Date.now(), Date.parse(updatedAt));
    if (days === 0) {
      return 'Today';
    }
    if (days === 1) {
      return 'Yesterday';
    }
    return `${days} days ago`;
  }, [data]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="common.purple100"
      color="common.purple500"
      py={2}
      px={3}
      borderRadius="8px"
    >
      <Typography variant="h3">
        This is your contract template
      </Typography>
      <Box mt={1.5} mb={2}>
        <Typography variant="body2">
          You will add customer information when you send the  contract. Any edits to this template
          will be universal. You can now send a custom contract to a customer from here or the {' '}
          <Link
            component={RouterLink}
            to="/dashboard"
          >
            dashboard.
          </Link>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {lastUpdatedCopy &&
          <Typography variant="subtitle2">
            Updated: {lastUpdatedCopy}
          </Typography>
        }
        <Box
          display="flex"
          alignItems="center"
        >
          {data?.contractor?.admin &&
            <Box mr={1}>
              <Button
                className={classes.contractButton}
                component={RouterLink}
                to="/dashboard/tools/contract-template/edit"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <CreateOutlined />
                  <Box mr={1} />
                  <Typography variant="subtitle2">
                    Edit your template
                  </Typography>
                </Box>
              </Button>
            </Box>
          }
          <Box mr={1}>
            <Button
              component="a"
              className={classes.contractButton}
              href={'data:application/octet-stream;base64,' + base64PDF}
              download="contract-template.pdf"
              onClick={() => itly.downloadContractTemplate()}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <GetApp />
                <Box mr={1} />
                <Typography variant="subtitle2">
                  Download a PDF
                </Typography>
              </Box>
            </Button>
          </Box>
          <Button
            className={classes.contractButton}
            onClick={() => {
              history.push('/dashboard/request/send-contract?source=tools', {
                background: location,
              });
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <SendOutlined />
              <Box mr={1} />
              <Typography variant="subtitle2">
                Send a contract
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ContractTemplateBanner.EDIT_CONTRACT_ADMIN_CHECK = gql`
  query EditContractAdminCheck {
    contractor {
      id
      admin
    }
    contractServiceContractTemplate(type:project_contract) {
      id
      updatedAt
    }
  }
`;

export default ContractTemplateBanner;
