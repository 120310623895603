import { DISPLAY_HOST, MARKETING_HOST } from 'common/utils/host';
import { Contractor, Organization } from 'contractor/types';

export default function contractorUrl(
  contractor: Contractor,
  initialPath = 'partners',
): { baseUrl: string; baseDisplayUrl: string; path: string } {
  const path = `/${initialPath}/${contractor.urlPath}`;

  return { baseUrl: MARKETING_HOST, baseDisplayUrl: DISPLAY_HOST, path };
}

export type CalendlyObject = {
  url: string;
  prefill: {
    name: string;
    email: string;
  };
  utm: {
    utmCampaign: number;
    utmSource: number;
    utmMedium: string;
  };
}

export function getCalendlyObj(
  contractor: Contractor,
  eventLink: string,
): CalendlyObject {
  const {
    firstName,
    lastName,
    email,
    id,
    organizationId,
  } = contractor;
  const fullName = `${firstName} ${lastName}`;

  return {
    url: `https://calendly.com/${eventLink}`,
    prefill: {
      name: fullName,
      email,
    },
    utm: {
      utmCampaign: id,
      utmSource: organizationId,
      utmMedium: 'dashboard',
    },
  };
}

export function openCalendly(contractor: Contractor, organization: Organization): void {
  const calendlyObj = getCalendlyObj(contractor, organization.trainingCallLink);
  window.Calendly.initPopupWidget(calendlyObj);
}
