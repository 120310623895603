import { forwardRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import { Typography, Button as MuiButton } from 'common/components/material-ui';

const Button = styled(MuiButton)({
  '& .MuiPaper-root': {
    '&.MuiButtonBase-root': {
      boxShadow: 'none',
      padding: '4px 20px',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
    },
  },
});

type ButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SendNewButton = forwardRef<HTMLButtonElement, ButtonProps>((
  { onClick },
  ref,
): JSX.Element => (
  <Button
    onClick={onClick}
    buttonType="common.primary"
    variant="contained"
    aria-controls="menu"
    aria-haspopup="true"
    size="small"
    ref={ref}
  >
    <AddIcon
      fontSize="large"
      sx={{
        color: 'common.basic100',
        mr: 1,
        mb: 0.25,
      }}
    />
    <Typography variant="p1" color="basic100">
      Send New
    </Typography>
  </Button>
));

export default SendNewButton;
