import { useState } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { useLazyAction } from 'promise-action';

import { sign } from 'contractor/actions/contract';
import { SignedContract } from 'types';
import { Smidge } from 'common/components/layouts';
import { Button, Link } from 'common/components/material-ui';
import constants from 'common/utils/constants';
import { refreshData } from 'contractor/actions/contractor';

type Props = {
  open: boolean;
  title?: React.ReactNode;
  body?: React.ReactNode;
  contract: SignedContract;
};

const useStyles = makeStyles({
  contractContainer: {
    width: '100%',
    border: 'none',
    height: '40vh',
  },
});

const ContractPopup = ({
  contract,
  open,
  title,
  body,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const signContract = useLazyAction(sign);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submit = async (): Promise<void> => {
    try {
      setLoading(true);
      await signContract.run({ contractId: contract.id });
      await dispatch(refreshData());
    } catch {
      setError('An error occurred, please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <Box m={5} display="flex" flexDirection="column">
        {title &&
          <Typography variant="h3" gutterBottom>{title}</Typography>
        }
        {body &&
          <Typography variant="body2" gutterBottom>{body}</Typography>
        }
        <Smidge />
        <iframe
          title="contract"
          className={classes.contractContainer}
          srcDoc={contract ? contract.html : undefined}
        />
        <Smidge x={2} />
        {error &&
          <>
            <Box color="common.danger500">
              <Typography variant="body2">
                {error}
              </Typography>
            </Box>
            <Smidge />
          </>}
        <Button
          variant="contained"
          buttonType="primary"
          onClick={submit}
          loading={loading}
        >
          Agree and continue
        </Button>
        <Smidge x={2} />
        <Typography variant="body2" align="center">
          Questions? Give us a call{' '}
          <Box display="inline" color="common.primary500">
            <Link
              variant="body2"
              color="inherit"
              href={`tel:${constants.supportNumber}`}
            >
              {constants.supportNumberDisplay}
            </Link>
          </Box>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default ContractPopup;
