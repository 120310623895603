import { Portal } from '@mui/material';

import { useRefs } from '../useContractWizard';

type Props = {
  children: React.ReactNode;
}

const WizardContent = ({ children }: Props): JSX.Element => {
  const { contentRef } = useRefs();

  return (
    <Portal container={contentRef}>
      {children}
    </Portal>
  );
};

export default WizardContent;
