import { Box, Typography, Card } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    '&:hover': {
      color: theme.palette.common.basic100,
      backgroundColor: theme.palette.common.primary100,
    },
  },
  button: {
    marginTop: 16,
    borderRadius: 8,
    position: 'static',
    border: `1px solid ${theme.palette.common.primary500}`,
    color: theme.palette.common.primary500,
    padding: '12px 16px',
    '&:hover': {
      color: theme.palette.common.basic100,
      backgroundColor: theme.palette.common.primary500,
    },
  },
}));

type Props = {
  title: React.ReactNode;
  description?: string;
  buttonTitle: string;
  onClick: () => void;
  backgroundImg: string;
  imageProps?: Partial<React.ImgHTMLAttributes<HTMLImageElement>>;
}

const SmallCard = ({
  title,
  description,
  buttonTitle,
  onClick,
  backgroundImg,
  imageProps,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <Box display="flex">
        <Box flex={3} pl={3} py={2} display="flex" flexDirection="column">
          <Typography variant="h3" color="textPrimary">{title}</Typography>
          <Box mt={2}>
            <Typography variant="body2" color="textPrimary">{description}</Typography>
          </Box>
          <Button variant="outlined" onClick={onClick} className={classes.button}>
            <Typography variant="body1" color="inherit">{buttonTitle}</Typography>
            <Box display="flex" alignItems="center" ml={1}>
              <ArrowForwardIcon />
            </Box>
          </Button>
        </Box>
        <Box flex={2} display="flex" alignItems="center">
          <img
            alt=""
            src={backgroundImg}
            {...imageProps}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SmallCard;
