import { DeepReadonly } from 'utility-types';
import { camelizeKeys } from 'humps';
import { ParsedQuery } from 'query-string';

import { Actions } from 'common/actions';
import { decodeToken } from 'common/utils/oauth';

const READ_QUERY = 'UserState/READ_QUERY';
const REGISTER_SESSION = 'UserState/REGISTER_SESSION';

export type UserState = DeepReadonly<{
  hearthUid: string;
  sessionId: string;
  anonymousId: string;
  deviceId?: string | null;
  isLoaded: boolean;
  sake: boolean;
  sandbox: boolean;
  isCsUser?: boolean | null;
  abTest?: {
    tests: AbTest[];
    variants: AbTestVariant[];
    randomSeed: number;
  };
}>

// Initial state
const initialState: UserState = {
  hearthUid: '',
  sessionId: '',
  anonymousId: '',
  deviceId: null,
  isLoaded: false,
  sake: false,
  sandbox: false,
};

type Query = {
  hearthUid?: string;
  deviceId?: string;
  sake?: string;
};

type ReadInQueryParams = {
  type: typeof READ_QUERY;
  payload: Query;
}

type RegisterSessionParams = {
  type: typeof REGISTER_SESSION;
  payload: {
    sessionId: string;
    anonymousId: string;
  };
};

export function readInQueryParams(rawQuery: ParsedQuery): ReadInQueryParams {
  const query: Query = camelizeKeys(rawQuery);

  return {
    type: READ_QUERY,
    payload: query,
  };
}

type UserStateActionTypes =
  ReadInQueryParams |
  RegisterSessionParams |
  Actions;

// Reducer
export default function UserStateReducer(
  state = initialState,
  action: UserStateActionTypes,
): UserState {
  switch (action.type) {
  case READ_QUERY:
    return {
      ...state,
      sake: action.payload.sake != null ? action.payload.sake === 'true' : state.sake,
      hearthUid: action.payload.hearthUid || state.hearthUid,
      deviceId: action.payload.deviceId || state.deviceId,
    };
  case 'Session/FETCH_USER_SESSION_SUCCESS':
    return {
      ...state,
      ...action.payload,
      isLoaded: true,
    };
  case 'Contractor/FETCH_SUCCESS':
    return {
      ...state,
      hearthUid: action.payload.hearthUid,
      deviceId: action.payload.currentDeviceId,
    };
  case 'Organization/FETCH_SUCCESS':
    return {
      ...state,
      sandbox: action.payload.sandboxOrg,
    };
  case 'Auth/REFRESH_TOKEN_SUCCESS':
  case 'Auth/LOGIN_SUCCESS': {
    const token = decodeToken(action.payload.accessToken);
    return {
      ...state,
      isCsUser: token.user.isCsUser || false,
    };
  }
  case 'Auth/LOGOUT_PENDING':
    return {
      ...state,
      sandbox: false,
    };

  case REGISTER_SESSION:
    return {
      ...state,
      sessionId: action.payload.sessionId,
      anonymousId: action.payload.anonymousId,
    };

  default:
    return state;
  }
}
