import { Box, Typography } from '@mui/material';

import { RadioOptionComponentProps } from 'common/components/elements/RadioOption';

const RadioOptionContainer = ({ control, label }: RadioOptionComponentProps): JSX.Element => (
  <Box
    display="flex"
    bgcolor="common.basic100"
    borderRadius="8px"
    mt={2}
    px={{ xs: 1, md: 2 }}
    py={{ xs: 1, md: 3 }}
    alignItems="center"
  >
    <Box mr={2}>
      {control}
    </Box>
    <Typography variant="h4">{label}</Typography>
  </Box>
);

export default RadioOptionContainer;
