import { useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import constate from 'constate';
import { isFilled } from 'ts-is-present';

import { useQueryParams } from 'common/hooks';
import { useGetWorkflowsDataQuery } from 'types';
import { FinancingWorkflowState } from './Financing/useFinancing';
import { ClientIndexState } from '../Clients/useClients';

type QueryParams = {
  activeTab?: string;
  source?: string;
  view?: 'my_clients' | 'all_clients';
};

export type ViewModeState = {
  viewMode: ViewMode;
}

type ViewModeActions = {
  type: ViewModeAction.SET_VIEW_MODE,
  data: ViewMode,
}

export enum ViewMode {
  INDIVIDUAL,
  TEAM,
}

export enum ViewModeAction {
  SET_VIEW_MODE
}

export type FilterState = {
  filterState: (FinancingWorkflowState & ViewModeState) | ClientIndexState;
  background: string;
};

const reducer = (
  state: ViewModeState,
  action: ViewModeActions,
): ViewModeState => {
  switch (action.type) {
  case ViewModeAction.SET_VIEW_MODE:
    return {
      ...state,
      viewMode: action.data,
    };
  default: return state;
  }
};

export const GET_WORKFLOWS_DATA = gql`
  fragment ClientViewContractData on ContractServiceContract {
    id
    status
    dueDate
    createdAt
    contractNumber
    creatorId
    receiverSignature {
      id
      signer {
        id
        fullName
        email
      }
    }
  }

  query GetWorkflowsData {
    contractor {
      id
      admin
    }
    organization {
      id
      contractServiceAccount {
        id
        termsAgreedAt
        contracts {
          nodes {
            id
            ...ClientViewContractIndex
          }
        }
      }
    }
  }
`;

const useContext = () => {
  const location = useLocation<FilterState>();
  const viewMode = location.state?.filterState?.viewMode;
  const { data } = useGetWorkflowsDataQuery();
  const isAdmin = useMemo(() => data?.contractor?.admin, [data]);
  const contractor = data?.contractor;
  const queryParams = useQueryParams<QueryParams>();

  const [state, dispatch] = useReducer(reducer, {
    viewMode: isAdmin ? ViewMode.TEAM : ViewMode.INDIVIDUAL,
  });

  useEffect(() => {
    if (viewMode) {
      return dispatch({
        type: ViewModeAction.SET_VIEW_MODE,
        data: viewMode,
      });
    }

    if (queryParams?.view === 'my_clients') {
      return dispatch({
        type: ViewModeAction.SET_VIEW_MODE,
        data: ViewMode.INDIVIDUAL,
      });
    }
    if (isAdmin && queryParams?.view === 'all_clients') {
      return dispatch({
        type: ViewModeAction.SET_VIEW_MODE,
        data: ViewMode.TEAM,
      });
    }

    // If there is no query parameter for the view,
    // then check the last visited view from local storage
    const view = localStorage.getItem('clientView');
    if (!queryParams?.view && view) {
      if (view === 'my_clients') {
        return dispatch({
          type: ViewModeAction.SET_VIEW_MODE,
          data: ViewMode.INDIVIDUAL,
        });
      }
      if (isAdmin && view === 'all_clients') {
        return dispatch({
          type: ViewModeAction.SET_VIEW_MODE,
          data: ViewMode.TEAM,
        });
      }
    }
    return undefined;
  }, [
    isAdmin,
    contractor,
    dispatch,
    queryParams?.view,
    queryParams?.activeTab,
    viewMode,
  ]);

  const contractServiceAccount = useMemo(() => (
    data?.organization?.contractServiceAccount
  ), [data]);

  const contracts = useMemo(() => (
    contractServiceAccount?.contracts?.nodes?.filter(isFilled) || []
  ), [contractServiceAccount]);

  const hasSentContracts = useMemo(() => {
    if (!data?.organization?.contractServiceAccount?.termsAgreedAt) return false;
    return (contracts || []).filter(isFilled).length > 0;
  }, [data?.organization?.contractServiceAccount, contracts]);

  return {
    data: {
      state,
      dispatch,
      isAdmin,
      contractor,
    },
    uiStates: {
      hasSentContracts,
    },
  };
};

export const [WorkflowsProvider, useWorkflows] = constate(useContext);

export default useWorkflows;
