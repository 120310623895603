import PropTypes from 'prop-types';
import { compose, defaultProps, withProps } from 'recompose';
import { styled } from '@mui/material/styles';

import withLabel from './withLabel';
import withField from './withField';

const StyledSelect = styled('select', {
  name: 'StyledSelect',
})(({ theme, value }) => ({
  width: '100%',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
  outline: 'none',
  padding: '8px 0',
  height: 50,
  '-webkitAppearance': 'none',
  '-mozAppearance': 'none',
  textIndent: 1,
  textOverflow: '',
  color: value ? theme.palette.common.basic1100 : theme.palette.common.basic700,

  '&::-moz-focus-inner': {
    border: 0,
    padding: 0,
  },

  '&::-ms-expand': {
    display: 'none',
  },
}));

const StyledOption = styled('option', { name: 'StyledOption' })({
  fontSize: 18,
  lineHeight: 1,
});

const Select = ({
  name,
  options,
  placeholder,
  value,
  onChange,
  ...otherProps
}) => (
  <StyledSelect
    id={name}
    name={name}
    value={value || ''}
    onChange={(event) => {
      onChange(event);
    }}
    {...otherProps}
  >
    {!value &&
      <option disabled value="">{placeholder}</option>
    }
    {options.map(option => (
      <StyledOption
        key={option.value}
        value={option.value}
      >
        {option.label}
      </StyledOption>
    ))}
  </StyledSelect>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  placeholder: '',
  value: '',
};

export default compose(
  defaultProps({
    suffix: (
      <span
        className="fa fa-chevron-down fa-fw blue-text"
        style={{ pointerEvents: 'none' }}
      />
    ),
  }),
  withLabel,
  withProps({ wrapperStyle: { marginRight: -42 } }),
  withField,
)(Select);
