import { SvgIconProps } from '../Icon';

const SendQuoteIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8137 0C15.0062 0 15.9752 0.966277 15.9752 2.15554H16V10.9264C16 12.1156 15.0311 13.0819 13.8385 13.0819H7.90062C7.87577 13.0819 7.87578 13.1067 7.87578 13.1067L5.09317 15.8568C4.81988 16.1542 4.34783 15.956 4.34783 15.5595V13.0819H2.16149C0.968943 13.0819 0 12.1156 0 10.9264V2.15554C0 0.966277 0.968943 0 2.16149 0H13.8137ZM9.41135 6.17233C9.68582 6.30694 9.89756 6.44796 10.0387 6.57616C10.4387 6.92871 10.6347 7.35818 10.6504 7.83892C10.6504 8.33249 10.47 8.76837 10.1093 9.14015C9.76424 9.4991 9.3094 9.74268 8.74477 9.87729V10.7619H7.67825V9.90934C7.02736 9.81319 6.52546 9.62089 6.17257 9.33245C5.81183 9.03759 5.5452 8.57607 5.37268 7.96712L5.34915 7.89661L6.6431 7.67868L6.66662 7.74277C6.81562 8.15301 6.99599 8.44787 7.20773 8.62094C7.41946 8.77478 7.70962 8.85811 8.07035 8.85811C8.43109 8.85811 8.72125 8.76837 8.95651 8.58248C9.19177 8.403 9.30156 8.17224 9.30156 7.87738C9.30156 7.62099 9.20746 7.41587 9.0114 7.26203C8.90946 7.18511 8.76046 7.09537 8.55656 6.99922C8.35267 6.90307 8.08604 6.78769 7.77236 6.67231C7.09794 6.42873 6.65878 6.19797 6.4392 5.98645C6.09415 5.67236 5.92162 5.29417 5.92162 4.85188C5.92162 4.64676 5.96868 4.44805 6.0471 4.26858C6.12552 4.0891 6.24315 3.92244 6.39999 3.77501C6.55683 3.63399 6.74504 3.50579 6.97246 3.39682C7.17636 3.30067 7.41162 3.21734 7.67041 3.15965V2.31995H8.73693V3.15324C9.12119 3.21734 9.44272 3.32631 9.69367 3.48656C9.95245 3.65963 10.2112 3.92244 10.4465 4.26858L10.4857 4.33267L9.33293 4.85829L9.29372 4.79419C9.0114 4.36472 8.65067 4.15961 8.19583 4.15961C7.91351 4.15961 7.68609 4.2237 7.50572 4.35831C7.32536 4.49292 7.23909 4.65317 7.23909 4.85188C7.23909 5.03136 7.30967 5.17879 7.45083 5.29417C7.59983 5.41596 7.91351 5.56339 8.3762 5.73646C8.79183 5.8903 9.13688 6.03772 9.41135 6.17233Z"
      fill={color}
    />
  </svg>
);

export default SendQuoteIcon;
