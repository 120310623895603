import { LinkProps } from '@mui/material';

import { sanitizePhone } from 'common/utils/sanitation';
import { Link } from 'common/components/material-ui';

type Props = {
  phoneNumber: string;
} & LinkProps;

const PhoneNumberLink = ({ phoneNumber, ...otherProps }: Props): JSX.Element => (
  <Link
    component="a"
    href={`tel:${(phoneNumber || '').replace(/[^\d]+/g, '')}`}
    {...otherProps}
  >
    {sanitizePhone(phoneNumber)}
  </Link>
);

export default PhoneNumberLink;
