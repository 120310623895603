import { useState, useMemo } from 'react';
import { Box, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Document, Page } from 'react-pdf';
import { useWindowSize } from 'react-use';

type Props = {
  base64PDF: string;
  width?: number;
  marginBottom?: number;
}

type StyleProps = {
  marginBottom?: number;
};

const useStyles = makeStyles<Theme, StyleProps>({
  container: ({ marginBottom }) => ({
    marginBottom: marginBottom != null ? marginBottom : 32,
    backgroundColor: 'transparent',
  }),
  page: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  },
});

export const generatePDFBlob = (base64: string): Blob => {
  const binary = atob(base64.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type: 'application/pdf' });
};

const ContractRenderer = ({
  base64PDF,
  width = 910,
  marginBottom,
}: Props): JSX.Element => {
  const { width: screenWidth } = useWindowSize();
  const [numPages, setNumPages] = useState(0);
  const classes = useStyles({ marginBottom });

  const blob = useMemo(() => generatePDFBlob(base64PDF), [base64PDF]);

  return (
    <Box display="flex">
      <Box className={classes.container}>
        <Document
          file={blob}
          onLoadSuccess={({ numPages: n }) => setNumPages(n)}
          loading={
            <Box display="flex" flexDirection="row" justifyContent="center">
              <CircularProgress />
            </Box>
          }
        >
          {[...Array(numPages).keys()].map(i => (
            <Box mb={i !== numPages - 1 ? 2 : 0} key={`page_${i + 1}`}>
              <Page
                pageNumber={i + 1}
                width={Math.min(width, screenWidth - 30)}
                className={classes.page}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </Box>
  );
};

export default ContractRenderer;
