import { useCallback } from 'react';
import { Box, Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help } from '@mui/icons-material';

import { currencyFormat } from 'common/utils/stringFormatters';
import { Button, Input } from 'common/components/material-ui';
import { useSnack } from 'common/utils/snackCart';

type Props = {
  recommendedLoanAmount: number;
  loanPrincipal: string;
  setLoanPrincipal: (arg0: string) => void;
  onSubmit: () => void;
}

const useStyles = makeStyles(theme => ({
  input: {
    padding: '13.5px 14px',
  },
}));

const EasyReapplyCard = ({
  recommendedLoanAmount,
  loanPrincipal,
  setLoanPrincipal,
  onSubmit,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { errorSnack } = useSnack();

  const submit = useCallback((e) => {
    if (parseInt(loanPrincipal, 10) < 1000) {
      errorSnack('Please enter an amount greater than or equal to $1000');
    } else {
      onSubmit();
    }
  }, [loanPrincipal, onSubmit, errorSnack]);

  return (
    <Card>
      <Box display="flex" flexDirection="column" minHeight={400}>
        <Box display="flex" flexDirection="column" pl={5} pr={4} py={2.5} flex="1 1 auto">
          <Typography variant="subtitle1">
            Request new options
          </Typography>
          <Box m={1} />
          <Typography variant="body1">
            You are likely to get more options if you
            request {currencyFormat(recommendedLoanAmount)} or less.
          </Typography>
          <Box mt={2} mb={1}>
            <Typography variant="subtitle2">
              New loan amount:
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex={1} mr={1}>
              <Input
                label=""
                mask="money"
                onChange={e => setLoanPrincipal(e.target.value)}
                value={loanPrincipal}
                InputProps={{
                  classes: { input: classes.input },
                }}
              />
            </Box>
            <Box flex={1} ml={1}>
              <Button
                variant="contained"
                buttonType="primary"
                onClick={submit}
                disabled={!loanPrincipal}
              >
                Change amount
              </Button>
            </Box>
          </Box>
          <Box m="auto">
            <img
              src="https://hearth.imgix.net/creditscore-flipped.png?auto=compress"
              alt="Does not affect credit score"
              style={{
                maxWidth: 265,
                width: '100%',
                marginTop: 16,
              }}
            />
          </Box>
        </Box>
        <Box display="flex" bgcolor="grey.100" alignItems="center" px={3} py={1}>
          <Box color="grey.700">
            <Help />
          </Box>
          <Box ml={2} color="text.primary">
            <Typography variant="body2">
              Likelihood of pre-qualification is calculated based on
              historical pre-qualification rates seen by past Hearth
              users
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default EasyReapplyCard;
