import { useCallback } from 'react';
import { Typography } from '@mui/material';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';
import { sentenceCase } from 'change-case';
import _ from 'lodash';

export type Props = {
  name: string;
  label?: string;
  helperText?: string;
  textFieldProps?: Partial<TextFieldProps>;
} & Partial<DatePickerProps>;

const DatePickerField = ({
  name,
  label,
  helperText,
  textFieldProps = {},
  ...otherProps
}: Props): JSX.Element => {
  const [{ value }, meta, { setValue, setTouched }] = useField(name);

  const formikError = meta.touched ? meta.error : undefined;
  const fieldLabel = label != null ? label : sentenceCase(name);

  const handleChange = useCallback((val) => {
    setValue(val);
  }, [setValue]);

  const formatDate = (date: string): string => {
    const dateArr = date.split('/');
    // if contractor hasn't entered a year, leave date as is
    // returning an empty string causes an "invalid date" error
    // to be displayed and the entered text to be retained
    if (dateArr.length < 3 || dateArr?.[2] === '') return '';
    let year = dateArr.pop();
    if (year && year.length < 4) {
      // prepend a 2 and then however many zeroes to make it length of 4
      year = '2' + year.padStart(3, '0');
    }
    return dateArr.join('/') + `/${year}`;
  };

  const caption = formikError || helperText;

  // if we add inputProps to the text field, via textFieldProps, the displayed date
  // does not update because the props controlling the display get overwritten --
  // this makes testing date fields difficult, as data-testid is unusable
  // this block ensures we don't overwrite the inputProps we want to retain
  const otherTextFieldProps = _.cloneDeep(textFieldProps);
  if (otherTextFieldProps.inputProps) {
    delete otherTextFieldProps.inputProps;
  }

  return (
    // TODO(muiV5-migration): fix font and label nuance issue
    <DatePicker
      value={value || null}
      onChange={handleChange}
      label={fieldLabel}
      InputAdornmentProps={{ position: 'start', sx: { ml: 1 } }}
      allowSameDateSelection
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
          }}
          fullWidth
          hiddenLabel={fieldLabel == null}
          error={!!formikError || textFieldProps?.error}
          // allow passing these functions in at the top level, rather than into textFieldProps
          onFocus={(e) => {
            if (textFieldProps?.onFocus) {
              textFieldProps.onFocus(e);
            }
          }}
          onBlur={(e) => {
            setTouched(true);
            if (textFieldProps?.onBlur) {
              textFieldProps.onBlur(e);
            } else {
              setValue(new Date(formatDate(e.target.value)));
            }
          }}
          helperText={caption ? <Typography variant="caption">{caption}</Typography> : undefined}
          {...otherTextFieldProps}
        />
      )}
      {...otherProps}
    />
  );
};

export default DatePickerField;
