import { Box } from '@mui/material';
import {
  getPlatePluginType,
  PlateEditor,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_UNDERLINE,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_BLOCKQUOTE,
} from '@udecode/plate';

import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import ListButton from './ListButton';

export type ToolbarProps = {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  editor?: PlateEditor;
}

const Toolbar = ({ leftChildren, rightChildren, editor }: ToolbarProps): JSX.Element => (
  <Box display="flex" alignItems="center">
    {leftChildren}
    <MarkButton type={getPlatePluginType(editor, MARK_BOLD)} />
    <MarkButton type={getPlatePluginType(editor, MARK_ITALIC)} />
    <MarkButton type={getPlatePluginType(editor, MARK_UNDERLINE)} />
    <MarkButton type={getPlatePluginType(editor, MARK_CODE)} />
    <BlockButton type={getPlatePluginType(editor, ELEMENT_H1)} />
    <BlockButton type={getPlatePluginType(editor, ELEMENT_H2)} />
    <BlockButton type={getPlatePluginType(editor, ELEMENT_BLOCKQUOTE)} />
    <ListButton type={getPlatePluginType(editor, ELEMENT_OL)} />
    <ListButton type={getPlatePluginType(editor, ELEMENT_UL)} />
    {rightChildren}
  </Box>
);

export default Toolbar;
