type Args<T, V> = {
  enumObj: T;
  enumValue: V;
}

// makes properties optional in types with nested objects
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function stringToEnum<ET, T>(enumObj: ET, str: keyof ET): T {
  return enumObj[str as string];
}

// returns the enum key STRING value, given an enum and value to search by
export function getEnumKeyByEnumValue<T, V>({ enumObj, enumValue }: Args<T, V>): keyof T | null {
  const keys = Object.keys(enumObj).filter(x => enumObj[x] === enumValue);
  return keys.length > 0 ? keys[0] as keyof T : null;
}

// returns actual enum VALUE, given an enum and value to search by
export function getEnumByEnumValue<T, V>({ enumObj, enumValue }: Args<T, V>): T[keyof T] | null {
  const keys = Object.keys(enumObj).filter(x => enumObj[x] === enumValue);
  return keys.length > 0 ? enumObj[keys[0]] : null;
}
