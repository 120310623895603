import { SVGProps, useMemo } from 'react';
import * as React from 'react';
import { useTheme } from '@mui/material';

import * as IconComponents from './icons';

export type IconName =
  'add-circle' |
  'account-manager' |
  'bank' |
  'banner' |
  'brush-square' |
  'calculator' |
  'check-circle' |
  'checkmark' |
  'clock' |
  'contract' |
  'document' |
  'download' |
  'envelope-open' |
  'fast-credit-card' |
  'fill-form' |
  'financing-calculator' |
  'fireworks' |
  'flag' |
  'hearth-cash' |
  'house' |
  'info-outline' |
  'invoice' |
  'lock' |
  'mail' |
  'money-bag' |
  'money-stub' |
  'offer-financing' |
  'paper-plane' |
  'paperwork' |
  'people' |
  'phone' |
  'phone-setup' |
  'presentation' |
  'question' |
  'quickbooks' |
  'receipt' |
  'request-payment' |
  'right-arrow' |
  'send-contract' |
  'send-quote' |
  'speech-heart' |
  'tada' |
  'transaction' |
  'unlock' |
  'unlock-filled' |
  'unsigned-contract' |
  'lock-filled' |
  'video-camera' |
  'wallet' |
  'warning-outline' |
  'spark-check' |
  'construction' |
  'dashboard' |
  'dashboard-outline' |
  'touch-screen' |
  'learn-center';

export type IconComponent =
  'AddCircleIcon' |
  'AccountManagerIcon' |
  'BankIcon' |
  'BannerIcon' |
  'BrushSquareIcon' |
  'CalculatorIcon' |
  'CheckCircleIcon' |
  'CheckmarkIcon' |
  'ClockIcon' |
  'ContractIcon' |
  'DocumentIcon' |
  'DownloadIcon' |
  'EnvelopeOpenIcon' |
  'FastCreditCardIcon' |
  'FillFormIcon' |
  'FinancingCalculatorIcon' |
  'FireworksIcon' |
  'FlagIcon' |
  'HearthCashIcon' |
  'HouseIcon' |
  'InfoOutlineIcon' |
  'InvoiceIcon' |
  'LockIcon' |
  'MailIcon' |
  'MoneyBagIcon' |
  'MoneyStubIcon' |
  'OfferFinancingIcon' |
  'PaperPlaneIcon' |
  'PaperworkIcon' |
  'PeopleIcon' |
  'PhoneIcon' |
  'PhoneSetupIcon' |
  'PresentationIcon' |
  'QuestionIcon' |
  'ReceiptIcon' |
  'RequestPaymentIcon' |
  'RightArrowIcon' |
  'SendContractIcon' |
  'SendQuoteIcon' |
  'SpeechHeartIcon' |
  'TadaIcon' |
  'TransactionIcon' |
  'UnlockIcon' |
  'UnlockFilledIcon' |
  'UnsignedContractIcon' |
  'LockFilledIcon' |
  'VideoCameraIcon' |
  'WalletIcon' |
  'WarningOutlineIcon' |
  'SparkCheckIcon' |
  'ConstructionIcon' |
  'DashboardIcon' |
  'DashboardOutlineIcon' |
  'TouchScreenIcon' |
  'LearnCenterIcon';

export type SvgIconProps = {
  color: string;
  bgColor?: string;
} & Omit<SVGProps<SVGSVGElement>, 'color'>;

type Props = {
  color?: Color;
  bgColor?: Color;
  size?: number | string;
  name: IconName;
} & Omit<SVGProps<SVGSVGElement>, 'color'>;

export const icons: Record<IconName, React.FC<SvgIconProps>> = {
  'right-arrow': IconComponents.RightArrowIcon,
  lock: IconComponents.LockIcon,
  bank: IconComponents.BankIcon,
  'fast-credit-card': IconComponents.FastCreditCardIcon,
  'hearth-cash': IconComponents.HearthCashIcon,
  house: IconComponents.HouseIcon,
  mail: IconComponents.MailIcon,
  paperwork: IconComponents.PaperworkIcon,
  calculator: IconComponents.CalculatorIcon,
  wallet: IconComponents.WalletIcon,
  'money-bag': IconComponents.MoneyBagIcon,
  presentation: IconComponents.PresentationIcon,
  people: IconComponents.PeopleIcon,
  'paper-plane': IconComponents.PaperPlaneIcon,
  'fill-form': IconComponents.FillFormIcon,
  checkmark: IconComponents.CheckmarkIcon,
  document: IconComponents.DocumentIcon,
  fireworks: IconComponents.FireworksIcon,
  'financing-calculator': IconComponents.FinancingCalculatorIcon,
  invoice: IconComponents.InvoiceIcon,
  'envelope-open': IconComponents.EnvelopeOpenIcon,
  unlock: IconComponents.UnlockIcon,
  'unlock-filled': IconComponents.UnlockFilledIcon,
  'unsigned-contract': IconComponents.UnsignedContractIcon,
  'lock-filled': IconComponents.LockFilledIcon,
  question: IconComponents.QuestionIcon,
  quickbooks: IconComponents.QuickBooksIcon,
  'video-camera': IconComponents.VideoCameraIcon,
  download: IconComponents.DownloadIcon,
  phone: IconComponents.PhoneIcon,
  'phone-setup': IconComponents.PhoneSetupIcon,
  banner: IconComponents.BannerIcon,
  'add-circle': IconComponents.AddCircleIcon,
  'brush-square': IconComponents.BrushSquareIcon,
  'check-circle': IconComponents.CheckCircleIcon,
  flag: IconComponents.FlagIcon,
  transaction: IconComponents.TransactionIcon,
  clock: IconComponents.ClockIcon,
  receipt: IconComponents.ReceiptIcon,
  'send-contract': IconComponents.SendContractIcon,
  'speech-heart': IconComponents.SpeechHeartIcon,
  tada: IconComponents.TadaIcon,
  'info-outline': IconComponents.InfoOutlineIcon,
  contract: IconComponents.ContractIcon,
  'warning-outline': IconComponents.WarningOutlineIcon,
  'spark-check': IconComponents.SparkCheckIcon,
  construction: IconComponents.ConstructionIcon,
  dashboard: IconComponents.DashboardIcon,
  'dashboard-outline': IconComponents.DashboardOutlineIcon,
  'offer-financing': IconComponents.OfferFinancingIcon,
  'request-payment': IconComponents.RequestPaymentIcon,
  'touch-screen': IconComponents.TouchScreenIcon,
  'learn-center': IconComponents.LearnCenterIcon,
  'account-manager': IconComponents.AccountManagerIcon,
  'money-stub': IconComponents.MoneyStubIcon,
  'send-quote': IconComponents.SendQuoteIcon,
};

const Icon = ({
  color,
  bgColor,
  size,
  name,
  ...otherProps
}: Props): JSX.Element => {
  const theme = useTheme();

  const Component = useMemo(() => icons[name], [name]);

  return (
    <Component
      color={color ? theme.palette.common[color] : 'inherit'}
      width={size}
      height={size}
      bgColor={bgColor ? theme.palette.common[bgColor] : undefined}
      {...otherProps}
    />
  );
};

export default Icon;
