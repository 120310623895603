import {
  Box,
  Card,
  CardMedia,
  Typography,
  TypographyProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 32px 64px',
    maxWidth: 185,
    backgroundColor: theme.palette.common.basic100,
    border: `1px solid ${theme.palette.common.basic500}`,
    borderRadius: 4,
    '&:hover': {
      boxShadow: '0px 3px 8px rgba(38, 53, 71, 0.2)',
    },
  },
  image: {
    height: 120,
    width: 120,
    objectFit: 'contain',
    alignSelf: 'center',
  },
}));

type Props = {
  caption?: string | React.ReactNode;
  captionColor?: TypographyProps['color'];
  imgSrc?: string | null;
  imgAlt?: string;
  onClick?: () => void;
}

const Polaroid = ({
  caption,
  captionColor = 'textPrimary',
  imgSrc,
  imgAlt,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={onClick}>
      {imgSrc ?
        <CardMedia
          className={classes.image}
          src={imgSrc}
          component="img"
          {...(imgAlt && { alt: imgAlt })}
        /> :
        <Box
          height={120}
          width={120}
          bgcolor="common.basic300"
        />
      }
      <Box mb={2} />
      {caption &&
        <Typography variant="h6" color={captionColor}>
          {caption}
        </Typography>
      }
    </Card>
  );
};

export default Polaroid;
