import { AxiosResponse } from 'axios';

import client from './client';

export type FetchUserSessionArgs = {
  query: string;
};

type FetchUserSessionResponse = AxiosResponse<{
  hearthUid: string;
  deviceId: string;
  utm: {
    utmCampaign: string | null;
    utmSource: string | null;
    utmMedium: string | null;
    utmTerm: string | null;
    utmContent: string | null;
  };
  abTest: {
    tests: AbTest[];
    variants: AbTestVariant[];
    randomSeed: number;
  };
}>;
// eslint-disable-next-line import/prefer-default-export
export const fetchUserSession =
  ({ query }: FetchUserSessionArgs): Promise<FetchUserSessionResponse> =>
    client.get(`/sessions${query}`);
