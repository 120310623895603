import { SvgIconProps } from '../Icon';

const WalletIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...otherProps}>
    <path
      d="M2.5 18.5h5M15.5 18.5h3M26.5 18.5h3M37.5 18.5h5M2.5 38.5h5M15.5 38.5h3M26.5 38.5h3M37.5 38.5h5M42.5 24.499V12.5h-36a4 4 0 01-4-4v30a6 6 0 006 6h34v-12"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 7.5v-3h-28a4 4 0 000 8M46.5 32.5h-9a4 4 0 010-8h9v8z"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WalletIcon;
