import { Box, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SendOutlined } from '@mui/icons-material';
import { differenceInDays } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Button } from 'common/components/material-ui';

import useTools from '../useTools';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.primary100,
    color: theme.palette.common.primary500,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    borderRadius: 8,
  },
  button: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const InvoiceTemplateBanner = (): JSX.Element => {
  const classes = useStyles();
  const { contractor } = useTools();
  const history = useHistory();

  const invoiceTemplate = contractor?.activeCustomizedInvoiceTemplate;

  return (
    <Box className={classes.root}>
      <Typography variant="h3">
        This is your invoice template
      </Typography>
      <Box pt={1.5} pb={2}>
        <Typography variant="body2">
          You will add the customer information and payment details when you send a payment request.
          Any edits to this template will be universal.
        </Typography>
      </Box>
      <Box className={classes.row}>
        {invoiceTemplate &&
          <Typography variant="subtitle2">
            Updated: {differenceInDays(Date.now(), Date.parse(invoiceTemplate.createdAt))} days ago
          </Typography>
        }
        <Box className={classes.row}>
          <Button
            buttonType="common.primary"
            onClick={() => history.push('/dashboard/tools/invoice-template/edit')}
            className={classes.button}
            variant="contained"
          >
            <Grid container direction="row">
              <Box mr={1}>
                <SendOutlined />
              </Box>
              Edit your template
            </Grid>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceTemplateBanner;
