import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { matchPath, useLocation, useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Link } from 'common/components/material-ui';
import CustomizeInvoice from 'contractor/modules/CustomizeInvoice';
import InvoicePreview from 'contractor/modules/InvoicePreview';
import { fakeInvoiceData } from 'contractor/modules/CustomizeInvoice/useCustomizeInvoice';

import { InvoiceTemplateBanner, MobileBlock } from './components';

const useStyles = makeStyles(theme => ({
  back: {
    alignSelf: 'center',
    color: theme.palette.common.primary500,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.common.primary500,
    },
  },
}));

const EditInvoiceTemplate = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box
        ml={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        color="common.primary500"
      >
        <IconButton
          onClick={() => history.push('/dashboard/tools/invoice-template')}
          className={classes.back}
          size="large"
        >
          <ArrowBackIcon color="inherit" fontSize="large" />
        </IconButton>
        <Link
          className={classes.back}
          variant="subtitle1"
          color="inherit"
          onClick={() => history.push('/dashboard/tools/invoice-template')}
        >
          Back to Marketing Tools
        </Link>
      </Box>
      <CustomizeInvoice />
    </>
  );
};

const InvoiceTemplate = (): JSX.Element => {
  const { pathname } = useLocation();
  const match = matchPath<{ isExact?: string | undefined }>(
    pathname, '/dashboard/tools/invoice-template/edit',
  );
  const itly = useLogEvent();

  useMount(() => {
    itly.viewCustomizeInvoiceTool();
  });

  return (
    <MobileBlock description="customize your invoice.">
      {match?.isExact ?
        <EditInvoiceTemplate /> :
        <Box px={{ xs: 4, sm: 8 }}>
          <InvoiceTemplateBanner />
          <Box mb={3} />
          <InvoicePreview invoiceData={fakeInvoiceData} />
        </Box>
      }
    </MobileBlock>
  );
};

export default InvoiceTemplate;
