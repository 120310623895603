import { useHistory } from 'react-router-dom';
import {
  Box,
  Tabs,
  Tab,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import { HomeownerEventBucket } from 'types';

import AddNewButton from './AddNewButton';
import MenuItems, { ViewMode } from './MenuItems';
import {
  useColorPalette,
  useClientIndex,
  ClientIndexActionType,
} from '../useClientIndex';

const buckets = [
  HomeownerEventBucket.FINANCING_AND_PAYMENTS,
  HomeownerEventBucket.FINANCING_INDEX,
  HomeownerEventBucket.PAYMENTS_INDEX,
];

type StyleProps = {
  selectedTab: HomeownerEventBucket;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tabs: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.common.primary100,
    maxWidth: 480,
  },
  tabRoot: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    maxWidth: 160,
  },
  selected: ({ selectedTab }) => ({
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.basic100,
    color: selectedTab === HomeownerEventBucket.PAYMENTS_INDEX ?
      theme.palette.common.success500 :
      theme.palette.common.primary500,
    fontWeight: 700,
  }),
  clientViewButton: ({ selectedTab }) => ({
    color: selectedTab === HomeownerEventBucket.PAYMENTS_INDEX ?
      theme.palette.common.success500 :
      theme.palette.common.primary500,
  }),
  checkBox: {
    width: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    borderRight: `1px solid ${theme.palette.common.basic700}`,
  },
  menu: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
}));

function a11yProps(index: number) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const ClientIndexHeader = (): JSX.Element => {
  const history = useHistory();
  const { state, dispatch } = useClientIndex();
  const { headerColor, buttonColor, buttonBackgroundColor } = useColorPalette();

  const value = buckets.indexOf(state.homeownerBucket);
  const classes = useStyles({ selectedTab: buckets[value] });

  const handleMenuClick = (viewMode: ViewMode) => {
    dispatch({
      type: ClientIndexActionType.SET_VIEW_MODE,
      data: viewMode,
    });
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch({
      type: ClientIndexActionType.SET_HOMEOWNER_EVENT_BUCKET,
      data: buckets[newValue],
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={headerColor}
      className={classes.header}
      px={4}
      pt={4}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flex={1}
        mb={4}
      >
        <MenuItems
          buttonText={state.viewMode === ViewMode.TEAM ? 'All Clients' : 'My Clients'}
          color={buttonColor}
          backgroundColor={buttonBackgroundColor}
          onSelectMyClients={() => handleMenuClick(ViewMode.INDIVIDUAL)}
          onSelectTeamClients={() => handleMenuClick(ViewMode.TEAM)}
          onSelectInvoices={() => history.replace('/dashboard/clients/invoices')}
          onSelectContracts={() => history.replace('/dashboard/clients/contracts')}
          viewMode={state.viewMode}
        />
        <Box>
          <AddNewButton
            color={buttonColor}
            menuColor={buttonColor}
          />
        </Box>
      </Box>
      {state.viewMode !== ViewMode.INVOICE &&
        state.viewMode !== ViewMode.CONTRACT &&
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleTabChange}
          aria-label="Client header"
        >
          <Tab
            label="All"
            classes={{
              root: cx(classes.tabRoot, { [classes.divider]: value === 2 }),
              selected: classes.selected,
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Financing"
            classes={{
              root: cx(classes.tabRoot, { [classes.divider]: value === 0 }),
              selected: classes.selected,
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Payments"
            classes={{
              root: classes.tabRoot,
              selected: classes.selected,
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      }
    </Box>
  );
};

export default ClientIndexHeader;
