import { useRef } from 'react';
import {
  Select as MaterialSelect,
  MenuItem,
  FormControl,
  FormControlProps,
  InputLabel,
  Typography,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select/Select';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  width: '100%',
});

type Option<T extends string | number> = {
  value: T;
  label: string;
}

export type Props<T extends string | number = string> = {
  name: string;
  error?: React.ReactNode;
  label: string;
  items: Option<T>[];
  hideErrorMessage?: boolean;
  onChange: (
    event: SelectChangeEvent<T>,
    child: React.ReactNode,
  ) => void;
  helperText?: React.ReactNode;
  formControlProps?: FormControlProps;
} & Omit<SelectProps<T>, 'error' | 'onChange'>;

const useStyles = makeStyles({
  icon: {
    top: 'unset',
    marginRight: '7px',
  },
});

function Select<T extends string | number = string>({
  value,
  items,
  name,
  error,
  label,
  onChange,
  autoWidth = false,
  multiple = false,
  native = false,
  inputProps = {},
  hideErrorMessage = false,
  helperText,
  formControlProps = {},
  IconComponent,
  ...selectProps
}: Props<T>): JSX.Element {
  const classes = useStyles();
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleChange = (
    event: SelectChangeEvent<T>,
    child: React.ReactNode,
  ): void => {
    if (onChange) {
      onChange(event, child);
    }
  };

  const ItemComponent = native ? 'option' : MenuItem;

  const caption = (!hideErrorMessage && error) ? error : helperText;

  return (
    <StyledFormControl variant="outlined" error={Boolean(error)} fullWidth {...formControlProps}>
      <InputLabel id={`select-label-${name}`} ref={labelRef} shrink>{label}</InputLabel>
      <MaterialSelect<T>
        value={value ?? '' as T} // always controlled for now
        name={name}
        onChange={handleChange}
        multiple={multiple}
        autoWidth={autoWidth}
        native={native}
        IconComponent={IconComponent}
        input={
          label ?
            <OutlinedInput label={label} notched /> :
            undefined
        }
        inputProps={{
          classes: { icon: classes.icon },
          error: error != null ? error : undefined,
          notched: 'true',
          ...inputProps,
        }}
        labelId={`select-label-${name}`}
        {...selectProps}
      >
        {native && value == null &&
          <ItemComponent value="">{''}</ItemComponent>
        }
        {items.map(option => (
          <ItemComponent
            key={option.value}
            value={option.value}
          >
            {option.label}
          </ItemComponent>
        ))}
      </MaterialSelect>
      {caption &&
        <FormHelperText>
          <Typography variant="caption">{caption}</Typography>
        </FormHelperText>
      }
    </StyledFormControl>
  );
}

export default Select;
