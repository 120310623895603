import { Typography, Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { stringify } from 'query-string';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import { useContainer } from 'bentoo/hooks';

import useLogEvent from 'common/hooks/useLogEvent';
import { isProduction } from 'config/app';
import { analytics } from 'common/services';
import { Note, Input, Button } from 'common/components/material-ui';
import { organizationSelector } from 'contractor/selectors/organization';
import { idSelector } from 'contractor/selectors/contractor';
import { sendBannerInstallEmail } from 'contractor/actions/contractor';
import { CopyCodeBlock, Popup } from 'contractor/components/widgets';
import { useUrl, useVerticalState } from 'common/hooks';
import { Smidge } from 'common/components/layouts';
import { buildMaterialInputParams } from 'common/utils/stepUtils';
import { useSnack } from 'common/utils/snackCart';
import { BannerOptionsMeta } from 'contractor/types/verticals';

type Props = {
  open: boolean;
  onClose: () => void;
  banner: BannerOptionsMeta;
  color: string;
  itemType: string;
  handleExpand: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.success500,
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const GetBannerModal = ({
  onClose,
  open,
  banner,
  color,
  itemType,
  handleExpand,
}: Props): JSX.Element => {
  const classes = useStyles();
  const organization = useSelector(organizationSelector);
  const contractorId = useSelector(idSelector);
  const send = useContainer(sendBannerInstallEmail);
  const { successSnack, errorSnack } = useSnack('Email successfully sent!');
  const { appUrl } = useUrl();
  const [{ brandMetadata, verticalMetadata }] = useVerticalState();
  const itly = useLogEvent();

  const {
    banners: {
      bannerPath,
    },
  } = verticalMetadata.dashboardMarketingTools.websiteContent;

  const imageName = color;
  const bannerLink = appUrl(
    `/${bannerPath}/${organization.urlPath}/banner.jpg`,
    {
      size_id: banner.size,
      extra_options: banner.extraOptions,
      color,
    },
  );

  const utmParams = stringify({
    utm_source: 'contractor',
    utm_medium: 'contractor-website',
    utm_campaign: organization.id,
    utm_term: banner.size,
    utm_content: imageName,
  });

  const organizationUrl = appUrl(`/partners/${organization.urlPath}?${utmParams}`);

  const imageTag = `<img src="${bannerLink}"alt="${brandMetadata.label} ${banner.size}"` +
    `style="height:${banner.height}px;width:${banner.width}px" />`;

  let bannerTag =
    `<a href="${organizationUrl}" target="_blank">\n` +
    `  ${imageTag}\n</a>`;

  if (itemType === 'widget') {
    const scriptData = `id="hearth-script" data-orgid="${organization.id}" data-partner="${organization.urlPath}"`;
    const scriptUrl = isProduction ?
      'https://widget.gethearth.com/script.js' : 'https://widget-staging.gethearth.com/script.js';
    bannerTag = `<script src="${scriptUrl}" ${scriptData}></script>\n` +
      '<iframe id="hearth-widget_calculator_v1"></iframe>';
  }

  const sendEmail = async (destinationEmail: string): Promise<void> => {
    try {
      await send.callApi({
        contractorId,
        organizationUrlPath: organization.urlPath,
        bannerHeight: banner.height,
        bannerWidth: banner.width,
        extraOptions: banner.extraOptions,
        color,
        utmParams,
        destinationEmail,
      });
      successSnack();
      itly.sendBannerInstructions({ type: itemType });
      onClose();
    } catch (e) {
      analytics.trackException(e);
      errorSnack();
    }
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
    >
      <Note
        title={`Install ${itemType}`}
        subtitle={banner.size + ` ${itemType}`}
      >
        <CopyCodeBlock
          code={bannerTag}
          description="Copy this code and add it to your website."
          snackText="Copied code!"
        />
        <Typography
          style={{
            fontSize: '14px',
            lineHeight: '19px',
            marginTop: '4px',
            marginBottom: '6px',
          }}
        >
          Email instructions to your web developer.
        </Typography>
        <Formik
          isInitialValid
          initialValues={{
            email: '',
          }}
          validationSchema={object().shape({
            email: string().label('Email').email().required(),
          })}
          validateOnChange={false}
          validateOnBlur
          onSubmit={(values, { resetForm }) => {
            sendEmail(values.email);
            resetForm();
          }}
        >
          {({
            handleChange,
            values,
            errors,
            isValidating,
          }) => (
            <Form>
              <Input
                fullWidth
                autoComplete="off"
                {...buildMaterialInputParams('email', values, errors, handleChange)}
                label="Email address"
              />
              <Smidge />
              <Button
                buttonType="primary"
                disabled={values.email === '' || isValidating}
                type="submit"
                style={{
                  display: 'flex',
                  alignSelf: 'flex-end',
                  marginLeft: 'auto',
                  marginRight: 0,
                }}
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
        <Smidge />
        <Box style={{ margin: 'auto' }}>
          <Typography display="inline" align="center">
            Need help with banners? Check out the{' '}
          </Typography>
          <Typography
            display="inline"
            color="primary"
            onClick={() => {
              onClose();
              handleExpand(1);
            }}
            className={classes.hoverPointer}
          >
            <u>Installation Help</u>
          </Typography>{' '}
          <Typography display="inline">section.</Typography>
        </Box>
      </Note>
    </Popup>
  );
};

export default GetBannerModal;
