import { SvgIconProps } from '../Icon';

const BrushSquareIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <path
      d="M17.08 19.109L32.92 4.17a2.5 2.5 0 013.536 3.536l-14.938 15.85"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M12.75 20.575a5.164 5.164 0 017.3 7.303c-2.017 2.017-9.738 2.435-9.738 2.435s.418-7.721 2.438-9.738z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M25.938 3.438h-22.5v33.75h33.75v-22.5"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default BrushSquareIcon;
