import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Link } from 'common/components/material-ui';

const useStyles = makeStyles(theme => ({
  back: {
    alignSelf: 'center',
    color: theme.palette.common.primary500,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.common.primary500,
    },
  },
}));

const BackToTools = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Link
      component={RouterLink}
      color="primary"
      to="/dashboard/tools/contract-template"
    >
      <Box
        display="flex"
        flex={1}
        my={2}
        alignItems="center"
        color="common.primary500"
      >
        <IconButton className={classes.back} size="large">
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Box
          ml={1}
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle1">Back to Tools</Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default BackToTools;
