import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteProps, useLocation } from 'react-router-dom';

import { useVerticalState } from 'common/hooks';
import { ReportingMenu } from 'contractor/modules/Reporting';
import { ReportingProvider } from 'contractor/modules/Reporting/useReporting';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  menu: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 32,
    },
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 32,
    alignItems: 'flex-start',
  },
}));

type Props = {
  children?: React.ReactNode;
} & RouteProps;

const ReportingContainer = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [{ brandMetadata }] = useVerticalState();
  const pageTitle = `Reporting | ${brandMetadata.label}`;

  // using approach found here: https://www.carlrippon.com/scrolling-a-react-element-into-view/
  // as react-scroll does not appear to work as expected on mount
  const scrollElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box className={classes.root} mb={8}>
          <Box className={classes.menu}>
            <ReportingMenu />
          </Box>
          <Box width="100%">
            <div ref={scrollElementRef} />
            {children}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ((props: Props): JSX.Element => (
  <ReportingProvider>
    <ReportingContainer {...props} />
  </ReportingProvider>
));
