import { SvgIconProps } from '../Icon';

const PaperworkIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 42" fill={bgColor} {...otherProps}>
    <path
      d="M3.116 5.637L30.8 1.439a2 2 0 012.277 1.677L37.275 30.8a2 2 0 01-1.678 2.277l-14.959 2.269a2 2 0 01-1.105-.147L5.588 29.07a2 2 0 01-1.173-1.53L1.438 7.913a2 2 0 011.678-2.277z"
      fill={bgColor}
      stroke={color}
      strokeWidth={2}
    />
    <path
      d="M4.445 27.741s4.125.99 7.728 1.129c3.603.137 6.257-.316 7.797-.674-.756 1.636.413 7.371.413 7.371"
      stroke={color}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <rect
      x={6.832}
      y={10.13}
      width={21}
      height={2}
      rx={1}
      transform="rotate(-8.622 6.832 10.13)"
      fill={color}
    />
    <rect
      x={6.832}
      y={10.13}
      width={21}
      height={2}
      rx={1}
      transform="rotate(-8.622 6.832 10.13)"
      fill={color}
    />
    <rect
      x={6.832}
      y={10.13}
      width={21}
      height={2}
      rx={1}
      transform="rotate(-8.622 6.832 10.13)"
      fill={color}
    />
    <rect
      x={7.431}
      y={14.085}
      width={15}
      height={2}
      rx={1}
      transform="rotate(-8.622 7.431 14.085)"
      fill={color}
    />
    <rect
      x={7.431}
      y={14.085}
      width={15}
      height={2}
      rx={1}
      transform="rotate(-8.622 7.431 14.085)"
      fill={color}
    />
    <rect
      x={7.431}
      y={14.085}
      width={15}
      height={2}
      rx={1}
      transform="rotate(-8.622 7.431 14.085)"
      fill={color}
    />
    <rect
      x={8.031}
      y={18.04}
      width={17}
      height={2}
      rx={1}
      transform="rotate(-8.622 8.031 18.04)"
      fill={color}
    />
    <rect
      x={8.031}
      y={18.04}
      width={17}
      height={2}
      rx={1}
      transform="rotate(-8.622 8.031 18.04)"
      fill={color}
    />
    <rect
      x={8.031}
      y={18.04}
      width={17}
      height={2}
      rx={1}
      transform="rotate(-8.622 8.031 18.04)"
      fill={color}
    />
  </svg>
);

export default PaperworkIcon;
