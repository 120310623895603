import { SvgIconProps } from '../Icon';

const FireworksIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...otherProps}>
    <path
      d="M28.5 8.102L25.736 7.7 24.5 5.196 23.264 7.7l-2.764.402 2 1.949-.472 2.753 2.472-1.3 2.472 1.3-.472-2.753 2-1.95z"
      stroke={color === 'inherit' ? '#FF5345' : color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 24.5s11 4 11 22M35.5 24.5s-11 4-11 22"
      stroke={color === 'inherit' ? '#0154A4' : color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 19.5v6"
      stroke={color === 'inherit' ? '#139F6F' : color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 37.406l-2.764-.402L9.5 34.5l-1.236 2.504-2.764.402 2 1.949-.472 2.753L9.5 40.809l2.472 1.299-.472-2.753 2-1.949zM43.5 37.406l-2.764-.402L39.5 34.5l-1.236 2.504-2.764.402 2 1.949-.472 2.753 2.472-1.299 2.472 1.299-.472-2.753 2-1.949z"
      stroke={color === 'inherit' ? '#FF5345' : color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 23.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM43 23.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      stroke={color === 'inherit' ? '#FFB900' : color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FireworksIcon;
