import { useEffect } from 'react';
import { useField } from 'formik';

import InputField, { InputFieldProps } from 'common/components/formik/InputField';
import { CheckboxField } from 'common/components/formik';

const CheckedInputField = ({
  name,
  ...inputFieldProps
}: InputFieldProps): JSX.Element => {
  const [{ value: boolValue }, , { setValue: setBoolValue }] = useField<boolean | undefined>(`socialLinks.${name}`);
  const [{ value: stringValue }] = useField<string | undefined>(`organization.${name}`);

  useEffect(() => {
    if (stringValue != null && stringValue !== '') {
      // if the string value changes, we want to set the checked field to true
      if (boolValue === false) {
        setBoolValue(true);
      }
    } else if (boolValue === true) {
      // otherwise - set to false
      setBoolValue(false);
    }
  // DO NOT MODIFY - we want functionality to uncheck the checkbox even if there is text
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringValue]);

  return (
    <CheckboxField
      name={`socialLinks.${name}`}
      disabled={!stringValue}
      label={<InputField name={`organization.${name}`} {...inputFieldProps} />}
    />
  );
};

export default CheckedInputField;
