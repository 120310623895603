import { FieldArray, useField, ArrayHelpers } from 'formik';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button } from 'common/components/material-ui';

import AttachmentField from './AttachmentField';

type Props = {
  namePrefix: string;
  onUpload: (file: File) => Promise<{ id: string; remoteUrl: string; }>;
  dropzoneOptions?: Partial<DropzoneOptions>;
}

type InnerProps = Props & {
  arrayHelpers: ArrayHelpers;
}

type Values = {
  id?: string;
  localUrl: string;
  remoteUrl?: string;
  fileName: string;
}

const AttachmentFieldGroup = ({
  namePrefix,
  onUpload,
  arrayHelpers,
  dropzoneOptions = {},
}: InnerProps): JSX.Element => {
  const [{ value }] = useField<Values[]>(namePrefix);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 5000000,
    accept: [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/pdf',
      'application/msword',
    ],
    onDrop: (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file, index) => {
        if (value.find(({ fileName }) => fileName === file.name)) {
          return;
        }
        const arrayIndex = value.length + index;
        const fileData = {
          localUrl: URL.createObjectURL(file),
          fileName: file.name,
        };
        arrayHelpers.push(fileData);
        onUpload(file).then(
          ({ id, remoteUrl }) => {
            // on successful upload, update the url with remote url and backend id
            arrayHelpers.replace(arrayIndex, {
              id,
              remoteUrl,
              ...fileData,
            });
          },
          () => (
            arrayHelpers.remove(arrayIndex)
          ));
      });
    },
    ...dropzoneOptions,
  });

  const rootProps = getRootProps();
  const { css, ...boxProps } = rootProps;

  return (
    <Box
      {...boxProps}
      sx={{
        bgcolor: isDragActive ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
        outlineWidth: 0,
        opacity: (isDragActive ? 0.5 : 1),
      }}
    >
      <input {...getInputProps()} />
      {/* need to stop propagation below to prevent upload modal from rendering */}
      <div onClick={e => e.stopPropagation()}>
        {value.map(({ id, fileName }, index) => (
          <Box mb={2}>
            <AttachmentField
              key={fileName}
              namePrefix={`${namePrefix}[${index}]`}
              action={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {!id &&
                    <CircularProgress size={18} sx={{ mr: 1 }} />
                  }
                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            />
          </Box>
        ))}
      </div>
      <Button
        buttonType="common.primary"
        variant="text"
        noStopPropagation
        onClick={(event) => { event.preventDefault(); }}
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        Add attachment
      </Button>
    </Box>
  );
};

export default ({
  namePrefix,
  ...otherProps
}: Props): JSX.Element => (
  <FieldArray
    name={namePrefix}
    render={arrayHelpers => (
      <AttachmentFieldGroup
        namePrefix={namePrefix}
        arrayHelpers={arrayHelpers}
        {...otherProps}
      />
    )}
  />
);
