import cx from 'classnames';

type Props = {
  className?: string;
  x?: number;
}

const Smidge = ({ className, x = 1 }: Props): JSX.Element => (
  <span className={cx('smidge', className)} style={{ height: 8 * x }} />
);

export default Smidge;
