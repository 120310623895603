import { useState } from 'react';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { isFilled } from 'ts-is-present';
import moment from 'moment';
import {
  Box,
  Typography,
  Grid,
  BoxProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';
import { hearthUidSelector } from 'common/selectors/user';
import { useCheckReturningApplicantQuery } from 'types';
import { Popup } from 'contractor/components/widgets';

import SeeOffersNote from './SeeOffersNote';

const useStyles = makeStyles(theme => ({
  ctaContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

type Props = {
  existingInquiries?: number;
  copy: string;
} & BoxProps;

/**
 * Banner that allows users to see existing offers. The banner checks if the current
 * HearthUID is associated to any loan inquiries. If it is, the banner will render
 *
 * @param copy The text that is shown below welcome back
 * @param existingInquiries number - Param to set visibility of the banner dependent upon the number
 * of loan inquiries a hearth_uid is related to. In case of the offers page, we only want to show
 * the ReturningApplicantBanner if the associated hearth_uid has more than 1 loan inquiry.
 */
const ReturningApplicantBanner = ({
  copy,
  existingInquiries = 1,
  ...containerProps
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const hearthUid = useSelector(hearthUidSelector);
  const { data, loading } = useCheckReturningApplicantQuery({
    variables: { hearthUid },
  });

  if (loading || data?.hearthUidSelector?.loanInquiries?.nodes == null) {
    return null;
  }

  const activeInquiries = data.hearthUidSelector
    .loanInquiries
    .nodes
    .filter(isFilled)
    .filter(inquiry => inquiry.qualified)
    .map(l => l.createdAt)
    .map(l => moment(l))
    .filter(l => l.isAfter(moment().subtract(30, 'days')));

  if (activeInquiries.length < existingInquiries) {
    return null;
  }

  return (
    <Box {...containerProps}>
      <Popup
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <SeeOffersNote
          showStartNote={false}
        />
      </Popup>
      <Box p={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h3">Welcome back!</Typography>
            <Box mt={1}>
              <Typography variant="body1">
                {copy}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            xs={12}
            sm={4}
            className={classes.ctaContainer}
          >
            <Box width={{ xs: 2 / 3 }} my={1}>
              <Button
                variant="contained"
                size="medium"
                buttonType="primary"
                fullWidth
                onClick={() => setOpen(!open)}
              >
                See previous options
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ReturningApplicantBanner.RETURNING_APPLICANT = gql`
  query CheckReturningApplicant($hearthUid: String!) {
    hearthUidSelector(hearthUid: $hearthUid) {
      loanInquiries {
        nodes {
          id
          createdAt
          qualified
        }
      }
    }
  }
`;

export default ReturningApplicantBanner;
