import { Box, Divider, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  title: string;
};

const useStyles = makeStyles({
  divider: {
    marginLeft: 12,
  },
});

const Header = ({ title }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row">
      <Box>
        <Typography variant="h5">
          {title}
        </Typography>
      </Box>
      <Box flexGrow={1} mt={2}>
        <Divider variant="inset" classes={{ root: classes.divider }} />
      </Box>
    </Box>
  );
};

export default Header;
