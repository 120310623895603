import { Helmet } from 'react-helmet';
import { Container, useTheme } from '@mui/material';

import EditContractTemplate from '../modules/EditContract/EditContractTemplate';

const EditContractTemplatePage = (): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Edit Contract Template | Hearth</title>
      </Helmet>
      <Container
        maxWidth="xl"
        style={{ backgroundColor: theme.palette.common.basic100 }}
      >
        <EditContractTemplate />
      </Container>
    </>
  );
};

export default (): JSX.Element => <EditContractTemplatePage />;
