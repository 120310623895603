import { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CreateOutlined } from '@mui/icons-material';
import { useField } from 'formik';

import { Button, ButtonBase, Input } from 'common/components/material-ui';
import { SubmitButton, LogoUploadField } from 'common/components/formik';
import { InformationTooltip } from 'contractor/components/widgets';
import { useLogEvent } from 'common/hooks';

import {
  useEditContractTemplate,
  useEditContractTemplateUIStates,
} from '../useEditContractTemplate';
import EditInputField from './EditInputField';
import EditAddressField from './EditAddressField';
import CustomChecklist from './CustomChecklist';
import TextEntryList from './TextEntryList';
import DescriptionOfServicesTooltip from './DescriptionofServicesTooltip';

const useStyles = makeStyles(theme => ({
  sidebarStyles: {
    borderRadius: 16,
    border: '1px solid',
    borderColor: theme.palette.common.basic500,
    color: theme.palette.common.basic1100,
    backgroundColor: theme.palette.common.basic100,
    marginLeft: 36,
    flex: 1,
    maxWidth: 500,
  },
  sectionDivider: {
    marginBottom: '32px',
  },
  fieldDivider: {
    margin: '0px 24px',
  },
  sectionHeader: {
    backgroundColor: theme.palette.common.primary100,
    padding: '16px 24px',
  },
  contractSectionHeader: {
    backgroundColor: theme.palette.common.primary100,
    padding: '4px 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  paymentField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px 16px 32px',
  },
  removePayment: {
    color: theme.palette.common.danger700,
    padding: 0,
  },
  addPayment: {
    color: theme.palette.common.primary500,
    // negative top margin to take into account top padding for button
    margin: '-8px 0px 16px 16px',
    padding: '8px 16px',
    borderRadius: 4,
  },
  termsOfUse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 20px 32px 24px',
  },
  inputBox: {
    padding: '24px 24px 16px 24px',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  saveBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '24px',
    boxShadow: '0px -4px 3px rgba(0, 0, 0, 0.08)',
  },
  editButton: {
    padding: '12px 20px',
    borderRadius: 4,
  },
  saveButton: {
    backgroundColor: theme.palette.common.primary500,
    color: theme.palette.common.basic100,
    padding: '12px 48px',
    borderRadius: 4,
  },
  darkDivider: {
    background: theme.palette.common.basic900,
    margin: '0 16px',
  },
}));

const EditContractSidebar = (): JSX.Element | null => {
  const history = useHistory();
  const itly = useLogEvent();
  const {
    organization,
    refetch,
    contractTemplate: {
      template,
    },
  } = useEditContractTemplate();

  const { setEditTosModalOpen, editingChecklist, editingTextEntryList } =
    useEditContractTemplateUIStates();

  const [{ value: payments }, , { setValue: setPayments }] =
    useField<number>('defaultNumberOfPayments');
  const [{ value: serviceDescription }, , { setValue: setServiceDescription }] =
    useField<string>('serviceDescription');

  const [editBusinessName, setEditBusinessName] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editBusinessLicense, setEditBusinessLicense] = useState(false);
  const [editBusinessNumber, setBusinessNumber] = useState(false);
  const [descriptionOfServices, setDescriptionOfServices] = useState<string>(serviceDescription);

  const [prevDescription, setPrevDescription] = useState(descriptionOfServices);

  const onDescriptionOfServicesBlur = useCallback(() => {
    if (descriptionOfServices !== prevDescription) {
      itly.editDescriptionInContractTemplate();
      setServiceDescription(descriptionOfServices);
      setPrevDescription(descriptionOfServices);
    }
  }, [descriptionOfServices, prevDescription]);

  // if the table has a footer (as it does in contracts v2), then
  // we want to render one fewer row in the edit payments section
  const templateNodes = useMemo(() => template?.body?.nodes, [template]);

  const paymentsInList = useMemo(() => {
    if (!templateNodes) return null;
    const paymentTableNode = templateNodes.find(node => node.id === 'payment-table-with-total');
    if (!paymentTableNode) return payments;
    return payments - 1;
  }, [templateNodes, payments]);

  const checklistInTemplate = useMemo(() => {
    if (!templateNodes) return false;
    const checklistNode = templateNodes.find(node => node.id === 'service-static-checkboxes');
    return Boolean(checklistNode);
  }, [templateNodes]);

  const detailsListInTemplate = useMemo(() => {
    if (!templateNodes) return false;
    const detailsList = templateNodes.find(node => node.id === 'description-detail-table');
    return Boolean(detailsList);
  }, [templateNodes]);

  const editingHeader = useMemo(() =>
    Boolean(editBusinessName || editAddress || editBusinessLicense),
  [editBusinessName, editAddress, editBusinessLicense]);

  const classes = useStyles();

  if (!organization || !paymentsInList) return null;

  const disableSubmit = editingChecklist || editingTextEntryList || editingHeader;

  return (
    <Box className={classes.sidebarStyles}>
      <Box m={3}>
        <Typography variant="h3">
          Edit your contract template
        </Typography>
      </Box>
      <Box className={classes.sectionDivider}>
        <Divider />
      </Box>
      <Box className={classes.sectionHeader}>
        <Typography variant="h5">
          Contract header
        </Typography>
        <InformationTooltip
          modalTitle="Contract header"
          modalText="Information you edit here will be reflected throughout your organization."
          color="primary500"
        />
      </Box>
      <EditInputField
        open={editBusinessName}
        setOpen={setEditBusinessName}
        name="companyName"
        placeHolder="Company name"
      />
      <Divider classes={{ root: classes.fieldDivider }} />
      <EditAddressField
        open={editAddress}
        setOpen={setEditAddress}
      />
      <Divider classes={{ root: classes.fieldDivider }} />
      <EditInputField
        open={editBusinessNumber}
        setOpen={setBusinessNumber}
        name="businessPhone"
        placeHolder="Business Phone"
      />
      <Divider classes={{ root: classes.fieldDivider }} />
      <EditInputField
        open={editBusinessLicense}
        setOpen={setEditBusinessLicense}
        name="businessLicenseNumber"
        prefix="License #"
      />
      <Divider classes={{ root: classes.fieldDivider }} />
      <Box p={2}>
        <LogoUploadField
          name="includeLogo"
          label="Show logo on contract"
          logoUrl={organization.logoUrl}
          refetch={refetch}
        />
      </Box>
      <Box mt={3}>
        <Box className={classes.sectionHeader} display="flex" alignItems="center">
          <Typography variant="h5">
            Description of the Services
          </Typography>
          <Box mr={1.5} />
          <DescriptionOfServicesTooltip />
        </Box>
        <Box py={3} px={2}>
          <Input
            // using the name of the actual field here causes too-frequent rerenders
            // this approach updates the contract preview only when the user is finished typing
            name="descriptionOfServices"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDescriptionOfServices(e.target.value)
            }
            label=""
            value={descriptionOfServices}
            fullWidth
            InputProps={{
              multiline: true,
              rows: 6,
              onBlur: onDescriptionOfServicesBlur,
              placeholder: '(Optional) Description will go here',
            }}
          />
        </Box>
        {(checklistInTemplate || detailsListInTemplate) &&
          <Divider classes={{ root: classes.darkDivider }} />
        }
        {checklistInTemplate && <CustomChecklist />}
        {detailsListInTemplate && <TextEntryList />}
        <Box className={classes.sectionHeader}>
          <Typography variant="h5">
            Payment section
          </Typography>
        </Box>
        <Box mt={3} />
        {[...Array(paymentsInList).keys()].map(i => (
          <>
            <Box className={classes.paymentField}>
              <Typography variant="body2">
                Payment {i + 1}
              </Typography>
              {i !== 0 && i === (paymentsInList - 1) &&
                <Button
                  variant="text"
                  className={classes.removePayment}
                  onClick={() => {
                    setPayments(payments - 1);
                    itly.removePaymentInContractTemplate({
                      numberOfPayments: payments - 1,
                    });
                  }}
                >
                  <Typography variant="subtitle2">
                    Remove
                  </Typography>
                </Button>
              }
            </Box>
          </>
        ))}
      </Box>
      {payments < 10 &&
        <ButtonBase
          component="button"
          className={classes.addPayment}
          onClick={() => {
            setPayments(payments + 1);
            itly.addPaymentInContractTemplate({
              numberOfPayments: payments + 1,
            });
          }}
        >
          <Typography variant="subtitle2">
            + Add another payment
          </Typography>
        </ButtonBase>
      }
      <Box className={classes.sectionHeader}>
        <Typography variant="h5">
          Terms of use section
        </Typography>
      </Box>
      <Grid container direction="row" className={classes.termsOfUse}>
        <Typography variant="caption">
          Terms of use text
        </Typography>
        <Box display="flex">
          <ButtonBase
            component="button"
            className={classes.editButton}
            onClick={() => setEditTosModalOpen(true)}
          >
            <Box color="common.primary500">
              <CreateOutlined fontSize="large" />
            </Box>
          </ButtonBase>
        </Box>
      </Grid>
      <Box className={classes.saveBox}>
        <Box
          color="common.basic900"
          mr={4}
        >
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={() => {
              history.push('/dashboard/tools/contract-template');
              // if the user has unsaved changes, a Prompt pops that blocks navigation --
              // we want to log this event only if the navigation occurs
              if (history.location.pathname === '/dashboard/tools/contract-template') {
                itly.cancelEditingContractTemplate();
              }
            }}
          >
            <Typography variant="subtitle2">
              Cancel
            </Typography>
          </Button>
        </Box>
        <SubmitButton
          variant="contained"
          buttonType="common.primary"
          disabled={disableSubmit}
        >
          Save
        </SubmitButton>
      </Box>
    </Box>
  );
};

export default EditContractSidebar;
