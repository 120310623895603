import {
  createPromiseAction,
  injectId,
  createCRUDAction,
} from 'bentoo/promiseAction';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { apiPromiseAction } from 'promise-action';
import { gql, FetchPolicy } from '@apollo/client';

import * as api from 'common/api/contractorApi';
import { idSelector } from 'contractor/selectors/contractor';
import { Contractor } from 'contractor/types';
import { errorsFromApiValidations } from 'common/utils/authErrors';
import { AppState } from 'stores';
import client from 'common/graphql/apolloClient';
import {
  FetchContractorQueryVariables,
  FetchContractorQuery,
  RefreshDataQuery,
  RefreshDataQueryVariables,
} from 'types';
import * as session from 'common/utils/session';

import { fetchArticles } from './contentful';
import { FULL_ORGANIZATION_FRAGMENT } from './organization';

const UPDATE = 'Contractor/UPDATE';
const CONTRACTOR_SIGNUP = 'Contractor/CONTRACTOR_SIGNUP';
const SEND_CONTRACTOR_APP_LINK = 'Contractor/SEND_CONTRACTOR_APP_LINK';
const CONTRACTOR_RESET_PASSWORD = 'Contractor/CONTRACTOR_RESET_PASSWORD';
const EDIT_PASSWORD = 'Contractor/EDIT_PASSWORD';
const COMMON_RESET_PASSWORD = 'Contractor/COMMON_RESET_PASSWORD';
const SEND_BANNER_INSTALL_EMAIL = 'Contractor/SEND_BANNER_INSTALL_EMAIL';
const DEACTIVATE_CONTRACTOR = 'Contractor/DEACTIVATE_CONTRACTOR';
const SUBMIT_QUICK_POLL = 'Contractor/SUBMIT_QUICK_POLL';
const SEND_HOMEOWNER_EMAIL = 'Contractor/SEND_EMAIL';

const FULL_CONTRACTOR_FRAGMENT = gql`
  fragment FullContractor on Contractor {
    affiliateReferralCode
    affiliateUrl
    appVersion
    createdAt
    currentDeviceId
    email
    emailNotifications
    firstName
    firstVisit
    hearthUid
    id
    lastName
    manualEntry
    onboarded
    onboardedAt
    passwordSet
    phoneNumber
    profileCompletedAt
    pushNotifications
    role
    demoMode
    demoModeSetAt
    signInCount
    textNotifications
    trainingScheduled
    trainingCompleted
    trainingScheduledAt
    trainingTime
    urlPath
    weeklyQualifiedVolume
    emailTemplateId
    textTemplateId
    calendlyCancelLink
    featureFlags {
      id
    }
    needsMoreTraining
    trainingVideoWatchedAt
    hasSeenGraduationScreen
    vertical
    tryPaymentsSeen
    hidePaymentsTab
    admin
    enterpriseAdmin
    webinarRedirectLink
  }
`;

const REFRESH_DATA = gql`
  query RefreshData {
    contractor {
      id
      ...FullContractor
    }
    organization {
      id
      ...FullOrganization
    }
    featureFlags {
      id
    }
  }
  ${FULL_ORGANIZATION_FRAGMENT},
  ${FULL_CONTRACTOR_FRAGMENT}
`;

const FETCH_CONTRACTOR = gql`
  query FetchContractor {
    contractor {
      id
      ...FullContractor
    }
  }
  ${FULL_CONTRACTOR_FRAGMENT}
`;

type FetchPayload = NonNullable<FetchContractorQuery['contractor']> & {
  id: number;
  featureFlags: string[];
}

export interface Fetch extends Action {
  type: 'Contractor/FETCH_SUCCESS';
  payload: FetchPayload;
}

export const fetch = () => client.query<FetchContractorQuery, FetchContractorQueryVariables>({
  query: FETCH_CONTRACTOR,
  fetchPolicy: 'network-only',
});

export const apolloRefreshData = (fetchPolicy?: FetchPolicy) =>
  client.query<RefreshDataQuery, RefreshDataQueryVariables>({
    query: REFRESH_DATA,
    fetchPolicy: fetchPolicy || 'network-only',
  });

export interface Update extends Action {
  type: 'Contractor/UPDATE_SUCCESS';
  payload: Contractor;
}
export const update = injectId(idSelector, createCRUDAction(api.update, UPDATE));

export const sendContractorAppLink =
  createPromiseAction(api.sendMobileAppLink, SEND_CONTRACTOR_APP_LINK);

export const sendHomeownerEmail = createPromiseAction(api.sendEmail, SEND_HOMEOWNER_EMAIL);

export const sendBannerInstallEmail =
  createPromiseAction(api.sendBannerInstallEmail, SEND_BANNER_INSTALL_EMAIL);

type RefreshContractor = (dispatch: Dispatch, getState: () => AppState) => Promise<void>;
export const refreshContractor =
  (): RefreshContractor => async (dispatch) => {
    const { data } = await fetch();
    const contractor = data?.contractor;

    if (contractor) {
      dispatch({
        type: 'Contractor/FETCH_SUCCESS',
        payload: {
          ...contractor,
          id: parseInt(contractor.id, 10),
        },
      });
    }
  };

type RefreshData = (dispatch: Dispatch, getState: () => AppState) => Promise<void>;
export const refreshData =
  (fetchPolicy?: FetchPolicy): RefreshData =>
    async (dispatch, getState) => {
      const state = getState();
      if (!state.contractor.isLoggedIn) {
        return;
      }

      const actions = bindActionCreators({
        fetchArticles,
      }, dispatch);

      const values = await Promise.all([
        apolloRefreshData(fetchPolicy),
        actions.fetchArticles(),
      ]);

      const contractorData = values[0].data?.contractor;
      const orgData = values[0].data?.organization;
      const featureFlags = values[0].data?.featureFlags;

      if (orgData && contractorData && featureFlags) {
        session.identify({
          userType: 'contractor',
          organizationId: orgData.id,
          contractorId: contractorData.id,
          featureFlags: featureFlags.map(({ id }) => id),
          firstName: contractorData.firstName,
          lastName: contractorData.lastName,
          email: contractorData.email,
        });
      }

      if (contractorData) {
        dispatch({
          type: 'Contractor/FETCH_SUCCESS',
          payload: {
            ...contractorData,
            id: parseInt(contractorData.id, 10),
            featureFlags: contractorData.featureFlags.map(data => data.id),
          },
        });
      }

      if (orgData) {
        const orgFlags = orgData.featureFlags.map(data => data.id);
        const subscriptionFlags = orgData.planFeatureFlags.map(data => data.id);
        dispatch({
          type: 'Organization/FETCH_SUCCESS',
          payload: {
            ...orgData,
            id: parseInt(orgData.id, 10),
            seats: orgData.totalSeats,
            seatsLeft: orgData.totalSeats - orgData.usedSeats,
            featureFlags: [...orgFlags, ...subscriptionFlags],
          },
        });
      }
    };

export const contractorSignup = createPromiseAction(api.contractorSignup, CONTRACTOR_SIGNUP, {
  // We want any 'member_invite' errors to show up un-normalized
  normalizeValidations: (errors: any) => {
    const normalizedErrors: { email?: string } = {};
    if (errors.memberInvite) {
      normalizedErrors.email = errors.memberInvite;
    }
    return {
      ...normalizedErrors,
      ...errorsFromApiValidations(errors),
    };
  },
});

export interface ResetPassword extends Action {
  type: 'Contractor/CONTRACTOR_RESET_PASSWORD';
  payload: null;
}
export const contractorResetPassword =
  createPromiseAction(api.resetPassword, CONTRACTOR_RESET_PASSWORD);

export const editPassword = createCRUDAction(api.editPassword, EDIT_PASSWORD);

export const commonResetPassword = createCRUDAction(api.commonResetPassword, COMMON_RESET_PASSWORD);

export const deactivateContractor =
  createPromiseAction(api.deactivateContractor, DEACTIVATE_CONTRACTOR);

export const submitQuickPoll = createPromiseAction(api.submitQuickPoll, SUBMIT_QUICK_POLL);

export type DispatchActions = Fetch | Update | ResetPassword;

// Actions

export const claimGiftCard =
  apiPromiseAction
    .create(api.claimGiftCard)
    .normalizeResponse(({ data }) => data);

export const sendContactEmail = apiPromiseAction.create(api.sendContactEmail);

export const uploadInvoiceAttachment =
  apiPromiseAction
    .create(api.uploadInvoiceAttachment)
    .normalizeResponse(({ data }) => data);
