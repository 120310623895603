import { Box, Typography } from '@mui/material';

type Props = {
  image: {
    src: string;
    alt: string;
  };
  title: string;
  body: string;
};

const SectionHeader = ({ title, body, image }: Props): JSX.Element => (
  <Box display="flex">
    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
      <img
        src={image.src}
        alt={image.alt}
        style={{
          margin: '0 40px',
          maxHeight: 100,
        }}
      />
    </Box>
    <Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Typography variant="h2" gutterBottom>
          <Box color="common.success500">
            {title}
          </Box>
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <Typography variant="h3" gutterBottom>
          <Box color="common.success500">
            {title}
          </Box>
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Typography variant="body1">
          {body}
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <Typography variant="body2">
          {body}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default SectionHeader;
