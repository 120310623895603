import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Link } from 'common/components/material-ui';
import constants from 'common/utils/constants';

const BillingInformation = (): JSX.Element => {
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'billing' });
  });

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Billing Information
      </Typography>
      <Box my={3}>
        <Typography variant="body1" color="textPrimary">
          To change your billing information, please contact our customer success team at{' '}
          <Box display="inline" color="common.primary500">
            <Link
              variant="body1"
              color="inherit"
              href={`tel:${constants.supportNumber}`}
            >
              {constants.supportNumberDisplay}
            </Link>
          </Box> or{' '}
          <Box display="inline" color="common.primary500">
            support@gethearth.com
          </Box>.
        </Typography>
      </Box>
    </Box>
  );
};

export default BillingInformation;
