import { useState } from 'react';
import * as React from 'react';
import { Box } from '@mui/material';
import { ExpandMoreSharp, ExpandLessSharp } from '@mui/icons-material';

import Note, { Props as NoteProps } from './Note';

export type ExpandableNoteProps = {
  children?: React.ReactNode;
  defaultOpen?: boolean;
} & NoteProps;

const ExpandableNote = ({
  children,
  defaultOpen = false,
  ...otherProps
}: ExpandableNoteProps): JSX.Element => {
  const [isOpen, setOpen] = useState(defaultOpen);

  const chevron = (
    <Box display="flex" alignItems="center">
      {isOpen ?
        <ExpandLessSharp fontSize="large" alignmentBaseline="central" /> :
        <ExpandMoreSharp fontSize="large" />
      }
    </Box>
  );

  return (
    <Box
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <Note
        expandingChevron={chevron}
        {...otherProps}
      >
        {children && isOpen &&
          <Box onClick={e => e.stopPropagation()}>
            {children}
          </Box>
        }
      </Note>
    </Box>
  );
};

export default ExpandableNote;
