import { SvgIconProps } from '../Icon';

const TouchScreenIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path d="M23 0H9C7.62 0 6.5 1.12 6.5 2.5V21.5C6.5 22.88 7.62 24 9 24H23C24.38 24 25.5 22.88 25.5 21.5V2.5C25.5 1.12 24.38 0 23 0ZM16 23C15.17 23 14.5 22.33 14.5 21.5C14.5 20.67 15.17 20 16 20C16.83 20 17.5 20.67 17.5 21.5C17.5 22.33 16.83 23 16 23ZM23.5 19H8.5V3H23.5V19Z" fill={color} />
    <path d="M12.7552 17.8049L13.5333 18.3258V17.3894V13.4001C13.5333 12.2042 14.5041 11.2334 15.7 11.2334C16.8959 11.2334 17.8667 12.2042 17.8667 13.4001V17.3894V18.3258L18.6448 17.8049C20.0682 16.8521 21 15.2379 21 13.4001C21 10.468 18.6321 8.1001 15.7 8.1001C12.7679 8.1001 10.4 10.468 10.4 13.4001C10.4 15.2379 11.3318 16.8521 12.7552 17.8049ZM18.1107 19.3001H17.8V13.4001C17.8 12.2386 16.8615 11.3001 15.7 11.3001C14.5385 11.3001 13.6 12.2386 13.6 13.4001V24.2395C13.4867 24.2156 13.3774 24.1925 13.2721 24.1702C11.9639 23.894 11.2527 23.7438 10.8551 23.6623C10.3879 23.5665 10.3156 23.5561 10.1853 23.5561C9.7126 23.5561 9.28931 23.7543 8.98911 24.0545L8.98911 24.0545L8.9869 24.0568L8.14423 24.9101L7.79511 25.2636L8.14645 25.615L13.4158 30.8843C13.7934 31.262 14.3243 31.5001 14.9 31.5001H22.1427C23.2121 31.5001 24.0223 30.7101 24.1731 29.7093L24.1731 29.7093L24.1737 29.7052L24.9736 24.0841C24.9742 24.0804 24.9747 24.0766 24.9753 24.0726C24.9848 24.0067 25 23.9007 25 23.8001C25 22.9409 24.4788 22.189 23.7379 21.8734L18.9095 19.4698L18.8935 19.4619L18.877 19.4551C18.6418 19.3582 18.3853 19.3001 18.1107 19.3001Z" fill={color} stroke="white" />
  </svg>
);

export default TouchScreenIcon;
