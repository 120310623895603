import { DeepReadonly } from 'utility-types';

import { Organization } from 'contractor/types';
import { Actions } from 'common/actions';
import { decodeToken } from 'common/utils/oauth';

export type OrganizationState = DeepReadonly<{
  id?: number;
  organization: Organization | {};
}>

const initialState: OrganizationState = {
  id: undefined,
  organization: {},
};

export default function OrganizationStateReducer(
  state = initialState,
  action: Actions,
): OrganizationState {
  switch (action.type) {
  case 'Auth/LOGOUT_SUCCESS':
    return initialState;
  case 'Auth/REFRESH_TOKEN_SUCCESS':
  case 'Auth/LOGIN_SUCCESS': {
    const token = decodeToken(action.payload.accessToken);
    return {
      ...state,
      id: token.user.organizationId,
    };
  }
  case 'Organization/FETCH_SUCCESS':
  case 'Organization/UPDATE_SUCCESS':
    return {
      ...state,
      id: action.payload.id,
      organization: action.payload,
    };

  default:
    return state;
  }
}
