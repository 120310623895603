import { useEffect } from 'react';
import constate from 'constate';
import { format } from 'date-fns';
import { useLocalStorage, useList } from 'react-use';
import { useSelector } from 'react-redux';
import { CamelCasedProperties, IterableElement } from 'type-fest';
import { decamelizeKeys } from 'humps';

import { isCsUserSelector } from 'common/selectors/user';
import { ampli, Ampli, EventOptions } from 'ampli';
import * as session from 'common/utils/session';
import { logoutPendingSelector } from 'contractor/selectors/contractor';

type Log = {
  timestamp: string;
  eventName: string;
  properties?: Record<string, unknown>;
};

const useLogger = () => {
  const [logs, setLogs] = useLocalStorage<Log[]>('analytics-logs', []);
  const [localLogs, { insertAt }] = useList<Log>(logs);
  const isLoggingOut = useSelector(logoutPendingSelector);
  const isCsUser = useSelector(isCsUserSelector);

  useEffect(() => {
    ampli.addEventMiddleware((payload, next) => {
      insertAt(
        0,
        {
          timestamp: format(Date.now(), 'pp'),
          eventName: payload.event.event_type,
          properties: payload.event.event_properties,
        },
      );
      next(payload);
    });
  }, [insertAt]);

  useEffect(() => {
    // disable amplitude tracking for CS user
    ampli.client?.setOptOut(isCsUser);
  }, [isCsUser]);

  useEffect(() => {
    setLogs(localLogs);
  }, [localLogs, setLogs]);

  useEffect(() => {
    if (isLoggingOut) {
      setLogs([]);
    }
  }, [isLoggingOut, setLogs]);

  return localLogs;
};

type CamelizePropertiesArg<T> =
T extends (properties: infer Properties, options?: EventOptions) => void ?
  (
    properties: Properties extends undefined ?
      CamelCasedProperties<Properties> :
      CamelCasedProperties<Properties> | undefined,
    options?: EventOptions
  ) => void :
  T;

const nonEventFunctionNames = [
  'client' as const,
  'load' as const,
  'identify' as const,
  'setGroup' as const,
  'track' as const,
  'addEventMiddleware' as const,
];

type NonEventFunctionName = IterableElement<typeof nonEventFunctionNames>;
type Event = Exclude<keyof Ampli, NonEventFunctionName>;

type LogEvent = {
  [T in Event]: NonNullable<Parameters<Ampli[T]>[0]> extends EventOptions ?
    Ampli[T] :
    CamelizePropertiesArg<Ampli[T]>
};

function isEventFunctionName(name: keyof Ampli): name is Event {
  return !(nonEventFunctionNames as string[]).includes(name);
}

// event functions is always of types:
// (1) (properties: Properties, options?: EventOptions) => void;
// (2) (options?: EventOptions) => void;
// this added a decamelization of the properties before we passed it to ampli
export const logEvent =
  (Object.getOwnPropertyNames(Object.getPrototypeOf(ampli)) as (keyof Ampli)[])
    .filter<Event>(isEventFunctionName)
    .reduce<LogEvent>(
    (accum, eventName) => ({
      ...accum,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [eventName]: (...args: any[]) => {
        if (!session.EVENT_TRACKING_ENABLED) return;
        const newArgs = args.map((arg, index) => (
          index === 0 ?
            decamelizeKeys(arg) :
            arg
        ));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ampli[eventName](...newArgs);
      },
    }
    ), {} as LogEvent);

const useLogEvent = (): LogEvent => logEvent;

export default useLogEvent;

export const [LogsProvider, useLogs] = constate(useLogger);
