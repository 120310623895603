import { useCallback, useMemo, useState } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import constate from 'constate';
import { sentenceCase } from 'change-case';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';

import { useSnack } from 'common/utils/snackCart';
import { ButtonBase } from 'common/components/material-ui';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';
import {
  useGetCustomizedInvoiceTemplateInfoQuery,
  CustomizedInvoiceTemplateAttributes,
  useCreateInvoiceTemplateMutation,
  OrganizationAttributes,
  useUpdateOrganizationInvoiceTemplateSettingsMutation,
} from 'types';
import userFeatureFlags from 'common/graphql/featureFlags';
import { CustomInvoiceProps } from '../CustomInvoice/CustomInvoice';

export const CUSTOMIZE_INVOICE_FRAGMENTS = gql`
  fragment OrganizationTemplateInfo on Organization {
    id
    companyName
    businessEmail
    businessPhone
    fullAddress
    facebookUrl
    twitterUrl
    linkedinUrl
    instagramUrl
    website
    logoUrl
    kycBusiness {
      id
      url
    }
  }

  fragment InvoiceCustomizationSettings on CustomizedInvoiceTemplate {
    id
    templateType
    primaryColor
    background
    logo
    website
    signatureMessage
    socialLinks {
      facebookUrl
      twitterUrl
      instagramUrl
      linkedinUrl
    }
  }

  fragment ContractorTemplateInfo on Contractor {
    id
    role
    fullName
    customSettings {
      paymentsApprovalModalSeen
    }
    customizedInvoiceTemplates {
      totalCount
    }
    activeCustomizedInvoiceTemplate {
      id
      ...InvoiceCustomizationSettings
    }
  }
`;

export const GET_CUSTOM_INVOICE_TEMPLATE_INFO = gql`
  query GetCustomizedInvoiceTemplateInfo {
    contractor {
      id
      ...ContractorTemplateInfo
    }
    organization {
      id
      ...OrganizationTemplateInfo
    }
  }
  ${CUSTOMIZE_INVOICE_FRAGMENTS}
`;

export const UPDATE_ORGANIZATION_INVOICE_TEMPLATE_SETTINGS = gql`
  mutation UpdateOrganizationInvoiceTemplateSettings(
    $attributes: OrganizationAttributes!
  ) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          ...OrganizationTemplateInfo
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${CUSTOMIZE_INVOICE_FRAGMENTS}
`;

export const CREATE_CUSTOM_INVOICE_TEMPLATE = gql`
  mutation CreateInvoiceTemplate($attributes: CustomizedInvoiceTemplateAttributes!) {
    upsertCustomizedInvoiceTemplate(templateAttributes: $attributes) {
        ... on UpsertCustomizedInvoiceTemplateSuccess {
        invoiceTemplate {
          id
          ...InvoiceCustomizationSettings
        }
      }
      ... on UpsertCustomizedInvoiceTemplateFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${CUSTOMIZE_INVOICE_FRAGMENTS}
`;

export const fakeInvoiceData: CustomInvoiceProps['invoice'] = {
  invoiceNumber: 123,
  sentAt: (new Date()).toISOString(),
  dueDate: (new Date()).toISOString(),
  fullName: 'Frank Smith',
  phoneNumber: '555-555-5555',
  email: 'frank@example.com',
  description: 'Roof Project - 2456 Sibley Road',
  personalNote: 'This is where a personalized note will appear if you include one with the invoice.',
  amountRequested: 1500000,
};

const useContext = () => {
  const { data, refetch } = useGetCustomizedInvoiceTemplateInfoQuery();

  const {
    contractor,
    organization,
    activeTemplate,
  } = useMemo(() => ({
    organization: data?.organization,
    contractor: data?.contractor,
    activeTemplate: data?.contractor?.activeCustomizedInvoiceTemplate,
  }), [data?.contractor, data?.organization]);

  const [invoiceSavedModalOpen, setInvoiceSavedModalOpen] = useState(false);

  return {
    contractor,
    organization,
    activeTemplate,
    refetch,
    uiStates: {
      invoiceSavedModalOpen,
      setInvoiceSavedModalOpen,
    },
  };
};

export const [CustomizeInvoiceProvider, useCustomizeInvoice] = constate(useContext);

type Actions = {
  generateCustomInvoiceTemplate: (attributes: CustomizedInvoiceTemplateAttributes) => Promise<void>;
  updateOrganizationInvoiceTemplateInfo: (
    attributes: OrganizationAttributes,
    showSuccessMessage: boolean
  ) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTemplateValues = ({
  templateType,
  primaryColor,
  background,
  socialLinks,
  logo,
  website,
  signatureMessage,
}: any) => ({
  templateType,
  primaryColor,
  background,
  socialLinks: {
    facebookUrl: socialLinks?.facebookUrl,
    twitterUrl: socialLinks?.twitterUrl,
    instagramUrl: socialLinks?.instagramUrl,
    linkedinUrl: socialLinks?.linkedinUrl,
  },
  logo,
  website,
  signatureMessage,
});

export const useCustomizeInvoiceActions = (): Actions => {
  const { paymentsOnboarding } = useReactiveVar(userFeatureFlags);
  const { closeSnackbar } = useSnackbar();
  const {
    activeTemplate,
    contractor,
    uiStates: { setInvoiceSavedModalOpen },
  } = useCustomizeInvoice();
  const snack = useSnack();
  const history = useHistory();
  const { handleMutationError } = useGql();

  // for the invoice saved modal, we display the modal on 'save' if:
  // 1) org has the payments onboarding FF
  // 2) contractor has seen the confetti modal (i.e. has come from there)
  // 3) this is the first time they're saving an invoice
  const showInvoiceSavedModal = useMemo(() => (
    paymentsOnboarding &&
    contractor?.customSettings?.paymentsApprovalModalSeen &&
    contractor?.customizedInvoiceTemplates?.totalCount === 1
  ), [contractor, paymentsOnboarding]);

  /**
   * Action: generateCustomInvoiceTemplate
   * Create a new custom invoice template with current settings
   */

  const [createInvoiceTemplate] = useCreateInvoiceTemplateMutation();

  const generateCustomInvoiceTemplate = useCallback(async (
    attributes: CustomizedInvoiceTemplateAttributes,
  ) => {
    // don't save a new one if they clicked "save" but nothing was changed
    if (isEqual(getTemplateValues(activeTemplate), attributes)) return;

    try {
      const response = await createInvoiceTemplate({
        variables: {
          attributes,
        },
        refetchQueries: ['GetCustomizedInvoiceTemplateInfo'],
      });

      parseGql<PayloadType<typeof response, 'upsertCustomizedInvoiceTemplate'>>(
        'upsertCustomizedInvoiceTemplate',
        response,
        'UpsertCustomizedInvoiceTemplateSuccess',
        'UpsertCustomizedInvoiceTemplateFailure',
      );

      if (showInvoiceSavedModal) {
        setInvoiceSavedModalOpen(true);
      } else {
        snack.successSnack(
          'Invoice customizations saved!',
          {
            action: (
              <>
                <ButtonBase
                  onClick={() => {
                    // We need multiple pushes such that when they close
                    // out of the request flow modal, they still stay on the
                    // client index page as the close function is a goBack.
                    history.push('/dashboard/clients');
                    history.push('/dashboard/request/request-payment?source=customize_invoice', {
                      background: {
                        pathname: '/dashboard/clients',
                      },
                    });
                    closeSnackbar();
                  }}
                >
                  <Box
                    color="common.basic100"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" style={{ textDecoration: 'underline' }}>
                      Send an Invoice
                    </Typography>
                    <Box m={0.5} />
                    <ArrowForwardIcon />
                  </Box>
                </ButtonBase>
                <Box m={2} />
                <ButtonBase
                  onClick={() => closeSnackbar()}
                >
                  <CloseIcon />
                </ButtonBase>
              </>
            ),
          },
        );
      }
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    createInvoiceTemplate,
    handleMutationError,
    snack,
    closeSnackbar,
    history,
    activeTemplate,
    showInvoiceSavedModal,
    setInvoiceSavedModalOpen,
  ]);

  /**
   * Action: updateOrganizationInvoiceTemplateInfo
   * Update fields/info on the organization itself--namely the social links
   * and the logo (if one has not yet been uploaded)
   */

  const [updateOrgInvoiceTemplateInfo] =
    useUpdateOrganizationInvoiceTemplateSettingsMutation();

  const updateOrganizationInvoiceTemplateInfo = useCallback(async (
    attributes: OrganizationAttributes,
    showSuccessMessage: boolean,
  ) => {
    try {
      const response = await updateOrgInvoiceTemplateInfo({
        variables: {
          attributes,
        },
      });

      parseGql<PayloadType<typeof response, 'upsertOrganization'>>(
        'upsertOrganization',
        response,
        'UpsertOrganizationSuccess',
        'UpsertOrganizationFailure',
      );

      const successMessage =
        Object.keys(attributes)
          .map(item => sentenceCase(item))
          .join(', ');

      // don't show a success message when there is an empty string
      // (i.e. when nothing was typed but blurred, and when an input is deleted)
      if (showSuccessMessage) {
        snack.successSnack(`Your organization's ${successMessage} was updated!`);
      }
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, updateOrgInvoiceTemplateInfo, snack]);

  return {
    generateCustomInvoiceTemplate,
    updateOrganizationInvoiceTemplateInfo,
  };
};
