import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ApolloQueryResult } from '@apollo/client';
import { useFormikContext } from 'formik';

import { Dialog, Typography, Button } from 'common/components/material-ui';
import {
  Exact,
  GetStartedOrganizationTemplateInfoFragment,
  GetStartedInvoiceCustomizationSettingsFragment,
  CustomizedInvoiceTemplateAttributes,
} from 'types';
import { ColorPicker } from 'contractor/components/platform';
import CustomizedInvoicePreview from 'contractor/components/platform/CustomizedInvoicePreview';
import InvoiceLogoUpload from 'contractor/components/platform/InvoiceLogoUpload';

const DialogContentBox = styled(Box, {
  name: 'DialogContentBox',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 18,
});

const PreviewHeader = styled(Box, {
  name: 'PreviewHeader',
})({
  display: 'flex',
  justifyContent: 'flex-start',
});

const PreviewContainer = styled(Box, {
  name: 'PreviewContainer',
})(({ theme }) => ({
  height: 165,
  overflowY: 'hidden',
  border: `1px solid ${theme.palette.common.basic300}`,
  padding: '-12px 0',
}));

type Props = {
  modalOpen: boolean;
  onClose: () => void;
  activeTemplate: GetStartedInvoiceCustomizationSettingsFragment;
  organization: GetStartedOrganizationTemplateInfoFragment;
  refetch: (variables?: Partial<Exact<{ [key: string]: never; }>> | undefined) =>
  // eslint-ignore @typescript-eslint/no-explicit-any
  Promise<ApolloQueryResult<any>>;
}

const CustomizeBrandModal = ({
  modalOpen,
  onClose,
  activeTemplate,
  organization,
  refetch,
}: Props): JSX.Element | null => {
  const { submitForm } = useFormikContext<CustomizedInvoiceTemplateAttributes>();
  return (
    <Dialog
      open={modalOpen}
      onClose={onClose}
      fullScreen={false}
      showCloseButton
      maxWidth="md"
      bgColor="basic100"
    >
      <DialogContentBox>
        <Typography
          variant="h2a"
          weight="semibold"
          sx={{ px: 2 }}
        >
          Upload your logo and choose a default color for your Hearth templates
          (e.g. digital invoices).
        </Typography>
        <Box sx={{ p: '16px 0 8px 0' }}>
          <Box sx={{ m: 4 }}>
            <InvoiceLogoUpload organization={organization} refetch={refetch} />
          </Box>
          <ColorPicker />
        </Box>
        <Box sx={{ mt: 3, px: 6 }}>
          <Box sx={{ width: 720 }}>
            <PreviewHeader>
              <Typography
                variant="h2a"
                weight="semibold"
                sx={{ mb: 2 }}
              >
                Preview
              </Typography>
            </PreviewHeader>
            <PreviewContainer>
              <CustomizedInvoicePreview
                activeTemplate={activeTemplate}
                organization={organization}
              />
            </PreviewContainer>
          </Box>
        </Box>
        <Box sx={{ m: 3 }}>
          {/* Submit Button functionality was not working here
          using regular Button with explicit onClick event */}
          <Button
            onClick={submitForm}
            variant="contained"
            buttonType="common.primary"
            sx={{
              py: 1,
              px: 16,
              borderRadius: 8,
            }}
          >
            Save
          </Button>
        </Box>
      </DialogContentBox>
    </Dialog>
  );
};

export default CustomizeBrandModal;
