import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { OrganizationTheme } from 'types';

import { colors, StyleProps } from '../theme';

const useStyles = makeStyles({
  themeColor: {
    color: ({ themeColor }: StyleProps) => colors[themeColor],
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  dot: {
    height: 8,
    width: 8,
    marginLeft: 8,
    marginRight: 8,
    color: ({ themeColor }: StyleProps) => colors[themeColor],
  },
});

type Props = {
  links: string[];
  themeColor: OrganizationTheme;
}

const Links = ({ links, themeColor }: Props): JSX.Element => {
  const classes = useStyles({ themeColor });

  return (
    <>
      {links.map((link, index) => (
        <Box className={classes.themeColor}>
          <Typography variant="caption" color="inherit" data-cy="links">
            {link}
          </Typography>
          {index !== links.length - 1 &&
            <FiberManualRecordIcon className={classes.dot} />
          }
        </Box>
      ))}
    </>
  );
};

export default Links;
