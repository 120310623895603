import { Typography, Box, ButtonBase } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type Props = {
  href: string;
  fileName: string;
  action?: React.ReactNode;
}

const Attachment = ({
  href,
  fileName,
  action = null,
}: Props): JSX.Element => (
  <Box
    sx={{
      p: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      borderRadius: 8,
      border: '1px solid',
      borderColor: 'common.primary100',
    }}
  >
    <AttachFileIcon />
    <ButtonBase
      href={href}
      target="_blank"
      onClick={(event) => { event.stopPropagation(); }}
      sx={{ ml: 2 }}
    >
      <Typography variant="body1">
        {fileName}
      </Typography>
    </ButtonBase>
    {action &&
      <Box sx={{ ml: 'auto' }}>
        {action}
      </Box>
    }
  </Box>
);

export default Attachment;
