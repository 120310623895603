import { useMemo } from 'react';

import { NavigationMenu } from 'contractor/components/widgets';
import { isProduction } from 'config/app';

import useTools, {
  ToolsRoute,
  useToolsActions,
  useToolsUIStates,
} from './useTools';

type Tools = 'Marketing Tools' | 'Invoice Template' | 'Contract Template' | 'Financing Templates' | 'Website Template';

type ToolsMenuItem = {
  label: Tools;
  value: ToolsRoute;
}

const toolsNavigation: ToolsMenuItem[] = [
  {
    label: 'Marketing Tools',
    value: ToolsRoute.MarketingTools,
  },
  {
    label: 'Financing Templates',
    value: ToolsRoute.FinancingTemplate,
  },
  {
    label: 'Invoice Template',
    value: ToolsRoute.InvoiceTemplate,
  },
  {
    label: 'Contract Template',
    value: ToolsRoute.ContractTemplate,
  },
  {
    label: 'Website Template',
    value: ToolsRoute.WebsiteTemplate,
  },
];

const ToolsMenu = (): JSX.Element => {
  const { contractor } = useTools();
  const { showContractTemplateTab } = useToolsUIStates();
  const { navigateToSection } = useToolsActions();

  const visibleToolsNavItems = useMemo(() => (
    [
      ToolsRoute.MarketingTools,
      contractor?.admin && ToolsRoute.FinancingTemplate,
      contractor?.admin && ToolsRoute.InvoiceTemplate,
      showContractTemplateTab && ToolsRoute.ContractTemplate,
      contractor?.admin && !isProduction && ToolsRoute.WebsiteTemplate,
    ].filter(Boolean) as ToolsRoute[]
  ), [contractor?.admin, showContractTemplateTab]);

  const toolsNavigationOptions = toolsNavigation.filter(nav =>
    visibleToolsNavItems.includes(nav.value),
  );

  return (
    <NavigationMenu
      title="Branding"
      navigationOptions={toolsNavigationOptions}
      onClickNavigation={(route: ToolsRoute) => navigateToSection(route)}
    />
  );
};

export default ToolsMenu;
