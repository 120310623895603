import { Box, Typography } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const EditingContractBanner = (): JSX.Element => (
  <Box
    display="flex"
    alignItems="center"
    color="common.primary500"
    bgcolor="common.primary100"
    borderRadius="8px"
    p={2}
  >
    <ErrorRoundedIcon style={{ marginRight: 16 }} />
    <Typography variant="body2">
      <b>Editing your contract template only.</b> You will add customer contract details
      and modify the description of services and payment information when you send a contract.
    </Typography>
  </Box>
);

export default EditingContractBanner;
