import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';

import {
  Maybe,
  useGetStartedCreateInvoiceTemplateMutation,
  CustomizedInvoiceTemplateAttributes,
  useUpdateOrganizationLogoSettingMutation,
  OrganizationAttributes,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';
import { useSnack } from 'common/utils/snackCart';

export const getTemplateValues = ({
  templateType,
  primaryColor,
  background,
  socialLinks,
  logo,
  website,
  signatureMessage,
}: any): CustomizedInvoiceTemplateAttributes => ({
  templateType,
  primaryColor,
  background,
  socialLinks: {
    facebookUrl: socialLinks?.facebookUrl,
    twitterUrl: socialLinks?.twitterUrl,
    instagramUrl: socialLinks?.instagramUrl,
    linkedinUrl: socialLinks?.linkedinUrl,
  },
  logo,
  website,
  signatureMessage,
});

type Args = {
  activeTemplate: Maybe<Partial<CustomizedInvoiceTemplateAttributes>> | undefined;
}

export type CustomizeBrandActions = {
  generateCustomInvoiceTemplate: (attributes: CustomizedInvoiceTemplateAttributes, args: Args) =>
  Promise<void | null>;
  upsertOrganizationSettings: (attributes: OrganizationAttributes) => Promise<void>;
}

export const useCustomizeBrandActions = (): CustomizeBrandActions => {
  const { handleMutationError } = useGql();
  const snack = useSnack();
  const [createGetStartedInvoiceTemplate] = useGetStartedCreateInvoiceTemplateMutation();
  const [upsertOrganizationSettings] = useUpdateOrganizationLogoSettingMutation();

  return {
    generateCustomInvoiceTemplate: useCallback(async (
      attributes: CustomizedInvoiceTemplateAttributes,
      { activeTemplate }: Args,
    ) => {
      // don't save a new one if they clicked "save" but nothing was changed
      if (isEqual(getTemplateValues(activeTemplate), attributes)) return;

      try {
        const response = await createGetStartedInvoiceTemplate({
          variables: {
            attributes,
          },
          refetchQueries: ['GetCustomizedInvoiceTemplateInfo'],
        });

        parseGql<PayloadType<typeof response, 'upsertCustomizedInvoiceTemplate'>>(
          'upsertCustomizedInvoiceTemplate',
          response,
          'UpsertCustomizedInvoiceTemplateSuccess',
          'UpsertCustomizedInvoiceTemplateFailure',
        );

        snack.successSnack('Invoice customizations saved!');
      } catch (e) {
        handleMutationError(e, {});
      }
    }, [
      createGetStartedInvoiceTemplate,
      handleMutationError,
      snack,
    ]),
    upsertOrganizationSettings: useCallback(async (
      attributes: OrganizationAttributes,
    ) => {
      try {
        const response = await upsertOrganizationSettings({
          variables: {
            attributes,
          },
          awaitRefetchQueries: true,
          refetchQueries: ['GetStartedCustomizedInvoiceTemplateInfo'],
        });

        parseGql<PayloadType<typeof response, 'upsertOrganization'>>(
          'upsertOrganization',
          response,
          'UpsertOrganizationSuccess',
          'UpsertOrganizationFailure',
        );
      } catch (e) {
        handleMutationError(e, {});
      }
    }, [upsertOrganizationSettings, handleMutationError]),
  };
};
