import { useCallback } from 'react';

import {
  useSendHearthAppDownloadLinkMutation,
  useUpdateMarketingToolsAccessedMutation,
  CustomContractorSettingsAttributes,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';

export type GetStartedActions = {
  textMobileAppLink: () => Promise<void>;
  upsertContractorSettings: (customSettings: CustomContractorSettingsAttributes) => Promise<void>;
}

export const useGetStartedActions = (): GetStartedActions => {
  const { handleMutationError } = useGql();
  const { successSnack } = useSnack();
  const [sendHearthAppDownloadLink] = useSendHearthAppDownloadLinkMutation();
  const [upsertContractorSettings] = useUpdateMarketingToolsAccessedMutation();

  return {
    textMobileAppLink: useCallback(async (): Promise<void> => {
      try {
        const response = await sendHearthAppDownloadLink();

        parseGql<PayloadType<typeof response, 'sendHearthAppDownloadLink'>>(
          'sendHearthAppDownloadLink',
          response,
          'SendHearthAppDownloadLinkSuccess',
          'SendHearthAppDownloadLinkFailure',
        );
        successSnack('Sent successfully!');
      } catch (e) {
        handleMutationError(e, {});
      }
    }, [sendHearthAppDownloadLink]),
    upsertContractorSettings: useCallback(async (
      customSettings: CustomContractorSettingsAttributes,
    ): Promise<void> => {
      try {
        const response = await upsertContractorSettings({
          variables: {
            customSettings,
          },
          awaitRefetchQueries: true,
        });

        parseGql<PayloadType<typeof response, 'upsertContractor'>>(
          'upsertContractor',
          response,
          'UpsertContractorSuccess',
          'UpsertContractorFailure',
        );
      } catch (e) {
        analytics.trackException(e);
      }
    }, [upsertContractorSettings],
    ),
  };
};
