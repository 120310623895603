import { Box, Typography, Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { currencyFormat } from 'common/utils/stringFormatters';
import { Button } from 'common/components/material-ui';

import { useSortingOffers } from '../useSortingOffers';

const useStyles = makeStyles(theme => ({
  loanFilterButton: {
    border: `1px solid ${theme.palette.common.basic300}`,
    borderRadius: 4,
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16,
      paddingBottom: 16,
      height: '100%',
    },
    height: 48,
  },
  filterItem: {
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));

const OffersFilter = (): JSX.Element => {
  const classes = useStyles();

  const {
    offersState: {
      minOffer,
      maxOffer,
    },
    actions: {
      setLoanFilterModalOpen,
    },
  } = useSortingOffers();

  return (
    <Grid container className={classes.filterItem}>
      <Grid item xs={3} sm={6}>
        <Box mr={1}>
          <Typography variant="body2" color="textSecondary">
            FILTER LOAN AMOUNTS:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sm={6}>
        {minOffer && maxOffer &&
          <Button
            variant="outlined"
            buttonType="secondary"
            className={classes.loanFilterButton}
            onClick={() => setLoanFilterModalOpen(true)}
          >
            {currencyFormat(minOffer)} - {currencyFormat(maxOffer)}
          </Button>
        }
      </Grid>
    </Grid>
  );
};

export default OffersFilter;
