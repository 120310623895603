import { useMemo } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import constate from 'constate';

import userFeatureFlags from 'common/graphql/featureFlags';
import { useGetDashboardActionBarInfoQuery, ConnectAccountStatus, PaymentsRole } from 'types';

export const GET_DASHBOARD_ACTION_BAR_INFO = gql`
  fragment ActionBarContractor on Contractor {
    id
    admin
    paymentsAdmin
    paymentsRole
  }

  query GetDashboardActionBarInfo {
    contractor {
      id
      ...ActionBarContractor
    }
    organization {
      id
      stripeConnectAccount {
        id
        status
      }
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
`;

const useContext = () => {
  const { data, loading } = useGetDashboardActionBarInfoQuery();
  const {
    kitchenSinkBlocked,
    paymentsSystem,
    contracts,
    hearthEssentials,
  } = useReactiveVar(userFeatureFlags);

  const connectAccountStatus = useMemo(() => (
    data?.organization?.stripeConnectAccount?.status
  ), [data]);

  const contractor = useMemo(() => data?.contractor, [data]);
  const organization = useMemo(() => data?.organization, [data]);

  const showPaymentsButton = useMemo(() => {
    const notBlocked = paymentsSystem && contractor?.paymentsRole !== PaymentsRole.BLOCKED;
    const notApproved = !connectAccountStatus ||
      connectAccountStatus !== ConnectAccountStatus.FULL_ACCESS;
    const notRejected = connectAccountStatus !== ConnectAccountStatus.REJECTED;

    // show the card if:
    // 1) they have the FF
    // 2) they are not blocked

    // if they have not been approved, only show card if:
    // 1) they are a payments admin
    // 2) they were not rejected
    if (notBlocked && notApproved) return contractor?.paymentsAdmin && notRejected;

    // otherwise, show the card if not blocked and they have full access
    return notBlocked && notRejected;
  }, [connectAccountStatus, contractor, paymentsSystem]);

  const showContractsButton = useMemo(() => {
    const noContractsAccount = !organization?.contractServiceAccount?.termsAgreedAt;

    // show the card if:
    // 1) they have the FF
    // 2) they are not hearth essentials

    if (hearthEssentials) return false;

    // if terms have not yet been agreed to, only show card if:
    // 1) they are an admin

    if (contracts && noContractsAccount) return contractor?.admin;

    // otherwise, always show card for FF
    return contracts;
  }, [
    contractor?.admin,
    contracts,
    organization?.contractServiceAccount?.termsAgreedAt,
    hearthEssentials,
  ]);

  return {
    loading,
    organization,
    connectAccountStatus,
    uiStates: {
      financingBlocked: kitchenSinkBlocked,
      paymentsButtonVisible: showPaymentsButton,
      contractsButtonVisible: showContractsButton,
    },
  };
};

export const [ActionBarProvider, useActionBar] = constate(useContext);
