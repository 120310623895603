import {
  Box,
  Paper,
  Typography,
  Theme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { ButtonBase } from 'common/components/material-ui';

type Props = {
  buttonText: string;
  iconSrc: string;
  iconAlt: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 6,
    width: 200,
  },
  paper: {
    position: 'relative',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.primary500,
    },
    '&:hover $icon, &:hover $text': {
      filter: 'brightness(0) invert(1)',
    },
  },
  icon: {
    height: 20,
  },
  text: {}, // empty class needed for hover state
}));

const ButtonModule = ({
  buttonText,
  iconSrc,
  iconAlt,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.root} onClick={onClick}>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="center" justifyContent="center" p={1}>
          <img
            src={iconSrc}
            alt={iconAlt}
            className={classes.icon}
          />
          <Typography variant="subtitle2">
            <Box color="common.basic700" ml={1.25} className={classes.text}>
              {buttonText}
            </Box>
          </Typography>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default ButtonModule;
