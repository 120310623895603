import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';

// making value only a number type for now, presumably we can
// add to possible types this can be in the future
type Option = {
  label: string;
  value: number;
}

// handleChange only takes a number type for now for the same
// reason above
type Props = {
  options: Option[];
  activeColor?: Color;
  defaultIndex: number;
  handleChange: (v: number) => void;
}

const useStyles = makeStyles(theme => ({
  unselected: {
    backgroundColor: theme.palette.common.basic300,
    color: theme.palette.common.basic1100,
    border: `2px solid ${theme.palette.common.basic500}`,
    borderRadius: '4px',
  },
  selected: ({ activeColor }: Pick<Props, 'activeColor'>) => ({
    boxShadow: `0 2px 5px 0 ${theme.palette.common.basic500}`,
    backgroundColor: activeColor ? theme.palette.common[activeColor] :
      theme.palette.common.success500,
    color: theme.palette.common.basic1100,
    border: `2px solid ${activeColor ? theme.palette.common[activeColor] :
      theme.palette.common.success500}`,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: activeColor ? theme.palette.common[activeColor] :
        theme.palette.common.success500,
      border: `2px solid ${activeColor ? theme.palette.common[activeColor] : theme.palette.common.success500}`,
    },
  }),
}));

const ButtonSelect = ({
  options,
  defaultIndex,
  handleChange,
  activeColor = 'success500',
}: Props): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const classes = useStyles({ activeColor });

  const handleSelectChange = (newIndex: number): void => {
    handleChange(options[newIndex].value);
    setSelectedIndex(newIndex);
  };

  return (
    <Box display="flex" flexDirection="row">
      {options.map((item, index) => (
        <Box mx={1} key={item.label}>
          <Button
            onClick={() => handleSelectChange(index)}
            buttonType="primary"
            className={index === selectedIndex ? classes.selected : classes.unselected}
          >
            <Typography variant="body2">
              <Box color={index === selectedIndex ? 'common.basic100' : 'common.basic1100'}>
                {item.label}
              </Box>
            </Typography>
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default ButtonSelect;
