import * as React from 'react';
import { Link as RouteLink, LinkProps as RouteLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

import { Link as MuiLink } from 'common/components/material-ui';

type Props = {
  children: React.ReactNode;
} & LinkProps & RouteLinkProps;

const Link = React.forwardRef<HTMLAnchorElement, RouteLinkProps>((props, ref) => (
  <RouteLink innerRef={ref} style={{ textDecoration: 'none' }} {...props} />
));

const RouterLink = ({
  children,
  ...otherProps
}: Props): JSX.Element => (
  <MuiLink
    component={Link}
    {...otherProps}
  >
    {children}
  </MuiLink>
);

export default RouterLink;
