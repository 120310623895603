import { NavigationMenu } from 'contractor/components/widgets';

import { SettingsRoute, useSettings, useSettingsActions } from './useSettings';

type Settings =
  'Hearth Pay Settings' |
  'Hearth Financing Settings' |
  'Notification Preferences' |
  'Change Password' |
  'Billing Information' |
  'Demo Mode' |
  'Privacy Policy' |
  'API Token';

type SettingsNavItem = {
  label: Settings;
  value: SettingsRoute;
}

const settingsNav: SettingsNavItem[] = [
  {
    label: 'Notification Preferences',
    value: SettingsRoute.Notifications,
  },
  {
    label: 'Hearth Pay Settings',
    value: SettingsRoute.HearthPay,
  },
  {
    label: 'Hearth Financing Settings',
    value: SettingsRoute.Financing,
  },
  {
    label: 'Change Password',
    value: SettingsRoute.Password,
  },
  {
    label: 'Billing Information',
    value: SettingsRoute.Billing,
  },
  {
    label: 'Demo Mode',
    value: SettingsRoute.DemoMode,
  },
  {
    label: 'Privacy Policy',
    value: SettingsRoute.Privacy,
  },
  {
    label: 'API Token',
    value: SettingsRoute.ApiToken,
  },
];

const SettingsMenu = (): JSX.Element => {
  const { visibleSettingsNavItems } = useSettings();
  const { navigateToSetting } = useSettingsActions();
  const settingsNavOptions = settingsNav.filter(nav =>
    visibleSettingsNavItems.includes(nav.value),
  );

  return (
    <NavigationMenu
      title="Settings"
      navigationOptions={settingsNavOptions}
      onClickNavigation={(route: SettingsRoute) => navigateToSetting(route)}
    />
  );
};

export default SettingsMenu;
