import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  BoxProps,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import { Props as PopperProps } from 'contractor/components/widgets/Popper/Popper';
import Popover from 'contractor/components/widgets/Popper/Popover';
import { Content, Header } from 'contractor/components/widgets/Popper';
import { Button, Typography } from 'common/components/material-ui';
import userFeatureFlags from 'common/graphql/featureFlags';

import { useDashboardNavigation, useDashboardNavActions } from '../useDashboardNavigation';

export type SendNewHighlightProps = {
  open: boolean;
  children: React.ReactNode;
  anchorProps?: Partial<BoxProps>;
  placement?: string;
  popperStyles?: React.CSSProperties;
  onClose?: () => void;
} & Omit<PopperProps, 'anchorEl' | 'open'>;

const SendNewButtonHighlight = ({
  open,
  children,
  anchorProps,
  placement,
  popperStyles,
  onClose = () => {},
  ...otherProps
}: SendNewHighlightProps): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { skittles } = useReactiveVar(userFeatureFlags);
  const {
    uiStates: {
      skittlesWelcomeTourSeen,
      showSkittlesTour,
    },
  } = useDashboardNavigation();
  const { upsertContractorSettings } = useDashboardNavActions();
  const [anchorEl, setAnchorEl] = useState(children);

  const onCloseHighlight = useCallback(() => {
    // set anchor element to null / call onclose
    setAnchorEl(null);
    // mark the welcome tour seen
    upsertContractorSettings({ sendNewProductTourSeen202201: true });
    onClose();
  }, [upsertContractorSettings, onClose]);

  const [showTooltipDelay, setShowTooltipDelay] = useState(false);
  useEffect(() => {
    if (anchorEl != null) {
      setTimeout(() => setShowTooltipDelay(true), 300);
      // setTimeout ensures that anchorEl is set
      // before rendering the dependent popover(tooltips)
    }
  }, [anchorEl]);

  const highlightOpen =
    skittles &&
    open &&
    !skittlesWelcomeTourSeen &&
    showSkittlesTour &&
    showTooltipDelay;

  if (!highlightOpen) return <>{children}</>;

  return (
    <Popover
      open={highlightOpen}
      anchorEl={anchorEl}
      containerProps={anchorProps}
      overlayProps={{
        width: '100%',
        borderRadius: 16,
      }}
      BackdropProps={{
        invisible: !isMobile,
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      popperStyles={popperStyles}
      {...otherProps}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'common.basic100',
          borderRadius: 16,
          pl: 4,
          pb: 4,
          pt: 6,
          mr: 4,
        }}
      >
        <Header
          title="Take action here"
        />
        <Content
          description={
            <Typography variant="p1" weight="medium">
              {/*
                TODO(skittles): update with conditional copy for people without
                contracts, invoices, or quotes
              */}
              Use <b>+ Send new</b> to deliver financing, contracts, invoices,
              and now quotes!
            </Typography>
          }
        />
        <Box sx={{ mt: 2, pr: 4 }}>
          <Button
            fullWidth
            variant="contained"
            buttonType="common.primary"
            onClick={onCloseHighlight}
          >
            Got it!
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default SendNewButtonHighlight;
