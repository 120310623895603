import { SvgIconProps } from '../Icon';

const UnlockFilledIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 16"
    fill="none"
    {...otherProps}
  >
    <path
      d="M13 6.666H4.334V3.94a2.638 2.638 0 012.639-2.607h.087a2.637 2.637 0 012.607 2.66L11 4.006A3.974 3.974 0 007.067 0h-.06A3.96 3.96 0 003 3.933v2.733H1a.667.667 0 00-.667.667v8A.667.667 0 001 16h12a.667.667 0 00.667-.667v-8A.667.667 0 0013 6.666zm-6 6a1.333 1.333 0 110-2.665 1.333 1.333 0 010 2.665z"
      fill={color}
    />
  </svg>
);

export default UnlockFilledIcon;
