import { useMemo, useCallback } from 'react';
import constate from 'constate';
import { gql, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useGetToolsDataQuery, useUpdateTemplateMutation } from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import userFeatureFlags from 'common/graphql/featureFlags';

export const GET_TOOLS_DATA = gql`
  query GetToolsData {
    contractor {
      id
      email
      admin
      activeCustomizedInvoiceTemplate {
        id
        createdAt
      }
      sendFinancingLinkTemplate {
        id
        templateString
      }
      signatureTemplate {
        id
        templateString
      }  
    }
    organization {
      id
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
  `;

export enum ToolsRoute {
  MarketingTools = 'marketing',
  InvoiceTemplate = 'invoice-template',
  ContractTemplate = 'contract-template',
  FinancingTemplate = 'financing-templates',
  WebsiteTemplate = 'website-template',
}

/*
 * Base hook [DO NOT EXPORT]
 */

const useBaseContext = () => {
  const { contracts, hearthEssentials } = useReactiveVar(userFeatureFlags);
  const query = useGetToolsDataQuery();
  const contractor = useMemo(() => query.data?.contractor, [query.data]);
  const hasContracts = useMemo(() =>
    query.data?.organization?.contractServiceAccount?.termsAgreedAt,
  [query.data]);

  // we want to show the edit contract template tab if:
  // 1. the contracts feature flag is enabled
  // 2. the org is NOT a hearth essentials org
  // 3. the org has contracts set up and the user is an admin
  const showContractTemplateTab = useMemo(() =>
    contracts && !hearthEssentials && (hasContracts && contractor?.admin),
  [
    contracts,
    hearthEssentials,
    hasContracts,
    contractor?.admin,
  ]);

  return {
    data: {
      contractor,
      query,
    },
    uiStates: {
      showContractTemplateTab,
    },
  };
};

const [ContainerProvider, useContext, useContextUIStates] = constate(
  useBaseContext,
  o => o.data,
  o => o.uiStates,
);

type ToolsActions = {
  navigateToSection: (route: ToolsRoute) => void;
  setTemplate: (id: string, text: string) => void;
}

const useActions = (): ToolsActions => {
  const history = useHistory();

  const [updateTemplateMutation] = useUpdateTemplateMutation();

  const navigateToSection = useCallback((route: ToolsRoute) => {
    history.push(`/dashboard/tools/${route}`);
  }, [history]);

  const setTemplate = useCallback(async (id: string, text: string) => {
    // let error propagate
    const response = await updateTemplateMutation({
      variables: {
        templateId: id,
        templateString: text,
      },
    });

    parseGql<PayloadType<typeof response, 'upsertTemplate'>>(
      'upsertTemplate',
      response,
      'UpsertTemplateSuccess',
      'UpsertTemplateFailure',
    );
  }, [updateTemplateMutation]);

  return {
    navigateToSection,
    setTemplate,
  };
};

/*
 * Exports
 */

export default useContext;
export const ToolsProvider = ContainerProvider;
export const useToolsActions = useActions;
export const useToolsUIStates = useContextUIStates;
