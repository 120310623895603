import { DeepReadonly } from 'utility-types';

import { Contractor } from 'contractor/types';
import { Actions } from 'common/actions';
import { decodeToken } from 'common/utils/oauth';

export type ContractorState = DeepReadonly<{
  id?: number;
  isLoggedIn: boolean;
  logoutPending: boolean;
  contractor: Contractor | {};
}>

const initialState: ContractorState = {
  id: undefined,
  isLoggedIn: false,
  logoutPending: false,
  contractor: {},
};

export default function ContractorStateReducer(
  state = initialState,
  action: Actions,
): ContractorState {
  switch (action.type) {
  case 'Auth/LOGOUT_SUCCESS':
    return initialState;
  // @TODO(oauth): remove
  case 'Auth/LOGOUT_PENDING':
    return {
      ...state,
      logoutPending: true,
    };
  case 'Contractor/UPDATE_SUCCESS':
  case 'Contractor/FETCH_SUCCESS':
    return {
      ...state,
      id: action.payload.id,
      isLoggedIn: true,
      contractor: action.payload,
    };
  case 'Auth/REFRESH_TOKEN_SUCCESS':
  case 'Auth/LOGIN_SUCCESS': {
    const token = decodeToken(action.payload.accessToken);
    return {
      ...state,
      isLoggedIn: true,
      id: token.user.id,
      contractor: {
        id: token.user.id,
        organizationId: token.user.organizationId,
        email: token.user.email,
      },
    };
  }
  default:
    return state;
  }
}
