import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import useLogEvent from 'common/hooks/useLogEvent';

import FinancingFeedback from './FinancingFeedback';
import HearthPayFeedback from './HearthPayFeedback';
import HomeownerFeedback from './HomeownerFeedback';
import { HomeownerFeedbackParams, FeedbackType } from './HomeownerFeedback/useHomeownerFeedback';

const BaseReporting = (): JSX.Element | null => {
  const { feedbackType } = useParams<HomeownerFeedbackParams>();
  const itly = useLogEvent();

  useEffect(() => {
    if (feedbackType === FeedbackType.FINANCING) {
      itly.viewCustomerFeedback({ type: 'financing' });
    }
    if (feedbackType === FeedbackType.PAYMENTS) {
      itly.viewCustomerFeedback({ type: 'hearth_pay' });
    }
  }, [itly, feedbackType]);

  if (feedbackType) {
    switch (feedbackType) {
    case (FeedbackType.FINANCING):
      return (
        <FinancingFeedback />
      );
    case (FeedbackType.PAYMENTS):
      return (
        <HearthPayFeedback />
      );
    default: return null;
    }
  }

  return null;
};

const Reporting = (): JSX.Element | null => (
  <>
    <HomeownerFeedback />
    <BaseReporting />
  </>
);

export default Reporting;
