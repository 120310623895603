import { useRef } from 'react';
import constate from 'constate';

export const [PageProvider, usePageRefs] = constate(() => {
  const breadcrumbRef = useRef<HTMLDivElement | null>(null);

  return ({
    breadcrumbRef,
  });
});

export default usePageRefs;
