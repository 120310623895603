import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Popup } from 'common/components/modals';

export default class Tooltip extends Component {
  static propTypes = {
    placement: PropTypes.string,
    text: PropTypes.node.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    doubleRowMobile: PropTypes.bool,
  };

  static defaultProps = {
    placement: 'top-left',
    title: '',
    className: '',
    doubleRowMobile: false,
  };

  state = {
    mobileOpen: false,
  };

  openMobile = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ mobileOpen: true });
  };

  closeMobile = () => {
    this.setState({ mobileOpen: false });
  };

  renderContent() {
    const {
      text,
      title,
      doubleRowMobile,
    } = this.props;
    return (
      <div className={cx('overflow-container', { 'double-row-mobile': doubleRowMobile })}>
        {title &&
          <h5>{title}</h5>
        }
        <div className="hidden-xs normal-text">
          {text}
        </div>
        <h5 className="visible-xs">
          {text}
        </h5>
      </div>
    );
  }

  render() {
    const { className, children, placement } = this.props;
    const { mobileOpen } = this.state;

    return (
      <div className={cx(className, 'tooltip-container')}>
        <div className="tooltip-trigger" onClick={this.openMobile}>
          {children}
        </div>
        <>
          <div className={cx('hidden-xs', 'tooltip-modal', `tooltip-${placement}`)}>
            {this.renderContent()}
          </div>
          <Popup
            isOpen={mobileOpen}
            onClosePopup={this.closeMobile}
          >
            {this.renderContent()}
          </Popup>
        </>
      </div>
    );
  }
}
