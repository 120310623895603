import { useState, useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { FacebookShareLink } from 'contractor/components/widgets';
import { Tooltip } from 'common/components/material-ui';
import { Icon } from 'common/components';
import { useLogEvent } from 'common/hooks';

import RatingBox from './RatingBox';
import SortHeader from './SortHeader';
import FeedbackRow from './FeedbackRow';

import useReporting, { SortMethod, sortFeedback } from '../useReporting';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    backgroundColor: theme.palette.common.basic100,
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px',
    marginLeft: 24,
    marginRight: 40,
    minWidth: 600,
  },
}));

type Props = {
  title: string;
  tooltipText: string;
  contractorRating?: number | null;
  contractorRatingDifference?: number | null;
  hearthRating?: number | null;
  hearthRatingDifference?: number | null;
  data: {
    fullName: string;
    homeownerId: string;
    submittedAt: string;
    averageScore: number;
  }[];
  onRowClick: (arg0: string) => void;
  feedbackType: 'hearth_pay' | 'financing';
}

const FeedbackBox = ({
  title,
  tooltipText,
  contractorRating,
  contractorRatingDifference,
  hearthRating,
  hearthRatingDifference,
  data,
  onRowClick,
  feedbackType,
}: Props): JSX.Element => {
  const { contractor } = useReporting();
  const classes = useStyles();
  const itly = useLogEvent();
  const [sortMethod, setSortMethod] = useState<SortMethod>(SortMethod.MOST_RECENT);

  const sortedData = useMemo(() =>
    sortFeedback(data, sortMethod),
  [data, sortMethod]);

  const facebookQuote = `I'm rated ${contractorRating} out of 5 by my customers ` +
    'on Hearth’s platform. My customers rated their experience using Hearth ' +
    `${hearthRating} out of 5. See my Hearth page here:`;

  const facebookOnClick = () => {
    itly.shareCustomerFeedback({ type: feedbackType });
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h3" color="textPrimary">
            {title}
          </Typography>
          <Tooltip
            placement="bottom"
            title={
              <Typography variant="subtitle1">{tooltipText}</Typography>
            }
            disableHoverListener={false}
            clone
          >
            <IconButton size="large">
              <Icon
                name="info-outline"
                color="basic700"
                size={20}
              />
            </IconButton>
          </Tooltip>
        </Box>
        {contractorRating && hearthRating &&
          <FacebookShareLink
            shareUrl={contractor?.fullUrl || ''}
            shareQuote={facebookQuote}
            ctaCopy="Share your ratings on facebook"
            onClick={facebookOnClick}
          />
        }
      </Box>
      <Box mb={3} />
      <Box display="flex" flexDirection="row" mb={5}>
        {(contractorRating != null && contractorRatingDifference != null) &&
          <RatingBox
            type="Contractor"
            rating={contractorRating}
            ratingDifference={contractorRatingDifference}
          />
        }
        <Box m={1} />
        {(hearthRating != null && hearthRatingDifference != null) &&
          <RatingBox
            type="Hearth"
            rating={hearthRating}
            ratingDifference={hearthRatingDifference}
          />
        }
      </Box>
      <SortHeader
        sortMethod={sortMethod}
        onMostRecent={() => setSortMethod(SortMethod.MOST_RECENT)}
        onAlphabetical={() => setSortMethod(SortMethod.ALPHABETICAL)}
      />
      <Box m={1} />
      {sortedData.length > 0 ?
        sortedData.map(feedback => (
          <FeedbackRow
            fullName={feedback.fullName}
            submittedAt={feedback.submittedAt}
            rating={feedback.averageScore}
            onClick={() => onRowClick(feedback.homeownerId)}
          />
        )) :
        <Box my={3}>
          <Typography variant="subtitle1" align="center">
            You haven’t received any feedback yet
          </Typography>
        </Box>
      }
    </Box>
  );
};

export default FeedbackBox;
