import { gql } from '@apollo/client';

import CustomInvoice from 'contractor/modules/CustomInvoice';
import {
  OrganizationBasicBusinessInfoFragmentDoc,
  useFetchCustomInvoicePreviewQuery,
} from 'types';
import { Loader } from 'common/components/widgets';
import { CustomInvoiceProps } from 'contractor/modules/CustomInvoice/CustomInvoice';

type Props = {
  invoiceData: CustomInvoiceProps['invoice'];
}

const InvoicePreview = ({ invoiceData }: Props): JSX.Element => {
  const { data, loading } = useFetchCustomInvoicePreviewQuery();

  if (loading ||
    !data ||
    !data.contractor?.activeCustomizedInvoiceTemplate ||
    !data.organization
  ) {
    return (
      <Loader />
    );
  }

  return (
    <CustomInvoice
      settings={data.contractor?.activeCustomizedInvoiceTemplate}
      organization={data.organization}
      invoice={invoiceData}
      attachments={[]}
    />
  );
};

export default InvoicePreview;

InvoicePreview.FETCH_INVOICE_PREVIEW = gql`
  fragment ActiveInvoiceTemplate on CustomizedInvoiceTemplate {
    id
    templateType
    primaryColor
    logo
    website
    background
    signatureMessage
    socialLinks {
      facebookUrl
      twitterUrl
      linkedinUrl
      instagramUrl
    }
  }

  query FetchCustomInvoicePreview {
    organization {
      id
      kycBusiness {
        id
        url
      }
      ...OrganizationBusinessInfo
    }
    contractor {
      id
      activeCustomizedInvoiceTemplate {
        id
        ...ActiveInvoiceTemplate
      }
    }
  }
  ${OrganizationBasicBusinessInfoFragmentDoc}
`;
