import { SvgIconProps } from '../Icon';

const RequestPaymentIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 0.5H7.5L12 5V14C12 14.825 11.325 15.5 10.5 15.5H1.5C0.675 15.5 0 14.825 0 14V2C0 1.175 0.675 0.5 1.5 0.5ZM7.12841 7.60706C7.41516 7.75057 7.63638 7.90091 7.78385 8.03758C8.20169 8.41344 8.40652 8.8713 8.4229 9.38382C8.4229 9.91002 8.23446 10.3747 7.85759 10.7711C7.49709 11.1538 7.0219 11.4134 6.43201 11.5569V12.5H5.31776V11.5911C4.63774 11.4886 4.11339 11.2836 3.74471 10.9761C3.36783 10.6617 3.08927 10.1697 2.90902 9.5205L2.88444 9.44533L4.23629 9.21298L4.26086 9.28132C4.41653 9.71868 4.60497 10.033 4.82618 10.2175C5.04739 10.3815 5.35053 10.4704 5.72741 10.4704C6.10429 10.4704 6.40743 10.3747 6.65322 10.1765C6.89901 9.98519 7.01371 9.73918 7.01371 9.42483C7.01371 9.15148 6.91539 8.9328 6.71057 8.76879C6.60406 8.68679 6.44839 8.59111 6.23537 8.48861C6.02236 8.3861 5.7438 8.2631 5.41608 8.14009C4.71148 7.88041 4.25267 7.63439 4.02327 7.40888C3.66278 7.07403 3.48253 6.67084 3.48253 6.19932C3.48253 5.98064 3.53169 5.76879 3.61362 5.57745C3.69555 5.3861 3.81844 5.20843 3.9823 5.05125C4.14616 4.90091 4.34279 4.76424 4.58039 4.64806C4.79341 4.54556 5.0392 4.45672 5.30957 4.39522V3.5H6.42381V4.38838C6.82527 4.45672 7.16118 4.57289 7.42336 4.74373C7.69373 4.92825 7.9641 5.20843 8.20988 5.57745L8.25085 5.64578L7.04648 6.20615L7.00551 6.13781C6.71057 5.67995 6.33369 5.46127 5.8585 5.46127C5.56355 5.46127 5.32595 5.52961 5.13751 5.67312C4.94907 5.81663 4.85895 5.98747 4.85895 6.19932C4.85895 6.39066 4.93269 6.54783 5.08016 6.67084C5.23583 6.80068 5.56355 6.95786 6.04694 7.14237C6.48116 7.30638 6.84166 7.46355 7.12841 7.60706Z"
      fill={color}
    />
  </svg>
);

export default RequestPaymentIcon;
