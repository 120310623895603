import {
  ClientEventFragment,
  HearthEventEventTypes,
  ClientViewEventFragment,
  ClientDetailEventFragment,
} from 'types';
import { analytics } from 'common/services';
import {
  parseISO,
  formatDistanceToNowStrict,
  differenceInSeconds,
} from 'date-fns';

// TODO(refactor): replace jsdoc type with typescript type
/**
 *
 * @param {(
 * ClientEventFragment | ClientViewEventFragment | ClientDetailEventFragment
 * )} HearthActivity
 *  { eventType, eventBody, eventAt }
 * @param {boolean} [withTimestamp=false] Whether to include the timestamp with
 *  the copy using the formatDistanceToNowStrict function
 * @returns {(string | null)} Copy for a HearthEvent
 */

export const generateActivityCopy = (
  {
    eventType,
    eventBody,
    eventAt,
  }: ClientEventFragment | ClientViewEventFragment | ClientDetailEventFragment,
  withTimestamp = false,
): string | null => {
  const itemNumber =
    eventBody?.invoice_number || eventBody?.contract_number || eventBody?.quote_number;
  // first check if there is a contract or quote name; if so use that as the identifier
  let identifier = '';
  if (eventBody?.contract_name) {
    identifier = eventBody?.contract_name;
  } else if (eventBody?.quote_name) {
    identifier = eventBody?.quote_name;
  } else if (itemNumber && eventBody?.invoice_number) {
    identifier = `Invoice #${itemNumber}`;
  } else if (itemNumber && eventBody?.contract_number) {
    identifier = `Contract #${itemNumber}`;
  } else if (itemNumber && eventBody?.quote_number) {
    identifier = `Quote #${itemNumber}`;
  }
  let activityCopy: string | null = null;

  switch (eventType) {
  case HearthEventEventTypes.HOMEOWNER_CREATED:
    activityCopy = 'Client created';
    break;
  case HearthEventEventTypes.HOMEOWNER_UPDATED:
    activityCopy = 'Contact details updated';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_SENT:
    activityCopy = 'Loan form sent';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_CREATED:
    activityCopy = 'Loan form started';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_REJECTED:
    activityCopy = 'Not pre-qualified';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_QUALIFIED:
    activityCopy = 'Pre-qualified';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_CLICKED:
    activityCopy = 'Clicked loan option';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_FUNDED:
    activityCopy = 'Funded';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CREATED:
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SENT:
    activityCopy = `Payment request sent ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_MANUAL_REMINDER_SENT: {
    const mediums = (eventBody?.mediums || []) as string[];
    activityCopy = `Payment reminder
    ${mediums.length ? mediums.map(medium => medium.toLocaleLowerCase()).join(' and ') : ' '}
    sent ${identifier}`;
    break;
  }
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE_REMINDER_SENT: {
    const mediums = (eventBody?.mediums || []) as string[];
    activityCopy = `Payment overdue
      ${mediums.length ? mediums.map(medium => medium.toLocaleLowerCase()).join(' and ') : ' notice '}
      sent ${identifier}`;
    break;
  }
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_INITIATED:
    activityCopy = `Customer payment processing ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_FAILED:
    activityCopy = `Payment failed ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_COMPLETED:
    activityCopy = `Payment completed ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PARTIAL_REFUND_COMPLETED:
    activityCopy = `Partially refunded ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_REFUND_COMPLETED:
    activityCopy = `Refunded ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE:
    activityCopy = `Payment overdue ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CANCELED:
    activityCopy = `Payment canceled ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_ACTIVITY_CREATED:
    activityCopy = null;
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SCHEDULED:
    activityCopy = `Payment request scheduled ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_LEAD:
    activityCopy = 'Credit card clicked';
    break;
  case HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_SOLD:
    activityCopy = 'Credit card approved';
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SENT:
    activityCopy = `Contract sent ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_VIEWED:
    activityCopy = `Contract viewed ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SIGNED:
    activityCopy = `Contract signed ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_CANCELED:
    activityCopy = `Contract canceled ${identifier}`;
    break;
  // quote events
  case HearthEventEventTypes.HOMEOWNER_QUOTE_SENT:
    activityCopy = `Quote sent ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_VIEWED:
    activityCopy = `Quote viewed ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_APPROVED:
    activityCopy = `Quote approved ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_CHANGES_REQUESTED:
    activityCopy = `Quote changes requested ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_REVISION_SENT:
    activityCopy = `Quote revision sent ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_CANCELED:
    activityCopy = `Quote canceled ${identifier}`;
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_EXPIRED:
    activityCopy = `Quote expired ${identifier}`;
    break;
  default: {
    analytics.trackMessage('Unable to render hearth event type: ' + eventType);
    activityCopy = null;
    break;
  }
  }

  if (activityCopy && withTimestamp) {
    // There are some events that are created with a future
    // date, but this doesn't make much sense from the point
    // of an activity timeline. Therefore, making times in the
    // future default to "now."
    if (differenceInSeconds(Date.now(), parseISO(eventAt)) < 0) {
      activityCopy += ' - now';
    } else {
      activityCopy += ` - ${formatDistanceToNowStrict(parseISO(eventAt))}`;
    }
  }

  return activityCopy;
};

export const generateActivityHeaders = (
  eventType: HearthEventEventTypes,
): string => {
  let activityCopy = '';
  switch (eventType) {
  case HearthEventEventTypes.HOMEOWNER_CREATED:
    activityCopy = 'Created';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_CREATED:
    activityCopy = 'Started';
    break;
  case HearthEventEventTypes.HOMEOWNER_UPDATED:
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_MANUAL_REMINDER_SENT:
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE_REMINDER_SENT:
    activityCopy = 'Updated';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_SENT:
    activityCopy = 'Sent';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_REJECTED:
    activityCopy = 'Not pre-qualified';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_QUALIFIED:
    activityCopy = 'Pre-qualified';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_CLICKED:
    activityCopy = 'Clicked loan option';
    break;
  case HearthEventEventTypes.HOMEOWNER_APPLICATION_FUNDED:
    activityCopy = 'Funded';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_INITIATED:
    activityCopy = 'Processing';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_FAILED:
    activityCopy = 'Declined';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_COMPLETED:
    activityCopy = 'Paid';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PARTIAL_REFUND_COMPLETED:
    activityCopy = 'Partially refunded';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_REFUND_COMPLETED:
    activityCopy = 'Refunded';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE:
    activityCopy = 'Overdue';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CANCELED:
    activityCopy = 'Canceled';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SCHEDULED:
    activityCopy = 'Scheduled';
    break;
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CREATED:
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SENT:
  case HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_ACTIVITY_CREATED:
    activityCopy = 'Unpaid';
    break;
  case HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_LEAD:
    activityCopy = 'Credit card clicked';
    break;
  case HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_SOLD:
    activityCopy = 'Credit card approved';
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SENT:
    activityCopy = 'Sent';
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_VIEWED:
    activityCopy = 'Viewed';
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SIGNED:
    activityCopy = 'Signed';
    break;
  case HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_CANCELED:
    activityCopy = 'Canceled';
    break;
  // quotes events
  case HearthEventEventTypes.HOMEOWNER_QUOTE_SENT:
    activityCopy = 'Sent';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_VIEWED:
    activityCopy = 'Viewed';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_APPROVED:
    activityCopy = 'Approved';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_CHANGES_REQUESTED:
    activityCopy = 'Changes requested';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_REVISION_SENT:
    activityCopy = 'Revision sent';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_CANCELED:
    activityCopy = 'Canceled';
    break;
  case HearthEventEventTypes.HOMEOWNER_QUOTE_EXPIRED:
    activityCopy = 'Expired';
    break;
  default: {
    analytics.trackMessage('Unable to render hearth event type: ' + eventType);
    activityCopy = '';
    break;
  }
  }

  return activityCopy;
};
