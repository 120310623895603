import { apiPromiseAction, PromiseAction, DispatchAction } from 'promise-action';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import keyBy from 'lodash/keyBy';
import * as contentful from 'contentful';
import filter from 'lodash/fp/filter';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import { constantCase } from 'change-case';

import * as api from 'common/api/contentfulApi';
import { idSelector } from 'contractor/selectors/contractor';
import { AppState } from 'stores';
import { LearnCenterLifecycle } from 'types';

/*
** Actions
*/

const fetchContractorData = apiPromiseAction
  .create(api.fetchContractorData)
  .normalizeResponse(({ data }): Record<string, ArticleContractorMetadata> => (
    keyBy(data.articles, 'contentfulId')
  ));

const markHelpful = apiPromiseAction
  .create(api.markHelpful)
  .normalizeResponse(({ data }) => data);

const markRead = apiPromiseAction
  .create(api.markRead)
  .normalizeResponse(({ data }) => data);

// These are external API calls so we can wrap them with promise-action but don't need to do API
// handling

type ResourceCenterResponse = {
  articles: Record<string, Article>;
  sections: Record<string, Section>;
  lifecycles: Record<LearnCenterLifecycle, Section>;
  sectionOrder: string[];
};

type PresaleCourseResponse = {
  videos: api.Video[];
  videoOrder: string[];
}

const normalizeResourceCenterBundle =
  (collection: contentful.EntryCollection<ContentfulEntry>): ResourceCenterResponse => ({
    articles: flow(
      filter(['sys.contentType.sys.id', 'article']),
      groupBy('sys.id'),
      mapValues(([item]: contentful.Entry<ContentfulArticle>[]): Article => ({
        id: item.sys.id,
        title: item.fields.title,
        subtitle: item.fields.subtitle,
        body: item.fields.body,
        thumbnailUrl: `https:${item.fields.thumbnail.fields.file.url}`,
      })),
    )(collection.items),
    sections: flow(
      filter(['sys.contentType.sys.id', 'section']),
      groupBy('sys.id'),
      mapValues(([item]: contentful.Entry<ContentfulSection>[]): Section => ({
        title: item.fields.title,
        subtitle: item.fields.subtitle,
        articles: map('sys.id')(item.fields.articles),
      })),
    )(collection.items),
    sectionOrder: flow(
      find(['sys.contentType.sys.id', 'resourceCenterHomepage']),
      get('fields.sectionReference'),
      map('sys.id'),
    )(collection.items),
    lifecycles: flow(
      filter(['sys.contentType.sys.id', 'section']),
      groupBy('sys.id'),
      mapValues(([item]: contentful.Entry<ContentfulSection>[]): Section => ({
        title: item.fields.title,
        subtitle: item.fields.subtitle,
        articles: map('sys.id')(item.fields.articles),
      })),
      filter(item => ['Complete Lifecycle', 'Default Lifecycle', 'Fund Lifecycle', 'Qualified Lifecycle', 'No Completes Lifecycle'].includes(item.title)),
      groupBy(item => constantCase(item.title)),
      mapValues(item => item[0]),
    )(collection.items),
  });

const normalizePresaleCourse =
  (items: contentful.Entry<Partial<api.PresaleVideo & api.Course>>[]): PresaleCourseResponse => ({
    videos: flow(
      filter(['sys.contentType.sys.id', 'presale_video']),
      groupBy('sys.id'),
      mapValues(([item]: contentful.Entry<api.PresaleVideo>[]): api.Video => ({
        id: item.sys.id,
        title: item.fields.title,
        subtitle: item.fields.subtitle,
        duration: item.fields.duration,
        videoUrl: `https:${item.fields.video.fields.file.url}`,
      })),
    )(items),
    videoOrder: flow(
      find(['sys.contentType.sys.id', 'course']),
      get('fields.videoReference'),
      map('sys.id'),
    )(items),
  });

const fetchResourceCenter = new PromiseAction(api.fetchResourceCenter)
  .normalizeResponse(response => normalizeResourceCenterBundle(response));

export const fetchPresaleCourse = new PromiseAction(api.fetchPresaleCourse)
  .normalizeResponse(response => normalizePresaleCourse(response.items));

/*
** Dispatchers
*/

export const fetchContractorDataDispatcher = fetchContractorData.dispatcher(
  'Article/FETCH_CONTRACTOR_DATA_PENDING',
  'Article/FETCH_CONTRACTOR_DATA_SUCCESS',
  'Article/FETCH_CONTRACTOR_DATA_ERROR',
  { contractorId: idSelector },
);

export const fetchResourceCenterDispatcher = fetchResourceCenter.dispatcher(
  'Article/FETCH_RESOURCE_CENTER_PENDING',
  'Article/FETCH_RESOURCE_CENTER_SUCCESS',
  'Article/FETCH_RESOURCE_CENTER_ERROR',
);

export const markHelpfulDispatcher = markHelpful.dispatcher(
  'Article/MARK_HELPFUL_PENDING',
  'Article/MARK_HELPFUL_SUCCESS',
  'Article/MARK_HELPFUL_ERROR',
);

export const markReadDispatcher = markRead.dispatcher(
  'Article/MARK_READ_PENDING',
  'Article/MARK_READ_SUCCESS',
  'Article/MARK_READ_ERROR',
);

export const fetchArticles =
  (): (dispatch: ThunkDispatch<AppState, void, Action>) => Promise<[unknown, unknown]> =>
    dispatch => Promise.all([
      dispatch(fetchResourceCenterDispatcher({})),
      dispatch(fetchContractorDataDispatcher({})),
    ]);

export type DispatchActions =
  DispatchAction<typeof fetchContractorDataDispatcher> |
  DispatchAction<typeof fetchResourceCenterDispatcher> |
  DispatchAction<typeof markHelpfulDispatcher> |
  DispatchAction<typeof markReadDispatcher>;
