import { useCallback, useMemo, useEffect } from 'react';
import { useGetSetState } from 'react-use';
import constate from 'constate';
import { DeepReadonly } from 'utility-types';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import analytics from './analytics';
import logger, { LogGroup } from './logger';

type AbTestInfo = DeepReadonly<{
  tests: {
    name: string;
    description: string;
    buckets: {
      name: string;
      probability: number;
      control: boolean;
      winner: boolean;
    }[];
  }[];
  variants: {
    name: string;
    variant: string;
  }[];
  randomSeed: number;
}>;

const useBaseAbTest = ({ abTestInfo }: { abTestInfo: AbTestInfo }) => {
  const initialVariantMap: Record<string, string | undefined> = useMemo(
    () => abTestInfo.variants
      .reduce((acc, { name, variant }) => ({ ...acc, [name]: variant }), {}),
    [abTestInfo.variants],
  );
  // Keeping track of local variant on local state
  const [getVariantMap, updateVariantMap] =
    useGetSetState<Record<string, string | undefined>>(initialVariantMap);

  const location = useLocation();

  // Parse variant from query params
  useEffect(() => {
    const query = parse(location.search) as Record<string, string>;

    abTestInfo.tests.forEach((test) => {
      if (test.name in query) {
        const variant = query[test.name];

        // make sure variant exists
        if (!test.buckets.find(({ name }) => name === variant)) {
          logger.error(LogGroup.EVENT, `Unable to set variant ${variant}, variant not found`);
          return;
        }

        updateVariantMap({ [test.name]: variant });
      }
    });
  }, [abTestInfo, location.search, updateVariantMap]);

  /**
   * Randomly set variant for a test
   */
  const setRandomVariant = useCallback((testName: string) => {
    const test = abTestInfo.tests.find(({ name }) => name === testName);

    if (!test) {
      logger.error(LogGroup.EVENT, `Unable to set variant for ${testName}, test not found`);
      return null;
    }

    const randomBucket = [...test.buckets]
      .sort((a, b) => b.probability - a.probability)
      .find(bucket => (
        bucket.probability <= abTestInfo.randomSeed
      ));

    if (!randomBucket) {
      logger.error(LogGroup.EVENT, `Unable to set variant for ${testName}, seed issues`);
      return null;
    }

    updateVariantMap({ [testName]: randomBucket.name });
    logger.info(LogGroup.EVENT, `Activated ${testName} with variant ${randomBucket.name}`);

    return randomBucket.name;
  }, [abTestInfo.tests, abTestInfo.randomSeed, updateVariantMap]);

  /**
   * Get variant for a test without setting it
   */
  const getVariant = useCallback((testName: string) => {
    const test = abTestInfo.tests.find(({ name }) => name === testName);

    if (!test) {
      logger.error(LogGroup.EVENT, `Unable to get variant for ${testName}, test not found`);
      return null;
    }

    const winnerBucket = test.buckets.find(({ winner }) => winner);

    if (winnerBucket) {
      return winnerBucket.name;
    }

    const variant = getVariantMap()[testName];
    if (variant) {
      return variant;
    }

    return null;
  }, [getVariantMap, abTestInfo.tests]);

  /**
   * Get variant for a test and assign a variant if not set
   */
  const getOrSetVariant = useCallback(testName => (
    getVariant(testName) || setRandomVariant(testName)
  ), [getVariant, setRandomVariant]);

  const actions = useMemo(() => ({
    getVariant,
    getOrSetVariant,
  }), [getVariant, getOrSetVariant]);

  return actions;
};

// eslint-disable-next-line import/prefer-default-export
export const [AbTestProvider, useAbTest] = constate(useBaseAbTest);
