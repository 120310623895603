import Modal from 'react-modal';
import cx from 'classnames';

import './BottomPageBar.scss';

const BottomPageBar = ({
  isOpen = true,
  className = '',
  children,
  overlay = false,
}) => (
  <Modal
    isOpen={isOpen}
    bodyOpenClassName="bottom-page-bar-body"
    ariaHideApp={false}
    overlayClassName={cx('bottom-page-bar-overlay', { 'background-transparent': !overlay })}
    className={cx('bottom-page-bar', isOpen ? 'open' : 'closed', className)}
  >
    {children}
  </Modal>
);

export default BottomPageBar;
