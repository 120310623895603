import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Button } from 'common/components/material-ui';
import { useGetApiTokenQuery } from 'types';
import Icon from 'common/components/Icon';

import { useSettingsActions } from './useSettings';

const ApiToken = (): JSX.Element => {
  const { generateApiToken } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'api_token' });
  });

  const { data } = useGetApiTokenQuery();
  const apiToken = data?.organization?.apiToken?.delimitedPlaintextKey;
  const isGeneratable = !apiToken;

  const showGenerateApiTokenButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        buttonType="common.primary"
        onClick={generateApiToken}
      >
        Generate API Token
      </Button>
    </Box>
  );

  const showApiToken = (
    <Box>
      <Box bgcolor="common.primary100" color="common.primary500" borderRadius="8px">
        <Box display="flex" ml={1} pb={0.5} pt={0.5}>
          <Typography variant="body1">
            API Token Generated
          </Typography>
          <Icon
            name="check-circle"
            size={24}
            bgColor="primary100"
            color="primary500"
          />
        </Box>
      </Box>
      <Box bgcolor="common.basic300" py={2} px={2} mt={3} borderRadius="8px">
        <Box width="100%">
          <Typography variant="body2">
            Here is your API key.
          </Typography>
        </Box>
        <Box
          mt={2}
          px={2}
          py={1}
          bgcolor="common.basic100"
          borderRadius="9px"
          fontSize={13}
        >
          {apiToken}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        API Token
      </Typography>
      <Box my={3}>
        <Typography variant="body1" color="textPrimary">
          Create an API token to set up Zapier integrations between Hearth and other tools.
        </Typography>
      </Box>
      {
        (isGeneratable && showGenerateApiTokenButton) || (!isGeneratable && showApiToken)
      }
    </Box>
  );
};

ApiToken.GET_API_TOKEN = gql`
  query GetApiToken {
    organization {
      id
      apiToken {
        id
        delimitedPlaintextKey
      }
    }
  }
`;

export default ApiToken;
