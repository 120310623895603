import { Box, Typography } from '@mui/material';
import { string, object } from 'yup';

import useLogEvent from 'common/hooks/useLogEvent';
import constants from 'common/utils/constants';
import { Dialog, Link } from 'common/components/material-ui';
import { Form, InputField, SubmitButton } from 'common/components/formik';

type InviteMemberProps = {
  onSubmit: (emails: string[]) => Promise<void>;
  availableSeats: number;
  trainingScheduledAt?: string;
}

const InviteMembers = ({
  availableSeats,
  onSubmit,
  trainingScheduledAt,
}: InviteMemberProps): JSX.Element => {
  const itly = useLogEvent();

  return (
    <>
      <Typography variant="h5" gutterBottom>
        <Box color="common.basic1100">
          Invite team members to join Hearth
          {trainingScheduledAt && ' & get trained'}
        </Box>
      </Typography>
      <Typography variant="body2">
        <Box color="common.basic1100">
          <br />
          <strong>You have {availableSeats} sales accounts available.</strong>
          <br />
          <br />
          Enter their emails below (comma-separated) and we’ll invite them to sell with Hearth
          {trainingScheduledAt && ` and attend your training webinar on ${trainingScheduledAt}`}.
        </Box>
      </Typography>
      <Box mt={8} />
      <Form
        initialValues={{
          email: '',
        }}
        validationSchema={object().shape({
          email: string().label('Email').required(),
        })}
        onSubmit={async (values, { resetForm }) => {
          await onSubmit(values.email.split(',').map(e => e.trim()));
          itly.inviteTeamMember();
          resetForm({});
        }}
      >
        <InputField
          fullWidth
          autoComplete="off"
          name="email"
          label="Sales rep email(s)"
        />
        <Box mt={3} />
        <Box display="flex" justifyContent="flex-end">
          <SubmitButton
            variant="contained"
            buttonType="common.primary"
          >
            Send invite
          </SubmitButton>
        </Box>
      </Form>
    </>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (emails: string[]) => Promise<void>;
  availableSeats: number;
  trainingScheduledAt?: string;
}

const AddSeatModal = ({
  open,
  onClose,
  onSubmit,
  availableSeats,
  trainingScheduledAt,
}: Props): JSX.Element | null => (
  <Dialog open={open} onClose={onClose}>
    <Box py={3}>
      {availableSeats === 0 ?
        <>
          <Typography variant="h5" gutterBottom>
            <Box color="common.basic1100">
              You’re out of sales seats!
            </Box>
          </Typography>
          <Typography variant="caption">
            <Box color="common.basic1100">
              Growing your team?{'\n'}
              Please{' '}
              <Link
                variant="caption"
                color="inherit"
                href={`tel:${constants.supportNumber}`}
              >
                <Box display="inline" color="common.primary500">
                  contact customer success.
                </Box>
              </Link>
            </Box>
          </Typography>
        </> :
        <InviteMembers
          availableSeats={availableSeats}
          onSubmit={values => onSubmit(values)}
          trainingScheduledAt={trainingScheduledAt}
        />
      }
    </Box>
  </Dialog>
);

export default AddSeatModal;
