import * as React from 'react';
import {
  Box,
  Dialog as MaterialDialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogProps as MuiDialogProps,
  Paper,
  Theme,
  useMediaQuery,
  DialogContentProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

type StyleProps = {
  bgColor: Color;
  titleBgColor: Color;
  closeIconColor: Color;
  scroll: 'paper' | 'body';
  showLeftAction: boolean;
  topPadding: boolean;
  bottomPadding: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ scroll }) => ({
    maxHeight: scroll === 'paper' ? '90vh' : 'inherit',
    position: 'relative',
  }),
  closeButton: ({ closeIconColor }: StyleProps) => ({
    color: theme.palette.common[closeIconColor],
  }),
  leftAction: {
    marginLeft: 8,
    marginTop: 12,
  },
  title: ({ titleBgColor, topPadding, bottomPadding }: StyleProps) => ({
    backgroundColor: theme.palette.common[titleBgColor],
    padding: 48,
    paddingTop: topPadding ? 48 : 0,
    paddingBottom: bottomPadding ? 48 : 0,
  }),
  content: ({ bgColor }: StyleProps) => ({
    backgroundColor: theme.palette.common[bgColor],
  }),
  overlayBackground: {
    backgroundColor: 'rgba(247, 247, 247, 0.8)',
    zIndex: 1,
  },
  noPadding: {
    padding: 0,
  },
  topBar: {
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));

export type DialogProps = {
  onClose: () => void;
  children: React.ReactNode;
  dialogTitle?: React.ReactNode;
  leftActionContent?: React.ReactNode;
  overlayOpen?: boolean;
  overlayTitle?: React.ReactElement | null;
  overlayChildren?: React.ReactElement | null;
  overlayTopPadding?: number | null;
  bgColor?: Color;
  titleBgColor?: Color;
  showCloseButton?: boolean;
  contentClassName?: string;
  closeIconColor?: Color;
  topPadding?: boolean;
  bottomPadding?: boolean;
  dialogContentProps?: Partial<DialogContentProps>;
} & MuiDialogProps;

const Dialog = ({
  open,
  onClose,
  children,
  maxWidth = 'sm',
  dialogTitle,
  overlayOpen = false,
  overlayTitle = null,
  overlayChildren = null,
  overlayTopPadding = null,
  bgColor = 'basic300',
  titleBgColor = 'basic100',
  leftActionContent = null,
  showCloseButton = true,
  contentClassName,
  closeIconColor = 'basic700',
  PaperProps = {},
  scroll = 'paper',
  topPadding = true,
  bottomPadding = true,
  dialogContentProps = {},
  ...otherProps
}: DialogProps): JSX.Element => {
  const classes = useStyles({
    bgColor,
    titleBgColor,
    closeIconColor,
    scroll,
    showLeftAction: leftActionContent != null,
    topPadding,
    bottomPadding,
  });
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <MaterialDialog
      fullScreen={isMobile}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      PaperProps={{ classes, ...PaperProps }}
      scroll={scroll}
      {...otherProps}
    >
      <Box className={classes.topBar}>
        {showCloseButton &&
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        }
      </Box>
      {leftActionContent &&
        <Box className={classes.leftAction}>
          {leftActionContent}
        </Box>
      }
      {dialogTitle &&
        <DialogTitle className={classes.title}>
          {dialogTitle}
        </DialogTitle>
      }
      <DialogContent className={cx(classes.content, contentClassName)} {...dialogContentProps}>
        {children}
      </DialogContent>
      {overlayOpen &&
        <Box
          position="absolute"
          height="100%"
          width="100%"
          top={0}
          pt={overlayTopPadding || 20}
          className={classes.overlayBackground}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Paper>
            <DialogTitle className={classes.title}>
              {overlayTitle}
            </DialogTitle>
            <DialogContent className={cx(classes.content, classes.noPadding)}>
              {overlayChildren}
            </DialogContent>
          </Paper>
        </Box>
      }
    </MaterialDialog>
  );
};

export default Dialog;
