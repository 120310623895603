import { Box, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    color: theme.palette.common.basic1100,
    padding: 8,
    margin: '8px 8px 0 0',
  },
}));

type Props = {
  title: string;
  onClose?: () => void;
}

const Header = ({ title, onClose }: Props): JSX.Element | null => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="flex-end">
        { onClose != null &&
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        }
      </Box>
      <Box pr={3.5} mt={-1}>
        <Typography variant="h2">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
