import { useState } from 'react';
import { Box, Menu, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from '@mui/icons-material/FilterList';

import { ButtonBase, MenuItem } from 'common/components/material-ui';

import { SortMethod } from '../useReporting';

const useStyles = makeStyles(theme => ({
  menu: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
  menuButton: {
    color: theme.palette.common.basic1100,
  },
}));

type Props = {
  sortMethod: SortMethod;
  onMostRecent: () => void;
  onAlphabetical: () => void;
}

const SortHeader = ({ sortMethod, onMostRecent, onAlphabetical }: Props): JSX.Element => {
  const classes = useStyles();
  const [addNewAnchorEl, setAddNewAnchorEl] = useState<HTMLElement>();
  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          All customer feedback
        </Typography>
        <ButtonBase
          color="secondary"
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAddNewAnchorEl(event.currentTarget)
          }
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <FilterListIcon style={{ marginRight: 8 }} />
            <Typography variant="caption">
              Sort by: {sortMethod === SortMethod.MOST_RECENT ? 'most recent' : 'A-Z'}
            </Typography>
          </Box>
        </ButtonBase>
        <Menu
          id="add-new-menu"
          anchorEl={addNewAnchorEl}
          keepMounted
          open={Boolean(addNewAnchorEl)}
          onClose={() => setAddNewAnchorEl(undefined)}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.menu }}
        >
          <MenuItem
            onClick={() => {
              onMostRecent();
              setAddNewAnchorEl(undefined);
            }}
          >
            Most Recent
          </MenuItem>
          <MenuItem
            onClick={() => {
              onAlphabetical();
              setAddNewAnchorEl(undefined);
            }}
          >
            A-Z
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default SortHeader;
