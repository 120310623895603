import { SvgIconProps } from '../Icon';

const PresentationIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...otherProps}>
    <path
      d="M15.5 46.5v-29M10.5 12.5a5 5 0 100-10 5 5 0 000 10zM3.5 46.5v-25a4 4 0 014-4h24"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 6.5h24v26h-24M33.5 32.5l6 14M26.5 6.5v-4"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PresentationIcon;
