import { Box, Typography, Skeleton } from '@mui/material';

import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Select } from 'common/components/material-ui';
import options from 'common/utils/options';

import { OrganizationAttributes } from 'types';
import { useSettingsActions, useSettings } from './useSettings';

const FlyerInformation = (): JSX.Element => {
  const { organization } = useSettings();
  const { editOrganizationSettings } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'flyer' });
  });

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Flyer Contact Information
      </Typography>
      <Box mt={3} />
      {organization ?
        <>
          <Select
            name="flyerContactInfo"
            label="Flyer Contact Info"
            items={options.marketingBannerSetting}
            value={organization.infoSetting}
            onChange={(e) => {
              itly.changeSetting({ fields: ['info_setting'] });
              editOrganizationSettings({
                infoSetting: (e.target.value as OrganizationAttributes['infoSetting']),
              });
            }}
          />
          <Box mt={1} color="common.basic900">
            <Typography variant="body2" color="inherit">
              Sets contact information displayed for your organization’s
              flyer, such as name, email and phone number.
            </Typography>
          </Box>
        </> :
        <Skeleton variant="rectangular" width="100%" height={50} />
      }
    </Box>
  );
};

export default FlyerInformation;
