import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  children: JSX.Element;
};

const SmallScreenDisplay = (): JSX.Element => (
  <Box
    display="flex"
    flex={1}
    flexDirection="column"
    alignItems="center"
    mt={20}
    color="common.primary900"
    fontSize={48}
  >
    <ErrorOutlineIcon fontSize="inherit" color="primary" />
    <Box mt={2} />
    <Typography variant="subtitle1">Screen size too small.</Typography>
    <Typography variant="subtitle1">Please expand screen to use this page.</Typography>
  </Box>
);

const SmallScreenFilter = ({ children }: Props): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return matches ? <SmallScreenDisplay /> : children;
};

export default SmallScreenFilter;
