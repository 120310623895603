import {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import { isPresent } from 'ts-is-present';
import { differenceInDays } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

import { useSnack } from 'common/utils/snackCart';
import {
  ConnectInvoiceStatus,
  useGetClientProfileQuery,
  useArchiveClientMutation,
  PaymentsRole,
  useHomeownerResendOffersEmailMutation,
  HearthEventScope,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';

import { sortedFinancingHearthEvents } from '../helpers/hearthEvents';

export type FicoRating = 'Poor' | 'Average' | 'Good' | 'Excellent';

const CONNECT_INVOICE_FRAGMENT = gql`
  fragment ClientViewConnectInvoice on ConnectInvoice {
    id
    invoiceNumber
    status
    dueDate
    description
    refundedAmount
    refundableAmount
    amountRequested
    sentAt
    scheduledFor
    syncedToQuickbooks
  }
`;

const PRIMARY_LOAN_INQUIRY_FRAGMENT = gql`
  fragment ClientOfferInfo on Offer {
    id
    loanAmount
    loanTermInMonths
    monthlyPayment
    apr
  }

  fragment ClientViewPrimaryLoanInquiry on LoanInquiry {
    id
    address
    city
    state
    zipCode
    totalOffers
    createdAt
    qualified
    offers {
      nodes {
        id
      }
    }
    lowestOffer {
      id
      ...ClientOfferInfo
    }
    requestedOffer {
      id
      ...ClientOfferInfo
    }
    highestOffer {
      id
      ...ClientOfferInfo
    }
  }
`;

const HOMEOWNER_FRAGMENT = gql`
  fragment ClientViewHomeowner on Homeowner {
    id
    fullName
    firstName
    lastName
    email
    fico
    phoneNumber
    financingStatus
    fundingAmount
    fundingDate
    fundingLender
    requestedAmount
    contractServiceSignatures {
      nodes {
        id
        status
        role
        contract {
          id
          status
          createdAt
          dueDate
          name
          contractNumber
        }
      }
    }
    connectInvoices {
      nodes {
        id
        ...ClientViewConnectInvoice
      }
    }
    primaryLoanInquiry {
      id
      ...ClientViewPrimaryLoanInquiry
    }
    selectedHearthEvents(events: $events, scope: $scope) {
      nodes {
        id
        ...ClientViewEvent
      }
    }
  }

  fragment ClientViewEvent on HearthEvent {
    id
    eventBody
    eventType
    eventAt
  }

  ${CONNECT_INVOICE_FRAGMENT}
  ${PRIMARY_LOAN_INQUIRY_FRAGMENT}
`;

export const CLIENT_PROFILE_CONTRACT_FRAGMENT = gql`
  fragment ClientProfileContract on ContractServiceContract {
    id
    status
    name
  }
`;

export const CONTRACT_SERVICE_ACCOUNT_STATUS_FRAGMENT = gql`
  fragment ContractServiceAccountStatus on ContractServiceAccount {
    id
    termsAgreedAt
    contracts {
      nodes {
        id
        ...ClientProfileContract
      }
    }
  }
  ${CLIENT_PROFILE_CONTRACT_FRAGMENT}
`;

export const GET_CLIENT_PROFILE = gql`
  query GetClientProfile(
    $homeownerId: String!
    $events: [HearthEventEventTypes!]!
    $scope: HearthEventScope
  ) {
    homeowner(id: $homeownerId) {
      id
      ...ClientViewHomeowner
    }
    contractor {
      id
      role
      paymentsRole
      admin
    }
    organization {
      id
      stripeConnectAccount {
        id
        status
        fullAccess
      }
      contractServiceAccount {
        id
        ...ContractServiceAccountStatus
      }
    }
  }
  ${HOMEOWNER_FRAGMENT}
`;

export const ARCHIVE_CLIENT = gql`
  mutation ArchiveClient($homeownerId: ID!, $contractorId: ID!, $archive: Boolean!) {
    archiveHomeowner(homeownerId: $homeownerId, contractorId: $contractorId, archive: $archive) {
      ... on ArchiveHomeownerSuccess {
        homeowner {
          id
        }
        contractor {
          id
        }
      }
      ... on ArchiveHomeownerFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const RESEND_OFFERS_EMAIL = gql`
  mutation HomeownerResendOffersEmail($id: ID!, $email: String!) {
    homeownerResendOffersEmail(loanInquiryUuid: $id, addressTo: $email) {
      ... on HomeownerResendOffersEmailSuccess {
        loanInquiry {
          id
        }
      }
      ... on HomeownerResendOffersEmailFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

type Props = {
  homeownerId: string;
}

export type EditClientValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const collectedStatuses = [
  ConnectInvoiceStatus.COLLECTED,
  ConnectInvoiceStatus.MANUALLY_COLLECTED,
];

const outstandingStatuses = [
  ConnectInvoiceStatus.CREATED,
  ConnectInvoiceStatus.PROCESSING,
];

const useContext = ({ homeownerId }: Props) => {
  const query = useGetClientProfileQuery({
    variables: {
      homeownerId,
      events: sortedFinancingHearthEvents,
      scope: HearthEventScope.PRIORITY,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // @TODO(client_view): investigate perf ramifications / alternatives
    query.startPolling(2_000);
    const timer = setTimeout(() => query.stopPolling(), 30_000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // returns an array of contracts between a homeowner and a contractor
  const homeownerContracts = useMemo(() => {
    const orgContractsList: { [key: string]: boolean } = {};
    const orgContracts = query?.data?.organization?.contractServiceAccount?.contracts?.nodes || [];
    orgContracts.forEach((contract) => {
      if (contract) orgContractsList[contract.id] = true;
    });
    return query?.data?.homeowner?.contractServiceSignatures?.nodes?.filter(
      node => node && orgContractsList[node.contract.id],
    );
  }, [query?.data]);

  const {
    homeowner,
    contractor,
    primaryLoanInquiry,
    stripeConnectAccount,
    connectInvoices,
    financingEvents,
    clientContracts,
  } = useMemo(() => ({
    homeowner: query?.data?.homeowner,
    contractor: query?.data?.contractor,
    primaryLoanInquiry: query?.data?.homeowner?.primaryLoanInquiry,
    stripeConnectAccount: query.data?.organization?.stripeConnectAccount,
    connectInvoices: query?.data?.homeowner?.connectInvoices?.nodes?.filter(isPresent) || [],
    financingEvents: query?.data?.homeowner?.selectedHearthEvents?.nodes?.filter(isPresent) || [],
    clientContracts: homeownerContracts,
  }), [query?.data, homeownerContracts]);

  const [paymentsSetupModalOpen, setPaymentSetupModalOpen] = useState(false);

  const notCanceledInvoices = useMemo(() =>
    [...connectInvoices].filter(
      invoice => invoice.status !== ConnectInvoiceStatus.CANCELLED),
  [connectInvoices]);

  const paid = useMemo(() =>
    [...notCanceledInvoices].filter(
      invoice => collectedStatuses.indexOf(invoice.status) !== -1,
    ),
  [notCanceledInvoices]);
  const notPaid = useMemo(() =>
    [...notCanceledInvoices].filter(
      invoice => collectedStatuses.indexOf(invoice.status) === -1,
    ),
  [notCanceledInvoices]);
  const overdue = useMemo(() =>
    [...notPaid].filter((invoice) => {
      if (!invoice.dueDate) {
        return false;
      }

      return differenceInDays(Date.now(), Date.parse(invoice.dueDate)) > 0;
    }),
  [notPaid]);

  const firstHearthEvent = useMemo(() => financingEvents[0], [financingEvents]);

  const hasOutstandingInvoice = useMemo(() =>
    connectInvoices.some(invoice => outstandingStatuses.includes(invoice.status)),
  [connectInvoices]);
  const hasProcessingInvoice = useMemo(() =>
    connectInvoices.some(invoice => invoice.status === ConnectInvoiceStatus.PROCESSING),
  [connectInvoices]);

  const paidAmount = paid.reduce((total, invoice) =>
    total + invoice.amountRequested, 0);
  const overdueAmount = overdue.reduce((total, invoice) =>
    total + invoice.amountRequested, 0);
  const unpaidAmount = notPaid.reduce((total, invoice) =>
    total + invoice.amountRequested, 0) - overdueAmount;

  const hasPaymentsAccess = stripeConnectAccount?.fullAccess;
  const showPaymentsButton = useMemo(() => {
    if (hasPaymentsAccess) {
      // If the org has payments access but the contractor
      // is payments blocked, don't show the payments button
      return contractor?.paymentsRole !== PaymentsRole.BLOCKED;
    }
    // If a contractor is a member and the org
    // does not have payments access, hide the button
    return contractor?.role !== 'Member';
  }, [hasPaymentsAccess, contractor]);

  const hasPayments = connectInvoices.length > 0;

  const hasFinancing = useMemo(() => financingEvents.length > 0, [financingEvents]);

  return {
    data: {
      homeowner,
      contractor,
      connectInvoices,
      clientContracts,
      stripeConnectAccount,
      paidAmount,
      unpaidAmount,
      overdueAmount,
      query,
      primaryLoanInquiry,
      hasOutstandingInvoice,
      hasProcessingInvoice,
      hasPayments,
      hasFinancing,
      financingEvents,
      firstHearthEvent,
      inferredStates: {
        showPaymentsButton,
      },
    },
    uiStates: {
      paymentsSetupModalOpen,
      setPaymentSetupModalOpen,
    },
  };
};

export const [ClientProfileProvider, useClientProfile, useClientProfileUIStates] = constate(
  useContext,
  value => value.data,
  value => value.uiStates,
);

type UseClientProfileActions = {
  archiveClient: () => Promise<void>;
  resendOffersEmail: () => Promise<void>;
  openRequestPayment: () => void;
  openOfferFinancing: () => void;
}

export const useClientProfileActions = (): UseClientProfileActions => {
  const { handleMutationError } = useGql();
  const {
    contractor,
    homeowner,
    primaryLoanInquiry,
    stripeConnectAccount,
  } = useClientProfile();
  const { setPaymentSetupModalOpen } = useClientProfileUIStates();
  const [archiveClientMutation] = useArchiveClientMutation();
  const history = useHistory();
  const location = useLocation();
  const snack = useSnack();
  const [homeownerResendOffersEmailMutation] = useHomeownerResendOffersEmailMutation();

  const resendOffersEmail = useCallback(async () => {
    if (!homeowner || !primaryLoanInquiry) return;

    try {
      const response = await homeownerResendOffersEmailMutation({
        variables: {
          id: primaryLoanInquiry?.id,
          email: homeowner?.email,
        },
      });

      parseGql<PayloadType<typeof response, 'homeownerResendOffersEmail'>>(
        'homeownerResendOffersEmail',
        response,
        'HomeownerResendOffersEmailSuccess',
        'HomeownerResendOffersEmailFailure',
      );

      snack.successSnack('Offers resent to client');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    homeowner,
    primaryLoanInquiry,
    homeownerResendOffersEmailMutation,
    snack,
    handleMutationError,
  ]);

  const archiveClient = useCallback(async () => {
    if (!homeowner || !contractor) return;

    try {
      const response = await archiveClientMutation({
        variables: {
          homeownerId: homeowner?.id,
          contractorId: contractor?.id,
          archive: true,
        },
        refetchQueries: ['GetClientProfile'],
        awaitRefetchQueries: true,
      });

      parseGql<PayloadType<typeof response, 'archiveHomeowner'>>(
        'archiveHomeowner',
        response,
        'ArchiveHomeownerSuccess',
        'ArchiveHomeownerFailure',
      );

      history.replace('/dashboard/clients');

      snack.successSnack('Deleted client');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    homeowner,
    contractor,
    history,
    archiveClientMutation,
    snack,
    handleMutationError,
  ]);

  const openRequestPayment = useCallback(() => {
    if (!homeowner) return;

    // If this organization does not have payments setup
    // we still want to show the setup modal to admins.
    if (!stripeConnectAccount?.fullAccess) {
      setPaymentSetupModalOpen(true);
      return;
    }

    history.push(`/dashboard/request/request-payment/${homeowner.id}?source=client_view`, {
      background: location,
    });
  }, [
    homeowner,
    stripeConnectAccount,
    setPaymentSetupModalOpen,
    history,
    location,
  ]);

  const openOfferFinancing = () => {
    if (!homeowner) return;

    history.push(`/dashboard/request/offer-financing/${homeowner.id}?source=client_view`, {
      background: location,
    });
  };

  return {
    archiveClient,
    resendOffersEmail,
    openRequestPayment,
    openOfferFinancing,
  };
};

export default useClientProfile;
