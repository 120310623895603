import PropTypes from 'prop-types';
import cx from 'classnames';

import { Col } from 'common/components/layouts';

import BBBIcon from './BBBIcon';
import './TrustIcons.scss';

// TODO: refactor props
const TrustIcons = ({
  logos,
  noMargin,
  inline,
  mini,
}) => {
  const colWidth = 12 / logos.length;

  return (
    <div className={
      cx('trust-icons', {
        'trust-icons--inline': inline,
        'trust-icons--no-margin': noMargin,
        'trust-icons--mini': mini,
      })
    }
    >
      {logos.indexOf('equal-housing-lender') !== -1 &&
        <Col xs={colWidth} className="trust-col">
          <img
            alt="equal-housing-lender"
            style={{ maxWidth: 90 }}
            src="https://hearth.imgix.net/icons/equal_housing_lender_horizontal.png?auto=compress"
          />
        </Col>
      }
      {logos.indexOf('norton') !== -1 &&
        <Col xs={colWidth} className="trust-col">
          <img
            alt="norton"
            style={{ maxWidth: 90 }}
            src="https://hearth.imgix.net/icons/norton-sec@3x.png?auto=compress"
          />
        </Col>
      }
      {logos.indexOf('mcafee') !== -1 &&
        <Col xs={colWidth} className="trust-col">
          <img
            alt="mcafee"
            style={{ maxWidth: 90, marginTop: 4 }}
            src="https://hearth.imgix.net/icons/mcafee@3x.png?auto=compress"
          />
        </Col>
      }
      {logos.indexOf('lock') !== -1 &&
        <Col xs={colWidth} className="trust-col">
          <img
            alt="aes-lock"
            style={{ maxWidth: 98 }}
            src="https://hearth.imgix.net/icons/aes-256-encrpytion.svg?auto=compress"
          />
        </Col>
      }
      {logos.indexOf('bbb') !== -1 &&
        <Col xs={colWidth} className="trust-col">
          <BBBIcon
            color="blue"
            type="a_plus_"
            style={{ maxWidth: 98 }}
          />
        </Col>
      }
    </div>
  );
};

TrustIcons.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string),
  noMargin: PropTypes.bool,
  inline: PropTypes.bool,
  mini: PropTypes.bool,
};

TrustIcons.defaultProps = {
  logos: ['norton', 'mcafee', 'lock', 'bbb'],
  noMargin: false,
  inline: false,
  mini: false,
};

export default TrustIcons;
