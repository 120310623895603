import { stringify } from 'query-string';

import * as contractorApi from 'common/api/contractorApi';
import contractorUrl from 'contractor/utils/contractorDashboard';
import { appUrl } from 'common/utils/urlHelpers';
import { Contractor, Organization } from 'contractor/types';

export function getAffiliateEmailMarkup(referralCoupon: string): string {
  return `
    <div>
      <p>
        Check out Hearth Financing! I'm using it to help close more deals.
        Sign up with my link to have the $99 setup fee waived on your subscription:
        <b><a href="${appUrl(`/r/${referralCoupon}`)}">${appUrl(`/r/${referralCoupon}`)}</a></b>
      </p>
    </div>
  `;
}

type EmailMarkup = {
  subject: string;
  tag: string;
  name: string;
  templateText: string;
  body: string;
}

export function getShareEmailMarkup(
  contractor: Contractor,
  organization: Organization,
  customerName = '',
  videoTest = false,
): EmailMarkup[] {
  const { baseUrl, path } = contractorUrl(contractor, 'web-email');
  const displayLink = baseUrl + path;

  const link = displayLink + '?' + stringify({ utm_content: 'after' });
  const greetingName = customerName || 'there';

  const youtubeLink = 'https://youtu.be/zUQjSncm1LY';
  let videoCopy = '';

  if (videoTest) {
    videoCopy = `
    <br />
    <p>
      Learn more about the process: <a href="${youtubeLink}"">${youtubeLink}</a>
    </p>
    `;
  }

  return [{
    subject: 'Payment options for your project',
    tag: 'share',
    name: 'max_qual',
    templateText: 'See the most your client can get',
    body: `
      <div>
        <p>
          Hi ${greetingName},
        </p>
        <br />
        <p>
          As we discuss next steps for your project, I wanted to make sure you knew that
          we offer monthly payment options through my financing partner – Hearth. Through
          Hearth, you can see options for installment loans that allow you to break up
          the project cost into predictable monthly payments. If you’re interested in
          seeing what financing options you can get, fill out the quick form on my Hearth
          page (seeing options doesn’t affect your credit score)!
        </p>
        <br />
        <p>
          Click here to see financing options: <a href="${link}"">${displayLink}</a>
        </p>
        <br />
        <p>
          Thanks,
          <br />${contractor.firstName}
        </p>
        ${videoCopy}
        <p>
          <br />
          <br />---
          <br />${organization.companyName} partners with Hearth to bring you monthly payment options for
          your project. Complete this quick financing request to see what loan
          options you pre-qualify for. Seeing options won’t affect your credit score!
          <a href="${link}"">${displayLink}</a>
        </p>
      </div>
    `,
  }];
}

type emailArgs = {
  tag: string;
  subject: string;
  email: string;
  body: string;
}

export function sendEmail(emailArgs: emailArgs, contractorId: Contractor['id']): Promise<unknown> {
  return contractorApi.sendEmail(emailArgs, contractorId);
}
