import { apiPromiseAction, DispatchAction } from 'promise-action';

import * as api from 'common/api/constantsApi';

const fetchServerConstants = apiPromiseAction
  .create(api.fetch)
  .normalizeResponse(({ data }) => data);

/*
** Dispatchers
*/

export const fetchServerConstantsDispatcher = fetchServerConstants.dispatcher(
  'ServerConstants/FETCH_PENDING',
  'ServerConstants/FETCH_SUCCESS',
  'ServerConstants/FETCH_ERROR',
);

export type DispatchActions =
  DispatchAction<typeof fetchServerConstantsDispatcher>;
