import { AppBar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useVerticalState } from 'common/hooks';
import { AppBarProps } from '@mui/material/AppBar';
import { useState } from 'react';

import { Link } from 'common/components/material-ui';
import constants from 'common/utils/constants';
import { AdvertiserDisclosureModal } from 'common/components/modals';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: theme.navbarHeight,
    alignItems: 'center',
    padding: 36,
  },
  support: {
    alignSelf: 'center',
  },
  logo: {
    alignSelf: 'center',
    height: 30,
  },
}));

type Props = {
  position?: AppBarProps['position'];
  numberType?: 'supportNumber' | 'salesNumber';
}

const GenericNavbar = ({ position = 'sticky', numberType = 'supportNumber' }: Props): JSX.Element => {
  const classes = useStyles();
  const [disclosureModalOpen, setDisclosureModalOpen] = useState(false);
  const [{ brandMetadata }] = useVerticalState();

  return (
    <AppBar position={position} className={classes.appBar}>
      {disclosureModalOpen &&
        <AdvertiserDisclosureModal
          open={disclosureModalOpen}
          onClose={() => setDisclosureModalOpen(false)}
        />
      }
      <Link
        href={brandMetadata.logoHref}
      >
        <img
          className={classes.logo}
          alt={brandMetadata.label}
          src={brandMetadata.logoSrc}
        />
      </Link>
      <Box display="flex" alignItems="center">
        <Link
          variant="subtitle2"
          color="textPrimary"
          underline="always"
          onClick={() => setDisclosureModalOpen(true)}
          style={{ marginRight: 12 }}
        >
          Advertiser Disclosure
        </Link>
        <Link
          className={classes.support}
          variant="body2"
          color="textPrimary"
          href={`tel:${constants[numberType]}`}
        >
          {constants[`${numberType}Display`]}
        </Link>
      </Box>
    </AppBar>
  );
};

export default GenericNavbar;
