import { Box, List, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import { RouterLink } from 'common/components/material-ui';
import { useVerticalState } from 'common/hooks';

import {
  ClientsTab,
  WorkflowsTab,
  BusinessTab,
  DashboardTab,
  SecondaryLinks,
  HelpSection,
} from './components';

const useStyles = makeStyles(theme => ({
  rightEdgeShadow: {
    boxShadow: '4px 0 4px -2px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    height: 28,
    marginLeft: 18,
    marginRight: 'auto',
    marginTop: 18,
    marginBottom: 18,
  },
  logoContainer: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navItems: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  drawerPaper: {
    zIndex: theme.drawerZIndex,
    width: theme.drawerWidth,
    overflow: 'visible',
  },
  drawerPaperAnchorLeft: {
    borderRight: 'none',
    zIndex: theme.drawerZIndex,
  },
}));

const NavigationDrawer = (): JSX.Element => {
  const classes = useStyles();
  const [{ brandMetadata }] = useVerticalState();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme => theme.drawerZIndex,
        width: theme => theme.drawerWidth,
      }}
    >
      <div className={cx(classes.logoContainer, classes.rightEdgeShadow)}>
        <RouterLink to="/dashboard">
          <img
            alt={brandMetadata.label}
            src={brandMetadata.logoSrc}
            className={classes.logo}
          />
        </RouterLink>
      </div>
      <Divider />
      <Box className={cx(classes.navItems, classes.rightEdgeShadow)}>
        <List component="nav" disablePadding>
          <DashboardTab />
          <ClientsTab />
          <WorkflowsTab />
          <BusinessTab />
        </List>
        <Box pb={3}>
          <SecondaryLinks />
          <HelpSection />
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationDrawer;
