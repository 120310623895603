import * as Sentry from '@sentry/react';
import uuidv4 from 'uuid/v4';
import {
  ApolloError,
  ServerError,
  ServerParseError,
} from '@apollo/client';

import { isClient, isDebug } from 'config/app';
import store from 'stores';
import { sandboxSelector } from 'common/selectors/user';
import { isServerParseError } from 'common/graphql/utils';

const ANONYMOUS_ID_KEY = 'HEARTH_ANONYMOUS_ID';

export const registerSession = (): void => {
  // sessionId pertains to the current browser window
  const sessionId = uuidv4();
  // anonymousId is persistent across sessions
  let anonymousId = window.localStorage.getItem(ANONYMOUS_ID_KEY);
  if (!anonymousId) {
    anonymousId = uuidv4();
    window.localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId);
  }

  store.dispatch({
    type: 'UserState/REGISTER_SESSION',
    payload: { sessionId, anonymousId },
  });
};

const analytics = {
  trackException: (
    e: unknown,
    info?: Record<string, unknown>,
    severity?: Sentry.Severity,
  ): void => {
    const state = store.getState();
    if (isClient && !sandboxSelector(state)) {
      Sentry.withScope((scope) => {
        if (info) {
          scope.setExtra('info', info);
        }
        if (severity) {
          scope.setLevel(severity);
        }
        Sentry.captureException(e);
      });
      if (isDebug) {
        console.error(e);
      }
    }
  },
  trackMessage: (
    message: string,
    level = Sentry.Severity.Error,
    info?: Record<string, unknown>,
  ): void => {
    if (isClient) {
      Sentry.withScope((scope) => {
        if (info) {
          scope.setExtra('info', info);
        }
        Sentry.captureMessage(message, level);
      });
      if (isDebug) {
        console.error(message);
      }
    }
  },
  trackApolloException: (err: ApolloError, level = Sentry.Severity.Error): void => {
    if (isClient) {
      Sentry.withScope((scope) => {
        if (err.extraInfo) {
          scope.setExtra('extraInfo', JSON.stringify(err.extraInfo));
        }
        if (err.graphQLErrors) {
          Sentry.captureException(err);
        } else if (err.networkError) {
          analytics.trackApolloNetworkException(err.networkError);
        } else {
          Sentry.captureMessage(err.message, level);
        }
      });
      if (isDebug) {
        console.error(err.message);
      }
    }
  },
  trackApolloNetworkException: (
    networkError: Error | ServerParseError | ServerError,
    level = Sentry.Severity.Error,
  ): void => {
    if (isClient) {
      Sentry.withScope((scope) => {
        scope.setExtra('message', networkError.message);
        scope.setExtra('name', networkError.name);

        // A server parse error is usually a 401 which returns HTML instead of
        // JSON. The body text will contain the error message we are looking for
        if (isServerParseError(networkError)) {
          Sentry.captureMessage(networkError.bodyText, level);
        } else {
          Sentry.captureException(networkError);
        }
      });
      if (isDebug) {
        console.error(networkError.message);
      }
    }
  },
};

export default analytics;
