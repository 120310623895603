import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  font-weight: 500;
`;

const LabelContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
`;

export default (Component) => {
  const Wrapper = ({
    label,
    rightLabel,
    errorMessage,
    name,
    info,
    style,
    ...otherProps
  }) => (
    <Container style={style}>
      {label &&
        <LabelContainer>
          <label htmlFor={name}>
            <Typography variant="h6">{label}</Typography>
          </label>
          <div style={{ marginLeft: 'auto' }}>
            {rightLabel}
          </div>
        </LabelContainer>
      }
      <Component name={name} {...otherProps} />
      {info &&
        <>
          <Box mb={1} />
          {info}
        </>
      }
      {errorMessage &&
        <>
          <Box mb={1} />
          <Typography variant="h6">
            <Box color="common.danger500">{errorMessage}</Box>
          </Typography>
        </>
      }
    </Container>
  );

  Wrapper.propTypes = {
    label: PropTypes.node,
    rightLabel: PropTypes.node,
    errorMessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    info: PropTypes.node,
    style: PropTypes.shape({}),
  };

  Wrapper.defaultProps = {
    label: null,
    rightLabel: null,
    errorMessage: '',
    info: null,
    style: undefined,
  };

  return Wrapper;
};
