import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0',
    paddingRight: 40,
  },
  next: {
    boxShadow: 'none',
    maxWidth: '50%',
  },
  actions: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
  },
  previous: {
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 8,
  },
});

type Props = {
  next?: string;
  previous?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  walkthroughStepNumber?: number;
  walkthroughStepCount?: number;
}

const Actions = ({
  next,
  previous,
  onNext,
  onPrevious,
  walkthroughStepNumber,
  walkthroughStepCount,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const showStepCount = Boolean(walkthroughStepNumber && walkthroughStepNumber > 0 &&
    walkthroughStepCount);

  return (
    <Box className={classes.root}>
      {showStepCount &&
        <Typography variant="subtitle1" color="textSecondary">
          {walkthroughStepNumber}/{walkthroughStepCount}
        </Typography>
      }
      <Box className={classes.actions}>
        {previous && onPrevious &&
          <Button
            buttonType="common.primary"
            variant="text"
            onClick={onPrevious}
            className={classes.previous}
          >
            {previous}
          </Button>
        }
        {next && onNext &&
          <Button
            buttonType="common.primary"
            variant="contained"
            onClick={onNext}
            className={classes.next}
          >
            {next}
          </Button>
        }
      </Box>
    </Box>
  );
};

export default Actions;
