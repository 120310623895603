import { useReducer } from 'react';
import constate from 'constate';

/*
  ON DESKTOP:
  1. "Send New" button in navigation bar
  2. "Account" button in navigation bar
  3. "Help" button in side navigation drawer
  4. Suggested Actions card on dashboard

  ON MOBILE:
  1. Suggested Actions card (no step counter)
*/

export enum WalkthroughStep {
  SEND_NEW = 'Send new button',
  ACCOUNT = 'Account button',
  HELP = 'Help button',
  SUGGESTED_ACTIONS = 'Suggested actions button',
}

type OnboardingWalkthroughState = {
  step?: WalkthroughStep;
  index: number;
  previous?: string;
  next?: string;
}

export enum WalkthroughAction { NEXT, PREVIOUS, SET }

export type WalkthroughActions =
  { type: WalkthroughAction.NEXT | WalkthroughAction.PREVIOUS } |
  { type: WalkthroughAction.SET; step: WalkthroughStep | undefined };

type StepDetail = {
  title: string;
  description: string;
}

export const WalkthroughStepDetails: Record<WalkthroughStep, StepDetail> = {
  [WalkthroughStep.SEND_NEW]: {
    title: 'Quickly access your business tools here!',
    description: 'Click “Send new” to see what exciting tools you can access.',
  },
  [WalkthroughStep.ACCOUNT]: {
    title: 'Access your profile and settings here!',
    description: 'Update your account and business settings from here quickly and easily.',
  },
  [WalkthroughStep.HELP]: {
    title: 'Need help?',
    description: 'Reach out to us anytime if you have questions. ' +
      'Welcome to the Hearth family!',
  },
  [WalkthroughStep.SUGGESTED_ACTIONS]: {
    title: 'Find your suggested actions here!',
    description: 'Learn what important actions you should ' +
      'take next to get the most out of Hearth!',
  },
};

const reducer = (state: OnboardingWalkthroughState, action: WalkthroughActions) => {
  const previousStep = { ...state, index: state.index - 1 };
  const nextStep = { ...state, index: state.index + 1 };

  switch (action.type) {
  case WalkthroughAction.SET:
    switch (action.step) {
    case WalkthroughStep.SUGGESTED_ACTIONS:
      return {
        // this is kind of hacky, but it's a way to determine whether to display
        // the index of a step without nil checks everywhere
        index: -1,
        step: action.step,
        previous: undefined,
        next: 'Got it',
      };
    default:
      return {
        ...state,
        previous: undefined,
        step: action.step,
      };
    }
  case WalkthroughAction.PREVIOUS:
    switch (state.step) {
    case WalkthroughStep.SEND_NEW:
      return {
        ...previousStep,
        step: undefined,
        previous: undefined,
        next: 'Next',
      };
    case WalkthroughStep.ACCOUNT:
      return {
        ...previousStep,
        step: WalkthroughStep.SEND_NEW,
        previous: undefined,
        next: 'Next',
      };
    case WalkthroughStep.HELP:
      return {
        ...previousStep,
        step: WalkthroughStep.ACCOUNT,
        previous: 'Previous',
        next: 'Next',
      };
    case WalkthroughStep.SUGGESTED_ACTIONS:
      return {
        ...previousStep,
        step: WalkthroughStep.HELP,
        previous: 'Previous',
        next: 'Next',
      };
    default: return { ...state };
    }
  case WalkthroughAction.NEXT:
    switch (state.step) {
    case WalkthroughStep.SEND_NEW:
      return {
        ...nextStep,
        step: WalkthroughStep.ACCOUNT,
        previous: 'Previous',
      };
    case WalkthroughStep.ACCOUNT:
      return {
        ...nextStep,
        step: WalkthroughStep.HELP,
        previous: 'Previous',
      };
    case WalkthroughStep.HELP:
      return {
        ...nextStep,
        step: WalkthroughStep.SUGGESTED_ACTIONS,
        previous: 'Previous',
        next: 'Finish',
      };
    case WalkthroughStep.SUGGESTED_ACTIONS:
      return {
        ...nextStep,
        step: undefined,
      };
    default: return { ...state, step: WalkthroughStep.SEND_NEW };
    }
  default: return { ...state };
  }
};

const useContext = () => {
  const [state, dispatch] = useReducer(reducer, {
    step: undefined,
    index: 0,
    previous: 'Previous',
    next: 'Next',
  });

  return {
    data: {
      state,
      dispatch,
    },
  };
};

export const [OnboardingWalkthroughProvider, useOnboardingWalkthrough] = constate(useContext);
