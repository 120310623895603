import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import head from 'lodash/fp/head';
import { PaymentProofFragment } from 'types';

export const socialProofCheck = (
  data: PaymentProofFragment[],
  loanTermInYears: number,
  offerApr: number | string,
): boolean => flow(
  filter(['loanTermInMonths', (loanTermInYears * 12).toString()]),
  map((calc: PaymentProofFragment) => calc && (Number(offerApr) <= calc.minApr)),
  head,
)(data);

export const getFicoBucket = (fico: number): string => {
  switch (true) {
  case (fico > 740):
    return 'excellent';
  case (fico > 680):
    return 'good';
  case (fico > 660):
    return 'average';
  default:
    return 'poor';
  }
};
