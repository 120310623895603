import { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { maybePluralize } from 'common/utils/stringFormatters';

import {
  OffersFilter,
  OffersSort,
  OffersList,
  Options,
  EasyReapplyCard,
  EasyReapplyModal,
  EasyCoborrowerReapplyCard,
  EasyCoborrowerReapplyModal,
  ZeroPercentCardOffer,
  RateCapFilter,
  NoMatchingOffers,
} from './components';
import { useSortingOffers } from './useSortingOffers';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    backgroundColor: theme.palette.common.basic300,
    paddingBottom: 200,
  },
  sortHeader: {
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  sortHeaderRightElements: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      paddingTop: 16,
    },
  },
  innerContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 900,
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    maxWidth: 200,
    borderColor: theme.palette.common.basic500,
  },
  coborrowGrid: {
    display: 'flex',
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
    },
  },
}));

const MonthlyPaymentOptionsBanner = (): JSX.Element | null => {
  const {
    inquiryState: { loanInquiry },
    offersState: { showMonthlyPaymentOffers, totalMonthlyPaymentOffers },
    processState: { monthlyPaymentSort },
    offersState: { offers },
  } = useSortingOffers();

  // don't render any banner if the sort type isn't score or
  // the inquiry doesn't have a min && max (i.e. they chose "I don't know")
  // for their monthly payment range
  if ((loanInquiry?.monthlyPaymentMin == null && loanInquiry?.monthlyPaymentMax == null) ||
    !monthlyPaymentSort) {
    return null;
  }

  if (showMonthlyPaymentOffers) {
    return (
      <Box mt={2}>
        <Typography variant="h3">
          We found {maybePluralize(totalMonthlyPaymentOffers, 'option')} within
          your monthly payment budget!
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Typography variant="h3">
        We found {maybePluralize(offers.length, 'loan option')} for you.
      </Typography>
      <Box pt={2}>
        <Typography variant="subtitle1">
          These options are above your stated monthly payments range.
        </Typography>
      </Box>
    </Box>
  );
};

type Props = {
  showLowOffers: boolean;
}

const SortingOffers = ({ showLowOffers }: Props): JSX.Element => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [coborrowerModalOpen, setCoborrowerModalOpen] = useState(false);
  const [withLoanPrincipalInput, setWithLoanPrincipalInput] = useState(false);
  const {
    inquiryState: { loanInquiry, homeowner },
    offersState: {
      hasRateCapping,
      topOffers,
      upgradeLineOfCreditOffer,
      zeroPercentOfferPosition,
    },
  } = useSortingOffers();
  const [loanPrincipal, setLoanPrincipal] = useState<string>('');

  const zeroPercentEligible = Number(loanInquiry?.fico) >= 680;
  const showCardOption = zeroPercentEligible && loanInquiry?.zeroPercentCards;
  const showOtherOptions = showCardOption || showLowOffers || Boolean(upgradeLineOfCreditOffer);

  useEffect(() => {
    if (loanInquiry?.recommendedLoanAmount) {
      setLoanPrincipal(loanInquiry.recommendedLoanAmount.toString());
    }
  }, [loanInquiry?.recommendedLoanAmount]);

  return (
    <>
      {loanInquiry?.recommendedLoanAmount &&
        <EasyReapplyModal
          loanInquiryUuid={loanInquiry.id}
          organizationId={loanInquiry.organization?.id}
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setWithLoanPrincipalInput(false);
          }}
          loanPrincipal={loanInquiry.recommendedLoanAmount}
          withLoanPrincipalInput={withLoanPrincipalInput}
        />
      }
      {loanInquiry?.loanPrincipal &&
        <EasyCoborrowerReapplyModal
          open={coborrowerModalOpen}
          onClose={() => setCoborrowerModalOpen(false)}
          organizationId={loanInquiry.organization?.id}
          loanInquiryUuid={loanInquiry.id}
          loanPrincipal={loanInquiry.loanPrincipal}
        />
      }
      <Box display="flex" py={{ sm: 4 }} className={classes.root}>
        <Box display="flex" className={classes.innerContainer}>
          <Box>
            <Grid container className={classes.sortHeader}>
              <Grid item xs={12} md={4}>
                <OffersSort />
              </Grid>
              <Grid item xs={12} md={8} className={classes.sortHeaderRightElements}>
                {hasRateCapping && (loanInquiry?.monthlyPaymentMax == null) &&
                  <RateCapFilter />
                }
                <Box className={classes.filter}>
                  <OffersFilter />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {zeroPercentOfferPosition === 'top' && homeowner &&
            <Box my={2}>
              <ZeroPercentCardOffer
                homeownerId={homeowner.id}
              />
              <Box mt={8}>
                <Typography variant="h2">Personal Loan Options</Typography>
              </Box>
            </Box>
          }
          <Box px={{ xs: 4, sm: 2, md: 0 }}>
            <MonthlyPaymentOptionsBanner />
            {topOffers.length > 0 ?
              <OffersList /> :
              <NoMatchingOffers />
            }
            <Options
              showLowOffers={showLowOffers}
              showOtherOptions={showOtherOptions}
            />
          </Box>
          <Box my={6}>
            <Divider />
          </Box>
          {(loanInquiry?.recommendedLoanAmount || !loanInquiry?.jointApplication) &&
            <Box px={1}>
              <Box mx={2} mb={6}>
                <Typography variant="h2">
                  <Box color="common.primary500">
                    Here’s how to see more options
                  </Box>
                </Typography>
                <Typography variant="body2">
                  Here are some ways you can improve your financial profile
                  in the eyes of lenders, and possibly get more financing offers.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {loanInquiry?.recommendedLoanAmount &&
                  <Grid item xs={12} sm={6}>
                    <EasyReapplyCard
                      loanPrincipal={loanPrincipal}
                      recommendedLoanAmount={loanInquiry.recommendedLoanAmount}
                      setLoanPrincipal={setLoanPrincipal}
                      onSubmit={() => setModalOpen(true)}
                    />
                  </Grid>
                }
                {!loanInquiry?.jointApplication &&
                  <Grid item xs={12} sm={6} className={classes.coborrowGrid}>
                    <EasyCoborrowerReapplyCard
                      onClick={() => setCoborrowerModalOpen(true)}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          }
        </Box>
      </Box>
    </>
  );
};

export default SortingOffers;
