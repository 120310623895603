import moment from 'moment';

import { Partnership } from 'contractor/types';
import constants from 'common/utils/constants';

import useVerticalState from './useVerticalState';
import { useUrl } from './useUrl';

type Disclosures =
  'offersPage' |
  'offersAdDisclosure' |
  'notALender' |
  'lowerRates' |
  'notALenderShort' |
  'contractorFinancing' |
  'bestLoanOptionsDisclosure' |
  'originationFees' |
  'broker' |
  'trademark';

type DisclosureType = {
  id: string;
  text: JSX.Element;
}

export const useTerms = () => {
  const { marketingUrl } = useUrl();
  const [{ brandMetadata: { label: brandLabel } }] = useVerticalState();

  return {
    getDisclosures: (): Record<Disclosures, DisclosureType> => ({
      offersPage: {
        id: 'offers-disclosure',
        text: (
          <>
            The operator of this website is not a lender, does not broker loans
            to lenders and does not make personal loans or credit decisions. This
            website does not constitute an offer or solicitation to lend and will only
            submit the information you provide to our lending partners. The operator of
            this website is not an agent, representative or broker of any lending partner
            and does not endorse or charge you for any service or product.  Providing your
            information on this website does not guarantee that you will be approved for
            a personal loan.  When you click “Continue” you will not receive the estimated
            loan terms but will be directed to the lending partner’s website to complete a
            credit application.<br /><br />Annual Percentage Rate (APR), loan term and
            monthly payments are estimated based on analysis of preliminary information
            provided by you, data provided by partners and publicly available information.
            These terms may not represent terms that are available to you. Only borrowers
            with excellent credit will qualify for the lowest rate available. All loan
            offers are subject to credit review and approval by the lending partners.
            Your actual rate and other loan terms may vary and are depend on factors like
            credit score, requested loan amount, loan term and credit history, as well as
            the terms and conditions of the relevant offer.<br /><br />The data and other
            information you may provide to {brandLabel} is not, and is not treated as, an
            application for a loan or a request to be pre-approved for a loan, or any
            similar concept.
          </>
        ),
      },
      offersAdDisclosure: {
        id: 'offers-ad-disclosure',
        text: (
          <>
            <b>Advertiser Disclosure</b>{' '}
            The offers that appear on this site are by third-party advertisers from which we receive
            compensation. This compensation may impact how, where, and in what order the products
            appear on this site. The offers on this site do not represent all available financial
            services, companies, or products.
          </>
        ),
      },
      notALender: {
        id: 'not-a-lender',
        text: (
          <>
            * All loan information is presented without warranty, and estimated APR and other
            terms are not binding. {brandLabel}’s lending partners generally present a range
            of APRs (for instance, from 5% to 35.99%) with a range of terms and monthly payments.
            As an example, a $10,000 loan with an APR of 14.50% and a term of 36 months would
            have a monthly payment of $344.21. Actual APRs will depend on factors like credit
            score, loan amount, loan term, and credit history. Only borrowers with excellent
            credit will qualify for the lowest APRs. All loans are subject to credit review
            and approval.
          </>
        ),
      },
      broker: {
        id: 'broker',
        text: (
          <>
            {brandLabel} is a technology company, which is licensed as a broker as may
            be required by state law. NMLS ID# 1628533 |{' '}
            <a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1628533">
              NMLS Consumer Access
            </a>. {brandLabel} does not accept applications for credit, does not
            make loans, and does not make credit decisions; this site does not constitute an
            offer or solicitation to lend. All insurance services are provided by Hearth Home
            Insurance Solutions, Inc. {brandLabel} may be compensated by third-party advertisers.
            Hearth will not charge any fee to a consumer for the submission of the prequalification
            form or the use of {brandLabel}’s credit marketplace.
            5812 Trade Center Dr. Austin, TX 78744
          </>
        ),
      },
      lowerRates: {
        id: 'lower_rates',
        text: (
          <>
            1. For borrowers with excellent credit, personal loan rates often range
            from 5.9% to 12%. The average credit card APR as reported by CreditCards.com
            on 5/19/2017 is 15.79%.
          </>
        ),
      },
      notALenderShort: {
        id: 'not-a-lender-short',
        text: (
          <>
            {brandLabel} does not accept applications for credit, does not make loans, and
            does not make credit decisions. All loans subject to credit approval by {brandLabel}’s
            lending partners. NMLS ID# 1628533
          </>
        ),
      },
      contractorFinancing: {
        id: 'contractor-financing',
        text: (
          <>
            {brandLabel} does not accept applications for credit, does not make loans, and
            does not make credit decisions. All loans subject to credit approval by {brandLabel}’s
            lending partners. NMLS ID# 1628533
          </>
        ),
      },
      bestLoanOptionsDisclosure: {
        id: 'best-loan-options',
        text: (
          <>
            Among the loan options returned by {brandLabel}’s lending partners, these options
            are the closest to the loan principal you requested, have a relatively lower total
            cost and lower APR, and are from lenders with relatively higher funding rates for
            individuals with your self-reported FICO score.
          </>
        ),
      },
      originationFees: {
        id: 'origination-fees',
        text: (
          <>
            Some lending partners charge an origination fee, which is subtracted from the loan’s
            principal amount. {brandLabel} does not charge customers this fee.
          </>
        ),
      },
      trademark: {
        id: 'trademark',
        text: (
          <>
            © 2016–{moment().year()} Shogun Enterprises Inc. {brandLabel}® is a registered trademark
            of Shogun Enterprises Inc. All Rights Reserved.
          </>
        ),
      },
    }),
    getTerms: (
      organization: {
        companyName: string;
        partnership?: Partnership;
      },
      termsType: string,
      jointApplication = false,
    ) => {
      let shareBullet = '';
      if (organization && organization.companyName) {
        const companyName = organization.companyName;
        shareBullet = `
          <li class="terms">
            I agree that ${brandLabel} may share this information along with all information
            previously provided on an aggregated and de-identified bases with Hearth and Hearth’s
            partners, and ${companyName} and that ${companyName} may contact me by phone, text
            messages or emails.
          </li>
        `;
      }

      let jointApplicationBullet = '';
      if (jointApplication && organization.companyName) {
        jointApplicationBullet = `
          <li class="terms">
            The co-borrower has explicitly authorized me to share the co-borrower
            information with Hearth, Hearth's lending partners and ${organization.companyName}.
          </li>
        `;
      }

      /*
       * Note about phone number: for now we want the number displayed to be the same for both
       * home_improvement and shogun_generic, hence why constants is used instead of the number
       * from useVerticalState
       */
      return `
        <ul class="tandc" style="padding-inline-start: 16px;">
          <li class="terms">
            I, the above-named individual, have read and consent to ${brandLabel}'s
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/econsent')}>Terms for Electronic Transactions, Signatures and Records</a>,
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/terms')}>Terms of Use</a>, and
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/privacy')}>Privacy Policy</a>.
          </li>
          ${termsType === 'personal-loans' && `
            <li class="terms">
              I am granting ${brandLabel} my written consent under the Fair
              Credit Reporting Act to share information I supply with
              its partners and financial institutions for purposes of obtaining
              financing for my home improvement project and to obtain
              credit report from one or more credit reporting agencies
              to show me rates and credit terms I may prequalify for when
              obtaining financing for home improvement projects. Hearth will not
              charge any fee to a consumer for the submission of the prequalification
              form or the use of ${brandLabel}’s credit marketplace.
            </li>
          `}
          ${jointApplicationBullet}
          <li class="terms">
            I electronically
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/callconsent')}>authorize</a>
            ${brandLabel} and its partners and affiliated companies and financial institutions to
            contact me via email and/or at the telephone number I have provided, to explore various
            financial products and services I inquired about, including contact through automatic
            dialing systems, artificial or pre-recorded voice messaging, or text message. Message
            and data rates may apply. I understand consent is not required to purchase services from
            ${brandLabel} or from its partners and affiliated companies and financial institutions;
            instead, I may contact ${brandLabel} at <a href=${`tel:${constants.supportNumber}`}>${constants.supportNumberDisplay}</a>
            .5812 Trade Center Dr. Austin, TX 78744
          </li>
          ${shareBullet}
        </ul>
      `;
    },
    getPaymentTerms: (
      companyName: string,
      creditCardTerms: boolean,
    ) => {
      let paymentSpecificTerms = 'electronically debt my bank';
      if (creditCardTerms) {
        paymentSpecificTerms = 'electronically credit my financial institution';
      }

      return `
        <ul class="tandc" style="padding-inline-start: 16px;">
          <li class="terms">
            I agree to Hearth’s
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/econsent')}>Electronic Transaction & Records Policy</a>,
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/terms')}>Terms of Use</a>,
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/privacy')}>Privacy Policy</a>, and
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/callconsent')}>Communications Terms & Conditions</a>.
          </li>
          <li class="terms">
            I
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/callconsent')}>electronically authorize</a>
            Hearth and ${companyName} to call
            or text me at the number I provide with information or offers.
            Message and data rates may apply. I understand consent is not
            required to use services from Hearth or ${companyName};
            instead, I may contact Hearth at
            <a href=${`tel:${constants.supportNumber}`}>${constants.supportNumberDisplay}</a>.
          </li>
          <li class="terms">
            By accepting 
            <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/hearth-payments-terms-of-use')}>Hearth Payments Terms of Use</a>
            , I am authorizing
            the payee to ${paymentSpecificTerms} for the amount invoiced by
            ${companyName}. This authorization is valid for this transaction only.
          </li>
        </ul>
      `;
    },
    getPaymentSetupTerms: () => `I have read and agreed to the
      <a tabindex="-1" target="_blank" rel="noopener noreferrer" href=${marketingUrl('/hearth-payments-contractor-terms-of-use-trial')}>Terms of Use for Hearth Payments</a>.
    `,
  };
};

export default useTerms;
