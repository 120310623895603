import { SvgIconProps } from '../Icon';

const ConstructionIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M7.126 5.875l-1.06 1.061 3.823 3.647 1.06-1.06-3.823-3.648z"
      fill={color}
    />
    <path
      d="M8.75 5c.965 0 1.75-.785 1.75-1.75 0-.29-.08-.56-.205-.8L8.945 3.8 8.2 3.055l1.35-1.35a1.728 1.728 0 00-.8-.205C7.785 1.5 7 2.285 7 3.25c0 .205.04.4.105.58l-.925.925-.89-.89.355-.355-.705-.705L6 1.745a1.5 1.5 0 00-2.12 0l-1.77 1.77.705.705h-1.41l-.355.355 1.77 1.77.355-.355V4.575l.705.705.355-.355.89.89L1.42 9.52l1.06 1.06 5.69-5.685c.18.065.375.105.58.105z"
      fill={color}
    />
  </svg>
);

export default ConstructionIcon;
