import {
  useState,
  useMemo,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import constate from 'constate';
import { isPresent } from 'ts-is-present';
import { useSelector } from 'react-redux';

import { useQueryParams } from 'common/hooks';
import * as localStorage from 'common/services/localStorage';
import { adminSelector } from 'contractor/selectors/contractor';
import {
  useGetClientsQuery,
  useGetClientIndexContractorAndOrganizationQuery,
  IndexClientFragment,
  HomeownerOrderers,
  HomeownerEventBucket,
  PaymentsRole,
} from 'types';

import { generateActivityHeaders } from '../helpers/activityCopy';
import { ViewMode } from './components/MenuItems';

type Palette = {
  headerColor: string;
  accentColor: string;
  activeColor: string;
  activeTextColor: string;
  textColor: string;
  iconColor: Color;
  buttonColor: Color;
  buttonBackgroundColor: Color;
  iconAccentColor: string;
}

// TODO(nav_phase_1): move these queries into useClients once rolled out to everyone
export const GET_CLIENT_INDEX_CONTRACTOR_AND_ORGANIZATION = gql`
  query GetClientIndexContractorAndOrganization {
    contractor {
      id
      role
      paymentsRole
      admin
    }
    organization {
      id
      stripeConnectAccount {
        id
        status
        fullAccess
      }
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  fragment ClientEvent on HearthEvent {
    id
    eventType
    eventBody
    eventAt
  }

  fragment IndexClient on Homeowner {
    id
    fullName
    hearthEvents(first: 1, bucket: $type) {
      nodes {
        id
        ...ClientEvent
      }
    }
    selectedHearthEvents(first: 10 events: [homeowner_project_contract_sent, homeowner_project_contract_signed]) {
      nodes {
        id
        eventAt
        eventType
      }
    }
  }

  query GetClients(
    $admin: Boolean
    $type: HomeownerEventBucket
    $orderBy: HomeownerOrderers
    $searchTerm: String
    $afterCursor: String
  ) {
    homeownerSearch(
      admin: $admin,
      type: $type,
      orderBy: $orderBy,
      first: 20,
      searchTerm: $searchTerm,
      after: $afterCursor
    ) {
      edges {
        node {
          id
          ...IndexClient
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export type ClientHeader = {
  title: string;
}

export type Sections<T> = (T | ClientHeader)[];

export function isHeader<T>(item: T | ClientHeader): item is ClientHeader {
  return (item as ClientHeader).title !== undefined;
}

function createSection<T>(
  iterable: T[],
  keyAccessor: (item: T) => string,
  setNumHeaders: Dispatch<SetStateAction<number>>,
): Sections<T> {
  const headers: string[] = [];

  const finalData: Sections<T> = [];

  iterable.forEach((element, _key) => {
    const accessor = keyAccessor(element);

    if (!headers.includes(accessor)) {
      headers.push(accessor);
      finalData.push({
        title: accessor,
      });
    }

    finalData.push(element);
  });

  setNumHeaders(headers.length);
  return finalData;
}

const createRecencySections = (
  homeowners?: IndexClientFragment[],
): Sections<IndexClientFragment> => [
  {
    title: 'Recent Activity',
  },
  ...(homeowners ?? []),
];

type ClientIndexState = {
  viewMode: ViewMode;
  sortMethod: HomeownerOrderers;
  homeownerBucket: HomeownerEventBucket;
  filterText: string;
}

export enum ClientIndexActionType {
  SET_VIEW_MODE,
  SET_FILTER_TEXT,
  SORT_BY_FIRST_NAME,
  SORT_BY_RECENT_ACTIVITY,
  SET_HOMEOWNER_EVENT_BUCKET,
  SORT_BY_STATUS
}

type ClientIndexActions =
| { type: ClientIndexActionType.SET_VIEW_MODE; data: ViewMode }
| { type: ClientIndexActionType.SET_FILTER_TEXT; data: string }
// eslint-disable-next-line max-len
| { type: ClientIndexActionType.SORT_BY_FIRST_NAME; data: HomeownerOrderers.FIRST_NAME_ASC | HomeownerOrderers.FIRST_NAME_DESC }
| { type: ClientIndexActionType.SET_HOMEOWNER_EVENT_BUCKET; data: HomeownerEventBucket }
| { type: ClientIndexActionType.SORT_BY_RECENT_ACTIVITY }
| { type: ClientIndexActionType.SORT_BY_STATUS }

const reducer = (state: ClientIndexState, action: ClientIndexActions): ClientIndexState => {
  switch (action.type) {
  case ClientIndexActionType.SET_VIEW_MODE:
    return {
      ...state,
      viewMode: action.data,
    };
  case ClientIndexActionType.SORT_BY_FIRST_NAME: {
    switch (state.homeownerBucket) {
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.FINANCING_INDEX,
        sortMethod: action.data,
      };
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.PAYMENTS_INDEX,
        sortMethod: action.data,
      };
    default:
      return {
        ...state,
        sortMethod: action.data,
      };
    }
  }
  case ClientIndexActionType.SET_HOMEOWNER_EVENT_BUCKET: {
    switch (action.data) {
    case HomeownerEventBucket.FINANCING_AND_PAYMENTS:
      return {
        ...state,
        homeownerBucket: action.data,
        sortMethod: HomeownerOrderers.BY_RECENT_EVENTS,
      };
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
      return {
        ...state,
        homeownerBucket: action.data,
        sortMethod: HomeownerOrderers.BY_STATUS_FINANCING_EVENTS,
      };
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX:
      return {
        ...state,
        homeownerBucket: action.data,
        sortMethod: HomeownerOrderers.BY_STATUS_PAYMENT_EVENTS,
      };
    default:
      return state;
    }
  }
  case ClientIndexActionType.SORT_BY_RECENT_ACTIVITY: {
    switch (state.homeownerBucket) {
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.FINANCING_INDEX,
        sortMethod: HomeownerOrderers.BY_RECENT_FINANCING_EVENTS,
      };
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.PAYMENTS_INDEX,
        sortMethod: HomeownerOrderers.BY_RECENT_PAYMENT_EVENTS,
      };
    default:
      return {
        ...state,
        sortMethod: HomeownerOrderers.BY_RECENT_EVENTS,
      };
    }
  }
  case ClientIndexActionType.SORT_BY_STATUS: {
    switch (state.homeownerBucket) {
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.FINANCING_INDEX,
        sortMethod: HomeownerOrderers.BY_STATUS_FINANCING_EVENTS,
      };
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX:
      return {
        ...state,
        homeownerBucket: HomeownerEventBucket.PAYMENTS_INDEX,
        sortMethod: HomeownerOrderers.BY_STATUS_PAYMENT_EVENTS,
      };
    default:
      return state;
    }
  }
  case ClientIndexActionType.SET_FILTER_TEXT:
    return {
      ...state,
      filterText: action.data,
    };
  default:
    return state;
  }
};

type QueryParams = {
  activeTab?: string;
  source?: string;
  view?: 'all_clients' | 'my_clients';
};

const useContext = () => {
  const isAdmin = useSelector(adminSelector);
  const [state, dispatch] = useReducer(reducer, {
    viewMode: isAdmin ? ViewMode.TEAM : ViewMode.INDIVIDUAL,
    sortMethod: HomeownerOrderers.BY_RECENT_EVENTS,
    homeownerBucket: HomeownerEventBucket.FINANCING_AND_PAYMENTS,
    filterText: '',
  });
  const queryParams = useQueryParams<QueryParams>();

  const history = useHistory();

  const contractorOrgQuery = useGetClientIndexContractorAndOrganizationQuery();
  const contractor = useMemo(() => contractorOrgQuery.data?.contractor, [contractorOrgQuery]);
  const contractServiceAccount = useMemo(() =>
    contractorOrgQuery.data?.organization?.contractServiceAccount,
  [contractorOrgQuery]);

  // TODO(contracts): need to update conditional routing for contracts view
  useEffect(() => {
    if (queryParams?.activeTab === 'payments') {
      dispatch({
        type: ClientIndexActionType.SET_HOMEOWNER_EVENT_BUCKET,
        data: HomeownerEventBucket.PAYMENTS_INDEX,
      });
    }
    if (queryParams?.view === 'my_clients') {
      dispatch({
        type: ClientIndexActionType.SET_VIEW_MODE,
        data: ViewMode.INDIVIDUAL,
      });
    }
    if (isAdmin && queryParams?.view === 'all_clients') {
      dispatch({
        type: ClientIndexActionType.SET_VIEW_MODE,
        data: ViewMode.TEAM,
      });
    }

    // If there is no query parameter for the view,
    // then check the last visited view from local storage
    const view = localStorage.getItem('clientView');
    if (!queryParams?.view && view) {
      if (view === 'my_clients') {
        dispatch({
          type: ClientIndexActionType.SET_VIEW_MODE,
          data: ViewMode.INDIVIDUAL,
        });
      }
      if (isAdmin && view === 'all_clients') {
        dispatch({
          type: ClientIndexActionType.SET_VIEW_MODE,
          data: ViewMode.TEAM,
        });
      }
      if (contractor?.paymentsRole !== PaymentsRole.BLOCKED &&
        view === 'all_invoices') {
        history.replace('/dashboard/clients/invoices');
      }
      // Only route to contracts if they have also agreed to
      // the contract terms, otherwise it will loop between
      // the contracts index view and normal client index
      if (view === 'contracts' && contractServiceAccount?.termsAgreedAt) {
        history.replace('/dashboard/clients/contracts');
      }
    }
  }, [
    isAdmin,
    dispatch,
    history,
    contractor?.paymentsRole,
    queryParams?.view,
    queryParams?.activeTab,
    contractServiceAccount?.termsAgreedAt,
  ]);

  const [numHeaders, setNumHeaders] = useState(0);

  const query = useGetClientsQuery({
    variables: {
      orderBy: state.sortMethod,
      admin: state.viewMode === ViewMode.TEAM,
      type: state.homeownerBucket,
      searchTerm: state.filterText,
    },
    fetchPolicy: 'cache-and-network',
    skip: state.viewMode === ViewMode.INVOICE,
  });

  const stripeConnectAccount = useMemo(() =>
    contractorOrgQuery.data?.organization?.stripeConnectAccount,
  [contractorOrgQuery]);

  const homeowners = useMemo(() =>
    // eslint-disable-next-line max-len
    query.data?.homeownerSearch.edges?.filter(isPresent).map(item => item.node).filter(isPresent) || [],
  [query]);

  const totalCount = useMemo(() =>
    numHeaders + (query.data?.homeownerSearch.totalCount ?? 0),
  [numHeaders, query]);

  const pageInfo = useMemo(
    () => query.data?.homeownerSearch.pageInfo,
    [query],
  );

  const sections = useMemo(() => {
    if (homeowners.length === 0) {
      return [];
    }

    switch (state.sortMethod) {
    case HomeownerOrderers.BY_RECENT_EVENTS:
    case HomeownerOrderers.BY_RECENT_FINANCING_EVENTS:
    case HomeownerOrderers.BY_RECENT_PAYMENT_EVENTS: {
      setNumHeaders(1);
      return createRecencySections(homeowners);
    }
    case HomeownerOrderers.BY_STATUS_FINANCING_EVENTS:
    case HomeownerOrderers.BY_STATUS_PAYMENT_EVENTS:
      return createSection<IndexClientFragment>(
        homeowners,
        (item) => {
          if (item.hearthEvents.nodes?.filter(isPresent) &&
          item.hearthEvents.nodes[0] != null
          ) {
            const status = item.hearthEvents.nodes[0].eventType;
            return generateActivityHeaders(status);
          }
          return 'Created';
        },
        setNumHeaders,
      );
    case HomeownerOrderers.FIRST_NAME_ASC:
    case HomeownerOrderers.LAST_NAME_DESC:
    default:
      return createSection<IndexClientFragment>(
        homeowners,
        item => item.fullName[0],
        setNumHeaders,
      );
    }
  }, [homeowners, state.sortMethod]);

  const colorPalette: Palette = useMemo(() => {
    if (state.viewMode === ViewMode.INVOICE) {
      return {
        headerColor: 'common.success500',
        accentColor: 'common.success100',
        activeColor: 'common.success300',
        textColor: 'common.success700',
        activeTextColor: 'common.success900',
        iconColor: 'success500',
        buttonColor: 'success500',
        buttonBackgroundColor: 'success700',
        iconAccentColor: 'common.success100',
      };
    }
    switch (state.homeownerBucket) {
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
      return {
        headerColor: 'common.primary500',
        accentColor: 'common.primary100',
        activeColor: 'common.primary300',
        textColor: 'common.primary700',
        activeTextColor: 'common.primary500',
        iconColor: 'primary500',
        buttonColor: 'primary500',
        buttonBackgroundColor: 'primary700',
        iconAccentColor: 'common.primary100',
      };
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX:
      return {
        headerColor: 'common.success500',
        accentColor: 'common.success100',
        activeColor: 'common.success300',
        textColor: 'common.success700',
        activeTextColor: 'common.success900',
        iconColor: 'success500',
        buttonColor: 'success500',
        buttonBackgroundColor: 'success700',
        iconAccentColor: 'common.success100',
      };
    default:
      return {
        headerColor: 'common.primary500',
        accentColor: 'common.basic100',
        activeColor: 'common.basic500',
        textColor: 'common.basic700',
        activeTextColor: 'common.primary500',
        iconColor: 'basic1100',
        buttonColor: 'primary500',
        buttonBackgroundColor: 'primary700',
        iconAccentColor: 'common.basic300',
      };
    }
  }, [state.homeownerBucket, state.viewMode]);

  const hasPaymentsAccess = stripeConnectAccount?.fullAccess;
  const showPaymentsButton = useMemo(() => {
    if (hasPaymentsAccess) {
      // If the org has payments access but the contractor
      // is payments blocked, don't show the payments button
      return contractor?.paymentsRole !== PaymentsRole.BLOCKED;
    }
    // If a contractor is a member and the org
    // does not have payments access, hide the button
    return contractor?.role !== 'Member';
  }, [hasPaymentsAccess, contractor]);

  return {
    data: {
      homeowners,
      contractor,
      query,
      sections,
      state,
      dispatch,
      pageInfo,
      totalCount,
      stripeConnectAccount,
    },
    inferredStates: {
      showPaymentsSetup: contractor?.paymentsRole === PaymentsRole.ADMIN && !hasPaymentsAccess,
      showPaymentsButton,
    },
    colorPalette,
  };
};

export const [
  ClientIndexProvider,
  useClientIndex,
  useInferredStates,
  useColorPalette,
] = constate(useContext,
  value => value.data,
  value => value.inferredStates,
  value => value.colorPalette,
);
