import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LaunchIcon from '@mui/icons-material/Launch';
import cx from 'classnames';

import { AddNewButton } from 'contractor/modules/ClientView/ClientIndex/components';
import { Button } from 'common/components/material-ui';

import { useWorkflows, ViewMode, ViewModeAction } from '../../../../../contractor/modules/Workflows/useWorkflows';

type Props = {
  title: string;
  color: Color;
}

type ColorPalette = {
  color: Color;
  inactiveBackground: Color,
}

const useStyles = makeStyles(theme => ({
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 32px',
  },
  tabRoot: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tabs: (props: { activeColor: Color }) => ({
    color: theme.palette.common.basic900,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.common.primary100,
    maxWidth: 320,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common[props.activeColor],
    },
  }),
  selected: (props: { activeColor: Color }) => ({
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.basic100,
    color: theme.palette.common[props.activeColor],
    fontWeight: 700,
  }),
  divider: {
    borderRight: `1px solid ${theme.palette.common.basic700}`,
  },
  contractTemplateButton: {
    border: `1px solid ${theme.palette.common.basic100}`,
    borderRadius: 48,
  },
}));

function a11yProps(index: number) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const viewOptions = [
  ViewMode.INDIVIDUAL,
  ViewMode.TEAM,
];

const Header = ({ title, color }: Props): JSX.Element => {
  const { data: { state, dispatch, isAdmin } } = useWorkflows();
  const history = useHistory();

  const palette: Record<'Financing' | 'Contracts' | 'Invoices', ColorPalette> = {
    Financing: {
      color: 'primary500',
      inactiveBackground: 'primary100',
    },
    Contracts: {
      color: 'purple500',
      inactiveBackground: 'purple100',
    },
    Invoices: {
      color: 'success500',
      inactiveBackground: 'success100',
    },
  };

  const colors = palette[title as keyof typeof palette];

  const classes = useStyles({
    activeColor: colors.color,
  });

  const value = viewOptions.indexOf(state.viewMode);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    dispatch({
      type: ViewModeAction.SET_VIEW_MODE,
      data: viewOptions[newValue],
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={`common.${color}`}
      borderRadius="8px 8px 0 0"
      pt={2.5}
      width="100%"
    >
      <Box color="common.basic100" px={4}>
        <Typography variant="h2" color="inherit">{title}</Typography>
        <Box pt={3}>
          {isAdmin &&
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleTabChange}
              aria-label="Client header"
              textColor="inherit"
              indicatorColor="secondary"
            >
              <Tab
                label="My Clients"
                sx={{ textTransform: 'uppercase' }}
                classes={{
                  root: cx(classes.tabRoot, { [classes.divider]: value === 1 }),
                  selected: classes.selected,
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="All Clients"
                sx={{ textTransform: 'uppercase' }}
                classes={{
                  root: cx(classes.tabRoot, { [classes.divider]: value === 0 }),
                  selected: classes.selected,
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          }
        </Box>
      </Box>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pr={4}
        mt={-2}
      >
        {title === 'Contracts' && isAdmin ?
          <Button
            variant="text"
            buttonType="common.basic"
            className={classes.contractTemplateButton}
            onClick={() => history.push('/dashboard/tools/contract-template')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              px={2}
            >
              <LaunchIcon style={{ marginRight: 10 }} />
              <Typography variant="button">
                Go to your contract template
              </Typography>
            </Box>
          </Button> :
          <AddNewButton
            color="basic100"
            menuColor={color}
            buttonVariant="outlined"
          />
        }
      </Box>
    </Box>
  );
};

export default Header;
