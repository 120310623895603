import { Box, Card, Typography } from '@mui/material';

import { Button } from 'common/components/material-ui';

type Props = {
  onClick: () => void;
}

const EasyCoborrowerReapplyCard = ({ onClick }: Props): JSX.Element => (
  <Card style={{ display: 'flex' }}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
      px={5}
      pt={2.5}
      pb={5}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">
          Add a co-borrower
        </Typography>
        <Box m={1} />
        <Typography variant="body1">
          We noticed you’re applying alone.
        </Typography>
        <Box m={1} />
        <Typography variant="body1">
          Adding a co-borrower can boost your overall credit
          profile and make it easier for lenders to provide
          options that fit your needs.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="contained"
          buttonType="primary"
          onClick={onClick}
          style={{ minWidth: 180 }}
        >
          Add a co-borrower
        </Button>
      </Box>
    </Box>
  </Card>
);

export default EasyCoborrowerReapplyCard;
