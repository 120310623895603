import {
  Menu,
  Box,
  MenuItem,
  IconButton,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AccountCircle,
  Settings,
  ExitToApp,
  ArrowDropDown,
} from '@mui/icons-material';

import { Button, RouterLink } from 'common/components/material-ui';

import { useDashboardNavigation, useDashboardNavActions } from '../useDashboardNavigation';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 36,
    color: theme.palette.common.primary500,
  },
  button: {
    color: theme.palette.common.primary500,
    borderColor: theme.palette.common.primary500,
  },
  menuItem: {
    minWidth: 150,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const AccountButton = (): JSX.Element => {
  const classes = useStyles();
  const { handleAccountOpen, handleAccountClose, handleLogout } = useDashboardNavActions();
  const { uiStates: { anchorEl } } = useDashboardNavigation();
  const accountOpen = Boolean(anchorEl);

  return (
    <>
      <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
        <IconButton
          aria-owns={accountOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleAccountOpen}
          className={classes.icon}
          size="large"
        >
          <AccountCircle fontSize="large" color="inherit" />
        </IconButton>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button
          size="small"
          aria-owns={accountOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleAccountOpen}
          variant="outlined"
          startIcon={<AccountCircle />}
          endIcon={<ArrowDropDown fontSize="large" />}
          className={classes.button}
        >
          <Typography variant="subtitle1" color="inherit">
            Account
          </Typography>
        </Button>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={accountOpen}
        onClose={handleAccountClose}
      >
        <RouterLink
          to="/dashboard/profile"
          color="inherit"
        >
          <MenuItem onClick={handleAccountClose} className={classes.menuItem}>
            <ListItemIcon className={classes.icon}>
              <AccountCircle color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
        </RouterLink>
        <RouterLink
          to="/dashboard/settings"
          color="inherit"
        >
          <MenuItem onClick={handleAccountClose} className={classes.menuItem}>
            <ListItemIcon className={classes.icon}>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
        </RouterLink>
        <MenuItem
          onClick={() => {
            handleAccountClose();
            handleLogout();
          }}
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.icon}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountButton;
