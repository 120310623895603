
type Props = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  className?: string;
  noPadding?: boolean;
  children: React.ReactChildren | React.ReactChild | React.ReactNode;
}

const Col = ({
  xs = 12,
  sm,
  md,
  lg,
  xsOffset,
  smOffset,
  mdOffset,
  lgOffset,
  children,
  className = '',
  noPadding,
  ...otherProps
}: Props): JSX.Element => {
  const classes = className.split(' ');
  if (xs) {
    classes.push(`col-xs-${xs}`);
  }
  if (sm) {
    classes.push(`col-sm-${sm}`);
  }
  if (md) {
    classes.push(`col-md-${md}`);
  }
  if (lg) {
    classes.push(`col-lg-${lg}`);
  }
  if (xsOffset || xsOffset === 0) {
    classes.push(`col-xs-offset-${xsOffset}`);
  }
  if (smOffset || smOffset === 0) {
    classes.push(`col-sm-offset-${smOffset}`);
  }
  if (mdOffset || mdOffset === 0) {
    classes.push(`col-md-offset-${mdOffset}`);
  }
  if (lgOffset || lgOffset === 0) {
    classes.push(`col-lg-offset-${lgOffset}`);
  }
  if (noPadding) {
    classes.push('col-no-padding');
  }
  return (<div className={classes.join(' ')} {...otherProps}>{children}</div>);
};

export default Col;
