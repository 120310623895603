import { ApiResponse } from 'promise-action';
import * as contentful from 'contentful';

import client from './client';

// TODO(refactor/bad-export)
export type Video = Omit<PresaleVideo, 'video'> & {
  videoUrl: string;
}

export type PresaleVideo = {
  id: string;
  title: string;
  subtitle: string;
  duration: string;
  video: contentful.Asset;
}

export type Course = {
  title: string;
  videoReference: contentful.Sys['contentType'][];
}

const resourceCenterClient = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_RESOURCE_CENTER_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_AUTHORIZATION_KEY,
});

const presaleCourseClient = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_PRESALE_COURSE_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_PRESALE_COURSE_AUTHORIZATION_KEY,
});

type FetchContractorDataParams = { contractorId: number }
type FetchContractorDataResponse = ApiResponse<{
  articles: Record<string, ArticleContractorMetadata>;
}>;
export const fetchContractorData =
  ({ contractorId }: FetchContractorDataParams): Promise<FetchContractorDataResponse> =>
    client.get(`/contractors/${contractorId}/articles`);

export const fetchResourceCenter = (): Promise<contentful.EntryCollection<ContentfulEntry>> =>
  resourceCenterClient.getEntries();

// eslint-disable-next-line max-len
export const fetchPresaleCourse = (): Promise<contentful.EntryCollection<Partial<PresaleVideo & Course>>> =>
  presaleCourseClient.getEntries<Partial<PresaleVideo & Course>>();

type MarkHelpfulParams = { articleId: string; isHelpful: string }
type MarkHelpfulResponse = { articleId: string; helpful: boolean; markedHelpfulAt: string | null }
export const markHelpful =
  ({ articleId, isHelpful }: MarkHelpfulParams): Promise<ApiResponse<MarkHelpfulResponse>> =>
    client.post('/articles/mark_helpful', { articleId, isHelpful });

type MarkReadParams = { articleId: string }
type MarkReadResponse = { articleId: string; readAt: string | null }
export const markRead = ({ articleId }: MarkReadParams): Promise<ApiResponse<MarkReadResponse>> =>
  client.post('/articles/mark_read', { articleId });
