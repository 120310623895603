import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { useHistory } from 'react-router-dom';

import { Button } from 'common/components/material-ui';

import { useWizard } from '../useContractWizard';
import { WizardHeader, WizardContent, SampleContractModal } from '../components';

const WelcomeStep = (): JSX.Element => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { nextStep } = useWizard();
  const history = useHistory();

  return (
    <>
      <SampleContractModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      />
      <WizardHeader title="Customize your contract in 2 easy steps" />
      <WizardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileOrSmall ? 'h3' : 'h2'}>
              Build your digital contract template
            </Typography>
            <Box display="flex" color="common.purple500" mt={2}>
              <Box ml={0.5} mr={1}>
                <FlagOutlinedIcon color="inherit" style={{ fontSize: 24 }} />
              </Box>
              <Typography variant="subtitle1">
                Included in your subscription!
              </Typography>
            </Box>
            <Box color="common.basic1100" mt={5} mb={{ xs: 3, md: 7 }}>
              <Typography variant={isMobileOrSmall ? 'subtitle1' : 'h5'}>
                Create a custom contract template in less than 2 minutes.
              </Typography>
              <Box mt={2} />
              <Typography variant={isMobileOrSmall ? 'subtitle1' : 'h5'}>
                After you create a template, you’ll be able to send digital contracts and get
                notified when your customer e-signs.
              </Typography>
            </Box>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <img
                src="https://hearth.imgix.net/contractormarketing/contracts-graphic-mobile.png"
                alt="contract graphic"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginBottom: 32,
                  maxWidth: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" width={isMobileOrSmall ? '100%' : 290}>
              <Button
                variant="contained"
                buttonType="common.purple"
                size="large"
                rounded
                onClick={() => {
                  nextStep();
                  history.replace('/dashboard/tools/contract-template/setup?source=contract_landing');
                }}
                fullWidth
                data-cy="getStartedButton"
              >
                Let’s get started →
              </Button>
              <Box m={2} />
              <Button
                variant="outlined"
                buttonType="common.purple"
                size="large"
                rounded
                onClick={() => setPreviewOpen(true)}
                fullWidth
                data-cy="getStartedButton"
              >
                Preview a sample contract
              </Button>
            </Box>
          </Grid>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Grid item xs={12} md={7} container alignItems="flex-end">
              <img
                src="https://hearth.imgix.net/contractormarketing/contracts-graphic.png"
                alt="contract graphic"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginBottom: -48,
                  width: 540,
                  maxWidth: '100%',
                  aspectRatio: '1.2',
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </WizardContent>
    </>
  );
};

export default WelcomeStep;
