import * as React from 'react';
import { Box, Typography } from '@mui/material';

import { Switch } from 'common/components/material-ui';

export type ToggleRowProps = {
  prompt: React.ReactNode;
  value: boolean;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  showLabel?: boolean;
}

const ToggleRow = ({
  prompt,
  value,
  name,
  onChange,
  disabled,
  showLabel = true,
}: ToggleRowProps): JSX.Element => (
  <Box
    my={1}
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Box>
      <Typography variant="subtitle1">{prompt}</Typography>
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center" ml={2}>
      {showLabel &&
        <Typography variant="body1">
          {value ? 'Yes' : 'No' }
        </Typography>
      }
      <Switch
        name={name}
        checked={value}
        onChange={onChange}
        disabled={disabled}
        color="primary500"
      />
    </Box>
  </Box>
);

export default ToggleRow;
