import { Helmet } from 'react-helmet';

import ForgotPassword from 'contractor/modules/ForgotPassword';
import { useVerticalState } from 'common/hooks';

const ForgotPasswordPage = (): JSX.Element => {
  const [{ brandMetadata }] = useVerticalState();

  return (
    <div>
      <Helmet>
        <title>Reset password | {brandMetadata.label}</title>
      </Helmet>
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
