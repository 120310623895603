import { Box, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { differenceInDays } from 'date-fns';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ButtonBase } from 'common/components/material-ui';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderTop: `1px solid ${theme.palette.common.basic500}`,
  },
  buttonBox: {
    width: '100%',
    height: 72,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

type Props = {
  fullName: string;
  submittedAt: string;
  rating: number;
  onClick: () => void;
}

const FeedbackRow = ({
  fullName,
  submittedAt,
  rating,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <ButtonBase onClick={onClick} className={classes.button}>
      <div className={classes.buttonBox}>
        <Typography variant="subtitle1">
          {fullName}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="caption">
            <Box color="common.basic900">
              {differenceInDays(new Date(), Date.parse(submittedAt))}{' '}
              days ago
            </Box>
          </Typography>
          <Box m={2} />
          <Typography variant="h4">
            <Box color="common.primary500" fontWeight="700">
              {rating}/5
            </Box>
          </Typography>
          <Box
            ml={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            color="common.basic700"
          >
            <ChevronRightIcon fontSize="large" />
          </Box>
        </Box>
      </div>
    </ButtonBase>
  );
};

export default FeedbackRow;

