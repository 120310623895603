import { useState } from 'react';
import { Divider, Typography } from '@mui/material';

import { Smidge } from 'common/components/layouts';
import { Button } from 'common/components/material-ui';
import { BannerSelectionType } from 'contractor/types/verticals';

import GetBannerModal from './GetBannerModal';

type Props = {
  color: string;
  handleExpand: (index: number) => void;
  content: BannerSelectionType;
}

const BannerSelection = ({
  color,
  handleExpand,
  content,
}: Props): JSX.Element => {
  const { imgAlt, imgSrc, bannerOptions } = content;
  const [banner, setBanner] = useState(Object.keys(bannerOptions)[0]);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <>
        <GetBannerModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          banner={bannerOptions[banner]}
          color={color}
          itemType="banner"
          handleExpand={handleExpand}
        />
        <Divider />
        <Smidge x={2} />
        {Object.entries(bannerOptions).map((bannerItem) => {
          const [bannerName, bannerInfo] = bannerItem;
          /* eslint-disable no-template-curly-in-string */
          const bannerImg =
            imgSrc.replace('${BANNERSIZE}', bannerInfo.size)
              .replace('${BANNERCOLOR}', color)
              .replace('${BANNEROPTIONS}', bannerInfo.extraOptions ? `_${bannerInfo.extraOptions}` : '');
          const bannerAlt =
            imgAlt.replace('${BANNERSIZE}', bannerInfo.size).replace('${BANNERCOLOR}', color);
          /* eslint-enable no-template-curly-in-string */
          return (
            <div key={bannerName}>
              <Typography>{bannerInfo.size}</Typography>
              <Typography>{bannerInfo.description}</Typography>
              <img
                src={bannerImg}
                alt={bannerAlt}
                style={{ maxWidth: '100%' }}
              />
              <Smidge x={2} />
              <Button
                variant="contained"
                onClick={() => {
                  setBanner(bannerItem[0]);
                  setModalOpen(true);
                }}
              >
                Install this banner
              </Button>
              <Smidge x={1} />
            </div>
          );
        })}
      </>
    </div>
  );
};

export default BannerSelection;
