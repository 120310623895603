import { ExecutionResult } from 'graphql';
import { CustomError } from 'ts-custom-error';
import { camelize } from 'humps';
import { sentenceCase } from 'change-case';
import { ConditionalPick, UnionToIntersection } from 'type-fest';

import {
  Maybe,
  ValidationError as ServerValidationError,
  InternalError as ServerInternalError,
  Mutation,
} from 'types';

/*
 * Error classes that parseGql throws
 */
export class UnexpectedError extends CustomError {
  constructor(tag: string, messages: string[]) {
    super(`[${tag}] Unexpected error occured: ${messages.join(', ')}`);
  }
}

export class InternalError extends CustomError {
  constructor(tag: string, messages: string[]) {
    super(`[${tag}] Internal error occured: ${messages.join(', ')}`);
  }
}

export class ValidationError extends CustomError {
  formErrors: Record<string, string[]>;
  userMessages: string[];

  constructor(tag: string, userMessages: string[], formErrors: Record<string, string[]>) {
    super(`[${tag}] Validation error received: ${userMessages.join(', ')} ` +
      `and paths: [${Object.keys(formErrors).join(', ')}]`,
    );
    this.formErrors = formErrors;
    this.userMessages = userMessages;
  }
}

/*
 * Helper types
 */

type IsUnion<T> = [T] extends [UnionToIntersection<T>] ? false : true
type UnionMutationKey = keyof ConditionalPick<{
  [T in keyof Mutation]: IsUnion<NonNullable<Mutation[T]>>;
}, true | undefined>;

type MutationUnionNames<T extends UnionMutationKey> = NonNullable<NonNullable<Mutation[T]>['__typename']>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WithSuffix<S extends string, Suffix extends string> = S extends `${infer _Prefix}${Suffix}` ? S : never;
type SuccessUnionName<T extends UnionMutationKey> = WithSuffix<MutationUnionNames<T>, 'Success'>;
type FailureUnionName<T extends UnionMutationKey> = WithSuffix<MutationUnionNames<T>, 'Failure'>;

type SuccessData<T extends UnionMutationKey> = Extract<
NonNullable<Mutation[T]>,
{ __typename?: SuccessUnionName<T> }
>;
type ErrorData<T extends UnionMutationKey> = Extract<
NonNullable<Mutation[T]>,
{
  __typename?: FailureUnionName<T>,
  readonly errors: ReadonlyArray<ServerValidationError | ServerInternalError>;
}
>;

type ParsableMutationKey = keyof ConditionalPick<{
  [T in UnionMutationKey]: NonNullable<Mutation[T]> extends (SuccessData<T> | ErrorData<T>) ?
    true :
    false
}, true | undefined>;

/*
 * Definition of mutation structure
 */

type ErrorResult<T extends ParsableMutationKey> = {
  readonly __typename?: FailureUnionName<T>;
  readonly errors: ReadonlyArray<ServerValidationError | ServerInternalError>;
};

type SuccessResult<T extends ParsableMutationKey, R> =
  { readonly __typename?: SuccessUnionName<T> } & R;

type Result<T extends ParsableMutationKey, R> =
  ErrorResult<T> |
  SuccessResult<T, R>;

type OuterResult<T extends ParsableMutationKey, R> =
  {
    readonly [key in T]?: Maybe<Result<T, R>>;
  };

/*
 * Type predicate helper functions
 */

function isErrorResult<
  T extends ParsableMutationKey,
  R extends Record<string, unknown>,
>(
  serverResult: Maybe<Result<T, R>> | undefined,
  errorType: FailureUnionName<T>,
): serverResult is ErrorResult<T> {
  return serverResult != null && serverResult.__typename === errorType;
}

function isSuccessResult<
  T extends ParsableMutationKey,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any>,
>(
  serverResult: Maybe<Result<T, R>> | undefined,
  successType: SuccessUnionName<T>,
): serverResult is SuccessResult<T, R> {
  return serverResult != null && serverResult.__typename === successType;
}

function isInternalError(error: ServerInternalError | ServerValidationError):
  error is ServerInternalError {
  return error.__typename === 'InternalError';
}

function isValidationError(error: ServerInternalError | ServerValidationError):
  error is ServerValidationError {
  return error.__typename === 'ValidationError';
}

/*
 * Other helper functions
 */

// sometimes, formErrors have duplicative first words (e.g., Password password must contain...)
// this function removes the duplicative word when it is present
const dedupeFirstWord = (text: string): string => {
  const textArr = text.split(' ');
  if (textArr[0].toLowerCase() === textArr?.[1]?.toLowerCase()) {
    return sentenceCase(textArr.slice(1).join(' '));
  }
  return text;
};

/*
 * Main function
 */

export default function parseGql<
  ParsedResult,
  Tag extends ParsableMutationKey = ParsableMutationKey,
>(
  tag: Tag,
  result: ExecutionResult<OuterResult<Tag, ParsedResult>>,
  successType: SuccessUnionName<Tag>,
  errorType: FailureUnionName<Tag>,
): ParsedResult {
  if (!result.data || result.errors) {
    const errors: string[] = [];

    if (!result.data) {
      errors.push('Data field is empty');
    }

    if (result.errors) {
      errors.push(...result.errors.map(e => e.message));
    }
    throw new UnexpectedError(tag, errors);
  }

  const { data } = result;

  if (Object.keys(data).length !== 1) {
    throw new UnexpectedError(tag, ['parseGql currently only supports a single mutation']);
  }

  const serverResult = data[tag];

  if (!serverResult) {
    throw new UnexpectedError(tag, ['parseGql received empty data from server']);
  }

  if (isSuccessResult(serverResult, successType)) {
    return serverResult as ParsedResult;
  }

  if (isErrorResult(serverResult, errorType)) {
    const errors = serverResult.errors;

    const internalErrors = errors.filter(isInternalError);
    const validationErrors = errors.filter(isValidationError);

    const formErrors: Record<string, string[]> = {};
    const userMessages: string[] = [];

    validationErrors.forEach((error) => {
      if (error.path) {
        const field = error.path[error.path.length - 1];
        const camelizedField = camelize(field);
        const validationError = dedupeFirstWord(`${(field)} ${error.message}`);
        // if field doesn't exist, then set value to array containing error message
        if (!Object.prototype.hasOwnProperty.call(formErrors, camelizedField)) {
          formErrors[camelizedField] = [sentenceCase(validationError)];
        } else {
          formErrors[camelizedField].push(sentenceCase(validationError));
        }
      } else {
        userMessages.push(error.message);
      }
    });

    if (validationErrors.length > 0) {
      throw new ValidationError(tag, userMessages, formErrors);
    }

    if (internalErrors.length > 0) {
      throw new InternalError(tag, internalErrors.map(error => error.message));
    }

    throw new UnexpectedError(tag, ['Received data with error payload but empty']);
  }

  throw new UnexpectedError(tag, ['Received data with undefined typename from server']);
}

/*
 * Helper method to determine Success payload type of a mutation
 */

export type PayloadType<
  M extends ExecutionResult<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly [key in T]?: any;
  }>,
  T extends ParsableMutationKey,
> = Extract<
NonNullable<NonNullable<M['data']>[T]>,
{ __typename?: SuccessUnionName<T> }
>;
