import { useCallback } from 'react';
import { gql } from '@apollo/client';

import parseGql, { PayloadType } from 'common/api/parseGql';
import { useSnack } from 'common/utils/snackCart';

import { ContactMedium, useSendDefaultFinancingInfoMutation } from 'types';

import useGql from './useGql';

export const SEND_DEFAULT_FINANCING_INFO = gql`
  mutation SendDefaultFinancingInfo ($id: ID!, $mediums: [ContactMedium!]!) {
    sendDefaultFinancingInfo(homeownerId: $id, mediums: $mediums) {
      ... on SendDefaultFinancingInfoSuccess {
        homeowner {
          id
          email
        }
      }
      ... on SendDefaultFinancingInfoFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

const useSendEmail = () => {
  const { successSnack } = useSnack();
  const { handleMutationError } = useGql();
  const [sendDefaultFinancingInfoMutation] = useSendDefaultFinancingInfoMutation();

  const sendDefaultFinancingInfo = useCallback(async (
    id: string,
    mediums: ContactMedium[],
  ): Promise<void> => {
    try {
      const response = await sendDefaultFinancingInfoMutation({
        variables: {
          id,
          mediums,
        },
      });

      parseGql<PayloadType<typeof response, 'sendDefaultFinancingInfo'>>(
        'sendDefaultFinancingInfo',
        response,
        'SendDefaultFinancingInfoSuccess',
        'SendDefaultFinancingInfoFailure',
      );

      successSnack('Email and text reminder successfully sent');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [sendDefaultFinancingInfoMutation, successSnack, handleMutationError]);

  return {
    sendDefaultFinancingInfo,
  };
};

export default useSendEmail;
