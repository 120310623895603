import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { ButtonBase } from 'common/components/material-ui';
import { Icon } from 'common/components';
import { Popup } from 'contractor/components/widgets';
import { Form, SelectField, SubmitButton } from 'common/components/formik';
import userFeatureFlags from 'common/graphql/featureFlags';

import { useSettings, useSettingsActions } from './useSettings';
import { FlyerInformation, ProjectIntentContract } from '../Settings';

export enum FilterOptions {
  TWENTY = '0.2',
  TWENTY_FIVE = '0.25',
  THIRTY = '0.3',
  THIRTY_FIVE = '0.35',
  NONE = 'none',
}

export const filterOptions = [
  { value: FilterOptions.TWENTY, label: '20%' },
  { value: FilterOptions.TWENTY_FIVE, label: '25%' },
  { value: FilterOptions.THIRTY, label: '30%' },
  { value: FilterOptions.THIRTY_FIVE, label: '35%' },
  { value: FilterOptions.NONE, label: 'No Cap' },
];

export const rateCapToFilterOption = (rateCap: number | null): FilterOptions => {
  switch (rateCap) {
  case 0.2:
    return FilterOptions.TWENTY;
  case 0.25:
    return FilterOptions.TWENTY_FIVE;
  case 0.3:
    return FilterOptions.THIRTY;
  case 0.35:
    return FilterOptions.THIRTY_FIVE;
  default:
    return FilterOptions.NONE;
  }
};

type RowProps = {
  first: string;
  second: string;
  third: string;
  bgcolor: string;
  color: string;
}

const Row = ({
  first,
  second,
  third,
  bgcolor,
  color,
}: RowProps) => (
  <Box mt={1} p={1} borderRadius="8px" bgcolor={bgcolor} color={color} display="flex" flexDirection="row">
    <Box flex={2} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Typography variant="subtitle2">
        {first}
      </Typography>
    </Box>
    <Box flex={2} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Typography align="center" variant="subtitle2">
        {second}
      </Typography>
    </Box>
    <Box flex={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Typography align="center" variant="subtitle2">
        {third}
      </Typography>
    </Box>
  </Box>
);

type RatesRowProps = {
  ficoRange: string;
  prequalifyRate: string;
  aprRange: string;
}

const RatesRow = ({ ficoRange, prequalifyRate, aprRange }: RatesRowProps) => (
  <Row
    first={ficoRange}
    second={prequalifyRate}
    third={aprRange}
    bgcolor="common.basic300"
    color="common.basic1100"
  />
);

type Values = {
  rateCap: FilterOptions;
};

const FinancingSettings = (): JSX.Element | null => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { organization } = useSettings();
  const { editOrganizationSettings } = useSettingsActions();
  const { rateCapping } = useReactiveVar(userFeatureFlags);

  const currentRateCap = useMemo(() =>
    rateCapToFilterOption(organization?.rateCap ?? null),
  [organization?.rateCap]);
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'financing' });
  });

  if (!organization) {
    return null;
  }

  return (
    <>
      {rateCapping &&
      <>
        <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
          <Box p={2} minWidth={450}>
            <Typography variant="h5">Hearth Homeowners</Typography>
            <Box mt={2} />
            <Row
              first="FICO"
              second="Likelihood to pre-qualify"
              third="Estimated APR range"
              bgcolor="common.primary100"
              color="common.primary500"
            />
            <RatesRow
              ficoRange="450 - 650"
              prequalifyRate="42.7%"
              aprRange="21.49% - 34.70%"
            />
            <RatesRow
              ficoRange="651 - 700"
              prequalifyRate="72.8%"
              aprRange="11.95% - 27.19%"
            />
            <RatesRow
              ficoRange="701 - 750"
              prequalifyRate="82.8%"
              aprRange="6.95% - 19.74%"
            />
            <RatesRow
              ficoRange="750+"
              prequalifyRate="92.9%"
              aprRange="4.99% - 10.69%"
            />
          </Box>
        </Popup>
        <Box bgcolor="common.basic100" borderRadius="20px" p={5} mb={3}>
          <Typography variant="h2">
            Financing Settings
          </Typography>
          <Box mt={5} p={3} bgcolor="common.basic300" borderRadius="8px">
            <Typography variant="subtitle1">
              <Box fontWeight={700}>
                Prioritize client APR rates
              </Box>
            </Typography>
            <Box mt={1} />
            <Typography variant="body1">
              Set your desired APR maximum
            </Typography>
            <Box mt={2} />
            <Form<Values>
              initialValues={{ rateCap: currentRateCap }}
              onSubmit={async (values) => {
                // Passing an undefined argument doesn't work if
                // we want to reset the value of rateCap to nil
                // on the backend, but passing it as null does
                let rateCap: number | null = null;
                if (values.rateCap !== FilterOptions.NONE) {
                  rateCap = parseFloat(values.rateCap);
                }
                await editOrganizationSettings({ rateCap });
              }}
            >
              <SelectField
                name="rateCap"
                items={filterOptions}
                style={{ backgroundColor: '#FFFFFF' }}
              />
              <Box mt={2} />
              <ButtonBase onClick={() => setPopupOpen(true)}>
                <Icon
                  name="info-outline"
                  size={16}
                  color="primary500"
                />
                <Box ml={1} />
                <Typography variant="caption">
                  <Box color="common.primary500">
                    Learn more about our average APRs
                  </Box>
                </Typography>
              </ButtonBase>
              <Box mt={3} p={2} bgcolor="common.warning100" color="common.warning900" borderRadius="8px">
                <Typography variant="body2">
                  Disclaimer: We will prioritize your homeowners’ preferences.
                  Please note that homeowners will be able to see all
                  pre-qualified loan options if they choose to.
                  Changes made here will apply to all accounts within your organization.
                </Typography>
              </Box>
              <Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
                <SubmitButton
                  variant="contained"
                  buttonType="common.primary"
                  style={{ width: 250 }}
                >
                  Save
                </SubmitButton>
              </Box>
            </Form>
          </Box>
        </Box>
      </>
      }
      <Box mb={3}>
        <FlyerInformation />
      </Box>
      <ProjectIntentContract />
    </>
  );
};

export default FinancingSettings;
