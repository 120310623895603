import { SvgIconProps } from '../Icon';

const PaperPlaneIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 27 26" fill="none" {...otherProps}>
    <path
      d="M7.954 15.265v9.145l3.43-2.83M26.244.632L7.954 15.265M.637 9.778L26.244.632l-4.573 24.692L.637 9.778z"
      stroke={color}
      strokeWidth={0.915}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PaperPlaneIcon;
