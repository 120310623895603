import { useCallback } from 'react';
import {
  Switch as MaterialSwitch,
  SwitchProps,
  Theme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

export type Props = Omit<SwitchProps, 'onChange' | 'color'> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color: Color;
};

const Switch = ({
  onChange,
  color,
  ...otherProps
}: Props): JSX.Element => {
  const onSwitch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  }, [onChange]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={(theme: Theme) => ({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              main: theme.palette.common[color],
            },
          },
        })}
      >
        <MaterialSwitch
          onChange={onSwitch}
          color="primary"
          {...otherProps}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Switch;
