import { createSelector } from 'reselect';

import { AppState } from 'stores';
import { UserState } from 'common/reducers/UserState';

export const userSelector = (state: AppState): UserState => state.user;

export const hearthUidSelector = createSelector(
  userSelector,
  user => user.hearthUid,
);

export const deviceIdSelector = createSelector(
  userSelector,
  user => user.deviceId,
);

export const sandboxSelector = createSelector(
  userSelector,
  user => (user.sandbox || user.isCsUser),
);

// Did they log in through mantle??
export const isCsUserSelector = createSelector(
  userSelector,
  user => user.isCsUser || false,
);

export const sakeSelector = createSelector(
  userSelector,
  user => user.sake,
);

export const sessionIdSelector = createSelector(
  userSelector,
  user => user.sessionId,
);

export const anonymousIdSelector = createSelector(
  userSelector,
  user => user.anonymousId,
);

export const abTestSelector = createSelector(
  userSelector,
  user => user.abTest,
);
