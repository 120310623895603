import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { ToggleRow } from 'common/components/widgets';

import { useSettingsActions, useSettings } from './useSettings';

const DemoMode = (): JSX.Element | null => {
  const { contractor } = useSettings();
  const { editContractorSettings } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'demo_mode' });
  });

  if (!contractor) return null;

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Demo Mode
      </Typography>
      <Box my={3}>
        <ToggleRow
          prompt="Demo Mode"
          name="demoMode"
          value={contractor.demoMode}
          showLabel={false}
          onChange={() => {
            itly.changeSetting({ fields: ['demo_mode'] });
            editContractorSettings({ demoMode: !contractor.demoMode });
          }}
        />
      </Box>
    </Box>
  );
};

export default DemoMode;
