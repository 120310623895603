import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';

import { Button, Checkbox, Input } from 'common/components/material-ui';
import { useLogEvent } from 'common/hooks';

import { useEditContractTemplateUIStates } from '../useEditContractTemplate';
import { Checkbox as CheckboxType } from '../EditContractTemplate';

const useStyles = makeStyles(theme => ({
  darkDivider: {
    background: theme.palette.common.basic900,
  },
}));

const CustomChecklist = (): JSX.Element => {
  const classes = useStyles();
  const itly = useLogEvent();
  const { setEditingChecklist } = useEditContractTemplateUIStates();

  const [{ value: customChecklist }, , { setValue: setCustomChecklist }] =
    useField<CheckboxType[]>('customChecklist');

  const [editCheckbox, setEditCheckbox] = useState<number | null>(null);
  const [tempValue, setTempValue] = useState<string>('');

  return (
    <Box pt={customChecklist.length < 1 ? 5 : 3} px={2}>
      {customChecklist.length < 1 ?
        <>
          <Button
            variant="text"
            buttonType="common.primary"
            onClick={() => {
              setCustomChecklist([{ itemName: '', isChecked: false }]);
              itly.addChecklistInContractTemplate();
            }}
          >
            + Add a checklist
          </Button>
          <Divider style={{ marginTop: 32 }} />
        </> :
        <Box display="flex" flexDirection="column">
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">
              <Box fontWeight={600}>
                Custom checklist
              </Box>
            </Typography>
            <Button
              variant="text"
              buttonType="common.primary"
              onClick={() => setCustomChecklist([])}
            >
              Remove
            </Button>
          </Box>
          <Divider />
          {customChecklist.map((checkbox, i) => (
            <Box display="flex" flexDirection="column" key={checkbox.itemName || `Service ${i + 1}`}>
              {editCheckbox !== i ?
                <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Checkbox
                    label={
                      <Typography variant="subtitle2">
                        <Box color="common.basic1100">
                          {checkbox.itemName || `Service ${i + 1}`}
                        </Box>
                      </Typography>
                    }
                    // checkbox is disabled because it should not be checkable in the template
                    // rather, it gets checked at the send stage
                    disabled
                  />
                  <Box display="flex">
                    <IconButton
                      onClick={() => {
                        setEditingChecklist(true);
                        setTempValue(customChecklist[i].itemName);
                        setEditCheckbox(i);
                      }}
                      size="large"
                    >
                      <Box display="flex" alignItems="center" color="common.primary500">
                        <EditOutlinedIcon fontSize="large" />
                      </Box>
                    </IconButton>
                    <IconButton
                      onClick={() => setCustomChecklist(
                        customChecklist.slice(0, i).concat(customChecklist.slice(i + 1)),
                      )}
                      size="large"
                    >
                      <Box display="flex" alignItems="center" color="common.basic900">
                        <CloseIcon fontSize="large" />
                      </Box>
                    </IconButton>
                  </Box>
                </Box> :
                <Box px={2} pt={3} pb={2}>
                  <Input
                    name="checklistItem"
                    label="Checklist item"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTempValue(e.target.value)
                    }
                    autoFocus
                    value={tempValue}
                    fullWidth
                  />
                  <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setEditCheckbox(null);
                        setTempValue('');
                        setEditingChecklist(false);
                      }}
                    >
                      <Typography variant="subtitle2">
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      buttonType="common.primary"
                      onClick={() => {
                        const checklistCopy = [...customChecklist];
                        checklistCopy[editCheckbox] = {
                          ...checklistCopy[editCheckbox],
                          itemName: tempValue,
                        };
                        setEditCheckbox(null);
                        setCustomChecklist(checklistCopy);
                        setTempValue('');
                        setEditingChecklist(false);
                      }}
                      disabled={customChecklist[editCheckbox].itemName === tempValue}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              }
              <Divider />
            </Box>
          ))}
          <Box py={3}>
            <Button
              variant="text"
              buttonType="common.primary"
              onClick={() =>
                setCustomChecklist([...customChecklist, { itemName: '', isChecked: false }])
              }
              disabled={editCheckbox !== null}
              style={{
                width: 'fit-content',
              }}
            >
              + Add another checklist item
            </Button>
          </Box>
          <Divider classes={{ root: classes.darkDivider }} />
        </Box>
      }
    </Box>
  );
};

export default CustomChecklist;
