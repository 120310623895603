import { createPromiseAction, createCRUDAction } from 'bentoo/promiseAction';
import { Action } from 'redux';
import { apiPromiseAction } from 'promise-action';
import { gql } from '@apollo/client';

import * as api from 'common/api/organizationApi';
import { memberApi } from 'common/api';
import { Organization, ValidateSeatType } from 'contractor/types';
import client from 'common/graphql/apolloClient';
import { FetchFullOrganizationQuery, FetchFullOrganizationQueryVariables } from 'types';

const FETCH_MINIMAL = 'Organization/FETCH_MINIMAL';
const UPDATE_ORGANIZATION = 'Organization/UPDATE';
const VALIDATE_SEAT = 'Organization/VALIDATE_SEAT';
const FETCH_BILLING = 'Organization/FETCH_BILLING';
const INVITING_MEMBERS = 'Organization/INVITE_MEMBERS';
const FETCH_SEATS = 'Organization/FETCH_SEATS';
const RESEND_INVITE = 'Organization/RESEND_INVITE';
const SUBMIT_PROJECT_LEADS_QUESTIONNAIRE = 'Organization/SUBMIT_PROJECT_LEADS_QUESTIONNAIRE';

export const FULL_ORGANIZATION_FRAGMENT = gql`
  fragment FullOrganization on Organization {
    activeContractorCount
    address
    answeredBusinessQuestions
    answeredProjectLeads
    backgroundPicture
    bannerInstalledAt
    businessEmail
    businessPhone
    descriptionOfOperations
    city
    companyName
    companyType
    createdAt
    featureFlags {
      id
    }
    planFeatureFlags {
      id
    }
    fullAddress
    hasFinancing
    id
    infoSetting
    latitude
    logoUrl
    longitude
    onboarded
    onboardedAt
    partnership {
      coupon
      label
      logoUrl
      pageVariant
      referralType
      urlPath
    }
    planName
    renewsAt
    requireContract
    sandboxOrg
    totalSeats
    usedSeats
    sentMemberInvites
    settings
    setupFlowCompleted
    setupFlowCompletedAt
    state
    statedNoTeam
    status
    subscriptionContract {
      status
      toBeSignedContract {
        html
        id
        text
      }
    }
    trainingCallLink
    urlPath
    verticalId
    website
    zipCode
  }
`;

const FETCH_FULL_ORGANIZATION = gql`
  query FetchFullOrganization {
    organization {
      id
      ...FullOrganization
    }
  }
  ${FULL_ORGANIZATION_FRAGMENT}
`;

type FetchPayload = NonNullable<FetchFullOrganizationQuery['organization']> & {
  id: number;
  seats: number;
  seatsLeft: number;
  featureFlags: string[];
}

export interface Fetch extends Action {
  type: 'Organization/FETCH_SUCCESS';
  payload: FetchPayload;
}

export const fetch = () =>
  client.query<FetchFullOrganizationQuery, FetchFullOrganizationQueryVariables>({
    query: FETCH_FULL_ORGANIZATION,
    fetchPolicy: 'network-only',
  });

// DEPRECATED, use fetchMinimalOrg
export const fetchMinimal = createPromiseAction(
  (id: number) => api.fetchMinimal({ id }),
  FETCH_MINIMAL, {
    normalizeData: ({ organization }) => organization,
  },
);

export const fetchMinimalOrg =
  apiPromiseAction.create(api.fetchMinimal)
    .normalizeResponse(({ data: { organization } }) => organization);

export const fetchSeats = createPromiseAction(api.fetchSeats, FETCH_SEATS);

export interface Update extends Action {
  type: 'Organization/UPDATE_SUCCESS';
  payload: Organization;
}
export const update = createCRUDAction(api.updateOrganization, UPDATE_ORGANIZATION);

export interface ValidateSeatEmail extends Action {
  type: 'Organization/VALIDATE_SEAT';
  payload: ValidateSeatType;
}

export const validateSeatEmail = createPromiseAction(api.validateSeatSignup, VALIDATE_SEAT);

export const fetchQuestionnaire =
  apiPromiseAction.create(api.fetchQuestionnaire)
    .normalizeResponse(({ data }) => data.businessQuestionnaireResponse);

export const submitQuestionnaire =
  apiPromiseAction.create(api.submitQuestionnaire)
    .normalizeResponse(({ data }) => data.businessQuestionnaireResponse);

export const fetchBilling = createPromiseAction(api.fetchBilling, FETCH_BILLING);

export type DispatchActions =
    | Fetch
    | Update;

export const inviteMembers = createPromiseAction(memberApi.inviteMembers, INVITING_MEMBERS, {
  normalizeData: data => data.map(member => member.email),
});

export const resendMemberInvite = createPromiseAction(memberApi.resendMemberInvite, RESEND_INVITE);

export const submitProjectLeadsQuestionnaire =
  createPromiseAction(api.submitProjectLeadsQuestionnaire, SUBMIT_PROJECT_LEADS_QUESTIONNAIRE);

export const generateIntuitOauthLink =
  apiPromiseAction
    .create(api.generateIntuitOauthLink)
    .normalizeResponse(({ data }) => data);

export const uploadLogo = apiPromiseAction
  .create(api.uploadLogo)
  .normalizeResponse(({ data }) => data);
