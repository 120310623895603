import { apiPromiseAction } from 'promise-action';

import { formErrorNormalizer } from 'common/api/utils';
import * as api from 'common/api/loanApplicationApi';

export const createApplication = apiPromiseAction
  .create(api.create)
  .normalizeResponse(({ data: { loanInquiry } }) => loanInquiry)
  .normalizeError(formErrorNormalizer);

export const updateApplication = apiPromiseAction
  .create(api.update)
  .normalizeResponse(({ data: { loanInquiry } }) => loanInquiry)
  .normalizeError(formErrorNormalizer);

export const updateCoapplicantApplication = apiPromiseAction
  .create(api.updateCoapplicant)
  .normalizeResponse(({ data: { loanInquiry } }) => loanInquiry)
  .normalizeError(formErrorNormalizer);

export const submitApplication = apiPromiseAction.create(api.submit);

export const reapply = apiPromiseAction
  .create(api.createFromExisting)
  .normalizeResponse(({ data: { loanInquiry } }) => loanInquiry);
