import { SvgIconProps } from '../Icon';

const ReceiptIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...otherProps}>
    <path
      d="M31.781 35.156h-27V2.531l4.5 2.813 4.5-2.813 4.5 2.813 4.5-2.813 4.5 2.813 4.5-2.813v32.625z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M14.906 23.344a2.785 2.785 0 002.813 2.812h1.125a2.785 2.785 0 002.812-2.812c0-3.938-6.75-1.688-6.75-5.625a2.785 2.785 0 012.813-2.813h1.125a2.785 2.785 0 012.812 2.813M18.281 26.156v2.25M18.281 12.656v2.25"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default ReceiptIcon;
