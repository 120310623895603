import { Box, BoxProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Popper, { Props as PopperProps } from 'contractor/components/widgets/Popper/Popper';
import { Actions, Content, Header } from 'contractor/components/widgets/Popper';

import {
  WalkthroughStep,
  WalkthroughAction,
  WalkthroughStepDetails,
  useOnboardingWalkthrough,
} from './useOnboardingWalkthrough';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.basic100,
    borderRadius: 16,
    paddingLeft: 40,
    paddingBottom: 32,
  },
}));

type Props = {
  children: React.ReactNode;
  step?: WalkthroughStep,
  anchorProps?: Partial<BoxProps>;
} & Omit<PopperProps, 'anchorEl' | 'open'>;

const WalkthroughStepWrapper = ({
  children,
  placement,
  step,
  anchorProps,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();
  const { data: { state, dispatch } } = useOnboardingWalkthrough();

  const open = step === state.step;

  if (!open || !state.step) {
    return <>{children}</>;
  }

  const { title, description } = WalkthroughStepDetails[state.step];
  const { next, previous } = state;

  return (
    <Popper
      open={open}
      anchorEl={children}
      placement={placement}
      containerProps={anchorProps}
      overlayProps={{
        width: 500,
        boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
        borderRadius: 16,
      }}
      {...otherProps}
    >
      <Box className={classes.container}>
        <Header
          title={title}
          onClose={() => dispatch({ type: WalkthroughAction.SET, step: undefined })}
        />
        <Content description={description} />
        <Actions
          next={next}
          previous={previous}
          onNext={next ?
            () => dispatch({ type: WalkthroughAction.NEXT }) :
            undefined
          }
          onPrevious={previous ?
            () => dispatch({ type: WalkthroughAction.PREVIOUS }) :
            undefined
          }
          walkthroughStepNumber={state.index + 1}
          walkthroughStepCount={
            // if we go directly to suggested actions (the last step)
            // set the step count accordingly
            (step === WalkthroughStep.SUGGESTED_ACTIONS && previous == null) ?
              undefined : 4
          }
        />
      </Box>
    </Popper>
  );
};

export default WalkthroughStepWrapper;
