import { Box, Typography } from '@mui/material';

import { CheckboxField } from 'common/components/formik';

import { useCustomizeInvoice } from '../useCustomizeInvoice';

const WebsiteModule = (): JSX.Element | null => {
  const { organization } = useCustomizeInvoice();
  const website = organization?.kycBusiness?.url || organization?.website;

  if (!website) {
    return null;
  }

  return (
    <Box>
      <Typography variant="subtitle1">
        <Box fontWeight="fontWeightBold">
          Website
        </Box>
      </Typography>
      <Box m={1.5} />
      <CheckboxField
        name="website"
        label={
          <Typography variant="body1" data-cy="website">
            {website}
          </Typography>
        }
      />
    </Box>
  );
};

export default WebsiteModule;
