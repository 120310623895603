import { useMemo } from 'react';
import { SvgIconProps } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {
  getPreventDefaultHandler,
  someNode,
  toggleNodeType,
} from '@udecode/plate-common';
import { usePlateEditorState } from '@udecode/plate-core';
import { ELEMENT_H1, ELEMENT_H2 } from '@udecode/plate-heading';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';

import BaseButton from './BaseButton';

type Props = {
  type: string,
  inactiveType?: string;
}

const ICON_MAPPING: Record<string, React.ComponentType<SvgIconProps>> = {
  [ELEMENT_H1]: LooksOneIcon,
  [ELEMENT_H2]: LooksTwoIcon,
  [ELEMENT_BLOCKQUOTE]: FormatQuoteIcon,
};

const BlockButton = ({ type, inactiveType }: Props): JSX.Element => {
  const editor = usePlateEditorState();
  const Icon = useMemo(() => ICON_MAPPING[type], [type]);

  return (
    <BaseButton
      Icon={Icon}
      active={!!editor?.selection && someNode(editor, { match: { type } })}
      onMouseDown={
        editor &&
        getPreventDefaultHandler(toggleNodeType, editor, {
          activeType: type,
          inactiveType,
        })
      }
    />
  );
};

export default BlockButton;
