import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { differenceInDays } from 'date-fns';

import { ConnectInvoiceStatus } from 'types';

type StyleProps = {
  bgcolor: Color;
  color: Color;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  pill: ({ bgcolor }) => ({
    paddingLeft: 8,
    paddingRight: 8,
    height: 30,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.common[bgcolor],
    maxWidth: 180,
  }),
  dot: ({ color }) => ({
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.common[color],
    marginRight: 8,
  }),
  text: ({ color }) => ({
    color: theme.palette.common[color],
  }),
}));

type Props = {
  status: ConnectInvoiceStatus;
  dueDate?: string | null;
  refundedAmount?: number | null;
  refundableAmount?: number | null;
}

const displayStatus = (
  status: ConnectInvoiceStatus,
  dueDate?: string | null,
  refundedAmount?: number | null,
  refundableAmount?: number | null,
): { backgroundColor: Color; color: Color; text: string } => {
  let backgroundColor: Color;
  let color: Color;
  let text: string;

  if (refundedAmount && refundedAmount > 0) {
    backgroundColor = 'success300';
    color = 'success700';
    text = 'Refunded';
    if (refundableAmount && refundableAmount > 0) {
      text = 'Partially Refunded';
    }

    return {
      backgroundColor,
      color,
      text,
    };
  }

  switch (status) {
  case ConnectInvoiceStatus.SCHEDULED_AFTER_FUND:
  case ConnectInvoiceStatus.SCHEDULED:
    backgroundColor = 'primary100';
    color = 'primary500';
    text = 'Scheduled';
    break;
  case ConnectInvoiceStatus.COLLECTED:
  case ConnectInvoiceStatus.MANUALLY_COLLECTED:
    backgroundColor = 'success100';
    color = 'success500';
    text = 'Paid';
    break;
  case ConnectInvoiceStatus.PROCESSING:
    backgroundColor = 'warning100';
    color = 'warning700';
    text = 'Processing';
    break;
  case ConnectInvoiceStatus.CANCELLED:
    backgroundColor = 'primary100';
    color = 'primary500';
    text = 'Canceled';
    break;
  default: {
    if (dueDate && differenceInDays(Date.parse(dueDate), Date.now()) < 0) {
      backgroundColor = 'danger100';
      color = 'danger500';
      text = 'Overdue';
    } else {
      backgroundColor = 'primary100';
      color = 'primary500';
      text = 'Unpaid';
    }
    break;
  }
  }

  return {
    backgroundColor,
    color,
    text,
  };
};

const PaymentStatusPill = ({
  status,
  dueDate,
  refundedAmount,
  refundableAmount,
}: Props): JSX.Element => {
  const {
    backgroundColor,
    color,
    text,
  } = displayStatus(status, dueDate, refundedAmount, refundableAmount);
  const classes = useStyles({ bgcolor: backgroundColor, color });

  return (
    <div className={classes.pill}>
      <div className={classes.dot} />
      <Typography variant="subtitle1" noWrap className={classes.text}>
        {text}
      </Typography>
    </div>
  );
};

export default PaymentStatusPill;
