import { useRef, useEffect, useState } from 'react';
import { useField } from 'formik';
import { camelize } from 'humps';

import InputField, { InputFieldProps } from './InputField';

type Props<
  Address extends string,
  City extends string,
  State extends string,
  ZipCode extends string
> = InputFieldProps & {
  name: Address;
  cityName: City;
  stateName: State;
  zipCodeName: ZipCode;
};

type AddressComponentMeta = {
  shortName: string;
  longName: string;
}

type AddressInfo = {
  streetNumber?: AddressComponentMeta;
  route?: AddressComponentMeta;
  locality?: AddressComponentMeta;
  administrativeAreaLevel1?: AddressComponentMeta;
  postalCode?: AddressComponentMeta;
  neighborhood?: AddressComponentMeta;
}

type LocalValues = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

function AddressField<
  Address extends string,
  City extends string,
  State extends string,
  ZipCode extends string
>({
  name,
  cityName,
  stateName,
  zipCodeName,
  ...otherProps
}: Props<Address, City, State, ZipCode>): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [, , nameHelpers] = useField<string>(name);
  const [, , cityHelpers] = useField<string>(cityName);
  const [, , stateHelpers] = useField<string>(stateName);
  const [, , zipCodeHelpers] = useField<string>(zipCodeName);
  const [localValues, setLocalValues] = useState<LocalValues | null>(null);

  useEffect(() => {
    if (!localValues) return;
    nameHelpers.setValue(localValues.address || '');
    cityHelpers.setValue(localValues.city || '');
    stateHelpers.setValue(localValues.state || '');
    zipCodeHelpers.setValue(localValues.zipCode || '');
  // Including all helpers.setValues causes an infinite loop,
  // therefore leaving it out of the dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValues]);

  useEffect(() => {
    if (inputRef.current && window.google) {
      const autocomplete =
        new window.google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });

      autocomplete.setComponentRestrictions({ country: 'us' });
      const listener = autocomplete.addListener('place_changed', () => {
        const { address_components: addressComponents } = autocomplete.getPlace();
        if (addressComponents) {
          const info = addressComponents.reduce<AddressInfo>(
            (acc, { long_name: longName, short_name: shortName, types }) => ({
              ...acc,
              ...types.reduce(
                (typeAcc, type) => ({
                  ...typeAcc,
                  [camelize(type)]: { longName, shortName },
                }),
                {},
              ),
            }),
            {},
          );

          const address = [
            info.streetNumber && info.streetNumber.shortName,
            info.route && info.route.longName,
          ].filter(Boolean).join(' ');
          const city = [
            info.locality && info.locality.longName,
            info.neighborhood && info.neighborhood.longName,
          ].find(Boolean);
          const state = info.administrativeAreaLevel1 && info.administrativeAreaLevel1.shortName;
          const zipCode = info.postalCode && info.postalCode.shortName;

          setLocalValues({
            address: address || '',
            city: city || '',
            state: state || '',
            zipCode: zipCode || '',
          });
        }
      });

      return () => listener.remove();
    }

    return () => {};
  }, [inputRef, window.google]);

  return (
    <InputField name={name} {...otherProps} inputRef={inputRef} />
  );
}

export default AddressField;
