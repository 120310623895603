import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';

import { useSnack } from 'common/utils/snackCart';
import Button, { ButtonProps } from 'common/components/material-ui/Button';

type Props = ButtonProps & {
  disableOnError?: boolean;
  disableOnInitialValues?: boolean;
};

const SubmitButton = ({
  onClick,
  loading,
  disabled,
  disableOnError = false,
  disableOnInitialValues = true,
  ...otherProps
}: Props): JSX.Element | null => {
  const {
    errors,
    initialValues,
    validateForm,
    values,
    isValidating,
    isSubmitting,
  } = useFormikContext();
  const snack = useSnack();

  const disable = useMemo(() => (
    disabled != null ?
      disabled :
      (disableOnInitialValues && isEqual(initialValues, values)) ||
      (disableOnError && (Object.keys(errors).length > 0))),
  [disabled, disableOnInitialValues, disableOnError, initialValues, values, errors]);

  return (
    <Button
      type="submit"
      onClick={async (e) => {
        const formErrors = await validateForm();
        if (Object.keys(formErrors).length > 0) {
          snack.errorSnack('There are incomplete fields in your submission');
          return;
        }

        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disable}
      loading={loading || isValidating || isSubmitting}
      {...otherProps}
    />
  );
};

export default SubmitButton;
