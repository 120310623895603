import { SvgIconProps } from '../Icon';

const PhoneSetupIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <g
      clipPath="url(#prefix__clip0)"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    >
      <path d="M4.063 7.188h26.25" />
      <path
        d="M4.063 32.188h14.375M20.938 39.063H7.188a3.125 3.125 0 01-3.125-3.126V4.688a3.125 3.125 0 013.125-3.125h20a3.125 3.125 0 013.125 3.125v14.375M30.313 32.813a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        strokeLinecap="square"
      />
      <path
        d="M35.937 30.938a5.634 5.634 0 00-.062-.809l2.101-2.172-1.25-2.165-2.929.734a5.61 5.61 0 00-1.403-.813l-.832-2.9h-2.5l-.828 2.901a5.61 5.61 0 00-1.404.813l-2.929-.733-1.25 2.165 2.099 2.17c-.04.268-.061.538-.063.808.002.271.023.541.063.809l-2.101 2.171 1.25 2.166 2.928-.734a5.61 5.61 0 001.404.813l.831 2.9h2.5l.83-2.901a5.61 5.61 0 001.403-.813l2.929.733 1.25-2.165-2.1-2.17a5.55 5.55 0 00.063-.808v0z"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneSetupIcon;
