import { memo } from 'react';
import { useField } from 'formik';
import { sentenceCase } from 'change-case';

import { Select } from 'common/components/material-ui';
import { Props as SelectProps } from 'common/components/material-ui/Select';

type Props = Omit<SelectProps, 'ref' | 'value' | 'onChange' | 'onBlur' | 'label'> & {
  name: string;
  label?: string;
  showLabel?: boolean;
  error?: boolean;
};

const SelectField = ({
  name,
  label,
  showLabel = true,
  error,
  helperText,
  ...otherProps
}: Props) => {
  const [field, meta] = useField(name);
  const formikError = meta.touched ? meta.error : undefined;
  const showError = Boolean(formikError || error);
  const fieldLabel = label != null ? label : sentenceCase(name);

  return (
    <Select
      {...field}
      label={showLabel ? fieldLabel : ''}
      error={showError ? (formikError ?? helperText) : undefined}
      helperText={showError ? undefined : helperText}
      {...otherProps}
    />
  );
};

export default memo(SelectField);
