import { NavigationMenu } from 'contractor/components/widgets';

import { ReportingRoute, useReportingActions } from './useReporting';

type Reporting = 'Hearth Pay Feedback' | 'Financing Feedback';

type ReportingMenuItem = {
  label: Reporting;
  value: ReportingRoute;
}

const reportingNavigation: ReportingMenuItem[] = [
  {
    label: 'Financing Feedback',
    value: ReportingRoute.Financing,
  },
  {
    label: 'Hearth Pay Feedback',
    value: ReportingRoute.HearthPay,
  },
];

const ReportingMenu = (): JSX.Element => {
  const { navigateToReporting } = useReportingActions();

  return (
    <NavigationMenu
      title="Reporting"
      navigationOptions={reportingNavigation}
      onClickNavigation={(route: ReportingRoute) => navigateToReporting(route)}
    />
  );
};

export default ReportingMenu;
