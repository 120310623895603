import { SvgIconProps } from '../Icon';

const MoneyBagIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 49" fill="none" {...otherProps}>
    <path
      d="M19.1694 33.4184C19.5392 35.1164 21.1706 36.3638 23.1817 36.3638H24.8181C27.109 36.3638 28.909 34.7438 28.909 32.682C28.909 27.5274 19.0908 30.4729 19.0908 25.3184C19.0908 23.2565 20.8908 21.6365 23.1817 21.6365H24.8181C26.8275 21.6365 28.459 22.8825 28.8305 24.5805"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 20V38"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 12L12 2H36L30 12"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 12H18C18 12 6 24.728 6 34C6 40.182 10.502 46 24 46C37.498 46 42 40.182 42 34C42 24.728 30 12 30 12Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MoneyBagIcon;
