import { SvgIconProps } from '../Icon';

const AccountManagerIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M19 9.22C19 3.73 14.74 0 10 0 5.31 0 1 3.65 1 9.28c-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1V8.9c0-3.87 3.13-7 7-7s7 3.13 7 7V16H9v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"
      fill={color}
    />
    <path
      d="M7 11a1 1 0 100-2 1 1 0 000 2zM13 11a1 1 0 100-2 1 1 0 000 2z"
      fill={color}
    />
    <path
      d="M16 8.03A6.04 6.04 0 0010.05 3C7.02 3 3.76 5.51 4.02 9.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"
      fill={color}
    />
  </svg>
);

export default AccountManagerIcon;
