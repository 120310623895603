import { useCallback } from 'react';

import {
  ValidationError,
  InternalError,
  UnexpectedError,
} from 'common/api/parseGql';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';
import { Severity } from '@sentry/react';

type Handlers = {
  onValidationError?: (formErrors: Record<string, unknown>) => void;
  onInternalError?: (err: InternalError) => void;
  onUnexpectedError?: (err: UnexpectedError) => void;
  onUnknownError?: (err: unknown) => void;
};

type UseGqlReturnType = {
  handleMutationError: (e: unknown, handlers?: Handlers) => void;
}

const useGql = (): UseGqlReturnType => {
  const { errorSnack } = useSnack();

  const handleMutationError = useCallback((e: unknown, handlers: Handlers = {}) => {
    const {
      onValidationError,
      onInternalError,
      onUnexpectedError,
      onUnknownError,
    } = handlers;

    if (e instanceof ValidationError) {
      e.userMessages.forEach(message => errorSnack(message));

      if (onValidationError) {
        onValidationError(e.formErrors);
      } else if (process.env.REACT_APP_ENV === 'development') {
        errorSnack(`[DEV] ValidationError: ${JSON.stringify(e.formErrors)}`);
      } else {
        Object.values(e.formErrors)
          .forEach(messageList => messageList.forEach(message => errorSnack(message)));
      }
      return;
    }

    if (e instanceof InternalError) {
      if (onInternalError) {
        onInternalError(e);
      } else if (process.env.REACT_APP_ENV === 'development') {
        errorSnack(`[DEV] InternalError: ${e.message}`);
      } else {
        analytics.trackException(e);
        errorSnack();
      }
      return;
    }

    if (e instanceof UnexpectedError) {
      if (onUnexpectedError) {
        onUnexpectedError(e);
      } else if (process.env.REACT_APP_ENV === 'development') {
        errorSnack(`[DEV] UnexpectedError: ${e.message}`);
      } else {
        analytics.trackException(e);
        errorSnack();
      }
      return;
    }

    if (e instanceof Error) {
      if (onUnknownError) {
        onUnknownError(e);
      } else if (process.env.REACT_APP_ENV === 'development') {
        errorSnack(`Uh oh, this is a dev BUG!! Exception: ${e.message}`);
      } else {
        analytics.trackException(e);
        errorSnack();
      }
    } else {
      analytics.trackMessage(
        'UseGql handled an error that is not an instance of Error type',
        Severity.Error,
        { error: e },
      );
    }
  }, [errorSnack]);

  return {
    handleMutationError,
  };
};

export default useGql;
