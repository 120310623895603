import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Snackbar,
  SnackbarContent,
  Box,
  Typography,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    backgroundColor: theme.customPalette.warning.color,
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  open: boolean;
};

const DemoInquirySnack = ({ open }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
    >
      <SnackbarContent
        className={classes.warning}
        message={
          <Box display="flex" alignItems="center">
            <WarningIcon fontSize="large" className={classes.icon} />
            <Typography variant="body2">
              This is a demo loan request. Offers are not valid.
            </Typography>
          </Box>
        }
      />
    </Snackbar>
  );
};

export default DemoInquirySnack;
