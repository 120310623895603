import {
  OrganizationTheme,
  OrganizationTemplateInfoFragment,
  CustomizedInvoiceTemplateAttributes,
  ConnectInvoice,
} from 'types';

export type ThemeColor =
  '#E14B41' |
  '#CE7308' |
  '#8A3E14' |
  '#67AC5B' |
  '#0154A4' |
  '#603FB0' |
  '#0D2135';

export const colors: Record<OrganizationTheme, ThemeColor> = {
  [OrganizationTheme.RED]: '#E14B41',
  [OrganizationTheme.ORANGE]: '#CE7308',
  [OrganizationTheme.BROWN]: '#8A3E14',
  [OrganizationTheme.GREEN]: '#67AC5B',
  [OrganizationTheme.BLUE]: '#0154A4',
  [OrganizationTheme.PURPLE]: '#603FB0',
  [OrganizationTheme.BLACK]: '#0D2135',
};

export type StyleProps = {
  themeColor: OrganizationTheme;
  fillBackground?: boolean;
}

export type TemplateProps = {
  settings: CustomizedInvoiceTemplateAttributes;
  organization: OrganizationTemplateInfoFragment;
  links: string[];
  invoice: Pick<
  ConnectInvoice,
  'invoiceNumber' |
  'dueDate' |
  'sentAt' |
  'fullName' |
  'phoneNumber' |
  'email' |
  'description' |
  'personalNote' |
  'amountRequested'
  >;
  attachments: {
    id: string;
    link: string;
    attachmentFileName: string;
  }[];
}
