import { SvgIconProps } from '../Icon';

const AddCircleIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...otherProps}>
    <circle cx={16} cy={16} r={16} fill={color} />
    <path
      d="M22.371 16.574h-5.05v5.175h-2.65v-5.175h-5.05v-2.4h5.05V8.999h2.65v5.175h5.05v2.4z"
      fill="#fff"
    />
  </svg>
);

export default AddCircleIcon;
