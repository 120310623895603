import {
  Box,
  Typography,
  Paper,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PaperProps } from '@mui/material/Paper';
import cx from 'classnames';

export type Props = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitle2?: string | null;
  children?: React.ReactNode;
  description?: string;
  locked?: boolean;
  lockedContent?: JSX.Element;
  expandingChevron?: React.ReactNode;
  className?: string;
  headerImage?: string;
  headerImageAlt?: string;
  footer?: React.ReactNode;
} & Omit<PaperProps, 'title'>;

const useStyles = makeStyles(({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255) 29.35%, rgba(255,255,255) 68.14%, rgba(255,255,255,0.6) 100%)',
    height: '100%',
    width: '100%',
    zIndex: 2,
  },
  overylayChild: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    padding: '20px 40px',
  },
  headerImg: {
    width: '100%',
  },
}));

const Note = ({
  title = null,
  subtitle = null,
  subtitle2 = null,
  description,
  children,
  locked = false,
  lockedContent,
  expandingChevron = null,
  className,
  footer = null,
  headerImage = undefined,
  headerImageAlt = undefined,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={cx(classes.root, className)} {...otherProps}>
      {headerImage &&
        <CardMedia
          component="img"
          image={headerImage}
          alt={headerImageAlt}
          className={classes.headerImg}
        />
      }
      <Box
        pt={2.5}
        px={{ xs: 3, sm: 5 }}
        pb={{ xs: 3, sm: 5 }}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        {locked &&
          <div className={classes.overlay}>
            <div className={classes.overylayChild}>
              {lockedContent}
            </div>
          </div>
        }
        <Box display="flex" justifyContent="space-between">
          <Box flex={1} display="flex" flexDirection="column" mb={1}>
            <Typography variant="subtitle2" gutterBottom color="primary">
              {title}
            </Typography>
            {(subtitle || subtitle2) &&
              <Box mb={2}>
                <Typography variant="h3">
                  {subtitle}
                </Typography>
                <Typography variant="body1">
                  {subtitle2}
                </Typography>
              </Box>
            }
            {description &&
              <Box mb={2}>
                <Typography variant="body2">
                  {description}
                </Typography>
              </Box>
            }
          </Box>
          {expandingChevron && expandingChevron}
        </Box>
        {children}
      </Box>
      {footer &&
        <Box bgcolor="grey.A100" px={{ xs: 2, sm: 3 }} py={2}>
          {footer}
        </Box>
      }
    </Paper>
  );
};

export default Note;
