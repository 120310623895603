import { HearthEventEventTypes } from 'types';

// If you are fetching hearth events by priority, make sure to use
// a SORTED LIST. The priority methods depend upon a sorted array

export const paymentsHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_ACTIVITY_CREATED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CANCELED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_CREATED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_MANUAL_REMINDER_SENT,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_OVERDUE_REMINDER_SENT,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PARTIAL_REFUND_COMPLETED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_COMPLETED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_FAILED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_PAYMENT_INITIATED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_REFUND_COMPLETED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SCHEDULED,
  HearthEventEventTypes.HOMEOWNER_CONNECT_INVOICE_SENT,
];

export const financingHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_APPLICATION_CLICKED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_CREATED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_FUNDED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_QUALIFIED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_REJECTED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_SENT,
];

export const creditCardHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_LEAD,
  HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_SOLD,
];

export const baseHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_UPDATED,
  HearthEventEventTypes.HOMEOWNER_CREATED,
];

export const sortedFinancingHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_APPLICATION_CLICKED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_SENT,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_CREATED,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_REJECTED,
  HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_LEAD,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_QUALIFIED,
  HearthEventEventTypes.HOMEOWNER_CREDIT_CARD_SOLD,
  HearthEventEventTypes.HOMEOWNER_APPLICATION_FUNDED,
];

export const sortedContractHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SENT,
  HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_VIEWED,
  HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_SIGNED,
  HearthEventEventTypes.HOMEOWNER_PROJECT_CONTRACT_CANCELED,
];

export const sortedQuotesHearthEvents: HearthEventEventTypes[] = [
  HearthEventEventTypes.HOMEOWNER_QUOTE_SENT,
  HearthEventEventTypes.HOMEOWNER_QUOTE_VIEWED,
  HearthEventEventTypes.HOMEOWNER_QUOTE_APPROVED,
  HearthEventEventTypes.HOMEOWNER_QUOTE_CHANGES_REQUESTED,
  HearthEventEventTypes.HOMEOWNER_QUOTE_REVISION_SENT,
  HearthEventEventTypes.HOMEOWNER_QUOTE_CANCELED,
  HearthEventEventTypes.HOMEOWNER_QUOTE_EXPIRED,
];

export const allHearthEvents: HearthEventEventTypes[] = [
  ...paymentsHearthEvents,
  ...creditCardHearthEvents,
  ...baseHearthEvents,
  ...sortedFinancingHearthEvents,
  ...sortedContractHearthEvents,
  ...sortedQuotesHearthEvents,
];
