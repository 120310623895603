import { CSSProperties } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  title: string;
  variant: Variant;
  fontStyleProps?: CSSProperties,
}

const useStyles = makeStyles((theme: Theme) => ({
  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.basic100,
    background: 'linear-gradient(270deg, #6506FF 0%, #027FF7 100%)',
    padding: '4px 12px',
    borderRadius: 100,
  },
}));

const FeatureStatusPill = ({ title, variant, fontStyleProps }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.pill}>
      <Typography variant={variant} style={{ ...fontStyleProps }}>
        <b>{title}</b>
      </Typography>
    </Box>
  );
};

export default FeatureStatusPill;
