import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Select } from 'common/components/material-ui';
import { FilterOptions, filterOptions, rateCapToFilterOption } from 'contractor/modules/Settings/FinancingSettings';

import { useSortingOffers } from '../useSortingOffers';

const useStyles = makeStyles(theme => ({
  sortSelect: {
    backgroundColor: theme.palette.common.basic100,
    paddingLeft: -8,
    [theme.breakpoints.up('sm')]: {
      height: 48,
    },
  },
  sortContainer: {
    width: 190,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 16,
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));

const OffersSort = (): JSX.Element => {
  const classes = useStyles();
  const {
    processState: {
      rateCap,
    },
    actions: {
      setRateCap,
      setGrowIn,
    },
  } = useSortingOffers();

  const selectedRateCap = rateCapToFilterOption(rateCap);
  return (
    <Box className={classes.sortContainer}>
      <Box flexGrow={1} mr={1}>
        <Typography variant="body2" color="textSecondary">
          Filter Max APR:
        </Typography>
      </Box>
      <Box maxWidth={130}>
        <Select<FilterOptions>
          label=""
          className={classes.sortSelect}
          name="aprRateCap"
          items={filterOptions}
          value={selectedRateCap}
          onChange={(e) => {
            setRateCap(parseFloat(e.target.value));
            setTimeout(() => {
              setGrowIn(true);
            }, 800);
          }}
          onClose={() => {
            setGrowIn(false);
            setTimeout(() => {
              setGrowIn(true);
            }, 800);
          }}
          onBlur={() => setGrowIn(true)}
        />
      </Box>
    </Box>
  );
};

export default OffersSort;
