import {
  Card,
  Typography,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Box,
} from '@mui/material';

import { Button } from 'common/components/material-ui';

type Props = {
  onClick: () => void;
};

const CoborrowerCard = ({ onClick }: Props): JSX.Element => (
  <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardActionArea>
      <CardMedia
        component="img"
        alt="discussion"
        image="https://hearth.imgix.net/nooffers/discussion.jpg"
        height="180"
        title="Discussion"
        style={{ objectPosition: '50% 20%' }}
      />
    </CardActionArea>
    <CardContent>
      <Box px={3} pt={1}>
        <Typography variant="h3" gutterBottom>
          Add a co-borrower
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          We noticed that you applied alone. If you submit your request with a co-borrower, who has
          additional income, lenders may be more likely to prequalify you for a personal loan.
        </Typography>
      </Box>
    </CardContent>
    <CardActions style={{ marginTop: 'auto' }}>
      <Box px={3} pb={2.5} width="100%" display="flex" justifyContent="flex-end">
        <Button
          buttonType="primary"
          onClick={onClick}
          variant="contained"
        >
          Add a co-borrower
        </Button>
      </Box>
    </CardActions>
  </Card>
);

export default CoborrowerCard;
