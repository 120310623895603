import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';
import { Icon } from 'common/components';

import { useClientProfile, useClientProfileActions } from '../hooks/useClientProfile';

const useStyles = makeStyles(theme => ({
  actionButton: {
    borderColor: theme.palette.common.primary500,
  },
  innerButton: {
    padding: '4px 28px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.common.primary500,
    alignItems: 'center',
  },
  root: {
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

const ClientActionButtons = (): JSX.Element => {
  const {
    inferredStates: {
      showPaymentsButton,
    },
  } = useClientProfile();
  const { openRequestPayment, openOfferFinancing } = useClientProfileActions();

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Button
        onClick={openOfferFinancing}
        variant="outlined"
        className={classes.actionButton}
      >
        <Box className={classes.innerButton}>
          <Icon
            name="transaction"
            color="primary500"
            size={20}
          />
          <Box mr={2} />
          <Typography variant="subtitle1" color="inherit">
            Offer Financing
          </Typography>
        </Box>
      </Button>
      {showPaymentsButton &&
        <>
          <Box ml={3} />
          <Button
            onClick={openRequestPayment}
            variant="outlined"
            className={classes.actionButton}
          >
            <Box className={classes.innerButton}>
              <Icon
                name="wallet"
                color="primary500"
                size={20}
              />
              <Box mr={2} />
              <Typography variant="subtitle1" color="inherit">
                Request Payment
              </Typography>
            </Box>
          </Button>
        </>
      }
    </Box>
  );
};

export default ClientActionButtons;
