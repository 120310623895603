const options = {
  states: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
  employmentStatus: [
    { value: 'employed', label: 'Employed' },
    { value: 'self-employed', label: 'Self Employed' },
    { value: 'retired', label: 'Retired' },
    { value: 'not-employed', label: 'Not Employed' },
  ],
  ficoRanges: [
    { value: '800', label: 'Excellent (741-850)' },
    { value: '740', label: 'Great (721-740)' },
    { value: '720', label: 'Very Good (701-720)' },
    { value: '700', label: 'Good (681-700)' },
    { value: '680', label: 'Average (661-680)' },
    { value: '660', label: 'Fair (641-660)' },
    { value: '640', label: 'Poor (621-640)' },
    { value: '620', label: 'Very Poor (501-620)' },
    { value: '500', label: 'Weak (350-500)' },
    { value: '725', label: "I don't know" },
  ],
  ficoRangesHomeownerFlow: [
    { value: '800', label: 'Excellent', sublabel: '741-850' },
    { value: '740', label: 'Great', sublabel: '721 - 740' },
    { value: '720', label: 'Very Good', sublabel: '701 - 720' },
    { value: '700', label: 'Good', sublabel: '681 - 700' },
    { value: '680', label: 'Average', sublabel: '661 - 680' },
    { value: '660', label: 'Fair', sublabel: '641 - 660' },
    { value: '640', label: 'Poor', sublabel: '621 - 640' },
    { value: '620', label: 'Very Poor', sublabel: '501 - 620' },
    { value: '500', label: 'Weak', sublabel: '350 - 500' },
    { value: '725', label: "I don't know" },
  ],
  statsFicoRanges: [
    { value: '800', label: 'Excellent (741-850)' },
    { value: '740', label: 'Great (721-740)' },
    { value: '720', label: 'Very Good (701-720)' },
    { value: '700', label: 'Good (681-700)' },
    { value: '680', label: 'Average (661-680)' },
    { value: '660', label: 'Fair (641-660)' },
    { value: '640', label: 'Poor (621-640)' },
    { value: '620', label: 'Very Poor (501-620)' },
    { value: '500', label: 'Weak (350-500)' },
  ],
  ficoRangesMin: [
    { value: '850+741', label: 'Excellent (741-850)' },
    { value: '741+681', label: 'Good (681-740)' },
    { value: '680+661', label: 'Average (661-680)' },
    { value: '660+500', label: 'Poor (500-660)' },
  ],
  marketingBannerSetting: [
    { value: 'organization', label: 'Organization' },
    { value: 'sales_rep', label: 'Sales Rep' },
  ],
};

export default options;
