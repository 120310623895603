import {
  Drawer,
  Box,
  useTheme,
  useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import NavigationDrawer from './NavigationDrawer';
import NavigationBar from './NavigationBar';
import DashboardModals from './DashboardModals';
import { DashboardNavProvider, useDashboardNavigation } from './useDashboardNavigation';
import { SendNewButtonHighlight, SendNewFloatingActionButton } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    zIndex: theme.drawerZIndex,
    width: theme.drawerWidth,
    overflow: 'visible',
  },
}));

export const DashboardNavigation = (): JSX.Element => {
  const classes = useStyles();
  const muiTheme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { uiStates: { mobileNavOpen, setMobileNavOpen } } = useDashboardNavigation();

  return (
    <div>
      <DashboardModals />
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            position: 'fixed',
            left: 0,
            height: '100%',
            bgcolor: 'common.basic100',
          }}
        >
          <NavigationDrawer />
        </Box>
        <NavigationBar />
      </Box>
      <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
        <SendNewButtonHighlight
          open={isMobile}
          popperStyles={{
            top: 'auto!important',
            left: 'auto!important',
            bottom: 100,
            right: 0,
          }}
        >
          <SendNewFloatingActionButton />
        </SendNewButtonHighlight>
      </Box>
      <nav className={classes.drawer}>
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <Drawer
            variant="temporary"
            anchor={muiTheme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileNavOpen}
            onClose={() => setMobileNavOpen(!mobileNavOpen)}
            classes={{ paper: classes.drawerPaper }}
            // better open performance on mobile
            ModalProps={{ keepMounted: true }}
          >
            {/* TODO(nav_phase_1): pass function to close drawer */}
            <NavigationDrawer />
          </Drawer>
        </Box>
      </nav>
    </div>
  );
};

const DashboardNavContainer = (): JSX.Element => (
  <DashboardNavProvider>
    <DashboardNavigation />
  </DashboardNavProvider>
);

export default DashboardNavContainer;
