import { Box } from '@mui/material';

import { SignatureMessage as SignatureMessageType } from 'types';

type Props = {
  signatureMessage?: SignatureMessageType | null;
}

const SignatureMessage = ({ signatureMessage }: Props): JSX.Element | null => {
  if (!signatureMessage) {
    return null;
  }

  return (
    <Box mt={3}>
      <img
        src={
          'https://hearth.imgix.net/contractor-v2/custom_invoice/' +
          `thank-you-${signatureMessage}.svg`
        }
        alt={`Thank you ${signatureMessage}`}
        height={signatureMessage === SignatureMessageType.SCRIPT ? 20 : 16}
      />
    </Box>
  );
};

export default SignatureMessage;
