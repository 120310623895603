import { SvgIconProps } from '../Icon';

const ContractIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M6 39V3h30v36H6z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 8L12 12.286V14h17v-1.714L20.5 8zM15 22v-4M20.803 22v-4M27 22v-4M12 22h17M12 27h17M12 32h8"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ContractIcon;
