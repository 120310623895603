import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { camelize } from 'humps';

import { useVerticalState } from 'common/hooks';
import { Link, RouterLink } from 'common/components/material-ui';
import { DashboardHome } from 'contractor/types/verticals';
import { organizationSelector } from 'contractor/selectors/organization';

const useStyles = makeStyles({
  lockedContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  newline: {
    whiteSpace: 'pre-line',
  },
});

const generateLockedCopy = (support: DashboardHome['support']) => ({
  default: {
    header: 'Complete your training call to unlock financing.',
    subheader: (
      <>
        Have a client that needs financing before your scheduled training?{'\n'}
        Call us now to unlock financing options{'\n'}
        <Link
          color="primary"
          href={`tel:${support.phone}`}
        >
          {support.displayPhone}
        </Link>
      </>
    ),
  },
  pastDue: {
    header: 'We were unable to to process your credit card.',
    subheader: (
      <>
        Your subscription is locked until we are able to collect your subscription
        payment.{'\n'}
        Please enter new payment information on the{' '}
        <RouterLink
          to="/dashboard/settings"
        >
          settings page
        </RouterLink>{' '}
        or contact us as{' '}
        <Link
          color="primary"
          href={`tel:${support.phone}`}
        >
          {support.displayPhone}
        </Link>
        or{' '}
        <Link
          color="primary"
          href={`mailto:${support.email}`}
        >
          {support.email}
        </Link>
      </>
    ),
  },
  canceled: {
    header: 'Your subscription has expired.',
    subheader: (
      <>
        Your subscription has ended either from cancellation or failure to
        collect payment.{'\n'}
        If you would like to reactivate your account, please contact us at{' '}
        <Link
          color="primary"
          href={`mailto:${support.phone}`}
        >
          {support.displayPhone}
        </Link>{' '}
        or{' '}
        <Link
          color="primary"
          href={`mailto:${support.email}`}
        >
          {support.email}
        </Link>
      </>
    ),
  },
});

const LockedContent = (): JSX.Element => {
  const classes = useStyles();

  const [{ verticalMetadata }] = useVerticalState();
  const { dashboardHome: { support } } = verticalMetadata;
  const lockedCopy = generateLockedCopy(support);
  const organization = useSelector(organizationSelector);
  const status = organization.status;

  let copy = lockedCopy.default;
  if (['past_due', 'canceled'].includes(status)) {
    copy = lockedCopy[camelize(status)];
  }

  return (
    <div className={classes.lockedContent}>
      <img src="https://hearth.imgix.net/icons/lock_dashboard.svg?auto=compress" alt="lock" />
      <Box m={2} />
      <Typography variant="h3" align="center">
        {copy.header}
      </Typography>
      <Box m={2} />
      <Typography align="center" className={classes.newline}>
        {copy.subheader}
      </Typography>
    </div>
  );
};

export default LockedContent;
