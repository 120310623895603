import { DeepReadonly } from 'utility-types';

import { Actions } from 'common/actions';
import { LearnCenterLifecycle } from 'types';

export type ArticleState = DeepReadonly<{
  articles: Record<string, Article>;
  sections: Record<string, Section>;
  sectionOrder: string[];
  contractorData: Record<string, ArticleContractorMetadata>;
  lifecycles?: Record<LearnCenterLifecycle, Section>;
}>

const initialState: ArticleState = {
  articles: {},
  sections: {},
  sectionOrder: [],
  contractorData: {},
  lifecycles: undefined,
};

export default (state = initialState, action: Actions): ArticleState => {
  switch (action.type) {
  case 'Article/FETCH_CONTRACTOR_DATA_SUCCESS':
    return {
      ...state,
      contractorData: action.payload,
    };

  case 'Article/FETCH_RESOURCE_CENTER_SUCCESS':
    return {
      ...state,
      ...action.payload,
    };

  case 'Article/MARK_HELPFUL_SUCCESS':
  case 'Article/MARK_READ_SUCCESS': {
    const { articleId, ...attrs } = action.payload;
    return {
      ...state,
      contractorData: {
        ...state.contractorData,
        [articleId]: {
          ...state.contractorData[articleId],
          ...attrs,
        },
      },
    };
  }

  default:
    return state;
  }
};
