import { Typography, Card as MuiCard, CardProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader';
import CardContent, { CardContentProps } from '@mui/material/CardContent';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
  },
  header: {
    borderBottom: `solid 1px ${theme.palette.common.basic700}`,
  },
  headerAction: {
    alignSelf: 'center',
    marginRight: 8,
    marginTop: 0,
  },
  cardContent: {
    padding: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
  },
}));

type Props = {
  title: React.ReactNode;
  children?: React.ReactNode;
  headerProps?: Partial<CardHeaderProps>;
  contentProps?: Partial<CardContentProps>;
} & Omit<CardProps, 'title'>;

const Card = ({
  title,
  children,
  headerProps,
  contentProps,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiCard variant="outlined" className={classes.root} {...otherProps}>
      <CardHeader
        title={
          typeof title === 'string' ?
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography> :
            title
        }
        classes={{ action: classes.headerAction }}
        className={classes.header}
        {...headerProps}
      />
      <CardContent
        classes={{ root: classes.cardContent }}
        {...contentProps}
      >
        {children}
      </CardContent>
    </MuiCard>
  );
};

export default Card;
