import { SvgIconProps } from '../Icon';

const UnsignedContractIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...otherProps}>
    <path d="M4.5 3.5H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 6.5H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 9.5H7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 12.5H7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 15.5H1.5V0.5H14.5V7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10.5L12.5 9.5L9.5 11.5V15.5H13H15.5V11.5L14 10.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 13.5V15.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default UnsignedContractIcon;
