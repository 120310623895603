import {
  Box,
  MenuItem as MuiMenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Typography } from 'common/components/material-ui';
import Icon, { IconName } from 'common/components/Icon';
import FeatureStatusPill from 'contractor/components/widgets/FeatureStatusPill';

export interface QuickActionItem {
  title: string;
  icon: IconName;
  action: () => void;
  locked: boolean;
  actionable?: boolean;
  statusIndicatorCopy?: string;
}

type StatusPillProps = {
  title?: string;
}

const LockedStatusPill = ({ title }: StatusPillProps): JSX.Element | null => {
  if (!title) return null;

  return (
    <Box
      sx={{
        py: 0.5,
        px: 0.75,
        bgcolor: 'common.primary100',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 8,
        ml: 2,
      }}
    >
      <LockOutlinedIcon fontSize="small" />
      <Typography variant="p3" weight="semibold">{title}</Typography>
    </Box>
  );
};

type Props = {
  item: QuickActionItem;
  featureStatus?: string;
}

const MenuItem = ({ item, featureStatus }: Props): JSX.Element => (
  <MuiMenuItem
    sx={{
      border: 1,
      borderColor: 'common.primary100',
      borderRadius: 8,
      py: 1,
      pl: 2,
    }}
    onClick={item.action}
    disabled={!item.actionable}
  >
    <ListItemIcon sx={{ mt: -0.25 }}>
      <Icon
        color="basic1100"
        name={item.icon}
        size={24}
      />
    </ListItemIcon>
    <ListItemText>
      <Typography variant="p1" weight="semibold">
        {item.title}
      </Typography>
    </ListItemText>
    {featureStatus &&
      <Box sx={{ ml: 1, mr: 8 }}>
        <FeatureStatusPill
          variant="caption"
          title={featureStatus}
          fontStyleProps={{ fontSize: 10 }}
        />
      </Box>
    }
    {item.locked && <LockedStatusPill title={item.statusIndicatorCopy} />}
    <IconButton
      onClick={item.action}
      sx={{ color: 'common.basic1100', pr: 1, mr: -1 }}
    >
      <ArrowForwardIcon fontSize="large" />
    </IconButton>
  </MuiMenuItem>
);

export default MenuItem;
