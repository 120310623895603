import { TRenderLeafProps } from '@udecode/plate-core';

const Leaf = ({ attributes, children, leaf }: TRenderLeafProps): JSX.Element => {
  let wrappedChildren = children;
  if (leaf.bold) {
    wrappedChildren = <strong>{wrappedChildren}</strong>;
  }

  if (leaf.code) {
    wrappedChildren = <code>{wrappedChildren}</code>;
  }

  if (leaf.italic) {
    wrappedChildren = <em>{wrappedChildren}</em>;
  }

  if (leaf.underline) {
    wrappedChildren = <u>{wrappedChildren}</u>;
  }

  return <span {...attributes}>{wrappedChildren}</span>;
};

export default Leaf;
