import { ApolloQueryResult } from '@apollo/client';

import { Exact, GetStartedOrganizationTemplateInfoFragment } from 'types';
import { LogoUploadField } from 'common/components/formik';

type Props = {
  organization: GetStartedOrganizationTemplateInfoFragment; // todo change
  refetch: (variables?: Partial<Exact<{ [key: string]: never; }>> | undefined) => Promise<ApolloQueryResult<any>>;
}

const InvoiceLogoUpload = ({ organization, refetch }: Props): JSX.Element | null => {
  if (!organization) return null;
  return (
    <LogoUploadField
      name="logo"
      logoUrl={organization.logoUrl}
      refetch={refetch}
    />
  );
};

export default InvoiceLogoUpload;
