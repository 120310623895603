import { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button } from 'common/components/material-ui';

const CopyLink = ({ text, onClick }) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        onClick();
        setCopied(true);
      }}
    >
      <Button
        variant="contained"
        buttonType="primary"
        btnColor="primary"
      >
        {copied ? 'Copied!' : 'Copy link'}
      </Button>
    </CopyToClipboard>
  );
};

CopyLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CopyLink;
