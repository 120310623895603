import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import logger, { LogGroup } from 'common/services/logger';
import { componentName, parentComponentName } from 'common/utils/react';

const Loader = () => (
  <svg className="loader" viewBox="0 0 100 100">
    <circle cx={-20} cy={50} r={20}>
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin={0.1}
      />
    </circle>
    <circle cx={60} cy={50} r={20}>
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin={0.2}
      />
    </circle>
    <circle cx={140} cy={50} r={20}>
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin={0.3}
      />
    </circle>
  </svg>
);

export default class Button extends Component {
  static propTypes = {
    btnColor: PropTypes.oneOf([
      'default',
      'primary',
      'secondary',
      'transparent-background',
      'transparent',
      'link',
      'space',
      'blue',
      'blue-empty',
      'navy-empty',
      'denim',
      'denim-filled',
      'green',
      'yellow',
      'white-outlined',
    ]),
    type: PropTypes.string,
    large: PropTypes.bool,
    link: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    navbar: PropTypes.bool,
    lock: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    block: PropTypes.bool,
    isLoading: PropTypes.bool,
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
  };

  static defaultProps = {
    btnColor: '',
    type: 'button',
    large: false,
    link: false,
    onClick: () => {},
    className: '',
    navbar: false,
    lock: false,
    disabled: false,
    block: false,
    isLoading: false,
    component: 'button',
  };

  handleClick = (e) => {
    const {
      onClick,
      disabled,
    } = this.props;
    if (disabled) {
      return;
    }

    onClick(e);
  };

  render() {
    const {
      btnColor,
      children,
      large,
      disabled,
      block,
      link,
      onClick,
      className,
      navbar,
      lock,
      type,
      isLoading,
      component: ButtonComponent,
      ...otherProps
    } = this.props;

    let extraProps = otherProps;

    // This is because button without button type is default to 'submit' and we have been defaulting
    // it to be 'button' in this component.
    if (ButtonComponent === 'button') {
      extraProps = { ...extraProps, type };
    }

    const btnClassName = cx(className, 'btn', {
      'btn-primary': btnColor === 'primary',
      'btn-secondary': btnColor === 'secondary',
      'btn-blue': btnColor === 'blue',
      'btn-default': btnColor === 'default',
      'btn-transparent': btnColor === 'transparent-background',
      'btn-link': btnColor === 'link',
      'btn-space': btnColor === 'space',
      'btn-blue-empty': btnColor === 'blue-empty',
      'btn-navy-empty': btnColor === 'navy-empty',
      'btn-denim-filled': btnColor === 'denim-filled',
      'btn-denim': btnColor === 'denim',
      'btn-yellow': btnColor === 'yellow',
      'btn-green': btnColor === 'green',
      'btn-white-outlined': btnColor === 'white-outlined',
      'btn-lg': large,
      'btn-block': block,
      'navbar-btn': navbar,
      disabled,
    });
    return (
      <ButtonComponent
        className={btnClassName}
        onClick={this.handleClick}
        disabled={disabled || isLoading}
        {...extraProps}
      >
        {isLoading ?
          <Loader /> :
          children
        }
        {!isLoading && lock &&
          <img
            src="https://hearth.imgix.net/icons/security_lock_with_padding.svg"
            alt="security-lock"
            className="btn-lock"
          />
        }
      </ButtonComponent>
    );
  }
}
