import { useMemo, useCallback, useState } from 'react';
import constate from 'constate';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { differenceInDays } from 'date-fns';

import { useGetReportingDataQuery } from 'types';

export const GET_REPORTING_DATA = gql`
  query GetReportingData {
    contractor {
      id
      fullName
      email
      fullUrl
      homeownerFinancingFeedbackMetrics {
        avgContractorSatisfaction
        avgContractorSatisfactionDifference
        avgHearthSatisfaction
        avgHearthSatisfactionDifference
      }
      homeownerPaymentsFeedbackMetrics {
        avgContractorSatisfaction
        avgContractorSatisfactionDifference
        avgHearthSatisfaction
        avgHearthSatisfactionDifference
      }
      homeownerFinancingFeedback {
        nodes {
          id
          homeownerId
          fullName
          submittedAt
          contractorExperience
          hearthRating
          additionalFinancingFeedback
        }
      }
      homeownerPaymentsFeedback {
        nodes {
          id
          homeownerId
          fullName
          submittedAt
          digitalPayments
          workQuality
          customerService
          additionalPaymentsFeedback
        }
      }
    }
  }
`;

export enum ReportingRoute {
  Financing = 'financing-feedback',
  HearthPay = 'hearth-pay-feedback',
}

export enum SortMethod {
  MOST_RECENT = 'most_recent',
  ALPHABETICAL = 'alphabetical',
}

export function sortFeedback<Type extends {
  submittedAt: string,
  fullName: string
}>(arr: Type[], sortMethod: SortMethod): Type[] {
  const arrCopy = [...arr];
  if (sortMethod === SortMethod.MOST_RECENT) {
    arrCopy.sort((a, b) => differenceInDays(Date.parse(b.submittedAt), Date.parse(a.submittedAt)));
  } else {
    arrCopy.sort((a, b) => a
      .fullName
      .toLowerCase()
      .localeCompare(b.fullName.toLowerCase()));
  }
  return arrCopy;
}

/*
 * Base hook [DO NOT EXPORT]
 */

const useBaseContext = () => {
  const query = useGetReportingDataQuery();
  const [sortMethod, setSortMethod] = useState<SortMethod>(SortMethod.MOST_RECENT);

  const contractor = useMemo(() => query.data?.contractor, [query.data]);
  const homeownerPaymentsFeedback = useMemo(() => {
    const array = (contractor?.homeownerPaymentsFeedback?.nodes || []);

    return array.map((feedback) => {
      const digitalPayments = feedback?.digitalPayments || 1;
      const workQuality = feedback?.workQuality || 1;
      const customerService = feedback?.customerService || 1;
      return {
        fullName: feedback?.fullName || '',
        submittedAt: feedback?.submittedAt || '',
        homeownerId: feedback?.homeownerId || '',
        digitalPayments,
        workQuality,
        customerService,
        additionalPaymentsFeedback: feedback?.additionalPaymentsFeedback,
        averageScore: Math.round(((digitalPayments + workQuality + customerService) / 3) * 10) / 10,
      };
    });
  }, [contractor]);

  const homeownerFinancingFeedback = useMemo(() => {
    const array = (contractor?.homeownerFinancingFeedback?.nodes || []);

    return array.map((feedback) => {
      const contractorExperience = feedback?.contractorExperience || 1;
      const hearthRating = feedback?.hearthRating || 1;
      return {
        fullName: feedback?.fullName || '',
        submittedAt: feedback?.submittedAt || '',
        homeownerId: feedback?.homeownerId || '',
        contractorExperience,
        hearthRating,
        additionalFinancingFeedback: feedback?.additionalFinancingFeedback,
        averageScore: Math.round(((contractorExperience + hearthRating) / 2) * 10) / 10,
      };
    });
  }, [contractor]);

  const financingFeedbackUrl = '/dashboard/reviews/financing-feedback';
  const hearthPayFeedbackUrl = '/dashboard/reviews/hearth-pay-feedback';

  return {
    data: {
      contractor,
      homeownerPaymentsFeedback,
      homeownerFinancingFeedback,
      homeownerFinancingFeedbackMetrics: contractor?.homeownerFinancingFeedbackMetrics,
      homeownerPaymentsFeedbackMetrics: contractor?.homeownerPaymentsFeedbackMetrics,
      financingFeedbackUrl,
      hearthPayFeedbackUrl,
      query,
    },
    uiStates: {
      sortMethod,
      setSortMethod,
    },
  };
};

const [ContainerProvider, useContext, useContextUIStates] = constate(
  useBaseContext,
  o => o.data,
  o => o.uiStates,
);

type ReportingActions = {
  navigateToReporting: (route: ReportingRoute) => void;
}

const useActions = (): ReportingActions => {
  const history = useHistory();

  const navigateToReporting = useCallback((route: ReportingRoute): void => (
    history.push(`/dashboard/reviews/${route}`)
  ), [history]);

  return {
    navigateToReporting,
  };
};

/*
 * Exports
 */

export default useContext;
export const ReportingProvider = ContainerProvider;
export const useReportingActions = useActions;
export const useReportingUIStates = useContextUIStates;
