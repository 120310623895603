import {
  Radio as MaterialRadio,
  RadioProps,
  Theme,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    padding: 5,
  },
});

const Radio = (props: RadioProps): JSX.Element => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  return (
    <MaterialRadio
      {...props}
      color="primary"
      icon={<RadioButtonUnchecked htmlColor={theme.palette.common.primary500} />}
      checkedIcon={<RadioButtonChecked htmlColor={theme.palette.common.primary500} />}
      classes={{ root: classes.root }}
    />
  );
};

export default Radio;
