import { useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';
import mean from 'lodash/fp/mean';

export type VideoPlayerProps = {
  url: string;
  onVideoProgress?: (percentWatched: number) => void;
  progressInterval?: number;
} & ReactPlayerProps;

const VideoPlayer = ({
  url,
  onVideoProgress,
  onEnded,
  progressInterval = 1000,
  ...otherProps
}: VideoPlayerProps): JSX.Element => {
  const videoRef = useRef<ReactPlayer>(null);
  const [bins, setBins] = useState<number[]>();

  const calcPercentWatched = (): number => {
    if (!bins) {
      return 0;
    }
    return mean(bins);
  };

  const onDuration = (): void => {
    const player = videoRef.current;
    if (player) {
      const binArray = Array.from(
        Array(Math.ceil((player.getDuration() * 1000) / progressInterval)),
        () => 0,
      );
      setBins(binArray);
    }
  };

  const onProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }): void => {
    if (state.playedSeconds && state.playedSeconds !== 0) {
      const key = Math.floor((state.playedSeconds * 1000) / progressInterval) - 1;
      if (bins) {
        bins[key] = 1;
      }
    }

    if (onVideoProgress) {
      onVideoProgress(calcPercentWatched());
    }
  };

  const finishVideo = (): void => {
    if (bins) {
      bins[bins.length - 1] = 1;
      if (onVideoProgress) {
        onVideoProgress(calcPercentWatched());
      }
    }
    if (onEnded) {
      onEnded();
    }
  };

  return (
    <ReactPlayer
      url={url}
      progressInterval={progressInterval}
      ref={videoRef}
      onDuration={onDuration}
      onProgress={onProgress}
      onEnded={finishVideo}
      {...otherProps}
    />
  );
};

export default VideoPlayer;
