import { Box, Typography } from '@mui/material';

const HelpSection = (): JSX.Element => (
  <Box
    pl={5}
    mt={3}
    display="flex"
    flexDirection="column"
    fontSize="10px"
  >
    <Typography variant="inherit" color="textPrimary">
      Need help?
    </Typography>
    <Box mb={3} mt={1} color="common.basic900">
      <Typography variant="inherit" color="inherit">
        (512) 686-4141
      </Typography>
      <Box mb={0.5} />
      <Typography variant="inherit" color="inherit">
        support@gethearth.com
      </Typography>
    </Box>
  </Box>
);

export default HelpSection;
