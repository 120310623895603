import { useState, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery, Theme } from '@mui/material';

import userFeatureFlags from 'common/graphql/featureFlags';
import { SendNewButton as BaseSendNewButton } from 'contractor/components/platform';

import { useDashboardNavigation } from '../useDashboardNavigation';
import QuickActionsMenu from './QuickActionsMenu';

type Props = {
  homeownerId?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

const SendNewButton = ({ homeownerId, buttonRef }: Props): JSX.Element => {
  const { skittles } = useReactiveVar(userFeatureFlags);
  const {
    uiStates: {
      skittlesWelcomeTourSeen,
      showSkittlesTour,
    },
  } = useDashboardNavigation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (skittles && showSkittlesTour && !skittlesWelcomeTourSeen && !isMobile && buttonRef) {
      setAnchorEl(buttonRef.current);
    } else if (isMobile) {
      setAnchorEl(null); // setting anchorEl will automatically open quick actions menu
    }
  }, [buttonRef, skittles, skittlesWelcomeTourSeen, isMobile, showSkittlesTour]);

  return (
    <>
      <BaseSendNewButton
        ref={buttonRef}
        onClick={event => setAnchorEl(event.currentTarget)}
      />
      <QuickActionsMenu
        anchorEl={anchorEl}
        onClose={onClose}
        isMobile={isMobile}
        homeownerId={homeownerId}
        sx={{ mt: 1 }}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default SendNewButton;
