import { createPromiseAction } from 'bentoo/promiseAction';
import sortBy from 'lodash/fp/sortBy';

import api from 'common/api/questionnaireApi';

const FETCH_QUESTIONS = 'Questionnaire/FETCH_QUESTIONS';
const FETCH_ANSWER_OPTIONS = 'Questionnaire/FETCH_ANSWER_OPTIONS';

export const fetchQuestions = createPromiseAction(api.fetchQuestions, FETCH_QUESTIONS);

export const fetchAnswerOptions =
  createPromiseAction(api.fetchAnswerOptions, FETCH_ANSWER_OPTIONS, {
    normalizeData: question => ({
      id: question.id,
      label: question.label,
      choices: sortBy(['position'])(question.answerOptions.map(option => ({
        label: option.optionText,
        value: option.answerId,
        correct: question.metadata.correctAnswerId === option.answerId,
      }))),
    }),
  });
