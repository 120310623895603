import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import {
  boolean,
  object,
  number,
  string,
} from 'yup';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { useLazyAction } from 'promise-action';

import { useCreateAndSubmitLoanInquiryFromExistingMutation } from 'types';
import { fetchMinimalOrg } from 'contractor/actions/organization';
import { useTerms } from 'common/hooks';
import { Dialog } from 'common/components/material-ui';
import {
  Form,
  CheckboxField,
  SubmitButton as FormikSubmitButton,
  InputField,
} from 'common/components/formik';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';
import { currencyFormat } from 'common/utils/stringFormatters';

type Props = {
  loanInquiryUuid: string;
  organizationId?: string;
  open: boolean;
  onClose: () => void;
  loanPrincipal: number;
  withLoanPrincipalInput: boolean;
}

type Values = {
  loanPrincipal: string;
  terms: boolean;
  termsString: string;
  loanInquiryUuid: string;
}

const useStyles = makeStyles({
  root: {
    height: 'unset',
    paddingBottom: 40,
  },
  underline: {
    textDecoration: 'underline',
    fontWeight: 600,
  },
});

const SubmitButton = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <FormikSubmitButton
      variant="contained"
      loading={isSubmitting}
      disabled={false}
      style={{ width: 250 }}
    >
      Show me additional options
    </FormikSubmitButton>
  );
};

const EasyReapplyModal = ({
  loanInquiryUuid,
  organizationId,
  open,
  onClose,
  loanPrincipal,
  withLoanPrincipalInput,
}: Props): JSX.Element => {
  const fetchOrganization = useLazyAction(fetchMinimalOrg);
  const classes = useStyles();
  const { errorSnack } = useSnack();
  const history = useHistory();
  const [createLoanInquiryFromExisting] = useCreateAndSubmitLoanInquiryFromExistingMutation();

  useEffect(() => {
    if (organizationId && !fetchOrganization.loading) {
      fetchOrganization.run({ id: organizationId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const { getTerms } = useTerms();
  const termsHtml = fetchOrganization.data ?
    getTerms(fetchOrganization.data, 'personal-loans') :
    '';

  return (
    <Dialog
      dialogTitle={
        withLoanPrincipalInput ?
          <>
            <Typography variant="h2">
              Request new options
            </Typography>
            <Box m={1} />
            <Typography variant="body1">
              Your are likely to get more options if you
              request {currencyFormat(loanPrincipal)} or less.
            </Typography>
          </> :
          <>
            <Typography variant="h2">
              Confirm resubmission
            </Typography>
            <Typography variant="body1">
              This is a financial legal requirement.
            </Typography>
            <Box mt={3} />
            <Typography variant="body1">
              It still <span className={classes.underline}>does not</span> affect your credit score.
            </Typography>
          </>
      }
      PaperProps={{
        classes: {
          root: classes.root,
        },
      }}
      bgColor="basic100"
      open={open}
      onClose={onClose}
    >
      <Form<Values>
        validateOnMount={false}
        initialValues={{
          loanPrincipal: loanPrincipal.toString(),
          loanInquiryUuid,
          termsString: termsHtml,
          terms: false,
        }}
        validationSchema={object().shape({
          loanPrincipal: number().required(),
          loanInquiryUuid: string().required(),
          termsString: string().required(),
          terms: boolean().label('Terms')
            .oneOf([true], 'Terms must be accepted')
            .required(),
        })}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur
        onSubmit={async ({ loanPrincipal: stringLoanPrincipal, ...values }) => {
          try {
            const result = await createLoanInquiryFromExisting({
              variables: {
                loanPrincipal: parseInt(stringLoanPrincipal, 10),
                ...values,
              },
            });

            const data = result?.data?.createLoanInquiryFromExisting;
            switch (data?.__typename) {
            case 'CreateLoanInquiryFromExistingFailure': {
              const errors = data?.errors;
              if (errors) {
                errorSnack(errors[0].message);
                onClose();
              }
              break;
            }
            case 'CreateLoanInquiryFromExistingSuccess': {
              const newLoanInquiryUuid = data?.loanInquiry?.id;
              if (newLoanInquiryUuid) {
                history.push(`/loans/${newLoanInquiryUuid}/loading`);
              }
              break;
            }
            default:
              break;
            }
          } catch (error) {
            analytics.trackException(error);
            errorSnack('An error occurred. Please try again.');
            onClose();
          }
        }}
      >
        {withLoanPrincipalInput &&
          <Box maxWidth={180} ml={2} mb={5}>
            <Typography variant="subtitle2">
              New loan amount:
            </Typography>
            <Box m={1} />
            <InputField
              mask="money"
              name="loanPrincipal"
              label=""
            />
          </Box>
        }
        <Box
          my={2}
          mx={-3}
          px={4}
          py={2}
          bgcolor="common.basic300"
        >
          <Typography variant="caption">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: termsHtml }} />
          </Typography>
        </Box>
        <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end">
          <CheckboxField name="terms" label="I agree to the terms above." />
          <SubmitButton />
        </Box>
        {withLoanPrincipalInput &&
          <Box
            mr={6}
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <img
              src="https://hearth.imgix.net/creditscore-flipped.png?auto=compress"
              alt="Does not affect credit score"
              style={{
                maxWidth: 265,
                width: '100%',
                marginTop: 16,
              }}
            />
          </Box>
        }
      </Form>
    </Dialog>
  );
};

EasyReapplyModal.CREATE_LOAN_INQUIRY_FROM_EXISTING = gql`
  mutation CreateAndSubmitLoanInquiryFromExisting(
    $loanInquiryUuid: String!
    $terms: Boolean!
    $termsString: String!
    $loanPrincipal: Int!
  ) {
    createLoanInquiryFromExisting(
      loanInquiryUuid: $loanInquiryUuid
      terms: $terms
      termsString: $termsString
      loanPrincipal: $loanPrincipal
      submit: true
    ) {
      ... on CreateLoanInquiryFromExistingSuccess {
        loanInquiry {
          id
        }
      }
      ... on CreateLoanInquiryFromExistingFailure {
        errors {
          code
          message
        }
      }
    }
  }
`;

export default EasyReapplyModal;
