import { SvgIconProps } from '../Icon';

const SendContractIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path
      d="M8.20861 0.763715C8.20861 0.343672 7.86078 0 7.43566 0H4.5603C4.13518 0 3.78735 0.343672 3.78735 0.763715V2.38279H8.20861V0.763715Z"
      fill={color}
    />
    <path
      d="M7.86865 12.4984V12.5901C7.86865 13.1743 8.33242 13.6326 8.92372 13.6326C9.21744 13.6326 9.49184 13.5218 9.68894 13.3156C9.88218 13.117 9.98653 12.8536 9.9788 12.5748C9.9788 12.571 9.9788 12.5672 9.9788 12.5634V12.4946C9.9788 11.9104 9.51503 11.4521 8.92372 11.4521C8.33242 11.4521 7.86865 11.9142 7.86865 12.4984Z"
      fill={color}
    />
    <path
      d="M1.15942 15.9999H10.8406C11.4783 15.9999 12 15.4844 12 14.8543V3.10456C12 2.47449 11.4783 1.95898 10.8406 1.95898H8.98551V2.76089C8.98551 2.97091 8.81159 3.14274 8.59903 3.14274H3.40097C3.18841 3.14274 3.01449 2.97091 3.01449 2.76089V1.95898H1.15942C0.521739 1.95898 0 2.47449 0 3.10456V14.8543C0 15.4844 0.521739 15.9999 1.15942 15.9999ZM10.7556 12.4983V12.5632C10.7671 13.0481 10.5894 13.5064 10.2493 13.85C9.90145 14.2013 9.43382 14.3961 8.92754 14.3961C8.43285 14.3961 7.96908 14.2052 7.62512 13.8615C7.28502 13.5216 7.09952 13.0672 7.09952 12.5861V12.4944C7.09952 12.0057 7.29275 11.5474 7.64058 11.2076C7.98454 10.8715 8.44444 10.6882 8.9314 10.6882C9.42609 10.6882 9.88985 10.8792 10.2338 11.2228C10.5662 11.5665 10.7556 12.0171 10.7556 12.4983ZM2.40386 4.94893H9.59614C9.8087 4.94893 9.98261 5.12076 9.98261 5.33079C9.98261 5.54081 9.8087 5.71264 9.59614 5.71264H2.40386C2.1913 5.71264 2.01739 5.54081 2.01739 5.33079C2.01739 5.12076 2.1913 4.94893 2.40386 4.94893ZM2.40386 7.6983H9.59614C9.8087 7.6983 9.98261 7.87014 9.98261 8.08016C9.98261 8.29018 9.8087 8.46202 9.59614 8.46202H2.40386C2.1913 8.46202 2.01739 8.29018 2.01739 8.08016C2.01739 7.87014 2.1913 7.6983 2.40386 7.6983ZM2.40386 10.4477H5.18647C5.39903 10.4477 5.57295 10.6195 5.57295 10.8295C5.57295 11.0396 5.39903 11.2114 5.18647 11.2114H2.40386C2.1913 11.2114 2.01739 11.0396 2.01739 10.8295C2.01739 10.6195 2.1913 10.4477 2.40386 10.4477Z"
      fill={color}
    />
  </svg>
);

export default SendContractIcon;
