import { SvgIconProps } from '../Icon';

const MailIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 32 19" fill={bgColor} {...otherProps}>
    <rect
      x={1}
      y={1}
      width={30}
      height={17}
      rx={2}
      fill={bgColor}
      stroke={color}
      strokeWidth={2}
    />
    <path
      transform="matrix(.87793 .4788 -.52151 .85324 2.152 .874)"
      fill={bgColor}
      stroke={color}
      strokeWidth={0.877}
      d="M.156.584h16.258v.877H.156z"
    />
    <path
      transform="matrix(.87793 -.4788 .52151 .85324 15.045 9.286)"
      fill={bgColor}
      stroke={color}
      strokeWidth={0.877}
      d="M.613.164h16.052v.877H.613z"
    />
  </svg>
);

export default MailIcon;
