import { useReducer } from 'react';
import { ContractBlob } from 'kakemono';

export enum ContractTemplateActionType {
  SET_TEMPLATE,
  UPDATE_DEFAULT_PAYMENT_ROW_COUNT,
  UPDATE_TOS,
  UPDATE_IMAGE_ATTRIBUTE,
}

export type ContractTemplateAction = {
  type: ContractTemplateActionType.SET_TEMPLATE,
  payload: { template: ContractBlob },
} | {
  type: ContractTemplateActionType.UPDATE_DEFAULT_PAYMENT_ROW_COUNT,
  payload: { rowCount: number },
} | {
  type: ContractTemplateActionType.UPDATE_TOS,
  payload: { body: string },
} | {
  type: ContractTemplateActionType.UPDATE_IMAGE_ATTRIBUTE,
  payload: { includeImage: boolean },
};

export function contractTemplateReducer(
  state: ContractBlob | null,
  action: ContractTemplateAction,
): ContractBlob | null {
  switch (action.type) {
  case ContractTemplateActionType.SET_TEMPLATE:
    return action.payload.template || state;
  case ContractTemplateActionType.UPDATE_DEFAULT_PAYMENT_ROW_COUNT: {
    if (!state) return state;
    return {
      ...state,
      body: {
        ...state.body,
        nodes: state.body.nodes.map(node => (
          node.id === 'payment-table' || node.id === 'payment-table-with-total' ?
            {
              ...node,
              attributes: {
                ...(node.attributes as Record<string, any>),
                defaultRowCount: action.payload.rowCount,
              },
            } :
            node
        )),
      },
    };
  }
  case ContractTemplateActionType.UPDATE_TOS: {
    if (!state) return state;
    return {
      ...state,
      body: {
        ...state.body,
        nodes: state.body.nodes.map(node => (
          node.id === 'editable-terms' ?
            {
              ...node,
              attributes: {
                ...(node.attributes as Record<string, any>),
                body: action.payload.body,
              },
            } :
            node
        )),
      },
    };
  }
  case ContractTemplateActionType.UPDATE_IMAGE_ATTRIBUTE: {
    if (!state) return state;
    return {
      ...state,
      body: {
        ...state.body,
        nodes: state.body.nodes.map(node => (
          node.id === 'document-header' ?
            {
              ...node,
              attributes: {
                ...(node.attributes as Record<string, any>),
                includeImage: action.payload.includeImage,
              },
            } :
            node
        )),
      },
    };
  }
  default:
    throw new Error('Unknown contract wizard action');
  }
}

export const getTosMarkdown = (template: ContractBlob): string => {
  const tosNode = template?.body?.nodes?.find(node => node.id === 'editable-terms');
  const tosMarkdown = (tosNode?.attributes as Record<'body', string>).body;
  return tosMarkdown;
};

export const getDefaultPaymentNumber = (template: ContractBlob, nodeId?: string): number => {
  const paymentsTableNode = template?.body?.nodes?.find(node =>
    node.id === 'payment-table' || node.id === 'payment-table-with-total',
  );
  const nodeAttributes = paymentsTableNode?.attributes;

  // if the payment table node is using the last row as a total, then return one less
  // than the default row count to account for it in the payments
  if (nodeId === 'payment-table-with-total' && nodeAttributes?.lastRowIsFooter) {
    return (nodeAttributes as Record<'defaultRowCount', number>).defaultRowCount - 1;
  }

  return (nodeAttributes as Record<'defaultRowCount', number>).defaultRowCount;
};

export const getIncludeLogo = (template: ContractBlob): boolean => {
  const headerNode = template?.body?.nodes?.find(node => node.id === 'document-header');
  return (headerNode?.attributes as Record<'includeImage', boolean>)?.includeImage || false;
};

type ReducerType = [
  ContractBlob | null,
  (arg0: ContractTemplateAction) => void,
];

const useEditContractTemplateReducer = (): ReducerType =>
  useReducer(contractTemplateReducer, null);

export default useEditContractTemplateReducer;
