import { useHistory } from 'react-router-dom';

import { Typography } from 'common/components/material-ui';
import { GetStartedContractorFragment } from 'types';

import { GetStartedListItem, GetStartedButton } from '..';
import { Task } from '../../useGetStarted';
import { useGetStartedActions } from '../../useGetStartedActions';

type Props = {
  contractor: GetStartedContractorFragment;
};

const MarketingToolTask = ({ contractor }: Props): JSX.Element => {
  const history = useHistory();
  const { customSettings: { marketingToolsAccessed } } = contractor;
  const { upsertContractorSettings } = useGetStartedActions();
  const handleClick = () => {
    history.push('/dashboard/tools/marketing');
    upsertContractorSettings({ marketingToolsAccessed: true });
  };

  return (
    <GetStartedListItem
      taskCompleted={marketingToolsAccessed}
      taskName={Task.MARKETING_TOOL}
    >
      <Typography variant="p2">
        Access marketing and sales tools to generate leads and close more deals.
      </Typography>
      <GetStartedButton
        sx={{ mt: 2, py: 1 }}
        onClick={handleClick}
        data-testid="marketing-tool-button"
      >
        Get Started
      </GetStartedButton>
    </GetStartedListItem>
  );
};

export default MarketingToolTask;
