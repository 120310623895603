import { contractor, organization, article } from 'contractor/reducers';

import ThirdPartyState from './ThirdPartyState';
import UserState from './UserState';
import ServerConstantsState from './serverConstantsState';

export default {
  thirdPartyState: ThirdPartyState,
  user: UserState,
  serverConstants: ServerConstantsState,
  contractor,
  organization,
  article,
};
