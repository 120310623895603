import { CircularProgress, useTheme } from '@mui/material';

import { GetStartedButton } from '.';

type LoadingButtonProps = {
  onClick: () => void,
  baseText: string,
  loadingText: string,
  loading: boolean;
}

const LoadingButton = ({
  onClick,
  baseText,
  loadingText,
  loading,
}: LoadingButtonProps): JSX.Element => {
  const theme = useTheme();
  if (loading) {
    return (
      <GetStartedButton
        sx={{
          mt: 2,
          py: 1,
          px: 2,
          backgroundColor: theme.palette.common.primary100,
          color: theme.palette.common.primary500,
          boxShadow: 'none',
          '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.common.primary100,
            color: theme.palette.common.primary500,
            boxShadow: 'none',
          },
        }}
      >
        {loadingText}
        <CircularProgress
          size={18}
          sx={{
            ml: 1,
            color: theme.palette.common.primary500,
          }}
        />
      </GetStartedButton>
    );
  }
  return (
    <GetStartedButton
      onClick={onClick}
      sx={{
        mt: 2,
        py: 1,
        px: 2,
      }}
    >
      {baseText}
    </GetStartedButton>
  );
};

export default LoadingButton;
