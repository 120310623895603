import { useState, useMemo, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext, useField } from 'formik';
import { useDebounce, useMount } from 'react-use';
import { ContractTemplate } from 'kakemono';

import { ContractTemplateActionType } from 'contractor/hooks/useEditContractTemplateReducer';
import { slateToMd } from 'common/components/elements/SlateEditor/serializers';
import { InputField } from 'common/components/formik';
import { useSnack } from 'common/utils/snackCart';
import { useGql, useLogEvent } from 'common/hooks';

import useEditContractTemplate, { useEditContractTemplateActions } from '../useEditContractTemplate';
import ContractRenderer from './ContractRenderer';
import { EditTemplateValues } from '../EditContractTemplate';

const useStyles = makeStyles(theme => ({
  title: {
    padding: '8px 4px',
    marginTop: 8,
    borderRadius: 4,
    '&:hover': {
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
      width: 'fit-content',
    },
  },
  titleInput: {
    marginTop: 8,
    padding: '8px 4px',
    width: 400,
  },
  titleInputFocused: {
    paddingLeft: 4,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    marginTop: -6, // to prevent shifting with box shadow
    marginBottom: -6,
  },
  inputFontSize: {
    ...theme.typography.h4,
  },
}));

const EditContractPreview = (): JSX.Element | null => {
  const classes = useStyles();
  const {
    contractor,
    organization,
    contractTemplate: {
      template,
      dispatch,
    },
    selectedTemplate,
    allTemplates,
  } = useEditContractTemplate();

  const itly = useLogEvent();
  const snack = useSnack();
  const { hydrateTemplate, generatePreviewPdf } = useEditContractTemplateActions();
  const { values } = useFormikContext<EditTemplateValues>();
  const [{ value: templateName }, , { setValue: setTemplateName }] = useField<string>('templateName');
  const [base64Pdf, setBase64Pdf] = useState<string | null>(null);
  const { handleMutationError } = useGql();

  useMount(() => itly.editContractTemplate());

  useEffect(() => {
    // typecheck
    if (!selectedTemplate?.versionName) return;
    setTemplateName(selectedTemplate.versionName);
    // including setTemplateName as a dep causes infinite rerenders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useDebounce(() => {
    dispatch({
      type: ContractTemplateActionType.UPDATE_DEFAULT_PAYMENT_ROW_COUNT,
      payload: {
        rowCount: values.defaultNumberOfPayments,
      },
    });
  }, 100, [values.defaultNumberOfPayments]);

  const [editTemplateName, setEditTemplateName] = useState(false);

  useDebounce(() => {
    dispatch({
      type: ContractTemplateActionType.UPDATE_TOS,
      payload: {
        body: slateToMd(values.tosSlateValue),
      },
    });
  }, 1000, [values.tosSlateValue]);

  useDebounce(() => {
    dispatch({
      type: ContractTemplateActionType.UPDATE_IMAGE_ATTRIBUTE,
      payload: {
        includeImage: values.includeLogo,
      },
    });
  }, 1000, [values.includeLogo]);

  const previewArgs = useMemo(() => {
    if (!contractor) return null;

    return {
      contractor: {
        email: contractor.email,
        name: contractor.fullName,
        phoneNumber: values.businessPhone,
      },
      company: {
        name: values.companyName,
        addressLine1: values.address.address,
        addressLine2: `${values.address.city}, ${values.address.state} ${values.address.zipCode}`,
        businessLicense: values.businessLicenseNumber ?
          `LIC# ${values.businessLicenseNumber}` :
          undefined,
        logo: organization?.logoUrl,
      },
    };
  }, [contractor, values, organization?.logoUrl]);

  const contractPreview = useMemo(() => {
    if (!template || !previewArgs) return null;
    const modifiedTemplate = hydrateTemplate(template, values);
    return ContractTemplate(modifiedTemplate).preview(previewArgs);
  }, [hydrateTemplate, previewArgs, template, values]);

  useEffect(() => {
    if (!contractPreview) return;
    generatePreviewPdf(contractPreview).then(setBase64Pdf, e => handleMutationError(e, {}));
  }, [contractPreview, generatePreviewPdf, handleMutationError]);

  if (!base64Pdf) {
    return (
      <Box
        mt={10}
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  // typecheck
  if (!selectedTemplate) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" mb={4}>
        <Typography variant="body2">
          <Box color="common.basic900" pl={0.5}>
            Contract template name
          </Box>
        </Typography>
        {!editTemplateName ?
          <Box className={classes.title} onClick={() => setEditTemplateName(true)}>
            <Typography variant="h4">
              {templateName}
            </Typography>
          </Box> :
          <InputField
            name="templateName"
            label=""
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onBlur={(e) => {
              if (e.target.value === '' && selectedTemplate?.versionName) {
                setTemplateName(selectedTemplate.versionName);
              } else {
                // check whether there is already a template with the same name
                // and different id to verify unique name
                const titleAlreadyExists = allTemplates.find(temp =>
                  temp.versionName &&
                      (temp.versionName.toLowerCase() ===
                      values.templateName.toLowerCase()) &&
                      temp.id !== selectedTemplate?.id,
                );
                if (titleAlreadyExists && selectedTemplate?.versionName) {
                  snack.errorSnack('A template with this name already exists');
                  setTemplateName(selectedTemplate.versionName);
                } else {
                  setTemplateName(e.target.value);
                  itly.editContractTemplateName();
                }
                setEditTemplateName(false);
              }
            }}
            classes={{
              root: classes.titleInput,
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              classes: {
                focused: classes.titleInputFocused,
                input: classes.inputFontSize,
              },
            }}
          />
        }
      </Box>
      <ContractRenderer
        width={900}
        base64PDF={base64Pdf}
      />
    </Box>
  );
};

export default EditContractPreview;
