import { SvgIconProps } from '../Icon';

const TadaIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" {...otherProps}>
    <path
      d="M22.125 15.25a.624.624 0 101.25 0 3.754 3.754 0 013.75-3.75 5.006 5.006 0 005-5 .625.625 0 10-1.25 0 3.754 3.754 0 01-3.75 3.75 5.006 5.006 0 00-5 5zM24.938 4.625a2.188 2.188 0 100-4.375 2.188 2.188 0 000 4.375zM35.562 15.25a2.187 2.187 0 100-4.375 2.187 2.187 0 000 4.375zM35.25 5.25a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM15.053 7.718a.625.625 0 00.79-.396l1.875-5.625a.626.626 0 00-1.188-.395l-1.875 5.625a.625.625 0 00.398.79zM19 11.5a.625.625 0 00.509-.262l3.125-4.375a.625.625 0 00-1.017-.726l-3.125 4.375A.625.625 0 0019 11.5zM31.137 15.367l-4.375 3.125a.624.624 0 00.253 1.14.626.626 0 00.473-.124l4.375-3.125a.624.624 0 00-.726-1.016zM11.33 7.947a.625.625 0 00-1.045.223L.687 35.758a1.25 1.25 0 001.545 1.607l27.575-8.392a.626.626 0 00.274-1.026l-18.75-20zM4.097 35.494l-.975-2.923 1.156-3.321 1.875 5.621-2.056.623zm6.24-1.9L6.632 22.477l1.156-3.323 4.605 13.813-2.055.627zm6.243-1.9L10.14 12.38l.984-2.824.524.558 6.985 20.953-2.055.626zm6.241-1.9L18.805 17.75l3.512 3.75 2.558 7.668-2.055.626zM36.303 20.282l-5.625 1.875a.625.625 0 10.395 1.188l5.624-1.875a.627.627 0 00-.395-1.188z"
      fill={color}
    />
  </svg>
);

export default TadaIcon;
