import { useEffect } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import { useLogEvent, useQueryParams } from 'common/hooks';

import {
  ContractWizardProvider,
  useRefs,
  useWizard,
  ContractWizardStep,
} from './useContractWizard';
import {
  CompanyStep,
  WelcomeStep,
  PaymentStep,
  TosStep,
} from './steps';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    borderRadius: 16,
    backgroundColor: theme.palette.common.basic100,
    [theme.breakpoints.up('md')]: {
      minWidth: 800,
    },
  },
}));

type QueryParams = {
  source?: string;
  utmMedium?: string;
}

/*
 * Inspired by MUI Dialog API (Dialog, DialogContent, DialogTitle, etc)
 * With React Portal we can separate Step layout from individual steps
 * Each step would have to render WizardHeader and WizardContent
 */
const ContractWizard = (): JSX.Element => {
  const classes = useStyles();
  const { setHeaderRef, setContentRef, setBottomSectionRef } = useRefs();
  const { currentStep } = useWizard();
  const itly = useLogEvent();
  const location = useLocation();
  const queryParams = useQueryParams<QueryParams>();

  useMount(() => {
    itly.viewContractLandingPage({ source: queryParams.source || queryParams.utmMedium });
  });

  useEffect(() => {
    if (location.pathname === '/dashboard/tools/contract-template/setup') {
      itly.viewContractSetup();
    }
  }, [itly, location.pathname]);

  return (
    <>
      <Box className={classes.root}>
        {/* Step layout */}
        <Box p={4} bgcolor="common.purple500" color="common.basic100">
          <div ref={setHeaderRef} />
        </Box>
        <Box px={4} pt={5} pb={6}>
          <div ref={setContentRef} />
        </Box>
        {/* Steps */}
        {currentStep === ContractWizardStep.WELCOME &&
          <WelcomeStep />
        }
        {currentStep === ContractWizardStep.COMPANY &&
          <CompanyStep />
        }
        {currentStep === ContractWizardStep.PAYMENT &&
          <PaymentStep />
        }
        {currentStep === ContractWizardStep.TOS &&
          <TosStep />
        }
      </Box>
      <div ref={setBottomSectionRef} />
    </>
  );
};

export default (): JSX.Element => (
  <ContractWizardProvider>
    <ContractWizard />
  </ContractWizardProvider>
);
