
type Props = {
  children: React.ReactChildren | React.ReactChild | React.ReactNode;
  className?: string;
  noMargin?: boolean;
}

const Row = ({
  children,
  className = '',
  noMargin = false,
  ...otherProps
}: Props): JSX.Element => (
  <div className={`row ${className} ${noMargin ? 'row-no-margin' : ''}`} {...otherProps}>{children}</div>
);

export default Row;
