import { SvgIconProps } from '../Icon';

const LockFilledIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="none"
    {...otherProps}
  >
    <path
      d="M10 5.333h-.667V4a3.335 3.335 0 00-6.667 0v1.333H2c-.734 0-1.333.6-1.333 1.333v6.667c0 .733.6 1.333 1.333 1.333h8c.733 0 1.333-.6 1.333-1.333V6.666c0-.733-.6-1.333-1.333-1.333zM4 4c0-1.107.893-2 2-2 1.106 0 2 .893 2 2v1.333H4V4z"
      fill={color}
    />
  </svg>
);

export default LockFilledIcon;
