import Button, { ButtonProps } from 'common/components/material-ui/Button';

const GetStartedButton = ({ children, sx, ...props }: ButtonProps): JSX.Element => (
  <Button
    buttonType="common.purple"
    variant="contained"
    size="small"
    sx={{ ...sx, borderRadius: 8 }}
    {...props}
  >
    {children}
  </Button>
);

export default GetStartedButton;
