import { useCallback } from 'react';
import { useSet } from 'react-use';
import {
  Box,
  Divider,
  CardMedia,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Button, Checkbox } from 'common/components/material-ui';
import { currencyFormat, percentFormat } from 'common/utils/stringFormatters';
import { Popup } from 'contractor/components/widgets';

import { useSortingOffers } from '../useSortingOffers';

export type AmountInfo = {
  amount: number;
  lowestApr: string | number;
  selected: boolean;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.common.primary300,
    padding: '20px 40px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  divider: {
    color: 'common.basic300',
    width: '100%',
    height: 1.5,
  },
  awardImg: {
    height: 90,
    width: 90,
    objectFit: 'contain',
  },
  selectButton: {
    alignSelf: 'flex-end',
  },
}));

const LoanFilterModal = ({
  isOpen,
  onClose,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();
  const {
    processState: {
      hiddenLoanAmounts: initialHiddenAmounts,
      allLoanAmounts,
    },
    actions: {
      filterLoanAmounts: onFilterAmounts,
    },
  } = useSortingOffers();

  const [hiddenAmounts, { has: isHidden, toggle: toggleHiddenAmount }] = useSet(
    initialHiddenAmounts,
  );

  const checkAmount = useCallback((amountOption: AmountInfo) => {
    toggleHiddenAmount(amountOption.amount);
  }, [toggleHiddenAmount]);

  return (
    <Popup
      open={isOpen}
      onClose={() => {
        // if we don't explicitly select, set the hidden amounts back to what was previously set
        onFilterAmounts(initialHiddenAmounts);
        onClose();
      }}
      {...otherProps}
    >
      <Box display="flex" flexDirection="row" className={classes.header}>
        <CardMedia
          className={classes.awardImg}
          component="img"
          image="https://hearth.imgix.net/offerspage/award.png"
          alt="Award"
        />
        <Typography variant="h3">
          <Box fontWeight="fontWeightMedium" mx={3}>
            You’re pre-qualified for{'\n\n'}
            these amounts!
          </Box>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={5} pt={2} px={5}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
          <Typography variant="caption">SELECT AMOUNTS TO COMPARE</Typography>
          <Typography variant="caption">LOWEST APR</Typography>
        </Box>
        <Divider className={classes.divider} />
        {allLoanAmounts.sort((a, b) => a.amount - b.amount).map((amountOption, index) => (
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Checkbox
                color="primary"
                checked={!isHidden(amountOption.amount)}
                onChange={() => checkAmount(amountOption)}
                label={
                  <Typography variant="subtitle2">
                    {currencyFormat(amountOption.amount)}
                  </Typography>
                }
              />
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2">
                  <Box fontWeight="fontWeightRegular">
                    options from {percentFormat(amountOption.lowestApr, 2)}
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.divider} />
          </>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" px={5} pb={5}>
        <Button
          variant="contained"
          buttonType="primary"
          className={classes.selectButton}
          onClick={() => {
            onFilterAmounts(hiddenAmounts);
            onClose();
          }}
        >
          Select
        </Button>
      </Box>
    </Popup>
  );
};

export default LoanFilterModal;
