import * as React from 'react';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';

import Button, { ButtonProps } from 'common/components/material-ui/Button';

const useStyles = makeStyles(theme => ({
  root: ({ borderColor }: Partial<OfferBoxProps>) => ({
    backgroundColor: theme.palette.common.basic100,
    border: borderColor ? `1px solid ${theme.palette.common[borderColor]}` : 'none',
    borderRadius: 20,
    padding: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 4,
      padding: '16px 16px 8px',
    },
  }),
  chooseButton: {
    backgroundColor: theme.palette.common.success500,
    boxShadow: 'none',
    borderRadius: 6,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  innerButtonContainer: ({ showClose }: Partial<OfferBoxProps>) => ({
    width: showClose ? '120%' : '100%',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
  }),
  closeIcon: {
    height: 24,
    width: 24,
  },
  buttonGridContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
}));

export type OfferBoxProps = {
  children: React.ReactNode;
  chooseLink?: string;
  mobileDisclosure?: React.ReactNode;
  rightAction?: React.ReactNode;
  rootStyles?: React.CSSProperties;
  borderColor?: Color;
  offerMetaData?: Record<string, string | number>;
  buttonCta?: React.ReactNode;
  showClose?: boolean;
  onClose?: () => void;
  header?: React.ReactNode;
} & Partial<ButtonProps>;

const OfferBox = ({
  children,
  chooseLink,
  offerMetaData,
  mobileDisclosure = null,
  rightAction = null,
  rootStyles = {},
  borderColor,
  showClose = false,
  buttonCta = 'Choose',
  onClose,
  header,
  className,
  ...otherProps
}: OfferBoxProps): JSX.Element => {
  const classes = useStyles({ borderColor, showClose });

  return (
    <div className={cx(classes.root, className)} style={rootStyles}>
      {header}
      <Grid container>
        <Grid item xs={12} sm={showClose ? 8 : 9}>
          {children}
        </Grid>
        <Grid item xs={12} sm={showClose ? 4 : 3} className={classes.buttonContainer}>
          <Grid container className={classes.buttonGridContainer}>
            <Grid item sm={showClose ? 11 : 12}>
              {rightAction ||
                <Box display="flex" flexDirection="column" className={classes.innerButtonContainer}>
                  <Button
                    buttonType="success"
                    className={classes.chooseButton}
                    component="a"
                    target="_blank"
                    href={chooseLink}
                    variant="contained"
                    {...otherProps}
                  >
                    {buttonCta}
                  </Button>
                  {mobileDisclosure}
                </Box>
              }
            </Grid>
            {showClose &&
              <Grid item xs={1}>
                <Box onClick={onClose} display="flex" alignSelf="flex-end" mt={-5} mr={-4}>
                  <CloseIcon className={classes.closeIcon} />
                </Box>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OfferBox;
