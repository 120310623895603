import { stringify } from 'query-string';

import { MARKETING_HOST } from 'common/utils/host';

const buildUrl = (host: string | undefined, path: string, query = {}): string => {
  if (path.indexOf('/') !== 0) {
    throw new Error('path must start with a /');
  }
  const queryString = stringify(query);
  return `${host}${path}${queryString && '?'}${queryString}`;
};

export const apiUrl = (path: string, query = {}): string => {
  if (path.indexOf('/api/v2/') !== 0) {
    throw new Error('path must start with a /api/v2');
  }
  return buildUrl(process.env.REACT_APP_API_HOST, path, query);
};

export const appUrl = (path: string, query = {}): string =>
  buildUrl(process.env.REACT_APP_API_HOST, path, query);

export const marketingUrl = (path: string, query = {}): string =>
  buildUrl(MARKETING_HOST, path, query);

export const trainingVideoUrl = 'https://www.youtube.com/watch?v=6e8tm82h-cA';
export const popPageVideoUrl = 'https://www.youtube.com/watch?v=zUQjSncm1LY';
