import { useCallback } from 'react';
import * as React from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

export type RadioGroupProps = MuiRadioGroupProps;

const RadioGroup = ({ onChange, ...otherProps }: RadioGroupProps): JSX.Element => {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (onChange) {
      onChange(event, value);
    }
  }, [onChange]);

  return (
    <MuiRadioGroup onChange={handleChange} {...otherProps} />
  );
};

export default RadioGroup;
