import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import userFeatureFlags from 'common/graphql/featureFlags';
import { NavDrawerItem, NavDrawerSecondaryItem } from 'common/components/material-ui';

import {
  useDashboardNavigation,
  NavigationOption,
  useDashboardNavActions,
} from '../useDashboardNavigation';

/**
 *
 * NAV: Workflows
 *
 * SUBNAV:
 *
 * * Financing
 *
 * * Quotes
 * - Pre-release: all elite and pro admins
 * - After release: orgs with quotes-2021-11 feature flag
 *
 * * Contracts:
 * - Feature flags: contracts
 *
 * * Invoices:
 * - Feature flags: paymentsSystem
 *
 * * Reviews:
 * - >= 1 homeowner feedback response
 *
 */

type SubnavData = {
  title: string;
  url: string;
  featureStatus?: string;
}

 type SubnavMappingKeys =
  NavigationOption.FINANCING |
  NavigationOption.QUOTES |
  NavigationOption.INVOICES |
  NavigationOption.CONTRACTS |
  NavigationOption.REVIEWS;

type SubnavMapping = Record<SubnavMappingKeys, SubnavData>

const WorkflowsTab = (): JSX.Element => {
  const {
    customerDetails,
    paymentsSystem,
    contracts,
    hearthEssentials,
    quotes,
  } = useReactiveVar(userFeatureFlags);
  const { onNavItemClick } = useDashboardNavActions();
  const { contractor, organization, uiStates: { activeTab } } = useDashboardNavigation();

  const contractsNotSetup = useMemo(() =>
    organization?.contractServiceAccount?.termsAgreedAt == null,
  [organization?.contractServiceAccount?.termsAgreedAt]);

  // if not set up, only show the contracts tab only if:
  // 1) they are NOT on hearth essentials
  // 2) they have the FF
  // 3) they are an admin

  // if they are set up, check only the FF
  const showContracts = useMemo(() => {
    if (hearthEssentials) return false;
    if (contractsNotSetup) return contracts && contractor?.admin;

    return contracts;
  }, [contractor?.admin, contracts, contractsNotSetup, hearthEssentials]);

  // show quotes if:
  // 1) not essentials
  // 2) no feature flag and admin (for waitlist)
  // 3) otherwise, show if feature flag enabled
  const showQuotes = useMemo(() => {
    if (hearthEssentials) return false;
    if (!quotes) return contractor?.admin;
    return quotes;
  }, [contractor?.admin, quotes, hearthEssentials]);

  const subnavMapping: SubnavMapping = {
    [NavigationOption.FINANCING]: {
      title: 'Financing',
      url: '/dashboard/workflows/financing',
    },
    [NavigationOption.QUOTES]: {
      title: 'Quotes',
      url: '/dashboard/workflows/quotes/all?source=dashboard',
      featureStatus: quotes ? 'New' : undefined,
    },
    [NavigationOption.CONTRACTS]: {
      title: 'Contracts',
      url: contractsNotSetup ? '/dashboard/tools/contract-template/setup' :
        '/dashboard/workflows/contracts',
    },
    [NavigationOption.INVOICES]: {
      title: 'Invoices',
      url: '/dashboard/workflows/invoices',
    },
    [NavigationOption.REVIEWS]: {
      title: 'Reviews',
      url: '/dashboard/reviews',
    },
  };

  const subNavItems = useMemo(() => (
    [
      customerDetails && NavigationOption.FINANCING,
      showQuotes && NavigationOption.QUOTES,
      showContracts && NavigationOption.CONTRACTS,
      paymentsSystem && NavigationOption.INVOICES,
      contractor?.hasHomeownerFeedback && NavigationOption.REVIEWS,
    ].filter(Boolean) as SubnavMappingKeys[]
  ), [
    showContracts,
    showQuotes,
    contractor?.hasHomeownerFeedback,
    customerDetails,
    paymentsSystem,
  ]);

  return (
    <>
      <NavDrawerItem
        title="Workflows"
        icon="construction"
        color={activeTab === NavigationOption.WORKFLOW ? 'primary500' : 'basic1100'}
        active={activeTab === NavigationOption.WORKFLOW}
        onClick={() => onNavItemClick(subnavMapping[subNavItems[0]].url)}
      />
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
        {subNavItems.map((subNav) => {
          const navDetails = subnavMapping[subNav];

          return (
            <NavDrawerSecondaryItem
              key={navDetails.title}
              title={navDetails.title}
              color="basic1100"
              onClick={() => onNavItemClick(navDetails.url)}
              active={activeTab === subNav}
              featureStatus={navDetails?.featureStatus}
            />
          );
        })}
      </Box>
    </>
  );
};

export default WorkflowsTab;
