import { AxiosResponse } from 'axios';

import client from './client';

/*
  eslint-disable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
export const fetch = (id: string | number): Promise<AxiosResponse> => client.request({
  method: 'GET',
  url: `/contractors/${id}`,
});

export const update = (id: string | number, data: any): Promise<AxiosResponse> => client.request({
  method: 'PUT',
  url: `/contractors/${id}`,
  data: { contractor: data },
});

export const contractorSignup = (signupData: any, extraParams = {}): Promise<AxiosResponse> =>
  client.request({
    method: 'POST',
    url: '/contractors',
    baseURL: `${process.env.REACT_APP_API_HOST}/`,
    data: { contractor: signupData, ...extraParams },
  });

export const getContractorByPersonId = (personId: string): Promise<AxiosResponse> =>
  client.request({
    method: 'GET',
    url: `/contractors/find_contractor_by_person_id/${personId}`,
  });

export const sendEmail = (emailData: any, contractorId: string | number): Promise<AxiosResponse> =>
  client.request({
    method: 'POST',
    url: `/contractors/${contractorId}/send_email`,
    data: emailData,
  });

export const editPassword = (passwordData: any): Promise<AxiosResponse> => client.request({
  method: 'PUT',
  url: '/contractors/password',
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
  data: { contractor: passwordData },
});

export const commonResetPassword = (email: string): Promise<AxiosResponse> => client.request({
  method: 'POST',
  url: '/contractors/generic_reset_password',
  data: { email },
});

export const resetPassword = (id: string | number): Promise<AxiosResponse> => client.request({
  method: 'POST',
  url: `/contractors/${id}/reset_password`,
});

export const sendMobileAppLink = (
  id: string | number,
  phoneNumber: string,
): Promise<AxiosResponse> =>
  client.request({
    method: 'POST',
    url: `/contractors/${id}/send_mobile_app_link`,
    data: { phoneNumber },
  });

export const sendBannerInstallEmail = ({
  contractorId,
  organizationUrlPath,
  bannerHeight,
  bannerWidth,
  extraOptions,
  color,
  utmParams,
  destinationEmail,
}: Record<string, any>): Promise<AxiosResponse> =>
  client.request({
    method: 'POST',
    url: `/contractors/${contractorId}/send_banner_install_email`,
    data: {
      organizationUrlPath,
      bannerHeight,
      bannerWidth,
      extraOptions,
      color,
      utmParams,
      destinationEmail,
    },
  });

export const deactivateContractor = (contractorId: string | number): Promise<AxiosResponse> =>
  client.request({
    method: 'PUT',
    url: `/contractors/${contractorId}/deactivate`,
  });

export const submitQuickPoll = (contractorId: string | number, data: any): Promise<AxiosResponse> =>
  client.request({
    method: 'PUT',
    url: `/contractors/${contractorId}/quick_poll`,
    data,
  });

export type SendContactEmailArgs = {
  contractorId: string;
  data: {
    reCaptchaKey: string;
    replyEmail: string;
    subject: string;
    body: string;
  };
};

export const sendContactEmail = (args: SendContactEmailArgs):
Promise<AxiosResponse> => client.request({
  method: 'POST',
  url: `/contractors/${args.contractorId}/send_contact_email`,
  data: args.data,
});

type ClaimGiftCardArgs = {
  id: number;
};
type ClaimGiftCardResponse = AxiosResponse<{
  claimUrl: string;
}>;
export const claimGiftCard = ({ id }: ClaimGiftCardArgs): Promise<ClaimGiftCardResponse> =>
  client.post(`/contractors/${id}/claim_gift_card`);

type UploadInvoiceAttachmentArgs = {
  id: number;
  attachment: File;
};
type UploadInvoiceAttachmentResponse = AxiosResponse<{
  id: string;
}>;
export const uploadInvoiceAttachment =
  ({ id, attachment }: UploadInvoiceAttachmentArgs): Promise<UploadInvoiceAttachmentResponse> => {
    const data = new FormData();
    data.append('attachment', attachment);

    return client.post(`/contractors/${id}/upload_invoice_attachment`, data);
  };
/*
  eslint-enable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
