import { SvgIconProps } from '../Icon';

const RightArrowIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...otherProps}>
    <path
      d="M6 .666l-.94.94 3.72 3.727H.665v1.333h8.113l-3.72 3.727.94.94 5.334-5.334L5.999.666z"
      fill={color}
    />
  </svg>
);

export default RightArrowIcon;
