import { Formik, Form, FormikValues } from 'formik';
import { string, object } from 'yup';
import { Box } from '@mui/material';

import { Input, Button } from 'common/components/material-ui';
import { buildMaterialInputParams } from 'common/utils/stepUtils';

type Props = {
  onSubmit: (values: FormikValues) => void;
}

const ForgotPasswordForm = ({ onSubmit }: Props): JSX.Element => (
  <Formik
    isInitialValid
    initialValues={{ email: '' }}
    validateOnChange={false}
    validationSchema={object().shape({
      email: string()
        .label('Email')
        .email()
        .required(),
    })}
    validateOnBlur
    onSubmit={onSubmit}
  >
    {({
      handleChange,
      values,
      errors,
      isValidating,
    }) => (
      <Form>
        <Box my={2}>
          <Input
            fullWidth
            {...buildMaterialInputParams('email', values, errors, handleChange)}
            onChange={handleChange}
            label="Email address"
            type="email"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Box width={{ md: 3 / 5 }}>
            <Button
              variant="contained"
              buttonType="primary"
              disabled={isValidating}
              type="submit"
              fullWidth
            >
              Reset password
            </Button>
          </Box>
        </Box>
      </Form>
    )}
  </Formik>
);

export default ForgotPasswordForm;
