import { useMemo } from 'react';
import { useHistory, Redirect, Prompt } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  object,
  array,
  string,
  number,
  boolean,
} from 'yup';
import { TNode } from '@udecode/plate-core';
import { useFormikContext } from 'formik';
import { useBeforeUnload } from 'react-use';
import _ from 'lodash';
import { NodeConfig } from 'kakemono/build/lib/nodes/types';

import { SmallScreenFilter } from 'common/components/elements';
import { Form } from 'common/components/formik';
import { Button } from 'common/components/material-ui';
import { useLogEvent } from 'common/hooks';

import useEditContractTemplate, {
  useEditContractTemplateUIStates,
  useEditContractTemplateActions,
} from './useEditContractTemplate';
import {
  EditContractSidebar,
  EditingContractBanner,
  EditContractPreview,
  EditTosModal,
} from './components';

export type Checkbox = {
  itemName: string;
  isChecked: boolean;
}

export type EditTemplateValues = {
  templateName: string;
  companyName: string;
  address: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  includeLogo: boolean;
  businessLicenseNumber: string;
  businessPhone: string;
  defaultNumberOfPayments: number;
  tosSlateValue: TNode[];
  serviceDescription: string;
  customChecklist: Checkbox[];
  customTextEntries: string[][];
}

const ExitBlock = () => {
  const { values, initialValues } = useFormikContext();
  const { templateSubmitted } = useEditContractTemplateUIStates();

  const block = useMemo(() =>
    // Even once they submit, values and initial values will
    // be different, but we don't want the navigation block
    // to show after they have successfully submitted while
    // we are routing them back to the view template page.
    !_.isEqual(values, initialValues) && !templateSubmitted,
  [values, initialValues, templateSubmitted]);

  useBeforeUnload(block, 'Leave without saving? Your progress will be discarded.');

  return (
    <Prompt
      when={block}
      message="Leave without saving? Your progress will be discarded."
    />
  );
};

const EditContractTemplate = (): JSX.Element | null => {
  const history = useHistory();
  const itly = useLogEvent();
  const {
    loading,
    contractor,
    organization,
    contractTemplate: {
      initialDefaultPaymentCount,
      initialTosSlateValue,
      includeLogo,
    },
    selectedTemplate,
  } = useEditContractTemplate();
  const {
    editTosModalOpen,
    setEditTosModalOpen,
  } = useEditContractTemplateUIStates();
  const { upsertContractTemplate } = useEditContractTemplateActions();

  const templateFields = useMemo(() => {
    const template = selectedTemplate?.template;
    if (!template) return null;
    const serviceDescription =
      template.body.nodes.find((node: NodeConfig) => node.id === 'service-description-entered');
    const checkboxList =
      template.body.nodes.find((node: NodeConfig) => node.id === 'service-static-checkboxes');
    const detailsTable =
      template.body.nodes.find((node: NodeConfig) => node.id === 'description-detail-table');
    return {
      serviceDescription,
      checkboxList,
      detailsTable,
    };
  }, [selectedTemplate]);

  if (
    loading ||
    !contractor ||
    !organization ||
    !initialTosSlateValue ||
    !initialDefaultPaymentCount ||
    includeLogo == null
  ) {
    return null;
  }

  if (!contractor.admin) {
    return <Redirect to="/dashboard/tools/contract-template" />;
  }
  return (
    <Box px={5}>
      <Box py={2}>
        <Button
          variant="text"
          buttonType="common.primary"
          onClick={() => {
            history.push('/dashboard/workflows/contracts/contract-templates');
            // if the user has unsaved changes, a Prompt pops that blocks navigation --
            // we want to log this event only if the navigation occurs
            if (history.location.pathname === '/dashboard/workflows/contracts/contract-templates') {
              itly.cancelEditingContractTemplate();
            }
          }}
        >
          <Box display="flex" alignItems="center">
            <ArrowBackIcon style={{ marginRight: 12, fontSize: 20 }} />
            <Typography variant="body2">
              Back to all contracts
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box mb={3}>
        <EditingContractBanner />
      </Box>
      <Form<EditTemplateValues>
        initialValues={{
          templateName: selectedTemplate?.versionName || '',
          companyName: organization.companyName || '',
          address: {
            address: organization.address || '',
            city: organization.city || '',
            state: organization.state || '',
            zipCode: organization.zipCode || '',
          },
          businessLicenseNumber: organization.businessLicenseNumber || '',
          businessPhone: organization.businessPhone || '',
          includeLogo,
          tosSlateValue: initialTosSlateValue,
          defaultNumberOfPayments: initialDefaultPaymentCount,
          serviceDescription: templateFields?.serviceDescription?.attributes?.defaultContent || '',
          customChecklist: templateFields?.checkboxList?.attributes?.checkboxes || [],
          customTextEntries: templateFields?.detailsTable?.attributes?.previewValues || [],
        }}
        validationSchema={object().shape({
          templateName: string().label('Contract Name'),
          companyName: string().label('Company Name').required(),
          address: object().shape({
            address: string().label('Address').required(),
            city: string().label('City').required(),
            state: string().label('State').required(),
            zipCode: string().label('Zip Code').required(),
          }).required(),
          businessPhone: string().label('Business Phone'),
          businessLicenseNumber: string().label('Business License Number'),
          includeLogo: boolean().required(),
          defaultNumberOfPayments: number().required(),
          tosSlateValue: array().required(),
          serviceDescription: string(),
          customChecklist: array().of(object().shape({
            itemName: string(),
            isChecked: boolean(),
          })),
          customTextEntries: array().of(array().of(string())),
        })}
        onSubmit={upsertContractTemplate}
      >
        <ExitBlock />
        {editTosModalOpen &&
          <EditTosModal
            open={editTosModalOpen}
            onClose={() => setEditTosModalOpen(false)}
          />
        }
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box width="100%">
            <EditContractPreview />
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            height="fit-content"
          >
            <EditContractSidebar />
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default (): JSX.Element => (
  <SmallScreenFilter>
    <EditContractTemplate />
  </SmallScreenFilter>
);
