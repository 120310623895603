import {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import constate from 'constate';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import userFeatureFlags from 'common/graphql/featureFlags';
import { logoutDispatcher } from 'contractor/actions/auth';
import {
  CustomContractorSettingsAttributes,
  useGetDashboardNavDataQuery,
  useUpsertContractorDashoardInfoMutation,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';

export const GET_DASHBOARD_NAV_DATA = gql`
  query GetDashboardNavData {
    contractor {
      id
      admin
      firstName
      lastSignInAt
      enterpriseAdmin
      hasHomeownerFeedback
      affiliateReferralCode
      customSettings {
        sendNewProductTourSeen202201
        showNewProductTour202201
      }
    }
    organization {
      id
      logoUrl
      companyName
      insuranceEligible
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
`;

export const UPSERT_CONTRACTOR_DASHBOARD_NAV_INFO = gql`
  mutation UpsertContractorDashoardNavInfo($customSettings: CustomContractorSettingsAttributes!) {
    upsertContractor(customSettings: $customSettings) {
      ... on UpsertContractorSuccess {
        contractor {
          id
          customSettings {
            sendNewProductTourSeen202201
          }
        }
      }
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export enum NavigationOption {
  DASHBOARD = 'dashboard',
  CLIENTS = 'clients',
  LEADS = 'leads',
  WORKFLOW = 'workflow',
  INVOICES = 'invoices',
  CONTRACTS = 'contracts',
  FINANCING = 'financing',
  QUOTES = 'quotes',
  REVIEWS = 'reviews',
  BUSINESS = 'business',
  TEAM = 'team',
  INSURANCE = 'insurance',
  BRANDING = 'branding',
  INSIGHTS = 'insights',
  HELP = 'help',
  SETTINGS = 'settings',
  BANKING = 'banking',
  LENDING = 'lending',
}

export const activeTabMap: Record<string, NavigationOption> = {
  '/dashboard': NavigationOption.DASHBOARD,
  '/dashboard/clients': NavigationOption.CLIENTS,
  '/dashboard/clients/leads': NavigationOption.LEADS,
  '/dashboard/workflows/invoices': NavigationOption.INVOICES,
  '/dashboard/workflows/contracts': NavigationOption.CONTRACTS,
  '/dashboard/tools/contract-template': NavigationOption.CONTRACTS,
  '/dashboard/workflows/financing': NavigationOption.FINANCING,
  '/dashboard/workflows/quotes': NavigationOption.QUOTES,
  '/dashboard/team': NavigationOption.TEAM,
  '/dashboard/insurance': NavigationOption.INSURANCE,
  '/dashboard/reviews': NavigationOption.REVIEWS,
  '/dashboard/tools': NavigationOption.BRANDING,
  '/dashboard/insights': NavigationOption.INSIGHTS,
  '/dashboard/learn': NavigationOption.HELP,
  '/dashboard/help': NavigationOption.HELP,
  '/dashboard/settings': NavigationOption.SETTINGS,
  '/dashboard/banking': NavigationOption.BANKING,
  '/dashboard/lending': NavigationOption.LENDING,
};

const useContext = () => {
  const featureFlags = useReactiveVar(userFeatureFlags);
  const query = useGetDashboardNavDataQuery();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(activeTabMap[pathname]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changeAccountModalOpen, setChangeAccountModalOpen] = useState(false);
  const [affiliateModalOpen, setAffiliateModalOpen] = useState(false);
  const welcomeTourSeen = useMemo(() => (
    query?.data?.contractor?.customSettings.sendNewProductTourSeen202201
  ), [query]);
  const [skittlesWelcomeTourSeen, setSkittlesWelcomeTourSeen] = useState(welcomeTourSeen);
  const showNewProductTour = useMemo(() => (
    query?.data?.contractor?.customSettings.showNewProductTour202201
  ), [query]);
  const [showSkittlesTour, setShowSkittlesTour] = useState(showNewProductTour);

  useEffect(() => {
    if (welcomeTourSeen == null) return;
    setSkittlesWelcomeTourSeen(welcomeTourSeen);
  }, [welcomeTourSeen]);

  useEffect(() => {
    if (showNewProductTour == null) return;
    setShowSkittlesTour(showNewProductTour);
  }, [showNewProductTour]);

  return {
    query,
    contractor: query?.data?.contractor,
    organization: query?.data?.organization,
    featureFlags,
    uiStates: {
      activeTab,
      setActiveTab,
      mobileNavOpen,
      setMobileNavOpen,
      anchorEl,
      setAnchorEl,
      changeAccountModalOpen,
      setChangeAccountModalOpen,
      affiliateModalOpen,
      setAffiliateModalOpen,
      skittlesWelcomeTourSeen,
      setSkittlesWelcomeTourSeen,
      showSkittlesTour,
      setShowSkittlesTour,
    },
  };
};

export const [DashboardNavProvider, useDashboardNavigation] = constate(useContext);

type NavigationActions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAccountOpen: (event: any) => void;
  handleAccountClose: () => void;
  handleLogout: () => void;
  onNavItemClick: (url: string) => void;
  upsertContractorSettings: (customSettings: CustomContractorSettingsAttributes) => Promise<void>;
}

export const useDashboardNavActions = (): NavigationActions => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [UpsertContractorDashoardNavInfo] = useUpsertContractorDashoardInfoMutation();
  const upsertContractorSettings = useCallback(async (
    customSettings: CustomContractorSettingsAttributes,
  ): Promise<void> => {
    try {
      const response = await UpsertContractorDashoardNavInfo({
        variables: {
          customSettings,
        },
        awaitRefetchQueries: true,
      });

      parseGql<PayloadType<typeof response, 'upsertContractor'>>(
        'upsertContractor',
        response,
        'UpsertContractorSuccess',
        'UpsertContractorFailure',
      );
    } catch {
      // We don't need to do anything on error as we just want to update the setting,
      // not necessarily pop a snack that the user doesn't know how to fix.
    }
  }, [UpsertContractorDashoardNavInfo]);

  const {
    uiStates: {
      setAnchorEl,
      setMobileNavOpen,
      setActiveTab,
    },
  } = useDashboardNavigation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAccountOpen = (event: any) => setAnchorEl(event.currentTarget);

  const handleAccountClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    await dispatch(logoutDispatcher({}));
    // This ensures that existing graphql calls do not get called
    history.push('/login');
  };

  const onNavItemClick = (url: string) => {
    setActiveTab(activeTabMap[url]);
    setMobileNavOpen(false);
    history.push(url);
  };

  return {
    handleAccountOpen,
    handleAccountClose,
    handleLogout,
    onNavItemClick,
    upsertContractorSettings,
  };
};
