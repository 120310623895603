import { Box } from '@mui/material';

import { GetStartedContractorFragment, GetStartedOrganizationFragment } from 'types';

import { GetStartedProgressBar } from '.';
import {
  MobileAppTask,
  CustomizeBrandTask,
  InviteMembersTask,
  MarketingToolTask,
  CompleteTrainingTask,
} from './tasks';

type Props = {
  contractor: GetStartedContractorFragment;
  organization: GetStartedOrganizationFragment;
};

const GetStartedList = ({ contractor, organization }: Props): JSX.Element => (
  <Box sx={{ width: '90%' }}>
    <GetStartedProgressBar progress={0.3} /> {/* TODO get_started(task_completed_logic) */}
    <CompleteTrainingTask contractor={contractor} />
    <MobileAppTask contractor={contractor} />
    <CustomizeBrandTask organization={organization} />
    <InviteMembersTask organization={organization} />
    <MarketingToolTask contractor={contractor} />
  </Box>
);

export default GetStartedList;
