import { SvgIconProps } from '../Icon';

const LockIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill={bgColor} {...otherProps}>
    <rect width={18} height={18} rx={3} fill={bgColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.326 11.317c0 .271-.004.542 0 .813.006.373.306.67.671.67a.676.676 0 00.676-.671c.005-.537.003-1.075 0-1.613a.664.664 0 00-.234-.513.627.627 0 00-.717-.108.643.643 0 00-.395.61c-.006.27-.002.541-.001.812zm2.491-3.686c.002-.023.004-.04.004-.058V6.495c0-.26-.037-.513-.144-.748-.319-.702-.852-1.097-1.615-1.136a1.69 1.69 0 00-1.262.46c-.39.355-.6.801-.615 1.335-.011.395-.005.791-.006 1.187 0 .012.004.024.006.038h3.632zm-5.206.003v-.079c.003-.418-.004-.837.01-1.255a3.34 3.34 0 01.573-1.77C6.72 3.745 7.44 3.246 8.351 3.07c1.045-.204 1.99.045 2.811.74.662.561 1.052 1.287 1.186 2.154.029.184.036.372.039.559.006.344.001.69.001 1.034v.074c.025.002.046.004.067.004.174 0 .348.005.522-.001.22-.008.404.078.571.209.305.237.45.56.45.949.003 1.675.002 3.35.001 5.024 0 .526-.29.953-.773 1.123-.12.042-.255.059-.383.06-2.563.003-5.125.003-7.688 0-.488 0-.839-.236-1.053-.682A1.135 1.135 0 014 13.82c0-1.681-.002-3.362 0-5.043 0-.515.302-.945.774-1.109a.62.62 0 01.188-.032c.192-.004.384-.002.577-.002h.071z"
      fill={color}
    />
  </svg>
);

export default LockIcon;
