import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  Box,
  Grid,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CheckBox, DescriptionOutlined, ErrorOutline } from '@mui/icons-material/';

import {
  Button,
  Checkbox,
  Dialog,
  Link,
} from 'common/components/material-ui';
import {
  useGetContractServiceAccountIdQuery,
  useSetTermsAcceptanceMutation,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useLogEvent } from 'common/hooks';

type Props = {
  open: boolean,
  onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  outerBox: {
    borderRadius: 8,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
  },
  innerBox: {
    color: theme.palette.common.purple500,
    marginBottom: 24,
    [theme.breakpoints.up('md')]: {
      padding: '32px 40px 24px 40px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: 48,
    },
  },
  iconContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 24,
    },
  },
  checkbox: {
    color: theme.palette.common.purple500,
    fontSize: '27px', // 27px is the default size for an ordinary filled checkbox
  },
  agreeButton: {
    marginRight: '8px',
  },
  buttonBox: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  cancelButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
}));

export const GET_CONTRACT_SERVICE_ACCOUNT_ID = gql`
  query GetContractServiceAccountId {
    organization {
      id
      contractServiceAccount {
        id
      }
    }
  }
`;

export const SET_TERMS_ACCEPTANCE = gql`
  mutation SetTermsAcceptance ($contractServiceAccountId: String!, $termsAgreed: String!) {
    contractServiceSignTerms(contractServiceAccountId: $contractServiceAccountId, termsAgreed: $termsAgreed) {
      ... on ContractServiceSignTermsSuccess {
        contractServiceAccount {
          id
          termsAgreed
        }
      }
      ... on ContractServiceSignTermsFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

const TermsBlockModal = ({
  open,
  onClose,
}: Props): JSX.Element => {
  const classes = useStyles();
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);
  const [discardProgressCheckModalOpen, setDiscardProgressCheckModalOpen] = useState(false);
  const { data } = useGetContractServiceAccountIdQuery();
  const [setTermsAcceptance] = useSetTermsAcceptanceMutation();
  const { handleMutationError } = useGql();
  const itly = useLogEvent();

  const accountId = useMemo(() => {
    if (data?.organization?.contractServiceAccount?.id) {
      return data.organization.contractServiceAccount.id;
    }
    return null;
  }, [data]);

  const acceptTerms = useCallback(async () => {
    if (accountId) {
      try {
        const response = await setTermsAcceptance({
          variables: {
            contractServiceAccountId: accountId,
            termsAgreed: 'I agree to Hearth\'s Terms of Use',
          },
        });

        parseGql<PayloadType<typeof response, 'contractServiceSignTerms'>>(
          'contractServiceSignTerms',
          response,
          'ContractServiceSignTermsSuccess',
          'ContractServiceSignTermsFailure',
        );
        itly.finishContractsSetup();
        onClose();
      } catch (e) {
        handleMutationError(e, {});
      }
    }
  }, [itly, setTermsAcceptance, accountId, onClose, handleMutationError]);

  return (
    <Box>
      <Dialog
        className={classes.outerBox}
        fullScreen={false}
        open={open}
        onClose={() => setDiscardProgressCheckModalOpen(true)}
        overlayOpen={discardProgressCheckModalOpen}
        titleBgColor="purple500"
        bgColor="basic100"
        scroll="body"
        overlayTopPadding={1}
        classes={{}}
        overlayTitle={
          <Box display="flex">
            <Box color="common.basic100" mr={1}>
              <ErrorOutline fontSize="large" />
            </Box>
            <Typography variant="h3">
              <Box color="common.basic100">
                Discard?
              </Box>
            </Typography>
          </Box>
        }
        overlayChildren={
          <Box py={5} px={3}>
            <Typography>Are you sure you want to discard your progress?</Typography>
            <Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                onClick={() => setDiscardProgressCheckModalOpen(false)}
                variant="text"
                buttonType="common.purple"
                style={{ flex: 1 }}
              >
                Cancel
              </Button>
              <Box m={1} />
              <Button
                variant="contained"
                buttonType="common.purple"
                onClick={() => history.push('/dashboard')}
                style={{ flex: 2 }}
              >
                Yes, discard progress
              </Button>
            </Box>
          </Box>
        }
      >
        <Box className={classes.innerBox}>
          <Grid container direction="row" className={classes.header}>
            <Grid item xs={12} md={1} className={classes.iconContainer}>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Box mr={1.5} display="flex" alignItems="center" fontSize="20px">
                  <DescriptionOutlined fontSize="inherit" />
                </Box>
              </Box>
              <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="common.purple100"
                  width={40}
                  height={40}
                  borderRadius="20px"
                >
                  <DescriptionOutlined />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={11}>
              <Typography variant="h5">
                Your contract template is ready!
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2} mb={{ xs: 3, md: 5 }}>
            <Typography variant="body2">
              Agree to Hearth’s terms of use and you will be able to send contracts to your
              customers, add project information, and track their E-signature status.
            </Typography>
          </Box>
          <Grid container direction="row">
            <Checkbox
              checkedIcon={<CheckBox className={classes.checkbox} />}
              checked={accepted}
              onChange={() => setAccepted(!accepted)}
              label={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">
                    Agree to Hearth’s{' '}
                    <Link
                      color="inherit"
                      href="https://www.gethearth.com/hearth-contracts-contractor-terms-of-use"
                      target="_blank"
                      rel="noopener"
                    >
                      <strong>Terms of Use</strong>
                    </Link>
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Box mt={3} className={classes.buttonBox}>
            <Button
              variant="contained"
              buttonType="common.purple"
              className={classes.agreeButton}
              onClick={() => acceptTerms()}
              disabled={!accepted}
              fullWidth={isMobileOrSmall}
            >
              <Typography variant="subtitle2">
                I Agree
              </Typography>
            </Button>
            <Button
              variant="text"
              buttonType="common.purple"
              onClick={() => setDiscardProgressCheckModalOpen(!discardProgressCheckModalOpen)}
              fullWidth={isMobileOrSmall}
              className={classes.cancelButton}
            >
              <Typography variant="subtitle2">
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TermsBlockModal;
