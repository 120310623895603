import { useMemo, useCallback } from 'react';
import omit from 'lodash/omit';

import {
  useGetStartedCustomizedInvoiceTemplateInfoQuery,
  CustomizedInvoiceTemplateAttributes,
} from 'types';
import { Form } from 'common/components/formik';

import { useCustomizeBrandActions } from './useCustomizeBrandActions';
import CustomizeBrandModal from './components/CustomizeBrandModal';

type Props = {
  modalOpen: boolean;
  onClose: () => void;
}

const CustomizeBrandModalContainer = ({ modalOpen, onClose }: Props): JSX.Element | null => {
  const { data, refetch } = useGetStartedCustomizedInvoiceTemplateInfoQuery();

  const { activeTemplate, organization } = useMemo(() => ({
    activeTemplate: data?.contractor?.activeCustomizedInvoiceTemplate,
    organization: data?.organization,
  }), [data?.contractor, data?.organization]);

  const { generateCustomInvoiceTemplate, upsertOrganizationSettings } = useCustomizeBrandActions();

  const handleSubmit = useCallback(async (values: CustomizedInvoiceTemplateAttributes) => {
    try {
      await generateCustomInvoiceTemplate(omit(values, 'organization'), { activeTemplate });
      await upsertOrganizationSettings({ firstUploadedLogoAt: new Date().toISOString() });
    } finally {
      onClose();
    }
  }, [generateCustomInvoiceTemplate, onClose, activeTemplate, upsertOrganizationSettings]);

  if (!activeTemplate || !organization) return null;

  return (
    <Form<CustomizedInvoiceTemplateAttributes>
      initialValues={{
        templateType: activeTemplate.templateType,
        primaryColor: activeTemplate.primaryColor,
        background: activeTemplate.background,
        socialLinks: {
          facebookUrl: activeTemplate.socialLinks?.facebookUrl,
          instagramUrl: activeTemplate.socialLinks?.instagramUrl,
          linkedinUrl: activeTemplate.socialLinks?.linkedinUrl,
          twitterUrl: activeTemplate.socialLinks?.twitterUrl,
        },
        logo: activeTemplate.logo,
        website: activeTemplate.website,
        signatureMessage: activeTemplate.signatureMessage,
      }}
      onSubmit={handleSubmit}
    >
      <CustomizeBrandModal
        modalOpen={modalOpen}
        onClose={onClose}
        activeTemplate={activeTemplate}
        organization={organization}
        refetch={refetch}
      />
    </Form>
  );
};

export default CustomizeBrandModalContainer;
