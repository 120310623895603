import { useState, useCallback, FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, useTheme } from '@mui/material';

import { Button } from 'common/components/material-ui';

import LogoPreview from './LogoPreview';

type Props = {
  imageAlt: string;
  defaultImageSrc?: string | null;
  onSubmit: (imageSrc: string) => void;
  setLogo?: (value: boolean) => void;
  disableUpload?: boolean;
  emptyPrompt?: boolean;
  Placeholder: FunctionComponent;
  style?: Record<string, unknown>;
}

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  placeholder: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
  },
  logoContainer: {
    maxWidth: 240,
    margin: '0 auto',
  },
});

const LogoPlaceHolder = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.placeholder}>
      <Typography
        variant="h1"
        align="center"
        style={{ color: theme.palette.common.basic900 }}
      >
        YOUR LOGO HERE
      </Typography>
      <Box mb={2} />
      <Button
        variant="contained"
        buttonType="primary"
        style={{ pointerEvents: 'none' }}
      >
        Upload your logo
      </Button>
    </div>
  );
};

const LogoUpload = ({
  imageAlt,
  onSubmit,
  setLogo,
  style,
  defaultImageSrc = null,
  Placeholder = LogoPlaceHolder,
  emptyPrompt = false,
  disableUpload = false,
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [imageSrc, setImgSrc] = useState<string | undefined>(defaultImageSrc || undefined);
  const [uploadError, setUploadError] = useState<string | null>();

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    reader.onerror = () =>
      setUploadError('There was an error uploading your image. Please try again.');
    reader.onload = () => {
      const binaryStr = reader.result;
      if (typeof binaryStr === 'string') {
        setImgSrc(binaryStr);
        setUploadError(null);
        onSubmit(binaryStr);
      }
      if (setLogo != null) {
        setLogo(true);
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      {disableUpload ?
        <div className={classes.placeholder}>
          <Typography
            variant="h1"
            align="center"
            style={{ color: theme.palette.common.basic900 }}
          >
            NO LOGO
          </Typography>
        </div> :
        <div className={classes.logoContainer} {...getRootProps()}>
          <Box
            className={classes.logo}
            border={2}
            borderRadius="5px"
            borderColor={theme.palette.common.basic500}
            {...style}
          >
            <input {...getInputProps()} multiple={false} />
            {imageSrc != null ?
              <LogoPreview
                imageAlt={emptyPrompt ? '' : imageAlt}
                imageUrl={imageSrc}
              /> :
              <>
                {emptyPrompt ?
                  <Box width={100} height={100} /> :
                  <Placeholder />
                }
              </>
            }
            {uploadError == null &&
              <Typography variant="body2">
                <Box color="common.danger500">{uploadError}</Box>
              </Typography>
            }
          </Box>
        </div>
      }
    </>
  );
};

export default LogoUpload;
