export interface VerticalMetaType {
  verticalContent: VerticalContentType;
}

export interface VerticalContentType {
  hostnames: Hostnames;
  urls: Urls;
  login: Login;
  footer: Footer;
  navigation: Navigation;
  loanFlow: LoanFlow;
  offers: Offers;
  referAFriend: ReferAFriend;
  paymentOptionsPage: PaymentOptions;
  dashboardHome: DashboardHome;
  dashboardTeam: DashboardTeam;
  dashboardCompanyProfile: DashboardCompanyProfile;
  dashboardMarketingTools: DashboardMarketingTools;
}

export type Vertical = 'home_improvement';

export interface InitialVerticalType {
  hostnames: Record<Vertical, Hostnames>;
  urls: Record<Vertical, Urls>;
  login: Record<Vertical, Login>;
  footer: Record<Vertical, Footer>;
  navigation: Record<Vertical, Navigation>;
  loanFlow: Record<Vertical, LoanFlow>;
  offers: Record<Vertical, Offers>;
  referAFriend: Record<Vertical, ReferAFriend>;
  paymentOptionsPage: Record<Vertical, PaymentOptions>;
  dashboardHome: Record<Vertical, DashboardHome>;
  dashboardTeam: Record<Vertical, DashboardTeam>;
  dashboardCompanyProfile: Record<Vertical, DashboardCompanyProfile>;
  dashboardMarketingTools: Record<Vertical, DashboardMarketingTools>;
}

interface Hostnames {
  marketingHost: string;
  displayHost: string;
  signupHost: string;
}

/* related to 'urls' section */
interface Urls {
  trainingVideoUrl: string;
  popPageVideoUrl: string;
}

/* related to 'login' section */
interface Login {
  title: string;
}

/* related to 'footer' section */
interface Footer {
  showAppLinks: boolean;
  logos: string[];
}

/* related to 'navigation' section */

type DashboardPage = 'dashboard' | 'tools' | 'team' | 'learn' | 'settings' | 'track';

export function isDashboardPageKey(key: string): key is DashboardPage {
  return ['dashboard', 'tools', 'team', 'learn', 'settings', 'track'].includes(key);
}

interface Navigation {
  canBlockTraining: boolean;
  menuOptions: MenuOption[];
  menuOptionsBottom?: MenuOption[];
  redirects?: Record<DashboardPage, string>;
}

export interface MenuOption {
  title: string;
  path: string;
  className?: string;
  requiredFlags?: string[] | null;
  showFor?: string | null;
  subOptions?: SubOption[] | null;
  tooltipContent?: string;
  image?: {
    activeImage: string;
    inactiveImage: string;
  };
}

export interface SubOption {
  showFor?: (string)[] | null;
  requiredFlags?: (string)[] | null;
  title: string;
  tooltipContent?: string;
  path: string;
}

/* related to 'loanFlow' section */

interface LoanFlow {
  projectTypeOptions?: (ProjectTypeOptions)[] | null;
  showProjectIntent: boolean;
  logos: string[];
  projectType?: string;
  welcomeStep: {
    fields: string[];
  };
  loanAmountStep: {
    label: string;
    helpText: string;
  };
}

interface ProjectTypeOptions {
  value: string;
  label: string;
}

/* related to 'offers' section */

interface Offers {
  pastOffers: PastOffersMeta;
  offers: OffersMeta;
  noOffers: {
    showCCOption: boolean;
  };
}

interface PastOffersMeta {
  title: string;
  showProjectType: boolean;
}

interface OffersMeta {
  zeroPercent: {
    description: string;
  };
  showSocialProof: boolean;
}

/* related to 'referAFriend' section */
interface ReferAFriend {
  title: string;
  subtitle: string;
  emailSubject: string;
  show: boolean;
}

/* related to 'paymentOptionsPage' section */

interface PaymentOptions {
  defaultPopVariant: boolean;
  showHowItWorks: boolean;
  fundButtonCopy: string;
  paymentPlansModal: {
    showCCOption: boolean;
  };
  popInfo: {
    bannerCopy: string;
  };
}

/* related to 'dashboardHome' section */

export interface DashboardHome {
  showOnboardingChecklist: boolean;
  showTrainingCallBanner: boolean;
  showTrainingWebinarBanner: boolean;
  showTrainingReminderBanner: boolean;
  showDivider: boolean;
  support: {
    showSupport: boolean;
    email: string;
    phone: string;
    displayPhone: string;
  };
  monthlyCalculatorCard: {
    estimateDescription: string;
  };
  loanFlowCard: {
    title: string;
    subtitle: string;
    demoModeCta: string;
  };
  showQuickPoll: boolean;
  popPageCard: {
    title: string;
    subtitle: string;
  };
  paymentCalculatorCard: {
    title: string;
    subtitle: string;
    description: string;
  };
  trainingReminderBanner: {
    title: string;
    subtitle: string;
  };
  trainingBanner: TrainingBanner;
}

interface TrainingBanner {
  title: TitleOrSubtitle;
  subtitle: TitleOrSubtitle;
}

interface TitleOrSubtitle {
  Admin: string;
  Member: string;
}

/* related to 'dashboardTeam' section */

interface DashboardTeam {
  showTrainingStatus: boolean;
}

/* related to 'dashboardCompanyProfile' section */

interface DashboardCompanyProfile {
  showContractCard: boolean;
  showFlyerSettingsCard: boolean;
}

/* related to 'dashboardMarketingTools' section */

interface DashboardMarketingTools {
  financingBadge: string;
  showFlyer: boolean;
  showWebsiteBadge: boolean;
  showFacebookShare: boolean;
  websiteContent: WebsiteContent;
}

export interface WebsiteContent {
  banners: Banners;
  showFinancingContent: boolean;
}

interface Banners {
  bannerHelp: {
    descriptionHeader?: null | string | undefined;
  };
  bannerSelection: BannerSelectionType;
  colorOptions: string[];
  bannerPath: string;
}

export interface BannerSelectionType {
  imgAlt: string;
  imgSrc: string;
  bannerOptions: BannerOptions;
}

interface BannerOptions {
  mainContentBanner: BannerOptionsMeta;
  mainContentBannerGeneral?: BannerOptionsMeta;
  articleBanner: BannerOptionsMeta;
  articleBannerWider: BannerOptionsMeta;
  engagementBanner: BannerOptionsMeta;
}

export interface BannerOptionsMeta {
  size: string;
  description: string;
  width: string;
  height: string;
  extraOptions?: string;
}
