import {
  Box,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';

type Props = {
  description: string;
  children: React.ReactNode;
  mobileChildren?: React.ReactNode;
}

const MobileBlock = ({
  description,
  children,
  mobileChildren,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (!isMobile) {
    return <> {children} </>;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      pt="173px"
    >
      <Box mb={2}>
        <img
          height={50}
          src="https://hearth.imgix.net/contractor-v2/mobile_redirect/screen.svg"
          alt="laptop"
        />
      </Box>
      <Box
        px={6}
        color="common.primary500"
      >
        <Typography
          variant="h3"
          color="inherit"
          align="center"
        >
          Please view this page on a larger device in order to {description}
        </Typography>
      </Box>
      {mobileChildren &&
        <Box width="100%">
          {mobileChildren}
        </Box>
      }
    </Box>
  );
};

export default MobileBlock;
