import { SvgIconProps } from '../Icon';

const CheckmarkIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 49 48" fill="none" {...otherProps}>
    <path
      d="M41.75 18.75c.9 2.25 1.5 4.8 1.5 7.5 0 10.8-8.7 19.5-19.5 19.5s-19.5-8.7-19.5-19.5 8.7-19.5 19.5-19.5c3.75 0 7.35 1.05 10.35 3"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 20.25l7.5 7.5 22.5-22.5"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckmarkIcon;
