import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

type Props = {
  title: string;
  defaultTemplate: boolean;
}

const TemplateRow = ({ title, defaultTemplate }: Props): JSX.Element => (
  <Box display="flex" alignItems="center">
    <DescriptionOutlinedIcon fontSize="large" />
    <Box
      marginLeft={2.5}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      {defaultTemplate &&
        <Typography variant="body2">
          <Box color="common.basic900">
            Default template
          </Box>
        </Typography>
      }
      <Typography variant="h5">
        {title}
      </Typography>
    </Box>
  </Box>
);

export default TemplateRow;
