import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Col, Row, Smidge } from 'common/components/layouts';

import withLabel from './withLabel';
import Input from './Input';
import Select from './Select';

const months = moment.monthsShort()
  .map((month, index) => ({ value: index.toString(), label: month }));

const days = (month, year) => {
  const numDays = month && parseInt(year, 10) ?
    moment({ month, year }).daysInMonth() :
    31;

  // returns array of hashes of all days in month
  return Array(numDays).fill().map((_, index) => (
    { value: (index + 1).toString(), label: (index + 1).toString() }
  ));
};

const DatePicker = ({
  value,
  onChange,
}) => {
  const initialDate = value ? moment(value) : null;
  const [date, setDate] = useState({
    year: initialDate && initialDate.year(),
    month: initialDate && initialDate.month().toString(),
    day: initialDate && initialDate.date().toString(),
  });
  useEffect(() => {
    if (date.year && date.month && date.day) {
      onChange(moment(date).toDate());
    } else {
      onChange(null);
    }
  }, [date]);

  const handleChange = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;

    setDate(prevDate => ({ ...prevDate, [name]: newValue }));
  };

  return (
    <Row>
      <Col md={4}>
        <Select
          value={date.month}
          options={months}
          onChange={handleChange}
          placeholder="Month"
          name="month"
        />
        <Smidge x={2} className="visible-xs visible-sm" />
      </Col>
      <Col md={4}>
        <Select
          value={date.day}
          options={days(date.month, date.year)}
          onChange={handleChange}
          placeholder="Day"
          name="day"
        />
        <Smidge x={2} className="visible-xs visible-sm" />
      </Col>
      <Col md={4}>
        <Input
          value={date.year}
          onChange={handleChange}
          placeholder="Year"
          name="year"
        />
      </Col>
    </Row>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withLabel(DatePicker);
