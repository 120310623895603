import { useCallback, useState } from 'react';
import * as React from 'react';
import { Box, Typography } from '@mui/material';

import { getAffiliateEmailMarkup, sendEmail } from 'contractor/utils/emailHelpers';
import { Button, Input, Dialog } from 'common/components/material-ui';
import { CopyCodeBlock } from 'contractor/components/widgets';
import { useSnack } from 'common/utils/snackCart';

type Props = {
  contractorId: number;
  isOpen: boolean;
  onClosePopup: () => void;
  referralCoupon: string;
  referralLink: string;
  copyContent: {
    title: string;
    subtitle: string;
    emailSubject: string;
  };
};

const AffiliateModal = ({
  contractorId,
  onClosePopup,
  isOpen,
  referralCoupon,
  referralLink,
  copyContent,
}: Props): JSX.Element => {
  const [emails, setEmails] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState<{ email?: string }>({});
  const {
    title,
    subtitle,
    emailSubject,
  } = copyContent;

  const { successSnack, errorSnack } = useSnack(
    'Successfully sent email!',
    'An error occurred. Please try again.',
  );

  const onSubmit = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    if (!emails) {
      setErrors({
        email: 'Please insert a valid email.',
      });
      return;
    }

    const emailArgs = {
      tag: 'share',
      subject: emailSubject,
      email: emails,
      body: getAffiliateEmailMarkup(referralCoupon),
    };

    setLoading(true);
    sendEmail(emailArgs, contractorId).then(() => {
      setSent(true);
      setErrors({});
      successSnack();
    }, (error) => {
      errorSnack(
        (error.response && error.response.status === 422) ?
          error.response.data.errors.email :
          'Unknown error, please try again.',
      );
    }).finally(() => setLoading(false));
  }, [contractorId, emailSubject, emails, errorSnack, referralCoupon, successSnack]);

  return (
    <Dialog
      className="affiliate-modal"
      open={isOpen}
      onClose={onClosePopup}
    >
      <Box p={3}>
        <Typography variant="h2">{title}</Typography>
        <Box m={2} />
        <Typography variant="body1">{subtitle}</Typography>
        <Box m={2} />
        <CopyCodeBlock
          code={referralLink}
          description="Copy your affiliate link."
          snackText="Copied link!"
          center
        />
        <Box m={2} />
        <hr />
        <Typography variant="h5">
          Email a referral link
        </Typography>
        <Box m={1} />
        {sent ?
          <>
            <Typography variant="caption">
              We’ve sent them an email with your referral link.
            </Typography>
            <Box m={3} />
            <Button
              variant="contained"
              buttonType="primary"
              onClick={() => {
                setSent(false);
                setEmails('');
              }}
            >
              Send to more people
            </Button>
          </> :
          <>
            <Input
              label="Emails, separated by commas"
              fullWidth
              value={emails}
              error={!!errors.email}
              helperText={errors.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmails(event.target.value)}
            />
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
            >
              <Button
                variant="contained"
                buttonType="primary"
                onClick={onSubmit}
                loading={loading}
              >
                Send referral link
              </Button>
            </Box>
          </>
        }
      </Box>
    </Dialog>
  );
};

export default AffiliateModal;
