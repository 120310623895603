import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Tooltip from './Tooltip';

const Container = styled('div')(({ theme }) => ({
  width: 40,
  height: 25,
  border: `1px solid ${theme.palette.common.basic500}`,
  borderRadius: 2,
  backgroundColor: theme.palette.common.basic100,
  color: theme.palette.common.primary900,
  textAlign: 'center',
  fontWeight: 500,
}));

const QuestionTooltip = props => (
  <Tooltip {...props}>
    <Container>?</Container>
  </Tooltip>
);

QuestionTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default QuestionTooltip;
