import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import sortBy from 'lodash/sortBy';
import { sentenceCase, snakeCase } from 'change-case';
import { isPresent } from 'ts-is-present';

import {
  useGetHearthPaySettingsQuery,
  useUpdateAcceptedPaymentsMutation,
  useUpdateRemindersMutation,
  useUpdateQuickbooksSyncSettingsMutation,
  useExportPaymentDataMutation,
  ExportPaymentData,
  ReminderFragment,
} from 'types';
import { useSnack } from 'common/utils/snackCart';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useLogEvent } from 'common/hooks';

export const GET_PAYMENT_SETTINGS = gql`
  fragment Reminder on ReminderSetting {
    id
    name
    settingName
    enabled
    medium
  }

  fragment StripeCardSettings on StripeCard {
    id
    brand
    last4
    defaultForCurrency
  }

  fragment StripeBankAccountSettings on StripeBankAccount {
    id
    bankName
    last4
    defaultForCurrency
  }

  query GetHearthPaySettings {
    organization {
      id
      quickbooksBusiness {
        id
        autoSyncInvoices
      }
      stripeConnectAccount {
        id
        achEnabled
        creditCardEnabled
        debitCardEnabled
        achFeeFixedAmount
        cardFeeFixedAmount
        cardFeePercentageAmount
        customerPaysAchFee
        customerPaysCardFee
        allowPassingCreditCardFeesToCustomer
        externalTransferDestinations {
          standardDestinations {
            ... on StripeCard {
              id
              ...StripeCardSettings
            }
            ... on StripeBankAccount {
              id
              ...StripeBankAccountSettings
            }
          }
        }
      }
    }
    contractor {
      id
      email
      role
      paymentReminders
      paymentsRole
      firstName
      contractorReminderSettings {
        id
        ...Reminder
      }
      homeownerReminderSettings {
        id
        ...Reminder
      }
    }
    connectInvoices(status: [created, scheduled, scheduled_after_fund]) {
      nodes {
        id
        fullName
        amountRequested
        dueDate
      }
    }
  }
`;

export const UPDATE_ACCEPTED_PAYMENTS = gql`
  mutation UpdateAcceptedPayments(
    $attributes: AcceptedPaymentsAttributes!
  ) {
    updateAcceptedPayments(attributes: $attributes) {
      ... on UpdateAcceptedPaymentsSuccess {
        stripeConnectAccount {
          id
          achEnabled
          creditCardEnabled
          debitCardEnabled
          customerPaysAchFee
          customerPaysCardFee
        }
      }
      ... on UpdateAcceptedPaymentsFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const UPDATE_REMINDER_SETTING = gql`
  mutation UpdateReminders($enable: Boolean!, $id: ID!) {
    updateReminderSetting(reminderSettingId: $id, enable: $enable) {
      ... on UpdateReminderSettingSuccess {
        reminderSetting {
          id
          enabled
          name
          settingName
        }
      }
      ... on UpdateReminderSettingFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const UPDATE_QUICKBOOKS_SYNC_SETTINGS = gql`
  mutation UpdateQuickbooksSyncSettings(
    $quickbooksBusinessId: ID!
    $settings: QuickbooksBusinessSettings!
  ) {
    updateQuickbooksSyncSettings(quickbooksBusinessId: $quickbooksBusinessId, settings: $settings) {
      ... on UpdateQuickbooksSyncSettingsSuccess {
        quickbooksBusiness {
          id
          autoSyncInvoices
        }
      }
      ... on UpdateQuickbooksSyncSettingsFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const EXPORT_PAYMENT_DATA = gql`
  mutation ExportPaymentData ($type: ExportPaymentData!) {
    exportPaymentData(type: $type) {
        ... on ExportPaymentDataSuccess {
        contractor {
          id
        }
      }
      ... on ExportPaymentDataFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

const useContext = () => {
  const { data } = useGetHearthPaySettingsQuery();

  const stripeConnectAccount = useMemo(
    () => data?.organization?.stripeConnectAccount || null,
    [data],
  );

  const quickbooksBusiness = useMemo(
    () => data?.organization?.quickbooksBusiness || null,
    [data],
  );

  const contractor = useMemo(() => data?.contractor || null, [data]);

  const contractorReminders = useMemo(
    () => sortBy(data?.contractor?.contractorReminderSettings, ['name']) || [],
    [data],
  );

  const homeownerReminders = useMemo(
    () => sortBy(data?.contractor?.homeownerReminderSettings, ['name', 'medium']) || [],
    [data],
  );

  const outstandingConnectInvoices = useMemo(
    () => data?.connectInvoices?.nodes?.filter(isPresent),
    [data],
  );

  return {
    stripeConnectAccount,
    contractorReminders,
    homeownerReminders,
    contractor,
    quickbooksBusiness,
    outstandingConnectInvoices,
  };
};

export const [HearthPaySettingsProvider, useHearthPaySettings] = constate(useContext);

export type PaymentAttributeType = 'achEnabled' | 'creditCardEnabled' | 'debitCardEnabled' | 'customerPaysCardFee' | 'customerPaysAchFee';

type SettingsActions = {
  toggleReminder: (reminder: ReminderFragment) => void;
  toggleAcceptedPayments: (attribute: PaymentAttributeType) => void;
  toggleQuickbooksSync: () => void;
  exportPaymentsData: (type: ExportPaymentData) => void;
}

export const useHearthPaySettingsActions = (): SettingsActions => {
  const {
    stripeConnectAccount,
    contractor,
    quickbooksBusiness,
  } = useHearthPaySettings();
  const snack = useSnack();
  const itly = useLogEvent();
  const { handleMutationError } = useGql();

  const [updateReminder] = useUpdateRemindersMutation();
  const [updateQuickbooksSyncSettings] = useUpdateQuickbooksSyncSettingsMutation();
  const [toggleAcceptedPaymentsMutation] = useUpdateAcceptedPaymentsMutation();
  const [exportPaymentData] = useExportPaymentDataMutation();

  /**
   * Action: toggleAcceptedPayments
   * Toggle ACH and Card options
   */

  const toggleAcceptedPayments = useCallback(async (attribute: PaymentAttributeType) => {
    try {
      if (!stripeConnectAccount) return;

      const response = await toggleAcceptedPaymentsMutation({
        variables: {
          attributes: {
            [attribute]: !stripeConnectAccount[attribute],
          },
        },
        refetchQueries: ['GetHearthPaySettings'],
      });

      parseGql<PayloadType<typeof response, 'updateAcceptedPayments'>>(
        'updateAcceptedPayments',
        response,
        'UpdateAcceptedPaymentsSuccess',
        'UpdateAcceptedPaymentsFailure',
      );

      snack.successSnack('Settings updated');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [stripeConnectAccount, toggleAcceptedPaymentsMutation, snack, handleMutationError]);

  /**
   * Action: onReminderToggle
   * Toggle reminder setting
   */

  const toggleReminder = useCallback(
    async (reminder: ReminderFragment) => {
      const {
        enabled,
        id,
        settingName,
        medium,
      } = reminder;
      try {
        const response = await updateReminder({
          variables: {
            enable: !enabled,
            id,
          },
        });

        parseGql<PayloadType<typeof response, 'updateReminderSetting'>>(
          'updateReminderSetting',
          response,
          'UpdateReminderSettingSuccess',
          'UpdateReminderSettingFailure',
        );

        snack.successSnack('Settings updated');

        const changedSetting = medium ? `${snakeCase(settingName)}_${snakeCase(medium)}` : snakeCase(settingName);
        itly.changeSetting({ fields: [changedSetting] });
      } catch (e) {
        handleMutationError(e, {});
      }
    },
    [updateReminder, snack, handleMutationError],
  );

  /**
   * Action: toggleQuickbooksSync
   * Toggle the quickbooks sync setting
   */

  const toggleQuickbooksSync = useCallback(async () => {
    if (!quickbooksBusiness?.id) {
      return;
    }

    try {
      const response = await updateQuickbooksSyncSettings({
        variables: {
          quickbooksBusinessId: quickbooksBusiness.id,
          settings: {
            autoSyncInvoices: !quickbooksBusiness.autoSyncInvoices,
          },
        },
      });

      parseGql<PayloadType<typeof response, 'updateQuickbooksSyncSettings'>>(
        'updateQuickbooksSyncSettings',
        response,
        'UpdateQuickbooksSyncSettingsSuccess',
        'UpdateQuickbooksSyncSettingsFailure',
      );
      snack.successSnack('QuickBooks settings updated');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    quickbooksBusiness,
    updateQuickbooksSyncSettings,
    snack,
    handleMutationError,
  ]);

  /**
   * Action: exportPaymentData
   * Export payment or transfer data to contractor via email
   */

  const exportPaymentsData = useCallback(async (type: ExportPaymentData) => {
    if (!contractor) return;

    try {
      const response = await exportPaymentData({
        variables: {
          type,
        },
      });

      parseGql<PayloadType<typeof response, 'exportPaymentData'>>(
        'exportPaymentData',
        response,
        'ExportPaymentDataSuccess',
        'ExportPaymentDataFailure',
      );

      snack.successSnack(`${sentenceCase(type)} history emailed to ${contractor.email}`);
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [contractor, exportPaymentData, handleMutationError, snack]);

  return {
    toggleReminder,
    toggleAcceptedPayments,
    toggleQuickbooksSync,
    exportPaymentsData,
  };
};
