import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FeedbackBox } from './components';
import useReporting from './useReporting';

const FinancingFeedback = (): JSX.Element => {
  const history = useHistory();
  const {
    homeownerFinancingFeedbackMetrics,
    homeownerFinancingFeedback,
    financingFeedbackUrl,
  } = useReporting();

  const onRowClick = useCallback((homeownerId: string) => {
    history.push(
      `${financingFeedbackUrl}/${homeownerId}`, {
        background: {
          pathname: `${financingFeedbackUrl}`,
        },
      },
    );
  }, [financingFeedbackUrl, history]);

  return (
    <FeedbackBox
      title="Financing Customer Feedback"
      tooltipText="We sent a survey to your customers who were funded
        by one of Hearth's lending partners, asking them to rate
        their experience. We've aggregated that feedback in this dashboard."
      contractorRating={homeownerFinancingFeedbackMetrics?.avgContractorSatisfaction}
      contractorRatingDifference={
        homeownerFinancingFeedbackMetrics?.avgContractorSatisfactionDifference
      }
      hearthRating={homeownerFinancingFeedbackMetrics?.avgHearthSatisfaction}
      hearthRatingDifference={homeownerFinancingFeedbackMetrics?.avgHearthSatisfactionDifference}
      data={homeownerFinancingFeedback}
      onRowClick={onRowClick}
      feedbackType="financing"
    />
  );
};

export default FinancingFeedback;
