import { styled } from '@mui/material/styles';
import MuiMenuList, { MenuListProps as MuiMenuListProps } from '@mui/material/MenuList';

import { IconName } from 'common/components/Icon';

import MenuItem from './QuickActionsMenuItem';

export type QuickActionItem = {
  title: string;
  route: string;
  locked: boolean;
  actionable?: boolean;
  lockedCopy?: string;
  icon: IconName;
  featureStatus?: string;
  action: () => void;
}

interface MenuListProps extends MuiMenuListProps {
  quickActions: QuickActionItem[];
}

const MenuListBase = styled(MuiMenuList, {
  name: 'StyledQuickActionsMenuBase',
})({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'common.basic100',
  padding: '16px 24px',
  '& .MuiPaper-root': {
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  '& > .MuiMenuItem-root': {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const QuickActionsMenuList = ({ quickActions, sx, ...otherProps }: MenuListProps): JSX.Element => (
  <MenuListBase {...otherProps}>
    {quickActions.map((item) => {
      const menuItem = {
        title: item.title,
        icon: item.icon,
        locked: item.locked,
        actionable: item.actionable ?? true,
        statusIndicatorCopy: item.lockedCopy,
        action: item.action,
        featureStatus: item.featureStatus,
      };

      return (
        <MenuItem
          key={menuItem.title}
          item={menuItem}
          featureStatus={menuItem.featureStatus}
        />
      );
    })}
  </MenuListBase>
);

export default QuickActionsMenuList;
