import { Box, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowDropUp,
  ArrowDropDown,
  FavoriteBorder,
} from '@mui/icons-material';
import { isPresent } from 'ts-is-present';

import { percentFormat } from 'common/utils/stringFormatters';

const getColors = (rating: number, difference: number): { color: string; background: string } => {
  let color = 'common.primary500';
  let background = 'common.primary100';
  if (difference < 0) {
    color = 'common.warning500';
    background = 'common.warning100';
  }
  if (rating === 5) {
    color = 'common.success500';
    background = 'common.success100';
  }

  return {
    color,
    background,
  };
};

type RatingsComparisonProps = {
  type: 'Contractor' | 'Hearth';
  rating?: number | null;
  ratingDifference?: number | null;
}

const RatingsComparison = ({ type, rating, ratingDifference }: RatingsComparisonProps) => {
  if (!isPresent(rating) || !isPresent(ratingDifference)) {
    return null;
  }

  const { color, background } = getColors(rating, ratingDifference);

  let copy = `${percentFormat(ratingDifference, 0)} above Hearth’s average`;
  let IconComponent = ArrowDropUp;
  let iconSize: 'large' | 'small' = 'large';
  if (ratingDifference < 0) {
    copy = `${percentFormat(ratingDifference, 0)} below Hearth’s average`;
    IconComponent = ArrowDropDown;
  }
  if (rating === 5) {
    copy = `Your customers love ${type === 'Hearth' ? 'Hearth' : 'you'}!`;
    IconComponent = FavoriteBorder;
    iconSize = 'small';
  }

  return (
    <Box
      bgcolor={background}
      color={color}
      borderRadius="4px"
      p={1}
      mt={1}
      display="flex"
      flexDirection="row"
      alignItems="center"
      maxWidth={170}
    >
      <IconComponent fontSize={iconSize} style={{ marginRight: 12 }} />
      <Typography variant="caption">
        {copy}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  ratingBox: {
    flex: 1,
    border: `1px solid ${theme.palette.common.basic500}`,
    borderRadius: 8,
    padding: '16px 24px',
  },
}));

type Props = {
  type: 'Contractor' | 'Hearth';
  rating: number;
  ratingDifference: number;
}

const RatingBox = ({ type, rating, ratingDifference }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.ratingBox}>
      <Typography variant="subtitle1">
        {type === 'Contractor' ?
          'Average Contractor Rating' :
          'Average Hearth Rating'
        }
      </Typography>
      <Box mt={1} />
      <Typography variant="caption">
        <Box color="common.basic700" height={32}>
          {type === 'Contractor' ?
            'Your customers’ rating of your business' :
            'Your customers’ rating of the Hearth experience'
          }
        </Box>
      </Typography>
      <Box mt={1} display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography align="center" variant="h2">
            {rating}
          </Typography>
          <Typography align="center" variant="caption">out of 5</Typography>
        </Box>
        <RatingsComparison
          type={type}
          rating={rating}
          ratingDifference={ratingDifference}
        />
      </Box>
    </div>
  );
};

export default RatingBox;
