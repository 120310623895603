import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

type Values = Record<string, unknown>;
type Errors = Record<string, string | unknown> | undefined;

const collectErrorMessages = (errors: Errors): string[] => (
  errors ?
    Object.values(errors).reduce<string[]>((accum, error) => [
      ...accum,
      ...(
        typeof error === 'string' ?
          [error] :
          collectErrorMessages(error as Errors)
      ),
    ], []) :
    []
);

const FormErrors = (): JSX.Element | null => {
  const { errors, submitCount } = useFormikContext<Values>();

  const errorMessages = collectErrorMessages(errors);

  if (errorMessages.length === 0 || submitCount === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid',
        borderRadius: 4,
        borderColor: 'common.danger500',
        bgcolor: 'common.danger100',
        my: 3,
      }}
    >
      {errorMessages.map(error => (
        <Typography key={error} variant="body1" sx={{ color: 'common.danger500' }}>
          {error}
        </Typography>
      ))}
    </Box>
  );
};

export default FormErrors;
