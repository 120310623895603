import * as React from 'react';
import { useLockBodyScroll } from 'react-use';

type Props = {
  active: boolean;
  children: React.ReactNode;
};

const PreventClicksAndScroll = ({ active, children }: Props): JSX.Element => {
  useLockBodyScroll(active);

  return (
    <div style={active ? { pointerEvents: 'none' } : {}}>
      {children}
    </div>
  );
};

export default PreventClicksAndScroll;
