import { useCallback } from 'react';
import { ButtonBase as MaterialButtonBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBaseProps as MaterialButtonBaseProps } from '@mui/material/ButtonBase';
import { LinkProps } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    '&:focus, &:hover, &:active': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
});

export type ButtonBaseProps = {
  children: React.ReactNode;
  component?: React.ReactNode;
  to?: LinkProps['to'];
  href?: LinkProps['href'];
  target?: string;
  download?: string;
} & MaterialButtonBaseProps;

const ButtonBase = ({
  onClick,
  ...otherProps
}: ButtonBaseProps): JSX.Element => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  const classes = useStyles();

  return (
    <MaterialButtonBase
      onClick={handleClick}
      classes={{ root: classes.root }}
      {...otherProps}
    />
  );
};

export default ButtonBase;
