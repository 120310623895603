import {
  Box,
  Divider,
  useTheme,
  BoxProps,
} from '@mui/material';
import {
  createHeadingPlugin,
  createBlockquotePlugin,
  createParagraphPlugin,
  createHistoryPlugin,
  createReactPlugin,
  createBasicMarkPlugins,
  createListPlugin,
  DEFAULTS_H1,
  DEFAULTS_H2,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_LIC,
  DEFAULTS_BOLD,
  DEFAULTS_ITALIC,
  DEFAULTS_UNDERLINE,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  DEFAULTS_PARAGRAPH,
  ELEMENT_PARAGRAPH,
  DEFAULTS_BLOCKQUOTE,
  ELEMENT_BLOCKQUOTE,
} from '@udecode/plate';
import {
  Plate,
  TNode,
  PlatePluginOptions,
  PlatePluginComponent,
  usePlateEditorState,
} from '@udecode/plate-core';

import Leaf from './Leaf';
import Element from './Element';
import Toolbar, { ToolbarProps } from './Toolbar';

const components = {
  [ELEMENT_H1]: Element as PlatePluginComponent,
  [ELEMENT_H2]: Element as PlatePluginComponent,
  [ELEMENT_OL]: Element as PlatePluginComponent,
  [ELEMENT_UL]: Element as PlatePluginComponent,
  [ELEMENT_LIC]: Element as PlatePluginComponent,
  [ELEMENT_BLOCKQUOTE]: Element as PlatePluginComponent,
  [ELEMENT_PARAGRAPH]: Element as PlatePluginComponent,
  [MARK_BOLD]: Leaf as PlatePluginComponent,
  [MARK_ITALIC]: Leaf as PlatePluginComponent,
  [MARK_UNDERLINE]: Leaf as PlatePluginComponent,
};

const plugins = [
  // editor
  createReactPlugin(), // withReact
  createHistoryPlugin(), // withHistory

  // elements
  createParagraphPlugin(), // paragraph element
  createBlockquotePlugin(), // blockquote element
  createHeadingPlugin(), // heading elements

  // marks: bold, italic, underline, strikethrough
  ...createBasicMarkPlugins(),

  createListPlugin(),
];

const options = {
  [ELEMENT_BLOCKQUOTE]: DEFAULTS_BLOCKQUOTE,
  [ELEMENT_H1]: DEFAULTS_H1,
  [ELEMENT_H2]: DEFAULTS_H2,
  [ELEMENT_PARAGRAPH]: DEFAULTS_PARAGRAPH,
  [MARK_BOLD]: DEFAULTS_BOLD,
  [MARK_ITALIC]: DEFAULTS_ITALIC,
  [MARK_UNDERLINE]: DEFAULTS_UNDERLINE,
};

type Props = {
  // this has to be unique if rendering multiple editors
  id?: string;
  value?: TNode[];
  onChange?: (node: TNode[]) => void;
  toolbarProps?: Partial<ToolbarProps>;
  containerProps?: BoxProps;
}

// Adapted from https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx
// With styling modifications + type fixes
const SlateEditor = ({
  id,
  value,
  onChange = () => {},
  toolbarProps = {},
  containerProps = {},
}: Props): JSX.Element => {
  const theme = useTheme();
  const body1 = theme.typography.body1;
  const editor = usePlateEditorState();

  return (
    <Box
      bgcolor="common.basic100"
      borderColor="common.basic300"
      border={1}
      p={2}
      fontSize={body1.fontSize}
      lineHeight={body1.lineHeight}
      fontWeight={body1.fontWeight}
      minHeight={500}
      display="flex"
      flexDirection="column"
      {...containerProps}
    >
      <Plate
        id={id}
        plugins={plugins}
        value={value}
        onChange={onChange}
        editableProps={{
          spellCheck: false,
          autoFocus: true,
          style: { flexGrow: 1, overflowY: 'auto' },
        }}
        components={components}
        options={options as unknown as PlatePluginOptions}
      >
        <Box pb={2}>
          <Toolbar editor={editor} {...toolbarProps} />
        </Box>
        <Box mb={2}>
          <Divider />
        </Box>
      </Plate>
    </Box>
  );
};

export default SlateEditor;
