import {
  Box,
  CardMedia,
  Typography,
  Theme,
  Container,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Link } from 'common/components/material-ui';
import { useVerticalState } from 'common/hooks';

type Props = {
  children: NonNullable<React.ReactNode>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.grey.A100,
    height: '100vh',
    '@media (max-height: 567px)': {
      height: '100%',
    },
  },
}));

const LoginContainer = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const [{ brandMetadata, verticalMetadata }] = useVerticalState();
  const { support: { phone, displayPhone } } = verticalMetadata.dashboardHome;

  return (
    <div className={classes.root}>
      <Box width={160} mt="100px" mb="60px">
        <CardMedia
          component="img"
          image={brandMetadata.logoSrc}
          alt={brandMetadata.label}
        />
      </Box>
      <Container maxWidth="sm">
        {children}
      </Container>
      <Typography variant="body2">
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'unset' }} alignItems="center" my="20px">
          <Box whiteSpace="pre">Having trouble logging in?{' '}</Box>
          <Box>Call us:{' '}
            <Link
              variant="subtitle2"
              color="primary"
              style={{ lineHeight: 'inherit' }}
              href={`tel:${phone}`}
            >
              {displayPhone}
            </Link>
          </Box>
        </Box>
      </Typography>
    </div>
  );
};

export default LoginContainer;
