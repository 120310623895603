import { useState } from 'react';
import { useMount } from 'react-use';
import {
  Box,
  Dialog,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useField, useFormikContext } from 'formik';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import useLogEvent from 'common/hooks/useLogEvent';
import { Button, Note } from 'common/components/material-ui';
import { InputField } from 'common/components/formik';
import { EmailLeadsFormValues } from 'contractor/modules/Tools/MarketingTools';

import { EmailLeadsPreviewModal } from '.';

type Props = {
  onClose: () => void,
  isOpen: boolean,
  contractorId: string,
};

enum EmailLeadsModalView {
  LEAD_FORM,
  PREVIEW,
}

const FileUpload = () => {
  const [fileField] = useField<string>('file');

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5000000,
    accept: ['text/csv'],
    onDrop: (acceptedFiles: File[]) => {
      const reader = new FileReader();

      reader.onload = async () => {
        const binaryStr = reader.result;
        if (typeof binaryStr === 'string') {
          fileField.onChange({
            target: {
              name: 'file',
              value: binaryStr,
            },
          });

          // TODO(EmailLeadsModal): nice add would be to say "attached your-file.csv" upon upload
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    multiple: false,
  });

  // needed for drag & drop
  const rootProps = getRootProps();
  const { css, ...boxProps } = rootProps;

  return (
    <Box display="flex" flexDirection="column" color="common.basic900" height={100}>
      <Box
        bgcolor="common.basic700"
        width={520}
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        mt={0.5}
        style={{ cursor: 'pointer' }}
        {...boxProps}
      >
        <input {...getInputProps()} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          position="relative"
        >
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            bgcolor="rgba(255, 255, 255, 0.75)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            color="common.basic1100"
            width="100%"
          >
            <Box color="common.primary500">
              <SystemUpdateAltIcon fontSize="large" color="inherit" />
            </Box>
            <Divider />
            <Box color="common.primary500">
              <Typography variant="subtitle2" color="inherit">Click to upload a CSV</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const EmailLeadsModal = ({
  onClose,
  isOpen,
  contractorId,
}: Props): JSX.Element => {
  const itly = useLogEvent();
  const [emailLeadsModalView, setEmailLeadsModalView] = useState<EmailLeadsModalView>(
    EmailLeadsModalView.LEAD_FORM,
  );

  useMount(() => itly.viewEmailLeadsSetup());

  const { values, resetForm } = useFormikContext<EmailLeadsFormValues>();

  if (emailLeadsModalView === EmailLeadsModalView.LEAD_FORM) {
    return (
      <Dialog
        scroll="body"
        onClose={() => {
          resetForm();
          onClose();
        }}
        fullWidth
        maxWidth="sm"
        open={isOpen}
      >
        <Note
          title="Email leads"
          subtitle="Add my leads"
        >
          <Typography variant="body2">
            Upload a CSV file with one email per line. Do not include a header.
          </Typography>
          <Box display="flex" justifyContent="center" mt={2} mb={3} flexDirection="column">
            <FileUpload />
            <Box my={2}>
              <Divider />
            </Box>
            <Box mb={2}>
              <Typography variant="body2">
                Or enter email addresses manually (one per line).
              </Typography>
            </Box>
            <InputField
              name="emails"
              label=""
              rows={6}
              multiline
              inputProps={{ maxLength: 512 }}
            />
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setEmailLeadsModalView(EmailLeadsModalView.PREVIEW)}
                disabled={!values.emails && !values.file}
              >
                Save and Continue
              </Button>
              <Box mt={1} />
            </Grid>
          </Grid>
        </Note>
      </Dialog>
    );
  }

  return (
    <EmailLeadsPreviewModal
      isOpen={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      contractorId={contractorId}
      onBack={() => setEmailLeadsModalView(EmailLeadsModalView.LEAD_FORM)}
    />
  );
};

export default EmailLeadsModal;
