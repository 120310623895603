import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/number';

import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';

import { ReactotronType } from 'common/utils/reactotronConfig';
import { CalendlyObject } from 'contractor/utils/contractorDashboard';

import Base from './Base';
import * as serviceWorker from './serviceWorker';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

declare global {
  interface Console {
    tron: ReactotronType;
  }
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer?: any;
    Calendly: {
      initPopupWidget: (obj: CalendlyObject) => void;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _wq: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _wcq: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Wistia: any;
  }
}

ReactDOM.render(<Base />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
