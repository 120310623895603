import { Box, Typography } from '@mui/material';

import { Button } from 'common/components/material-ui';
import Icon from 'common/components/Icon';

import { useSortingOffers } from '../useSortingOffers';

const NoMatchingOffers = (): JSX.Element => {
  const {
    inquiryState: { homeowner },
    actions: {
      filterLoanAmounts,
      setRateCap,
    },
  } = useSortingOffers();
  return (
    <Box
      bgcolor="common.primary100"
      border={1}
      borderColor="common.primary500"
      borderRadius="8px"
      p={3}
      mt={5}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexGrow={1}>
        <Box display="flex" flexDirection="row">
          <Icon
            name="warning-outline"
            color="primary500"
            size={20}
            style={{ marginRight: 8 }}
          />
          <Box>
            <Typography variant="h5">
              <Box color="common.primary500">
                Sorry {homeowner?.firstName}, there are no loan options within the specified
                filters.
              </Box>
            </Typography>
            <Box mt={1} />
            <Typography variant="body1">
              Please adjust the above filters to see additional options or click
              to the right to show all option.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        buttonType="common.primary"
        onClick={() => {
          setRateCap(null);
          filterLoanAmounts(new Set());
        }}
        size="medium"
        style={{ minWidth: 200, marginLeft: 16 }}
      >
        Show All Offers
      </Button>
    </Box>
  );
};

export default NoMatchingOffers;
