import { Box, Typography } from '@mui/material';
import { useField } from 'formik';

import { SignatureMessage } from 'types';
import { Checkbox } from 'common/components/material-ui';

const SignatureModule = (): JSX.Element => {
  const [{ value }, , { setValue }] = useField<SignatureMessage | null>('signatureMessage');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

  const handleChange = (name: SignatureMessage) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked ? name : null);
  };

  return (
    <Box>
      <Typography variant="subtitle1">
        <Box fontWeight="fontWeightBold">
          Signature Message
        </Box>
      </Typography>
      <Box m={2} />
      <Checkbox
        label={
          <img
            src="https://hearth.imgix.net/contractor-v2/custom_invoice/thank-you-script.svg"
            alt="Thank you script"
            height="30"
            style={{ marginLeft: 16 }}
          />
        }
        checked={value === SignatureMessage.SCRIPT}
        onChange={handleChange(SignatureMessage.SCRIPT)}
      />
      <Checkbox
        label={
          <img
            src="https://hearth.imgix.net/contractor-v2/custom_invoice/thank-you-serif.svg"
            alt="Thank you serif"
            height="24"
            style={{ marginLeft: 16 }}
          />
        }
        checked={value === SignatureMessage.SERIF}
        onChange={handleChange(SignatureMessage.SERIF)}
      />
    </Box>
  );
};

export default SignatureModule;
