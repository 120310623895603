import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useField } from 'formik';

import { InvoiceTemplateType } from 'types';

enum ArrowDirection {
  RIGHT,
  LEFT
}

type StyleProps = {
  arrowDirection: ArrowDirection;
}

const useStyles = makeStyles(theme => ({
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 40,
    marginRight: ({ arrowDirection }: StyleProps) =>
      (arrowDirection === ArrowDirection.RIGHT ? -16 : 0),
    color: theme.palette.common.basic900,
    background: 'url(https://hearth.imgix.net/contractor-v2/custom_invoice/fade.png)',
    cursor: 'pointer',
  },
  scrollContainer: {
    overflowX: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    '& > *': {
      marginRight: 8,
    },
    marginRight: ({ arrowDirection }: StyleProps) =>
      (arrowDirection === ArrowDirection.LEFT ? -24 : 0),
  },
  templateWrapper: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    width: 180,
    height: 200,
    borderRadius: 8,
    position: 'relative',
    padding: 8,
    color: theme.palette.common.primary500,
    backgroundColor: 'transparent',
    transition: 'transform 1s ease',
    transform: ({ arrowDirection }: StyleProps) =>
      (arrowDirection === ArrowDirection.LEFT ? 'translate(-140px)' : 'translate(0)'),
    '&:hover': {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    },
  },
  checkCircle: {
    position: 'absolute',
    bottom: -2,
    right: 2,
    fontSize: 40,
  },
}));

const templateOptions = [
  InvoiceTemplateType.CLASSIC_TEMPLATE,
  InvoiceTemplateType.MODERN_TEMPLATE,
  InvoiceTemplateType.FUN_TEMPLATE,
];

const TemplatePicker = (): JSX.Element | null => {
  // if the arrow direction is left, that means we're on the right side
  // if the arrow direction is right, that mean's we're on the left side
  // because we need to *go* in the arrow direction
  const [arrowDirection, setArrowDirection] = useState<ArrowDirection>(ArrowDirection.RIGHT);
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField<InvoiceTemplateType>('templateType');
  const classes = useStyles({ arrowDirection });

  return (
    <Box color="common.primary500">
      <Typography variant="h3" color="inherit">
        Choose a template
      </Typography>
      <Box m={2} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {arrowDirection === ArrowDirection.LEFT &&
          <Box
            className={classes.arrowContainer}
            onClick={() => setArrowDirection(ArrowDirection.RIGHT)}
          >
            <NavigateBeforeIcon fontSize="inherit" color="inherit" />
          </Box>
        }
        <Box className={classes.scrollContainer}>
          {templateOptions.map(type => (
            <Box
              key={type}
              className={(classes.templateWrapper)}
              border={
                (value === type ? `3px solid ${theme.palette.common.primaryTransparent500}` :
                  'none')
              }
              onClick={() => {
                setValue(type);
              }}
              data-cy={type}
            >
              <img
                src={`https://hearth.imgix.net/contractor-v2/custom_invoice/${type}_thumbnail.png`}
                alt="Thank you script"
                height="180"
                width="140"
              />
              {value === type &&
                <Box className={classes.checkCircle}>
                  <CheckCircleIcon fontSize="inherit" color="inherit" />
                </Box>
              }
            </Box>
          ))}
        </Box>
        {arrowDirection === ArrowDirection.RIGHT &&
          <Box
            className={classes.arrowContainer}
            onClick={() => setArrowDirection(ArrowDirection.LEFT)}
          >
            <NavigateNextIcon fontSize="inherit" color="inherit" />
          </Box>
        }
      </Box>
    </Box>
  );
};

export default TemplatePicker;
