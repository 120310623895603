import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import {
  boolean,
  string,
  object,
  date,
  number,
} from 'yup';

import options from 'common/utils/options';
import { Popup } from 'contractor/components/widgets';
import { PopupProps } from 'contractor/components/widgets/Popup';
import {
  Form,
  InputField,
  AddressField,
  DatePickerField,
  SelectField,
  CheckboxField,
  SubmitButton,
} from 'common/components/formik';
import { currencyFormat } from 'common/utils/stringFormatters';

type Props = {
  extraErrors: Partial<Record<keyof Values, string>>;
  onSubmit: (values: Values) => void;
  termsHtml: string;
  loading: boolean;
  initialValues: Partial<Values>;
  loanPrincipal?: number;
} & Omit<PopupProps, 'onSubmit'>;

type Values = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  birthday?: Date;
  ssn?: string;
  fico?: string;
  employmentStatus?: string;
  income?: string;
  terms: boolean;
};

const CoborrowerModal = ({
  extraErrors,
  onClose,
  onSubmit,
  termsHtml,
  loading,
  initialValues,
  loanPrincipal,
  ...otherProps
}: Props): JSX.Element => (
  <Popup
    onClose={onClose}
    {...otherProps}
  >
    <Box p={{ xs: 2, sm: 5 }} bgcolor="common.basic100">
      <Form<Values>
        initialValues={{
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
          email: initialValues.email,
          phoneNumber: initialValues.phoneNumber,
          address: initialValues.address,
          city: initialValues.city,
          state: initialValues.state,
          zipCode: initialValues.zipCode,
          birthday: initialValues.birthday,
          fico: initialValues.fico,
          employmentStatus: initialValues.employmentStatus,
          income: initialValues.income,
          ssn: undefined,
          terms: false,
        }}
        extraErrors={extraErrors}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange={false}
        validationSchema={object().shape({
          address: string().label('Address').required(),
          city: string().label('City').required(),
          email: string().label('Email').email().required(),
          firstName: string().label('First name').required(),
          lastName: string().label('Last name').required(),
          phoneNumber: string().label('Phone number')
            .length(12, 'Phone number must be exactly 10 digits')
            .required(),
          state: string().label('State').required(),
          zipCode: string().label('Zip code').required(),
          ssn: string().label('Social security number').length(9).required(),
          birthday: date().typeError('Date is invalid').label('Birthdate')
            .max(moment().subtract(18, 'years').toDate(), 'You must be at least 18 years old')
            .required(),
          income: number().label('Annual income')
            .min(10000, 'Your annual income must be more than $10,000')
            .required(),
          employmentStatus: string().label('Employment status').required(),
          fico: string().label('Credit score').required(),
          terms: boolean().label('Terms')
            .oneOf([true], 'Terms must be accepted')
            .required(),
        })}
      >
        <Typography variant="h2">
          <Box fontFamily="Lora">
            Add a co-borrower
          </Box>
        </Typography>
        <Box m={1} />
        <Typography variant="body2">
          You may increase the amount you can borrow, and you could lower your rate by applying with
          someone else. The co-borrower and you will both sign the loan agreement and are jointly
          liable for the loan.
        </Typography>
        <Box m={3} />
        {loanPrincipal &&
          <>
            <Typography variant="h4">
              Project cost: {currencyFormat(loanPrincipal)}
            </Typography>
            <Box m={2} />
          </>
        }
        <Typography variant="h3">
          Co-borrower info
        </Typography>
        <Box m={2} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField name="firstName" autoFocus />
          </Grid>
          <Grid item xs={6}>
            <InputField name="lastName" />
          </Grid>
        </Grid>
        <Box m={2} />
        <InputField name="email" label="Email address" />
        <Box m={2} />
        <InputField name="phoneNumber" mask="phone" />
        <Box m={5} />
        <AddressField
          name="address"
          cityName="city"
          stateName="state"
          zipCodeName="zipCode"
        />
        <Box m={2} />
        <InputField name="city" />
        <Box m={2} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectField name="state" items={options.states} native />
          </Grid>
          <Grid item xs={6}>
            <InputField name="zipCode" />
          </Grid>
        </Grid>
        <Box m={5} />
        <SelectField name="employmentStatus" items={options.employmentStatus} />
        <Box m={2} />
        <InputField name="income" mask="money" label="Annual income (individual)" />
        <Box m={2} />
        <SelectField name="fico" items={options.ficoRanges} label="Credit score" />
        <Box my={2} mx={{ xs: -2, sm: -5 }} px={{ xs: 2, sm: 5 }} py={3} bgcolor="grey.A100">
          <DatePickerField
            name="birthday"
            label="Birthdate"
            textFieldProps={{ fullWidth: true, defaultValue: '' }}
          />
          <Box m={2} />
          <InputField name="ssn" mask="ssn" label="Social security number" />
          <Typography variant="caption">
            <Box textAlign="center" mt={1}>
              Seeing rates does <Box fontWeight="fontWeightBold" display="inline">NOT</Box> affect
              your credit score.
            </Box>
          </Typography>
          <Box m={3} />
          <Typography variant="caption">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: termsHtml }} />
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <CheckboxField name="terms" label="I agree to the terms above." />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Box minWidth={200}>
            <SubmitButton
              buttonType="primary"
              variant="contained"
              loading={loading}
              fullWidth
            >
              Compare loan options
            </SubmitButton>
          </Box>
        </Box>
      </Form>
    </Box>
  </Popup>
);

export default CoborrowerModal;
