import { Box, Typography } from '@mui/material';

type Props = {
  description: React.ReactNode;
}

const Content = ({ description }: Props): JSX.Element => (
  <Box mt={3} mb={2} pr={5}>
    <Typography variant="h5">
      {description}
    </Typography>
  </Box>
);

export default Content;
