import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Button, Dialog } from 'common/components/material-ui';

type Props = {
  open: boolean;
  onClose: () => void;
}

const TrainingBlockModal = ({ open, onClose }: Props): JSX.Element => (
  <Dialog
    fullScreen={false}
    open={open}
    onClose={onClose}
    titleBgColor="primary500"
    bgColor="basic100"
    scroll="body"
    overlayTopPadding={1}
    dialogTitle={
      <Box display="flex">
        <Box color="common.basic100" mr={1}>
          <ErrorOutlineIcon fontSize="large" />
        </Box>
        <Typography variant="h3">
          <Box color="common.basic100">
            Training Not Completed
          </Box>
        </Typography>
      </Box>
    }
  >
    <Box
      display="flex"
      flexDirection="column"
      px={5}
      pt={4}
      pb={3}
    >
      <Typography>
        After you attend your training, financing will be unlocked.
      </Typography>
      <Box mb={2} />
      <Button
        variant="contained"
        buttonType="common.primary"
        onClick={onClose}
        style={{ alignSelf: 'center' }}
      >
        Close
      </Button>
    </Box>
  </Dialog>
);

export default TrainingBlockModal;
