import { useState, useCallback } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';

import { useOnboardingWalkthrough } from 'contractor/modules/OnboardingWalkthrough/useOnboardingWalkthrough';
import { ActionBarProvider } from 'contractor/modules/Dashboard/modules/ActionBar/useActionBar';

import QuickActionsMenu from './QuickActionsMenu';
import SendNewButtonHighlight, { SendNewHighlightProps } from '../SendNewButtonHighlight';
import { useDashboardNavigation } from '../../useDashboardNavigation';

type Props = {
  homeownerId?: string;
  anchorEl: HTMLElement | null;
  open?: boolean;
  isMobile: boolean,
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
} & Partial<MenuProps>;

type WrappedProps = {
  children: React.ReactNode;
  tooltipOpen?: boolean;
  onClose?: () => void;
} & Omit <SendNewHighlightProps, 'open'>;

const WrappedMenu = ({
  children,
  tooltipOpen,
  onClose,
  ...otherProps
}: WrappedProps): JSX.Element => {
  const { data: { state: onboardingState } } = useOnboardingWalkthrough();
  if (!tooltipOpen) return <>{children}</>;
  return (
    <SendNewButtonHighlight
      open={onboardingState.step == null}
      anchorProps={{ top: 8 }}
      backdrop={false}
      onClose={onClose}
      {...otherProps}
    >
      {children}
    </SendNewButtonHighlight>
  );
};

const QuickActionsMenuContainer = ({
  isMobile,
  homeownerId,
  anchorEl,
  open,
  setAnchorEl,
  ...otherProps
}: Props): JSX.Element => {
  const anchorVertical = isMobile ? 'top' : 'bottom';
  const transformVertical = isMobile ? 'bottom' : 'top';
  const {
    uiStates: {
      skittlesWelcomeTourSeen,
      showSkittlesTour,
    },
  } = useDashboardNavigation();
  const [, setDimensions] = useState<DOMRectReadOnly>();
  const measuredRef = useCallback((node) => {
    if (node != null) {
      setDimensions(node.getBoundingClientRect());
    }
  }, []);

  return (
    <Menu
      data-testid="quick-actions-menu"
      keepMounted
      anchorEl={anchorEl}
      open={open ?? Boolean(anchorEl)}
      anchorOrigin={{ vertical: anchorVertical, horizontal: 'right' }}
      transformOrigin={{ vertical: transformVertical, horizontal: 'right' }}
      {...otherProps}
    >
      {isMobile ?
        <QuickActionsMenu homeownerId={homeownerId} /> :
        <WrappedMenu
          tooltipOpen={!skittlesWelcomeTourSeen && showSkittlesTour}
          onClose={() => {
            if (setAnchorEl == null) return;
            setAnchorEl(null);
          }}
        >
          <div ref={measuredRef}>
            <QuickActionsMenu
              homeownerId={homeownerId}
              onClose={() => {
                if (setAnchorEl == null) return;
                setAnchorEl(null);
              }}
            />
          </div>
        </WrappedMenu>}
    </Menu>
  );
};

export default (props: Props): JSX.Element => (
  <ActionBarProvider>
    <QuickActionsMenuContainer {...props} />
  </ActionBarProvider>
);
