/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

import { analytics } from 'common/services';
import { registerSession } from 'common/services/analytics';
import { readInQueryParams } from 'common/reducers/UserState';
import { fetchServerConstantsDispatcher } from 'common/actions/serverConstants';
import { fetchUserSessionDispatcher } from 'common/actions/session';
import { refreshData } from 'contractor/actions/contractor';
import * as localStorage from 'common/services/localStorage';
import { refreshTokenDispatcher } from 'contractor/actions/auth';

const useApp = (): boolean => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  const actions = useMemo(() => bindActionCreators({
    readInQueryParams,
    refreshData,
    fetchServerConstants: fetchServerConstantsDispatcher,
    fetchUserSession: fetchUserSessionDispatcher,
    refreshToken: refreshTokenDispatcher,
  }, dispatch), []);

  useEffect(registerSession, []);

  const loadInformation = useCallback(
    async () => {
      const query = parse(location.search);

      try {
        if (localStorage.hasStorage()) {
          if (localStorage.getItem('refreshToken')) {
            await actions.refreshToken({
              token: localStorage.getItem('refreshToken') || '',
            });
          }
          if (localStorage.getItem('accessToken')) {
            await actions.refreshData();
          }
        }
      } catch (error) {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        analytics.trackException(error);
      }

      await Promise.all([
        actions.fetchUserSession({ query: location.search }),
        actions.readInQueryParams(query),
        actions.fetchServerConstants({}),
      ]);

      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    loadInformation();
  }, []);

  return loading;
};

export default useApp;
