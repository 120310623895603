import { useState } from 'react';
import {
  Box,
  CardMedia,
  Typography,
  Card,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 620,
    height: '100%',
    backgroundColor: theme.palette.common.basic100,
    borderRadius: 10,
    border: 'none',
    padding: 40,
    '&:hover': {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  icon: {
    height: 40,
    width: 40,
  },
}));

type Props = {
  onPress: () => void;
  title: string;
}

const LargeNavCard = ({ onPress, title }: Props): JSX.Element => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <Card className={classes.card}>
      <Typography variant="h2">
        <Box
          color="common.primary900"
          fontSize={38}
          fontWeight="fontWeightMedium"
          pr={3}
        >
          {title}
        </Box>
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-end"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onPress}
      >
        <CardMedia
          className={classes.icon}
          component="img"
          alt="Down arrow icon"
          src={'https://hearth.imgix.net/contractor-v2/pop_page/' +
            `down_arrow_${hover ? 'active' : 'inactive'}.svg`}
        />
      </Box>
    </Card>
  );
};

export default LargeNavCard;
