import { useCallback, useState } from 'react';

import { GetStartedContractorFragment } from 'types';
import { Typography } from 'common/components/material-ui';
import { useGetStartedActions } from 'contractor/modules/platform/GetStarted/useGetStartedActions';

import { GetStartedListItem, LoadingButton } from '..';
import { Task } from '../../useGetStarted';

type Props = {
  contractor: GetStartedContractorFragment
}

const MobileAppTask = ({ contractor }: Props): JSX.Element => {
  const { firstAppLoginAt } = contractor;
  const { textMobileAppLink } = useGetStartedActions();
  const [loading, setLoading] = useState(false);

  const textLinkCallback = useCallback(async () => {
    setLoading(true);
    try {
      await textMobileAppLink();
    } finally {
      setLoading(false);
    }
  }, [setLoading, textMobileAppLink]);

  return (
    <GetStartedListItem
      taskCompleted={Boolean(firstAppLoginAt)}
      taskName={Task.MOBILE_APP}
    >
      <Typography variant="p2">
        Text yourself a link to download the mobile app.
      </Typography>
      <LoadingButton
        onClick={textLinkCallback}
        baseText="Text link"
        loadingText="Sending"
        loading={loading}
      />
    </GetStartedListItem>
  );
};

export default MobileAppTask;
