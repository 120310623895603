import { useMemo } from 'react';
import { SvgIconProps } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import CodeIcon from '@mui/icons-material/Code';
import { PlatePluginOptions, usePlateEditorState } from '@udecode/plate-core';
import {
  getPreventDefaultHandler,
  isMarkActive,
  toggleMark,
} from '@udecode/plate-common';
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from '@udecode/plate';

import BaseButton from './BaseButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MarkButtonProps extends Pick<PlatePluginOptions, 'type' | 'clear'>{}

const ICON_MAPPING: Record<string, React.ComponentType<SvgIconProps>> = {
  [MARK_BOLD]: FormatBoldIcon,
  [MARK_ITALIC]: FormatItalicIcon,
  [MARK_UNDERLINE]: FormatUnderlinedIcon,
  [MARK_CODE]: CodeIcon,
};

const MarkButton = ({ type, clear, ...props }: MarkButtonProps): JSX.Element => {
  const editor = usePlateEditorState();
  const Icon = useMemo(() => ICON_MAPPING[type], [type]);

  return (
    <BaseButton
      Icon={Icon}
      active={!!editor?.selection && isMarkActive(editor, type)}
      onMouseDown={
        editor ?
          getPreventDefaultHandler(toggleMark, editor, type, clear) :
          undefined
      }
      {...props}
    />
  );
};

export default MarkButton;
