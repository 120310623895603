import { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { Prompt } from 'react-router-dom';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { useBeforeUnload } from 'react-use';

// TODO(reusable_component_refactor)
import CustomInvoice from 'contractor/modules/CustomInvoice';
import {
  CustomizedInvoiceTemplateAttributes,
  GetStartedOrganizationTemplateInfoFragment,
  GetStartedInvoiceCustomizationSettingsFragment,
} from 'types';

// TODO(reusable_component_refactor)
import { getTemplateValues, fakeInvoiceData } from 'contractor/modules/CustomizeInvoice/useCustomizeInvoice';

type Props = {
  activeTemplate: GetStartedInvoiceCustomizationSettingsFragment;
  organization: GetStartedOrganizationTemplateInfoFragment;
}

const CustomizedInvoicePreview = ({ activeTemplate, organization }: Props): JSX.Element => {
  const { values } = useFormikContext<CustomizedInvoiceTemplateAttributes>();

  const block = useMemo(() => {
    if (!activeTemplate) return false;

    const currentValues = getTemplateValues(activeTemplate);
    const formValues = getTemplateValues(values);
    return !isEqual(formValues, currentValues);
  }, [activeTemplate, values]);

  useBeforeUnload(block, 'Leave without saving? Your progress will be discarded.');

  if (organization == null) return <Skeleton variant="rectangular" height="100%" />;

  return (
    <>
      <Prompt
        when={block}
        message="Leave without saving? Your progress will be discarded."
      />
      <CustomInvoice
        settings={values}
        organization={organization}
        invoice={fakeInvoiceData}
        attachments={[]}
      />
    </>
  );
};

export default CustomizedInvoicePreview;
