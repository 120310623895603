import { gql } from '@apollo/client';
import {
  Box,
  Typography,
  IconButton,
  BoxProps,
  useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

import {
  useSetQuickbooksBannerDismissedMutation,
  useGetQuickBooksDismissibleBannerDataQuery,
} from 'types';
import { Button } from 'common/components/material-ui';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useUrl } from 'common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    backgroundColor: theme.palette.common.basic100,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    position: 'relative',
    border: `1px solid ${theme.palette.common.success700}`,
    '&:hover': {
      backgroundColor: theme.palette.common.success100,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.common.basic700,
  },
  setupButton: {
    marginRight: 16,
    color: theme.palette.common.success700,
    '&:hover': {
      color: theme.palette.common.success700,
      backgroundColor: theme.palette.common.success300,
    },
    [theme.breakpoints.down('xl')]: {
      borderRadius: 8,
      marginTop: 16,
      marginRight: 0,
    },
  },
}));

type Props = {
  rootProps?: Partial<BoxProps>;
}

const QuickBooksDismissibleBanner = ({ rootProps }: Props): JSX.Element | null => {
  const classes = useStyles();
  const { appUrl } = useUrl();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));

  const { handleMutationError } = useGql();
  const [updateQuickBooksBannerMutation] = useSetQuickbooksBannerDismissedMutation();
  const updateQuickBooksBanner = async () => {
    try {
      const response = await updateQuickBooksBannerMutation({
        variables: {
          customSettings: {
            quickbooksBannerDismissed: true,
          },
        },
      });

      parseGql<PayloadType<typeof response, 'upsertContractor'>>(
        'upsertContractor',
        response,
        'UpsertContractorSuccess',
        'UpsertContractorFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  };

  const { data } = useGetQuickBooksDismissibleBannerDataQuery();

  const isAdmin = data?.contractor?.admin ?? false;
  const quickbooksEnabled = !!data?.contractor?.organization?.quickbooksBusiness?.id;
  const showQuickBooksBanner = !data?.contractor?.customSettings?.quickbooksBannerDismissed;
  const paidInvoicesCount = data?.contractor?.paidConnectInvoices?.totalCount || 0;
  const hasPaidInvoices = paidInvoicesCount > 0;

  if (!isAdmin || !showQuickBooksBanner || !hasPaidInvoices || quickbooksEnabled) {
    return null;
  }

  return (
    <Box className={classes.root} {...rootProps}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box mr={2} display="flex" mb={{ xs: 2, sm: 0 }}>
          <img
            style={{ marginRight: 8 }}
            alt="QuickBooks logo"
            height={24}
            width={24}
            src="https://hearth.imgix.net/icons/QB_48x48.png"
          />
          <Typography variant="h5"> QuickBooks</Typography>
        </Box>
        <Typography variant="body2">
          Sync paid invoices with QuickBooks
        </Typography>
      </Box>
      <Button
        variant={isMobile ? 'outlined' : 'text'}
        fullWidth={isMobile}
        buttonType="common.success"
        className={classes.setupButton}
        href={appUrl('/quickbooks/setup')}
        target="_blank"
      >
        Set Up →
      </Button>
      <IconButton
        disableRipple
        aria-label="close"
        className={classes.closeButton}
        size="large"
      >
        <CloseIcon onClick={updateQuickBooksBanner} />
      </IconButton>
    </Box>
  );
};

QuickBooksDismissibleBanner.GET_QUICKBOOKS_DISMISSIBLE_BANNER_DATA = gql`
  query GetQuickBooksDismissibleBannerData {
    contractor {
      id
      paidConnectInvoices {
        totalCount
      }
      customSettings {
        quickbooksBannerDismissed
      }
      organization {
        id
        quickbooksBusiness {
          id
        }
      }
      admin
    }
  }
`;

QuickBooksDismissibleBanner.SET_QUICKBOOKS_BANNER_DISMISSED = gql`
  mutation SetQuickbooksBannerDismissed($customSettings: CustomContractorSettingsAttributes!) {
    upsertContractor(customSettings: $customSettings) {
      ... on UpsertContractorSuccess {
        contractor {
          id
          customSettings {
            quickbooksBannerDismissed
          }
        }
      }
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default QuickBooksDismissibleBanner;
