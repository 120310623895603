import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import { Icon } from 'common/components';
import { Dialog, Button } from 'common/components/material-ui';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 24px 48px',
    color: theme.palette.common.success700,
  },
  button: {
    backgroundColor: theme.palette.common.success700,
    boxShadow: 'none',
  },
  dashboardButton: {
    textDecoration: 'underline',
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
}

const InvoiceSavedModal = ({ open, onClose }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      bgColor="basic100"
    >
      <Box className={classes.root}>
        <Icon
          name="spark-check"
          color="success700"
          bgColor="basic100"
          size={82}
        />
        <Box mt={2} />
        <Typography variant="h2">
          Invoice template design saved!
        </Typography>
        <Box px={11}>
          <Box mt={3} mb={4}>
            <Typography variant="subtitle1" color="textPrimary" align="center">
              You are ready to send a payment request with your new invoice design
            </Typography>
          </Box>
          <Button
            buttonType="common.success"
            onClick={() => {
              history.push('/dashboard');
              history.push('/dashboard/request/request-payment?source=customize_invoice', {
                background: {
                  pathname: '/dashboard',
                },
              });
            }}
            variant="contained"
            className={classes.button}
            fullWidth
          >
            Send a payment request
          </Button>
          <Box mt={2} />
          <Button
            buttonType="common.success"
            onClick={() => history.push('/dashboard')}
            variant="text"
            className={classes.dashboardButton}
            fullWidth
          >
            or go to your dashboard
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InvoiceSavedModal;
