import {
  ApiRejectionError,
  ApiNetworkError,
  ApiMaxPollError,
  ApiError,
} from 'promise-action';
import { AxiosError } from 'axios';
import reduce from 'lodash/reduce';
import { sentenceCase } from 'change-case';
import { CustomError } from 'ts-custom-error';

class ApiFormError extends CustomError {
  status?: number;
  formErrors?: Record<string, string>;
  apiResponse: ApiError['response'];

  constructor(response: ApiError['response']) {
    if (!response) {
      super('Unknown API error');
    } else {
      super(`API returns status code ${response.status} from ${response.config.url}`);
    }

    this.apiResponse = response;

    if (!response) {
      return;
    }

    this.status = response.status;

    const { errors } = response.data as { errors: Record<string, string[]> };
    this.formErrors = reduce(errors, (result, errorList, field) => ({
      ...result,
      [field]: `${sentenceCase(field)} ${errorList[0]}`,
    }), {});
  }

  sentryExtra() {
    return {
      status: this.status,
      formErrors: this.formErrors,
      apiResponse: this.apiResponse,
    };
  }
}

export type FormError = ApiMaxPollError | ApiNetworkError | ApiFormError;

// eslint-disable-next-line import/prefer-default-export
export const formErrorNormalizer = (error: ApiRejectionError): FormError => {
  if (error instanceof ApiNetworkError || error instanceof ApiMaxPollError) {
    return error;
  }

  return new ApiFormError(error.response);
};

export function isAxiosError(error: Error): error is AxiosError {
  return (error as AxiosError).config !== undefined;
}
