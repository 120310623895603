import { useField } from 'formik';
import { ImageListItem, ImageListItemBar, ImageListItemProps } from '@mui/material';

type Props = {
  namePrefix: string;
  action?: React.ReactNode;
} & ImageListItemProps;

export type ImageListItemFieldValues = {
  id?: string;
  localSrc: string;
  remoteSrc?: string;
  fileName: string;
}

const ImageListItemField = ({
  namePrefix,
  action,
  ...otherProps
}: Props): JSX.Element => {
  const [{ value }] = useField<ImageListItemFieldValues>(namePrefix);

  return (
    <ImageListItem sx={{ borderRadius: 8, overflow: 'auto' }} {...otherProps}>
      <img
        src={value.remoteSrc || value.localSrc}
        alt="job"
        loading="lazy"
        style={{ height: 140 }}
      />
      <ImageListItemBar
        sx={{
          background: 'none',
          // this makes the action element more visible when the color is similar to its background
          filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4))',
        }}
        position="top"
        actionPosition="right"
        actionIcon={action}
      />
    </ImageListItem>
  );
};

export default ImageListItemField;
