import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import { Dialog, Link } from 'common/components/material-ui';

import {
  useHomeownerFeedback,
  HomeownerFeedbackProvider,
  useHomeownerFeedbackActions,
  HomeownerFeedbackParams,
} from './useHomeownerFeedback';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '48px 32px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: theme.palette.common.basic300,
  },
}));

type RatingProps = {
  rating: number;
}

const Stars = ({ rating }: RatingProps) => (
  <Box display="flex" flexDirection="row">
    {[...Array(5)].map((_, index) => (
      <Box
        fontSize={48}
        color={index < rating ? 'common.warning500' : 'common.basic500'}
      >
        <StarRoundedIcon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    ))}
  </Box>
);

type Props = {
  onClose: () => void;
}

const HomeownerFeedbackModal = ({ onClose }: Props): JSX.Element | null => {
  const classes = useStyles();

  const { navigateToClientProfile } = useHomeownerFeedbackActions();
  const { homeowner, feedback } = useHomeownerFeedback();

  if (!homeowner || !feedback) return null;

  const {
    ratings,
    additionalFeedback,
    submittedAt,
    overallRating,
  } = feedback;

  return (
    <Dialog open onClose={onClose}>
      <Box className={classes.root}>
        <Box className={classes.row} mb={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" color="textPrimary">
              {homeowner.fullName}
            </Typography>
            <Box color="common.primary500">
              <Link
                variant="body2"
                onClick={() => navigateToClientProfile(homeowner.id)}
                color="inherit"
              >
                Go to customer profile
              </Link>
            </Box>
          </Box>
          <Box className={classes.row} color="common.primary500">
            <Typography variant="body2" color="inherit">
              Overall rating
            </Typography>
            <Box ml={3} />
            <Typography variant="h2" color="inherit">
              {overallRating}/5
            </Typography>
          </Box>
        </Box>
        <Divider variant="fullWidth" className={classes.divider} />
        <Box mt={3} />
        {ratings.map(rating => (
          <Box className={classes.row} mb={1}>
            <Typography variant="subtitle1" color="textPrimary">
              {rating.label}
            </Typography>
            <Stars rating={rating.rating} />
          </Box>
        ))}
        {additionalFeedback &&
          <Box mt={2}>
            <Divider variant="fullWidth" className={classes.divider} />
            <Box mt={3} />
            <Typography variant="body1" color="textSecondary">
              Review for contractor:
            </Typography>
            <Box mt={2} />
            <Typography variant="caption" color="textSecondary">
              {submittedAt ? format(Date.parse(submittedAt), 'MMM dd, yyyy') : ''}
            </Typography>
            <Box mt={1} />
            <Typography variant="subtitle2" color="textPrimary">
              <Box fontWeight="fontWeightBold">
                {`"${additionalFeedback}"`}
              </Box>
            </Typography>
          </Box>
        }
      </Box>
    </Dialog>
  );
};

const HomeownerFeedbackModalContainer = (): JSX.Element | null => {
  const history = useHistory();
  const { homeownerId, feedbackType } = useParams<HomeownerFeedbackParams>();

  if (!homeownerId) return null;

  return (
    <HomeownerFeedbackProvider
      homeownerId={homeownerId}
      feedbackType={feedbackType}
    >
      <HomeownerFeedbackModal
        onClose={() => {
          history.replace(`/dashboard/reviews/${feedbackType.toLowerCase()}`);
        }}
      />
    </HomeownerFeedbackProvider>
  );
};

export default HomeownerFeedbackModalContainer;
