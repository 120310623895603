import { useMemo, useCallback } from 'react';
import { Typography, Box, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Dialog, Polaroid } from 'common/components/material-ui';
import { Loader } from 'common/components/widgets';
import {
  GetEnterpriseInfoQuery,
  SwitchEnterpriseOrgMutation,
  SwitchEnterpriseOrgMutationVariables,
} from 'types';

const GET_ENTERPRISE_INFO = gql`
  query GetEnterpriseInfo {
    contractor {
      id
      role
      admin
      enterpriseAdmin
      enterpriseOrgs {
        nodes {
          id
          companyName
          logoUrl
        }
      }
      organization {
        id
        companyName
        logoUrl
      }
    }
  }
`;

const SWITCH_ACCOUNT = gql`
  mutation SwitchEnterpriseOrg ($organizationId: ID!) {
    switchEnterpriseOrg(organizationId: $organizationId) {
      ... on SwitchEnterpriseOrgSuccess {
        contractor {
          id
          admin
        }
      }
      ... on SwitchEnterpriseOrgFailure {
        errors {
          code
          message
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 32px 64px',
    backgroundColor: theme.palette.common.basic100,
    border: `1px solid ${theme.palette.common.basic500}`,
    borderRadius: 4,
  },
  hearthLogo: {
    height: 24,
    width: 24,
  },
  hearthTextLogo: {
    height: 24,
    width: 'auto',
  },
}));

type Props = {
  onClose: () => void;
  switcherOpen: boolean;
}

const AccountSwitcher = ({ onClose, switcherOpen }: Props): JSX.Element => {
  const classes = useStyles();

  // @TODO: this re-renders a LOT. Investigate to reduce amount of network calls
  const { data, loading } = useQuery<GetEnterpriseInfoQuery>(
    GET_ENTERPRISE_INFO, {
      fetchPolicy: 'network-only',
    },
  );

  const [switchEnterpriseOrg] =
    useMutation<SwitchEnterpriseOrgMutation, SwitchEnterpriseOrgMutationVariables>(
      SWITCH_ACCOUNT,
    );

  const enterpriseOrgs = useMemo(() => {
    if (loading || !data?.contractor?.enterpriseOrgs?.nodes) {
      return [];
    }

    return data?.contractor?.enterpriseOrgs?.nodes;
  }, [data, loading]);

  const switchOrganization = useCallback(async (orgId: string | undefined) => {
    if (orgId) {
      await switchEnterpriseOrg({
        variables: {
          organizationId: orgId,
        },
      });

      window.location.href = '/dashboard/profile';
      onClose();
    }
  }, [onClose, switchEnterpriseOrg]);

  return (
    <Dialog
      maxWidth="lg"
      open={switcherOpen}
      onClose={onClose}
      dialogTitle={
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <CardMedia
              className={classes.hearthLogo}
              alt="Hearth logo"
              src="https://hearth.imgix.net/logos/hearth-logo.svg"
              component="img"
            />
            <Box mx={1} />
            <CardMedia
              className={classes.hearthTextLogo}
              alt="Hearth text"
              src="https://hearth.imgix.net/logos/hearth-logo-text.svg"
              component="img"
            />
          </Box>
        </>
      }
    >
      <Box mt={5} mb={2}>
        <Typography variant="h5" color="textPrimary" align="center">
          Select the account you want to manage
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexWrap="wrap"
        px={{ md: 16, lg: 36 }}
      >
        {enterpriseOrgs.length === 0 &&
          <Loader />
        }
        {enterpriseOrgs.map(org => (
          <Box key={org?.id} mx={2} mb={5}>
            <Polaroid
              caption={org?.companyName}
              imgSrc={org?.logoUrl}
              imgAlt="Organization logo"
              onClick={() => switchOrganization(org?.id)}
            />
          </Box>
        ))}
      </Box>
    </Dialog>
  );
};

export default AccountSwitcher;
