import { useState, Children as ReactChildren } from 'react';
import {
  Box,
  Theme,
  Tabs,
  Tab,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const tabStyles = makeStyles((theme: Theme) => ({
  tab: {
    color: theme.palette.common.basic1100,
    borderRight: `1px solid ${theme.palette.common.basic700}`,
  },
  informationBody: {
    backgroundColor: theme.palette.common.basic300,
    padding: '20px 40px',
  },
  beigeBackground: {
    backgroundColor: theme.palette.common.basic300,
  },
  indicator: {
    display: 'none',
  },
}));

type Props = {
  tabs: string[];
  children: React.ReactNode;
};

const InformationTabs = ({ tabs, children }: Props): JSX.Element => {
  const classes = tabStyles();
  const [tabOpen, setTabOpen] = useState(0);

  return (
    <>
      <Box display="flex" flexDirection="row" flex={0}>
        <Tabs
          value={tabOpen}
          onChange={(e, newValue) => setTabOpen(newValue)}
          aria-label="information tabs"
          classes={{ indicator: classes.indicator }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab}
              value={index}
              classes={{ root: classes.tab, selected: classes.beigeBackground }}
            />
          ))}
        </Tabs>
      </Box>
      {ReactChildren.map(children, ((child, index) => (
        <Box
          display={tabOpen === index ? 'flex' : 'none'}
          className={classes.informationBody}
          flex={tabOpen === index ? 1 : 0}
        >
          {child}
        </Box>
      )))}
    </>
  );
};

export default InformationTabs;
