const constants = {
  componentForm: {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name',
  },
  componentMapper: {
    street_number: 'address',
    route: 'address',
    locality: 'city',
    administrative_area_level_1: 'state',
    postal_code: 'zipCode',
  },
  helocStates: ['AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'FL', 'GA', 'ID', 'IL', 'IN', 'KS',
    'LA', 'MA', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV',
    'OH', 'OK', 'OR', 'PA', 'RI', 'SD', 'TN', 'VA', 'WA', 'WI', 'WY'],
  loadingBlocks: [
    'Accessing lender databases',
    'Securely fetching your personalized rates',
    'Just a few more seconds',
    'Almost there',
    'Calculating best loans to show you',
  ],
  supportNumber: '5126864141',
  supportNumberDisplay: '(512) 686-4141',
  salesNumber: '5126452229',
  salesNumberDisplay: '(512) 645-2229',
  projectImages: {
    addition: 'https://hearth.imgix.net/offerspage/addition.jpg',
    basement: 'https://hearth.imgix.net/offerspage/basement.jpg',
    bathroom: 'https://hearth.imgix.net/offerspage/bathroom.jpg',
    HVAC: 'https://hearth.imgix.net/offerspage/hvac.jpg',
    kitchen: 'https://hearth.imgix.net/offerspage/kitchen.jpg',
    home_improvement: 'https://hearth.imgix.net/offerspage/other.jpg',
    roofing: 'https://hearth.imgix.net/offerspage/roofing.jpg',
    solar: 'https://hearth.imgix.net/offerspage/solar.jpg',
    concrete: 'https://hearth.imgix.net/offerspage/concrete.jpg',
    painting: 'https://hearth.imgix.net/offerspage/painting.jpg',
    electrical: 'https://hearth.imgix.net/offerspage/electrical.jpg',
    cabinetry: 'https://hearth.imgix.net/offerspage/cabinetry.jpg',
    windows: 'https://hearth.imgix.net/offerspage/windows.jpg',
  },
  referralDiscount: 100,
};

export default constants;
