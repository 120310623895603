import { SvgIconProps } from '../Icon';

const ClockIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...otherProps}>
    <path
      d="M4.219 34.031l3.937-4.5M32.344 34.031l-3.938-4.5"
      stroke={color}
      strokeMiterlimit={10}
    />
    <path
      d="M18.281 34.031c7.456 0 13.5-6.044 13.5-13.5s-6.044-13.5-13.5-13.5-13.5 6.044-13.5 13.5 6.044 13.5 13.5 13.5zM15.332 3.974a7.632 7.632 0 00-12.007 9.003M33.238 12.976a7.59 7.59 0 00-.436-7.522 7.641 7.641 0 00-11.57-1.48"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M11.531 13.781l6.75 6.75 5.063-5.062"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default ClockIcon;
