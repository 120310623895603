import { Popover as MuiPopover, useTheme } from '@mui/material';

type CloneProps = { anchorEl?: HTMLButtonElement | null }

const ClonePopover = ({ anchorEl }: CloneProps): JSX.Element | null => {
  const theme = useTheme();
  if (anchorEl == null) return null;

  return (
    <MuiPopover
      anchorEl={anchorEl}
      open
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      disablePortal
      BackdropProps={{
        invisible: false,
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 8,
          mt: -1,
        },
      }}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: anchorEl.outerHTML }}
        // the only way to render/clone the button for now (not ideal)
        style={{
          padding: 4,
          backgroundColor: theme.palette.common.basic100,
        }}
      />
    </MuiPopover>
  );
};

export default ClonePopover;
