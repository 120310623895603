import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Icon } from 'common/components';
import { Button } from 'common/components/material-ui';
import { useCheckTermsAcceptanceQuery } from 'types';
import ContractWizard from 'contractor/modules/ContractWizard';
import EditContractTemplatePage from 'contractor/pages/EditContractTemplatePage';
import ViewContractTemplate from 'contractor/modules/EditContract/ViewContractTemplate';
import TermsBlockModal from 'contractor/modules/EditContract/modals/TermsBlockModal';

import { MobileBlock } from './components';

const ContractTemplate = (): JSX.Element | null => {
  const history = useHistory();
  const location = useLocation();
  const [termsBlockModalOpen, setTermsBlockModalOpen] = useState(false);
  const { data, loading } = useCheckTermsAcceptanceQuery({
    fetchPolicy: 'network-only',
  });
  const itly = useLogEvent();

  useMount(() => {
    itly.viewCustomizeContractTool();
  });

  const contractServiceAccount = data?.organization?.contractServiceAccount;

  useEffect(() => {
    // If they have a contractServiceAccount, then that means
    // they already went through the setup wizard. If they've gone
    // through the setup wizard, then we need to check if they agreed to the terms.
    if (contractServiceAccount != null && contractServiceAccount?.termsAgreedAt == null) {
      setTermsBlockModalOpen(true);
    }
  }, [contractServiceAccount]);

  if (loading) {
    return null;
  }

  return (
    <>
      <TermsBlockModal
        open={termsBlockModalOpen}
        onClose={() => setTermsBlockModalOpen(false)}
      />
      <Switch>
        {contractServiceAccount != null &&
          <Redirect
            from="/dashboard/tools/contract-template/setup"
            to="/dashboard/tools/contract-template"
          />
        }
        <Route
          exact
          path="/dashboard/tools/contract-template/(setup)?"
        >
          {contractServiceAccount == null ?
            <ContractWizard /> :
            <MobileBlock
              description="use the Contracts feature"
              mobileChildren={
                <Box mt={7}>
                  <Button
                    variant="contained"
                    buttonType="common.purple"
                    onClick={() => {
                      history.push('/dashboard/request/send-contract?source=mobile_block', {
                        background: location,
                      });
                    }}
                    fullWidth
                  >
                    <Icon
                      name="paper-plane"
                      color="basic100"
                      size={20}
                    />
                    <Box m={1} />
                    Send a contract
                  </Button>
                </Box>
              }
            >
              <ViewContractTemplate />
            </MobileBlock>
          }
        </Route>
        {contractServiceAccount != null &&
          <MobileBlock description="use the Contracts feature">
            <Route
              exact
              path="/dashboard/tools/contract-template/edit"
              component={EditContractTemplatePage}
            />
          </MobileBlock>
        }
      </Switch>
    </>
  );
};

ContractTemplate.CHECK_TERMS_ACCEPTANCE = gql`
  query CheckTermsAcceptance {
    organization {
      id
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
`;

export default ContractTemplate;
