import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import constate from 'constate';

import { contractorArticleSelector } from 'contractor/selectors/article';
import { useGetSetupLaterQuery } from 'types';
import { isLoggedInSelector } from 'contractor/selectors/contractor';

export type CompletedType = {
  login: boolean;
  install: boolean;
  invite: boolean;
  articleVideo: boolean;
}

type SetupProgress = {
  closeTooltip: () => void;
  completedSteps: CompletedType;
  showInstallBannersLaterTooltip: boolean;
  showInviteTeamTooltip: boolean;
}

const setupFlowVideoArticleId = 'q11yTMRvuVhSVBWhi6cgd';

export const GET_SETUP_STATUS = gql`
  query GetSetupLater {
    contractor {
      id
      role
      appVersion
      onboardedAt
      setupFlowAppLoginLater
      setupFlowInstallBannersLater
      setupFlowWatchArticleVideoLater
      setupFlowInviteTeamLater
      setupFlowInstallBanners
      setupFlowAddTeamMembers
      setupFlowTextLinkSent
      setupFlowWatchArticleVideo
      admin
      enterpriseAdmin
      organization {
        id
        website
        bannerInstalledAt
        totalSeats
        statedNoTeam
        sentMemberInvites
      }
    }
  }
`;

export const useSetupProgress = (): SetupProgress => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { data } = useGetSetupLaterQuery({
    skip: !isLoggedIn,
  });

  // Article for the video for seats
  const contractorData = useSelector(contractorArticleSelector(setupFlowVideoArticleId));
  const videoWatched = contractorData ? !!contractorData.readAt : false;
  const contractor = useMemo(() => data?.contractor, [data]);
  const organization = useMemo(() => data?.contractor?.organization, [data]);

  const [showInstallBannersLaterTooltip, setShowInstallBannersLaterTooltip] = useState(false);
  const [showInviteTeamTooltip, setShowInviteTeamTooltip] = useState(false);

  const closeTooltip = useCallback(() => {
    setShowInstallBannersLaterTooltip(false);
    setShowInviteTeamTooltip(false);
  }, []);

  const completedStepsMemo = useMemo(() => {
    let completedSteps = {
      login: false,
      install: false,
      invite: false,
      articleVideo: false,
    };

    if (contractor && organization) {
      const {
        appVersion,
        setupFlowAppLoginLater,
        setupFlowInstallBannersLater,
        setupFlowInviteTeamLater,
        setupFlowWatchArticleVideoLater,
        setupFlowInstallBanners,
        setupFlowAddTeamMembers,
        setupFlowTextLinkSent,
        setupFlowWatchArticleVideo,
      } = contractor;
      const {
        website,
        bannerInstalledAt,
        statedNoTeam,
        sentMemberInvites,
      } = organization;

      completedSteps = {
        login: Boolean(appVersion !== null || setupFlowAppLoginLater || setupFlowTextLinkSent),
        install: Boolean(
          bannerInstalledAt !== null ||
          website === '' ||
          setupFlowInstallBannersLater ||
          setupFlowInstallBanners,
        ),
        invite: Boolean(
          statedNoTeam ||
          sentMemberInvites ||
          setupFlowInviteTeamLater ||
          setupFlowAddTeamMembers,
        ),
        articleVideo: Boolean(
          videoWatched ||
          setupFlowWatchArticleVideo ||
          setupFlowWatchArticleVideoLater,
        ),
      };
    }
    return completedSteps;
  }, [contractor, organization, videoWatched]);

  return {
    completedSteps: completedStepsMemo,
    showInstallBannersLaterTooltip,
    showInviteTeamTooltip,
    closeTooltip,
  };
};

const [Provider, useSetupProgressContext] = constate(useSetupProgress);

export default useSetupProgressContext;
export const SetupProgressProvider = Provider;
