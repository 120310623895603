import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { FormikValues } from 'formik';
import { useContainer } from 'bentoo/hooks';

import { Note } from 'common/components/material-ui';
import { useVerticalState } from 'common/hooks';
import { useSnack } from 'common/utils/snackCart';
import { commonResetPassword } from 'contractor/actions/contractor';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = (): JSX.Element => {
  const [passwordReset, setPasswordReset] = useState(false);
  const resetPassword = useContainer(commonResetPassword);
  const { errorSnack } = useSnack();
  const [{ verticalMetadata }] = useVerticalState();
  const { title } = verticalMetadata.login;

  const onSubmit = async (values: FormikValues): Promise<void> => {
    try {
      await resetPassword.callApi(values.email);
      setPasswordReset(true);
    } catch (e) {
      errorSnack();
    }
  };

  return (
    <Note
      title={title}
      subtitle="Forgot your password?"
    >
      <Box mt={1} mb={4}>
        <Typography>
          Please enter the email address associated with your account.
          We will email you a link to reset your password.
        </Typography>
      </Box>
      {passwordReset ?
        <Box m={8}>
          <Typography color="primary" align="center" variant="h5">
            Password reset instructions have been emailed to you.
          </Typography>
        </Box> :
        <ForgotPasswordForm onSubmit={onSubmit} />
      }
    </Note>
  );
};

export default ForgotPassword;
