import {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { isPresent } from 'ts-is-present';
import { ContractBlob } from 'kakemono';

import {
  ContractTemplateVersionFragment,
  useGetAllContractTemplatesQuery,
  useGetEditContractTemplateQuery,
  useGetContractBaseTemplatesQuery,
  useUpsertEditContractTemplateVersionMutation,
  useUpsertOrganizationContractHeaderInfoMutation,
  useSetDefaultContractTemplateMutation,
  useDeleteAllTemplatesByVersionNameMutation,
  useGenerateEditContractPreviewPdfMutation,
} from 'types';
import useEditContractTemplateReducer, {
  ContractTemplateActionType,
  getTosMarkdown,
  getDefaultPaymentNumber,
  getIncludeLogo,
} from 'contractor/hooks/useEditContractTemplateReducer';
import { mdToSlate } from 'common/components/elements/SlateEditor/serializers';
import { useSnack } from 'common/utils/snackCart';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useLogEvent } from 'common/hooks';

import { EditTemplateValues, Checkbox } from './EditContractTemplate';
import { TemplateTitleFormValues } from './TemplatePreview';

export const ORGANIZATION_HEADER_FRAGMENT = gql`
  fragment OrganizationContractTemplateInfo on Organization {
    id
    companyName
    businessLicenseNumber
    businessPhone
    address
    city
    state
    zipCode
    logoUrl
    contractServiceAccount {
      id
    }
  }
`;

export const EDIT_CONTRACT_TEMPLATE = gql`
  query GetEditContractTemplate {
    organization {
      id
      ...OrganizationContractTemplateInfo
    }
    contractor {
      id
      phoneNumber
      email
      admin
      fullName
    }
    contractServiceContractTemplate(type:project_contract) {
      id
      versionName
      template
      baseTemplate {
        id
      }
    }
  }
  ${ORGANIZATION_HEADER_FRAGMENT}
`;

export const GET_CONTRACT_BASE_TEMPLATES = gql`
  query GetContractBaseTemplates {
    contractServiceBaseTemplate(type:project_contract) {
      id
      template
    }
  }
`;

export const UPSERT_HEADER_INFORMATION = gql`
  mutation UpsertOrganizationContractHeaderInfo(
    $attributes: OrganizationAttributes!
  ) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          ...OrganizationContractTemplateInfo 
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${ORGANIZATION_HEADER_FRAGMENT}
`;

export const UPSERT_CONTRACT_TEMPLATE = gql`
  mutation UpsertContractTemplate(
    $contractServiceAccountId: ID!,
    $baseTemplateId: String!,
    $template: JSON!
  ) {
    contractServiceContractTemplateUpsert(
      contractServiceAccountId: $contractServiceAccountId
      templateParams: {
        type: project_contract
        template: $template
        baseTemplateId: $baseTemplateId
    }) {
      ... on ContractServiceTemplateUpsertSuccess {
        contractServiceContractTemplate {
          id
          version
          baseTemplate {
            id
          }
        }
      }
      ... on ContractServiceTemplateUpsertFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

// we have a separate mutation on the backend for contracts_v2
// to prevent anything from breaking
export const UPSERT_EDIT_CONTRACT_TEMPLATE_VERSION = gql`
  mutation UpsertEditContractTemplateVersion (
    $templateId: ID,
    $contractServiceAccountId: ID!,
    $baseTemplateId: String!,
    $template: JSON!,
    $versionName: String!
    $contractorId: ID!,
  ) {
    contractServiceContractTemplateVersionUpsert(
      contractServiceAccountId: $contractServiceAccountId
      templateParams: {
        id: $templateId
        type: project_contract
        template: $template
        baseTemplateId: $baseTemplateId
        versionName: $versionName
        editedBy: $contractorId
    }) {
      ... on ContractServiceTemplateVersionUpsertSuccess {
        contractServiceContractTemplate {
          id
          version
          baseTemplate {
            id
          }
        }
      }
      ... on ContractServiceTemplateVersionUpsertFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const GET_ALL_CONTRACT_TEMPLATES = gql`
  fragment ContractTemplateVersion on ContractServiceContractTemplate {
    id
    template
    versionName
    default
    baseTemplate {
      id
    }
    updatedAt
    editor {
      id
      fullName
    }
  }

  query GetAllContractTemplates {
    organization {
      id
      contractServiceAccount {
        id
        latestVersionContractTemplates {
          nodes {
            id
            ...ContractTemplateVersion
          }
        }
      }
    }
  }
`;

export const SET_DEFAULT_CONTRACT_TEMPLATE = gql`
  mutation SetDefaultContractTemplate ($contractServiceContractTemplateId: ID!) {
    contractServiceMarkDefaultTemplate(
      contractServiceContractTemplateId: $contractServiceContractTemplateId
    ) {
      ... on ContractServiceMarkDefaultTemplateSuccess {
        contractServiceContractTemplate {
          id
        }
      }
      ... on ContractServiceMarkDefaultTemplateFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const DELETE_ALL_TEMPLATES_BY_VERSION_NAME = gql`
  mutation DeleteAllTemplatesByVersionName($versionName: String!) {
    contractServiceContractTemplateDeleteByName(versionName: $versionName) {
      ... on ContractServiceTemplateDeleteByNameSuccess {
        contractServiceContractTemplateVersionName
      }
      ... on ContractServiceTemplateDeleteByNameFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const GENERATE_EDIT_CONTRACT_PREVIEW_PDF = gql`
  mutation GenerateEditContractPreviewPdf($html: String!) {
    contractGeneratorRestGeneratePdf(input: { html: $html }) @rest(endpoint: "contractGenerator", method: "POST", path: "/pdf_generator", type: "ContractGeneratorRestGeneratePdfResult") {
      pdf
    }
  }
`;

const useBaseContext = () => {
  const { data, loading, refetch } = useGetEditContractTemplateQuery();
  const { data: allTemplatesData } = useGetAllContractTemplatesQuery();
  const [upsertContractTemplateVersionMutation] = useUpsertEditContractTemplateVersionMutation();
  const { handleMutationError } = useGql();
  const { data: baseTemplates } = useGetContractBaseTemplatesQuery();
  const [template, dispatch] = useEditContractTemplateReducer();
  const [editTosModalOpen, setEditTosModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ContractTemplateVersionFragment | null>(null);
  // Formik doesn't have a "successfully submitted" field
  // so we need to keep track when the template has been saved
  // so that we do not surface the block modal upon routing back to
  // the contract template view.
  const [templateSubmitted, setTemplateSubmitted] = useState(false);

  const contractor = useMemo(() => data?.contractor, [data]);
  const organization = useMemo(() => data?.organization, [data]);
  const contractServiceContractTemplate = useMemo(() =>
    data?.contractServiceContractTemplate,
  [data]);
  const templateName = data?.contractServiceContractTemplate?.versionName;

  const latestBaseTemplate = useMemo(() => {
    if (!baseTemplates) return null;
    return {
      id: baseTemplates.contractServiceBaseTemplate.id,
      template: baseTemplates.contractServiceBaseTemplate.template,
    };
  }, [baseTemplates]);

  const currentTemplate = useMemo(() => selectedTemplate?.template, [selectedTemplate]);

  // for orgs that have contracts set up before contracts v2
  // pre-contracts v2 contracts do not have a name, but a name is needed
  // for contracts v2, so this mutation gives an existing template a name
  // eslint-disable-next-line max-len
  const upsertFirstContractTemplateVersionTitle = useCallback(async (temp: ContractTemplateVersionFragment) => {
    try {
      const baseTemplateId = temp.baseTemplate.id;
      const contractServiceAccountId = organization?.contractServiceAccount?.id;
      if (!baseTemplateId) {
        throw new Error('Base contract template not found');
      }

      if (!temp.template) {
        throw new Error('Contract template not found');
      }

      if (!contractServiceAccountId) {
        throw new Error('Contract service account not found');
      }

      if (!contractor) {
        throw new Error('Contractor not found');
      }

      const response = await upsertContractTemplateVersionMutation({
        variables: {
          contractServiceAccountId,
          baseTemplateId,
          template: temp.template,
          versionName: 'Contract Template 1',
          contractorId: contractor.id,
        },
        refetchQueries: ['GetAllContractTemplates'],
      });

      parseGql<PayloadType<typeof response, 'contractServiceContractTemplateVersionUpsert'>>(
        'contractServiceContractTemplateVersionUpsert',
        response,
        'ContractServiceTemplateVersionUpsertSuccess',
        'ContractServiceTemplateVersionUpsertFailure',
      );
    } catch (e) {
      handleMutationError(e);
    }
  }, [contractor, organization, upsertContractTemplateVersionMutation, handleMutationError]);

  useEffect(() => {
    if (currentTemplate) {
      dispatch({
        type: ContractTemplateActionType.SET_TEMPLATE,
        payload: {
          template: currentTemplate as ContractBlob,
        },
      });
    }
  }, [dispatch, currentTemplate]);

  const allTemplates = useMemo(() => {
    if (!contractor || !organization) return [];
    const templateList = allTemplatesData
      ?.organization
      ?.contractServiceAccount
      ?.latestVersionContractTemplates
      ?.nodes?.filter(isPresent) || [];
    // if the org set up contracts before v2, then its template will not have a name,
    // so we'll give it a name here
    if (templateList.length === 1 && !templateList[0].versionName) {
      upsertFirstContractTemplateVersionTitle(templateList[0]);
    }
    return templateList
      .filter(temp => temp?.versionName != null)
      .sort((a, b) => {
        // default template will always be first
        if (a.default) return -1;
        if (b.default) return 1;
        if (a.versionName && b.versionName) {
          return (a.versionName).localeCompare(b.versionName);
        }
        return 0;
      });
  }, [allTemplatesData, contractor, organization, upsertFirstContractTemplateVersionTitle]);

  const initialTosMarkdown = useMemo(() => {
    if (!currentTemplate) return '';
    return getTosMarkdown(currentTemplate as ContractBlob);
  }, [currentTemplate]);

  const initialDefaultPaymentCount = useMemo(() => {
    if (!currentTemplate) return 2;
    return getDefaultPaymentNumber(currentTemplate as ContractBlob);
  }, [currentTemplate]);

  const initialTosSlateValue = useMemo(() => (
    initialTosMarkdown ? mdToSlate(initialTosMarkdown) : []
  ), [initialTosMarkdown]);

  const includeLogo = useMemo(() => {
    if (!currentTemplate) return null;
    return getIncludeLogo(currentTemplate as ContractBlob);
  }, [currentTemplate]);

  const [editingChecklist, setEditingChecklist] = useState(false);
  const [editingTextEntryList, setEditingTextEntryList] = useState(false);

  return {
    data: {
      loading,
      contractor,
      organization,
      // Contract template from backend
      contractServiceContractTemplate,
      allTemplates,
      latestBaseTemplate,
      // Contract reducer data
      contractTemplate: {
        template,
        templateName,
        dispatch,
        initialDefaultPaymentCount,
        initialTosSlateValue,
        includeLogo,
      },
      refetch,
      selectedTemplate,
      setSelectedTemplate,
    },
    uiStates: {
      editTosModalOpen,
      setEditTosModalOpen,
      templateSubmitted,
      setTemplateSubmitted,
      editingChecklist,
      setEditingChecklist,
      editingTextEntryList,
      setEditingTextEntryList,
    },
  };
};

export const [
  EditContractTemplateProvider,
  useEditContractTemplate,
  useEditContractTemplateUIStates,
] = constate(
  useBaseContext,
  ({ data }) => data,
  ({ uiStates }) => uiStates,
);

type Actions = {
  hydrateTemplate: (contractTemplate: ContractBlob, formValues: EditTemplateValues) => ContractBlob;
  upsertContractTemplate: (values: EditTemplateValues) => void;
  upsertContractTemplateTitle: (values: TemplateTitleFormValues) => void;
  setDefaultContractTemplate: (newTemplateId?: string) => void;
  deleteAllContractTemplatesByName: () => Promise<string | void>
  upsertNewContractTemplate: () => void;
  generatePreviewPdf: (html: string) => Promise<string>;
}

export const useEditContractTemplateActions = (): Actions => {
  const {
    organization,
    contractor,
    contractTemplate: {
      // version of template with changes from reducer
      template,
    },
    selectedTemplate,
    allTemplates,
    latestBaseTemplate,
  } = useEditContractTemplate();

  const { setTemplateSubmitted } = useEditContractTemplateUIStates();
  const [upsertContractTemplateVersionMutation] = useUpsertEditContractTemplateVersionMutation();
  const [upsertOrganizationContractHeaderInfoMutation] =
    useUpsertOrganizationContractHeaderInfoMutation();
  const [setDefaultContractTemplateMutation] = useSetDefaultContractTemplateMutation();
  const [deleteAllContractTemplatesByNameMutation] = useDeleteAllTemplatesByVersionNameMutation();
  const [generateEditContractPreviewPdfMutation] = useGenerateEditContractPreviewPdfMutation();

  const { handleMutationError } = useGql();
  const history = useHistory();
  const snack = useSnack();
  const itly = useLogEvent();

  const setDefaultContractTemplate = useCallback(async (newTemplateId?: string) => {
    try {
      const currentTemplateId = newTemplateId || selectedTemplate?.id;
      if (currentTemplateId) {
        const response = await setDefaultContractTemplateMutation({
          variables: {
            contractServiceContractTemplateId: currentTemplateId,
          },
          refetchQueries: [
            'GetEditContractTemplate',
            'GetViewContractTemplate',
            'GetAllContractTemplates',
          ],
        });

        parseGql<PayloadType<typeof response, 'contractServiceMarkDefaultTemplate'>>(
          'contractServiceMarkDefaultTemplate',
          response,
          'ContractServiceMarkDefaultTemplateSuccess',
          'ContractServiceMarkDefaultTemplateFailure',
        );
      }
    } catch (e) {
      handleMutationError(e);
    }
  }, [selectedTemplate, setDefaultContractTemplateMutation, handleMutationError]);

  const deleteAllContractTemplatesByName = useCallback(async () => {
    if (!selectedTemplate?.versionName) {
      throw new Error('Cannot find selected template version name');
    }
    try {
      const response = await deleteAllContractTemplatesByNameMutation({
        variables: {
          versionName: selectedTemplate.versionName,
        },
        refetchQueries: [
          'GetEditContractTemplate',
          'GetViewContractTemplate',
          'GetAllContractTemplates',
        ],
      });

      const result = parseGql<PayloadType<typeof response, 'contractServiceContractTemplateDeleteByName'>>(
        'contractServiceContractTemplateDeleteByName',
        response,
        'ContractServiceTemplateDeleteByNameSuccess',
        'ContractServiceTemplateDeleteByNameFailure',
      );
      return result.contractServiceContractTemplateVersionName;
    } catch (e) {
      return handleMutationError(e);
    }
  }, [selectedTemplate, deleteAllContractTemplatesByNameMutation, handleMutationError]);

  const upsertContractTemplate = useCallback(async (values: EditTemplateValues) => {
    try {
      const baseTemplateId = selectedTemplate?.baseTemplate?.id;
      const contractServiceAccountId = organization?.contractServiceAccount?.id;

      if (!baseTemplateId) {
        throw new Error('Base contract template not found');
      }

      if (!template) {
        throw new Error('Contract template not found');
      }

      if (!contractServiceAccountId) {
        throw new Error('Contract service account not found');
      }

      if (!contractor) {
        throw new Error('Contractor not found');
      }

      const organizationResponse = await upsertOrganizationContractHeaderInfoMutation({
        variables: {
          attributes: {
            companyName: values.companyName,
            address: values.address.address,
            city: values.address.city,
            state: values.address.state,
            zipCode: values.address.zipCode,
            businessLicenseNumber: values.businessLicenseNumber,
            businessPhone: values.businessPhone,
          },
        },
      });

      parseGql<PayloadType<typeof organizationResponse, 'upsertOrganization'>>(
        'upsertOrganization',
        organizationResponse,
        'UpsertOrganizationSuccess',
        'UpsertOrganizationFailure',
      );

      // because of how multiple-template storage is handled on the backend, we want
      // to delete the selected template if a name change occurs - in brief, the query
      // fetches the most recent version of a template, and in distinguishing between
      // templates, it uses the versionName field. this means we're okay NOT deleting
      // the existing template if there's no name change -- but if there is, the outdated
      // template still will be fetched under its old name along with the edited version
      const newTemplateName = selectedTemplate?.versionName;

      const templateResponse = await upsertContractTemplateVersionMutation({
        variables: {
          // if an id is provided, the template is updated, and no new template
          // is created -- this is the desired behavior when the name has not changed
          templateId: newTemplateName === values.templateName ? selectedTemplate?.id : null,
          contractServiceAccountId,
          baseTemplateId,
          template: hydrateTemplate(template, values),
          versionName: values.templateName,
          contractorId: contractor.id,
        },
        // if no name change, refetch here -- otherwise, we'll refetch
        // when we call the delete mutation
        refetchQueries: newTemplateName === values.templateName ?
          [
            'GetEditContractTemplate',
            'GetViewContractTemplate',
            'GetAllContractTemplates',
          ] : [],
      });

      const result = parseGql<PayloadType<typeof templateResponse, 'contractServiceContractTemplateVersionUpsert'>>(
        'contractServiceContractTemplateVersionUpsert',
        templateResponse,
        'ContractServiceTemplateVersionUpsertSuccess',
        'ContractServiceTemplateVersionUpsertFailure',
      );

      if (selectedTemplate?.default) {
        setDefaultContractTemplate(result.contractServiceContractTemplate.id);
      }

      // this is where we delete, per the comment above (name change => delete old version)
      if (newTemplateName !== values.templateName) {
        await deleteAllContractTemplatesByName();
      }

      setTemplateSubmitted(true);

      if (values.templateName) {
        itly.saveContractTemplate({ nameOfTemplate: values.templateName });
      }

      snack.successSnack('Successfully updated contract template');
      history.push('/dashboard/workflows/contracts/contract-templates', {
        editedTemplateName: values.templateName,
      });
    } catch (e) {
      handleMutationError(e);
    }
  }, [
    handleMutationError,
    history,
    organization,
    snack,
    template,
    selectedTemplate,
    contractor,
    itly,
    upsertContractTemplateVersionMutation,
    upsertOrganizationContractHeaderInfoMutation,
    setTemplateSubmitted,
    setDefaultContractTemplate,
    deleteAllContractTemplatesByName,
  ]);

  const upsertContractTemplateTitle = useCallback(async (values: TemplateTitleFormValues) => {
    try {
      const baseTemplateId = selectedTemplate?.baseTemplate?.id;
      const contractServiceAccountId = organization?.contractServiceAccount?.id;
      if (!baseTemplateId) {
        throw new Error('Base contract template not found');
      }

      if (!selectedTemplate?.template) {
        throw new Error('Contract template not found');
      }

      if (!contractServiceAccountId) {
        throw new Error('Contract service account not found');
      }

      if (!contractor) {
        throw new Error('Contractor not found');
      }

      const response = await upsertContractTemplateVersionMutation({
        variables: {
          contractServiceAccountId,
          baseTemplateId,
          template: selectedTemplate.template,
          versionName: values.templateTitle,
          contractorId: contractor.id,
        },
      });

      const result = parseGql<PayloadType<typeof response, 'contractServiceContractTemplateVersionUpsert'>>(
        'contractServiceContractTemplateVersionUpsert',
        response,
        'ContractServiceTemplateVersionUpsertSuccess',
        'ContractServiceTemplateVersionUpsertFailure',
      );

      itly.editContractTemplateName();

      if (selectedTemplate.default) {
        setDefaultContractTemplate(result.contractServiceContractTemplate.id);
      }
      // delete selected template because otherwise, outdated version will continue to be
      // fetched because of how this works on the backend
      await deleteAllContractTemplatesByName();
      return result.contractServiceContractTemplate.id;
    } catch (e) {
      return handleMutationError(e);
    }
  }, [
    handleMutationError,
    upsertContractTemplateVersionMutation,
    setDefaultContractTemplate,
    deleteAllContractTemplatesByName,
    organization,
    contractor,
    selectedTemplate,
    itly,
  ]);

  const upsertNewContractTemplate = useCallback(async () => {
    try {
      const baseTemplateId = latestBaseTemplate?.id;
      const contractTemplate = latestBaseTemplate?.template;
      const contractServiceAccountId = organization?.contractServiceAccount?.id;

      if (!baseTemplateId) {
        throw new Error('Base contract template not found');
      }

      if (!contractTemplate) {
        throw new Error('Contract template not found');
      }

      if (!contractServiceAccountId) {
        throw new Error('Contract service account not found');
      }

      if (!contractor) {
        throw new Error('Contractor not found');
      }

      let newTemplateName = 'New Template';
      // // if a template with the default name exists, increment i until there isn't a match
      for (let i = 1; i <= allTemplates.length; i += 1) {
        // eslint-disable-next-line no-loop-func
        if (!allTemplates.find(temp => temp.versionName === `${newTemplateName} ${i}`)) {
          newTemplateName += ` ${i}`;
          break;
        }
      }

      const response = await upsertContractTemplateVersionMutation({
        variables: {
          contractServiceAccountId,
          baseTemplateId,
          template: contractTemplate,
          versionName: newTemplateName,
          contractorId: contractor.id,
        },
        refetchQueries: ['GetAllContractTemplates'],
      });

      parseGql<PayloadType<typeof response, 'contractServiceContractTemplateVersionUpsert'>>(
        'contractServiceContractTemplateVersionUpsert',
        response,
        'ContractServiceTemplateVersionUpsertSuccess',
        'ContractServiceTemplateVersionUpsertFailure',
      );

      itly.createNewContractTemplate();
    } catch (e) {
      handleMutationError(e);
    }
  }, [
    allTemplates,
    contractor,
    organization,
    latestBaseTemplate,
    itly,
    upsertContractTemplateVersionMutation,
    handleMutationError,
  ]);

  const hydrateTemplate = (contractTemplate: ContractBlob, formValues: EditTemplateValues) => {
    const modifiedTemplate = _.cloneDeep(contractTemplate);
    const serviceDescription = modifiedTemplate.body.nodes.find(node => node.id === 'service-description-entered');
    const checkboxList = modifiedTemplate.body.nodes.find(node => node.id === 'service-static-checkboxes');
    const detailsTable = modifiedTemplate.body.nodes.find(node => node.id === 'description-detail-table');
    if (serviceDescription) {
      serviceDescription.attributes = {
        ...serviceDescription.attributes,
        defaultContent: formValues.serviceDescription,
      };
    }
    if (checkboxList) {
      checkboxList.attributes = {
        ...checkboxList.attributes,
        checkboxes: formValues.customChecklist.map((checkbox: Checkbox, i: number) =>
          ({
            itemName: checkbox.itemName || `Service ${i + 1}`,
            isChecked: checkbox.isChecked,
          })),
      };
    }
    if (detailsTable) {
      detailsTable.attributes = {
        ...detailsTable.attributes,
        previewValues: formValues.customTextEntries.map((entry: string[], i: number) =>
          (!entry[0] ? [`Detail ${i + 1}`, entry[1]] : [...entry])),
      };
    }
    return modifiedTemplate;
  };

  const generatePreviewPdf = useCallback(async (html: string) => {
    const { data } = await generateEditContractPreviewPdfMutation({ variables: { html } });
    if (!data) {
      throw new Error('Data is empty');
    }
    return data.contractGeneratorRestGeneratePdf.pdf;
  }, [generateEditContractPreviewPdfMutation]);

  return {
    hydrateTemplate,
    upsertContractTemplate,
    upsertContractTemplateTitle,
    setDefaultContractTemplate,
    deleteAllContractTemplatesByName,
    upsertNewContractTemplate,
    generatePreviewPdf,
  };
};

export default useEditContractTemplate;
