import { useCallback, useEffect } from 'react';
import { useStateWithHistory } from 'react-use';
import { Typography, Box, CardMedia } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Dialog, Button } from 'common/components/material-ui';

type FeatureInfo = {
  title: React.ReactNode;
  description?: React.ReactNode;
  mediaSrc: string;
  mediaType: 'image' | 'video';
  mediaAlt: string;
}

type Props = {
  onClose: () => void;
  open: boolean;
  featureInfo: FeatureInfo[];
  singleButton?: boolean;
  singleButtonProps?: {
    action: () => void;
    title: string;
  };
}

const useStyles = makeStyles({
  button: {
    width: '20%',
  },
  image: {
    height: '100%',
    width: '100%',
    maxHeight: 380,
    objectFit: 'contain',
    alignSelf: 'center',
  },
});

const FeatureDialog = ({
  open,
  onClose,
  featureInfo,
  singleButton = false,
  singleButtonProps,
}: Props): JSX.Element => {
  const classes = useStyles();
  const featureLength = featureInfo.length;
  const [state,, history] = useStateWithHistory(
    featureInfo[featureLength - 1],
    featureLength,
    featureInfo.slice(0),
  );

  // technically the above hook is for usage with defined
  // "previous" values and you start at the end--
  // by doing this on mount, it lets us save state with built in
  // prev & next actions but start at the beginning :)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.go(0), []);

  const handleBackStep = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.disabled) {
      return;
    }

    history.back(1);
  }, [history]);

  const handleForwardStep = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.disabled) {
      return;
    }

    if (history.position === featureLength - 1) {
      onClose();
    } else {
      history.forward(1);
    }
  }, [history, onClose, featureLength]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={onClose}
        dialogTitle={
          <Typography variant="h5" color="textPrimary" align="center">
            {state.title}
          </Typography>
        }
      >
        <Box px={2}>
          <Box my={3}>
            <Typography variant="h6" color="textPrimary" align="left">
              {state.description}
            </Typography>
          </Box>
          <Box>
            {state.mediaType === 'image' &&
              <CardMedia
                className={classes.image}
                src={state.mediaSrc}
                component="img"
                alt={state.mediaAlt}
              />
            }
          </Box>
          {singleButton && singleButtonProps != null ?
            <Box
              my={5}
              display="flex"
              justifyContent="center"
            >
              <Button
                variant="contained"
                buttonType="primary"
                color="primary"
                onClick={singleButtonProps.action}
                className={classes.button}
                size="medium"
              >
                {singleButtonProps.title}
              </Button>
            </Box> :
            <Box
              my={5}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                buttonType="primary"
                color="primary"
                onClick={handleBackStep}
                disabled={!history.position}
                className={classes.button}
                size="medium"
              >
                BACK
              </Button>
              <Typography variant="body1">
                <Box color="common.basic700">
                  {history.position + 1} / {featureLength}
                </Box>
              </Typography>
              <Button
                variant="contained"
                buttonType="primary"
                color="primary"
                onClick={handleForwardStep}
                className={classes.button}
                size="medium"
              >
                {history.position === featureLength - 1 ? 'DONE' : 'NEXT'}
              </Button>
            </Box>
          }
        </Box>
      </Dialog>
    </>
  );
};

export default FeatureDialog;
