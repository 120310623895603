import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledProgressBar = styled(LinearProgress, {
  name: 'StyledProgressBar',
})(({ theme }) => ({
  height: 16,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: theme.palette.common.basic500,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.common.success700,
  },
}));

type Props = {
  progress: number;
}

const GetStartedProgressBar = ({ progress }: Props): JSX.Element => (
  <StyledProgressBar variant="determinate" value={progress * 100} />
);

export default GetStartedProgressBar;
