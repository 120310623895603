import { useMemo, useCallback } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import { format } from 'date-fns-tz';

import parseGql, { PayloadType } from 'common/api/parseGql';
import { useSnack } from 'common/utils/snackCart';
import { useGql } from 'common/hooks';

import {
  useGetTrainingWebinarSessionsQuery,
  HearthWebinar,
  useScheduleWebinarMutation,
  useSendInviteAndScheduleWebinarMutation,
} from 'types';

import userFeatureFlags from 'common/graphql/featureFlags';

export type SessionType = {
  id: string;
  date: string;
  sessionTime: Date;
  dayOfWeek: string;
  utc: string;
}

export const GET_TRAINING_WEBINAR_SESSIONS = gql`
  query GetTrainingWebinarSessions ($webinarType: HearthWebinar!, $status: String!) {
    livestormWebinars(webinarType: $webinarType, status: $status) {
      id
      attributes {
        estimatedStartedAt
      }
    }
  }
`;

export const SEND_INVITE_AND_SCHEDULE_WEBINAR = gql`
  mutation SendInviteAndScheduleWebinar(
    $emails: [String!]!,
    $trainingScheduledAt: ISO8601DateTime!,
    $sessionId: String!
  ) {
    sendInviteAndScheduleWebinar(
      emails: $emails,
      trainingScheduledAt: $trainingScheduledAt,
      sessionId: $sessionId
    ) {
      ... on SendInviteAndScheduleWebinarSuccess {
        organization {
          id
        }
      }
      ... on SendInviteAndScheduleWebinarFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const SCHEDULE_WEBINAR = gql`
  mutation ScheduleWebinar($id: ID!, $trainingScheduledAt: ISO8601DateTime!, $sessionId: String!) {
    scheduleWebinar(contractorId: $id, trainingScheduledAt: $trainingScheduledAt, sessionId: $sessionId) {
      ... on ScheduleWebinarSuccess {
        contractor {
          id
          trainingScheduledAt
        }
      }
      ... on ScheduleWebinarFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

const webinarSlugMap = {
  [HearthWebinar.HEARTH_TRAINING]: 'hearth-training-webinar',
  [HearthWebinar.HEARTH_TEAM_TRAINING]: 'hearth-training-webinar-for-teams',
};

const useWebinar = (initialWebinarType: HearthWebinar): {
  sortedWebinars: SessionType[][],
  scheduleWebinar: (id: string, trainingScheduledAt: string, sessionId: string) => Promise<void>,
  sendInvitesAndScheduleWebinar: (
    emails: string[],
    trainingScheduledAt: string,
    sessionId?: string | null,
  ) => Promise<void>,
  getDirectRegistrationLink: (contractorId: string) => string,
} => {
  const { handleMutationError } = useGql();
  const snack = useSnack();
  const { industryWebinar } = useReactiveVar(userFeatureFlags);

  // we overwrite this, dependent upon on the associated feature flag
  // this is a bit dangerous as we're conditionally overriting all input that comes in
  let webinarType = initialWebinarType;
  if (industryWebinar) {
    webinarType = HearthWebinar.HEARTH_INDUSTRY;
  }

  /*
    Query: retrieve all webinar sessions for webinar type provided
  */
  const query = useGetTrainingWebinarSessionsQuery({
    variables: {
      webinarType,
      status: 'upcoming',
    },
  });

  const webinars = useMemo(() => query.data?.livestormWebinars, [query.data]);

  const getDirectRegistrationLink = useCallback((contractorId: string) => (
    `https://app.livestorm.co/hearth/${webinarSlugMap[webinarType]}?` +
    `utm_source=contractor&utm_content=${contractorId}&utm_medium=dashboard`
  ), [webinarType]);

  const sortedWebinars = useMemo(() => {
    if (webinars == null) return [];

    const groupedWebinars: Record<string, SessionType[]> = {};

    // sort the sessions by time and map that to the date that we'll look for
    // session "sets" by (one date has multiple session times)
    const sortedTimes = [...webinars.map(session =>
      new Date(session.attributes.estimatedStartedAt),
    )].sort((a, b) => a.getTime() - b.getTime());

    const sortedDates = [...new Set(sortedTimes.map(session =>
      format(session, 'MMM d'),
    ))];

    webinars.forEach((session) => {
      // as we go through, add them to a top level hash.
      // if that hash day + date exists, we add it to the inner object
      const sessionTime = new Date(session.attributes.estimatedStartedAt);
      const dayOfWeek = format(sessionTime, 'EEEE');
      const date = format(sessionTime, 'MMM d');
      const utc = sessionTime.toISOString();

      if (groupedWebinars[date] == null) {
        groupedWebinars[date] = [{
          id: session.id,
          date,
          sessionTime,
          dayOfWeek,
          utc,
        }];
      } else {
        groupedWebinars[date] = [...groupedWebinars[date], {
          id: session.id,
          date,
          sessionTime,
          dayOfWeek,
          utc,
        }];
      }
    });

    return sortedDates.map(date => groupedWebinars[date]);
  }, [webinars]);

  /*
    Mutation: schedule webinar for contractor for session provided
  */

  const [scheduleWebinarMutation] = useScheduleWebinarMutation();

  const scheduleWebinar = useCallback(async (
    id: string,
    trainingScheduledAt: string,
    sessionId: string,
  ): Promise<void> => {
    try {
      const response = await scheduleWebinarMutation({
        variables: {
          id,
          trainingScheduledAt,
          sessionId,
        },
      });

      parseGql<PayloadType<typeof response, 'scheduleWebinar'>>(
        'scheduleWebinar',
        response,
        'ScheduleWebinarSuccess',
        'ScheduleWebinarFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [scheduleWebinarMutation, handleMutationError]);

  /*
    Mutation: send member invite and register them for webinars
  */

  const [scheduleMemberInviteWebinarMutation] = useSendInviteAndScheduleWebinarMutation();

  const sendInvitesAndScheduleWebinar = useCallback(async (
    emails: string[],
    trainingScheduledAt: string,
    sessionId?: string | null,
  ) => {
    if (!sessionId) return;

    try {
      const response = await scheduleMemberInviteWebinarMutation({
        variables: {
          emails,
          trainingScheduledAt,
          sessionId,
        },
      });

      // Not caching errors here so validation errors will bubble up and can be shown on the form
      parseGql<PayloadType<typeof response, 'sendInviteAndScheduleWebinar'>>(
        'sendInviteAndScheduleWebinar',
        response,
        'SendInviteAndScheduleWebinarSuccess',
        'SendInviteAndScheduleWebinarFailure',
      );

      snack.successSnack('Member invited & registered for training');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    snack,
    handleMutationError,
    scheduleMemberInviteWebinarMutation,
  ]);

  return {
    getDirectRegistrationLink,
    sortedWebinars,
    scheduleWebinar,
    sendInvitesAndScheduleWebinar,
  };
};

export default useWebinar;
