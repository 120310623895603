import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { LogsProvider } from 'common/hooks/useLogEvent';
import { Loader } from 'common/components/widgets';
import routes from 'routes/routes';
import graphqlClient from 'common/graphql/apolloClient';
import { VerticalStateProvider } from 'common/hooks/useVerticalState';

import store, { persistor } from './stores';
import theme from './assets/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Base = (): JSX.Element => (
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loader isOverlay />}>
        <ApolloProvider client={graphqlClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider maxSnack={3} hideIconVariant>
                  <VerticalStateProvider>
                    <BrowserRouter>
                      <LogsProvider>
                        {routes}
                      </LogsProvider>
                    </BrowserRouter>
                  </VerticalStateProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);

export default Base;
