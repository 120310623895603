import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBase } from 'common/components/material-ui';
import Icon, { IconName } from 'common/components/Icon';

const useStyles = makeStyles(theme => ({
  buttonbase: {
    display: 'inline-block',
    width: '100%',
    minHeight: 40,
    paddingLeft: 16,
    borderRadius: 8,
    '&:hover': {
      background: theme.palette.common.basic300,
      bgRadius: '8px',
    },
  },
  active: (props: { color: Color }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common[props.color],
    fontWeight: 700,
  }),
}));

type TitleProps = {
  title: string;
  color: Color;
  active: boolean;
  icon?: IconName | React.ReactElement;
}

const Title = ({
  title,
  color,
  icon,
  active,
}: TitleProps) => (
  <Box color={color} display="flex" alignItems="center">
    <Box mr={1}>
      {typeof icon === 'string' ?
        <Icon
          name={icon}
          color={color}
          size={14}
        /> :
        <Box pt={0.25} color={`common.${color}`}>
          {icon}
        </Box>
      }
    </Box>
    <Typography variant={active ? 'subtitle1' : 'body1'} color="inherit">{title}</Typography>
  </Box>
);

type Props = {
  onClick?: () => void;
} & TitleProps;

const NavDrawerItem = ({
  color,
  onClick,
  active,
  ...titleProps
}: Props): JSX.Element => {
  const itemColor = active ? 'primary500' : color;
  const classes = useStyles({ color: itemColor });

  return (
    <ButtonBase
      onClick={onClick}
      className={classes.buttonbase}
    >
      {active ?
        <Box className={classes.active}>
          <Title color={itemColor} active={active} {...titleProps} />
          <Box
            width={4}
            height={38}
            borderRadius="2px"
            bgcolor="common.primary500"
          />
        </Box> :
        <Title color={itemColor} active={active} {...titleProps} />
      }
    </ButtonBase>
  );
};

export default NavDrawerItem;
