/* @jsxImportSource @emotion/react */
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

import { themeColors } from 'assets/theme';

export type FontWeightVariant = 'regular' | 'medium' | 'semibold';
export type FontWeightValue = 400 | 500 | 600;

type FontWeight = {
  [w in FontWeightVariant]: FontWeightValue;
};

export const fontWeights: FontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
};

export interface TypographyProps extends MuiTypographyProps {
  weight?: FontWeightVariant;
  color?: Color;
}

const Typography = ({ weight, color, ...props }: TypographyProps): JSX.Element => (
  <MuiTypography
    css={{
      fontWeight: weight ? fontWeights[weight] : 'inherit',
      color: color ? themeColors[color] : 'inherit',
    }}
    {...props}
  />
);

export default Typography;
