import {
  applyMiddleware,
  compose,
  combineReducers,
  createStore,
  Middleware,
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

import rootReducer from 'common/reducers';
import { isDebug } from 'config/app';
import { ReactotronType } from 'common/utils/reactotronConfig';

let reactotron: ReactotronType | undefined;
if (process.env.REACT_APP_ENV === 'development') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  reactotron = require('common/utils/reactotronConfig').default;
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'contractor',
    'organization',
    'user',
    'serverConstants',
  ],
};

const reducer = combineReducers({
  ...rootReducer,
});

export type AppState = ReturnType<typeof reducer>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const middleware: Middleware[] = [thunk];

if (isDebug) {
  middleware.push(createLogger());
}

let enhancer = applyMiddleware(...middleware);
enhancer = compose(enhancer, sentryReduxEnhancer);

if (reactotron?.createEnhancer) {
  enhancer = compose(enhancer, reactotron.createEnhancer());
}

const store = createStore(
  persistReducer(persistConfig, reducer),
  enhancer,
);

export const persistor = persistStore(store);

export default store;
