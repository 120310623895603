import { useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';
import {
  Box,
  Typography,
  Theme,
  useMediaQuery,
  Breadcrumbs as MuiBreadcrumbs,
} from '@mui/material';
import { useLocation, useRouteMatch, RouteComponentProps } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';

import { useFetchHomeownerNameLazyQuery } from 'types';
import { getEnumByEnumValue } from 'common/utils/typescript';

import { NavigationOption, useDashboardNavigation, activeTabMap } from './useDashboardNavigation';

enum Breadcrumb {
  MARKETING = 'marketing',
  // TODO(contracts_iteration): remove CONTRACT_TEMPLATE when rolled out to all
  CONTRACT_TEMPLATE = 'contract-template',
  CONTRACT_TEMPLATES = 'contract-templates',
  INVOICE_TEMPLATE = 'invoice-template',
  FINANCING_TEMPLATES = 'financing-templates',
  EDIT = 'edit',
  SETUP = 'setup',
  TOOLS = 'tools',
  LEADS = 'leads',

  // settings
  HEARTH_PAY = 'hearth-pay',
  FINANCING = 'financing',
  PASSWORD = 'password',
  NOTIFICATIONS = 'notifications',
  BILLING = 'billing',
  FLYER = 'flyer',
  ACCOUNT_INFO = 'account-info',
  TOKEN = 'token',
  DEMO_MODE = 'demo-mode',
  PROJECT_INTENT = 'project-intent',
  PROFILE = 'profile',

  // reviews
  FINANCING_FEEDBACK = 'financing-feedback',
  HEARTH_PAY_FEEDBACK = 'hearth-pay-feedback',
}

// defines the actual string value
const breadcrumbTitleMapping: Record<Breadcrumb, string> = {
  [Breadcrumb.MARKETING]: 'Marketing tools',
  [Breadcrumb.CONTRACT_TEMPLATE]: 'Contract template',
  [Breadcrumb.CONTRACT_TEMPLATES]: 'Contract templates',
  [Breadcrumb.INVOICE_TEMPLATE]: 'Invoice template',
  [Breadcrumb.FINANCING_TEMPLATES]: 'Financing templates',

  [Breadcrumb.EDIT]: 'Edit',
  [Breadcrumb.SETUP]: 'Setup',
  [Breadcrumb.TOOLS]: 'Tools',
  [Breadcrumb.LEADS]: 'Leads',

  // settings
  [Breadcrumb.PROFILE]: 'Profile',
  [Breadcrumb.HEARTH_PAY]: 'Hearth Pay',
  [Breadcrumb.PASSWORD]: 'Change password',
  [Breadcrumb.NOTIFICATIONS]: 'Notification preferences',
  [Breadcrumb.BILLING]: 'Billing information',
  [Breadcrumb.ACCOUNT_INFO]: 'Account information',
  [Breadcrumb.FLYER]: 'Flyer contact information',
  [Breadcrumb.DEMO_MODE]: 'Demo mode',
  [Breadcrumb.TOKEN]: 'API Token',
  [Breadcrumb.PROJECT_INTENT]: 'Project Intent Contract',
  [Breadcrumb.FINANCING]: 'Hearth Financing',

  // feedback
  [Breadcrumb.FINANCING_FEEDBACK]: 'Financing feedback',
  [Breadcrumb.HEARTH_PAY_FEEDBACK]: 'Hearth Pay feedback',
};

const navBreadcrumbMap: Record<NavigationOption, string[]> = {
  // Top level Nav
  [NavigationOption.DASHBOARD]: ['Dashboard'],
  [NavigationOption.CLIENTS]: ['Clients'],
  [NavigationOption.WORKFLOW]: ['Workflows'],
  [NavigationOption.BUSINESS]: ['Business'],
  [NavigationOption.HELP]: ['Resources'],
  [NavigationOption.SETTINGS]: ['Settings'],

  // Client views
  [NavigationOption.LEADS]: ['Clients', 'Leads'],

  // Workflows
  [NavigationOption.INVOICES]: ['Workflows', 'Invoices'],
  [NavigationOption.CONTRACTS]: ['Workflows', 'Contracts'],
  [NavigationOption.FINANCING]: ['Workflows', 'Financing'],
  [NavigationOption.QUOTES]: ['Workflows', 'Quotes'],
  [NavigationOption.REVIEWS]: ['Workflows', 'Reviews'],

  // Business
  [NavigationOption.TEAM]: ['Business', 'Team'],
  [NavigationOption.INSURANCE]: ['Business', 'Insurance'],
  [NavigationOption.BRANDING]: ['Business', 'Branding'],
  [NavigationOption.INSIGHTS]: ['Business', 'Insights'],
  [NavigationOption.BANKING]: ['Business', 'Banking'],
  [NavigationOption.LENDING]: ['Business', 'Lending'],
};

const constructBreadcrumbs = (pathname: string): { breadcrumbs: string[], baseUrl: string } => {
  const pathnames = pathname.split('/');
  const breadcrumbs: string[] = [];

  // if there is a direct mapping, just return that
  if (activeTabMap[pathname] != null) {
    return {
      breadcrumbs: navBreadcrumbMap[activeTabMap[pathname]],
      baseUrl: pathname,
    };
  }

  let path = pathname;

  // hmmm should there be a "set active tab" function?
  for (let i = 0; i < pathnames.length; i += 1) {
    const baseUrl = path.slice(0, path.lastIndexOf('/'));

    // add to the front of crumbs, then 'remove' from the url path
    const currentCrumb = path.slice(path.lastIndexOf('/') + 1);
    const breadcrumb: Breadcrumb | null = getEnumByEnumValue<typeof Breadcrumb, string>({
      enumObj: Breadcrumb,
      enumValue: currentCrumb,
    });

    // if this mapping exists, then add it to the breadcrumbs
    if (breadcrumb != null) {
      breadcrumbs.unshift(breadcrumbTitleMapping[breadcrumb]);
    }

    // update the string to remove what we just processed
    path = path.replace(currentCrumb, '');

    // is this a base navigation url?
    if (activeTabMap[baseUrl] != null) {
      // if so, then add this to the beginning of the breadcrumbs
      breadcrumbs.unshift(...navBreadcrumbMap[activeTabMap[baseUrl]]);

      return {
        breadcrumbs,
        baseUrl,
      };
    }
  }

  return {
    breadcrumbs,
    baseUrl: path,
  };
};

type MatchParams = {
  homeownerId?: string;
};

type LocationState = {
  background?: RouteComponentProps['location'];
} | undefined;

const Breadcrumbs = (): JSX.Element => {
  const location = useLocation<LocationState>();
  const { uiStates: { setActiveTab } } = useDashboardNavigation();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // TODO(nav_phase_1): there should be a more programmatic way to determine this, but for now...
  const clientViewProfileMatch = useRouteMatch<MatchParams>('/dashboard/clients/:homeownerId');
  const [fetchHomeownerName] = useFetchHomeownerNameLazyQuery();
  const params = clientViewProfileMatch?.params;

  useEffect(() => {
    if (!params?.homeownerId) return;

    fetchHomeownerName({
      variables: {
        homeownerId: params.homeownerId,
      },
    });
  }, [fetchHomeownerName, params?.homeownerId]);

  const breadcrumbs = useMemo(() => {
    const baseCrumbs = constructBreadcrumbs(location.pathname);

    // no matter how 'deep' we are, we want to ensure that the top level
    // navigation tab is correctly displayed as selected
    // ...except settings. this is kind of hacky, but for now...
    if (activeTabMap[baseCrumbs.baseUrl] && !location.pathname.includes('/settings/')) {
      setActiveTab(activeTabMap[baseCrumbs.baseUrl]);
    }

    const allBreadcrumbs = baseCrumbs.breadcrumbs;

    // on mobile, only show the latest breadcrumb so as not to overload the top nav
    return isMobile ? [allBreadcrumbs[allBreadcrumbs.length - 1]] : allBreadcrumbs;
  }, [location.pathname, isMobile, setActiveTab]);

  return (
    <Box my={2}>
      <MuiBreadcrumbs
        aria-label="breadcrumbs"
        separator={
          <Box color="common.basic1100" display="flex" alignItems="center" mb="2px">
            <ChevronRight fontSize="large" color="inherit" />
          </Box>
        }
      >
        {breadcrumbs.map(breadcrumb => (
          <Typography key={breadcrumb} variant="subtitle1" color="textPrimary">
            {breadcrumb}
          </Typography>
        ))}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;

Breadcrumbs.FETCH_HOMEOWNER_NAME = gql`
  query FetchHomeownerName($homeownerId: String!) {
    homeowner(id: $homeownerId) {
      id
      fullName
    }
  }
`;
