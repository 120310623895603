import { memo } from 'react';
import { useField } from 'formik';
import { sentenceCase } from 'change-case';
import split from 'lodash/fp/split';
import last from 'lodash/fp/last';
import flow from 'lodash/fp/flow';

import Input, { Props as InputProps } from 'common/components/material-ui/Input';
import formatCaption from 'common/utils/formik/formatCaption';

export type InputFieldProps = Omit<InputProps, 'ref' | 'value' | 'label'> & {
  name: string;
  label?: string;
  hideErrorMessage?: boolean;
};

const labelFn = flow<[string], string[], string, string>(split('.'), last as (a: string[]) => string, sentenceCase);

const InputField = ({
  name,
  label,
  hideErrorMessage = false,
  helperText,
  error,
  ...otherProps
}: InputFieldProps) => {
  const [field, meta, { setError }] = useField(name);
  const formikError: string | string[] | undefined = meta.touched ? meta.error : undefined;

  const caption = (!hideErrorMessage && formikError) ? formikError : helperText;

  return (
    <>
      <Input
        {...field}
        error={!!formikError || error}
        fullWidth
        label={label != null ? label : labelFn(name)}
        helperText={caption ? formatCaption(caption) : undefined}
        onFocus={() => setError('')}
        InputLabelProps={{
          shrink: true,
        }}
        {...otherProps}
      />
    </>
  );
};

export default memo(InputField);
