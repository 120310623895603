import { Box, Typography, Divider } from '@mui/material';
import { object, string, number } from 'yup';

import {
  Form,
  StarRatingField,
  SubmitButton,
  InputField,
} from 'common/components/formik';
import { Link } from 'common/components/material-ui';
import { useUrl } from 'common/hooks';

type MetricType = {
  label: string;
  name: string;
  rating: number;
}

type Props = {
  title: string;
  onSubmit: (values) => void;
  metrics: MetricType[];
  additionalFeedbackLabel?: string;
  additionalFeedbackName?: string;
}

const FeedbackForm = ({
  title,
  metrics,
  onSubmit,
  additionalFeedbackLabel = 'Additional feedback',
  additionalFeedbackName = 'additionalFeedback',
}: Props): JSX.Element => {
  const { marketingUrl } = useUrl();
  // use a reducer to convert an array of metrics to initial values
  // so that we can reuse this bit for multiple feedback forms
  const initialMetricsValues = metrics.reduce((obj, metric) =>
    ({ ...obj, [metric.name]: metric.rating }), {});

  // numerical star based metrics are required
  const metricsValidations = metrics.reduce((obj, metric) =>
    ({ ...obj, [metric.name]: number().required().positive() }), {});

  return (
    <Form
      initialValues={{
        ...initialMetricsValues,
        [additionalFeedbackName]: undefined,
      }}
      validationSchema={object().shape({
        ...metricsValidations,
        [additionalFeedbackName]: string().notRequired().default(undefined),
      })}
      onSubmit={onSubmit}
    >
      <Box bgcolor="common.basic100" px={6} py={4} borderRadius="8px">
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
        <Box mt={3} />
        <Divider variant="fullWidth" />
        <Box display="flex" flexDirection="column">
          {metrics.map(metric => (
            <Box key={metric.name}>
              <Box py={3}>
                <StarRatingField
                  label={metric.label}
                  name={metric.name}
                />
              </Box>
              <Divider variant="fullWidth" />
            </Box>
          ))}
        </Box>
        <Box mt={5} mb={3}>
          <InputField
            name={additionalFeedbackName}
            label={additionalFeedbackLabel}
            placeholder=""
            multiline
            rows={3}
          />
        </Box>
        <Typography variant="caption" color="textPrimary">
          I agree to Hearth’s
          <Link
            component="a"
            variant="inherit"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            href={marketingUrl('/econsent')}
          >
            {' '}Electronic Transaction & Records Policy
          </Link>,
          <Link
            component="a"
            variant="inherit"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            href={marketingUrl('/terms')}
          >
            {' '}Terms of Use
          </Link>, and
          <Link
            component="a"
            variant="inherit"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            href={marketingUrl('/privacy')}
          >
            {' '}Privacy Policy
          </Link>. By submitting this form, I agree that Hearth may share
          my responses to this survey with any third party.
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <SubmitButton
            disableOnInitialValues={false}
            variant="contained"
            buttonType="common.primary"
          >
            Submit feedback
          </SubmitButton>
        </Box>
      </Box>
    </Form>
  );
};

export default FeedbackForm;
