import React, { useCallback, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Radio,
  Button,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import {
  useUpdateOrganizationWebsitesInfoMutation,
} from 'types';

import { Form, SubmitButton } from 'common/components/formik';
import { useSnack } from 'common/utils/snackCart';
import { useGql } from 'common/hooks';
import { organizationSelector } from 'contractor/selectors/organization';
import { Button as ModalButton, Dialog } from 'common/components/material-ui';
import { Smidge } from 'common/components/layouts';

import useTools from './useTools';

export const UPDATE_COMPANY_INFO = gql`
  mutation UpdateOrganizationWebsitesInfo(
    $attributes: OrganizationAttributes!
  ) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          descriptionOfOperations
          backgroundPicture
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

type ImageGridProps = {
  bgImageUrl: string;
  setBgImageUrl: (value: string) => void;
}

const useStyles = makeStyles({
  image: {
    maxHeight: 200,
    maxWidth: 400,
  },
});

enum ImageUrls {
  ConstructionPlans = 'https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80',
  Sanding = 'https://images.unsplash.com/photo-1513467535987-fd81bc7d62f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1190&q=80',
  Paint = 'https://images.unsplash.com/photo-1562259949-e8e7689d7828?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1016&q=80',
  Workshop = 'https://images.unsplash.com/photo-1505798577917-a65157d3320a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
}

const ImageGrid = ({ bgImageUrl, setBgImageUrl }: ImageGridProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBgImageUrl((event.target as HTMLInputElement).value);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <Radio
            checked={bgImageUrl === ImageUrls.ConstructionPlans}
            onChange={handleChange}
            value={ImageUrls.ConstructionPlans}
            name="construction-plans"
            style={{ color: 'black' }}
          />
          <img
            alt="construction plans"
            src={ImageUrls.ConstructionPlans}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={6}>
          <Radio
            checked={bgImageUrl === ImageUrls.Sanding}
            onChange={handleChange}
            value={ImageUrls.Sanding}
            name="construction-plans"
            style={{ color: 'black' }}
          />
          <img
            alt="sanding"
            src={ImageUrls.Sanding}
            className={classes.image}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <Radio
            checked={bgImageUrl === ImageUrls.Paint}
            onChange={handleChange}
            value={ImageUrls.Paint}
            name="construction-plans"
            style={{ color: 'black' }}
          />
          <img
            alt="paint"
            src={ImageUrls.Paint}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={6}>
          <Radio
            checked={bgImageUrl === ImageUrls.Workshop}
            onChange={handleChange}
            value={ImageUrls.Workshop}
            name="construction-plans"
            style={{ color: 'black' }}
          />
          <img
            alt="workshop"
            src={ImageUrls.Workshop}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

type ClaimWebsiteProps = {
  open: boolean;
  onClose: () => void;
  urlPath: string;
}

const ClaimWebsiteModal = ({
  onClose,
  open,
  urlPath,
}: ClaimWebsiteProps): JSX.Element => (
  <Dialog
    open={open}
    onClose={onClose}
  >
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" px={5} py={2.5} flex="1 1 auto">
        <Typography variant="h3">
          <Box mb={2}>Claim your domain</Box>
        </Typography>
        <Smidge />
        <Box display="flex" flexDirection="row" alignItems="center">
          <TextField
            id="outlined-read-only-input"
            label="Url Prefix"
            fullWidth
            defaultValue={urlPath}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <Typography variant="h5" style={{ marginLeft: 16 }}>
            .hearthwebsites.com
          </Typography>
        </Box>
        <Smidge />
        <ModalButton
          buttonType="primary"
          type="submit"
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            marginLeft: 'auto',
            marginRight: 0,
          }}
        >
          {/* Localhost: opens blank page */}
          <a href={`https://${urlPath}.hearthwebsites.com`} target="_blank" rel="noreferrer">Go to your website</a>
        </ModalButton>
        <Smidge />
      </Box>
    </Box>
  </Dialog>
);

const WebsiteTemplate = (): JSX.Element | null => {
  const { contractor } = useTools();
  const organization = useSelector(organizationSelector);
  const snack = useSnack();
  const { handleMutationError } = useGql();
  const [bgImageUrl, setBgImageUrl] = useState<string>(organization.backgroundPicture !== 'general' ? organization.backgroundPicture : ImageUrls.ConstructionPlans);
  const [desOfOperations, setDesOfOperations] = useState<string>(organization.descriptionOfOperations || '');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [updateOrgWebsitesInfoMutation] = useUpdateOrganizationWebsitesInfoMutation();

  const updateWebsitesInfo = useCallback(async () => {
    try {
      await updateOrgWebsitesInfoMutation({
        variables: {
          attributes: {
            descriptionOfOperations: desOfOperations,
            backgroundPicture: bgImageUrl,
          },
        },
      });
      snack.successSnack('Template updated');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, updateOrgWebsitesInfoMutation, desOfOperations, bgImageUrl, snack]);

  // typecheck
  if (!contractor) {
    return null;
  }

  return (
    <Box>
      <ClaimWebsiteModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        urlPath={organization.urlPath}
      />
      <Box
        bgcolor="common.basic100"
        borderRadius="20px"
        p={5}
        maxWidth={1024}
        mb={5}
      >
        <Typography variant="h2" color="textPrimary">
          Publicize Your Business
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" style={{ marginTop: 8 }}>
            Create a unique website for your business with one click!
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
            Claim your domain
          </Button>
        </Box>
      </Box>
      <Form
        initialValues={{}}
        onSubmit={updateWebsitesInfo}
      >
        <Box
          bgcolor="common.basic100"
          borderRadius="20px"
          p={5}
          maxWidth={1024}
        >
          <Typography variant="h2" color="textPrimary">
            Customize Your Website
          </Typography>
          <Typography variant="body2" style={{ marginTop: 8 }}>
            Get a unique website for your own business.
          </Typography>
          <Box mt={3}>
            <TextField
              id="outlined-multiline-static"
              label="Business and Service Description"
              placeholder="Welcome your customers and inform them about the services you offer. This
                will be the first text that your visitors will see!"
              multiline
              fullWidth
              rows={5}
              defaultValue={desOfOperations}
              onChange={e => setDesOfOperations(e.target.value)}
              variant="outlined"
            />
          </Box>
          <Box mt={5} mb={2}>
            Choose a background image for your website
          </Box>
          <ImageGrid bgImageUrl={bgImageUrl} setBgImageUrl={setBgImageUrl} />
          <Box mt={6}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <SubmitButton
                variant="contained"
                buttonType="common.primary"
                disableOnInitialValues={false}
              >
                Save Customization
              </SubmitButton>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default WebsiteTemplate;
