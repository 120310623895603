import {
  Box,
  Typography,
  Portal,
  useMediaQuery,
  Theme,
} from '@mui/material';

import { useRefs } from '../useContractWizard';
import WizardStepper from './WizardStepper';

type Props = {
  title: string;
  stepIndex?: number;
}

const WizardHeader = ({ title, stepIndex }: Props): JSX.Element => {
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { headerRef } = useRefs();

  return (
    <Portal container={headerRef}>
      {stepIndex && isMobileOrSmall &&
        <Box mt={1} mb={2} display="flex" flexDirection="row" justifyContent="flex-end">
          <WizardStepper number={stepIndex} total={2} />
        </Box>
      }
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography variant={isMobileOrSmall ? 'h3' : 'h2'}>
          {title}
        </Typography>
        {stepIndex && !isMobileOrSmall &&
          <Box my={1}>
            <WizardStepper number={stepIndex} total={2} />
          </Box>
        }
      </Box>
    </Portal>
  );
};

export default WizardHeader;
