import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  Box,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Grow,
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Theme,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LaunchIcon from '@mui/icons-material/Launch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useHistory, useLocation } from 'react-router-dom';
import { camelizeKeys } from 'humps';
import { parse } from 'query-string';
import { useMount } from 'react-use';
import { snakeCase } from 'change-case';
import { format } from 'date-fns';

import useLogEvent from 'common/hooks/useLogEvent';
import { Button, Dialog } from 'common/components/material-ui';
import { ToggleRow } from 'common/components/widgets';
import { percentFormat, currencyFormatFromCents } from 'common/utils/stringFormatters';
import { useSnack } from 'common/utils/snackCart';
import { ExportPaymentData } from 'types';
import Icon from 'common/components/Icon';

import {
  PaymentAttributeType,
  HearthPaySettingsProvider,
  useHearthPaySettings,
  useHearthPaySettingsActions,
} from './useHearthPaySettings';
import { GreyContentCard, GreyRowCard } from './GreyCard';

const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    maxHeight: 500,
    overflowY: 'auto',
    marginTop: 16,
    marginBottom: 16,
  },
  row: {
    borderBottom: `1px solid ${theme.palette.common.basic500}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.common.basic300,
    padding: 16,
    '&:first-child': {
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
    '&:last-child': {
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottom: 'none',
    },
  },
}));

type Query = {
  quickbooks: 'true';
}

const HearthPaySettings = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const snack = useSnack();
  const query = camelizeKeys(parse(location.search)) as Query;
  const [exportPayoutDataLoading, setExportPayoutDataLoading] = useState(false);
  const [exportPaymentDataLoading, setExportPaymentDataLoading] = useState(false);
  const [quickbooksDialogOpen, setQuickbooksDialogOpen] = useState(false);
  const [confirmChangeAttribute, setConfirmChangeAttribute] =
    useState<PaymentAttributeType | null>(null);
  const [quickbooksEnabledDialogOpen, setQuickbooksEnabledDialogOpen] = useState(
    query.quickbooks === 'true',
  );
  const {
    stripeConnectAccount,
    contractor,
    contractorReminders,
    homeownerReminders,
    quickbooksBusiness,
    outstandingConnectInvoices,
  } = useHearthPaySettings();
  const {
    toggleAcceptedPayments,
    toggleReminder,
    toggleQuickbooksSync,
    exportPaymentsData,
  } = useHearthPaySettingsActions();
  const [showAutomatedReminders, setShowAutomatedReminders] = useState(false);
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'hearth_pay' });
  });

  const stripeConnectAccountRef = useRef(stripeConnectAccount);

  useEffect(() => {
    if (stripeConnectAccountRef.current === null) {
      stripeConnectAccountRef.current = stripeConnectAccount;
    }
    if (stripeConnectAccount !== null) {
      const differences = (
        Object.keys(stripeConnectAccount) as Array<keyof typeof stripeConnectAccount>).filter(k => (
        k !== 'externalTransferDestinations' &&
        k !== '__typename' &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        stripeConnectAccount[k] !== stripeConnectAccountRef.current![k]
      )).map(k => snakeCase(k));
      // All settings are toggled so only be one element should be in differences array at a time
      if (differences.length === 1) {
        itly.changeSetting({ fields: differences });
      }
      stripeConnectAccountRef.current = stripeConnectAccount;
    }
  }, [stripeConnectAccount, itly]);

  const paymentMethodStatuses = useMemo(() => {
    if (!stripeConnectAccount) return {};
    return {
      achEnabled: stripeConnectAccount.achEnabled,
      creditCardEnabled: stripeConnectAccount.creditCardEnabled,
      debitCardEnabled: stripeConnectAccount.debitCardEnabled,
    };
  }, [stripeConnectAccount]);

  const maybeToggleAcceptedPayments = useCallback((attribute: PaymentAttributeType) => {
    // make sure disabling payment method won't leave no payment methods allowed
    const enabledPaymentMethods = Object.entries(paymentMethodStatuses)
      .filter(method => method[1] === true);
    if (enabledPaymentMethods.length === 1 && enabledPaymentMethods[0][0] === attribute) {
      snack.errorSnack('At least one payment method must be selected');
      return;
    }
    if ((outstandingConnectInvoices || []).length === 0) {
      toggleAcceptedPayments(attribute);
      return;
    }
    setConfirmChangeAttribute(attribute);
  }, [paymentMethodStatuses, outstandingConnectInvoices, snack, toggleAcceptedPayments]);

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <MaterialDialog
        open={quickbooksEnabledDialogOpen}
        onClose={() => setQuickbooksEnabledDialogOpen(false)}
      >
        <DialogContent>
          <Box color="common.success700" display="flex" alignItems="top">
            <Box mr={3}>
              <Icon
                name="check-circle"
                size={36}
                bgColor="success500"
                color="basic100"
              />
            </Box>
            <Box>
              <Box mb={3}>
                <Typography variant="h3">
                  QuickBooks is now enabled
                </Typography>
              </Box>
              <DialogContentText>
                All of your paid invoices will now be automatically synced.
              </DialogContentText>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setQuickbooksEnabledDialogOpen(false)}
          >
            Awesome
          </Button>
        </DialogActions>
      </MaterialDialog>
      <Dialog
        open={confirmChangeAttribute !== null}
        onClose={() => setConfirmChangeAttribute(null)}
      >
        <Box py={2}>
          <ErrorOutlineIcon fontSize="large" />
          <Box m={1} />
          <Typography variant="h3">
            You are trying to update your payment settings and you have
            {outstandingConnectInvoices?.length && outstandingConnectInvoices?.length > 1 ?
              ' outstanding invoices.' :
              ' an outstanding invoice.'
            }
          </Typography>
          <Box m={1} />
          <Typography variant="body1">
            This settings change will not be reflected on the following
            {outstandingConnectInvoices?.length && outstandingConnectInvoices?.length > 1 ?
              ' invoices.' :
              ' invoice.'
            }
            {' '}Please press “Confirm” to save your settings.
          </Typography>
          <Box className={classes.scrollContainer}>
            {outstandingConnectInvoices?.map(invoice => (
              <Box className={classes.row}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      {invoice.dueDate ?
                        format(Date.parse(invoice.dueDate), 'MM/dd/yyyy') :
                        'Scheduled'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1">{invoice.fullName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" align="right">
                      {currencyFormatFromCents(invoice.amountRequested, 2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Button
              buttonType="common.primary"
              variant="contained"
              onClick={() => {
                if (confirmChangeAttribute) {
                  toggleAcceptedPayments(confirmChangeAttribute);
                }
                setConfirmChangeAttribute(null);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Typography variant="h2" color="textPrimary">
        Hearth Pay Settings
      </Typography>
      <Box mt={3} />
      {!stripeConnectAccount ?
        <Skeleton variant="rectangular" width="100%" height={100} /> :
        <>
          {contractor?.paymentsRole === 'Admin' &&
            <>
              <GreyContentCard
                title="Credit Card Payments"
                subtitle={
                  <>
                    Credit Card Payment fee is{' '}
                    {percentFormat(stripeConnectAccount.cardFeePercentageAmount, 1)}{' '}
                    +{' '}
                    {currencyFormatFromCents(stripeConnectAccount.cardFeeFixedAmount, 2)}{' '}
                    per payment.
                  </>
                }
              >
                <ToggleRow
                  prompt={
                    <Typography variant="body1" color="textPrimary">
                      Accept Credit Card Payments
                    </Typography>
                  }
                  name="creditCardEnabled"
                  value={stripeConnectAccount.creditCardEnabled}
                  onChange={() => maybeToggleAcceptedPayments('creditCardEnabled')}
                  showLabel={false}
                />
                <Box my={3}>
                  <Divider />
                </Box>
                <ToggleRow
                  showLabel={false}
                  prompt={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" color="textPrimary">
                        Pass Credit Card Fees to your Customer
                      </Typography>
                      {!stripeConnectAccount.allowPassingCreditCardFeesToCustomer &&
                      <Tooltip
                        title="Passing fees for credit card transactions is not permitted in
                              your state per state regulations"
                      >
                        <Box display="flex" alignItems="center" color="common.basic900" ml={1}>
                          <InfoOutlinedIcon />
                        </Box>
                      </Tooltip>
                      }
                    </Box>
                  }
                  name="customerPaysCardFee"
                  value={stripeConnectAccount.customerPaysCardFee}
                  onChange={() => maybeToggleAcceptedPayments('customerPaysCardFee')}
                  disabled={!stripeConnectAccount.allowPassingCreditCardFeesToCustomer}
                />
              </GreyContentCard>
              <Box mt={3} />
              <GreyContentCard
                title="Debit Card Payments"
                subtitle={
                  <>
                    Debit Card Payment fee is{' '}
                    {percentFormat(stripeConnectAccount.cardFeePercentageAmount, 1)}{' '}
                    +{' '}
                    {currencyFormatFromCents(stripeConnectAccount.cardFeeFixedAmount, 2)}{' '}
                    per payment.
                  </>
                }
              >
                <ToggleRow
                  showLabel={false}
                  prompt={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" color="textPrimary">
                        Accept Debit Card Payments
                      </Typography>
                      <Tooltip
                        title="Passing fees to your customer for debit card
                          transactions is not allowed in your state per state law"
                      >
                        <Box display="flex" alignItems="center" color="common.basic900" ml={1}>
                          <InfoOutlinedIcon />
                        </Box>
                      </Tooltip>
                    </Box>
                  }
                  name="debitCardEnabled"
                  value={stripeConnectAccount.debitCardEnabled}
                  onChange={() => maybeToggleAcceptedPayments('debitCardEnabled')}
                />
              </GreyContentCard>
              <Box mt={3} />
              <GreyContentCard
                title="ACH / E-Check Payments"
                subtitle={
                  <>
                    ACH Payment fee is{' '}
                    {currencyFormatFromCents(stripeConnectAccount.achFeeFixedAmount, 2)}{' '}
                    per payment.
                  </>
                }
              >
                <ToggleRow
                  showLabel={false}
                  prompt={
                    <Typography variant="body1" color="textPrimary">
                      Accept ACH Payments
                    </Typography>
                  }
                  name="achEnabled"
                  value={stripeConnectAccount.achEnabled}
                  onChange={() => maybeToggleAcceptedPayments('achEnabled')}
                />
                <Box my={3}>
                  <Divider />
                </Box>
                <ToggleRow
                  showLabel={false}
                  prompt={
                    <Typography variant="body1" color="textPrimary">
                      Pass ACH Fees to your Customer
                    </Typography>
                  }
                  name="customerPaysAchFee"
                  value={stripeConnectAccount.customerPaysAchFee}
                  onChange={() => maybeToggleAcceptedPayments('customerPaysAchFee')}
                />
              </GreyContentCard>
              <Box mt={3} />
              <GreyRowCard title="Hearth Pay Account">
                <Button
                  buttonType="common.primary"
                  variant="contained"
                  onClick={() => (
                    history.push('/dashboard/settings/hearth-pay/account-info')
                  )}
                >
                  Edit account
                </Button>
              </GreyRowCard>
              <Box mt={3} />
              <GreyRowCard title="Payout Details">
                <Button
                  buttonType="common.primary"
                  variant="contained"
                  onClick={() => history.push('/dashboard/payments/add_payout_details')}
                >
                  Edit payout info
                </Button>
              </GreyRowCard>
              <Box mt={3} />
              <GreyContentCard
                title="Reporting"
                subtitle={
                  <>
                    Email {contractor?.email} a CSV of your Hearth Pay data.
                  </>
                }
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">
                      Export payout history
                    </Typography>
                    <Button
                      buttonType="common.primary"
                      variant="contained"
                      loading={exportPayoutDataLoading}
                      onClick={async () => {
                        setExportPayoutDataLoading(true);
                        try {
                          await exportPaymentsData(ExportPaymentData.PAYOUT);
                        } finally {
                          setExportPayoutDataLoading(false);
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          Export
                          <Box ml={1} display="flex" alignItems="center">
                            <LaunchIcon color="secondary" />
                          </Box>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">
                      Export payment history
                    </Typography>
                    <Button
                      buttonType="common.primary"
                      variant="contained"
                      loading={exportPaymentDataLoading}
                      onClick={async () => {
                        setExportPaymentDataLoading(true);
                        try {
                          await exportPaymentsData(ExportPaymentData.PAYMENT);
                        } finally {
                          setExportPaymentDataLoading(false);
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          Export
                          <Box ml={1} display="flex" alignItems="center">
                            <LaunchIcon color="secondary" />
                          </Box>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </GreyContentCard>
              <Box mb={3} />
            </>
          }
          <GreyRowCard
            title="Automated Payment Reminders"
            {...(showAutomatedReminders ? { borderRadius: '8px 8px 0 0' } : {})}
          >
            {showAutomatedReminders ?
              <IconButton
                aria-label="expand-less"
                onClick={() => setShowAutomatedReminders(false)}
                size="large"
              >
                <ExpandLessIcon color="disabled" fontSize="large" />
              </IconButton> :
              <IconButton
                aria-label="expand-more"
                onClick={() => setShowAutomatedReminders(true)}
                size="large"
              >
                <ExpandMoreIcon color="disabled" fontSize="large" />
              </IconButton>
            }
          </GreyRowCard>
          {showAutomatedReminders &&
            <Grow in={showAutomatedReminders} timeout={650}>
              <Box>
                <Divider />
                <GreyContentCard
                  title="Remind Me"
                  subtitle="Mobile app notification about payments due"
                  pb={2}
                  borderRadius={0}
                >
                  {contractorReminders.map(reminder => (
                    <Box key={reminder.id}>
                      <ToggleRow
                        showLabel={false}
                        prompt={
                          <Typography variant="body1" color="textPrimary">
                            {reminder.settingName}
                          </Typography>
                        }
                        value={reminder.enabled}
                        onChange={() => toggleReminder(reminder)}
                      />
                      <Box my={3}>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                </GreyContentCard>
                <GreyContentCard
                  title="Remind My Client"
                  subtitle="Email or text message about payments due"
                  pt={0}
                  pb={4}
                  borderRadius="0 0 8px 8px"
                >
                  {homeownerReminders.map(reminder => (
                    <Box key={reminder.id}>
                      <ToggleRow
                        showLabel={false}
                        prompt={
                          <Typography variant="body1" color="textPrimary">
                            {reminder.settingName}{' '}
                            {reminder.medium === 'email' ? '(Email)' : '(Text)'}
                          </Typography>
                        }
                        value={reminder.enabled}
                        onChange={() => toggleReminder(reminder)}
                      />
                      <Box my={3}>
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                </GreyContentCard>
              </Box>
            </Grow>
          }
          {contractor?.role === 'Admin' &&
            <>
              <Box mt={3} />
              <Dialog
                open={quickbooksDialogOpen}
                onClose={() => setQuickbooksDialogOpen(false)}
              >
                <DialogTitle>QuickBooks setup</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Your QuickBooks account is not connected with Hearth.
                    Continue below to get started.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setQuickbooksDialogOpen(false)}
                    buttonType="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    href="/quickbooks/setup"
                    target="_blank"
                    onClick={() => setQuickbooksDialogOpen(false)}
                    autoFocus
                  >
                    Set Up QuickBooks
                  </Button>
                </DialogActions>
              </Dialog>
              <GreyContentCard
                title="QuickBooks Sync"
                subtitle=""
              >
                <ToggleRow
                  showLabel={false}
                  prompt={
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" color="textPrimary">
                        Sync with QuickBooks
                      </Typography>
                    </Box>
                  }
                  name="quickbooksEnabled"
                  value={quickbooksBusiness?.autoSyncInvoices || false}
                  onChange={() => {
                    if (!quickbooksBusiness?.id) {
                      setQuickbooksDialogOpen(true);
                    } else {
                      toggleQuickbooksSync();
                      itly.changeSetting({ fields: ['auto_sync_invoices'] });
                    }
                  }}
                />
              </GreyContentCard>
            </>
          }
        </>
      }
    </Box>
  );
};

export default (): JSX.Element => (
  <HearthPaySettingsProvider>
    <HearthPaySettings />
  </HearthPaySettingsProvider>
);
