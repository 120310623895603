import { Children, useState } from 'react';
import { Box, Fab, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    position: 'relative',
    height: '100%',
  },
  fabRootLeft: {
    backgroundColor: theme.palette.common.basic100,
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: -20,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      left: -15,
    },
  },
  fabRootRight: {
    backgroundColor: theme.palette.common.basic100,
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    right: -20,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      right: -15,
    },
  },
}));

type CarouselType = {
  children: JSX.Element[] | JSX.Element;
  className?: string;
}

const Carousel = ({ children, className }: CarouselType): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();

  const setCurrentIndexWithIncrement = (index: number, incrementBy: number): void => {
    const childrenCount = Children.count(children);
    if ((index === 0) && (incrementBy === -1)) {
      setCurrentIndex(childrenCount - 1);
      return;
    }
    if ((index === childrenCount - 1) && incrementBy === 1) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(index + incrementBy);
  };

  return (
    <Box className={cx(classes.carouselContainer, className)}>
      <Fab
        classes={{ root: classes.fabRootLeft }}
        onClick={() => {
          setCurrentIndexWithIncrement(currentIndex, -1);
        }}
      >
        <ChevronLeft />
      </Fab>
      <Fab
        classes={{ root: classes.fabRootRight }}
        onClick={() => {
          setCurrentIndexWithIncrement(currentIndex, 1);
        }}
      >
        <ChevronRight />
      </Fab>
      {Children.map(children, (child, i) => (
        <Fade in={i === currentIndex} style={{ position: 'absolute', width: '100%', height: '100%' }}>
          {child}
        </Fade>
      ))}
    </Box>
  );
};

export default Carousel;
