
import { useCallback } from 'react';
import { useField } from 'formik';
import {
  Box,
  Typography,
  IconButton,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';

import { OrganizationTheme, Background, InvoiceTemplateType } from 'types';
import { colors } from 'contractor/modules/CustomInvoice/templates/theme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.basic100,
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: 16,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  colorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    width: 42,
    height: 42,
  },
  patternBox: {
    position: 'absolute',
    zIndex: 100,
    color: 'inherit',
  },
  colorButton: {
    height: '100%',
    width: '100%',
  },
}));

// maybe the background picker should just live here, since it's conditional anyway??
const BackgroundPicker = (): JSX.Element => {
  const classes = useStyles();

  const [{ value: bgValue }, , { setValue: setBgValue }] = useField<Background>('background');
  const [{ value: primaryColorValue }] = useField<OrganizationTheme>('primaryColor');

  const selectBackground = useCallback((background: Background) => {
    setBgValue(background);
  }, [setBgValue]);

  return (
    <>
      <Box color="common.primary500" mb={2}>
        <Typography variant="h3" color="inherit">
          Background
        </Typography>
      </Box>
      <Box className={classes.colorsContainer} justifyContent="flex-start">
        <Box
          className={classes.colorBox}
          bgcolor={colors[primaryColorValue]}
          color={colors[primaryColorValue]}
        >
          <IconButton
            disableRipple
            aria-label={`select-solid-bg-${primaryColorValue}`}
            onClick={() => selectBackground(Background.SOLID)}
            className={classes.colorButton}
            size="large"
          >
            {bgValue === Background.SOLID ?
              <CheckIcon fontSize="large" color="secondary" /> : null
            }
          </IconButton>
        </Box>
        <Box
          className={classes.colorBox}
          position="relative"
          color={colors[primaryColorValue]}
        >
          <CardMedia
            className={classes.colorBox}
            component="img"
            image={
              'https://hearth.imgix.net/contractor-v2/' +
            `custom_invoice/tools-button-${primaryColorValue}.png`
            }
            style={{
              opacity: (bgValue === Background.PATTERN) ? 0.5 : 1,
            }}
          />
          <IconButton
            disableRipple
            aria-label={`select-solid-bg-${primaryColorValue}`}
            onClick={() => selectBackground(Background.PATTERN)}
            className={classes.patternBox}
            size="large"
          >
            {bgValue === Background.PATTERN ?
              <CheckIcon fontSize="large" color="inherit" /> : null
            }
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const ColorPicker = (): JSX.Element => {
  const classes = useStyles();

  // NOTE: need to assert type here since Object.keys is of type string[] by design:
  // https://github.com/Microsoft/TypeScript/issues/12870#issuecomment-266637861
  const primaryColorOptions = Object.keys(colors) as OrganizationTheme[];

  const [{ value }, , { setValue }] = useField<OrganizationTheme>('primaryColor');
  const [{ value: templateType }] = useField<InvoiceTemplateType>('templateType');

  return (
    <Box className={classes.root}>
      <Box color="common.primary500">
        <Typography variant="h3" color="inherit">
          Color
        </Typography>
      </Box>
      <Box mt={2} />
      <Box className={classes.colorsContainer} justifyContent="space-between">
        {primaryColorOptions.map(colorKey => (
          <Box
            className={classes.colorBox}
            bgcolor={colors[colorKey]}
            color={colors[colorKey]}
          >
            <IconButton
              disableRipple
              aria-label={`select-color-${colorKey}`}
              className={classes.colorButton}
              onClick={() => setValue(colorKey)}
              size="large"
            >
              {colorKey === value ?
                <CheckIcon fontSize="large" color="secondary" /> : null
              }
            </IconButton>
          </Box>
        ))}
      </Box>
      {templateType === InvoiceTemplateType.FUN_TEMPLATE &&
        <Box mt={5}>
          <BackgroundPicker />
        </Box>
      }
    </Box>
  );
};

export default ColorPicker;
