import { useRef } from 'react';
import {
  Box,
  BoxProps,
  useTheme,
  Popover as MuiPopover,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PopoverProps } from '@mui/material/Popover';

const useStyles = makeStyles(theme => ({
  anchorEl: {
    zIndex: theme.drawerZIndex + 5,
    backgroundColor: theme.palette.common.basic100,
    padding: 4,
    borderRadius: 8,
  },
  // reference: https://github.com/mui-org/material-ui/blob/latest/docs/src/pages/components/popper/ScrollPlayground.js
  // TODO(onboarding): look into how to pass props into nested JSS that references another class
  paper: {
    width: 400,
    marginTop: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
}));

const Arrow = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        mt: '1.25',
        '&::before': {
          backgroundColor: theme.palette.common.basic100,
          content: '""',
          display: 'block',
          position: 'absolute',
          transform: 'rotate(45deg)',
          width: 30,
          height: 30,
          top: 100,
          right: 17,
        },
      }}
    />
  );
};

const MobileArrow = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        '&::before': {
          backgroundColor: theme.palette.common.basic100,
          content: '""',
          display: 'block',
          position: 'absolute',
          transform: 'rotate(45deg)',
          width: 24,
          height: 24,
          top: 253,
          right: 0,
          marginRight: '10%',
        },
      }}
    />
  );
};

export type Props = {
  children?: React.ReactNode;
  anchorEl: React.ReactNode;
  containerProps?: Partial<BoxProps>;
  overlayProps?: Partial<BoxProps>;
  onBackgroundClick?: () => void;
  arrow?: boolean;
  backdrop?: boolean;
  topOffset?: number;
  leftOffset?: number;
  showAnchor?: boolean;
  exactWidth?: boolean;
  popperStyles?: React.CSSProperties;
} & Omit<PopoverProps, 'anchorEl'>;

const Popover = ({
  children,
  containerProps,
  overlayProps,
  onBackgroundClick = () => {},
  anchorEl,
  arrow = true,
  backdrop = true,
  exactWidth = true,
  showAnchor = true,
  id,
  popperStyles,
  ...popoverProps
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  return (
    <>
      <div
        ref={anchorRef}
        className={classes.anchorEl}
        id="anchorElClass"
      >
        {anchorEl}
      </div>
      {children &&
        <MuiPopover
          onClose={onBackgroundClick}
          anchorEl={anchorRef.current}
          PaperProps={{
            classes: {
              root: classes.paper,
            },
            sx: {
              '&.MuiPaper-root': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 16,
                marginTop: 2,
                zIndex: theme.drawerZIndex + 1,
                paddingBottom: '22px',
                // left in px to prevent overflow action in mobile
                ...popperStyles,
              },
            },
          }}
          {...popoverProps}
        >
          {isMobile ? <MobileArrow /> : <Arrow />}
          <Box {...overlayProps}>
            {children}
          </Box>
        </MuiPopover>
      }
    </>
  );
};

export default Popover;
