import { Box, Typography } from '@mui/material';
import { string, object } from 'yup';
import { decode } from 'html-entities';

import { Form, SubmitButton, InputField } from 'common/components/formik';
import { useSnack } from 'common/utils/snackCart';
import { useGql } from 'common/hooks';

import useTools, { useToolsActions } from './useTools';

type Values = {
  sendFinancingLinkText: string;
  signatureText: string;
}

const FinancingTemplates = (): JSX.Element | null => {
  const { contractor } = useTools();
  const { setTemplate } = useToolsActions();
  const snack = useSnack();
  const { handleMutationError } = useGql();

  // typecheck
  if (!contractor) {
    return null;
  }

  const sendFinancingLinkTemplate = contractor?.sendFinancingLinkTemplate;
  const signatureTemplate = contractor?.signatureTemplate;

  return (
    <Form<Values>
      initialValues={{
        sendFinancingLinkText: decode(sendFinancingLinkTemplate.templateString),
        signatureText: decode(signatureTemplate.templateString),
      }}
      validationSchema={object().shape({
        sendFinancingLinkText: string().label('Send Financing Link Text').required(),
        signatureText: string().label('Signature Text').required(),
      })}
      onSubmit={async ({ sendFinancingLinkText, signatureText }) => {
        try {
          await Promise.all([
            setTemplate(sendFinancingLinkTemplate.id, sendFinancingLinkText),
            setTemplate(signatureTemplate.id, signatureText),
          ]);
          snack.successSnack('Templates updated');
        } catch (e) {
          handleMutationError(e, {});
        }
      }}
    >
      <Box
        bgcolor="common.basic100"
        borderRadius="20px"
        p={5}
        maxWidth={1024}
      >
        <Typography variant="h2" color="textPrimary">
          Financing Templates
        </Typography>
        <Typography variant="body2" style={{ marginTop: 8 }}>
          These templates will be used when sending out your financing link.
          Any edits to these templates will be universal.
        </Typography>
        <Box mt={3}>
          <InputField
            name="sendFinancingLinkText"
            label="Financing link SMS template"
            multiline
            rows={5}
          />
        </Box>
        <Box mt={3}>
          <InputField
            name="signatureText"
            label="Email signature template"
            multiline
            rows={3}
          />
        </Box>
        <Box mt={3}>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <SubmitButton
              variant="contained"
              buttonType="common.primary"
            >
              Save Changes
            </SubmitButton>
          </Box>
        </Box>
      </Box>
    </Form>
  );
};

export default FinancingTemplates;
