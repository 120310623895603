import {
  Box,
  Divider,
  Grid,
  CardMedia,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';

import { OfferDetailsFragment } from 'types';
import { currencyFormat, percentFormat } from 'common/utils/stringFormatters';
import { Tooltip } from 'common/components/modals';
import { Icon } from 'common/components';
import { Dialog, Button } from 'common/components/material-ui';

import OfferBox from './OfferBox';
import { useSortingOffers } from '../useSortingOffers';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    '&:hover': {
      boxShadow: '0px 3px 8px rgba(38, 53, 71, 0.2)',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 4,
    },
  },
  lenderLogo: {
    height: 48,
    maxWidth: 100,
    objectFit: 'contain',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      objectFit: 'scale-down',
    },
    [theme.breakpoints.down('sm')]: {
      height: 32,
    },
  },
  divider: {
    color: 'common.basic300',
  },
  topInfo: {
    marginBottom: 24,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
  },
  bottomInfo: {
    margin: '8px 0',
  },
  disclosure: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  securedImg: {
    height: 18,
    width: 18,
    marginRight: 8,
  },
  oneMain: {
    alignSelf: 'center',
  },
  verticalDivider: {
    height: 50,
    width: 1,
    backgroundColor: theme.palette.common.basic300,
  },
  mobileItem: {
    flex: 1,
  },
  socialProofBox: {
    borderRadius: '0 0 4px 4px',
    backgroundColor: theme.palette.common.primary500,
    padding: '16px 8px 8px',
    width: '100%',
    marginTop: -8,
    zIndex: 1,
    position: 'relative',
  },
  mobileInfoRow: {
    border: `1px solid ${theme.palette.common.basic300}`,
    display: 'flex',
    flex: 1,
    padding: '8px 16px',
  },
}));

type OneMainProps = {
  secured: boolean;
};

const OneMainDisclosure = ({ secured }: OneMainProps) => (
  <Box display="flex" flexDirection="row" mr={2} alignItems="center">
    {secured ?
      <>
        <Box mr={1}>
          <Icon
            name="lock"
            color="basic100"
            bgColor="info500"
            size={18}
          />
        </Box>
        <Typography variant="caption" color="textPrimary" align="center">
          Secured loan - requires car collateral & a branch visit
        </Typography>
      </> :
      <>
        <Box mr={1}>
          <Icon
            name="bank"
            color="basic100"
            bgColor="info500"
            size={18}
          />
        </Box>
        <Typography variant="caption" color="textPrimary" align="center">
          Requires branch visit
        </Typography>
      </>
    }
  </Box>
);

const SecuredDisclosure = () => (
  <Box display="flex" flexDirection="row" mr={2} alignItems="center">
    <Box mr={1}>
      <Icon
        name="lock"
        color="basic100"
        bgColor="info500"
        size={18}
      />
    </Box>
    <Typography variant="caption" color="textPrimary" align="center">
      Secured loan - requires car collateral
    </Typography>
  </Box>
);

export type OfferInfoItemProps = {
  infoItem: {
    header: string;
    desc: string;
    autopay?: boolean | null;
  };
  className?: string;
}

export const OfferInfoItem = ({ infoItem, className = '' }: OfferInfoItemProps): JSX.Element => (
  <Box display="flex" flexDirection="column" className={className}>
    <Typography variant="body2" color="textSecondary">
      {infoItem.header}
    </Typography>
    <Box display="flex" flexDirection="row" alignItems="baseline">
      <Typography variant="h4" color="textPrimary">
        {infoItem.desc}
      </Typography>
      {infoItem.autopay &&
        <Typography variant="caption" color="textSecondary">
          <Box pl={1} fontWeight="fontWeightNormal" display="inline">
            w/ autopay
          </Box>
        </Typography>
      }
    </Box>
  </Box>
);

type DisclosureProps = {
  offer: OfferDetailsFragment;
}

const LenderDisclosure = ({ offer }: DisclosureProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="body"
      >
        <Box p={{ xs: 2, sm: 5 }}>
          <Typography variant="subtitle1" color="textPrimary">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: offer.disclosure }}
            />
          </Typography>
        </Box>
      </Dialog>
      <Button
        variant="text"
        buttonType="common.basic"
        onClick={() => setOpen(true)}
      >
        <Box color="common.basic700">
          {offer.autopay && '*'}Lending partner disclosures
        </Box>
      </Button>
    </>
  );
};

type Props = {
  offer: OfferDetailsFragment;
  socialProof: boolean;
  topOffer?: boolean;
}

const LoanOfferBox = ({
  offer,
  socialProof,
  topOffer = false,
}: Props): JSX.Element => {
  const { processState: { monthlyPaymentSort } } = useSortingOffers();
  const classes = useStyles();
  const loanTerm = offer.loanTermInMonths / 12;

  const offerData: OfferInfoItemProps['infoItem'][] = [
    {
      header: 'Loan Amount',
      desc: currencyFormat(offer.loanAmount),
    },
    {
      header: `Per Month${offer.autopay ? '*' : ''}`,
      desc: currencyFormat(offer.monthlyPayment),
    },
    {
      header: `APR${offer.autopay ? '*' : ''}`,
      desc: `${percentFormat(offer.apr, 2)}`,
      autopay: offer.autopay,
    },
    {
      header: `Term${offer.autopay ? '*' : ''}`,
      desc: `${Number.isInteger(loanTerm) ? loanTerm : loanTerm.toFixed(2)} years`,
    },
  ];

  return (
    <>
      <OfferBox
        buttonCta={
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="secondary">
              Choose
            </Typography>
            <Box mx={1} />
            <Icon name="right-arrow" color="basic100" size={12} />
          </Box>
        }
        offerMetaData={{ loanAmount: offer.loanAmount }}
        chooseLink={`${offer.redirectUrl}?utm_medium=web`}
        className={classes.root}
        rootStyles={
          socialProof ? {
            zIndex: 10,
            position: 'relative',
          } : {}
        }
        mobileDisclosure={
          <Box mt={2} sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <LenderDisclosure offer={offer} />
          </Box>
        }
        //  green border if:
        //  1) sort type is score,
        //  2) the offer is in range, and
        //  3) it's a top offer (because there may be more than 8)
        {...((offer.inMonthlyPaymentRange && topOffer && monthlyPaymentSort) || socialProof ?
          { borderColor: socialProof ? 'primary500' : 'success500' } : {}
        )}
      >
        <Box sx={{ display: { xs: 'flex', sm: 'none', flexDirection: 'column' } }}>
          <Box mb={2} alignSelf="flex-start">
            <CardMedia
              className={classes.lenderLogo}
              component="img"
              alt="lender logo"
              image={
                `https://hearth.imgix.net/lenders/left-align/${offer.lenderImageSlug}.png?auto=compress`
              }
            />
          </Box>
          <Box className={classes.mobileInfoRow}>
            <OfferInfoItem infoItem={offerData[0]} className={classes.mobileItem} />
            <OfferInfoItem infoItem={offerData[1]} className={classes.mobileItem} />
          </Box>
          <Box className={classes.mobileInfoRow} my={1}>
            <OfferInfoItem infoItem={offerData[2]} className={classes.mobileItem} />
            <OfferInfoItem infoItem={offerData[3]} className={classes.mobileItem} />
          </Box>
          <Box my={1} />
          {offer.lenderName === 'OneMain' &&
            <Box mb={2}>
              <OneMainDisclosure secured={offer.secure || false} />
            </Box>
          }
          {(offer.lenderName === 'Upgrade' && offer.secure) &&
            <Box mb={2}>
              <SecuredDisclosure />
            </Box>
          }
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex', flexDirection: 'column' }, pr: 2 }}>
          <Box className={classes.topInfo}>
            <Box display="flex" alignSelf="center">
              <CardMedia
                className={classes.lenderLogo}
                component="img"
                image={`https://hearth.imgix.net/lenders/left-align/${offer.lenderImageSlug}.png` +
                  '?auto=compress'}
                alt="lender logo"
              />
            </Box>
            {offerData.map(infoItem => (
              <OfferInfoItem
                key={infoItem.header}
                infoItem={infoItem}
              />
            ))}
          </Box>
          <Divider className={classes.divider} />
          <Grid container spacing={2} className={classes.bottomInfo}>
            {offer.lenderName === 'OneMain' &&
              <Grid item sm={4} className={classes.oneMain}>
                <OneMainDisclosure secured={offer.secure || false} />
              </Grid>
            }
            {(offer.lenderName === 'Upgrade' && offer.secure) &&
              <Grid item sm={4} className={classes.oneMain}>
                <SecuredDisclosure />
              </Grid>
            }
            <Grid item sm={5} className={classes.disclosure}>
              <LenderDisclosure offer={offer} />
            </Grid>
          </Grid>
        </Box>
      </OfferBox>
      {socialProof &&
      <Box className={classes.socialProofBox}>
        <Tooltip
          placement="bottom-left"
          text={
            <small>
              Hearth’s data shows that this APR is lower than 50% of
              pre-qualification rates seen by past Hearth homeowners with similar profiles for
              this loan amount.
            </small>
          }
          className="full-width"
        >
          <Typography color="secondary" variant="subtitle2" align="center">
            Great APR: This offer has a better APR than 50% of offers we’ve seen for profiles
            similar to yours
          </Typography>
        </Tooltip>
      </Box>
      }
    </>
  );
};

export default LoanOfferBox;
