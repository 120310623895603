import { Route, Switch } from 'react-router-dom';

import { GenericNavbar, DashboardNav } from 'main/components/navs';
import { HomeownerFlowNav, PopNavbar, PaymentsNav } from 'contractor/components/navs';

const NavbarRoute = (): JSX.Element => (
  <Switch>
    {/* Render nothing for these routes */}
    <Route
      path={[
        '/setup',
        '/seats/signup',
        '/login',
        '/password',
        '/edit_password',
        '/map',
        '/hearth-course',
        '/payments',
        '/loans/see-offers',
        '/quotes/:quoteId',
      ]}
    />
    <Route
      path={[
        '/homeowner-invoices/:homeownerId',
        '/invoices/:invoiceId',
      ]}
      component={PaymentsNav}
    />
    <Route
      path="/dashboard"
      component={DashboardNav}
    />
    <Route
      path={[
        '/loans/(white-label)*/:partner?',
        '/credit-cards/apply',
      ]}
      component={PopNavbar}
    />
    <Route
      path="/financing/:organizationId"
      component={HomeownerFlowNav}
    />
    <Route component={GenericNavbar} />
  </Switch>
);

export default NavbarRoute;
