import { Box } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { NavDrawerItem, NavDrawerSecondaryItem } from 'common/components/material-ui';
import userFeatureFlags from 'common/graphql/featureFlags';

import {
  useDashboardNavigation,
  NavigationOption,
  useDashboardNavActions,
} from '../useDashboardNavigation';

/**
 *
 * NAV: Business
 *
 * SUBNAV:
 *
 * * Team:
 * - role: Admin or EnterpriseAdmin
 *
 * * Insurance:
 * - Organization is in an insurance eligible state: insuranceEligible
 *
 * * Branding:
 *
 * * Insights:
 * - role: Admin or EnterpriseAdmin
 */

type SubnavData = {
  title: string;
  url: string;
  featureStatus?: string;
};

type SubnavMappingKeys =
  NavigationOption.TEAM |
  NavigationOption.INSURANCE |
  NavigationOption.BRANDING |
  NavigationOption.INSIGHTS |
  NavigationOption.BANKING |
  NavigationOption.LENDING;

type SubnavMapping = Record<SubnavMappingKeys, SubnavData>;

const subnavMapping: SubnavMapping = {
  [NavigationOption.TEAM]: {
    title: 'Team',
    url: '/dashboard/team',
  },
  [NavigationOption.INSURANCE]: {
    title: 'Insurance',
    url: '/dashboard/insurance',
  },
  [NavigationOption.BRANDING]: {
    title: 'Branding',
    url: '/dashboard/tools',
  },
  [NavigationOption.BANKING]: {
    title: 'Banking',
    url: '/dashboard/banking',
    featureStatus: 'Coming Soon',
  },
  [NavigationOption.INSIGHTS]: {
    title: 'Insights',
    url: '/dashboard/insights?source=dashboard',
  },
  [NavigationOption.LENDING]: {
    title: 'Lending',
    url: '/dashboard/lending',
  },
};

const BusinessTab = (): JSX.Element => {
  const { contractor, organization, uiStates: { activeTab } } = useDashboardNavigation();
  const { onNavItemClick } = useDashboardNavActions();
  const { insightsTab, banking, lending } = useReactiveVar(userFeatureFlags);

  const subNavItems = useMemo(() => (
    [
      (contractor?.admin || contractor?.enterpriseAdmin) && NavigationOption.TEAM,
      organization?.insuranceEligible && NavigationOption.INSURANCE,
      NavigationOption.BRANDING,
      (contractor?.admin || contractor?.enterpriseAdmin) &&
        insightsTab &&
        NavigationOption.INSIGHTS,
      banking && NavigationOption.BANKING,
      (contractor?.admin || contractor?.enterpriseAdmin) &&
        lending &&
        NavigationOption.LENDING,
    ].filter(Boolean) as SubnavMappingKeys[]
  ), [contractor, organization?.insuranceEligible, insightsTab, banking, lending]);

  return (
    <Box>
      <Box mt={2}>
        <NavDrawerItem
          title="Business"
          icon={<WorkIcon />}
          color="basic1100"
          active={activeTab === NavigationOption.BUSINESS}
          onClick={() => onNavItemClick(subnavMapping[subNavItems[0]].url)}
        />
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
        {subNavItems.map((subNav) => {
          const { title, url, featureStatus } = subnavMapping[subNav];

          return (
            <NavDrawerSecondaryItem
              key={title}
              title={title}
              color="basic1100"
              onClick={() => onNavItemClick(url)}
              active={activeTab === subNav}
              featureStatus={featureStatus}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default BusinessTab;
