import {
  Box,
  Typography,
  Theme,
  Divider,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { object, string, number } from 'yup';
import HelpIcon from '@mui/icons-material/Help';

import { Button, Tooltip } from 'common/components/material-ui';
import {
  Form,
  SubmitButton,
  SelectField,
  RadioGroupField,
} from 'common/components/formik';
import RadioOption from 'common/components/elements/RadioOption';
import { ContractTemplateActionType } from 'contractor/hooks/useEditContractTemplateReducer';

import { useContractTemplate, useWizard } from '../useContractWizard';
import {
  RadioOptionContainer,
  WizardHeader,
  WizardContent,
} from '../components';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  buttonBox: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
}));

type Values = {
  paymentsPerProject: string;
  fixedPaymentsPerProject: string;
}

// 1 to 10 basically
const paymentsPerProjectOptions = Array.from({ length: 10 }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString(),
}));

const PaymentStep = (): JSX.Element => {
  const classes = useStyles();
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { stepIndex, nextStep, prevStep } = useWizard();
  const { dispatch } = useContractTemplate();

  return (
    <>
      <WizardHeader title={`Step ${stepIndex}: Payment Details`} stepIndex={stepIndex} />
      <WizardContent>
        <Form<Values>
          initialValues={{
            paymentsPerProject: '2',
            fixedPaymentsPerProject: 'yes',
          }}
          validationSchema={object().shape({
            paymentsPerProject: number().label('Payments per project'),
            fixedPaymentsPerProject: string().label('Fixed payments per project'),
          })}
          onSubmit={(values) => {
            if (values.fixedPaymentsPerProject === 'yes') {
              dispatch({
                type: ContractTemplateActionType.UPDATE_DEFAULT_PAYMENT_ROW_COUNT,
                payload: {
                  rowCount: Number(values.paymentsPerProject),
                },
              });
            }
            nextStep();
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography variant={isMobileOrSmall ? 'h3' : 'h2'}>
              How many payments do you collect per project?
            </Typography>
            <Tooltip
              title="We use your response here to include the correct number of payments in the
              Payment Details section of your contract template. When you send a contract to a
              client, you will enter the payment amounts and payment schedule specific to that
              project."
              disableHoverListener={false}
              placement="bottom"
              clone
              bgColor="purple500"
            >
              <Box
                my={1}
                color="common.basic900"
                display="flex"
                alignItems="center"
                style={{ cursor: 'default' }}
              >
                <HelpIcon />
                <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                  Why are you asking me this?
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <Box mt={2} mx={-4}>
              <Divider />
            </Box>
          </Box>
          <Box mt={{ xs: 1, md: 3 }} />
          <RadioGroupField
            name="fixedPaymentsPerProject"
            label={null}
            radioColor="primary500"
            radioSize={21}
            OptionContainer={RadioOptionContainer}
          >
            <RadioOption
              value="yes"
              label={
                <>
                  I always collect
                  <SelectField
                    name="paymentsPerProject"
                    label=""
                    native
                    formControlProps={{
                      style: {
                        display: 'inline',
                        verticalAlign: 'baseline',
                        margin: '0 8px',
                        paddingTop: 5,
                      },
                    }}
                    classes={{ select: classes.select }}
                    items={paymentsPerProjectOptions}
                  />
                  payment(s) per projects.
                </>
              }
            />
            <RadioOption
              value="no"
              label={
              // using fragment so the default styling for string won't be applied
                <>The number of payments I collect varies by project</>
              }
            />
          </RadioGroupField>
          <Box mt={5} />
          <Box mt={4} className={classes.buttonBox}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant="text"
                buttonType="common.purple"
                onClick={prevStep}
                rounded
              >
                Back
              </Button>
            </Box>
            <SubmitButton
              variant="contained"
              buttonType="common.purple"
              rounded
              size="large"
              disableOnInitialValues={false}
              fullWidth={isMobileOrSmall}
            >
              Next step: Terms of use details
            </SubmitButton>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <Button
                variant="text"
                buttonType="common.purple"
                onClick={prevStep}
                rounded
                fullWidth
                className={classes.backButton}
              >
                Back
              </Button>
            </Box>
          </Box>
        </Form>
      </WizardContent>
    </>
  );
};

export default PaymentStep;
