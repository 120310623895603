import { useState, Suspense } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useLazyAction } from 'promise-action';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { recaptchaSiteKey } from 'config/app';
import { sendContactEmail } from 'contractor/actions/contractor';
import { Input, Note, Button } from 'common/components/material-ui';
import { buildMaterialInputParams } from 'common/utils/stepUtils';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';
import { Popup } from 'contractor/components/widgets';
import { Loader } from 'common/components/widgets';
import lazyRetry from 'common/utils/lazyRetry';

const TextEditor = lazyRetry(() => import('common/components/layouts/TextEditor'));

type Props = {
  contractorId: string;
  open: boolean;
  onClose: () => void;
}

const ContactEmailModal = ({
  contractorId,
  open,
  onClose,
}: Props): JSX.Element => {
  const sendEmail = useLazyAction(sendContactEmail);
  const [loading, setLoading] = useState(false);
  const [reCaptchaKey, setReCaptchaKey] = useState('');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { successSnack, errorSnack } = useSnack(
    'Successfully sent email!',
    'An error occurred. Please try again.',
  );

  return (
    <Popup
      open={open}
      onClose={onClose}
      // the z-index on the paywall is 10000, and the z-index
      // on material-ui papger is 1300, but when trying to set
      // it with a class, it gets overwritten, therefore needing to
      // set the z-index with style
      style={{ zIndex: 10001 }}
    >
      <Note
        title="Send email"
        subtitle="Contact"
      >
        <Box mt={2} />
        <Formik
          isInitialValid
          initialValues={{
            replyEmail: '',
            subject: '',
            body: '',
          }}
          validationSchema={object().shape({
            replyEmail: string().label('Email').email().required(),
            subject: string().label('Subject').required(),
            body: string().label('Email body').required(),
          })}
          validateOnChange={false}
          validateOnBlur
          onSubmit={async (values) => {
            try {
              setLoading(true);
              await sendEmail.run({
                contractorId,
                data: {
                  reCaptchaKey,
                  ...values,
                },
              });
              successSnack();
            } catch (error) {
              analytics.trackException(error);
              errorSnack();
            } finally {
              setLoading(false);
            }
            onClose();
          }}
        >
          {({
            handleChange,
            values,
            errors,
            isValidating,
          }) => (
            <Form>
              <Box my={2}>
                <Input
                  fullWidth
                  {...buildMaterialInputParams('replyEmail', values, errors, handleChange)}
                  label="Reply email address"
                  type="email"
                />
              </Box>
              <Box my={2}>
                <Input
                  fullWidth
                  {...buildMaterialInputParams('subject', values, errors, handleChange)}
                />
              </Box>
              <Box mt={2} mb={4}>
                <Suspense fallback={<Loader />}>
                  <TextEditor
                    value={values.body}
                    onChange={(html) => {
                      handleChange({
                        target: {
                          name: 'body',
                          value: html,
                        },
                      });
                    }}
                  />
                </Suspense>
              </Box>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                onChange={(key) => {
                  if (key) {
                    setReCaptchaKey(key);
                  }
                }}
                size={isMobile ? 'compact' : 'normal'}
              />
              <Box mt={2} />
              <Button
                variant="contained"
                buttonType="primary"
                disabled={!reCaptchaKey}
                loading={loading}
                type="submit"
                fullWidth
              >
                Send email
              </Button>
            </Form>
          )}
        </Formik>
      </Note>
    </Popup>
  );
};

export default ContactEmailModal;
