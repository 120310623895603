import { SvgIconProps } from '../Icon';

const HearthCashIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" {...otherProps}>
    <path
      d="M5.96803 15.8811L5.98527 15.8981L6.00702 15.9089L6.04473 15.9275L6.05721 15.9398L6.10104 15.9832H6.16267H6.1924H6.22213H6.28159H21.7411C22.6547 15.9832 23.3775 15.271 23.3775 14.3636V2.63611C23.3775 1.72868 22.6547 1.0165 21.7411 1.0165H2.71403C1.80037 1.0165 1.07754 1.72868 1.07754 2.63611V10.8659L1.07754 10.8704C1.07752 10.8962 1.07749 10.9296 1.08334 10.9643C1.08882 10.9968 1.09854 11.0267 1.11239 11.0575C1.12247 11.1008 1.14603 11.1359 1.17039 11.1599C1.18812 11.1775 1.21142 11.1943 1.23923 11.206L5.96803 15.8811ZM5.8343 12.0416V14.504L2.60343 11.3098H5.0924C5.51659 11.3098 5.8343 11.6264 5.8343 12.0416ZM2.71403 1.90435H21.7411C22.1652 1.90435 22.4829 2.2209 22.4829 2.63611V14.393C22.4829 14.8082 22.1652 15.1247 21.7411 15.1247H6.72889V12.0416C6.72889 11.1342 6.00606 10.422 5.0924 10.422H1.97213V2.63611C1.97213 2.2209 2.28984 1.90435 2.71403 1.90435Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M3.90277 4.25566H6.28114C6.40391 4.25566 6.5181 4.2146 6.60214 4.13151C6.68634 4.04826 6.72844 3.93453 6.72844 3.81174C6.72844 3.68896 6.68634 3.57522 6.60214 3.49198C6.5181 3.40889 6.40391 3.36782 6.28114 3.36782H3.90277C3.78 3.36782 3.66581 3.40889 3.58177 3.49198C3.49757 3.57522 3.45547 3.68896 3.45547 3.81174C3.45547 3.93453 3.49757 4.04826 3.58177 4.13151C3.66581 4.2146 3.78 4.25566 3.90277 4.25566Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M3.90277 8.9583H6.28114C6.40391 8.9583 6.5181 8.91724 6.60214 8.83414C6.68634 8.7509 6.72844 8.63716 6.72844 8.51438C6.72844 8.3916 6.68634 8.27786 6.60214 8.19462C6.5181 8.11153 6.40391 8.07046 6.28114 8.07046H3.90277C3.78 8.07046 3.66581 8.11153 3.58177 8.19462C3.49757 8.27786 3.45547 8.3916 3.45547 8.51438C3.45547 8.63716 3.49757 8.7509 3.58177 8.83414C3.66581 8.91724 3.78 8.9583 3.90277 8.9583Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M8.6596 4.25566H13.4164C13.5391 4.25566 13.6533 4.2146 13.7374 4.13151C13.8216 4.04826 13.8637 3.93453 13.8637 3.81174C13.8637 3.68896 13.8216 3.57522 13.7374 3.49198C13.6533 3.40889 13.5391 3.36782 13.4164 3.36782H8.6596C8.53684 3.36782 8.42265 3.40889 8.3386 3.49198C8.2544 3.57522 8.2123 3.68896 8.2123 3.81174C8.2123 3.93453 8.2544 4.04826 8.3386 4.13151C8.42265 4.2146 8.53684 4.25566 8.6596 4.25566Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M3.90277 6.60698H13.4163C13.539 6.60698 13.6532 6.56592 13.7373 6.48282C13.8215 6.39958 13.8636 6.28584 13.8636 6.16306C13.8636 6.04028 13.8215 5.92654 13.7373 5.8433C13.6532 5.76021 13.539 5.71914 13.4163 5.71914H3.90277C3.78 5.71914 3.66581 5.76021 3.58177 5.8433C3.49757 5.92654 3.45547 6.04028 3.45547 6.16306C3.45547 6.28584 3.49757 6.39958 3.58177 6.48282C3.66581 6.56592 3.78 6.60698 3.90277 6.60698Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M12.2272 8.07046H9.84879C8.93514 8.07046 8.2123 8.78263 8.2123 9.69006C8.2123 10.5975 8.93514 11.3097 9.84879 11.3097H12.2272C13.1408 11.3097 13.8637 10.5975 13.8637 9.69006C13.8637 8.78263 13.1408 8.07046 12.2272 8.07046ZM12.2272 10.4218H9.84879C9.42461 10.4218 9.1069 10.1053 9.1069 9.69006C9.1069 9.27485 9.42461 8.9583 9.84879 8.9583H12.2272C12.6514 8.9583 12.9691 9.27485 12.9691 9.69006C12.9691 10.1053 12.6514 10.4218 12.2272 10.4218Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M13.4164 12.7733H8.6596C8.53684 12.7733 8.42265 12.8144 8.3386 12.8975C8.2544 12.9807 8.2123 13.0945 8.2123 13.2173C8.2123 13.34 8.2544 13.4538 8.3386 13.537C8.42265 13.6201 8.53684 13.6612 8.6596 13.6612H13.4164C13.5391 13.6612 13.6533 13.6201 13.7374 13.537C13.8216 13.4538 13.8637 13.34 13.8637 13.2173C13.8637 13.0945 13.8216 12.9807 13.7374 12.8975C13.6533 12.8144 13.5391 12.7733 13.4164 12.7733Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M18.1279 4.10571V12.678"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.4895 5.52162C19.407 4.95918 15.93 4.53485 15.93 6.47028C15.93 8.78717 20.2594 7.96947 20.2594 10.014C20.2594 12.0584 17.4454 11.9051 15.4971 11.2407"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default HearthCashIcon;
