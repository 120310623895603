import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Switch,
  Route,
  RouteComponentProps,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Business, Menu as MenuIcon } from '@mui/icons-material';
import { useReactiveVar } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { useVerticalState } from 'common/hooks';
import { WalkthroughStep } from 'contractor/modules/OnboardingWalkthrough/useOnboardingWalkthrough';
import WalkthroughStepWrapper from 'contractor/modules/OnboardingWalkthrough';
import userFeatureFlags from 'common/graphql/featureFlags';
import ClonePopover from 'contractor/components/widgets/Popper/ClonePopover';

import { AccountButton, SendNewButton } from './components';
import { useDashboardNavigation } from './useDashboardNavigation';
import Breadcrumbs from './Breadcrumbs';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.drawerZIndex - 2,
    backgroundColor: 'white',
    marginLeft: theme.drawerWidth,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.common.basic500}`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawerWidth}px)`,
    },
  },
}));

type DashboardLocation = {
  background?: RouteComponentProps['location'];
}

const Greeting = (): JSX.Element | null => {
  const [{ brandMetadata }] = useVerticalState();
  const { contractor } = useDashboardNavigation();
  const { skittles } = useReactiveVar(userFeatureFlags);

  if (skittles) return null;

  return (
    <>
      {contractor?.firstName &&
        <Box
          display={{
            xs: 'none',
            sm: contractor?.enterpriseAdmin ? 'none' : 'block',
            md: 'block',
          }}
        >
          <Typography variant="h5" display="inline">
            <Box color="common.primary500" display="inline">
              Hey {contractor.firstName},
            </Box>
          </Typography>{' '}
          welcome to your {brandMetadata.label}{' '}
          dashboard!
        </Box>
      }
    </>
  );
};

const NavigationBar = (): JSX.Element => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const location = useLocation<DashboardLocation>();
  const background = location.state?.background;
  const isDashboardHome = useRouteMatch({ path: '/dashboard', exact: true });
  const {
    uiStates: {
      skittlesWelcomeTourSeen,
      showSkittlesTour,
    },
  } = useDashboardNavigation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { skittles } = useReactiveVar(userFeatureFlags);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [ref, setRef] = useState<HTMLButtonElement | null>(null);

  const {
    organization,
    contractor,
    uiStates: {
      mobileNavOpen,
      setMobileNavOpen,
    },
  } = useDashboardNavigation();

  useEffect(() => {
    setRef(buttonRef.current);
  }, [buttonRef, setRef]);

  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.content}>
          <Switch location={background || location}>
            <Route exact path="/dashboard">
              {contractor?.enterpriseAdmin &&
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
                    {organization?.logoUrl ?
                      <Box
                        style={{
                          backgroundImage: `url(${organization.logoUrl})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center center',
                          backgroundRepeat: 'no-repeat',
                          width: 50,
                          height: 50,
                          minWidth: 50,
                        }}
                      /> :
                      <Business fontSize="large" color="disabled" />
                    }
                    <Box mx={2}>
                      <Typography variant="h5" noWrap style={{ fontWeight: 150 }}>
                        {organization?.companyName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                      <Divider orientation="vertical" style={{ height: '25px' }} />
                    </Box>
                  </Box>
                </Box>
              }
              <Typography variant="h5" noWrap>
                <Greeting />
                <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                  Dashboard
                </Box>
              </Typography>
            </Route>
          </Switch>
          {!isDashboardHome && <Breadcrumbs />}
          {!isMobile &&
            <Box display="flex">
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <SendNewButton buttonRef={buttonRef} />
                {skittles && showSkittlesTour && !skittlesWelcomeTourSeen && !isMobile &&
                <ClonePopover anchorEl={ref} />}
                {/* <WalkthroughStepWrapper
                  step={WalkthroughStep.SEND_NEW}
                  placement="bottom"
                  anchorProps={{ top: 8 }}
                >
                  <SendNewButton />
                </WalkthroughStepWrapper> */}
                {/* TODO(skittles_tour):
                do we want to keep this ^ when we eventually disable tooltips  */}
              </Box>
              <Box mr={1.5} />
              <WalkthroughStepWrapper
                placement="bottom"
                step={WalkthroughStep.ACCOUNT}
                leftOffset={muiTheme.drawerWidth - 40}
                anchorProps={{ top: 8 }}
              >
                <AccountButton />
              </WalkthroughStepWrapper>
            </Box>
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
