import * as React from 'react';
import {
  Fab as MaterialFab,
  FabProps as MaterialFabProps,
} from '@mui/material';

export type FabProps = MaterialFabProps;

const Fab = ({ onClick, ...otherProps }: FabProps) => (
  <MaterialFab
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
    }}
    {...otherProps}
  />
);

export default Fab;
