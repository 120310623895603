import { SvgIconProps } from '../Icon';

const UnlockIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 52 62" fill="none" {...otherProps}>
    <path
      d="M37.711 13.6a11.569 11.569 0 00-3.447-8.192A11.837 11.837 0 0025.978 2v0a11.837 11.837 0 00-8.287 3.408 11.568 11.568 0 00-3.447 8.192v13.533M49.444 27.133H2.511V60h46.933V27.133zM25.977 46.467v5.8"
      stroke={color}
      strokeWidth={3.96}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.978 46.467c3.24 0 5.866-2.597 5.866-5.8 0-3.204-2.626-5.8-5.866-5.8-3.24 0-5.867 2.596-5.867 5.8 0 3.203 2.627 5.8 5.867 5.8z"
      stroke={color}
      strokeWidth={3.96}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UnlockIcon;
