import { SvgIconProps } from '../Icon';

const HouseIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 31" fill={bgColor} {...otherProps}>
    <path
      d="M1 11.663a2 2 0 01.94-1.696l13-8.125a2 2 0 012.12 0l13 8.125a2 2 0 01.94 1.696V28a2 2 0 01-2 2H3a2 2 0 01-2-2V11.663z"
      fill={bgColor}
      stroke={color}
      strokeWidth={2}
    />
    <path
      d="M34 6l4-4M30 5V1m5 9h4M10 20c.429 1.333 2.229 4 6 4s5.571-2.667 6-4"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default HouseIcon;
