import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  useLazyAction,
  ApiNetworkError,
  ApiMaxPollError,
} from 'promise-action';

import { useCreateLoanInquiryFromExistingMutation } from 'types';
import {
  updateCoapplicantApplication,
  submitApplication,
  updateApplication,
} from 'main/actions/loanApplicationV2';
import { SecondaryArgs, LoanApplicationResult } from 'common/api/loanApplicationApi';
import { fetchMinimalOrg } from 'contractor/actions/organization';
import { FormError } from 'common/api/utils';
import { useTerms } from 'common/hooks';
import CoborrowerModal from 'contractor/modules/HomeownerFlow/CoborrowerModal';
import WvContract from 'contractor/modules/HomeownerFlow/WvContract';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';

type Props = {
  open: boolean;
  onClose: () => void;
  organizationId?: string;
  loanInquiryUuid: string;
  loanPrincipal: number;
}

const EasyCoborrowerReapplyModal = ({
  open,
  onClose,
  organizationId,
  loanInquiryUuid,
  loanPrincipal,
}: Props): JSX.Element => {
  const history = useHistory();
  const { getTerms } = useTerms();
  const snack = useSnack();
  const [newLoanInquiryUuid, setNewLoanInquiryUuid] = useState<string>();
  const [wvContractOpen, setWvContractOpen] = useState(false);

  const [createLoanInquiryFromExisting] = useCreateLoanInquiryFromExistingMutation();

  const fetchOrganization = useLazyAction(fetchMinimalOrg);
  const update = useLazyAction(updateApplication, {}, { resetOnReload: true });
  const submit = useLazyAction(submitApplication, {}, { resetOnReload: true });
  const updateCoapplicant = useLazyAction(
    updateCoapplicantApplication,
    {},
    { resetOnReload: true },
  );

  useEffect(() => {
    if (organizationId && !fetchOrganization.loading) {
      fetchOrganization.run({ id: organizationId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const termsHtml = fetchOrganization.data ?
    getTerms(fetchOrganization.data, 'personal-loans', true) :
    '';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const extraErrors = useMemo(() => {
    const secondaryError = updateCoapplicant.error;

    const getExtraErrors = (error: FormError) => {
      if (error instanceof ApiNetworkError || error instanceof ApiMaxPollError) {
        return {};
      }
      if (error.status === 422 && error.formErrors) {
        return error.formErrors;
      }
      return {};
    };

    return {
      secondary: secondaryError ? getExtraErrors(secondaryError) : {},
    };
  }, [updateCoapplicant.error]);

  const handleError = useCallback((error: FormError) => {
    if (error instanceof ApiNetworkError || error instanceof ApiMaxPollError) {
      snack.errorSnack('Something went wrong! Please try again.');
      return;
    }
    if (error.status === 422 && error.formErrors) {
      if (error.formErrors.loanPrincipal) {
        snack.errorSnack(error.formErrors.loanPrincipal);
      } else {
        snack.errorSnack('Please make sure you fill out all your information correctly.');
      }
    }
  }, [snack]);

  const onUpdateCoapplicant = useCallback(async (id: string, values: Partial<SecondaryArgs>): Promise<LoanApplicationResult['loanInquiry']> => {
    try {
      const { terms, ...otherValues } = values;
      await update.run({
        id,
        data: {
          jointApplication: true,
          terms,
          termsString: terms ? termsHtml : null,
        },
      });
      return await updateCoapplicant.run({
        id,
        data: otherValues,
      });
    } catch (e) {
      analytics.trackException(e);
      handleError(e as FormError);
      throw e;
    }
  }, [handleError, termsHtml, update, updateCoapplicant]);

  const onSubmit = useCallback(async (id: string, wvContractSigned: boolean) => {
    await update.run({
      id,
      data: { wvContractSigned },
    },
    );
    await submit.run({ id });
    history.push(`/loans/${id}/loading`);
  }, [update, submit, history]);

  return (
    <>
      <CoborrowerModal
        open={open}
        onClose={onClose}
        extraErrors={extraErrors.secondary}
        loading={false}
        initialValues={{}}
        onSubmit={async ({
          birthday,
          fico,
          income,
          ...otherValues
        }) => {
          let id;

          const result = await createLoanInquiryFromExisting({
            variables: {
              loanPrincipal,
              loanInquiryUuid,
              terms: true,
              termsString: 'some terms',
            },
          });

          const data = result?.data?.createLoanInquiryFromExisting;
          switch (data?.__typename) {
          case 'CreateLoanInquiryFromExistingFailure': {
            const errors = data?.errors;
            if (errors) {
              snack.errorSnack(errors[0].message);
              onClose();
            }
            return;
          }
          case 'CreateLoanInquiryFromExistingSuccess': {
            id = data.loanInquiry.id;
            setNewLoanInquiryUuid(data.loanInquiry.id);
            break;
          }
          default:
            snack.errorSnack('An error occurred. Please try again.');
            onClose();
            return;
          }

          if (!id) {
            return;
          }

          const application = await onUpdateCoapplicant(
            id,
            {
              ...otherValues,
              birthday,
              fico: (fico != null && fico !== '') ? Number(fico) : undefined,
              income: (income != null && income !== '') ? Number(income) : undefined,
            },
          );
          if (application.borrower.state === 'WV') {
            setWvContractOpen(true);
            return;
          }
          await onSubmit(id, false);
        }}
        termsHtml={termsHtml}
        loanPrincipal={loanPrincipal}
        fullScreen={isMobile}
      />
      <WvContract
        open={wvContractOpen}
        onClose={() => setWvContractOpen(false)}
        onSubmit={async () => {
          if (!newLoanInquiryUuid) {
            return;
          }
          await onSubmit(newLoanInquiryUuid, true);
        }}
        loanInquiryUuid={newLoanInquiryUuid}
      />
    </>
  );
};

EasyCoborrowerReapplyModal.CREATE_LOAN_INQUIRY_FROM_EXISTING = gql`
  mutation CreateLoanInquiryFromExisting(
    $loanInquiryUuid: String!
    $terms: Boolean!
    $termsString: String!
    $loanPrincipal: Int!
  ) {
    createLoanInquiryFromExisting(
      loanInquiryUuid: $loanInquiryUuid
      terms: $terms
      termsString: $termsString
      loanPrincipal: $loanPrincipal
    ) {
      ... on CreateLoanInquiryFromExistingSuccess {
        loanInquiry {
          id
          state
        }
      }
      ... on CreateLoanInquiryFromExistingFailure {
        errors {
          code
          message
        }
      }
    }
  }
`;

export default EasyCoborrowerReapplyModal;
