import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { PaywallBanner } from 'contractor/components/banners';
import { useGetPaywallInfoQuery } from 'types';

type Props = {
  pathname?: string;
};

const ConditionalPaywallBanner = ({ pathname }: Props): JSX.Element => {
  const [trialUpgradeBannerSeen, setTrialUpgradeBannerSeen] = useState(false);
  const { data } = useGetPaywallInfoQuery();

  const planName = data?.organization?.planName;
  const canceledAt = data?.organization?.canceledAt;
  const isTrialPlan = planName && planName.indexOf('trial') !== -1;

  return (
    <>
      {isTrialPlan && canceledAt && !trialUpgradeBannerSeen &&
        <PaywallBanner
          ctaText="Manage payment plan"
          ctaLink="/dashboard/settings"
          pathname={pathname}
          onClick={() => setTrialUpgradeBannerSeen(!trialUpgradeBannerSeen)}
          onClose={() => setTrialUpgradeBannerSeen(!trialUpgradeBannerSeen)}
          closable
        >
          <Typography variant="h2" color="secondary">
            Hello – Your trial will expire at the end of this month!{' '}
            <span role="img" aria-label="credit card">💳</span>
          </Typography>
          <Box mt={2} />
          <Typography variant="h3" color="secondary">
            Thanks for giving Hearth a try! Please manage your payment
            plan in order to continue offering customers affordable financing.
          </Typography>
        </PaywallBanner>
      }
    </>
  );
};

ConditionalPaywallBanner.GET_PAYWALL_INFO = gql`
  query GetPaywallInfo {
    organization {
      id
      planName
      canceledAt
    }
  }
`;

export default ConditionalPaywallBanner;
