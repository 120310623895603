import { useCallback, useMemo, useState } from 'react';
import { Box, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { ContractBlob } from 'kakemono';

import { Button, Dialog } from 'common/components/material-ui';
import { SlateEditor } from 'common/components/elements';
import { useGetDefaultTosQuery } from 'types';
import { mdToSlate } from 'common/components/elements/SlateEditor/serializers';
import { getTosMarkdown } from 'contractor/hooks/useEditContractTemplateReducer';

import { EditTemplateValues } from '../EditContractTemplate';

const useStyles = makeStyles((theme: Theme) => ({
  outlined: {
    color: theme.palette.common.basic1100,
    borderColor: theme.palette.common.basic1100,
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
}

const EditTosModal = ({ open, onClose }: Props): JSX.Element => {
  const classes = useStyles();
  const [{ value: template }, , { setValue }] = useField<EditTemplateValues['tosSlateValue']>('tosSlateValue');
  const [tempTemplate, setTempTemplate] = useState<EditTemplateValues['tosSlateValue']>(template);
  const { data } = useGetDefaultTosQuery();
  const defaultTemplate = data?.contractServiceBaseTemplate.template;

  const defaultTosMarkdown = useMemo(() => {
    if (!defaultTemplate) return '';
    return getTosMarkdown(defaultTemplate as ContractBlob);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultTosSlateValue = useMemo(() => (
    defaultTosMarkdown ? mdToSlate(defaultTosMarkdown) : null
  ), [defaultTosMarkdown]);

  const saveTosToForm = useCallback(() => {
    setValue(tempTemplate);
    onClose();
  }, [setValue, tempTemplate, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      bgColor="basic100"
      maxWidth="md"
      showCloseButton={false}
    >
      <Typography variant="h3">
        Edit the terms of use section
      </Typography>
      <Box mt={2} />
      <SlateEditor
        id="edit-tos"
        value={tempTemplate}
        onChange={setTempTemplate}
      />
      <Box my={2} display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="text"
          buttonType="common.basic"
          onClick={onClose}
        >
          <Box color="common.basic1100">
            Cancel
          </Box>
        </Button>
        <Box m={1} />
        <Button
          variant="outlined"
          onClick={() => setTempTemplate([{ type: 'p', children: [{ text: '' }] }])}
          className={classes.outlined}
        >
          Clear text
        </Button>
        <Box m={1} />
        <Button
          variant="outlined"
          onClick={() => {
            if (defaultTosSlateValue) {
              setTempTemplate(defaultTosSlateValue);
            }
          }}
          className={classes.outlined}
        >
          Revert to our suggested template
        </Button>
        <Box m={1} />
        <Button
          variant="contained"
          buttonType="common.purple"
          onClick={saveTosToForm}
          disabled={_.isEqual(template, tempTemplate)}
        >
          Save changes
        </Button>
      </Box>
    </Dialog>
  );
};

export const GET_DEFAULT_TOS = gql`
  query GetDefaultTos {
    contractServiceBaseTemplate(type: project_contract) {
      id
      template
    }
  }
`;

export default EditTosModal;
