import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Typography from './Typography';

type AccordionSummaryProps = {
  leftContent?: React.ReactNode;
  heading?: React.ReactNode;
} & MuiAccordionSummaryProps;

const AccordionSummary = styled(MuiAccordionSummary, {
  name: 'AccordionSummary',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const AccordionHeader = ({
  leftContent,
  heading,
  children,
  ...props
}: AccordionSummaryProps): JSX.Element => (
  <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props}>
    {leftContent &&
      <Box sx={{ mr: 2 }}>
        {leftContent}
      </Box>
    }
    {typeof heading === 'string' ?
      <Typography variant="p1" weight="semibold" sx={{ marginTop: '3px' }}>
        {heading}
      </Typography> :
      heading
    }
  </AccordionSummary>
);

export default AccordionHeader;
