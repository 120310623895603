import { useState } from 'react';
import { useDebounce } from 'react-use';

import { Tooltip } from 'common/components/material-ui';
import { TooltipProps } from 'common/components/material-ui/Tooltip';

type Props = {
  initialOpen?: boolean;
  autoHideKey?: string | number;
} & Omit<TooltipProps, 'onOpen'| 'onClose'| 'isOpen'>;

const StatefulTooltip = ({
  initialOpen = false,
  title,
  autoHideKey,
  ...otherProps
}: Props): JSX.Element => {
  const [open, setOpen] = useState(initialOpen);

  useDebounce(() => {
    if (autoHideKey) {
      setOpen(false);
    }
  }, 1000, [autoHideKey]);

  return (
    <Tooltip
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOpen={open && title != null}
      title={title}
      {...otherProps}
    />
  );
};

export default StatefulTooltip;
