import { useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Theme,
  LinearProgress,
  Box,
  RadioProps,
  FormControlLabelClassKey,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

type Props<Choice> = {
  name: string;
  choices: Choice[];
  FeedbackComponent?: React.SFC<{ choice: Choice }>;
  onChoose?: (choice: Choice) => Promise<void> | void;
  answered?: boolean | undefined;
  showFeedback?: boolean;
  selectedOptionClasses?: Partial<Record<FormControlLabelClassKey, string>>;
  optionClasses?: Partial<Record<FormControlLabelClassKey, string>>;
}

const BigRadio = (props: RadioProps): JSX.Element => {
  const theme: Theme = useTheme();
  return (
    <Radio
      {...props}
      color="primary"
      icon={<RadioButtonUnchecked htmlColor={theme.palette.common.basic1100} fontSize="large" />}
      checkedIcon={<RadioButtonChecked htmlColor={theme.palette.common.basic1100} fontSize="large" />}
    />
  );
};

// eslint-disable-next-line arrow-parens
const Question = <Choice extends { label: string; value: string }>({
  choices,
  onChoose = () => Promise.resolve(),
  FeedbackComponent = () => null,
  name,
  answered = undefined,
  showFeedback = true,
  selectedOptionClasses = {},
  optionClasses = {},
}: Props<Choice>): JSX.Element => {
  const [selectedValue, setValue] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setValue(event.target.value);
    setLoading(true);
    await onChoose(choices.find(({ value }) => event.target.value === value) as Choice);
    setLoading(false);
  };

  if (showFeedback && (answered ?? selectedValue != null)) {
    return (
      <FeedbackComponent
        choice={choices.find(({ value }) => selectedValue === value) as Choice}
      />
    );
  }

  return (
    <>
      <RadioGroup
        name={name}
        onChange={handleChange}
        value={selectedValue}
      >
        {choices.map(({ label, value }) => (
          <FormControlLabel
            classes={value === selectedValue ? selectedOptionClasses : optionClasses}
            key={value}
            value={value}
            control={<BigRadio />}
            label={
              <Box py={1} pr={1}>
                <Typography variant="subtitle1">
                  <Box
                    lineHeight="1.5rem"
                    color={value === selectedValue ? 'common.primary500' : 'common.basic1100'}
                    fontWeight="fontWeightMedium"
                  >
                    {label}
                  </Box>
                </Typography>
              </Box>
            }
          />
        ))}
      </RadioGroup>
      {loading && showFeedback &&
        <Box mt={2}>
          <LinearProgress color="primary" variant="query" />
        </Box>
      }
    </>
  );
};

export default Question;
