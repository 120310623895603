import React from 'react';
import {
  Tooltip as MaterialTooltip,
  Box,
  ClickAwayListener,
  Theme,
  Zoom,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

type Position = 'begin' | 'center' | 'end';

export type TooltipProps = {
  title: React.ReactNode;
  bgColor?: Color;
  textAlign?: AlignSetting;
  isOpen?: boolean;
  placement?: MuiTooltipProps['placement'];
  popperClass?: string;
  arrowClass?: string;
  tooltipClass?: string;
  arrowPlacement?: Position;
  onOpen?: () => void;
  onClose?: () => void;
  onTooltipAction?: () => void;
  // This prop is useful if you don't want an extra div component wrapping children. This might
  // interfere with how the children behave.
  clone?: boolean;
  children?: React.ReactNode;
  disableHoverListener?: boolean;
  PopperProps?: MuiTooltipProps['PopperProps'];
}

type StyleProps = {
  bgColor: Color;
  textAlign: AlignSetting;
  placement: Position;
  top?: number;
  left?: number;
};

const arrowPlacementCalculation = (position: Position): string => {
  switch (position) {
  case 'begin':
    return '2em';
  case 'center':
    return 'calc(50% - 1.5em)';
  default:
    return 'calc(100% - 4em)';
  }
};

const arrowGenerator = (placement: Position) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: arrowPlacementCalculation(placement),
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: arrowPlacementCalculation(placement),
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    top: arrowPlacementCalculation(placement),
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    top: arrowPlacementCalculation(placement),
  },
});

const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: (props: StyleProps) => ({
    backgroundColor: theme.palette.common[props.bgColor],
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  }),
  title: (props: StyleProps) => ({
    backgroundColor: theme.palette.common[props.bgColor],
    padding: '4px 8px',
    minWidth: 150,
    textAlign: props.textAlign,
  }),
  popperArrow: (props: StyleProps) => ({
    pointerEvents: 'auto',
    ...arrowGenerator(props.placement),
  }),
  tooltipPlacementTop: {
    margin: '8px 0 0',
  },
  tooltipPlacementRight: {
    marginLeft: 0,
  },
  arrow: (props: StyleProps) => ({
    '&::before': {
      color: theme.palette.common[props.bgColor],
    },
  }),
}));

const Tooltip = ({
  title,
  children = false,
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  bgColor = 'primary700',
  textAlign = 'left',
  placement = 'top',
  popperClass = undefined,
  arrowClass = undefined,
  tooltipClass = undefined,
  onTooltipAction = () => {},
  arrowPlacement = 'center',
  clone,
  disableHoverListener = true,
  PopperProps = {},
  ...otherProps
}: TooltipProps): JSX.Element => {
  const {
    tooltip,
    arrow,
    title: titleClass,
    ...classes
  } = useTooltipStyles({
    bgColor,
    textAlign,
    placement: arrowPlacement,
  });

  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onOpen();
    onTooltipAction();
  };

  const handleTooltipFocus = () => {
    onOpen();
    onTooltipAction();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MaterialTooltip
        TransitionComponent={Zoom}
        PopperProps={{
          disablePortal: true,
          style: {
            position: 'fixed',
            zIndex: 500,
          },
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                options: {
                  enabled: false,
                },
              },
              {
                name: 'arrow',
                options: {
                  enabled: true,
                },
              },
            ],
          },
          ...PopperProps,
        }}
        classes={{
          ...classes,
          arrow: cx(arrow, arrowClass),
          tooltip: cx(tooltip, popperClass),
        }}
        open={isOpen}
        placement={placement}
        title={
          <Box className={cx(titleClass, tooltipClass)}>
            {title}
          </Box>
        }
        disableHoverListener={disableHoverListener}
        arrow
        // Making the touch delay 50 milliseconds
        // so that tooltips work on mobile without
        // having to touch for the default of 700 milliseconds
        enterTouchDelay={50}
        {...otherProps}
      >
        {(clone && children && React.isValidElement(children)) ?
          React.cloneElement(children, {
            onClick: handleTooltipOpen,
            onFocus: handleTooltipFocus,
            onBlur: onClose,
          }) : (
            <div
              onClick={handleTooltipOpen}
              onFocus={handleTooltipFocus}
              onBlur={onClose}
            >
              {children}
            </div>
          )
        }
      </MaterialTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
