import { apiPromiseAction, DispatchAction, PromiseAction } from 'promise-action';

import * as api from 'common/api/authApi';
import graphqlClient from 'common/graphql/apolloClient';
import * as localStorage from 'common/services/localStorage';

/*
** Promise actions
*/

const login =
  apiPromiseAction
    .create(api.oauthToken)
    .normalizeResponse(({ data }) => data)
    .interceptResponse((response) => {
      graphqlClient.cache.reset();
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
    });

const refreshToken =
  apiPromiseAction
    .create(api.refreshToken)
    .normalizeResponse(({ data }) => data)
    .interceptResponse((response) => {
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
    });

// @TODO: replace when session auth is removed
// const logout =
//   apiPromiseAction
//     .create(api.logout)
//     .normalizeResponse(({ data }) => data)
//     .interceptResponse(() => {
//       localStorage.removeItem('accessToken');
//       localStorage.removeItem('refreshToken');
//       graphqlClient.cache.reset();
//     });

const logout = new PromiseAction(() =>
  Promise.all([api.logout(), api.sessionLogout()]),
).interceptResponse(() => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  graphqlClient.cache.reset();
});

export const magicLinkLogin =
  apiPromiseAction
    .create(api.oauthMagicLink)
    .normalizeResponse(({ data }) => data)
    .interceptResponse((response) => {
      graphqlClient.cache.reset();
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
    });
/*
** Dispatchers
*/

export const loginDispatcher = login.dispatcher(
  'Auth/LOGIN_PENDING',
  'Auth/LOGIN_SUCCESS',
  'Auth/LOGIN_ERROR',
);

export const logoutDispatcher = logout.dispatcher(
  'Auth/LOGOUT_PENDING',
  'Auth/LOGOUT_SUCCESS',
  'Auth/LOGOUT_ERROR',
);

export const refreshTokenDispatcher = refreshToken.dispatcher(
  'Auth/REFRESH_TOKEN_PENDING',
  'Auth/REFRESH_TOKEN_SUCCESS',
  'Auth/REFRESH_TOKEN_ERROR',
);

export type DispatchActions =
  DispatchAction<typeof loginDispatcher> |
  DispatchAction<typeof logoutDispatcher> |
  DispatchAction<typeof refreshTokenDispatcher>;
