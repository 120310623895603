import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { Button } from 'common/components/material-ui';

import { useSettingsActions } from './useSettings';

const ChangePassword = (): JSX.Element => {
  const { sendResetPassword } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'change_password' });
  });

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Change Password
      </Typography>
      <Box my={3}>
        <Typography variant="body1" color="textPrimary">
          An email will be sent to reset your password.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          buttonType="common.primary"
          onClick={sendResetPassword}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
