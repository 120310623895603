import { useMemo } from 'react';
import { SvgIconProps } from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { getPreventDefaultHandler } from '@udecode/plate-common';
import { usePlateEditorState } from '@udecode/plate-core';
import {
  toggleList,
  ELEMENT_OL,
  ELEMENT_UL,
  getListItemEntry,
} from '@udecode/plate-list';

import BaseButton from './BaseButton';

type Props = {
  type: string;
}

const ICON_MAPPING: Record<string, React.ComponentType<SvgIconProps>> = {
  [ELEMENT_OL]: FormatListNumberedIcon,
  [ELEMENT_UL]: FormatListBulletedIcon,
};

const ListButton = ({ type }: Props): JSX.Element => {
  const editor = usePlateEditorState();
  const Icon = useMemo(() => ICON_MAPPING[type], [type]);
  const res = !!editor?.selection && getListItemEntry(editor);

  return (
    <BaseButton
      Icon={Icon}
      active={!!res && res.list[0].type === type}
      onMouseDown={
        editor &&
        getPreventDefaultHandler(toggleList, editor, {
          type,
        })
      }
    />
  );
};

export default ListButton;
