import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAction } from 'promise-action';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';

import { Loader } from 'common/components/widgets';
import { magicLinkLogin } from 'contractor/actions/auth';
import { isAxiosError } from 'common/api/utils';
import { refreshData } from 'contractor/actions/contractor';
import { useSnack } from 'common/utils/snackCart';

type Props = {
  email: string;
  token: string;
  noPasswordSet: boolean;
  redirectPath?: string;
}

const Autologin = ({
  email,
  token,
  noPasswordSet,
  redirectPath,
}: Props): JSX.Element | null => {
  const { errorSnack } = useSnack();
  const dispatch = useDispatch();
  const history = useHistory();
  let errorText: string | undefined;
  const autologin = useAction(magicLinkLogin, {
    email,
    magicLinkToken: token,
    noPasswordSet,
  });

  useEffect(() => {
    if (!autologin.loading && errorText != null) {
      history.push('/login');
      errorSnack(errorText);
    }
  }, [autologin.loading, errorSnack, errorText, history]);

  useAsync(async () => {
    if (autologin.data) {
      dispatch({
        type: 'Auth/LOGIN_SUCCESS',
        payload: autologin.data,
      });

      await dispatch(refreshData('network-only'));

      if (redirectPath) {
        history.push(redirectPath);
      } else {
        history.push('/dashboard');
      }
    }
  }, [autologin.data]);

  if (!autologin.loading && autologin.error) {
    const err = autologin.error;
    if (isAxiosError(err)) {
      switch (err.code) {
      case '404':
        errorText = 'Could not locate your account, please ensure you copied the full link provided';
        break;
      case '403':
        errorText = 'Invalid credentials, please contact your account representative.';
        break;
      default:
        errorText = 'Login link has expired, please contact your account representative.';
        break;
      }
    }
  }

  return autologin.loading ? <Loader customText="Securely logging you in..." /> : null;
};

export default Autologin;
