import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { gql } from '@apollo/client';
import { isFilled } from 'ts-is-present';
import constate from 'constate';

import {
  OfferDetailsFragment,
  useGetOffersQuery,
  useGetPaymentProofDataQuery,
} from 'types';
import { socialProofCheck, getFicoBucket } from 'common/utils/socialProofCalculations';
import { useVerticalState } from 'common/hooks';
import { useAbTest } from 'common/services/abTest';

import { AmountInfo } from './components/LoanFilterModal';

export const GET_OFFERS = gql`
  fragment OfferDetails on Offer {
    id
    inMonthlyPaymentRange
    redirectUrl
    apr
    autopay
    secure
    lenderImageSlug
    loanTermInMonths
    loanAmount
    monthlyPayment
    score
    disclosure
    lenderName
    offerType
  }

  query GetOffers($id: String!) {
    loanInquiry(loanInquiryUuid: $id) {
      id
      minOffer
      maxOffer
      fico
      jointApplication
      loanPrincipal
      requestedAmount
      householdIncome
      zeroPercentCards
      monthlyPaymentMin
      monthlyPaymentMax
      recommendedLoanAmount
      rateCap
      homeowner {
        id
        firstName
      }
      organization {
        id
        featureFlags {
          id
        }
        planFeatureFlags {
          id
        }
      }
      offers {
        nodes {
          id
          ...OfferDetails
        }
      }
    }
  }
  `;

export const PAYMENT_OPTION_PROOF = gql`
  fragment PaymentProof on MonthlyPaymentCalculation {
    id
    minApr
    loanTermInMonths
  }

  query getPaymentProofData($ficoBucket: String!, $loanPrincipal: Float!) {
    monthlyPaymentCalculation(ficoBucket: $ficoBucket, loanPrincipal: $loanPrincipal) {
      nodes {
        id
        ...PaymentProof
      }
    }
  }
`;

export type SortingOffersSortType = keyof Pick<OfferDetailsFragment, 'apr' | 'monthlyPayment' | 'loanAmount' | 'loanTermInMonths' | 'score'>;

const lowestAprOffer = (offers: OfferDetailsFragment[]): OfferDetailsFragment => (
  [...offers].sort((a, b) => parseFloat(a.apr) - parseFloat(b.apr))[0]
);

const lowestAprForAmount = (
  offersByAmount: OfferDetailsFragment[],
  amount: number,
  selected: boolean,
): AmountInfo => {
  const lowestApr = lowestAprOffer(offersByAmount).apr;

  return {
    amount,
    lowestApr,
    selected,
  };
};

const offersToShow = 6;

const useContext = ({ loanInquiryUuid }: { loanInquiryUuid: string }) => {
  const { getOrSetVariant } = useAbTest();
  const [{ verticalMetadata }] = useVerticalState();
  const { showSocialProof } = verticalMetadata.offers.offers;

  const [sortType, setSortType] = useState<SortingOffersSortType>('score');
  const [rateCap, setRateCap] = useState<number | null>(null);
  const [loanFilterModalOpen, setLoanFilterModalOpen] = useState(false);
  const [showTopOffers, toggleShowTopOffers] = useToggle(true);

  const { data: offersData, loading: offersLoading } = useGetOffersQuery({
    variables: {
      id: loanInquiryUuid,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const monthlyPaymentMax = data?.loanInquiry?.monthlyPaymentMax;
      const rateCapping = data?.loanInquiry?.organization?.featureFlags?.some(x => x.id === 'rate_capping');
      // We only prefilter by the rate cap if they have the rate
      // capping test on
      if (rateCapping && (monthlyPaymentMax == null)) {
        setRateCap(data?.loanInquiry?.rateCap ?? null);
      }
    },
  });

  const loanInquiry = useMemo(() => (
    (!offersLoading && offersData?.loanInquiry) ? offersData?.loanInquiry : null
  ), [offersData, offersLoading]);

  const homeowner = useMemo(() => (
    (loanInquiry?.homeowner == null || offersLoading) ? null : loanInquiry.homeowner
  ), [loanInquiry, offersLoading]);

  // @TODO(feature_flags): zero_percent_credit_card_add_on lives on the subscription
  // feature flags, not the organization features, therefore we need to merge the two here.
  const orgFeatureFlags = useMemo(() => (
    // eslint-disable-next-line max-len
    (loanInquiry?.organization?.featureFlags && loanInquiry?.organization?.planFeatureFlags && !offersLoading) ?
      // eslint-disable-next-line max-len
      [...loanInquiry?.organization?.featureFlags, ...loanInquiry?.organization?.planFeatureFlags].map(ff => ff.id) :
      null
  ), [loanInquiry, offersLoading]);

  const offers = useMemo(() => {
    if (offersLoading || loanInquiry?.offers?.nodes == null) {
      return [];
    }

    return loanInquiry.offers.nodes.filter(isFilled).filter(offer => offer.offerType === 'personal_loan');
  }, [loanInquiry, offersLoading]);

  const upgradeLineOfCreditOffer = useMemo(() => {
    if (offersLoading || loanInquiry?.offers?.nodes == null) {
      return null;
    }

    const lineOfCreditOffers = loanInquiry.offers.nodes.filter(isFilled).filter(offer => offer.offerType === 'line_of_credit');
    if (lineOfCreditOffers.length > 0) {
      // Upgrade Card is the only lender that returns a line of credit offer
      // and only returns at most one offer
      return lineOfCreditOffers[0];
    }
    return null;
  }, [loanInquiry, offersLoading]);

  const { data: socialProofData, loading: socialProofLoading } =
    useGetPaymentProofDataQuery({
      variables: {
        ficoBucket: getFicoBucket(Number(loanInquiry?.fico)),
        loanPrincipal: loanInquiry?.loanPrincipal as number,
      },
      skip: !loanInquiry,
    });

  const socialProofOffer = useMemo(() => (
    lowestAprOffer(offers)
  ), [offers]);

  const hasSocialProof = useMemo(() => {
    if (!socialProofOffer || !socialProofData) {
      return false;
    }

    if (showSocialProof && socialProofData.monthlyPaymentCalculation?.nodes) {
      return socialProofCheck(
        socialProofData.monthlyPaymentCalculation.nodes.filter(isFilled),
        socialProofOffer.loanTermInMonths / 12,
        socialProofOffer.apr,
      );
    }

    return false;
  }, [showSocialProof, socialProofOffer, socialProofData]);

  const uniqueLoanAmounts = useMemo(() => {
    const amounts = offers.map(o => o.loanAmount);
    return amounts.filter((offer, index) => amounts.indexOf(offer) === index);
  }, [offers]);

  const allLoanAmounts = useMemo((): AmountInfo[] => (
    uniqueLoanAmounts.map((amount) => {
      const offersByAmount = offers.filter(o => o.loanAmount === amount);
      return lowestAprForAmount(offersByAmount, amount, true);
    })
  ), [offers, uniqueLoanAmounts]);

  /*
    We store the loan amounts we want to filter out rather than what we want to keep,
    since we initially want to show all, and because it would be difficult to have
    an initial value for the useState in the reverse scenario.
  */
  const [hiddenLoanAmounts, filterLoanAmounts] = useState<Set<number>>(new Set([]));

  /*
    Logic related to monthly payment entry (given there is an org with the flag):
    1) calculate what offers fall into the bucket
    2) total # of top offers shown should be the offers that match, at most 8
  */

  // this is ALL offers, sorted by monthly payment ones first.
  const monthlyPaymentOffers = useMemo(() => (
    offers.sort((o1, o2) =>
      Number(o2.inMonthlyPaymentRange) - Number(o1.inMonthlyPaymentRange))
  ), [offers]);

  // this can change based on what loan amounts are being filtered out
  const monthlyPaymentOfferCount = useMemo(() => (
    offers.filter(o => o.inMonthlyPaymentRange && !hiddenLoanAmounts.has(o.loanAmount)).length
  ), [offers, hiddenLoanAmounts]);

  const showZeroPercentOfferBanner = useMemo(() => (
    !!orgFeatureFlags?.find(ff => ff === 'zero_percent_credit_card_add_on' || ff === 'zero_percent_cards_may_2019')
  ), [orgFeatureFlags]);

  const hasRateCapping = useMemo(() => orgFeatureFlags?.includes('rate_capping'), [orgFeatureFlags]);

  /**
   * The credit_card_placement_test_jan2021 tests whether putting the ZeroPercentCardOffer
   * on top of the offers will generate more credit card sales. This function adds logic
   * to determine where to actually position the component.
   */
  const zeroPercentOfferPosition: false | 'top' | 'bottom' = useMemo(() => {
    const zeroPercentEligible = Number(loanInquiry?.fico) >= 680;
    const loanPrincipal = Number(loanInquiry?.loanPrincipal);

    if (!showZeroPercentOfferBanner || !zeroPercentEligible || !homeowner || !loanPrincipal) {
      return false;
    }

    if (getOrSetVariant('credit_card_placement_test_jan2021') === 'credit_card_top_variant' && loanPrincipal <= 12_000) {
      return 'top';
    }

    return 'bottom';
  }, [
    getOrSetVariant,
    homeowner,
    loanInquiry?.fico,
    loanInquiry?.loanPrincipal,
    showZeroPercentOfferBanner,
  ]);

  const showMonthlyPaymentOffers = useMemo(() => (
    monthlyPaymentOfferCount > 0
  ), [monthlyPaymentOfferCount]);

  // TODO(0% cc): clarify logic for only showing 3 top offers -- this should be as easy as
  // checking the flag and using a variable instead of hardcoding 8
  // only defer to monthlyPaymentOfferCount if the sort type is score (and its < 8)
  const baseOffersToShow = (sortType === 'score' &&
    monthlyPaymentOfferCount <= 8 &&
    monthlyPaymentOfferCount > 0) ? monthlyPaymentOfferCount : 8;

  const filteredOffers: OfferDetailsFragment[] = useMemo(() => {
    if (sortType === 'score' && showMonthlyPaymentOffers) {
      return monthlyPaymentOffers.filter((offer: OfferDetailsFragment) =>
        !hiddenLoanAmounts.has(offer.loanAmount));
    }

    return offers.filter((offer: OfferDetailsFragment) =>
      !hiddenLoanAmounts.has(offer.loanAmount));
  }, [offers, hiddenLoanAmounts, monthlyPaymentOffers, showMonthlyPaymentOffers, sortType]);

  const aprFilteredOffers = useMemo(() => {
    if (!rateCap) {
      return filteredOffers;
    }
    return filteredOffers.filter((offer: OfferDetailsFragment) => parseFloat(offer.apr) <= rateCap);
  }, [rateCap, filteredOffers]);

  const sortedOffers = useMemo(() => {
    // we don't want the sort to re-order everything--so first sort the monthly payment options,
    // then the bottom, and return the concatenation
    if (sortType === 'score' && showMonthlyPaymentOffers) {
      const topSorted = [...aprFilteredOffers].filter(o => o.inMonthlyPaymentRange)
        .sort((a, b) => b[sortType] - a[sortType]);
      const bottomSorted = [...aprFilteredOffers].filter(o => !o.inMonthlyPaymentRange)
        .sort((a, b) => b[sortType] - a[sortType]);
      return [...topSorted, ...bottomSorted];
    }

    if (sortType === 'score') {
      // if we're doing the default/recommended, we want to go from highest --> lowest
      return aprFilteredOffers.sort((a, b) => b[sortType] - a[sortType]);
    }

    // we can just do this because all of the sorts are lowest --> highest
    return aprFilteredOffers.sort((a, b) => (a[sortType] as number) - (b[sortType] as number));
  }, [aprFilteredOffers, sortType, showMonthlyPaymentOffers]);

  /* eslint-disable react-hooks/exhaustive-deps */
  // we need sortType here so that the offers actually change/update

  const topOffers = useMemo(() => (
    sortedOffers.slice(0, offersToShow)
  ), [sortedOffers, offersToShow, sortType]);

  const bottomOffers = useMemo(() => (
    sortedOffers.slice(offersToShow, offers.length)
  ), [sortedOffers, offers.length, offersToShow, sortType]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // to handle the "animation"
  const [growIn, setGrowIn] = useState(true);

  return {
    socialProof: {
      socialProofOffer,
      hasSocialProof,
    },
    processState: {
      allLoanAmounts,
      sortType,
      rateCap,
      monthlyPaymentSort: sortType === 'score',
      loanFilterModalOpen,
      hiddenLoanAmounts,
      growIn,
    },
    actions: {
      setSortType,
      setRateCap,
      setLoanFilterModalOpen,
      toggleShowTopOffers,
      filterLoanAmounts,
      setGrowIn,
    },
    inquiryState: {
      loanInquiry,
      homeowner,
    },
    offersState: {
      hasRateCapping,
      showMonthlyPaymentOffers,
      zeroPercentOfferPosition,
      totalMonthlyPaymentOffers: baseOffersToShow,
      showTopOffers,
      maxOffer: loanInquiry?.maxOffer,
      minOffer: loanInquiry?.minOffer,
      topOffers,
      bottomOffers,
      offers,
      offersToShow,
      upgradeLineOfCreditOffer,
      lowOffers: 6,
      loading: offersLoading || !loanInquiry || !offers || socialProofLoading || !socialProofData,
    },
  };
};

export const [OffersProvider, useSortingOffers] = constate(useContext);
