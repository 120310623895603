import {
  CSSProperties,
  memo,
  useCallback,
} from 'react';
import { ListChildComponentProps, FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  Box,
  Typography,
  Divider,
  Container,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isPresent } from 'ts-is-present';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory, useLocation } from 'react-router-dom';

import { HomeownerEventBucket, IndexClientFragment } from 'types';
import { ButtonBase, Button } from 'common/components/material-ui';
import { Icon } from 'common/components';
import { useSnack } from 'common/utils/snackCart';
import { analytics } from 'common/services';

import { QuickBooksDismissibleBanner } from 'contractor/components/banners';
import {
  useColorPalette,
  useClientIndex,
  useInferredStates,
  Sections,
  isHeader,
  ClientHeader,
} from '../useClientIndex';
import { ViewMode } from './MenuItems';
import { generateActivityCopy } from '../../helpers/activityCopy';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.common.basic300,
    height: 48,
    width: 48,
  },
  rowButton: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 8,
  },
}));

const LoadingRow = ({ style }: { style?: CSSProperties }) => (
  <Box display="flex" flex={1} flexDirection="row" py={2} style={style}>
    <Box display="flex" flex={0} alignItems="center">
      <Skeleton variant="circular" width={48} height={48} />
    </Box>
    <Box display="flex" flex={4} flexDirection="column" ml={4}>
      <Skeleton variant="text" width={300} />
      <Skeleton variant="text" width={300} />
    </Box>
    <Box display="flex" flex={0} alignItems="center" mr={2}>
      <Skeleton variant="rectangular" width={40} height={20} />
    </Box>
  </Box>
);

const HeaderRow = ({ style, item }: { item: ClientHeader; style: CSSProperties }) => {
  const { state } = useClientIndex();
  const colorPalette = useColorPalette();

  return (
    <Box
      style={style}
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      bgcolor={colorPalette.accentColor}
    >
      <Typography variant="h5">
        <Box color={colorPalette.textColor} pl={2}>
          {item.title}
        </Box>
      </Typography>
      {state.homeownerBucket === HomeownerEventBucket.FINANCING_AND_PAYMENTS &&
        <Box mt={2}>
          <Divider style={{ width: '100%' }} />
        </Box>
      }
    </Box>
  );
};

type RowProps = Omit<ListChildComponentProps, 'data'> & {
  data?: Sections<IndexClientFragment>;
}

const Row = memo(({ index, data, style }: RowProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const colorPalette = useColorPalette();
  const item = data && data[index];

  // This occurs when the specific item is still loading
  if (!item) {
    return (
      <LoadingRow style={style} />
    );
  }

  if (isHeader(item)) {
    return (
      <HeaderRow item={item} style={style} />
    );
  }

  return (
    <ButtonBase
      style={style}
      className={classes.rowButton}
      onClick={() => history.push(
        `/dashboard/clients/${item.id}`, { background: location },
      )}
    >
      <Box display="flex" flex={0} alignItems="center">
        <Box
          bgcolor={colorPalette.iconAccentColor}
          width={48}
          height={48}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="24px"
        >
          <Icon
            name="bank"
            color={colorPalette.iconColor}
            height={20}
            width={20}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex={4}
        flexDirection="column"
        pl={4}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Box color="common.basic1100">
          <Typography variant="subtitle1" color="inherit">
            {item.fullName}
          </Typography>
        </Box>
        {item.hearthEvents.nodes?.filter(isPresent) &&
          item.hearthEvents.nodes[0] != null &&
          <Typography variant="caption" color="inherit">
            <Box color="common.basic900">
              {generateActivityCopy(item.hearthEvents.nodes[0], true)}
            </Box>
          </Typography>
        }
      </Box>
      <Box display="flex" flex={0} color="common.basic700" alignItems="center">
        <ChevronRightIcon fontSize="large" />
      </Box>
    </ButtonBase>
  );
}, areEqual);

const HomeownerList = (): JSX.Element | null => {
  const { errorSnack } = useSnack();
  const {
    sections,
    homeowners,
    totalCount,
    pageInfo,
    query,
    state,
    contractor,
  } = useClientIndex();
  const colorPalette = useColorPalette();
  const { showPaymentsSetup } = useInferredStates();
  const history = useHistory();

  const isItemLoaded = useCallback(
    (index: number) => (!pageInfo?.hasNextPage) || (index < sections.length),
    [pageInfo, sections]);

  const loadMore = useCallback(async (_startIndex: number, _stopIndex: number) => {
    if (query.data?.homeownerSearch?.pageInfo.hasNextPage === false) {
      return;
    }

    if (query.fetchMore && isPresent(pageInfo)) {
      try {
        await query.fetchMore({
          variables: {
            orderBy: state.sortMethod,
            admin: state.viewMode === ViewMode.TEAM,
            type: state.homeownerBucket,
            searchTerm: state.filterText,
            afterCursor: pageInfo.endCursor,
          },
        });
      } catch (error) {
        analytics.trackException(error);
        errorSnack('An error occurred loading more homeowners. Please try again.');
      }
    }
  }, [
    pageInfo,
    query,
    state.homeownerBucket,
    state.sortMethod,
    state.viewMode,
    state.filterText,
    errorSnack,
  ]);

  if ((homeowners.length === 0 && query.loading) || !contractor) {
    return (
      <Box display="flex" flexDirection="column">
        {[...Array(20).keys()].map(val => (
          <LoadingRow key={`loading-${val}`} />
        ))}
      </Box>
    );
  }

  if (showPaymentsSetup && state.homeownerBucket === HomeownerEventBucket.PAYMENTS_INDEX) {
    return (
      <Container maxWidth="xs">
        <Box
          display="flex"
          my={4}
          flexDirection="column"
          alignItems="center"
          color={colorPalette.textColor}
        >
          <Typography variant="h3">
            Payments not set up
          </Typography>
          <Box mt={3} />
          <Typography variant="body2">
            {contractor.admin ?
              `Your clients will appear here after you set up Hearth Pay for digital invoicing and
              easy payments.` :
              `Your clients will appear here after your account administrator sets up Hearth Pay for
              digital invoicing and easy payments.`
            }
          </Typography>
          <Box mt={3} />
          {contractor.admin ?
            <Button
              variant="contained"
              buttonType="common.success"
              onClick={() => history.push('/dashboard/payments?source=client_view')}
              size="medium"
            >
              Setup Hearth Pay
            </Button> :
            <Button
              variant="contained"
              buttonType="common.success"
              onClick={() => window.open('https://www.gethearth.com/hearth-pay')}
              size="medium"
            >
              Learn More
            </Button>
          }
        </Box>
      </Container>
    );
  }

  if (homeowners.length === 0) {
    return (
      <Box
        pt={4}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Typography variant="h5">
          Your clients will appear here
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {state.homeownerBucket === HomeownerEventBucket.PAYMENTS_INDEX &&
        <QuickBooksDismissibleBanner rootProps={{ margin: '8px 16px 24px' }} />
      }
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={totalCount}
            loadMoreItems={loadMore}
            minimumBatchSize={20}
            threshold={15}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                itemCount={totalCount}
                itemSize={70}
                width={width}
                itemData={sections}
                ref={ref}
                onItemsRendered={onItemsRendered}
                useIsScrolling
                overscanCount={5}
              >
                {Row}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </>
  );
};

export default HomeownerList;
