import {
  FieldPolicy,
  makeVar,
  Reference,
  StoreObject,
} from '@apollo/client/cache';
import isEqual from 'lodash/isEqual';
import { FeatureFlag } from 'types';

const getKey = (obj: Record<string, string>, val: string) =>
  Object.keys(obj).find(key => obj[key] === val);

const flagMapping = {
  createPaymentPlans: 'create_payment_plans',
  customerDetails: 'customer_details',
  kitchenSinkBlocked: 'kitchen_sink_blocked',
  paymentsSystem: 'payments_system',
  quickPollFlag: 'quick_poll_sep_2019',
  tryPayments: 'try_payments_may2020',
  hearthEssentials: 'hearth_essentials',
  kycWelcomeVariant: 'kyc_welcome_variant_jan_2021',
  invoiceWithoutPayments: 'invoice_without_payments',
  apiToken: 'api_token',
  rateCapping: 'rate_capping',
  paymentsOnboarding: 'payments_onboarding_apr_2021',
  contracts: 'contracts',
  hearthRewards: 'hearth_rewards_may_2021',
  industryWebinar: 'roofing_industry_webinar',
  webinarSignup: 'webinar_signup',
  hearthCashPaintedDoorVariantA: 'hearth_cash_painted_door_variant_a',
  hearthCashPaintedDoorVariantB: 'hearth_cash_painted_door_variant_b',
  insightsTab: 'insights_tab',
  marketingEmailLeads: 'marketing_email_leads',
  contractsV2: 'contracts_v2',
  unifiedClientView: 'unified_client_views',
  banking: 'banking',
  quotes: 'quotes-2021-11',
  webinarWithWistia: 'wistia_test_2021_12',
  skittles: 'skittles_2021_12',
  lending: 'lending',
  getStarted: 'get_started_2022_02',
};

type AllFeatureFlags = {
  createPaymentPlans: boolean;
  customerDetails: boolean;
  kitchenSinkBlocked: boolean;
  paymentsSystem: boolean;
  quickPollFlag: boolean;
  tryPayments: boolean;
  hearthEssentials: boolean,
  kycWelcomeVariant: boolean,
  invoiceWithoutPayments: boolean,
  apiToken: boolean,
  rateCapping: boolean,
  paymentsOnboarding: boolean,
  contracts: boolean;
  hearthRewards: boolean;
  industryWebinar: boolean;
  webinarSignup: boolean;
  hearthCashPaintedDoorVariantA: boolean;
  hearthCashPaintedDoorVariantB: boolean;
  insightsTab: boolean;
  marketingEmailLeads: boolean;
  contractsV2: boolean;
  unifiedClientView: boolean;
  banking: boolean;
  quotes: boolean;
  webinarWithWistia: boolean;
  skittles: boolean;
  lending: boolean;
  getStarted: boolean;
};

const baseFlags: AllFeatureFlags = {
  createPaymentPlans: false,
  customerDetails: false,
  kitchenSinkBlocked: false,
  paymentsSystem: false,
  quickPollFlag: false,
  tryPayments: false,
  hearthEssentials: false,
  kycWelcomeVariant: false,
  invoiceWithoutPayments: false,
  apiToken: false,
  rateCapping: false,
  paymentsOnboarding: false,
  contracts: false,
  hearthRewards: false,
  industryWebinar: false,
  webinarSignup: false,
  hearthCashPaintedDoorVariantA: false,
  hearthCashPaintedDoorVariantB: false,
  insightsTab: false,
  marketingEmailLeads: false,
  contractsV2: false,
  unifiedClientView: false,
  banking: false,
  quotes: false,
  webinarWithWistia: false,
  skittles: false,
  lending: false,
  getStarted: false,
};

const userFeatureFlags = makeVar<AllFeatureFlags>(baseFlags);

export const featureFlagsMergePolicy: FieldPolicy<FeatureFlag[], FeatureFlag[], FeatureFlag[]> = {
  merge(_existing, incoming, { readField, canRead }) {
    let temporaryFlags = { ...baseFlags };

    incoming.forEach((element: StoreObject | Reference) => {
      const featureFlagId = canRead(element) ? readField<string>('id', element) : undefined;
      if (featureFlagId) {
        const key = getKey(flagMapping, featureFlagId) as keyof AllFeatureFlags | undefined;
        if (key) {
          if (temporaryFlags[key] === false) {
            temporaryFlags = {
              ...temporaryFlags,
              [key]: true,
            };
          }
        }
      }
    });

    if (!isEqual(userFeatureFlags(), temporaryFlags)) {
      userFeatureFlags(temporaryFlags);
    }
    return incoming;
  },
};

export default userFeatureFlags;
