import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { decamelizeKeys } from 'humps';

import { ampli, IdentifyProperties } from 'ampli';
import { isDebug, isProduction } from 'config/app';

const SCREEN_TRACKING_ENABLED = isProduction;
export const EVENT_TRACKING_ENABLED = true;
const ERROR_TRACKING_ENABLED = !isDebug;

export const configure = (): void => {
  // FullStory
  if (SCREEN_TRACKING_ENABLED) {
    FullStory.init({
      orgId: '47VPG',
      devMode: isDebug,
    });
  }

  // Sentry
  if (ERROR_TRACKING_ENABLED) {
    Sentry.init({
      // Netlify has a env variable called 'COMMIT_REF', which we set in the .env file
      release: process.env.REACT_APP_COMMIT_REF,
      dsn: process.env.REACT_APP_SENTRY_FRONTEND_DSN,
      environment: process.env.REACT_APP_ENV,
      autoSessionTracking: true,
      normalizeDepth: 5,
      // This is useful to easily add context from custom error or object by just adding sentryExtra
      // field or method.
      beforeSend(event, hint) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = hint?.originalException as any;
        if (event && error && typeof error !== 'string' && 'sentryExtra' in error) {
          if (error.sentryExtra) {
            // eslint-disable-next-line no-param-reassign
            event.extra = typeof (error.sentryExtra) === 'function' ?
              error.sentryExtra() :
              error.sentryExtra;
          }
        }
        return event;
      },
    });
  }

  // Amplitude
  if (EVENT_TRACKING_ENABLED) {
    ampli.load({
      environment: isProduction ? 'production' : 'development',
      client: {
        config: {
          includeUtm: true,
          includeReferrer: true,
          apiEndpoint: process.env.REACT_APP_AMPLITUDE_ENDPOINT,
        },
      },
    });
  }
};

type User = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  featureFlags?: string[];
  organizationId?: string;
  contractorId?: string;
  homeownerId?: string;
  contractServiceSignerId?: string;
  userType: 'homeowner' | 'contractor' | 'contract_service_signer';
}
export const identify = (user: User): void => {
  let id: string | undefined;

  if (user.contractorId && user.userType === 'contractor') {
    id = `CON_${user.contractorId}`;
  }

  if (user.homeownerId && user.userType === 'homeowner') {
    id = `HO_${user.homeownerId}`;
  }

  if (user.contractServiceSignerId && user.userType === 'contract_service_signer') {
    id = `CSS_${user.contractServiceSignerId}`;
  }

  // GTM
  if (EVENT_TRACKING_ENABLED && window.dataLayer) {
    if (id) {
      window.dataLayer.push({ userId: id });
    }
    window.dataLayer.push({ event: 'userProps', userProps: user });
  }

  // FullStory
  if (SCREEN_TRACKING_ENABLED) {
    if (id) {
      FullStory.identify(id);
    }
    FullStory.setUserVars(
      Object.keys(user).reduce<Record<string, unknown>>((result, field) => ({
        ...result,
        [field === 'featureFlags' ? `${field}_strs` : `${field}_str`]: user[field as keyof User],
      }), {}),
    );
  }

  // Sentry
  if (ERROR_TRACKING_ENABLED) {
    Sentry.setUser({ id, ...user });
  }

  // Amplitude
  if (EVENT_TRACKING_ENABLED) {
    ampli.identify(id, decamelizeKeys(user) as IdentifyProperties);
  }
};

export const anonymize = (): void => {
  // GTM
  if (EVENT_TRACKING_ENABLED) {
    window.dataLayer.push({ userId: null });
  }

  // FullStory
  if (SCREEN_TRACKING_ENABLED) {
    FullStory.anonymize();
  }

  // Sentry
  if (ERROR_TRACKING_ENABLED) {
    Sentry.setUser(null);
  }

  // Amplitude
  if (EVENT_TRACKING_ENABLED) {
    ampli.client?.setUserId(null);
    ampli.client?.regenerateDeviceId();
  }
};
