import { Box, Typography } from '@mui/material';

import { Dialog } from 'common/components/material-ui';

type Props = {
  open: boolean;
  onClose: () => void;
}

const AdvertiserDisclosureModal = ({ open, onClose }: Props): JSX.Element => (
  <Dialog open={open} onClose={onClose} scroll="body">
    <Box p={{ xs: 2, sm: 5 }}>
      <Typography variant="h3" color="textPrimary">
        Advertiser Disclosure
      </Typography>
      <Box mt={3} />
      <Typography variant="subtitle1" color="textPrimary">
        The offers that appear on this site are from third party advertisers from
        which we receive compensation. This compensation may impact how, where, and
        in what order the products appear on this site. The offers on this site do
        not represent all available financial services, companies, or products.
      </Typography>
    </Box>
  </Dialog>
);

export default AdvertiserDisclosureModal;
