import { TNode, TElement, isElement } from '@udecode/plate';
import remarkSlate, { serialize } from 'remark-slate';
import unified from 'unified';
import markdown from 'remark-parse';

const nodeTypes = {
  paragraph: 'p',
  block_quote: 'blockquote',
  code_block: 'codeblock',
  link: 'link',
  ul_list: 'ul',
  ol_list: 'ol',
  listItem: 'li',
  heading: {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  },
  emphasis_mark: 'italic',
  strong_mark: 'bold',
  delete_mark: 'strikethrough',
  inline_code_mark: 'code',
  thematic_break: 'thematicbreak',
  image: 'image',
};

/*
 * This is kinda annoying but for serializing to work we have to replace 'lic' to 'p'
 * (and the other way for when deserializing).
 *
 * {
 *   "type": "ul",
 *   "children": [
 *     {
 *       "type": "li",
 *       "children": [
 *         {
 *           "type: "lic" (editor default) vs "p" (what the serializer expect),
 *           "children": [
 *             {
 *               "text": "aloha"
 *             }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 * }
 */
function convert(node: TNode, toSlate: boolean, pType?: string): TNode {
  const to = (toSlate ? 'lic' : 'p');
  const match = (toSlate ? 'p' : 'lic');

  if (isElement(node)) {
    return {
      ...node,
      type: (node.type === match && pType === 'li') ? to : node.type,
      children: node.children.map(child => convert(child, toSlate, node.type)),
    } as TElement;
  }
  return node;
}

export const slateToMd = (state: TNode[]): string => (
  state.map(v => serialize(convert(v, false) as TElement, { nodeTypes })).join('')
);

export const mdToSlate = (content: string): TNode[] => {
  const tree = unified()
    .use(markdown)
    .use(remarkSlate, { nodeTypes })
    .processSync(content);

  return (tree.result as TNode[]).map(r => convert(r, true));
};
