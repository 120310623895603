import { lazy, ComponentType } from 'react';

/**
 * The retry function will retry a promise until success.
 *
 * @param fn A function that returns a promise
 * @param retriesLeft The number of retries before returning an error
 * @param interval Time period to wait before retrying
 */

const retry = <T>(
  fn: () => Promise<T>,
  retriesLeft = 5,
  interval = 1000,
) => new Promise<T>((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error: Error) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          reject(error);
          return;
        }

        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

/**
 * The lazy retry function should be used in place of a lazy call. It will retry the
 * import 5 times before throwing an error. This is helpful when preventing chunking
 * failure issues.
 *
 * @template T
 * @param {() => Promise<{ default: T }>} fn
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyRetry = <T extends ComponentType<any>>(fn: () => Promise<{ default: T }>) =>
  lazy(() => retry(fn));

export default lazyRetry;
