import { useState, useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useFormikContext } from 'formik';

import { useSendEmailLeadsMutation } from 'types';
import { InputField } from 'common/components/formik';
import { useGql, useLogEvent } from 'common/hooks';
import { Button, Checkbox, Dialog } from 'common/components/material-ui';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useSnack } from 'common/utils/snackCart';

type Props = {
  contractorId: string,
  onClose: () => void,
  isOpen: boolean,
  onBack: () => void,
};

type FormValues = {
  file: string;
  emails: string;
};

const TermsAndConditions = `Shogun Enterprises Inc. doing business as “Hearth” (“Hearth”, “Platform”, “we”, “us” and “our”) provides software services via its website (www.gethearth.com) along with an email blast service, in which you (“you” or “your”) can upload a list of potential leads that Hearth will send out via email. By enrolling in this service through the Hearth platform, you agree to these Terms of Use (“CONTRACTOR EMAIL LEAD BLAST”).

1. Hearth Policies
The Contractor Email Lead Blast terms are incorporated by reference and are governed by Hearth’s Terms of Use, Privacy Policy, and Financial Privacy Policy, as they may be modified from time to time (collectively, the “Hearth Policies”). By using this service you agree to the Hearth Policies. All capitalized terms used but not defined herein shall have the respective meanings given to them in the Hearth Policies.

2. Hearth Contractor Agreement
Notwithstanding anything to the contrary, you agree to all the terms and conditions set forth in the Hearth Contractor Agreement.

3. Contractor Email Lead Blast
By using the Contractor Email Lead Blast, you confirm and acknowledge the following:

a) The lead recipients have not opted-out of receiving future marketing solicitations in accordance with the CANSPAM ACT of 2003, see 15 USC 7701, et al.
b) The lead recipients have not been purchased by a lead generation source or other provider.
c) You will not send more than 2 emails per week to your lead recipient.
d) You will notify and update your lead recipient list if a lead recipient chooses to opt out of your email campaigns.
e) All lead recipients have agreed to share their email addresses and names with Hearth and you can provide sufficient proof of their consent to share.

4. Limitation of Liability
TO THE MAXIMUM EXTENT PERMITTED BY LAW, HEARTH WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OR LOST PROFITS ARISING OUT YOUR USAGE OF CONTRACTOR EMAIL LEAD BLAST SERVICE.  HEARTH’S AGGREGATE LIABILITY FOR ANY CLAIM ARISING HEREUNDER SHALL BE LIMITED TO THE LIABILITY LIMITATIONS IN THE HEARTH CONTRACTOR AGREEMENT.

5. Indemnification
In addition to indemnification under the Hearth Contractor Agreement and Hearth’s Terms of Use, you agree to indemnify and hold harmless Hearth, it Affiliates and licensors, and each of their respective officers, directors, employees, agents and successors and assigns (each, a “Company Indemnitee”), from and against any and all claims, actions, suits, demands, causes of actions, losses, liabilities, damages, costs and expenses, incurred or otherwise suffered by each Company Indemnitee (including but not limited to cost of defense, investigation and reasonable attorneys’ fees), which are attributable to or arise from any disputes between you and your Customer. If and as requested by Hearth, you agree to defend, at your cost, each Company Indemnitee in connection with any third party claims, demands, or causes of action resulting from, arising out of or in connection with any of the foregoing, provided that you shall not settle any claim, action or suit without the prior written consent of Hearth. HEARTH HAS NO OBLIGATION TO DEFEND, INDEMNIFY OR HOLD YOU HARMLESS UNDER THIS AGREEMENT.

6. Terms of Use Subject to Change
These Terms of Use are subject to change at our sole discretion.

7. General
In all other respects, the Hearth Policies shall remain unchanged and in full force and effect in accordance with the terms thereof.  In the event of any conflict between this Contractor Email Lead Blast terms, Hearth Contractor Agreement, and the Hearth Policies, the provisions shall govern in the following order: this Contractor Email Lead Blast Terms, the Hearth Contractor Agreement, and the Hearth Policies.  This Contractor Email Lead Blast Terms is governed by the laws of the State of Texas.`;

const EmailLeadsPreviewModal = ({
  onClose,
  contractorId,
  isOpen,
  onBack,
}: Props): JSX.Element => {
  const itly = useLogEvent();
  const { values } = useFormikContext<FormValues>();
  const snack = useSnack();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [sendEmailLeadsMutation] = useSendEmailLeadsMutation();
  const { handleMutationError } = useGql();

  useMount(() => itly.submitEmailLeadsList({ type: values.emails ? 'manual' : 'csv' }));

  useEffect(() => {
    if (termsAgreed) itly.signTermsAndConditionsForEmailLeads();
  }, [itly, termsAgreed]);

  const sendEmails = useCallback(async () => {
    try {
      const response = await sendEmailLeadsMutation({
        variables: {
          file: values.file,
          emails: values.emails,
          contractorId: contractorId as unknown as string,
        },
      });

      const parsedResponse = parseGql<PayloadType<typeof response, 'sendEmailLead'>>(
        'sendEmailLead',
        response,
        'SendEmailLeadSuccess',
        'SendEmailLeadFailure',
      );

      itly.sendEmailLeads({ numberOfEmailsUploaded: parsedResponse.count });

      snack.successSnack(`Email${parsedResponse.count !== 1 ? 's ' : ' '}
        sent to ${parsedResponse.count} ${parsedResponse.count === 1 ? 'contact' : 'contacts'}!`);
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    itly,
    snack,
    handleMutationError,
    values,
    contractorId,
    sendEmailLeadsMutation,
  ]);

  return (
    <Dialog
      closeIconColor="basic700"
      scroll="body"
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      open={isOpen}
    >
      <Box ml={-1}>
        <Button variant="text" onClick={() => onBack()}>
          <Box mr={1} mt={0.5}>
            <ArrowBack color="primary" />
          </Box>
          Back
        </Button>
      </Box>
      <Box py={2}>
        <Typography variant="body1">Email leads</Typography>
        <Typography variant="h3">Preview and Send</Typography>
      </Box>
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="space-between"
      >
        <Box width="100%">
          <img
            src="https://hearth.imgix.net/marketing-email-leads-preview.png?auto=compress"
            alt="printable flyers"
            width="100%"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pl={2}
          width="100%"
        >
          <Box mt={2} />
          <Typography>Terms and Conditions</Typography>
          <Checkbox
            label={
              <Typography>I have read and agreed to the terms and conditions</Typography>
            }
            checked={termsAgreed}
            onChange={() => setTermsAgreed(!termsAgreed)}
          />
          <InputField
            name="emails"
            label=""
            rows={4}
            multiline
            disabled
            inputProps={{ value: TermsAndConditions }}
          />
          <Box my={2} display="flex" justifyContent="center">
            <Button
              disabled={!termsAgreed}
              variant="contained"
              buttonType="primary"
              onClick={async () => {
                await sendEmails();
                onClose();
                onBack();
              }}
              fullWidth
            >
              Send <ArrowForward />
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

EmailLeadsPreviewModal.SEND_EMAIL_LEADS = gql`
  mutation SendEmailLeads (
    $contractorId: ID!,
    $file: String!,
    $emails: String!
  ) {
    sendEmailLead (
      contractorId: $contractorId,
      file: $file,
      emails: $emails,
    ) {
      ... on SendEmailLeadSuccess {
        organization {
          id
        }
        count
      }
      ... on SendEmailLeadFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default EmailLeadsPreviewModal;
