import { createSelector, OutputSelector, SelectorArray } from 'reselect';

import { Organization } from 'contractor/types';
import { AppState } from 'stores';

/*
  eslint-disable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
export const idSelector = (state: any): number => state.organization.id;

export const organizationSelector = (state: any): Organization =>
  state.organization.organization;

/* eslint-enable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
export const answeredProjectLeadsSelector = createSelector(
  organizationSelector,
  organization => organization.answeredProjectLeads,
);

export const companyNameSelector = createSelector(
  organizationSelector,
  organization => organization.companyName,
);

export const verticalSelector = createSelector(
  organizationSelector,
  organization => organization.verticalId,
);

export const statedNoTeamSelector = createSelector(
  organizationSelector,
  organization => organization.statedNoTeam,
);

export const sentMemberInvitesSelector = createSelector(
  organizationSelector,
  organization => organization.sentMemberInvites,
);

export const hasWebsiteSelector = createSelector(
  organizationSelector,
  organization => organization.website !== '',
);

export const bannerInstalledSelector = createSelector(
  organizationSelector,
  organization => organization.bannerInstalledAt !== null,
);

export const subscriptionContractSelector = createSelector(
  organizationSelector,
  organization => organization.subscriptionContract,
);

export const featureFlagsSelector = createSelector(
  organizationSelector,
  organization => organization.featureFlags,
);

declare type OrganizationFeatureFlags =
  'contractor_banner_redesign_aug_2019' |
  'app_onboarding' |
  'home_single_card_test' |
  'loan_flow_v2' |
  'quick_poll_sep_2019' |
  'project_leads_sep_2019' |
  'zero_percent_cards_may_2019' |
  'zero_percent_credit_card_add_on' |
  'create_payment_plans' |
  'customer_details' |
  'know_before_you_go_pricing' |
  'email_video_sep_2019' |
  'project_uma_phase_one' |
  'progress_update_notifications' |
  'seats_training_video_test' |
  'homeowner_flow_dec_2019' |
  'org_stats_feb_2020' |
  'coterie_banner_apr_2020' |
  'payments_system' |
  'resource_center' |
  'sorting_offers_may2020' |
  'new_feature_callout_june2020' |
  'pop_cards_header_june2020' |
  'pop_no_header_june2020' |
  'marketing_banners';

const featureFlagSelectorFactory =
  (featureFlag: OrganizationFeatureFlags):
  OutputSelector<SelectorArray, boolean, (res: Organization) => boolean> =>
    createSelector(
      organizationSelector,
      organization => organization.featureFlags && organization.featureFlags.includes(featureFlag),
    );

const zeroPercentCardsOffersPageSelector =
  (state: Organization): boolean =>
    featureFlagSelectorFactory('zero_percent_cards_may_2019')(state) ||
    featureFlagSelectorFactory('zero_percent_credit_card_add_on')(state);

export const organizationFeatureFlagSelectors = {
  quickPollSep2019: featureFlagSelectorFactory('quick_poll_sep_2019'),
  projectLeadsSep2019: featureFlagSelectorFactory('project_leads_sep_2019'),
  zeroPercentAddOn: featureFlagSelectorFactory('zero_percent_credit_card_add_on'),
  zeroPercentCardsOffersPage: zeroPercentCardsOffersPageSelector,
  seatsTrainingVideoTest: featureFlagSelectorFactory('seats_training_video_test'),
  orgStatsFeb2020: featureFlagSelectorFactory('org_stats_feb_2020'),
  coterieBannerApr2020: featureFlagSelectorFactory('coterie_banner_apr_2020'),
};

export const tierAbilityFlagsSelector = (state: AppState): AbilityFlags => ({
  createPaymentPlans: featureFlagSelectorFactory('create_payment_plans')(state),
  customerDetails: featureFlagSelectorFactory('customer_details')(state),
  knowBeforeYouGo: featureFlagSelectorFactory('know_before_you_go_pricing')(state),
  progressUpdateNotifications: featureFlagSelectorFactory('progress_update_notifications')(state),
  paymentsSystem: featureFlagSelectorFactory('payments_system')(state),
  resourceCenter: featureFlagSelectorFactory('resource_center')(state),
  marketingBanners: featureFlagSelectorFactory('marketing_banners')(state),
});

export const createdAtSelector = createSelector(
  organizationSelector,
  organization => organization.createdAt,
);

export const seatsLeftSelector = createSelector(
  organizationSelector,
  organization => organization.seatsLeft,
);

export const isActiveAccount = createSelector(
  organizationSelector,
  organization => ['active', 'past_due', 'trialing'].includes(organization.status),
);

export const canHaveSeatsSelector = createSelector(
  organizationSelector,
  organization => organization.seats > 1,
);

export const paywallSelector = createSelector(
  organizationSelector,
  organization => ['past_due', 'canceled'].includes(organization.status),
);

export const companyTypeSelector = createSelector(
  organizationSelector,
  organization => organization.companyType,
);

export const orgSetupCompletedSelector = createSelector(
  organizationSelector,
  organization => organization.setupFlowCompleted,
);
