import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { camelizeKeys } from 'humps';

const useQueryParams = <T extends Record<string, string | undefined>>(): T => {
  const location = useLocation();

  return useMemo(() => (camelizeKeys(parse(location.search)) as T), [location.search]);
};

export default useQueryParams;
