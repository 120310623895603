import { useMemo, useState } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { MenuItem, Menu, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';
import { PaymentsRole, useAddNewButtonQuery } from 'types';
import userFeatureFlags from 'common/graphql/featureFlags';
import { isDebug } from 'config/app';
import { TrainingBlockModal } from 'contractor/components/widgets';

import { PaymentsSetupDialog } from '../../components';

type StyleProps = {
  color: Color;
  menuColor: Color;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  addButton: ({ color }) => ({
    borderRadius: 22,
    color: theme.palette.common[color],
    boxShadow: 'none',
    border: `2px solid ${theme.palette.common[color]}`,
  }),
  clientViewButton: ({ menuColor }) => ({
    color: theme.palette.common[menuColor],
  }),
  menu: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
}));

type Props = {
  menuColor: Color;
  color: Color;
  buttonVariant?: 'contained' | 'outlined';
}

const AddNewButton = ({
  menuColor,
  color,
  buttonVariant = 'contained',
}: Props): JSX.Element | null => {
  const location = useLocation();
  const history = useHistory();
  const [addNewAnchorEl, setAddNewAnchorEl] = useState<HTMLElement>();
  const [paymentsSetupOpen, setPaymentsSetupOpen] = useState(false);
  const [trainingBlockOpen, setTrainingBlockOpen] = useState(false);
  const classes = useStyles({ menuColor, color });
  const { contracts, hearthEssentials } = useReactiveVar(userFeatureFlags);

  const { data } = useAddNewButtonQuery();

  const stripeConnectAccount = data?.organization?.stripeConnectAccount;
  const contractor = data?.contractor;
  const organization = data?.organization;
  const hasContracts = data?.organization?.contractServiceAccount?.termsAgreedAt;

  // we want to show the send contract button if:
  // 1. the contracts feature flag is enabled
  // 2. the org is NOT a hearth essentials org
  // 3. the org has contracts set up
  // 4. if contracts is NOT set up, only if the contractor is an admin
  const showContractsButton = useMemo(() =>
    contracts && !hearthEssentials && (hasContracts || contractor?.admin),
  [
    contracts,
    hearthEssentials,
    hasContracts,
    contractor?.admin,
  ]);

  const hasPaymentsAccess = stripeConnectAccount?.fullAccess;
  const showPaymentsButton = useMemo(() => {
    if (hasPaymentsAccess) {
      // If the org has payments access but the contractor
      // is payments blocked, don't show the payments button
      return contractor?.paymentsRole !== PaymentsRole.BLOCKED;
    }
    // If a contractor is a member and the org
    // does not have payments access, hide the button
    return contractor?.role !== 'Member';
  }, [hasPaymentsAccess, contractor]);

  const showPaymentsSetup = contractor?.paymentsRole === PaymentsRole.ADMIN && !hasPaymentsAccess;

  return (
    <>
      <TrainingBlockModal
        open={trainingBlockOpen}
        onClose={() => setTrainingBlockOpen(false)}
      />
      {paymentsSetupOpen &&
        <PaymentsSetupDialog
          open={paymentsSetupOpen}
          onClose={() => setPaymentsSetupOpen(false)}
        />
      }
      <Button
        variant={buttonVariant}
        color="inherit"
        className={classes.addButton}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAddNewAnchorEl(event.currentTarget)
        }
      >
        <Add color="inherit" />
        Add New
      </Button>
      <Menu
        id="add-new-menu"
        anchorEl={addNewAnchorEl}
        keepMounted
        open={Boolean(addNewAnchorEl)}
        onClose={() => setAddNewAnchorEl(undefined)}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.menu }}
      >
        <MenuItem>
          <Button
            onClick={() => {
              // trainingCompleted doesn't update in dev mode, so the isDebug
              // flag is needed so we can still use this functionality in dev mode
              if (contractor?.trainingCompleted || isDebug) {
                history.push('/dashboard/request/offer-financing?source=client_view', {
                  background: location,
                });
              } else {
                setTrainingBlockOpen(true);
              }
              setAddNewAnchorEl(undefined);
            }}
            variant="text"
            className={classes.clientViewButton}
          >
            <Add color="inherit" />
            Offer Financing
          </Button>
        </MenuItem>
        {showPaymentsButton &&
          <MenuItem>
            <Button
              onClick={() => {
                if (showPaymentsSetup) {
                  setPaymentsSetupOpen(true);
                  setAddNewAnchorEl(undefined);
                  return;
                }
                history.push('/dashboard/request/request-payment?source=client_view', {
                  background: location,
                });
              }}
              variant="text"
              className={classes.clientViewButton}
            >
              <Add color="inherit" />
              Request Payment
            </Button>
          </MenuItem>
        }
        {showContractsButton &&
          <MenuItem>
            <Button
              onClick={() => {
                if (organization?.contractServiceAccount?.termsAgreedAt) {
                  history.push('/dashboard/request/send-contract?source=client_view', {
                    background: location,
                  });
                } else {
                  history.push('/dashboard/tools/contract-template/setup?source=client_view');
                }
                setAddNewAnchorEl(undefined);
              }}
              variant="text"
              className={classes.clientViewButton}
            >
              <Add color="inherit" />
              Send Contract
            </Button>
          </MenuItem>
        }
      </Menu>
    </>
  );
};

AddNewButton.ADD_NEW_BUTTON_QUERY = gql`
  query AddNewButton {
    organization {
      id
      stripeConnectAccount {
        id
        status
        fullAccess
      }
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
    contractor {
      id
      admin
      role
      paymentsRole
      trainingCompleted
    }
  }
`;

export default AddNewButton;
