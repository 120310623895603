import {
  Box,
  Typography,
  Divider,
  Grid,
  TypographyProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { currencyFormatFromCents } from 'common/utils/stringFormatters';

import { colors, StyleProps, TemplateProps } from './theme';
import { Attachments, Links, SignatureMessage } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.basic100,
    padding: '48px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: 792,
  },
  themeColor: {
    color: ({ themeColor }: StyleProps) => colors[themeColor],
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,
    paddingRight: 24,
    paddingLeft: 24,
  },
  invoiceTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  logo: {
    maxWidth: 240,
    maxHeight: 92,
    width: 'auto',
    height: 'auto',
  },
  contactDetailsContainer: {
    marginTop: 32,
    paddingRight: 24,
    paddingLeft: 24,
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '20px',
  },
  organizationDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  descriptionBox: {
    border: `1px solid ${theme.palette.common.basic300}`,
    borderRadius: 8,
    padding: '16px 24px',
    marginTop: 36,
    marginBottom: 32,
    minHeight: 100,
  },
  linksContainer: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  footer: {
    marginTop: 32,
    marginBottom: 16,
  },
}));

type BodyTextProps = {
  align?: TypographyProps['align'];
  children: React.ReactNode;
}

const BodyText = ({ children, align = 'left' }: BodyTextProps) => (
  <Box mt={0.5} style={{ wordBreak: 'break-word' }}>
    <Typography variant="body2" color="textPrimary" align={align}>
      {children}
    </Typography>
  </Box>
);
const ClassicTemplate = ({
  settings,
  organization,
  invoice,
  links,
  attachments,
}: TemplateProps): JSX.Element => {
  const classes = useStyles({ themeColor: settings.primaryColor });

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.header}>
          <Box className={classes.invoiceTitle}>
            <Box className={classes.themeColor} data-testid="invoice-title-theme-color">
              <Typography variant="h3" color="inherit">
                Invoice #{invoice.invoiceNumber}
              </Typography>
            </Box>
            <Box mt={2} />
            {invoice.sentAt &&
              <BodyText>
                Sent: {format(Date.parse(invoice.sentAt), 'MMMM d, yyyy')}
              </BodyText>
            }
            {invoice.dueDate &&
              <BodyText>
                Due: {format(Date.parse(invoice.dueDate), 'MMMM d, yyyy')}
              </BodyText>
            }
          </Box>
          {settings.logo && organization.logoUrl &&
            <img
              src={organization.logoUrl}
              className={classes.logo}
              alt="Company logo"
            />
          }
        </Box>
        <Divider variant="fullWidth" />
        <Grid container className={classes.contactDetailsContainer}>
          <Grid item sm={6} className={classes.contactDetails}>
            <Typography variant="subtitle2" color="textPrimary">
              Invoice To:
            </Typography>
            <Box mt={1} />
            <BodyText>
              {invoice.fullName}
            </BodyText>
            <BodyText>
              {invoice.phoneNumber}
            </BodyText>
            <BodyText>
              {invoice.email}
            </BodyText>
          </Grid>
          <Grid item sm={6} className={classes.contactDetails}>
            <Typography variant="subtitle2" color="textPrimary" align="right">
              {organization.companyName}
            </Typography>
            <Box mt={1} />
            <BodyText align="right">
              {organization.fullAddress}
            </BodyText>
            <BodyText align="right">
              {organization.businessPhone}
            </BodyText>
            <BodyText align="right">
              {organization.businessEmail}
            </BodyText>
          </Grid>
        </Grid>
        <Box className={classes.descriptionBox}>
          <Typography variant="subtitle2" color="textPrimary">
            Description:
          </Typography>
          <Box mt={1} />
          <Typography variant="body2" color="textPrimary">
            {invoice.description}
          </Typography>
        </Box>
        <Box px={3}>
          {invoice.personalNote &&
            <>
              <Typography variant="subtitle2" color="textPrimary">
                Note:
              </Typography>
              <Box mt={1} />
              <Typography variant="body2" color="textPrimary">
                {invoice.personalNote}
              </Typography>
            </>
          }
          <Box mt={4} />
          <Typography variant="subtitle2" color="textPrimary">
            Total
          </Typography>
          <Box className={classes.themeColor}>
            <Typography variant="h2" color="inherit">
              {currencyFormatFromCents(invoice.amountRequested, 2)}
            </Typography>
          </Box>
          <SignatureMessage signatureMessage={settings.signatureMessage} />
          {attachments.length > 0 &&
            <Attachments attachments={attachments} />
          }
        </Box>
      </Box>
      {links.length > 0 &&
        <Box className={classes.footer}>
          <Divider variant="fullWidth" />
          <Box className={classes.linksContainer}>
            <Links links={links} themeColor={settings.primaryColor} />
          </Box>
        </Box>
      }
    </Box>
  );
};

export default ClassicTemplate;
