import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  compose,
  withProps,
  defaultProps,
  mapProps,
} from 'recompose';
import padStart from 'lodash/padStart';

import withLabel from './withLabel';
import withField from './withField';

const StyledInput = styled('input', {
  name: 'StyledInput',
})(({ theme }) => ({
  borderColor: 'transparent',
  backgroundColor: 'transparent',
  width: '100%',
  outline: 'none',
  padding: '12px 0',
  color: theme.palette.common.basic1100,
  fontSize: 18,
  lineHeight: 1,

  '&::placeholder': {
    color: theme.palette.common.basic700,
  },
}));

const maskTypes = {
  money: {
    normalize: text => text.replace(/[^0-9]+/g, ''),
    denormalize: value => parseInt(value, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    extraProps: {
      prefix: <span className="fa fa-dollar fa-fw" />,
      fixedCursor: true,
    },
  },
  ssn: {
    normalize: (text, previousValue = '') => {
      const number = text.replace(/[^0-9●]+/g, '');
      // depending on the number in text, we either add the last digit from text or remove last
      // digit
      return (number.length > previousValue.length) ?
        (previousValue + number[number.length - 1]).substring(0, 9) :
        previousValue.slice(0, -1);
    },
    denormalize: (rawValue) => {
      // at most we want to take out first 5 digits or everything except the last digit
      const hiddenLength = Math.min(rawValue.length - 1, 5);

      // replace hidden part with dots
      const value = padStart(rawValue.substring(hiddenLength, 9), rawValue.length, '●');
      const parts = [value.substring(0, 3), value.substring(3, 5), value.substring(5, 9)];
      return `${parts[0]}${parts[1] && ` — ${parts[1]}`}${parts[2] && ` — ${parts[2]}`}`;
    },
    extraProps: {
      suffix: <span className="fa fa-lock fa-fw" />,
      maxLength: 15,
      fixedCursor: true,
    },
  },
  lastFourSsn: {
    normalize: text => text.replace(/[^0-9]+/g, ''),
    denormalize: value => value,
    extraProps: {
      placeholder: '0000',
      suffix: <span className="fa fa-lock fa-fw" />,
      maxLength: 4,
      fixedCursor: true,
      autoComplete: 'off',
    },
  },
  phone: {
    // value is in this format XXX-XXX-XXXX
    normalize: (text) => {
      const strippedText = text.replace(/[^0-9]+/g, '');
      return `${strippedText.substring(0, 3)}-${strippedText.substring(3, 6)}-${strippedText.substring(6, 10)}`;
    },
    denormalize: (rawValue) => {
      const value = rawValue.replace(/[^0-9]+/g, '');
      const parts = [value.substring(0, 3), value.substring(3, 6), value.substring(6, 10)];
      return `${parts[0] && `(${parts[0]}`}${parts[1] && `) ${parts[1]}`}${parts[2] && `-${parts[2]}`}`;
    },
    extraProps: {
      prefix: <span className="fa fa-phone fa-fw" />,
      maxLength: 14,
      fixedCursor: true,
      placeholder: '(555) 555-5555',
    },
  },
  default: {
    normalize: text => text,
    denormalize: text => text,
    extraProps: {},
  },
};

const Input = ({ name, inputRef, ...otherProps }) => (
  <StyledInput
    id={name}
    ref={inputRef}
    name={name}
    {...otherProps}
  />
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
};

Input.defaultProps = {
  inputRef: undefined,
};

export default compose(
  defaultProps({
    mask: 'default',
    fixedCursor: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
  }),
  withProps(({ mask }) => ({
    maskOption: maskTypes[mask],
  })),
  mapProps(({
    value,
    defaultValue,
    maskOption,
    onChange,
    fixedCursor,
    onFocus,
    onBlur,
    ...otherProps
  }) => ({
    defaultValue: defaultValue ? maskOption.denormalize(defaultValue) : '',
    value: value ? maskOption.denormalize(value) : '',
    onChange: (event) => {
      const length = event.target.value.length;

      // don't allow change in the middle if fixedCursor set to true
      if (fixedCursor && event.target.selectionStart !== length) {
        event.target.selectionRange(length, length);
        return;
      }

      onChange({
        ...event,
        target: {
          ...event.target,
          value: maskOption.normalize(event.target.value, value),
          name: event.target.name,
        },
      });
    },
    onFocus: (event) => {
      onFocus(event);
    },
    onBlur: (event) => {
      onBlur(event);
    },
    ...maskOption.extraProps,
    ...otherProps,
  })),
  withLabel,
  withField,
)(Input);
