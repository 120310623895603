import { useMemo } from 'react';

import { useGetGetStartedDataQuery } from 'types';

import GetStarted from './GetStarted';
import { GetStartedProvider } from './useGetStarted';

const GetStartedContainer = (): JSX.Element | null => {
  const query = useGetGetStartedDataQuery();
  const contractor = useMemo(() => (
    query?.data?.contractor
  ), [query.data]);
  const organization = useMemo(() => (
    query?.data?.organization
  ), [query.data]);

  if (!contractor || !organization) {
    return null;
  }

  return (
    <GetStartedProvider>
      <GetStarted contractor={contractor} organization={organization} />
    </GetStartedProvider>
  );
};

export default GetStartedContainer;
