import { Dialog, Fab, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { DialogProps } from '@mui/material/Dialog';

export type PopupProps = {
  onClose: () => void;
  closable?: boolean;
} & DialogProps;

type StyleProps = {
  fullScreen: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  paper: {
    'overflow-y': 'visible',
  },
  fabRoot: ({ fullScreen }) => ({
    position: 'absolute',
    minHeight: 'unset',
    height: 34,
    width: 34,
    top: fullScreen ? 8 : -16,
    right: fullScreen ? 8 : -16,
    zIndex: 1,
    backgroundColor: theme.palette.common.basic100,
  }),
}));

/**
 *
 * @deprecated Use custom Dialog component instead
 */
const Popup = ({
  onClose,
  open,
  children,
  closable = true,
  fullScreen = false,
  ...otherProps
}: PopupProps): JSX.Element => {
  const classes = useStyles({ fullScreen });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      disableEscapeKeyDown={!closable}
      fullScreen={fullScreen}
      {...otherProps}
      scroll="body"
    >
      {closable &&
        <Fab
          classes={{ root: classes.fabRoot }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </Fab>
      }
      {children}
    </Dialog>
  );
};

export default Popup;
