import { SvgIconProps } from '../Icon';

const EnvelopeOpenIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...otherProps}>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M5.063 11.625l3-2.175v3.812l-3-1.637zM21.563 11.625l-3-2.175v3.812l3-1.637z"
        fill={color}
      />
      <path
        d="M8.063 9.45l-3 2.175v9h16.5v-9l-3-2.175"
        stroke={color}
        strokeWidth={0.75}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.063 13.275v-9.15h10.5v9.15M5.063 11.625l16.5 9M21.563 11.625l-8.25 4.5M11.063 7.875h4.5M11.063 10.875h4.5"
        stroke={color}
        strokeWidth={0.75}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(3.938 3)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EnvelopeOpenIcon;
