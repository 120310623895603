import { Box, Typography, Skeleton } from '@mui/material';

import { useMount } from 'react-use';

import useLogEvent from 'common/hooks/useLogEvent';
import { ToggleRow } from 'common/components/widgets';
import { useSettingsActions, useSettings } from './useSettings';

const NotificationPreferences = (): JSX.Element => {
  const { contractor } = useSettings();
  const { editContractorSettings } = useSettingsActions();
  const itly = useLogEvent();

  useMount(() => {
    itly.viewSettingSection({ section: 'notification' });
  });

  return (
    <Box bgcolor="common.basic100" borderRadius="20px" p={5}>
      <Typography variant="h2" color="textPrimary">
        Notification Preferences
      </Typography>
      <Box mt={3} px={3} py={4} borderRadius="8px" bgcolor="common.basic300">
        {contractor ?
          <>
            <ToggleRow
              prompt="Text Messages"
              name="textNotifications"
              value={contractor.textNotifications}
              showLabel={false}
              onChange={() =>
                editContractorSettings({ textNotifications: !contractor.textNotifications })
              }
            />
            <Box mt={1} />
            <ToggleRow
              prompt="Emails"
              name="emailNotifications"
              value={contractor.emailNotifications}
              showLabel={false}
              onChange={() =>
                editContractorSettings({ emailNotifications: !contractor.emailNotifications })
              }
            />
          </> :
          <Skeleton variant="rectangular" width="100%" height={50} />
        }
      </Box>
    </Box>
  );
};

export default NotificationPreferences;
