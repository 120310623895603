import './AppLogos.scss';

const AppLogos = () => (
  <div className="app-logos">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://itunes.apple.com/us/app/hearth-for-contractors/id1383073333?mt="
    >
      <img
        alt="Available on the App Store"
        src="https://hearth.imgix.net/icons/app_store.png"
      />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.gethearth.katana&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
      <img
        alt="Get it on Google Play"
        src="https://hearth.imgix.net/icons/google_play.png?fit=scale&w=277&h=83"
      />
    </a>
  </div>
);

export default AppLogos;
