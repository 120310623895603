import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Tooltip } from 'common/components/material-ui';

const useStyles = makeStyles(({
  tooltipPopper: {
    minWidth: 400,
  },
}));

const DescriptionOfServicesTooltip = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <Box p={3} display="flex" flexDirection="column" color="common.basic1100">
          <Box display="flex" alignItems="center" mb={2}>
            <InfoOutlinedIcon style={{ fontSize: 24 }} />
            <Box mr={1} />
            <Typography variant="subtitle2">
              <b>Description of the Services</b>
            </Typography>
          </Box>
          <Typography variant="body2">
            (Optional) Save time by adding a description here that you want to appear every time{' '}
            you send a contract with this template. You’ll be able to modify the details for a{' '}
            specific customer when you send.
            <br />
            <br />
            Checklists allow quickly checking off items like value-added services when you{' '}
            send a contract.
            <br />
            <br />
            Custom text entry lists are good for job details you always include, such as size,{' '}
            color, and start dates.
          </Typography>
        </Box>
      }
      disableHoverListener={false}
      placement="bottom"
      clone
      bgColor="basic100"
      popperClass={classes.tooltipPopper}
    >
      <Box color="common.basic700" display="flex" alignItems="center">
        <InfoOutlinedIcon style={{ fontSize: 24 }} />
      </Box>
    </Tooltip>
  );
};

export default DescriptionOfServicesTooltip;
