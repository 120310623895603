import { AccordionDetails as MuiAccordionDetails, AccordionDetailsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type AccordionContentProps = AccordionDetailsProps

const AccordionDetail = styled(MuiAccordionDetails, {
  name: 'AccordionDetails',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const AccordionDetails = ({
  children,
  ...props
}: AccordionContentProps): JSX.Element => (
  <AccordionDetail {...props}>
    {children}
  </AccordionDetail>
);

export default AccordionDetails;
