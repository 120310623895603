import { useCallback } from 'react';
import { Box, Collapse } from '@mui/material';
import { useLocalStorage } from 'react-use';

import { GetStartedContractorFragment, GetStartedOrganizationFragment } from 'types';

import { GetStartedSidebar, GetStartedFloatingActionButton } from './components';

type Props = {
  contractor: GetStartedContractorFragment;
  organization: GetStartedOrganizationFragment;
};

const GetStarted = ({ contractor, organization }: Props): JSX.Element => {
  const [getStartedSidebarOpen, setGetStartedSidebarOpen] = useLocalStorage('@LOCAL/getStartedSidebarOpen', true);
  const openSidebar = useCallback(() => {
    setGetStartedSidebarOpen(!getStartedSidebarOpen);
  }, [getStartedSidebarOpen, setGetStartedSidebarOpen]);

  return (
    <Box
      sx={{
        position: 'fixed',
        right: 0,
        top: 64,
        height: '100%',
      }}
    >
      <Collapse
        in={getStartedSidebarOpen}
        orientation="horizontal"
        timeout={{
          enter: 550,
          exit: 550,
        }}
        sx={{ height: '100%' }}
      >
        <GetStartedSidebar
          openSidebar={openSidebar}
          contractor={contractor}
          organization={organization}
        />
      </Collapse>
      <Collapse
        in={!getStartedSidebarOpen}
        timeout={{
          enter: 300,
          exit: 200,
        }}
      >
        <GetStartedFloatingActionButton openSidebar={openSidebar} />
      </Collapse>
    </Box>
  );
};

export default GetStarted;
