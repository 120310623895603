import { Dialog } from 'common/components/material-ui/';

type Props = {
  open: boolean;
  onClose: () => void;
  image: string;
}

const ImageModal = ({ open, onClose, image }: Props): JSX.Element => (
  <Dialog open={open} onClose={onClose}>
    <img src={image} alt="quote job" />
  </Dialog>
);

export default ImageModal;
