import React, { useMemo, useCallback } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { CreateOutlined } from '@mui/icons-material';
import { useField } from 'formik';

import { Button } from 'common/components/material-ui';
import { ExpandableAddressField } from 'common/components/formik';

type Props = {
  open: boolean;
  setOpen: (arg0: boolean) => void;
}

type AddressType = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

const EditAddressField = ({ open, setOpen }: Props): JSX.Element => {
  const [{ value: address }, , { setValue }] = useField<AddressType>('address');

  // While saving the values to the form and reseting
  // them back to the initial values is really not ideal,
  // this seems like the best compromise between creating
  // a whole new address input (that doesn't use formik)
  // and nesting a form in a form. I tried putting a form
  // in a form, but there was a really weird bug where
  // when clicking submit would cause the page to refresh.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => address, [open]);

  const disabled = useMemo(() => [
    address.address === initialValues.address,
    address.city === initialValues.city,
    address.state === initialValues.state,
    address.zipCode === initialValues.zipCode,
  ].every(x => x), [address, initialValues]);

  const onCancel = useCallback(() => {
    setValue(initialValues);
    setOpen(false);
  }, [initialValues, setValue, setOpen]);

  const onConfirm = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  let hasAddress = false;
  const displayValue = 'Company address';
  let displayAddress = '';
  let displayLocation = '';

  if (address.address || address.city || address.state || address.zipCode) {
    hasAddress = true;
    displayAddress = `${address.address}`;
    displayLocation = `${address.city}, ${address.state} ${address.zipCode}`;
  }
  if (open) {
    return (
      <Box px={2} pt={3} pb={2}>
        <ExpandableAddressField
          name="address.address"
          cityName="address.city"
          stateName="address.state"
          zipCodeName="address.zipCode"
        />
        <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={onCancel}
          >
            <Typography variant="subtitle2">
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            buttonType="common.primary"
            onClick={onConfirm}
            disabled={disabled}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      px={3}
      py={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="subtitle2">
        {hasAddress ? (
          <>
            {displayAddress}
            <br />
            {displayLocation}
          </>
        ) : (
          <>
            {displayValue}
          </>
        )}

      </Typography>
      <IconButton
        edge="end"
        size="small"
        onClick={() => setOpen(true)}
      >
        <Box color="common.primary500">
          <CreateOutlined fontSize="large" />
        </Box>
      </IconButton>
    </Box>
  );
};

export default EditAddressField;
