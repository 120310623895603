import { Box, Typography, Divider } from '@mui/material';

import { LoanInquiry } from 'types';
import OtherOptions from 'contractor/modules/OtherOfferOptions';

import ZeroPercentCardOffer from './ZeroPercentCardOffer';
import { useSortingOffers } from '../useSortingOffers';
import UpgradeCardOfferBox from './UpgradeCardOfferBox';

/*
* showLowOffers: shows coborrower, adjust amount, and explore CC cards
* showCardOption: shows the credit card option
* showOtherOptions: header display condition so there isn't a header without cards
*/

type Props = {
  showLowOffers: boolean;
  showOtherOptions: boolean;
}

const Options = ({
  showLowOffers,
  showOtherOptions,
}: Props): JSX.Element => {
  const {
    inquiryState: { homeowner, loanInquiry },
    offersState: { upgradeLineOfCreditOffer, zeroPercentOfferPosition },
  } = useSortingOffers();

  return (
    <>
      {showOtherOptions &&
        <>
          <Box my={{ xs: 3, sm: 5 }} mx={-3}>
            <Divider />
          </Box>
          <Box mb={3}>
            <Typography variant="h2" color="textPrimary">
              Other options
            </Typography>
          </Box>
        </>
      }
      {upgradeLineOfCreditOffer &&
        <Box my={2}>
          <UpgradeCardOfferBox
            offer={upgradeLineOfCreditOffer}
          />
        </Box>
      }
      {zeroPercentOfferPosition === 'bottom' && homeowner &&
        <Box my={2}>
          <ZeroPercentCardOffer
            homeownerId={homeowner.id}
          />
        </Box>
      }
      {showLowOffers && homeowner != null &&
        <Box my={2}>
          <OtherOptions
            noOffers={false}
            loanInquiry={loanInquiry as LoanInquiry}
            homeownerId={homeowner.id}
            title="Get more options"
            description={'Here are some ways you can improve your financial profile in the eyes ' +
              'of lenders and possibly see more loan offers.'}
          />
        </Box>
      }
    </>
  );
};

export default Options;
