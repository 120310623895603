import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Popover,
  Typography,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem as MaterialListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu } from '@mui/icons-material';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { useAction } from 'promise-action';

import { useVerticalState } from 'common/hooks';
import { Button, ButtonBase, Link } from 'common/components/material-ui';
import { fetchMinimalOrg } from 'contractor/actions/organization';
import { GenericNavbar } from 'main/components/navs';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px 24px',
    height: 100,
    backgroundColor: theme.palette.common.basic100,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.common.basic500}`,
    [theme.breakpoints.down('md')]: {
      height: 64,
      padding: '8px 20px 8px 8px',
      zIndex: theme.zIndex.appBar,
    },
  },
  logo: {
    alignSelf: 'center',
    height: 24,
  },
  link: {
    paddingRight: 24,
  },
  support: {
    alignSelf: 'center',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: 'inherit',
    },
  },
  popoverPaper: {
    width: 340,
    padding: 20,
  },
  chevronLeftButton: {
    color: theme.palette.common.black,
    height: 24,
    width: 24,
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    color: theme.palette.common.black,
    height: 24,
    width: 24,
  },
  blankBox: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    width: 48,
  },
  menuCloseButton: {
    backgroundColor: theme.palette.common.basic500,
    padding: 4,
    flex: 1,
  },
  hr: {
    borderTopColor: theme.palette.common.basic500,
  },
  menuItem: {
    padding: '8px 16px 8px 0',
  },
}));

const HelpText = () => {
  const [{ brandMetadata, verticalMetadata }] = useVerticalState();
  const { dashboardHome: { support } } = verticalMetadata;

  return (
    <>
      <Typography variant="subtitle1">
        <Box fontWeight="fontWeightLight">
          Need help understanding your financing options?
          Call or email us and we’ll walk you through it.
        </Box>
      </Typography>
      <Box mt={2}>
        <Typography variant="h3">
          {brandMetadata.label} help:
        </Typography>
        <Box mt={1}>
          <Link
            variant="h3"
            color="primary"
            href={`tel:${support.phone}`}
          >
            {support.displayPhone}
          </Link>
        </Box>
        <Box mt={1}>
          <Link
            variant="h3"
            color="primary"
            href={`mailto:${support.email}`}
          >
            {support.email}
          </Link>
        </Box>
      </Box>
    </>
  );
};

type ListItemProps = {
  text: string;
  onClick: () => void;
};

const ListItem = ({ text, ...otherProps }: ListItemProps): JSX.Element => (
  <MaterialListItem style={{ padding: '8px 16px 8px 0' }}>
    <ButtonBase {...otherProps} style={{ textAlign: 'left' }}>
      <Typography variant="h5">
        {text}
      </Typography>
    </ButtonBase>
  </MaterialListItem>
);

type OrganizationInformationProps = {
  logoUrl: string;
  companyName: string;
};

const imageStyles = makeStyles(theme => ({
  image: {
    objectFit: 'contain',
    marginRight: 16,
    width: 64,
    height: 64,
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
}));

const OrganizationInformation = (
  { logoUrl, companyName }: OrganizationInformationProps,
): JSX.Element => {
  const classes = imageStyles();
  return (
    <Box display="flex" alignItems="center" ml={{ xs: 0, md: 5 }} color="common.basic1100">
      {logoUrl &&
        <img
          src={logoUrl}
          className={classes.image}
          alt="company logo"
        />
      }
      <div>
        <Typography variant="h5" color="inherit">
          {companyName}
        </Typography>
      </div>
    </Box>
  );
};

type MatchParams = {
  partner?: string;
  organizationId: string;
};

const HomeownerFlowNavbar = (
  { match, location, history }: RouteComponentProps<MatchParams>,
): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const homePath = location.pathname
    .replace('/prequalify', '')
    .replace('/estimate', '')
    .replace('/learn', '');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const { partner, organizationId } = match.params;

  const [
    { brandMetadata },
    { setBrand, setPartnerOrganizationId },
  ] = useVerticalState();

  useEffect(() => {
    if (partner === 'hilp') {
      setBrand(partner);
    }
    if (organizationId) {
      setPartnerOrganizationId(Number(organizationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const minimalOrganization = useAction(fetchMinimalOrg, { id: organizationId });

  if (!minimalOrganization.data) {
    return <GenericNavbar />;
  }

  const organization = minimalOrganization.data;

  return (
    <AppBar position="sticky" className={classes.root}>
      <Helmet>
        <title>
          {organization.companyTypeLabel} financing from{' '}
          {organization.companyName} | {brandMetadata.label}
        </title>
      </Helmet>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <OrganizationInformation
          logoUrl={organization.logoUrl}
          companyName={organization.companyName}
        />
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={() => setDrawerOpen(!drawerOpen)}
          className={classes.menuButton}
          size="large"
        >
          <Menu className={classes.menuIcon} />
        </IconButton>
      </Box>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        flex={1}
        justifyContent="space-between"
      >
        <OrganizationInformation
          logoUrl={organization.logoUrl}
          companyName={organization.companyName}
        />
        <Box display="flex" alignItems="center">
          <Button
            variant="text"
            buttonType="secondary"
            component={RouterLink}
            to={{
              ...location,
              pathname: `${homePath}/learn`,
            }}
          >
            About {brandMetadata.label}
          </Button>
          <Box ml={6}>
            <Button
              variant="text"
              buttonType="secondary"
              component={RouterLink}
              to={{
                ...location,
                pathname: `${homePath}/prequalify`,
              }}
            >
              Pre-qualify now
            </Button>
          </Box>
          <Link onClick={handleClick}>
            <Box ml={6} mr={5}>
              <img
                src={brandMetadata.logoSrc}
                style={{
                  height: 24,
                  objectFit: 'contain',
                }}
                alt={`${brandMetadata.label} logo`}
              />
            </Box>
          </Link>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.popoverPaper }}
        >
          <HelpText />
        </Popover>
      </Box>
      <SwipeableDrawer
        anchor="top"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        style={{ zIndex: 1000 }}
      >
        <Box m={5} mt={12}>
          <List>
            <ListItem
              text={`About ${brandMetadata.label}`}
              onClick={() => {
                history.push({
                  ...location,
                  pathname: `${homePath}/learn`,
                });
                setDrawerOpen(false);
              }}
            />
            <Box mt={1} />
            <ListItem
              text="Pre-qualify now"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: `${homePath}/prequalify`,
                });
                setDrawerOpen(false);
              }}
            />
            <ListItem
              text="Estimate monthly payments"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: `${homePath}/estimate`,
                });
                setDrawerOpen(false);
              }}
            />
            <ListItem
              text="Learn more"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: `${homePath}/learn`,
                });
                setDrawerOpen(false);
              }}
            />
          </List>
          <hr className={classes.hr} />
          <HelpText />
        </Box>
        <ButtonBase
          onClick={() => setDrawerOpen(false)}
          classes={{ root: classes.menuCloseButton }}
        >
          <Typography variant="caption">
            Close {brandMetadata.label} help menu
          </Typography>
        </ButtonBase>
      </SwipeableDrawer>
    </AppBar>
  );
};

export default HomeownerFlowNavbar;
