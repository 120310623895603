import { useEffect } from 'react';
import { Container } from '@mui/material';

import { OffersProvider, useSortingOffers } from 'contractor/modules/SortingOffers/useSortingOffers';
import { Loader } from 'common/components/widgets';
import { LoanFilterModal } from 'contractor/modules/SortingOffers/components';
import SortingOffers from 'contractor/modules/SortingOffers';
import ReturningApplicantBanner from 'contractor/modules/SeeOffers/ReturningApplicantBanner';
import useLogEvent from 'common/hooks/useLogEvent';

type Props = {
  loanInquiryUuid: string;
}

const OffersContainer = ({ loanInquiryUuid }: Props): JSX.Element | null => {
  const {
    offersState: { offers, loading, lowOffers },
    processState: { loanFilterModalOpen },
    actions: { setLoanFilterModalOpen },
    inquiryState: { homeowner },
  } = useSortingOffers();
  const itly = useLogEvent();

  const numberOfLenders = new Set(offers.map(offer => offer.lenderName)).size;
  const minAprInDecimal = Math.min(...offers.map(offer => Number(offer.apr))) || 0;
  const maxLoanAmountInDollar = Math.max(...offers.map(offer => Number(offer.loanAmount))) || 0;

  useEffect(() => {
    if (loading || !homeowner?.id) return;

    itly.viewFinancingOffers({
      loanInquiryUuid,
      homeownerId: homeowner.id,
      numberOfOffers: offers.length,
      numberOfLenders,
      minAprInDecimal,
      maxLoanAmountInDollar,
    });
  }, [
    itly,
    loanInquiryUuid,
    numberOfLenders,
    minAprInDecimal,
    maxLoanAmountInDollar,
    loading,
    homeowner?.id,
    offers.length,
  ]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ReturningApplicantBanner
        existingInquiries={2}
        copy="You’ve pre-qualified for options through Hearth before.
              Click here if you’d like to view your previous options."
      />
      {loanFilterModalOpen &&
        <LoanFilterModal
          isOpen={loanFilterModalOpen}
          onClose={() => setLoanFilterModalOpen(false)}
        />
      }
      <SortingOffers
        showLowOffers={offers.length <= lowOffers}
      />
    </>
  );
};

export default (props: Props): JSX.Element => (
  <OffersProvider {...props}>
    <Container disableGutters maxWidth={false}>
      <OffersContainer {...props} />
    </Container>
  </OffersProvider>
);
