import {
  Card,
  Typography,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Box,
} from '@mui/material';

import { Button } from 'common/components/material-ui';

type Props = {
  href: string;
};

const ExploreCreditCard = ({ href }: Props): JSX.Element => (
  <Card>
    <CardActionArea>
      <CardMedia
        component="img"
        alt="credit card"
        image="https://hearth.imgix.net/nooffers/card.png"
        height="180"
        title="Credit card"
      />
    </CardActionArea>
    <CardContent>
      <Box px={3} pt={1}>
        <Typography variant="h3" gutterBottom>
          Consider a credit card
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          Although we couldn’t pre-qualifiy you for a personal loan to fund your project, we do
          offer other financing options. Based on your request a credit card might be a great option
          for your situation.
        </Typography>
      </Box>
    </CardContent>
    <CardActions>
      <Box px={3} pb={2.5} width="100%" display="flex" justifyContent="flex-end">
        <Button
          buttonType="primary"
          href={href}
          variant="contained"
          component="a"
          target="_blank"
        >
          Explore credit cards
        </Button>
      </Box>
    </CardActions>
  </Card>
);

export default ExploreCreditCard;
