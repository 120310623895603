import { Box, Typography, Divider } from '@mui/material';
import { Button } from 'common/components/material-ui';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Dialog, { DialogProps } from 'common/components/material-ui/Dialog';

type Props = {
  title: React.ReactNode;
} & Omit<DialogProps, 'children'>;

const AdBlockModal = ({ title, ...otherProps }: Props): JSX.Element => (
  <Dialog maxWidth="sm" {...otherProps} aria-label="Show message to disable AdBlock extensions">
    <Box sx={{ p: 2, color: 'common.basic1100' }}>
      <Box sx={{ display: 'flex' }}>
        <WarningAmberIcon sx={{ fontSize: 48, mr: 3 }} />
        {typeof title === 'string' ?
          <Typography variant="h3">
            {title}
          </Typography> :
          title
        }
      </Box>
      <Divider sx={{ my: 3, mx: -5 }} />
      <ol>
        <li>
          <>
            <Typography variant="subtitle1">
              Click the icon of the ad blocker extension installed on your browser.
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }}>
              You’ll usually find this icon in the upper right-hand corner of your screen. You may
              have more than one ad blocker installed.
            </Typography>
          </>
        </li>
        <li>
          <>
            <Typography variant="subtitle1">
              Follow the instructions for disabling the ad blocker on the site you’re viewing.
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }}>
              You may have to select a menu option or click a button.
            </Typography>
          </>
        </li>
        <li>
          <Typography variant="subtitle1">
            Refresh the page, either by following prompts or clicking your browser’s “refresh” or
            “reload” button.
          </Typography>
        </li>
      </ol>
      <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
        <Button
          buttonType="common.primary"
          onClick={() => window.location.reload()}
          variant="contained"
        >
          Refresh page
        </Button>
      </Box>
    </Box>
  </Dialog>
);

export default AdBlockModal;
