import { SvgIconProps } from '../Icon';

const BankIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...otherProps}>
    <path
      d="M24.5 3.5l-22 12v6h44v-6l-22-12zM30.5 40.5v-14M6.5 40.5v-14M18.5 26.5v14M42.5 40.5v-14"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 16.5a3 3 0 100-6 3 3 0 000 6zM46.5 40.5h-44v6h44v-6z"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BankIcon;
