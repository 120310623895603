import { SvgIconProps } from '../Icon';

const CheckCircleIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <circle cx={20} cy={20} r={20} fill={bgColor} />
    <path
      d="M16.8 23.9l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4-10.6 10.6z"
      fill={color}
    />
  </svg>
);

export default CheckCircleIcon;
