import { useState, useMemo } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Accordion,
  AccordionHeader,
  AccordionDetails,
} from 'common/components/material-ui';

import { useGetStarted } from '../useGetStarted';

type Props = {
  children: React.ReactNode;
  taskCompleted: boolean;
  taskName: string;
};

const GetStartedListItem = ({
  children,
  taskCompleted,
  taskName,
}: Props): JSX.Element => {
  const { uiState: { currentTask, setCurrentTask } } = useGetStarted();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (task: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentTask(task);
      setExpanded(isExpanded ? task : false);
    };

  const isExpanded = useMemo(() => (
    expanded === currentTask
  ), [expanded, currentTask]);

  return (
    <Accordion
      onChange={handleChange(taskName)}
      expanded={isExpanded}
    >
      <AccordionHeader
        sx={{
          borderBottom: isExpanded ? 'none' : theme => `2px solid ${theme.palette.common.basic500}`,
        }}
        leftContent={
          <CheckCircleIcon
            fontSize="large"
            sx={{ color: taskCompleted ? 'common.success700' : 'common.basic500' }}
          />
        }
        heading={taskName}
        data-testid={taskName}
      />
      <AccordionDetails
        data-testid="task-card-content"
        sx={{
          pb: 3,
          '&.MuiAccordionDetails-root': {
            borderBottom: theme => `2px solid ${theme.palette.common.basic500}`,
          },
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default GetStartedListItem;
