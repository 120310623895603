import { useState } from 'react';
import {
  Box,
  Divider,
} from '@mui/material';

import { Smidge } from 'common/components/layouts';
import { Button } from 'common/components/material-ui';

import GetBannerModal from './GetBannerModal';

type Props = {
  onExpand: (index: number) => void;
}

const CalculatorWidgetSelection = ({ onExpand }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column">
      <GetBannerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        banner={{
          size: 'Calculator',
          description: 'Embed this widget on its own page or in a sidebar',
          width: '375',
          height: '934',
        }}
        color="widget-calculator"
        itemType="widget"
        handleExpand={onExpand}
      />
      <Divider />
      <Smidge x={2} />
      <div>
        <Button
          variant="contained"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Install this banner
        </Button>
        <Smidge x={2} />
        <img
          src="https://hearth.imgix.net/contractordashboard/widget-calculator.png?auto=compress"
          alt="widget calculator"
          style={{
            width: '390px',
            maxWidth: '100%',
          }}
        />
        <Smidge x={1} />
      </div>
    </Box>
  );
};

export default CalculatorWidgetSelection;
