import * as React from 'react';
import {
  ButtonBase,
  Button as MaterialButton,
  CircularProgress,
  Theme,
} from '@mui/material';
import { ButtonProps as MaterialButtonProps } from '@mui/material/Button';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { LinkProps } from 'react-router-dom';

import { useSnack } from 'common/utils/snackCart';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type ButtonProps = {
  children: React.ReactNode;
  component?: React.ReactNode;
  to?: LinkProps['to'];
  href?: string;
  target?: string;
  rel?: string;
  buttonType?: Palette;
  transparent?: boolean;
  loading?: boolean;
  rounded?: boolean;
  disabledMessage?: string;
  download?: string;
  noStopPropagation?: boolean;
} & MaterialButtonProps;

const useStyles = makeStyles({
  rounded: {
    borderRadius: 100,
  },
  noOutline: {
    outline: 'none',
  },
  noPointer: {
    cursor: 'default',
  },
});

const Button = React.forwardRef(({
  children,
  buttonType = 'primary',
  onClick,
  transparent = false,
  loading,
  disabled,
  rounded = false,
  disabledMessage,
  noStopPropagation = false,
  ...otherProps
}: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
  const { errorSnack } = useSnack();
  const styles = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (!noStopPropagation) {
      event.stopPropagation();
    }

    if (onClick) {
      onClick(event);
    }
  };

  const button = (
    <MaterialButton
      onClick={handleClick}
      color="primary"
      disabled={loading || disabled}
      className={cx(styles.noOutline, { [styles.rounded]: rounded })}
      ref={ref}
      {...otherProps}
    >
      {loading ?
        <CircularProgress size={20} /> :
        children
      }
    </MaterialButton>
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={(theme: Theme) => ({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              main: theme.customPalette[buttonType].color,
              dark: theme.customPalette[buttonType].secondaryColor,
              contrastText: theme.customPalette[buttonType].contrast,
            },
            text: {
              ...theme.palette.text,
              primary: theme.customPalette[buttonType].color,
            },
          },
          components: {
            ...theme.components,
            MuiButton: {
              styleOverrides: {
                ...(theme?.components?.MuiButton ? theme.components.MuiButton.styleOverrides : {}),
                containedPrimary: {
                  '&:focus, &:hover, &:active': {
                    color: theme.customPalette[buttonType].contrast,
                  },
                },
                outlinedPrimary: !transparent ?
                  {
                    backgroundColor: theme.palette.common.basic100,
                    '&:hover': {
                      backgroundColor: theme.palette.common.basic100,
                    },
                  } :
                  {},
              },
            },
          },
        })}
      >
        {disabled && disabledMessage ?
          <ButtonBase
            disableRipple
            disableTouchRipple
            onClick={() => errorSnack(disabledMessage)}
            className={styles.noPointer}
          >
            {button}
          </ButtonBase> :
          button
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default Button;
