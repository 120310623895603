import { Resolvers, gql } from '@apollo/client';

export const resolverDefs = gql`
  extend type Query {
    session: Session!
  }

  extend type Mutation {
    contractGeneratorRestGeneratePdf(input: ContractGeneratorRestGeneratePdfInput!): ContractGeneratorRestGeneratePdfResult!
  }

  type Session {
    hearthUid: String!
  }

  input ContractGeneratorRestGeneratePdfInput {
    html: String!
  }

  type ContractGeneratorRestGeneratePdfResult {
    pdf: String!
  }

  extend type Mutation {
    setHearthUid(hearthUid: String!): Session!
  }
`;

const GET_SESSION = gql`
  query GetSession {
    session @client {
      hearthUid
    }
  }
`;

const resolvers: Resolvers = {
  Session: {
    hearthUid: (_root, _args, { cache }) => {
      const { session } = cache.readQuery({ query: GET_SESSION });

      return session.hearthUid;
    },
  },
  Mutation: {
    setHearthUid: (_root, { hearthUid }, { cache }) => {
      const data = {
        session: {
          __typename: 'Session',
          hearthUid,
        },
      };

      cache.writeQuery({ query: GET_SESSION, data });

      return data;
    },
  },
};

export default resolvers;
