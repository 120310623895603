import {
  MenuItem as MaterialMenuItem,
  MenuItemProps,
} from '@mui/material';

type Props = {
  onClick: () => void;
} & Omit<MenuItemProps, 'onClick'>;

const MenuItem = ({ onClick, ...otherProps }: Props): JSX.Element => {
  const handleClick = (): void => {
    onClick();
  };

  return (
    <MaterialMenuItem onClick={handleClick} {...otherProps} />
  );
};

export default MenuItem;
