import { SvgIconProps } from '../Icon';

const LearnCenterIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path d="M27 0.666667C25.52 0.2 23.8933 0 22.3333 0C19.7333 0 16.9333 0.533333 15 2C13.0667 0.533333 10.2667 0 7.66667 0C5.06667 0 2.26667 0.533333 0.333334 2V21.5333C0.333334 21.8667 0.666667 22.2 1 22.2C1.13333 22.2 1.2 22.1333 1.33333 22.1333C3.13333 21.2667 5.73333 20.6667 7.66667 20.6667C10.2667 20.6667 13.0667 21.2 15 22.6667C16.8 21.5333 20.0667 20.6667 22.3333 20.6667C24.5333 20.6667 26.8 21.0667 28.6667 22.0667C28.8 22.1333 28.8667 22.1333 29 22.1333C29.3333 22.1333 29.6667 21.8 29.6667 21.4667V2C28.8667 1.4 28 1 27 0.666667ZM27 18.6667C25.5333 18.2 23.9333 18 22.3333 18C20.0667 18 16.8 18.8667 15 20V4.66667C16.8 3.53333 20.0667 2.66667 22.3333 2.66667C23.9333 2.66667 25.5333 2.86667 27 3.33333V18.6667Z" fill={color} />
    <path d="M22.3333 8C23.5067 8 24.64 8.12 25.6667 8.34667V6.32C24.6133 6.12 23.48 6 22.3333 6C20.0667 6 18.0133 6.38667 16.3333 7.10667V9.32C17.84 8.46667 19.9333 8 22.3333 8Z" fill={color} />
    <path d="M16.3333 10.6533V12.8666C17.84 12.0133 19.9333 11.5466 22.3333 11.5466C23.5067 11.5466 24.64 11.6666 25.6667 11.8933V9.86663C24.6133 9.66663 23.48 9.54663 22.3333 9.54663C20.0667 9.54663 18.0133 9.94663 16.3333 10.6533Z" fill={color} />
    <path d="M22.3333 13.1067C20.0667 13.1067 18.0133 13.4934 16.3333 14.2134V16.4267C17.84 15.5734 19.9333 15.1067 22.3333 15.1067C23.5067 15.1067 24.64 15.2267 25.6667 15.4534V13.4267C24.6133 13.2134 23.48 13.1067 22.3333 13.1067Z" fill={color} />
  </svg>
);

export default LearnCenterIcon;
