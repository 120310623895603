import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteProps, useLocation } from 'react-router-dom';

import { DemoModeSnack } from 'main/components/widgets';
import { ConditionalPaywallBanner } from 'contractor/components/banners';
import { refreshData } from 'contractor/actions/contractor';
import { Loader } from 'common/components/widgets';
import userFeatureFlags from 'common/graphql/featureFlags';
import GetStartedContainer from 'contractor/modules/platform/GetStarted/GetStartedContainer';

type Props = {
  children?: React.ReactNode;
} & RouteProps;

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.basic300,
    marginTop: 56,
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      height: 'calc(100vh - 64px)',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,
    },
  },
}));

const ContractorContainer = ({ children, location }: Props): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { getStarted } = useReactiveVar(userFeatureFlags);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const refetchData = useCallback(
    async () => {
      try {
        // @TODO: update to 'cache-first' when the apollo cache is the primary
        // store. We this network-only currently to ensure that redux and the apollo
        // cache stay in sync. There are instances (such as reset password) where
        // removing the 'network-first' setting will create strange state changes.
        await dispatch(refreshData('network-only'));
      } catch (error) {
        // @TODO
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  // using approach found here: https://www.carlrippon.com/scrolling-a-react-element-into-view/
  // as react-scroll does not appear to work as expected on mount
  const scrollElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.wrapper}>
      <div ref={scrollElementRef} />
      <ConditionalPaywallBanner
        pathname={location?.pathname}
      />
      {children}
      {getStarted && !isMobile && <GetStartedContainer />}
      <DemoModeSnack />
    </div>
  );
};

export default ContractorContainer;
