import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import { ButtonBase, Select } from 'common/components/material-ui';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    backgroundColor: theme.palette.common.basic100,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
    marginLeft: 24,
    marginRight: 40,
    minWidth: 260,
  },
  webNavActive: {
    backgroundColor: theme.palette.common.primary100,
    color: theme.palette.common.primary500,
    padding: '16px 24px',
    justifyContent: 'flex-start',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.basic300,
    },
  },
  webNav: {
    backgroundColor: theme.palette.common.basic100,
    color: theme.palette.common.basic1100,
    padding: '16px 24px',
    justifyContent: 'flex-start',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.basic300,
    },
  },
}));

type NavigationItem = {
  label: string;
  value: string;
}

type Props<T> = {
  title: string;
  navigationOptions: NavigationItem[];
  onClickNavigation: (value: T) => void;
}

function NavigationMenu<T extends string>({
  title,
  navigationOptions,
  onClickNavigation,
}: Props<T>): JSX.Element {
  const classes = useStyles();
  const location = useLocation();

  const currentPage = location.pathname.split('/').pop();

  return (
    <Box flex={1}>
      <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
        <Box p={2}>
          <Select<string>
            name="select"
            items={navigationOptions}
            value={currentPage}
            onChange={e => onClickNavigation(e.target.value as T)}
            label={title}
          />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Box className={classes.root}>
          <Box p={3}>
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
          </Box>
          {navigationOptions.map(navItem => (
            <ButtonBase
              key={navItem.value}
              onClick={() => onClickNavigation(navItem.value as T)}
              className={navItem.value === currentPage ?
                classes.webNavActive :
                classes.webNav
              }
            >
              <Typography variant="subtitle1" color="inherit" align="left">
                {navItem.label}
              </Typography>
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default NavigationMenu;
