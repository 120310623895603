import { useState } from 'react';
import { FieldArray, useField, ArrayHelpers } from 'formik';
import { Box, IconButton, ImageList } from '@mui/material';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button } from 'common/components/material-ui';
import ImageModal from 'contractor/components/quotes/ImageModal';

import ImageListItemField, { ImageListItemFieldValues } from './ImageListItemField';

type Props = {
  namePrefix: string;
  onUpload: (file: File) => Promise<{ id: string; remoteSrc: string; }>;
  dropzoneOptions?: Partial<DropzoneOptions>;
}

type InnerProps = Props & {
  arrayHelpers: ArrayHelpers;
}

const ImageListFieldGroup = ({
  namePrefix,
  onUpload,
  arrayHelpers,
  dropzoneOptions,
}: InnerProps): JSX.Element => {
  const [clickedImage, setClickedImage] = useState<string | null>(null);
  const [{ value }] = useField<ImageListItemFieldValues[]>(namePrefix);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 5000000,
    accept: [
      'image/jpeg',
      'image/jpg',
      'image/png',
    ],
    onDrop: (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file, index) => {
        if (value.find(({ fileName }) => fileName === file.name)) {
          return;
        }
        const arrayIndex = value.length + index;
        const fileData = {
          localSrc: URL.createObjectURL(file),
          fileName: file.name,
        };
        arrayHelpers.push(fileData);
        onUpload(file).then(
          ({ id, remoteSrc }) => {
            // on successful upload, update the url with remote url and backend id
            arrayHelpers.replace(arrayIndex, {
              id,
              remoteSrc,
              ...fileData,
            });
          },
          () => (
            arrayHelpers.remove(arrayIndex)
          ),
        );
      });
    },
    ...dropzoneOptions,
  });

  const rootProps = getRootProps();
  const { css, ...boxProps } = rootProps;

  return (
    <>
      {clickedImage &&
        <ImageModal
          open={!!clickedImage}
          onClose={() => setClickedImage(null)}
          image={clickedImage}
        />
      }
      <Box
        {...boxProps}
        sx={{
          bgcolor: isDragActive ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
          outlineWidth: 0,
          opacity: (isDragActive ? 0.5 : 1),
        }}
      >
        <input {...getInputProps()} />
        {/* need to stop propagation below to prevent upload modal from rendering */}
        <ImageList cols={2} gap={16} onClick={e => e.stopPropagation()}>
          {value.map(({ fileName, localSrc }, index) => (
            <ImageListItemField
              rows={1}
              // use 2 cols if the image is the last one in the list and there's nothing on the left
              // of it
              cols={((index === value.length - 1) && (index % 2 === 0)) ? 2 : 1}
              key={fileName}
              namePrefix={`${namePrefix}[${index}]`}
              onClick={() => setClickedImage(localSrc)}
              action={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      arrayHelpers.remove(index);
                    }}
                  >
                    <CloseIcon sx={{ color: 'common.basic100' }} />
                  </IconButton>
                </Box>
              }
            />
          ))}
        </ImageList>
        <Button
          buttonType="common.primary"
          variant="text"
          onClick={event => event.preventDefault()}
          noStopPropagation
        >
          <AddCircleIcon sx={{ mr: 1 }} />
          Add photo
        </Button>
      </Box>
    </>
  );
};

export default ({
  namePrefix,
  ...otherProps
}: Props): JSX.Element => (
  <FieldArray
    name={namePrefix}
    render={arrayHelpers => (
      <ImageListFieldGroup
        namePrefix={namePrefix}
        arrayHelpers={arrayHelpers}
        {...otherProps}
      />
    )}
  />
);
