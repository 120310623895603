import { useMemo, useEffect, useState } from 'react';
import {
  Box,
  Theme,
  Dialog,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ContractTemplate, ContractBlob } from 'kakemono';
import { useWindowSize } from 'react-use';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';

import { ContractRenderer } from 'contractor/modules/EditContract/components';
import { useGql } from 'common/hooks';

import {
  useContractTemplate,
  useContractor,
  useOrganization,
  useContractWizardActions,
} from '../useContractWizard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  closeIcon: {
    fontSize: 36,
    color: theme.palette.common.basic100,
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  scroll: {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
}

const SampleContractModal = ({ open, onClose }: Props): JSX.Element => {
  const { template } = useContractTemplate();
  const contractor = useContractor();
  const organization = useOrganization();
  const { width, height } = useWindowSize();
  const classes = useStyles({ width });
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { generatePreviewPdf } = useContractWizardActions();
  const [base64Pdf, setBase64Pdf] = useState<string | null>(null);
  const { handleMutationError } = useGql();

  const previewArgs = useMemo(() => {
    if (!contractor || !organization) return null;

    return {
      contract: {
        agreementDate: format(new Date(), 'PPP'),
      },
      contractor: {
        email: contractor.email,
        name: contractor.fullName,
        // TODO(contracts): replace this when we add fuller header customization
        phoneNumber: organization.businessPhone,
      },
      company: {
        name: organization.companyName,
        addressLine1: organization.address,
        addressLine2: `${organization.city}, ${organization.state} ${organization.zipCode}`,
        businessLicense: organization.businessLicenseNumber ?
          `# ${organization.businessLicenseNumber}` :
          undefined,
        logo: organization?.logo,
      },
      customer: {
        name: 'Frank Smith',
        emailAddress: 'franksmith@example.com',
        address: '123 Main St., San Francisco, CA 94110',
        phoneNumber: '(555) 555-5555',
      },
      homeowner: {
        name: 'Frank Smith',
        emailAddress: 'franksmith@example.com',
        address: '123 Main St., San Francisco, CA 94110',
        phoneNumber: '(555) 555-5555',
      },
      project: {
        servicesRendered: '1. Demo old roof\n2. Add protective coating\n3. Add new shingles\n4. Seal the gutters',
        additionalProvisions: '',
        includeAdditionalProvisions: false,
        totalAmount: '$5,200.00',
        detailsTable: [
          ['Shingle Type', ''],
          ['Shingle Color', ''],
          ['Roof Dimensions', ''],
          ['Installation Start Date', ''],
        ],
        staticChecklist: [
          { itemName: '5 Year Labor Warranty Included', isChecked: true },
          { itemName: 'Manufacturer Warranty Included', isChecked: false },
        ],
      },
      payments: {
        rows: [
          ['Deposit', '$2,100'],
          ['Completion', '$3,100'],
          ['', 'Total: $5,200'],
        ],
      },
    };
  }, [contractor, organization]);

  const contractPreview = useMemo(() => {
    if (!template || !previewArgs) return null;
    return ContractTemplate(template as ContractBlob).render(previewArgs);
  }, [template, previewArgs]);

  useEffect(() => {
    if (!contractPreview) return;
    generatePreviewPdf(contractPreview).then(setBase64Pdf, e => handleMutationError(e, {}));
  }, [generatePreviewPdf, handleMutationError, contractPreview]);

  return (
    <Dialog
      open={Boolean(base64Pdf) && open}
      onClose={onClose}
      onBackdropClick={onClose}
      classes={{ root: classes.root, paper: classes.paper }}
      maxWidth={false}
    >
      <Box className={classes.container}>
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={onClose} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box mt={4} height={height - 40} className={classes.scroll}>
          {base64Pdf ?
            <ContractRenderer
              base64PDF={base64Pdf}
              marginBottom={0}
              width={isMobileOrSmall ? width - 64 : undefined}
            /> :
            null
          }
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={onClose} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SampleContractModal;
