import { useMemo } from 'react';
import {
  Box,
  Chip,
  Typography,
  emphasize,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isPresent } from 'ts-is-present';

import { HomeownerOrderers, HomeownerEventBucket } from 'types';

import { useClientIndex, useColorPalette, ClientIndexActionType } from '../useClientIndex';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.basic300,
  },
  colorPrimary: (props: { activeColor: Color }) => ({
    backgroundColor: theme.palette.common[props.activeColor],
    color: theme.palette.common.basic100,
  }),
  clickableColorPrimary: (props: { activeColor: Color }) => ({
    '&:focus': {
      backgroundColor: theme.palette.common[props.activeColor],
    },
    '&:hover': {
      backgroundColor: emphasize(theme.palette.common[props.activeColor], 0.08),
    },
    '&:active': {
      boxShadow: theme.shadows[0],
    },
  }),
}));

const OrderGroup = (): JSX.Element => {
  const { state, dispatch } = useClientIndex();
  const colorPalette = useColorPalette();
  const classes = useStyles({
    activeColor: colorPalette.headerColor.replace('common.', '') as Color,
  });

  const selectedIndex = useMemo(() => {
    switch (state.homeownerBucket) {
    case HomeownerEventBucket.FINANCING:
    case HomeownerEventBucket.FINANCING_INDEX:
    case HomeownerEventBucket.PAYMENTS:
    case HomeownerEventBucket.PAYMENTS_INDEX: {
      switch (state.sortMethod) {
      case HomeownerOrderers.BY_STATUS_FINANCING_EVENTS:
      case HomeownerOrderers.BY_STATUS_PAYMENT_EVENTS:
        return 0;
      case HomeownerOrderers.FIRST_NAME_ASC:
        return 1;
      case HomeownerOrderers.FIRST_NAME_DESC:
        return 2;
      case HomeownerOrderers.BY_RECENT_FINANCING_EVENTS:
      case HomeownerOrderers.BY_RECENT_PAYMENT_EVENTS:
        return 3;
      default:
        return 0;
      }
    }
    case HomeownerEventBucket.FINANCING_AND_PAYMENTS: {
      switch (state.sortMethod) {
      case HomeownerOrderers.FIRST_NAME_ASC:
        return 0;
      case HomeownerOrderers.FIRST_NAME_DESC:
        return 1;
      case HomeownerOrderers.BY_RECENT_EVENTS:
        return 2;
      default:
        return 0;
      }
    }
    default:
      return 0;
    }
  }, [state.homeownerBucket, state.sortMethod]);

  const orderButtons = useMemo(() => [
    state.homeownerBucket !== HomeownerEventBucket.FINANCING_AND_PAYMENTS ? {
      label: 'Status',
      onPress: () => {
        dispatch({
          type: ClientIndexActionType.SORT_BY_STATUS,
        });
      },
    } : undefined,
    {
      label: 'A-Z',
      onPress: () => dispatch({
        type: ClientIndexActionType.SORT_BY_FIRST_NAME,
        data: HomeownerOrderers.FIRST_NAME_ASC,
      }),
    },
    {
      label: 'Z-A',
      onPress: () => dispatch({
        type: ClientIndexActionType.SORT_BY_FIRST_NAME,
        data: HomeownerOrderers.FIRST_NAME_DESC,
      }),
    },
    {
      label: 'Recent Activity',
      onPress: () => dispatch({
        type: ClientIndexActionType.SORT_BY_RECENT_ACTIVITY,
      }),
    },
  ].filter(isPresent), [state.homeownerBucket, dispatch]);

  return (
    <Box display="flex" flex={1} flexDirection="row" alignItems="center">
      <Box mr={1}>
        <Typography variant="subtitle2">Sort by: </Typography>
      </Box>
      {orderButtons.map((orderButton, index) => {
        const isActive = (index === selectedIndex);

        return (
          <Box mx={1} key={orderButton.label}>
            <Chip
              label={orderButton.label}
              onClick={orderButton.onPress}
              color={isActive ? 'primary' : 'default'}
              classes={classes}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default OrderGroup;
