import { Action } from 'redux';

const SET_PARTNER = 'ThirdParty/SET_PARTNER';

type ThirdPartyState = Readonly<{
  partner: string;
}>

const initialState: ThirdPartyState = {
  partner: '',
};

export function setPartner(partner: string): SetPartner {
  return {
    type: SET_PARTNER,
    payload: partner,
  };
}

export interface SetPartner extends Action {
  type: typeof SET_PARTNER;
  payload: string;
}

export default function ThirdPartyStateReducer(
  state = initialState,
  action: SetPartner,
// @TODO: Add specific type to the return of camelizeKeys
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThirdPartyState {
  switch (action.type) {
  case SET_PARTNER:
    return {
      ...state,
      partner: action.payload,
    };

  default:
    return state;
  }
}
