import { useState } from 'react';
import {
  Box,
  Divider,
  Typography,
} from '@mui/material';

import { Smidge } from 'common/components/layouts';
import { Button } from 'common/components/material-ui';

import GetBannerModal from './GetBannerModal';

const banners = {
  mainContentBanner: {
    size: '700x110',
    description: 'Place this banner at the top of main content of a page for best use',
    width: '700',
    height: '110',
  },
  articleBanner: {
    size: '310x610',
    description: 'Embed this banner within text content or at the end of an article',
    width: '310',
    height: '610',
  },
};

type Props = {
  onExpand: (index: number) => void;
}

const ZeroPercentBannerSelection = ({ onExpand }: Props): JSX.Element => {
  const [banner, setBanner] = useState('mainContentBanner');
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column">
      <GetBannerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        banner={banners[banner]}
        color="zero_percent"
        itemType="banner"
        handleExpand={onExpand}
      />
      <Divider />
      <Smidge x={2} />
      {Object.entries(banners).map(([key, data]) => (
        <div key={key}>
          <Typography>{data.size}</Typography>
          <Typography>{data.description}</Typography>
          <img
            src={`https://hearth.imgix.net/contractor-v2/banners/${data.size}_zero_percent.png`}
            alt={`banner_${data.size}_zero_percent`}
            style={{
              width: `${data.width}px`,
              maxWidth: '100%',
            }}
          />
          <Smidge x={2} />
          <Button
            variant="contained"
            onClick={() => {
              setBanner(key);
              setModalOpen(true);
            }}
          >
            Install this banner
          </Button>
          <Smidge x={1} />
        </div>
      ))
      }
    </Box>
  );
};

export default ZeroPercentBannerSelection;
