import { useCallback, useMemo, Children as ReactChildren } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Theme,
  AccordionProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import cx from 'classnames';

import InformationTabs from './InformationTabs';

type StyleProps = {
  childrenCount: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  AccordionRoot: {
    marginTop: 4,
    border: `1px solid ${theme.palette.common.basic700}`,
    borderRadius: 4,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 3px 8px rgba(38, 53, 71, 0.2)',
      '@media (hover: none)': {
        boxShadow: 'none',
      },
      '& $circle': {
        backgroundColor: theme.palette.common.basic300,
      },
    },
  },
  summaryRoot: {
    minHeight: 70,
    paddingLeft: 40,
  },
  AccordionDetailsRoot: {
    paddingLeft: 40,
    paddingBottom: 40,
    paddingRight: 64,
  },
  rightChild: props => ({
    backgroundColor: props.childrenCount > 1 ?
      theme.palette.common.basic100 :
      theme.palette.common.basic300,
    padding: props.childrenCount > 1 ? 0 : '8px 16px',
    borderTop: `1px solid ${theme.palette.common.basic700}`,
  }),
  expandIcon: {
    marginRight: 0,
  },
  arrow: {
    color: theme.palette.common.basic1100,
    fontSize: '2rem',
  },
  circle: {
    backgroundColor: 'transparent',
    height: 40,
    width: 40,
    borderRadius: 20,
    position: 'absolute',
  },
}));

export type InformationBoxProps = {
  summaryChild: React.ReactNode;
  leftChild?: React.ReactNode;
  children?: React.ReactNode;
  tabs?: string[];
  onArrowClick?: () => void;
  classes?: {
    accordionSummaryRoot?: string;
  };
  expandedIcon?: React.ReactNode;
} & Omit<AccordionProps, 'children'>;

const InformationBox = ({
  summaryChild,
  leftChild,
  children,
  tabs,
  onArrowClick,
  classes = {},
  expandedIcon,
  ...otherProps
}: InformationBoxProps): JSX.Element => {
  const childrenCount = useMemo(() => {
    if (children) {
      return ReactChildren.count(children);
    }
    return 0;
  }, [children]);
  const baseClasses = useStyles({ childrenCount });

  const hasContent = leftChild || children;
  const Icon = hasContent ? KeyboardArrowDown : ChevronRight;

  const expansionOnClick = useCallback(() => {
    if (onArrowClick) {
      onArrowClick();
    }
  }, [onArrowClick]);

  return (
    <Accordion
      classes={{ root: baseClasses.AccordionRoot }}
      expanded={hasContent ? undefined : false}
      onClick={expansionOnClick}
      TransitionProps={{ unmountOnExit: true }} // Required to not mount data heavy children
      {...otherProps}
    >
      <AccordionSummary
        classes={{
          root: cx(baseClasses.summaryRoot, classes.accordionSummaryRoot),
          expandIconWrapper: baseClasses.expandIcon,
        }}
        expandIcon={expandedIcon ??
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={baseClasses.circle}
          >
            <Icon classes={{ root: baseClasses.arrow }} />
          </Box>
        }
      >
        {summaryChild}
      </AccordionSummary>
      {hasContent &&
        <AccordionDetails classes={{ root: baseClasses.AccordionDetailsRoot }}>
          <Grid container>
            <Grid xs={4}>
              {leftChild}
            </Grid>
            {children &&
              <Grid xs={8} classes={{ root: baseClasses.rightChild }} container direction="column">
                {tabs ?
                  <InformationTabs
                    tabs={tabs}
                  >
                    {children}
                  </InformationTabs> :
                  children
                }
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      }
    </Accordion>
  );
};

export default InformationBox;
