import { SvgIconProps } from '../Icon';

const SpeechHeartIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 46 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M39.8 30.6A16.033 16.033 0 0045 19c0-9.9-9.8-18-22-18S1 9.1 1 19s9.8 18 22 18a31.837 31.837 0 006.2-.7L41 41l-1.2-10.4z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M30.562 13.443A4.901 4.901 0 0023 14.2a4.917 4.917 0 10-7.562 6.206L23 28l7.563-7.59a4.939 4.939 0 00-.001-6.967v0z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default SpeechHeartIcon;
