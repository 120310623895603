import { SvgIconProps } from '../Icon';

const VideoCameraIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <g
      clipPath="url(#prefix__clip0)"
      stroke={color}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M38.333 11.667L26.666 20l11.667 8.333V11.667z" />
      <path d="M23.333 8.333H5a3.333 3.333 0 00-3.333 3.334v16.666A3.333 3.333 0 005 31.667h18.333a3.333 3.333 0 003.334-3.334V11.667a3.333 3.333 0 00-3.334-3.334z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default VideoCameraIcon;
