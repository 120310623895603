import { AxiosResponse } from 'axios';

import client from './client';

export type SecondaryArgs = {
  employmentStatus: string;
  fico: number;
  firstName: string;
  lastName: string;
  income: number;
  email: string;
  phoneNumber: string;
  address: string;
  city?: string;
  state?: string;
  zipCode?: string;
  birthday: Date;
  ssn: string;
  terms: boolean;
  jointApplicant?: {
    employmentStatus?: string;
    fico?: number;
    firstName?: string;
    lastName?: string;
    income?: number;
    email?: string;
    phoneNumber?: string;
    address?: string;
    ssn?: string;
    birthday?: Date;
  };
}

export type PrimaryArgs = SecondaryArgs & {
  monthlyHousingCosts: string;
  loanPrincipal: string;
  jointApplication: boolean;
  termsString: string | null;
  wvContractSigned: boolean;
  organizationId: string;
  contractorId?: string;
  homeownerId?: string;
  authenticityToken?: string;
  projectType?: string;
  monthlyPaymentMin?: number;
  monthlyPaymentMax?: number;
};

export type LoanApplicationResult = {
  loanInquiry: {
    stubbedCall: boolean;
    loanInquiryUuid: string;
    vertical: string;
    homeownerId: string | null;
    homeownerEmail: string | null;
    loanPrincipal: string | null;
    minAmountByLender: number | null;
    monthlyPaymentMin?: number | null;
    monthlyPaymentMax?: number | null;
    prequalifyApprovalRate: number | null;
    fieldsComplete: boolean;
    jointApplication: boolean;
    borrower: {
      state?: string;
      organizationId?: string;
      contractorId?: string;
      fico?: number;
      income?: number;
      email?: string;
      employmentStatus?: string;
      monthlyHousingCosts?: string;
      loanPrincipal?: string;
      birthday?: Date;
      firstName?: string;
      lastName?: string;
    };
    coapplicant: {
      employmentStatus?: string;
      fico?: number;
      firstName?: string;
      lastName?: string;
      income?: number;
      email?: string;
      phoneNumber?: string;
      address?: string;
      ssn?: string;
      birthday?: Date;
    } | null;
    metadata: {
      organizationId: number | null;
      contractorId: number | null;
      featureFlags: string[];
    };
  };
}

type CreateArgs = {
  data: Partial<PrimaryArgs>;
};
type CreateResponse = AxiosResponse<LoanApplicationResult>;
export const create = ({ data }: CreateArgs): Promise<CreateResponse> => (
  client.post('/loan_application', { loanInquiry: data })
);

type FetchArgs = {
  id: string;
};
type FetchResponse = AxiosResponse<LoanApplicationResult>;
export const fetch = ({ id }: FetchArgs): Promise<FetchResponse> =>
  client.get(`/loan_application/${id}`);

type UpdateArgs = {
  id: string;
  data: Partial<PrimaryArgs>;
};
type UpdateResponse = AxiosResponse<LoanApplicationResult>;
export const update = ({ id, data }: UpdateArgs): Promise<UpdateResponse> => (
  client.put(`/loan_application/${id}`, { loanInquiry: data })
);

type UpdateCoapplicantArgs = {
  id: string;
  data: Partial<SecondaryArgs>;
};
type UpdateCoapplicantResponse = AxiosResponse<LoanApplicationResult>;
export const updateCoapplicant =
  ({ id, data }: UpdateCoapplicantArgs): Promise<UpdateCoapplicantResponse> =>
    client.put(`/loan_application/${id}/coapplicant`, { loanInquiry: { coapplicant: data } });

type SubmitArgs = {
  id: string;
};
export const submit = ({ id }: SubmitArgs): Promise<AxiosResponse> =>
  client.post(`/loan_application/${id}/submit`);

export const pollOffers = (id: string): Promise<AxiosResponse> =>
  client.get(`/loan_application/${id}/offers`);

export const sendEmail = (id: string, email: string): Promise<AxiosResponse> =>
  client.post(`/loan_application/${id}/perform_email`, { loanApplication: { addressTo: email } });

type CreateFromExistingArgs = {
  id: string;
  data?: {
    recommendedAmount: boolean;
  };
};
type CreateFromExistingResponse = AxiosResponse<LoanApplicationResult>;
export const createFromExisting =
  ({ id, data }: CreateFromExistingArgs): Promise<CreateFromExistingResponse> =>
    client.post(`/loan_application/${id}/create_from_existing`, data);
