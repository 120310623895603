import { SvgIconProps } from '../Icon';

const BannerIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <g
      clipPath="url(#prefix__clip0)"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.417 2.083h30l-6.667 10 6.667 10h-30M5.417 2.083V38.75" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BannerIcon;
