import { SvgIconProps } from '../Icon';

const SparkCheckIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 78 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <circle cx={38.28} cy={51.2} r={19.8} fill={color} />
    <path
      d="M34.049 56.315l-5.505-5.504-1.874 1.861 7.379 7.379 15.84-15.84-1.861-1.861-13.98 13.965z"
      fill={bgColor}
    />
    <path
      fill={color}
      d="M1.947 15.402l13.367 14.607-1.948 1.783L0 17.184zM77.973 17.732L63.717 31.473l-1.832-1.9L76.141 15.83zM38.883.04l-.267 19.798-2.64-.035.267-19.798z"
    />
  </svg>
);

export default SparkCheckIcon;
