import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Button, Dialog } from 'common/components/material-ui';
import { useLogEvent } from 'common/hooks';
import { useSnack } from 'common/utils/snackCart';

import useEditContractTemplate, { useEditContractTemplateActions } from '../useEditContractTemplate';

type Props = {
  open: boolean;
  onClose: () => void;
}

const DeleteCheckModal = ({ open, onClose }: Props): JSX.Element | null => {
  const { deleteAllContractTemplatesByName } = useEditContractTemplateActions();
  const { allTemplates, setSelectedTemplate } = useEditContractTemplate();
  const snack = useSnack();
  const itly = useLogEvent();

  // typecheck
  if (!allTemplates) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      titleBgColor="primary500"
      dialogTitle={
        <Box display="flex" flexDirection="row">
          <Box color="common.basic100" mr={1}>
            <ErrorOutlineIcon fontSize="large" />
          </Box>
          <Typography variant="h3">
            <Box color="common.basic100">
              Delete template?
            </Box>
          </Typography>
        </Box>
      }
    >
      <Box py={5} px={3}>
        <Typography>Are you sure you want to delete this template?</Typography>
        <Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="text"
            buttonType="common.primary"
            onClick={onClose}
            style={{ flex: 1 }}
          >
            Cancel
          </Button>
          <Box m={1} />
          <Button
            variant="contained"
            buttonType="common.primary"
            onClick={async () => {
              const success = await deleteAllContractTemplatesByName();
              if (typeof success === 'string') {
                itly.deleteContractTemplate();
                snack.successSnack('Template deleted');
              }
              const defaultTemplate = allTemplates.find(temp => temp.default);
              if (defaultTemplate) setSelectedTemplate(defaultTemplate);
              onClose();
            }}
            style={{ flex: 2 }}
          >
            Yes, delete template
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteCheckModal;
