import MuiMenuList, { MenuListProps } from '@mui/material/MenuList';

type Props = {
  children: React.ReactNode;
} & MenuListProps;

const MenuList = ({ children, ...otherProps }: Props): JSX.Element => (
  <MuiMenuList
    sx={{
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'common.basic100',
      px: 3,
      py: 2,
      '& .MuiPaper-root': {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: 8,
      },
      '& > .MuiMenuItem-root': {
        mb: 1,
        '&:last-child': {
          mb: 0,
        },
      },
    }}
    {...otherProps}
  >
    {children}
  </MuiMenuList>
);

export default MenuList;
