import {
  Box,
  Typography,
  Divider,
  BoxProps,
} from '@mui/material';

type ContentProps = {
  title: string;
  subtitle: React.ReactNode;
  children: React.ReactNode;
} & BoxProps;

export const GreyContentCard = ({
  title,
  subtitle,
  children,
  ...otherProps
}: ContentProps): JSX.Element => (
  <Box px={3} py={4} borderRadius="8px" bgcolor="common.basic300" {...otherProps}>
    <Typography variant="h5" color="textPrimary">
      {title}
    </Typography>
    <Box mt={1} />
    <Typography variant="body2" color="textSecondary">
      {subtitle}
    </Typography>
    <Box my={3}>
      <Divider />
    </Box>
    {children}
  </Box>
);

type RowProps = {
  title: string;
  children: React.ReactNode;
} & BoxProps;

export const GreyRowCard = ({
  title,
  children,
  ...otherProps
}: RowProps): JSX.Element => (
  <Box
    px={3}
    py={4}
    borderRadius="8px"
    bgcolor="common.basic300"
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    {...otherProps}
  >
    <Typography variant="h5" color="textPrimary">
      {title}
    </Typography>
    {children}
  </Box>
);
