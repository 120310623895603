import { useMemo, useCallback } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import constate from 'constate';
import { useHistory } from 'react-router-dom';

import {
  useGetSettingsDataQuery,
  useUpdateOrganizationSettingsMutation,
  useUpdateContractorSettingsMutation,
  useSendResetPasswordLinkMutation,
  useUpdateTemplateMutation,
  useGenerateApiTokenMutation,
  ContractorAttributes,
  OrganizationAttributes,
} from 'types';
import { useSnack } from 'common/utils/snackCart';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useUrl, useLogEvent } from 'common/hooks';
import userFeatureFlags from 'common/graphql/featureFlags';

/*
 * GQL queries and mutations
 */

const SETTINGS_FRAGMENTS = gql`
  fragment SettingsOrganization on Organization {
    id
    infoSetting
    requireContract
    rateCap
    stripeConnectAccount {
      id
      fullAccess
    }
  }

  fragment SettingsContractor on Contractor {
    id
    admin
    textNotifications
    emailNotifications
    demoMode
  }
`;

export const GET_PROFILE_DATA = gql`
  query GetSettingsData {
    organization {
      id
      ...SettingsOrganization
    }
    contractor {
      id
      ...SettingsContractor
    }
  }
  ${SETTINGS_FRAGMENTS}
`;

export const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation UpdateOrganizationSettings($attributes: OrganizationAttributes!) {
    upsertOrganization(attributes: $attributes) {
      ... on UpsertOrganizationSuccess {
        organization {
          id
          ...SettingsOrganization
        }
      }
      ... on UpsertOrganizationFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${SETTINGS_FRAGMENTS}
`;

export const UPDATE_CONTRACTOR_SETTINGS = gql`
  mutation UpdateContractorSettings($attributes: ContractorAttributes!) {
    upsertContractor(attributes: $attributes) {
      ... on UpsertContractorSuccess {
        contractor {
          id
          ...SettingsContractor
        }
      }
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
  ${SETTINGS_FRAGMENTS}
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation SendResetPasswordLink {
    sendResetPasswordLink {
      ... on SendResetPasswordLinkSuccess {
        contractor {
          id
        }
      }
      ... on SendResetPasswordLinkFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const GENERATE_API_TOKEN = gql`
  mutation GenerateApiToken {
    generateApiToken {
      ... on GenerateApiTokenSuccess {
        organization {
          id
          apiToken {
            id
            delimitedPlaintextKey
          }
        }
      }
      ... on GenerateApiTokenFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($templateId: ID!, $templateString: String!) {
    upsertTemplate(templateId: $templateId, templateString: $templateString) {
      ... on UpsertTemplateSuccess {
        template {
          id
          templateString
        }
      }
      ... on UpsertTemplateFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export enum SettingsRoute {
  HearthPay = 'hearth-pay',
  Financing = 'financing',
  Notifications = 'notifications',
  Password = 'password',
  Billing = 'billing',
  DemoMode = 'demo-mode',
  Flyer = 'flyer',
  ProjectIntent = 'project-intent',
  Privacy = 'privacy',
  ApiToken = 'token',
}

type SettingsNavItem = {
  link: string;
  type: 'external' | 'route';
}

const settingsNav: Record<SettingsRoute, SettingsNavItem> = {
  [SettingsRoute.HearthPay]: {
    link: 'hearth-pay',
    type: 'route',
  },
  [SettingsRoute.Financing]: {
    link: 'financing',
    type: 'route',
  },
  [SettingsRoute.Notifications]: {
    link: 'notifications',
    type: 'route',
  },
  [SettingsRoute.Password]: {
    link: 'password',
    type: 'route',
  },
  [SettingsRoute.Billing]: {
    link: 'billing',
    type: 'route',
  },
  [SettingsRoute.DemoMode]: {
    link: 'demo-mode',
    type: 'route',
  },
  [SettingsRoute.Flyer]: {
    link: 'flyer',
    type: 'route',
  },
  [SettingsRoute.ProjectIntent]: {
    link: 'project-intent',
    type: 'route',
  },
  [SettingsRoute.Privacy]: {
    link: 'privacy',
    type: 'external',
  },
  [SettingsRoute.ApiToken]: {
    link: 'token',
    type: 'route',
  },
};
/*
 * Base hook [DO NOT EXPORT]
 */

const useBaseContext = () => {
  const { apiToken } = useReactiveVar(userFeatureFlags);
  const query = useGetSettingsDataQuery();

  const organization = useMemo(() => query.data?.organization, [query.data]);
  const contractor = useMemo(() => query.data?.contractor, [query.data]);

  const visibleSettingsNavItems: SettingsRoute[] = useMemo(() => (
    [
      SettingsRoute.Notifications,
      contractor?.admin && SettingsRoute.Financing,
      organization?.stripeConnectAccount && contractor?.admin && SettingsRoute.HearthPay,
      SettingsRoute.Password,
      contractor?.admin && SettingsRoute.Billing,
      SettingsRoute.DemoMode,
      SettingsRoute.Privacy,
      apiToken && SettingsRoute.ApiToken,
    ].filter(Boolean) as SettingsRoute[]
  ), [apiToken, contractor?.admin, organization?.stripeConnectAccount]);

  return {
    organization,
    contractor,
    query,
    visibleSettingsNavItems,
  };
};

const [ContainerProvider, useContext] = constate(useBaseContext);

/*
 * useActions hook
 */

type SettingsActions = {
  editContractorSettings: (attributes: ContractorAttributes) => void;
  editOrganizationSettings: (attributes: OrganizationAttributes) => void;
  sendResetPassword: () => void;
  setTemplate: (id: string, text: string) => void;
  navigateToSetting: (routeKey: SettingsRoute) => void;
  generateApiToken: () => void;
}

const useActions = (): SettingsActions => {
  const history = useHistory();

  const snack = useSnack();
  const { marketingUrl } = useUrl();
  const itly = useLogEvent();

  const { handleMutationError } = useGql();

  const query = useGetSettingsDataQuery();
  const contractor = useMemo(() => query.data?.contractor, [query.data]);

  /*
   * GraphQL mutations
   */

  const [updateOrganizationSettingsMutation] = useUpdateOrganizationSettingsMutation();
  const [updateContractorSettingsMutation] = useUpdateContractorSettingsMutation();
  const [sendResetPasswordLinkMutation] = useSendResetPasswordLinkMutation();
  const [updateTemplateMutation] = useUpdateTemplateMutation();
  const [generateApiTokenMutation] = useGenerateApiTokenMutation();

  /*
   * Actions
   */

  const navigateToSetting = useCallback((routeKey: SettingsRoute) => {
    if (settingsNav[routeKey].type === 'route') {
      history.push(`/dashboard/settings/${settingsNav[routeKey].link}`);
    } else {
      window.open(marketingUrl(`/${settingsNav[routeKey].link}`));
    }
  }, [history, marketingUrl]);

  const editContractorSettings = useCallback(async (attributes: ContractorAttributes) => {
    try {
      const response = await updateContractorSettingsMutation({
        variables: {
          attributes,
        },
      });

      const parsedResponse = parseGql<PayloadType<typeof response, 'upsertContractor'>>(
        'upsertContractor',
        response,
        'UpsertContractorSuccess',
        'UpsertContractorFailure',
      );

      snack.successSnack('Information updated');
      if (contractor?.textNotifications !== parsedResponse.contractor.textNotifications) {
        itly.changeSetting({ fields: ['text_notifications'] });
      }
      if (contractor?.emailNotifications !== parsedResponse.contractor.emailNotifications) {
        itly.changeSetting({ fields: ['email_notifications'] });
      }
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [
    updateContractorSettingsMutation,
    snack,
    contractor?.textNotifications,
    contractor?.emailNotifications,
    itly,
    handleMutationError,
  ]);

  const editOrganizationSettings = useCallback(async (attributes: OrganizationAttributes) => {
    try {
      const response = await updateOrganizationSettingsMutation({
        variables: {
          attributes,
        },
        refetchQueries: ['GetSettingsData'],
      });

      parseGql<PayloadType<typeof response, 'upsertOrganization'>>(
        'upsertOrganization',
        response,
        'UpsertOrganizationSuccess',
        'UpsertOrganizationFailure',
      );

      snack.successSnack('Information updated');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [snack, handleMutationError, updateOrganizationSettingsMutation]);

  const sendResetPassword = useCallback(async () => {
    try {
      const response = await sendResetPasswordLinkMutation({});

      parseGql<PayloadType<typeof response, 'sendResetPasswordLink'>>(
        'sendResetPasswordLink',
        response,
        'SendResetPasswordLinkSuccess',
        'SendResetPasswordLinkFailure',
      );

      snack.successSnack('Reset password link sent');
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, sendResetPasswordLinkMutation, snack]);

  const generateApiToken = useCallback(async () => {
    try {
      const response = await generateApiTokenMutation({});

      parseGql<PayloadType<typeof response, 'generateApiToken'>>(
        'generateApiToken',
        response,
        'GenerateApiTokenSuccess',
        'GenerateApiTokenFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [handleMutationError, generateApiTokenMutation]);

  const setTemplate = useCallback(async (id: string, text: string) => {
    // let error propagate
    const response = await updateTemplateMutation({
      variables: {
        templateId: id,
        templateString: text,
      },
    });

    parseGql<PayloadType<typeof response, 'upsertTemplate'>>(
      'upsertTemplate',
      response,
      'UpsertTemplateSuccess',
      'UpsertTemplateFailure',
    );
  }, [
    updateTemplateMutation,
  ]);

  return {
    editContractorSettings,
    editOrganizationSettings,
    sendResetPassword,
    setTemplate,
    navigateToSetting,
    generateApiToken,
  };
};

/*
 * Exports
 */

export const useSettings = useContext;
export const SettingsProvider = ContainerProvider;
export const useSettingsActions = useActions;
