import { memo, ChangeEvent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@mui/icons-material';

export type Props = {
  label: JSX.Element;
  errorMessage?: string;
} & CheckboxProps;

const useStyles = makeStyles({
  icon: {
    height: '1.5em',
    width: '1.5em',
  },
  label: {
    width: '100%',
  },
});

type OnChange = (
  event: ChangeEvent<HTMLInputElement>,
  checked: boolean
) => void;

const Checkbox = ({
  onChange,
  label,
  checked,
  errorMessage,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleChange: OnChange = (
    event: ChangeEvent<HTMLInputElement>,
    c: boolean,
  ): OnChange | void => {
    if (onChange) {
      return onChange(event, c);
    }

    return undefined;
  };

  return (
    <FormControl error={!!errorMessage}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            color="primary"
            onChange={handleChange}
            checkedIcon={<CheckBoxIcon className={classes.icon} />}
            icon={<CheckBoxOutlineBlank className={classes.icon} />}
            checked={checked}
            value={checked ? 'true' : 'false'}
            {...otherProps}
          />
        }
        label={label}
        classes={{
          label: classes.label,
        }}
      />
      {errorMessage &&
        <FormHelperText>{errorMessage}</FormHelperText>
      }
    </FormControl>
  );
};

export default memo(Checkbox);
