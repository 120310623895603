
type Disclosure = {
  id: string;
  title?: string;
  text: React.ReactNode;
}

type Props = {
  disclosures: Disclosure[];
  partner?: string;
}

const DisclosureFooter = ({ disclosures, partner }: Props): JSX.Element => (
  <div className={'disclosure-footer' + (partner ? ' white-label-disclosure' : '')}>
    <div className="container">
      <div className="row">
        {disclosures.filter(Boolean).map(({ id, text }) => (
          <div
            id={id}
            key={id}
            className="col-sm-12 disclosure-text"
          >
            <p><small>{text}</small></p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default DisclosureFooter;
