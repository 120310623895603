import { LogoUploadField } from 'common/components/formik';

import { useCustomizeInvoice } from '../useCustomizeInvoice';

const InvoiceLogoUpload = (): JSX.Element | null => {
  const { organization, refetch } = useCustomizeInvoice();

  if (!organization) return null;
  return (
    <LogoUploadField
      name="logo"
      label="Show logo on invoice"
      logoUrl={organization.logoUrl}
      refetch={refetch}
    />
  );
};

export default InvoiceLogoUpload;
