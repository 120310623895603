import { SvgIconProps } from '../Icon';

const FastCreditCardIcon = ({ color, bgColor, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 46 40" fill={bgColor} {...otherProps}>
    <rect
      x={13.455}
      y={14.114}
      width={24}
      height={18}
      rx={2}
      transform="rotate(-32.049 13.455 14.114)"
      fill={bgColor}
      stroke={color}
      strokeWidth={2}
    />
    <rect
      x={16.108}
      y={18.352}
      width={18}
      height={2}
      rx={1}
      transform="rotate(-32.049 16.108 18.352)"
      fill={color}
    />
    <rect
      x={36.141}
      y={16.429}
      width={3}
      height={2}
      rx={1}
      transform="rotate(-32.049 36.14 16.429)"
      fill={color}
    />
    <rect
      x={11.314}
      y={33.775}
      width={9}
      height={2}
      rx={1}
      transform="rotate(-32.049 11.314 33.775)"
      fill={color}
    />
    <rect
      x={10.363}
      y={37.91}
      width={9}
      height={2}
      rx={1}
      transform="rotate(-32.049 10.363 37.91)"
      fill={color}
    />
  </svg>
);

export default FastCreditCardIcon;
