import { useState } from 'react';
import {
  Box,
  Divider,
  CardMedia,
  Typography,
  Fade,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useUrl } from 'common/hooks';

import OfferBox from './OfferBox';

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    height: 50,
    width: 1,
    backgroundColor: theme.palette.common.basic300,
  },
  topInfo: {
    marginBottom: 8,
  },
  icon: {
    height: 40,
    width: 40,
  },
  divider: {
    color: theme.palette.common.basic300,
    [theme.breakpoints.up('sm')]: {
      marginRight: 40,
    },
  },
  bottomInfo: {
    marginTop: 8,
  },
  creditCardImg: {
    height: 32,
    width: 170,
  },
  creditCardPill: {
    backgroundColor: theme.palette.common.success500,
    height: 24,
    width: 190,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightArrow: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 32,
  },
}));

export type ZeroPercentCardOfferProps = {
  homeownerId: string;
  ccDisclosure?: string;
}

const ZeroPercentCardOffer = ({ homeownerId }: ZeroPercentCardOfferProps): JSX.Element => {
  const classes = useStyles();
  const { appUrl } = useUrl();
  const [showCreditCardDetails, setShowCreditCardDetails] = useState(false);

  return (
    <Box>
      <OfferBox
        {...(showCreditCardDetails) ?
          {
            showClose: true,
            onClose: () => setShowCreditCardDetails(false),
            variant: 'outlined',
            buttonCta: 'Explore credit cards',
            chooseLink: appUrl(`/v1/credit_cards/redirect/${homeownerId}`),
          } :
          {
            rightAction:
            <Box className={classes.rightArrow} onClick={() => setShowCreditCardDetails(true)}>
              <CardMedia
                className={classes.icon}
                component="img"
                alt="Right arrow icon"
                src="https://hearth.imgix.net/contractor-v2/offers/right_arrow_green.svg"
              />
            </Box>,
          }
        }
      >
        {showCreditCardDetails ?
          <Fade in={showCreditCardDetails} timeout={650}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box className={classes.creditCardPill}>
                  <Typography variant="subtitle2" color="secondary">
                    CREDIT CARD OPTIONS
                  </Typography>
                </Box>
              </Box>
              <Box mt={2} mb={4}>
                <Typography variant="subtitle2" color="textPrimary">
                  Eligible users can apply for 0% Intro APR credit cards, which are a great option
                  to finance large purchases, such as a home improvement project. These cards can
                  offer 0% for up to 18 months on purchases.
                </Typography>
              </Box>
            </Box>
          </Fade> :
          <Box display="flex" flexDirection="column">
            <Box className={classes.creditCardPill}>
              <Typography variant="subtitle2" color="secondary">
                CREDIT CARD OPTIONS
              </Typography>
            </Box>
            <Box my={1}>
              <Typography variant="h3">
                <Box fontWeight="fontWeightMedium" color="textPrimary" mb={1}>
                  You are eligible to apply for a 0% Intro APR credit card.
                </Box>
              </Typography>
              <Typography variant="body2" color="textPrimary">
                * Not all applicants will qualify for this credit card offering.
              </Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box my={1}>
              <CardMedia
                className={classes.creditCardImg}
                component="img"
                alt="Credit card images"
                src="https://hearth.imgix.net/contractor-v2/offers/credit_cards.svg"
              />
            </Box>
          </Box>
        }
      </OfferBox>
    </Box>
  );
};

export default ZeroPercentCardOffer;
