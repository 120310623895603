import { SvgIconProps } from '../Icon';

const PeopleIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...otherProps}>
    <path
      d="M20.319 31.234l-.358-2.508c4.872 1.107 7.539-.226 7.539-.226a18.984 18.984 0 01-3-9v-1.726a8.182 8.182 0 00-7.467-8.257A8 8 0 008.5 17.5v2a18.983 18.983 0 01-3 9s2.667 1.333 7.539.226l-.358 2.508a2 2 0 01-1.4 1.633l-6.643 1.992A3 3 0 002.5 37.732V42.5h28v-4.768a3 3 0 00-2.138-2.873l-6.638-1.992a2 2 0 01-1.405-1.633zM36.5 42.5h10v-8.709a3 3 0 00-2.211-2.891l-7.521-2.051a2 2 0 01-1.454-1.649l-.353-2.473c4.872 1.106 7.539-.227 7.539-.227a18.984 18.984 0 01-3-9v-1.726a8.182 8.182 0 00-7.467-8.257A7.974 7.974 0 0026.209 7.5"
      stroke={color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PeopleIcon;
