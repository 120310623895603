import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import constate from 'constate';

import { verticalSelector } from 'contractor/selectors/organization';
import { verticalContent } from 'common/utils/verticalContent.json';
import { VerticalContentType, InitialVerticalType, Vertical } from 'contractor/types/verticals';

type Brand = 'hearth' | 'brokerage' | 'hilp';

type BrandMetadata = {
  key: string;
  logoSrc: string;
  label: string;
  slogan?: string;
  logoHref: string;
};

type VerticalBrandData = {
  key: Vertical;
  defaultBrand: Brand;
  associatedBrandIds: Brand[];
  associatedBrands: BrandMetadata[];
};

const hostVertical: Record<string, Vertical> = {
  'app.gethearth.com': 'home_improvement',
  'staging-hearth-api.shogun.cool': 'home_improvement',
};

const brands: Record<Brand, BrandMetadata> = {
  hearth: {
    key: 'hearth',
    label: 'Hearth',
    slogan: 'Home Improvement Financing Done Right',
    logoSrc: 'https://hearth.imgix.net/logos/hearth-2018.svg',
    logoHref: 'https://gethearth.com',
  },
  brokerage: {
    key: 'brokerage',
    label: 'Hearth Brokerage',
    logoSrc: 'https://hearth.imgix.net/logos/hearth-brokerage-2018.svg',
    logoHref: '#',
  },
  hilp: {
    key: 'hilp',
    label: 'Shogun Enterprises, Inc.', // doesn't match with hilp but per AV request and AMS Changes Jun 2020
    logoSrc: 'https://hearth.imgix.net/whitelabel/shogun.png', // see comment 2 lines up
    logoHref: '#',
  },
};

const verticals: Record<Vertical, VerticalBrandData> = {
  home_improvement: {
    key: 'home_improvement',
    defaultBrand: 'hearth',
    associatedBrandIds: ['hearth', 'brokerage', 'hilp'],
    associatedBrands: map(['hearth', 'brokerage', 'hilp'],
      (brand: Brand): BrandMetadata => brands[brand]),
  },
};

type Result = [
  {
    brandMetadata: BrandMetadata;
    verticalBrandData: VerticalBrandData;
    verticalMetadata: VerticalContentType;
    partnerOrganizationId: number | null;
  },
  {
    setBrand: (brand: Brand) => void;
    setPartnerOrganizationId: (organizationId: number) => void;
    setVertical: (vertical: Vertical) => void;
  }
];

const useBaseVerticalState = (): Result => {
  // NOTE: in order to resolve types, I had to transform the JSON file data
  // so the values aligned with the defined vertical types
  const verticalMetadata: InitialVerticalType =
    JSON.parse(JSON.stringify(verticalContent));
  const { hostname } = window.location;
  const [brand, setBrand] = useState<Brand>('hearth');
  const [vertical, setVertical] = useState<Vertical>(hostVertical[hostname] ||
    'home_improvement');
  const [partnerOrganizationId, setPartnerOrganizationId] = useState<number | null>(null);
  const orgVertical = useSelector(verticalSelector);

  useEffect(() => {
    if (orgVertical != null) {
      setVertical(orgVertical);
    }
  }, [orgVertical, setVertical]);

  useEffect(() => {
    setBrand(verticals[vertical].defaultBrand);
  }, [vertical]);

  return [
    {
      brandMetadata: brands[brand],
      verticalBrandData: verticals[vertical],
      verticalMetadata: mapValues(verticalMetadata, vertical),
      partnerOrganizationId,
    },
    {
      setPartnerOrganizationId,
      setBrand,
      setVertical,
    },
  ];
};

const [Provider, useVerticalState] = constate(useBaseVerticalState);
export default useVerticalState;
export const VerticalStateProvider = Provider;
