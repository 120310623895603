import React, { memo } from 'react';
import { useField } from 'formik';
import { sentenceCase } from 'change-case';
import { FormHelperText, Typography } from '@mui/material';

import RadioGroup, { RadioGroupProps } from 'common/components/material-ui/RadioGroup';
import { RadioOptionComponentProps } from 'common/components/elements/RadioOption';

type Props = Omit<RadioGroupProps, 'ref' | 'value' | 'onChange' | 'onBlur'> & {
  name: string;
  label?: string | null;
  helperText?: string;
  radioColor?: Color;
  radioSize?: number;
  OptionContainer?: React.ComponentType<RadioOptionComponentProps>;
};

const RadioGroupField = ({
  name,
  label,
  helperText,
  radioColor,
  radioSize,
  children,
  OptionContainer,
  ...otherProps
}: Props) => {
  const [field, meta] = useField(name);
  const error = meta.touched ? meta.error : undefined;

  const caption = error || helperText;

  return (
    <>
      {label !== null &&
        <Typography variant="body1">
          {label !== undefined ? label : sentenceCase(name)}
        </Typography>
      }
      <RadioGroup
        {...field}
        {...otherProps}
      >
        {React.Children.map(children, child => (
          React.isValidElement(child) ?
            React.cloneElement(child, {
              color: radioColor,
              size: radioSize,
              Component: OptionContainer,
            }) :
            child
        ))}
      </RadioGroup>
      {caption && (
        typeof caption === 'string' ?
          <FormHelperText error={!!error}>{caption}</FormHelperText> :
          caption
      )}
    </>
  );
};

export default memo(RadioGroupField);
