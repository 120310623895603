import { useMemo } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { parse, stringify } from 'query-string';
import { gql } from '@apollo/client';

import {
  isLoggedInSelector,
  logoutPendingSelector,
} from 'contractor/selectors/contractor';
import { useVerticalState } from 'common/hooks';
import * as localStorage from 'common/services/localStorage';
import { logoutDispatcher } from 'contractor/actions/auth';
import { useGetAuthenticatedInfoQuery } from 'types';
import { isDashboardPageKey } from 'contractor/types/verticals';

const useRedirect = (location: RouteProps['location']): string | null => {
  const { data } = useGetAuthenticatedInfoQuery();

  const [{ verticalMetadata }] = useVerticalState();
  const { redirects } = verticalMetadata.navigation;

  const isLoggedIn = useSelector(isLoggedInSelector);
  const isAdmin = data?.contractor?.admin ?? false;
  const isOrgOnboarded = data?.organization?.onboarded ?? false;
  const subscriptionContractStatus = data?.organization?.subscriptionContract.status;
  const passwordSet = data?.contractor?.passwordSet;

  const path = location ? location.pathname : '';
  const query = location && parse(location.search);
  const dashKey = path.split('/')[2];
  // eslint-disable-next-line max-len
  const customRedirect = (path && redirects && isDashboardPageKey(dashKey)) ? redirects[dashKey] : false;

  return useMemo(() => {
    let redirectPath = '';
    if (isLoggedIn) {
      if (query?.redirect && typeof query?.redirect === 'string') {
        redirectPath = query.redirect;
      } else {
        redirectPath = '/dashboard';
      }

      if (path.startsWith('/quickbooks')) {
        redirectPath = '';
      }

      if (isAdmin && (
        (subscriptionContractStatus === 'not-signed') ||
        !isOrgOnboarded ||
        !passwordSet
      )) {
        redirectPath = '/setup';
      }

      if (path.startsWith('/payments') || path.startsWith('/invoice-preview')) {
        redirectPath = path;
      }
    } else if (path !== '/seats/signup' &&
              path !== '/login' &&
              path !== '/oauth') {
      const newRedirect = path + (query ? `?${stringify(query)}` : '');
      redirectPath = `/login?${stringify({ redirect: newRedirect })}`;
    }

    if (path === redirectPath || path.startsWith(redirectPath)) {
      if (customRedirect) {
        redirectPath = customRedirect;
      } else {
        return null;
      }
    }
    return redirectPath;
  }, [
    customRedirect,
    isAdmin,
    isLoggedIn,
    isOrgOnboarded,
    passwordSet,
    path,
    query,
    subscriptionContractStatus,
  ]);
};

const AuthenticatedRoute = ({ location, ...props }: RouteProps): JSX.Element | null => {
  const redirectPath = useRedirect(location);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isLoggingOut = useSelector(logoutPendingSelector);
  const dispatch = useDispatch();

  if (isLoggedIn &&
    !isLoggingOut &&
    localStorage.hasStorage() &&
    !localStorage.getItem('accessToken') &&
    !localStorage.getItem('refreshToken')
  ) {
    dispatch(logoutDispatcher({}));
    return null;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return <Route {...props} />;
};

AuthenticatedRoute.GET_AUTHENTICATED_INFO = gql`
  query GetAuthenticatedInfo {
    featureFlags {
      id
    }
    contractor {
      id
      admin
      passwordSet
    }
    organization {
      id
      onboarded
      subscriptionContract {
        status
      }
    }
  }
`;

export default AuthenticatedRoute;
