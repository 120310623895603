import { createSelector, OutputSelector, SelectorArray } from 'reselect';

import { Contractor } from 'contractor/types';

// @TODO: Fill in AppState for state type
/*
  eslint-disable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/
export const idSelector = (state: any): number => state.contractor.id;

export const contractorSelector = (state: any): Contractor =>
  state.contractor.contractor;

export const isLoggedInSelector = (state: any): boolean =>
  state.contractor.isLoggedIn;

// @TODO(oauth): remove
export const logoutPendingSelector = (state: any): boolean =>
  state.contractor.logoutPending;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any
*/

export const adminSelector = createSelector(
  contractorSelector,
  contractor => contractor && contractor.admin,
);

export const enterpriseAdminSelector = createSelector(
  contractorSelector,
  contractor => contractor && contractor.enterpriseAdmin,
);

export const roleSelector = createSelector(
  contractorSelector,
  contractor => contractor && contractor.role,
);

export const onboardedSelector = createSelector(
  contractorSelector,
  contractor => contractor && (contractor.onboarded),
);

export const verticalIdSelector = createSelector(
  contractorSelector,
  contractor => (contractor && contractor.vertical) || 'home_improvement',
);

export const passwordSetSelector = createSelector(
  contractorSelector,
  contractor => contractor && contractor.passwordSet,
);

export const contractorPopUrlSelector = createSelector(
  contractorSelector,
  contractor => contractor && contractor.urlPath,
);

export const getEmailTemplateIdSelector = createSelector(
  contractorSelector,
  contractor => contractor.emailTemplateId,
);

const featureFlagSelectorFactory =
  (featureFlag: string): OutputSelector<SelectorArray, boolean, (res: Contractor) => boolean> =>
    createSelector(
      contractorSelector,
      contractor => contractor.featureFlags && contractor.featureFlags.includes(featureFlag),
    );

export const contractorFeatureFlagSelectors = {
  kitchenSinkBlocked: featureFlagSelectorFactory('kitchen_sink_blocked'),
  tryPayments: featureFlagSelectorFactory('try_payments_may2020'),
};

export const trainingVideoWatchedAtSelector = createSelector(
  contractorSelector,
  contractor => contractor.trainingVideoWatchedAt,
);

export const calendlyCancelLinkSelector = createSelector(
  contractorSelector,
  contractor => contractor.calendlyCancelLink,
);

export const webinarRedirectLinkSelector = createSelector(
  contractorSelector,
  contractor => contractor.webinarRedirectLink,
);

export const needsMoreTrainingSelector = createSelector(
  contractorSelector,
  contractor => contractor.needsMoreTraining,
);

export const trainingTimeSelector = createSelector(
  contractorSelector,
  contractor => contractor.trainingTime,
);

export const trainingCompleteSelector = createSelector(
  contractorSelector,
  contractor => contractor.trainingCompleted,
);

export const trainingScheduledSelector = createSelector(
  contractorSelector,
  contractor => contractor.trainingScheduled,
);

export const trainingScheduledAtSelector = createSelector(
  contractorSelector,
  contractor => contractor.trainingScheduledAt,
);

export const urlPathSelector = createSelector(
  contractorSelector,
  contractor => contractor.urlPath,
);

export const contractorHasAppSelector = createSelector(
  contractorSelector,
  contractor => contractor.appVersion !== null,
);

export const phoneNumberSelector = createSelector(
  contractorSelector,
  contractor => contractor.phoneNumber,
);

export const emailSelector = createSelector(
  contractorSelector,
  contractor => contractor.email,
);

export const referralCouponSelector = createSelector(
  contractorSelector,
  contractor => contractor.affiliateReferralCode,
);

/**
 * This will not fetch actual seatsSpiff information until the next iteration
 * of Seats Spiff. The current network calls to the backend do NOT include data
 * to fetch seats spiff data. Ensure that refreshData calls fetch seats spiff
 * information with Graphqlbefore using the following selector.
 */
export const seatsSpiffSelector = createSelector(
  contractorSelector,
  contractor => contractor.seatsSpiff,
);

/**
 * Selector to determine whether Cohere related components and the Cohere module
 * should start recording. This selector is disabled for logged in contractors, to
 * make sure that cohere does not record logged in sessions of our contractors.
 */
export const showCohereSelector = createSelector(
  isLoggedInSelector,
  loggedIn => process.env.REACT_APP_ENV === 'production' && !loggedIn,
);
