import { camelizeKeys } from 'humps';

export type JWT = {
  iss: string;
  env: string;
  iat: number;
  jti: string;
  refreshTokenJti: string;
  refreshTokenExpiresAt: number;
  expiresIn: string;
  type: string;
  scopes: string;
  applicationId: null;
  user: {
    id: number;
    email: string;
    organizationId: number;
    isCsUser?: boolean;
  };
}

export function decodeToken(token: string): JWT {
  const base64Url = token.split('.')[1];
  const decodedValue: JWT = JSON.parse(window.atob(base64Url));

  return camelizeKeys(decodedValue) as JWT;
}
