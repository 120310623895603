import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { currencyFormatFromCents } from 'common/utils/stringFormatters';

import { colors, StyleProps, TemplateProps } from './theme';
import { Attachments, Links, SignatureMessage } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 792,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.basic100,
  },
  themeColor: {
    color: ({ themeColor }: StyleProps) => colors[themeColor],
  },
  header: {
    backgroundColor: ({ themeColor }: StyleProps) => colors[themeColor],
    padding: '24px 56px',
    height: 256,
  },
  invoiceNumber: {
    padding: '8px 16px',
    borderRadius: 8,
    backdropFilter: 'brightness(0.85)',
    fontSize: 11,
    fontWeight: 600,
  },
  companyName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    borderRight: `1px solid ${theme.palette.common.basic100}`,
  },
  whiteText: {
    wordBreak: 'break-word',
    color: theme.palette.common.basic100,
    textOverflow: 'clip',
  },
  greyText: {
    color: theme.palette.common.basic900,
  },
  body: {
    padding: '24px 56px',
  },
  payment: {
    marginTop: -80,
    padding: '32px 24px',
    borderRadius: 8,
    backgroundColor: theme.palette.common.basic100,
    boxShadow: '0px 0px 25px rgba(0, 57, 169, 0.1)',
    display: 'flex',
    flexDirection: 'row',
  },
  dueDate: {
    marginTop: 8,
    padding: 8,
    borderRadius: 8,
    backgroundColor: '#E17371',
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.common.basic100,
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const ModernTemplate = ({
  settings,
  organization,
  invoice,
  links,
  attachments,
}: TemplateProps): JSX.Element => {
  const classes = useStyles({ themeColor: settings.primaryColor });

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.header}>
          <Box display="flex" flexDirection="row">
            <Box className={classes.invoiceNumber} color="common.basic100">
              Invoice #{invoice.invoiceNumber}
            </Box>
            <Box ml={2}>
              {invoice.sentAt &&
                <Typography variant="caption" className={classes.whiteText}>
                  Sent {format(Date.parse(invoice.sentAt), 'MMMM d, yyyy')}
                </Typography>
              }
            </Box>
          </Box>
          <Box mt={3} display="flex" flexDirection="row" justifyContent="space-between">
            <Box flex={1} pr={2} className={classes.companyName}>
              <Typography variant="h2" className={classes.whiteText}>
                {organization.companyName}
              </Typography>
            </Box>
            <Box flex={1} pl={2} className={classes.whiteText}>
              <Typography variant="caption">
                {organization.fullAddress}
              </Typography>
              <br />
              <Typography variant="caption">
                {organization.businessPhone}
              </Typography>
              <br />
              <Typography variant="caption">
                {organization.businessEmail}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classes.payment}>
            <Box flex={1.5}>
              <Typography variant="caption" className={classes.greyText}>
                Bill to
              </Typography>
              <Box mt={1} color="common.primary900">
                <Typography variant="body2">
                  {invoice.fullName}
                </Typography>
                <Box mt={1} />
                <Typography variant="body2">
                  {invoice.phoneNumber}
                </Typography>
                <Box mt={1} />
                <Typography variant="body2">
                  {invoice.email}
                </Typography>
              </Box>
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <Typography variant="caption" className={classes.greyText}>
                Total
              </Typography>
              <Box mt={1} />
              <Typography variant="h2">
                <Box className={classes.themeColor}>
                  {currencyFormatFromCents(invoice.amountRequested, 2)}
                </Box>
              </Typography>
              {invoice.dueDate &&
                <Box display="flex" flexDirection="row">
                  <Box className={classes.dueDate}>
                    Due: {format(Date.parse(invoice.dueDate), 'MMMM d, yyyy')}
                  </Box>
                </Box>
              }
            </Box>
          </Box>
          <Box m={3} />
          <Box px={3}>
            <Typography variant="caption" className={classes.greyText}>
              Description
            </Typography>
            <Box mt={1} />
            <Typography variant="body2" color="textPrimary">
              {invoice.description}
            </Typography>
            {invoice.personalNote &&
              <Box mt={8}>
                <Typography variant="caption" className={classes.greyText}>
                  Note:
                </Typography>
                <Box mt={1} />
                <Typography variant="body2" color="textPrimary">
                  {invoice.personalNote}
                </Typography>
              </Box>
            }
            <SignatureMessage signatureMessage={settings.signatureMessage} />
            {attachments.length > 0 &&
              <Attachments attachments={attachments} />
            }
          </Box>
        </Box>
      </Box>
      <Box mb={3} px={7} display="flex" flexDirection="row">
        <Box className={classes.linksContainer} flexGrow={1}>
          <Links links={links} themeColor={settings.primaryColor} />
        </Box>
        {settings.logo && organization.logoUrl &&
          <img
            src={organization.logoUrl}
            style={{ maxWidth: 100, maxHeight: 50 }}
            alt="logo"
          />
        }
      </Box>
    </Box>
  );
};

export default ModernTemplate;
