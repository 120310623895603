import { SvgIconProps, Box, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { ButtonBase } from 'common/components/material-ui';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
}));

type Props = {
  active: boolean,
  Icon: React.ComponentType<SvgIconProps>;
  onMouseDown: React.HTMLProps<HTMLSpanElement>['onMouseDown']; //  (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BaseButton = ({
  Icon,
  onMouseDown,
  active,
}: Props): JSX.Element => {
  const classes = useStyles();

  const color = active ? 'common.basic1100' : 'common.basic700';

  return (
    <Box mr={0.5}>
      <ButtonBase onMouseDown={onMouseDown}>
        <Box
          display="flex"
          p={0.5}
          color={color}
        >
          <Icon className={classes.icon} />
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default BaseButton;
