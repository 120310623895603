import { Box, Typography } from '@mui/material';

import { Popup } from 'contractor/components/widgets';
import { PopupProps } from 'contractor/components/widgets/Popup';
import { useUrl } from 'common/hooks';
import { Button } from 'common/components/material-ui';

type Props = {
  loanInquiryUuid?: string;
  onSubmit: () => void;
} & PopupProps;

const WvContract = ({
  loanInquiryUuid,
  onSubmit,
  ...otherProps
}: Props): JSX.Element => {
  const { apiUrl } = useUrl();
  const contractUrl = `/api/v2/loan_application/${loanInquiryUuid}/wv_contract.pdf`;

  return (
    <Popup
      closable={false}
      {...otherProps}
    >
      <Box border={6} p={5} borderRadius="4px" boxShadow={3} borderColor="common.basic300">
        <Typography variant="h2">
          <Box fontFamily="Lora" fontWeight="fontWeightRegular" mb={1}>
            West Virginia residents
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box>
            As a West Virginia resident, please review and agree that
            you’ve received these disclosures required by your state.
          </Box>
        </Typography>
        <Box my={3} mx={-5} p={3} bgcolor="common.basic300">
          <iframe
            src={apiUrl(contractUrl)}
            title="West Virginia Contract"
            style={{
              width: '100%',
              height: 356,
            }}
          >
            The browser that you are currently on does not support iframes. Please download
            the PDF by clicking the ‘Download PDF’ button below in order to review and agree
            that you have received these disclosures.
          </iframe>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box width={{ sm: 1 / 2 }}>
            <Button
              variant="contained"
              buttonType="common.basic"
              fullWidth
              onClick={() => window.open(apiUrl(contractUrl), '_blank')}
            >
              Download PDF
            </Button>
            <Box mb={2} />
            <Button
              variant="contained"
              buttonType="primary"
              type="submit"
              onClick={onSubmit}
              fullWidth
            >
              Agree and continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Popup>
  );
};

export default WvContract;
