import { Box } from '@mui/material';
import { FacebookProvider, Share } from 'react-facebook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Link } from 'common/components/material-ui';

const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

type Props = {
  shareUrl: string;
  shareQuote: string;
  ctaCopy: string;
  onClick: () => void;
}

const FacebookShareLink = ({
  shareUrl,
  shareQuote,
  ctaCopy,
  onClick,
}: Props): JSX.Element => (
  <FacebookProvider appId={fbAppId}>
    <Share href={shareUrl} quote={shareQuote}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        color="common.primary500"
      >
        <OpenInNewIcon />
        <Link
          variant="caption"
          color="inherit"
          onClick={onClick}
        >
          {ctaCopy}
        </Link>
      </Box>
    </Share>
  </FacebookProvider>
);

export default FacebookShareLink;
