import { useEffect, useState } from 'react';
import { useReloadingContainer } from 'bentoo/hooks';
import { Helmet } from 'react-helmet';
import { AppBar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router-dom';
import { camelizeKeys } from 'humps';
import { parse } from 'query-string';

import { useVerticalState, useUrl } from 'common/hooks';
import { Link } from 'common/components/material-ui';
import constants from 'common/utils/constants';
import { fetchMinimal } from 'contractor/actions/organization';
import { GenericNavbar } from 'main/components/navs';
import { ContactEmailModal } from 'contractor/components/modals';
import { AdvertiserDisclosureModal } from 'common/components/modals';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px 24px',
    height: 80,
    backgroundColor: theme.palette.common.basic100,
  },
  logo: {
    alignSelf: 'center',
    height: 24,
  },
  link: {
    paddingRight: 24,
  },
  support: {
    alignSelf: 'center',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: 'inherit',
    },
  },
  companyName: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 250,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  contactButton: {
    paddingLeft: 0,
  },
}));

type MatchParams = {
  partner?: string;
  organizationId?: string;
};

type QueryParams = {
  organizationId?: string;
};

const PopNavbar = ({ match, location }: RouteComponentProps<MatchParams>): JSX.Element => {
  const classes = useStyles();
  const [contactEmailOpen, setContactEmailOpen] = useState(false);
  const [disclosureModalOpen, setDisclosureModalOpen] = useState(false);
  const [
    { brandMetadata, partnerOrganizationId },
    { setBrand, setPartnerOrganizationId },
  ] = useVerticalState();

  const { marketingUrl } = useUrl();

  useEffect(() => {
    const { partner, organizationId } = match.params;

    if (partner === 'hilp') {
      setBrand(partner);
    }
    if (organizationId) {
      setPartnerOrganizationId(Number(organizationId));
    }
  }, [match, setBrand, setPartnerOrganizationId]);

  useEffect(() => {
    const { organizationId } = camelizeKeys(parse(location.search)) as QueryParams;
    if (organizationId) {
      setPartnerOrganizationId(Number(organizationId));
    }
  }, [location, setPartnerOrganizationId]);

  const minimalOrganization =
    useReloadingContainer(
      fetchMinimal,
      [partnerOrganizationId],
      { disabled: !partnerOrganizationId },
    );

  if (!minimalOrganization.data) {
    return <GenericNavbar />;
  }

  const organization = minimalOrganization.data;
  const contractorId = location.pathname.split('/')[3];

  return (
    <AppBar position="sticky" className={classes.root}>
      <Helmet>
        <title>
          {organization.companyTypeLabel} financing from{' '}
          {organization.companyName} | Hearth
        </title>
      </Helmet>
      {disclosureModalOpen &&
        <AdvertiserDisclosureModal
          open={disclosureModalOpen}
          onClose={() => setDisclosureModalOpen(false)}
        />
      }
      <Box mt="20px" mr="12px" display="flex" flex="1" justifyContent="space-between">
        <Link
          href={marketingUrl('/')}
        >
          <img
            alt={brandMetadata.label}
            className={classes.logo}
            src={brandMetadata.logoSrc}
          />
        </Link>
        <Box display="flex" alignItems="center">
          <Link
            variant="subtitle2"
            color="textPrimary"
            underline="always"
            onClick={() => setDisclosureModalOpen(true)}
            style={{ marginRight: 12 }}
          >
            Advertiser Disclosure
          </Link>
          <Link
            variant="body2"
            href={`tel:${constants.supportNumber}`}
          >
            <Box color="common.basic900">
              {constants.supportNumberDisplay}
            </Box>
          </Link>
        </Box>
      </Box>
      <ContactEmailModal
        contractorId={contractorId}
        open={contactEmailOpen}
        onClose={() => setContactEmailOpen(false)}
      />
    </AppBar>
  );
};

export default PopNavbar;
