import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteProps, useLocation } from 'react-router-dom';

import { useVerticalState } from 'common/hooks';
import { ToolsMenu } from 'contractor/modules/Tools';
import { ToolsProvider } from 'contractor/modules/Tools/useTools';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
  menu: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 32,
    },
  },
}));

const hiddenMenuPaths = [
  '/dashboard/tools/invoice-template/edit',
  '/dashboard/tools/contract-template/edit',
  '/dashboard/tools/contract-template/setup',
];

type Props = {
  children?: React.ReactNode;
} & RouteProps;

const ToolsContainer = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [{ brandMetadata }] = useVerticalState();
  const pageTitle = `Branding | ${brandMetadata.label}`;

  // using approach found here: https://www.carlrippon.com/scrolling-a-react-element-into-view/
  // as react-scroll does not appear to work as expected on mount
  const scrollElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box>
        <Box className={classes.root} mb={8}>
          {!hiddenMenuPaths.includes(pathname) &&
            <Box className={classes.menu}>
              <ToolsMenu />
            </Box>
          }
          <Container maxWidth="lg">
            <div ref={scrollElementRef} />
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default ((props: Props): JSX.Element => (
  <ToolsProvider>
    <ToolsContainer {...props} />
  </ToolsProvider>
));
