import { SvgIconProps } from '../Icon';

const CalculatorIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 40 46" fill="none" {...otherProps}>
    <path
      d="M39 1H1v44h38V1z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31 11H9v8h22v-8zM9 27h2M19 27h2M9 35h2M19 35h2M29 27h2M29 35h2"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalculatorIcon;
