import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  ripple: {
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.common.warningTransparent300,
    },
  },
  star: {
    cursor: 'pointer',
    color: theme.palette.common.basic500,
    '&:hover': {
      color: theme.palette.common.warning500,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
    },
  },
  filledStar: {
    color: theme.palette.common.warning500,
  },
  starsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

type Props = {
  maxRating?: number;
  rating?: number;
  onChange: (rating: number) => void;
}

const StarRating = ({ rating = 0, maxRating = 5, onChange }: Props): JSX.Element => {
  const classes = useStyles();
  // helps us determine what stars should be filled in
  const [starsFilled, setStarsFilled] = useState<number | null>(null);

  return (
    <Box>
      <Box className={classes.starsContainer}>
        {[...Array(maxRating)].map((_, index) => (
          <IconButton
            // eslint-disable-next-line react/no-array-index-key
            key={`star-${index}`}
            disableRipple
            disableTouchRipple
            aria-label={`star #${index}`}
            id={`star-${index}`}
            className={cx(
              classes.star,
              {
                [classes.filledStar]: (
                  index <= (starsFilled == null ? (rating - 1) : starsFilled)
                ),
              },
            )}
            onMouseEnter={() => setStarsFilled(index)}
            onMouseLeave={() => setStarsFilled(null)}
            classes={{ root: classes.ripple }}
            onClick={() => {
              if (starsFilled != null) {
                onChange(starsFilled + 1);
              } else {
                onChange(rating + 1);
              }
            }}
            size="large"
          >
            <StarRoundedIcon
              fontSize="inherit"
              color="inherit"
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};

export default StarRating;
