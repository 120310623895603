import {
  Box,
  Typography,
  IconButton,
} from '@mui/material';

import Tooltip, { TooltipProps } from 'common/components/material-ui/Tooltip';
import Icon, { IconName } from 'common/components/Icon';

type Props = {
  modalTitle?: string;
  modalText?: string;
  color: Color;
  icon?: IconName | React.ReactElement;
  tooltipProps?: Partial<TooltipProps>;
}

const InformationTooltip = ({
  modalTitle,
  modalText,
  color,
  icon = 'info-outline',
  tooltipProps = {},
}: Props): JSX.Element => (
  <Tooltip
    placement="top"
    title={
      <Box>
        <Box display="flex" flexDirection="row" mb={2} color="common.basic100">
          <Box mr={2} style={{ marginTop: 2 }}>
            <Icon
              name="info-outline"
              color="basic100"
              size={20}
              style={{ marginTop: 4 }}
            />
          </Box>
          <Typography>{modalTitle}</Typography>
        </Box>
        <Box>
          <Typography>{modalText}</Typography>
        </Box>
      </Box>
    }
    disableHoverListener={false}
    bgColor={color}
    clone
    {...tooltipProps}
  >
    <IconButton size="small">
      {typeof icon === 'string' ?
        <Icon
          name={icon}
          color={color}
          size={20}
        /> :
        <Box color={`common.${color}`}>
          {icon}
        </Box>}
    </IconButton>
  </Tooltip>
);

export default InformationTooltip;
