import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import clamp from 'lodash/clamp';

import { useUrl, useVerticalState } from 'common/hooks';
import { LoanInquiry } from 'types';
import { Loader } from 'common/components/widgets';
import { ApplicationResult } from 'common/models';
import {
  SectionHeader,
  CoborrowerCard,
  AdjustAmountCard,
  ExploreCreditCard,
} from './components';

type Props = {
  loanInquiry: LoanInquiry | ApplicationResult;
  title: string;
  description: string;
  homeownerId: string | number;
  noOffers: boolean;
}

const OtherOptions = ({
  loanInquiry,
  title,
  description,
  homeownerId,
  noOffers,
}: Props): JSX.Element => {
  const { appUrl } = useUrl();
  const history = useHistory();
  const [{ verticalMetadata }] = useVerticalState();
  const { noOffers: { showCCOption } } = verticalMetadata.offers;

  const { jointApplication, householdIncome, requestedAmount } = loanInquiry;

  if (!householdIncome || !requestedAmount) {
    return <Loader />;
  }

  const showCoborrower = !jointApplication;
  const quarterOfIncome = householdIncome / 4;
  const showAdjustAmount = requestedAmount >= quarterOfIncome;
  const recommendedAmount = Math.ceil(clamp(quarterOfIncome, 5001, 250000) / 100) * 100;
  const creditCardUrl = appUrl(`/v1/credit_cards/redirect/${homeownerId}`);

  return (
    <>
      {(showCoborrower || showAdjustAmount || showCCOption) &&
      <>
        <SectionHeader
          image={{
            src: 'https://hearth.imgix.net/nooffers/thinking.png',
            alt: 'thinking person',
          }}
          title={title}
          body={description}
        />
        <Box mt={5} />
        <Grid container spacing={2}>
          {showCoborrower &&
            <Grid item xs={12} sm={showAdjustAmount ? 6 : 12}>
              <CoborrowerCard
                onClick={() => history.push('reapply')}
              />
            </Grid>
          }
          {showAdjustAmount &&
          <Grid item xs={12} sm={showCoborrower ? 6 : 12}>
            <AdjustAmountCard
              recommendedAmount={recommendedAmount}
              onClick={() => history.push('reapply?recommended_amount=true')}
            />
          </Grid>
          }
          {showCCOption && noOffers &&
            <Grid item xs={12}>
              <ExploreCreditCard
                href={creditCardUrl}
              />
            </Grid>
          }
        </Grid>
      </>
      }
    </>
  );
};

export default OtherOptions;
