import * as React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LinkProps } from 'react-router-dom';

import { ButtonBase, Note } from 'common/components/material-ui';
import { Props as NoteProps } from 'common/components/material-ui/Note';

export type ClickableNoteProps = {
  onClick?: () => void;
  to?: LinkProps['to'];
  href?: LinkProps['href'];
  target?: LinkProps['target'];
  children?: React.ReactNode;
  component?: React.ReactNode;
} & NoteProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    borderRadius: 6,
    border: '2px solid transparent',
    '&:hover': {
      border: `2px solid ${theme.palette.common.primary500}`,
      boxShadow: theme.shadows[24],
      cursor: 'pointer',
    },
  },
}));

const ClickableNote = ({
  onClick,
  children,
  component,
  to,
  href,
  target,
  ...otherProps
}: ClickableNoteProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ButtonBase
      onClick={onClick}
      className={classes.root}
      component={component}
      to={to}
      href={href}
      target={target}
    >
      <Note
        style={{ height: '100%' }}
        {...otherProps}
      >
        {children}
      </Note>
    </ButtonBase>
  );
};

export default ClickableNote;
