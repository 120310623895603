import { ApiResponse } from 'promise-action';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { decamelizeKeys } from 'humps';

import { MinimalOrganization } from 'contractor/types';

import { Organization } from 'types';
import client from './client';

export const fetch = (id: number | string): Promise<AxiosResponse> => client.request({
  method: 'GET',
  url: `/organizations/${id}`,
});

export const fetchSeats = (id: number | string): Promise<AxiosResponse> => client.request({
  method: 'GET',
  url: `/organizations/${id}/seats`,
});

type FetchMinimalResponse = ApiResponse<{organization: MinimalOrganization}>;
export const fetchMinimal = ({ id }: { id: number | string }): Promise<FetchMinimalResponse> =>
  client.request({
    method: 'GET',
    url: `/organizations/${id}/minimal`,
  });

type QuestionnaireResponse = AxiosResponse<{
  businessQuestionnaireResponse: {
    answers: Record<string, string>;
    unansweredQuestions: string[];
  };
}>

export const fetchQuestionnaire =
  ({ id }: { id: number | string }): Promise<QuestionnaireResponse> =>
    client.request({
      method: 'GET',
      url: `/organizations/${id}/business_questionnaire_answers`,
    });

type SubmitQuestionnaireArgs = {
  id: number | string;
  data: Record<string, string>;
}

export const submitQuestionnaire =
({ id, data }: SubmitQuestionnaireArgs): Promise<QuestionnaireResponse> =>
  client.request({
    method: 'PATCH',
    url: `/organizations/${id}/business_questionnaire_answers/submit`,
    data,
  });

export const updateOrganization = (organizationData: any): Promise<AxiosResponse> =>
  client.request({
    method: 'PUT',
    url: `/organizations/${organizationData.id}`,
    data: { organization: organizationData },
  });

type UploadLogoParams = {
  organizationId: string;
  file: File;
}

export const uploadLogo =
({ file, organizationId }: UploadLogoParams): Promise<AxiosResponse<Partial<Organization>>> => {
  const data = new FormData();
  data.append('logo', file);
  return client.request({
    method: 'POST',
    url: `/organizations/${organizationId}/logo_upload`,
    data,
  });
};

export const validateSeatSignup = (organizationId: string | number): Promise<AxiosResponse> =>
  client.request({
    method: 'GET',
    url: `/organizations/${organizationId}/validate_seat_signup`,
  });

export const fetchBilling = (organizationId: string | number): Promise<AxiosResponse> =>
  client.request({
    method: 'GET',
    url: `/organizations/${organizationId}/billing`,
  });

export const submitProjectLeadsQuestionnaire = (
  organizationId: string | number,
  data: unknown,
): Promise<AxiosResponse> =>
  client.request({
    method: 'PUT',
    url: `/organizations/${organizationId}/project_leads_questionnaire`,
    data,
  });

type PaymentSetupLinkParams = {
  id: number;
  data: {
    authorizedRedirectUri: string;
    contractId: string;
  };
};
type PaymentSetupLinkResponse = ApiResponse<{ link: string }>
export const paymentsSetupLink =
  ({ id, data }: PaymentSetupLinkParams): Promise<PaymentSetupLinkResponse> =>
    client.request({
      method: 'POST',
      url: `/organizations/${id}/payments/setup_link`,
      data,
    });

type PaymentAuthorizeParams = {
  id: number;
  data: {
    code: string;
    redirectUrl: string;
    contractId: string;
  };
};
type PaymentAuthorizeResponse = ApiResponse<{ redirectUrl: string }>
export const paymentsAuthorize =
  ({ id, data }: PaymentAuthorizeParams): Promise<PaymentAuthorizeResponse> =>
    client.request({
      method: 'POST',
      url: `/organizations/${id}/payments/authorize`,
      data,
    });

type GenerateIntuitOauthLinkResponse = AxiosResponse<{url: string}>
export const generateIntuitOauthLink = (): Promise<GenerateIntuitOauthLinkResponse> => {
  const params = stringify(
    decamelizeKeys(
      { authorizedRedirectUri: `${process.env.REACT_APP_API_HOST}/dashboard/settings/hearth-pay?quickbooks=true` },
    ),
  );
  return client.get(`/intuit/generate_intuit_oauth_link?${params}`);
};
