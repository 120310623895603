import { useDispatch } from 'react-redux';
import { useMediaQuery, Theme } from '@mui/material';
import { useState, useEffect } from 'react';

import { Note } from 'common/components/material-ui';
import { useSnack } from 'common/utils/snackCart';
import { useVerticalState } from 'common/hooks';
import { loginDispatcher } from 'contractor/actions/auth';
import LoginModal from 'contractor/components/modals/LoginModal';
import useLogEvent from 'common/hooks/useLogEvent';

import LoginForm, { Values as FormValues } from './LoginForm';

const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { errorSnack } = useSnack('', 'Incorrect email or password.');
  const [{ verticalMetadata }] = useVerticalState();
  const { title } = verticalMetadata.login;
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    { defaultMatches: undefined },
  );
  const itly = useLogEvent();

  const onSubmit = async (values: FormValues): Promise<void> => {
    try {
      // await to catch the error
      await dispatch(loginDispatcher(values));
      itly.logIn();
    } catch (e) {
      errorSnack();
    }
  };

  const [modalOpen, setModalOpen] = useState(isMobile);
  useEffect(() => {
    if (isMobile != null) {
      setModalOpen(isMobile);
    }
  }, [isMobile]);

  return (
    <>
      <Note
        title={title}
        subtitle="Log in"
      >
        <LoginForm
          onSubmit={onSubmit}
        />
      </Note>
      <LoginModal modalOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default Login;
