import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { Link } from 'common/components/material-ui';

const useStyles = makeStyles({
  linkRoot: {
    marginRight: 8,
    marginTop: 8,
  },
  linkBox: {
    '&:hover': {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    },
  },
});

type Attachment = {
  link: string;
  attachmentFileName: string;
}

type Props = {
  attachments: Attachment[];
}

const Attachments = ({ attachments }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box mt={4} display="flex" flexWrap="wrap" flexDirection="row">
      {attachments.map((attachment, index) => (
        <Link
          component="a"
          href={attachment.link.startsWith('localhost') ? `//${attachment.link}` : attachment.link}
          target="_blank"
          classes={{ root: classes.linkRoot }}
          style={{ textDecoration: 'none' }}
        >
          <Box
            py={1}
            px={2}
            border={1}
            borderRadius="8px"
            borderColor="common.basic500"
            display="flex"
            flexDirection="row"
            width={200}
            className={classes.linkBox}
          >
            <Box mr={1.5} color="common.basic700" display="flex" alignItems="center">
              <AttachFileIcon fontSize="large" />
            </Box>
            <Box color="common.basic1100">
              <Typography variant="overline">Attachment {index + 1}</Typography>
              <Typography
                noWrap
                variant="body1"
                style={{ maxWidth: 130 }}
              >
                {attachment.attachmentFileName}
              </Typography>
            </Box>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default Attachments;
