import * as React from 'react';
import { useTheme, Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
  show?: number;
  color: Color;
  bulletSize?: number;
  noBullets?: boolean;
}

const BulletedList = ({
  children,
  show,
  color,
  bulletSize = 12,
  noBullets = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const childrenCount = React.Children.count(children);
  const showCount = Math.min(
    show || React.Children.count(children),
    React.Children.count(children),
  );

  const lineWidth = Math.min(2, bulletSize / 10);

  return (
    <Box flex={1}>
      {React.Children.map(children, (child, index) => (
        (index >= showCount) ?
          null :
          <>
            <Box display="flex" flexDirection="row" alignItems="stretch">
              {!noBullets &&
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mr={noBullets ? 0 : 1}
                  flex={0}
                  position="relative"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    bgcolor={`common.${color}`}
                    width={bulletSize}
                    height={bulletSize}
                    borderRadius={1}
                  />
                  <Box
                    bgcolor={`common.${color}`}
                    width={lineWidth}
                    position="absolute"
                    top={index ? 0 : '50%'}
                    bottom={(index !== showCount - 1) ? 0 : '50%'}
                    left={(bulletSize - lineWidth) / 2}
                  />
                  {(index === showCount - 1) && showCount < childrenCount &&
                    <Box
                      width={lineWidth}
                      position="absolute"
                      top="50%"
                      bottom={0}
                      left={(bulletSize - lineWidth) / 2}
                      style={{
                        background: `linear-gradient(180deg, ${theme.palette.common[color]} 0%, ${theme.palette.common.basic100} 150%)`,
                      }}
                    />
                  }
                </Box>
              }
              <Box flex={1}>
                {child}
              </Box>
            </Box>
            {noBullets && index < childrenCount - 1 &&
              <Box
                width={lineWidth}
                style={{
                  background:
                    (index === showCount - 1) && showCount < childrenCount ?
                      `linear-gradient(180deg, ${theme.palette.common[color]} 0%, ${theme.palette.common.basic100} 150%)` :
                      theme.palette.common[color],
                  marginLeft: (bulletSize - lineWidth) / 2,
                }}
                height={18}
              />
            }
          </>
      ))}
    </Box>
  );
};

export default BulletedList;
