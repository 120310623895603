import { Box, Grid } from '@mui/material';
import { useField } from 'formik';

import options from 'common/utils/options';

import AddressField from './AddressField';
import InputField from './InputField';
import SelectField from './SelectField';

type Props = {
  name: string;
  cityName: string;
  stateName: string;
  zipCodeName: string;
};

const ExpandableAddressField = ({
  name,
  cityName,
  stateName,
  zipCodeName,
  ...otherProps
}: Props): JSX.Element => {
  const [, { value: address }] = useField<string>(name);
  const [, { value: city }] = useField<string>(cityName);
  const [, { value: state }] = useField<string>(stateName);
  const [, { value: zipCode }] = useField<string>(zipCodeName);

  return (
    <>
      <AddressField
        name={name}
        cityName={cityName}
        stateName={stateName}
        zipCodeName={zipCodeName}
        {...otherProps}
      />
      {(address || city || state || zipCode) &&
        <>
          <Box m={2} />
          <InputField name={cityName} label="City" />
          <Box m={2} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectField
                name={stateName}
                label="State"
                items={options.states}
                native
                inputProps={{ 'data-testid': 'client-state-field' }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField name={zipCodeName} label="Zip code" />
            </Grid>
          </Grid>
        </>
      }
    </>
  );
};

export default ExpandableAddressField;
