import { useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { AbTestProvider } from 'common/services/abTest';
import Sake from 'main/modules/Sake';
import { Loader } from 'common/components/widgets';
import { sakeSelector, abTestSelector } from 'common/selectors/user';
import { isProduction } from 'config/app';
import NavbarRoute from 'common/components/routes/NavbarRoute';
import { SetupProgressProvider } from 'contractor/hooks/useSetupProgress';
import { OnboardingWalkthroughProvider } from 'contractor/modules/OnboardingWalkthrough/useOnboardingWalkthrough';
import { PageProvider } from 'common/hooks/usePageRefs';
import useApp from 'common/hooks/useApp';
import * as session from 'common/utils/session';

import './assets/styles/application.scss';

// Configure 3rd party APIs
session.configure();

type Props = {
  children?: ReactNode;
};

const App = ({ children }: Props): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const loading = useApp();
  const sake = useSelector(sakeSelector);
  const abTestInfo = useSelector(abTestSelector);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Reset location.state on reload
    if (location.state) {
      history.replace({ ...location, state: undefined });
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!abTestInfo || loading) {
    return <Loader withOverlay />;
  }

  return (
    <AbTestProvider abTestInfo={abTestInfo}>
      <SetupProgressProvider>
        <OnboardingWalkthroughProvider>
          <PageProvider>
            {!isProduction && sake && <Sake />}
            <NavbarRoute />
            <div id="content">
              {children}
            </div>
          </PageProvider>
        </OnboardingWalkthroughProvider>
      </SetupProgressProvider>
    </AbTestProvider>
  );
};

export default App;
