import { useState } from 'react';
import {
  Box,
  Typography,
  ClickAwayListener,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import find from 'lodash/find';

export type ParagraphSelectOption = {
  label: string;
  sublabel?: string;
  value: string;
}

type Props = {
  name: string;
  options: ParagraphSelectOption[];
  value?: ParagraphSelectOption['value'];
  placeholder?: string;
  onSelect: (event: {
    target: {
      name: string;
      value: ParagraphSelectOption['value'];
    };
  }) => void;
  error: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.common.basic300,
      cursor: 'pointer',
    },
  },
}));

const ParagraphSelect = ({
  name,
  options,
  onSelect,
  value,
  placeholder = '　　　　',
  error,
}: Props): JSX.Element => {
  const [selectOpen, setSelectOpen] = useState(false);
  const classes = useStyles();
  const color = error ? 'common.danger500' : 'common.primary500';

  return (
    <ClickAwayListener onClickAway={() => setSelectOpen(false)}>
      <Box
        display="inline-block"
        onClick={() => setSelectOpen(!selectOpen)}
        className={classes.container}
      >
        {selectOpen &&
          <Box
            sx={{
              display: 'inherit',
              position: 'absolute',
              borderRadius: 1,
              zIndex: 'tooltip',
              backgroundColor: 'common.basic100',
              boxShadow: 5,
            }}
            onMouseLeave={() => setSelectOpen(false)}
          >
            {options.map(item => (
              <Box
                key={item.label}
                className={classes.hover}
                sx={{
                  fontFamily: 'Lora',
                  width: 250,
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 1.5,
                  px: 2.5,
                  borderRadius: 1,
                  fontSize: 16,
                  color: value === item.value ? color : 'common.basic1100',
                }}
                onClick={() => {
                  onSelect({ target: { name, value: item.value } });
                }}
              >
                <Typography variant="inherit" color="inherit">
                  {item.label}
                </Typography>
                <Typography variant="inherit" color="inherit">
                  {item.sublabel}
                </Typography>
              </Box>
            ))}
          </Box>
        }
        <Box color={value ? color : 'common.basic500'} style={{ textDecoration: 'underline' }}>
          {value ? (find(options, { value }) as ParagraphSelectOption).label : placeholder}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default ParagraphSelect;
