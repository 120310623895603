import { Box, Typography } from '@mui/material';

import { Popup, Question } from 'contractor/components/widgets';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (answer: string) => Promise<void>;
  question: Question;
}

const ProjectLeadsModal = ({
  question,
  open,
  onClose,
  onSubmit,
}: Props): JSX.Element => (
  <Popup
    open={open}
    onClose={onClose}
    fullWidth
  >
    <Box display="flex" flexDirection="column" pl={5} pr={4} py={2.5} flex={1}>
      <Typography variant="body2" color="textPrimary">
        <Box fontWeight="fontWeightRegular">
          Thanks for your interest. Project leads for Hearth pros are coming soon; we’ve added you
          to our waitlist and will let you know as soon as they are available in your area.
        </Box>
      </Typography>
      <Box bgcolor="common.yellow" mt={3} mb={2} py={3} textAlign="center">
        <Typography variant="body1" color="textPrimary">
          <Box fontWeight="fontWeightMedium">{question.label}</Box>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Question
          name="project-leads"
          choices={question.choices}
          onChoose={choice => onSubmit(choice.value)}
        />
      </Box>
    </Box>
  </Popup>
);

export default ProjectLeadsModal;
