import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { Link } from 'common/components/material-ui';

const Banner = styled('div', { name: 'Banner' })(({ align }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: align,
  height: '100%',
}));

const BannerContainer = styled('div', { name: 'BannerContainer' })({
  position: 'relative',
});

const BannerBox = ({
  children,
  bannerContent,
  bannerAlignment,
  onClick,
  Container,
  ParentContainer,
  bannerContainerStyle,
}) => (
  <ParentContainer>
    <Link
      component="div"
      onClick={onClick}
      noDecoration
    >
      <BannerContainer style={bannerContainerStyle}>
        <Banner align={bannerAlignment}>
          {bannerContent}
        </Banner>
      </BannerContainer>
      <Container>
        {children}
      </Container>
    </Link>
  </ParentContainer>
);

BannerBox.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  bannerContainerStyle: PropTypes.shape({}),
  bannerContent: PropTypes.element,
  bannerAlignment: PropTypes.string,
  Container: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  ParentContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

BannerBox.defaultProps = {
  bannerContent: null,
  bannerContainerStyle: null,
  bannerAlignment: 'center',
  onClick: () => {},
};

export default BannerBox;
