import { SvgIconProps } from '../Icon';

const FlagIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...otherProps}>
    <path
      d="M5.41675 2.08325H35.4167L28.7501 12.0833L35.4167 22.0833H5.41675"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41675 2.08325V38.7499"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FlagIcon;
