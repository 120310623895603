import { sentenceCase } from 'change-case';

export function getErrorString(key: string, value: string | string[]) {
  if (value && value.length > 0) {
    return `${key} ${value[0]}`;
  }
  return null;
}

export function errorsFromApiValidations(errors: Record<string, string | string[]>) {
  const customLabels = {
    urlPath: 'Link',
  };

  return Object.keys(errors).reduce((obj, errorKey) => (
    {
      ...obj,
      [errorKey]:
        getErrorString(customLabels[errorKey] || sentenceCase(errorKey), errors[errorKey]),
    }
  ), {});
}
