import { useMemo, memo } from 'react';
import { useField } from 'formik';
import { Typography, Box } from '@mui/material';

import { Checkbox } from 'common/components/material-ui';
import { Props as CheckboxProps } from 'common/components/material-ui/Checkbox';

export type CheckboxFieldProps = Omit<CheckboxProps, 'ref' | 'value' | 'checked' | 'onChange' | 'onBlur' | 'label'> & {
  name: string;
  label: React.ReactElement | string;
};

const CheckboxField = ({
  name,
  label,
  ...otherProps
}: CheckboxFieldProps): JSX.Element => {
  // Taking out value here because formik checks string value
  // https://github.com/jaredpalmer/formik/blob/c0fe1b928b903405b8591f7250b66a8aa9828650/src/Formik.tsx#L1052
  const [
    // taking out onChange & onBlur because we are gonna use setValue that has shouldValidate option
    {
      value, onChange, onBlur, ...field
    },
    meta,
    { setValue },
  ] = useField<boolean | undefined>(name);
  const error = meta.touched ? meta.error : undefined;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

  const checkboxLabel = useMemo(() => (typeof label === 'string' ?
    <Box color="common.basic1100">
      <Typography variant="body2" color="inherit">
        {label}
      </Typography>
    </Box> :
    label
  ), [label]);

  return (
    <Checkbox
      {...field}
      color="primary"
      label={checkboxLabel}
      errorMessage={error}
      checked={value?.toString() === 'true'} // TODO: fix this w/ Formik 2.1.3
      {...otherProps}
      onChange={(_event, checked) => {
        setValue(checked, true);
      }}
    />
  );
};

export default memo(CheckboxField);
