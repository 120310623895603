import { Box, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Input } from 'common/components/material-ui';
import { Props as InputProps } from 'common/components/material-ui/Input';

type Props = {
  maxLength?: number;
  error?: boolean;
} & Partial<Omit<InputProps, 'ref'>>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    marginLeft: -1,
  },
  input: {
    padding: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    [theme.breakpoints.up('sm')]: {
      borderBottom: '3px solid',
      marginTop: 2,
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid',
      marginTop: 1,
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after, &:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& > input': {
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        marginBottom: -7,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: -4,
      },
    },
    '& > input::placeholder': {
      color: 'inherit',
      opacity: 1,
    },
  },
}));

const ParagraphInput = ({
  maxLength = 9,
  value,
  error = false,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();
  const color = error ? 'common.danger500' : 'common.primary500';

  return (
    <Box
      display="inline-flex"
      color={value ? color : 'common.basic300'}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>$</Box>
      <Input
        mask="money"
        variant="standard"
        value={value}
        classes={{ root: classes.root }}
        InputProps={{
          className: classes.input,
          inputProps: { maxLength, size: maxLength },
          startAdornment: null,
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default ParagraphInput;
