import {
  useRef,
  useState,
  cloneElement,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  Box,
  BoxProps,
  Popper as MaterialPopper,
  Portal,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PopperProps } from '@mui/material/Popper';

const useStyles = makeStyles(theme => ({
  clone: {
    pointerEvents: 'none',
    padding: 4,
    borderRadius: 8,
    zIndex: 300,
    backgroundColor: theme.palette.common.basic100,
  },
  overlay: {
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.75)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // the dashboard top nav and drawer have a zIndex
    // of 110 and 100 respectively, so this must be higher
    zIndex: 120,
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  // reference: https://github.com/mui-org/material-ui/blob/latest/docs/src/pages/components/popper/ScrollPlayground.js
  // TODO(onboarding): look into how to pass props into nested JSS that references another class
  popper: {
    zIndex: 260,
    '&[data-popper-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '4em 3.5em 0 3.5em',
        borderColor: `${theme.palette.common.basic100} transparent transparent transparent`,
      },
    },
    '&[data-popper-placement*="bottom"] $arrow': {
      left: 0,
      top: '-3em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 3.5em 4em 3.5em',
        borderColor: `transparent transparent ${theme.palette.common.basic100} transparent`,
      },
    },
    '&[data-popper-placement*="right"] $arrow': {
      left: '-3em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '3.5em 4em 3.5em 0',
        borderColor: `transparent ${theme.palette.common.basic100} transparent transparent`,
      },
    },
    '&[data-popper-placement*="left"] $arrow': {
      right: '-3em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderColor: `transparent transparent transparent ${theme.palette.common.basic100}`,
        borderWidth: '3.5em 0 3.5em 4em',
      },
    },
  },
}));

export type Props = {
  children?: React.ReactNode;
  anchorEl: React.ReactNode;
  containerProps?: Partial<BoxProps>;
  overlayProps?: Partial<BoxProps>;
  onBackgroundClick?: () => void;
  arrow?: boolean;
  backdrop?: boolean;
  topOffset?: number;
  leftOffset?: number;
  showAnchor?: boolean;
  exactWidth?: boolean;
} & Omit<PopperProps, 'anchorEl'>;

const Popper = ({
  children,
  containerProps,
  overlayProps,
  onBackgroundClick = () => {},
  anchorEl,
  placement = 'bottom',
  arrow = true,
  backdrop = true,
  topOffset = 0,
  leftOffset = 0,
  exactWidth = true,
  showAnchor = true,
  ...popperProps
}: Props): JSX.Element => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [dimensions, setDimensions] = useState<DOMRectReadOnly>();

  const measuredRef = useCallback((node) => {
    if (node != null) {
      setDimensions(node.getBoundingClientRect());
    }
  }, []);

  useLayoutEffect(() => {
    if (anchorRef?.current != null) {
      setDimensions(anchorRef.current.getBoundingClientRect());
    }
  }, []);

  const highlightedElement = cloneElement(anchorEl as React.ReactElement);

  return (
    <>
      <div ref={anchorRef}>
        <div
          ref={measuredRef}
          style={{
            visibility: showAnchor ? 'visible' : 'hidden',
          }}
        >
          {anchorEl}
        </div>
      </div>
      {backdrop &&
        <Portal>
          <Box
            onClick={onBackgroundClick}
            className={classes.overlay}
          />
          {dimensions &&
            <Box
              className={classes.clone}
              top={dimensions.y - topOffset}
              left={dimensions.x - leftOffset}
              minWidth={exactWidth ? dimensions.width : undefined}
              position="fixed"
              {...containerProps}
            >
              {highlightedElement}
            </Box>
          }
        </Portal>
      }
      {children &&
        <MaterialPopper
          anchorEl={anchorRef.current}
          className={classes.popper}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 48],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            },
            {
              name: 'arrow',
              options: {
              //   enabled: arrow,
                element: arrowRef,
              //   padding: ({ popper, reference }) =>
              //     popper.width / reference.width,
              },
              // TODO: these are commented out due to a TS error
              // but the file isn't currently being used
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['right', 'top', 'left', 'bottom'],
              },
            },
          ]}
          placement={placement}
          {...popperProps}
        >
          {arrow ?
            <div className={classes.arrow} ref={setArrowRef} /> :
            null
          }
          <Box {...overlayProps}>
            {children}
          </Box>
        </MaterialPopper>
      }
    </>
  );
};

export default Popper;
