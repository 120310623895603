import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { AppBar, Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from 'common/components/material-ui';
import {
  FetchHomeownerOrganizationQuery,
  FetchInvoiceOrganizationQuery,
} from 'types';
import { useUrl, useVerticalState } from 'common/hooks';

const organizationFragment = gql`
  fragment OrganizationBrand on Organization {
    id
    logoUrl
    companyName
  }
`;

const contractorFragment = gql`
  fragment ContractorUrl on Contractor {
    id
    urlPath
  }
`;

const FETCH_HOMEOWNER_ORGANIZATION = gql`
  query FetchHomeownerOrganization($homeownerId: String!) {
    homeowner(id: $homeownerId) {
      id
      organization {
        id
        ...OrganizationBrand
      }
      contractor {
        id
        ...ContractorUrl
      }
    }
  }
  ${organizationFragment}
  ${contractorFragment}
`;

const FETCH_INVOICE_ORGANIZATION = gql`
  query FetchInvoiceOrganization($invoiceId: ID!) {
    connectInvoice(id: $invoiceId) {
      id
      organization {
        id
        ...OrganizationBrand
      }
      contractor {
        id
        ...ContractorUrl
      }
    }
  }
  ${organizationFragment}
  ${contractorFragment}
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px 24px',
    height: 100,
    backgroundColor: theme.palette.common.basic100,
  },
  logo: {
    height: '100%',
    maxHeight: 80,
    marginRight: 25,
  },
}));

type Params = {
  homeownerId?: string;
  invoiceId?: string;
}

const PaymentsNav = (): JSX.Element | null => {
  const classes = useStyles();
  const { homeownerId, invoiceId } = useParams<Params>();
  const { appUrl } = useUrl();
  const [{ brandMetadata }] = useVerticalState();
  // eslint-disable-next-line max-len
  const { data: homeownerOrgData } = useQuery<FetchHomeownerOrganizationQuery>(FETCH_HOMEOWNER_ORGANIZATION, {
    skip: !!invoiceId,
    variables: {
      homeownerId,
    },
  });

  // eslint-disable-next-line max-len
  const { data: invoiceOrgData } = useQuery<FetchInvoiceOrganizationQuery>(FETCH_INVOICE_ORGANIZATION, {
    skip: !!homeownerId,
    variables: {
      invoiceId,
    },
  });

  const logoUrl = useMemo(() =>
    homeownerOrgData?.homeowner?.organization?.logoUrl ||
    invoiceOrgData?.connectInvoice?.organization?.logoUrl,
  [homeownerOrgData, invoiceOrgData]);

  const companyName = useMemo(() =>
    homeownerOrgData?.homeowner?.organization?.companyName ||
    invoiceOrgData?.connectInvoice?.organization?.companyName,
  [homeownerOrgData, invoiceOrgData]);

  const urlPath = useMemo(() =>
    homeownerOrgData?.homeowner?.contractor?.urlPath ||
    invoiceOrgData?.connectInvoice?.contractor?.urlPath,
  [homeownerOrgData, invoiceOrgData]);

  if (!companyName || !urlPath) {
    return null;
  }

  return (
    <AppBar position="sticky" className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {logoUrl &&
          <img
            src={logoUrl}
            alt="logo"
            className={classes.logo}
          />
        }
        <Typography variant="subtitle1">
          {companyName}
        </Typography>
      </Box>
      <Box
        width={260}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button
            variant="text"
            buttonType="secondary"
            component="a"
            href={appUrl(`/partners/${urlPath}`)}
            target="_blank"
          >
            Financing Options
          </Button>
        </Box>
        <Box mr={5} />
        <img
          src={brandMetadata.logoSrc}
          style={{
            height: 15,
            objectFit: 'contain',
          }}
          alt={`${brandMetadata.label} logo`}
        />
      </Box>
    </AppBar>
  );
};

export default PaymentsNav;
