import { Box, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import CopyToClipboard from 'react-copy-to-clipboard';
import cx from 'classnames';

import { useSnack } from 'common/utils/snackCart';

type Props = {
  code: string;
  description: string;
  snackText: string;
  center?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    paddingBottom: '4px',
  },
  icon: {
    fontSize: 24,
    margin: theme.spacing(1),
    color: theme.palette.common.success500,
  },
  font: {
    fontSize: '14px',
    marginBottom: '6px',
    display: 'flex',
    paddingTop: '8px',
  },
  center: {
    textAlign: 'center',
  },
}));

const CopyCodeBlock = ({
  code,
  description,
  snackText,
  center = false,
}: Props): JSX.Element => {
  const classes = useStyles(center);
  const copySnack = useSnack(snackText).successSnack;

  return (
    <>
      <Box flexDirection="row" justifyContent="space-between" className={classes.box}>
        <Typography className={classes.font}>
          {description}
        </Typography>
        <CopyToClipboard
          text={code}
          onCopy={() => {
            copySnack();
          }}
        >
          <FilterNoneIcon className={classes.icon} />
        </CopyToClipboard>
      </Box>
      <pre className={cx({ [classes.center]: center })}>
        {code}
      </pre>
    </>
  );
};

export default CopyCodeBlock;
