import { useField } from 'formik';

import { Attachment } from 'common/components/widgets';

type Props = {
  namePrefix: string;
  action?: React.ReactNode;
}

export type AttachmentFieldValues = {
  id?: string;
  localUrl: string;
  remoteUrl?: string;
  fileName: string;
}

const AttachmentField = ({
  namePrefix,
  action,
}: Props): JSX.Element => {
  const [{ value }] = useField<AttachmentFieldValues>(namePrefix);

  return (
    <Attachment
      href={value.remoteUrl || value.localUrl}
      fileName={value.fileName}
      action={action}
    />
  );
};

export default AttachmentField;
