import { ApiResponse } from 'promise-action';

import client from './client';

// @TODO(oauth): remove
export const sessionLogout = (): Promise<ApiResponse> => client.request({
  baseURL: process.env.REACT_APP_API_HOST,
  method: 'DELETE',
  url: '/contractors/sign_out',
});

export const logout = (): Promise<ApiResponse> => client.request({
  baseURL: process.env.REACT_APP_API_HOST,
  method: 'DELETE',
  url: '/oauth/logout',
});

type OauthTokenArgs = {
  email: string;
  password: string;
};

type OauthTokenPayload = ApiResponse<{
  accessToken: string;
  tokenType: 'Bearer';
  expiresIn: number;
  refreshToken: string;
  createdAt: number;
}>;

export const oauthToken = (data: OauthTokenArgs): Promise<OauthTokenPayload> =>
  client.request({
    baseURL: process.env.REACT_APP_API_HOST,
    method: 'POST',
    url: '/oauth/token',
    data: {
      email: data.email,
      password: data.password,
      grantType: 'password',
    },
  });

type OautMagicLinkArgs = {
  email: string;
  magicLinkToken: string;
  noPasswordSet?: boolean;
}

export const oauthMagicLink = (data: OautMagicLinkArgs): Promise<OauthTokenPayload> =>
  client.request({
    baseURL: process.env.REACT_APP_API_HOST,
    method: 'POST',
    url: '/oauth/token',
    data: {
      email: data.email,
      magicLinkToken: data.magicLinkToken,
      grantType: 'password',
      noPasswordSet: data.noPasswordSet,
    },
  });

type GetTokenInfoPayload = ApiResponse<{
  resourceOwnerId: number;
  scope: string;
  expiresIn: number;
  application: {
    uid: string | null;
  };
  createdAt: string;
}>;

export const getTokenInfo = (): Promise<GetTokenInfoPayload> =>
  client.request({
    baseURL: process.env.REACT_APP_API_HOST,
    method: 'POST',
    url: '/oauth/token/info',
  });

type RefreshTokenArgs = {
  token: string;
};

export const refreshToken = ({ token }: RefreshTokenArgs): Promise<OauthTokenPayload> =>
  client.request({
    baseURL: process.env.REACT_APP_API_HOST,
    method: 'POST',
    url: '/oauth/token',
    data: {
      refreshToken: token,
      grantType: 'refresh_token',
    },
  });
