import { useState } from 'react';

import {
  Box,
  Toolbar,
  AppBar,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

import { isDebug } from 'config/app';
import { isCsUserSelector } from 'common/selectors/user';
import { useLogs } from 'common/hooks/useLogEvent';
import { EVENT_TRACKING_ENABLED } from 'common/utils/session';

const Sake = (): JSX.Element => {
  const [barVisible, setBarVisible] = useState<boolean>(false);
  const [logVisible, setLogVisible] = useState<boolean>(false);
  const logs = useLogs();
  const isCsUser = useSelector(isCsUserSelector);

  const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
      minHeight: 25,
      backgroundColor: 'rgba(0,0,0,0.7)',
      opacity: barVisible ? 1 : 0,
    },
    appbar: {
      background: 'transparent',
      boxShadow: 'none',
      zIndex: theme.zIndex.snackbar,
    },
  }));

  const styleClasses = useStyles();
  const trackingEnabled = !isCsUser && EVENT_TRACKING_ENABLED;

  return (
    <>
      <AppBar
        className={styleClasses.appbar}
      >
        <Toolbar
          className={styleClasses.toolbar}
          onMouseOver={() => setBarVisible(true)}
          onMouseOut={() => setBarVisible(false)}
        >
          <Typography
            variant="body1"
            onClick={() => setLogVisible(!logVisible)}
            style={{ cursor: 'pointer' }}
          >
            {logVisible ?
              'Hide analytics log' :
              'Show analytics log'
            }
          </Typography>
          <Typography
            variant="body1"
            onClick={() => setLogVisible(!logVisible)}
            sx={{
              fontWeight: 'fontWeightSemiBold',
              ml: 2,
              color: trackingEnabled ? 'common.basic100' : 'common.danger500',
            }}
          >
            {trackingEnabled ?
              `Amplitude ON [${isDebug ? 'AdBlock has to be disabled' : ''}]` :
              'Amplitude OFF'
            }
          </Typography>
        </Toolbar>
      </AppBar>
      {logVisible &&
        <Box
          position="fixed"
          bottom={30}
          left={0}
          bgcolor="rgba(0,0,0,0.7)"
          width={600}
          zIndex={1000}
          color="common.basic100"
          padding={1}
        >
          <Box position="absolute" right={4} mt={-0.7}>
            <IconButton size="small" onClick={() => setLogVisible(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
          <Box overflow="auto" height={200}>
            {logs.map(log => (
              <Box fontSize="body2" display="flex" flexDirection="row">
                <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
                  {log.timestamp}
                </Typography>
                <Box pl={2} flex={1}>
                  <Typography variant="body1">
                    {log.eventName}<br />
                    {Object.keys(log.properties || {}).length > 0 &&
                      <pre>{JSON.stringify(log.properties, null, 2)}</pre>
                    }
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      }
    </>
  );
};

export default Sake;
