import { useCallback, useMemo } from 'react';
import { useDebounce } from 'react-use';
import {
  Box,
  Typography,
  Theme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { object, string } from 'yup';
import HelpIcon from '@mui/icons-material/Help';
import { useField } from 'formik';
import { TNode } from '@udecode/plate-core';
import _ from 'lodash';

import { Button, Tooltip } from 'common/components/material-ui';
import { useLogEvent } from 'common/hooks';
import { Form, SubmitButton } from 'common/components/formik';
import { SlateEditor } from 'common/components/elements';
import { slateToMd, mdToSlate } from 'common/components/elements/SlateEditor/serializers';
import { ContractTemplateActionType, getTosMarkdown } from 'contractor/hooks/useEditContractTemplateReducer';

import {
  useOrganization,
  useWizard,
  useContractWizardActions,
  useContractTemplate,
} from '../useContractWizard';
import { WizardHeader, WizardContent } from '../components';

type Values = {
  tosSlateValue?: TNode[];
}

type TosEditorProps = {
  suggestedTosSlateValue?: TNode[];
}

const useEditorStyles = makeStyles((theme: Theme) => ({
  outlined: {
    color: theme.palette.common.basic1100,
    backgroundColor: 'transparent',
    borderColor: theme.palette.common.basic1100,
    '&:hover': {
      borderColor: theme.palette.common.basic1100,
    },
  },
  smallButton: {
    fontSize: 12,
    padding: '5px 10px',
    borderRadius: 10,
  },
  slateEditor: {
    maxHeight: 350,
    [theme.breakpoints.up('md')]: {
      maxHeight: 500,
    },
  },
  buttonBox: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  buttonBox: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
}));

const TosEditor = ({ suggestedTosSlateValue }: TosEditorProps): JSX.Element => {
  const classes = useEditorStyles();
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [tosSlateValueField] = useField<TNode[] | null>('tosSlateValue');
  const organization = useOrganization();
  const itly = useLogEvent();

  useDebounce(
    () => {
      itly.editContractTerms();
    },
    5000,
    [tosSlateValueField.value],
  );

  const onClear = useCallback(() =>
    tosSlateValueField.onChange({
      target: {
        name: 'tosSlateValue',
        value: [{ type: 'p', children: [{ text: '' }] }],
      },
    }), [tosSlateValueField]);

  const onRevert = useCallback(() =>
    tosSlateValueField.onChange({
      target: {
        name: 'tosSlateValue',
        value: suggestedTosSlateValue,
      },
    }), [tosSlateValueField, suggestedTosSlateValue]);

  const onChange = useCallback((value: TNode[]) => {
    tosSlateValueField.onChange({
      target: {
        name: 'tosSlateValue',
        value,
      },
    });
  }, [tosSlateValueField]);

  return (
    <Box my={4}>
      <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
        <Box mb={2}>
          <Typography variant={isMobileOrSmall ? 'subtitle1' : 'h5'}>
            {organization?.companyName} Terms of Use:
          </Typography>
        </Box>
        <Box mb={2} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={onRevert}
            className={classes.outlined}
            disabled={_.isEqual(tosSlateValueField.value, suggestedTosSlateValue)}
            classes={{ root: classes.smallButton }}
          >
            Revert to original
          </Button>
          <Box m={0.5} />
          <Button
            variant="outlined"
            onClick={onClear}
            className={classes.outlined}
            classes={{ root: classes.smallButton }}
          >
            Clear text
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">
            {organization?.companyName} Terms of Use:
          </Typography>
          <Box display="flex" flexDirection="row">
            <Button
              variant="outlined"
              onClick={onClear}
              className={classes.outlined}
            >
              Clear text
            </Button>
            <Box m={1} />
            <Button
              variant="outlined"
              onClick={onRevert}
              className={classes.outlined}
              disabled={_.isEqual(tosSlateValueField.value, suggestedTosSlateValue)}
            >
              Revert to original
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mx={{ xs: -2, md: 0 }}>
        <SlateEditor
          value={tosSlateValueField.value || []}
          onChange={onChange}
          containerProps={{
            className: classes.slateEditor,
          }}
        />
      </Box>
    </Box>
  );
};

const TosStep = (): JSX.Element => {
  const classes = useStyles();
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { stepIndex, prevStep } = useWizard();
  const { template, dispatch } = useContractTemplate();
  const { upsertCurrentTemplate } = useContractWizardActions();

  const initialTosMarkdown = useMemo(() => {
    if (!template) return '';
    return getTosMarkdown(template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialTosSlateValue = useMemo(() => (
    initialTosMarkdown ? mdToSlate(initialTosMarkdown) : undefined
  ), [initialTosMarkdown]);

  return (
    <>
      <WizardHeader title={`Step ${stepIndex}: Terms of use details`} stepIndex={stepIndex} />
      <WizardContent>
        <Form<Values>
          initialValues={{
            tosSlateValue: initialTosSlateValue,
          }}
          validationSchema={object().shape({
            useSuggestedTos: string().label('Suggested terms of use'),
          })}
          onChange={(values) => {
            dispatch({
              type: ContractTemplateActionType.UPDATE_TOS,
              payload: {
                body: values.tosSlateValue ?
                  slateToMd(values.tosSlateValue) :
                  initialTosMarkdown,
              },
            });
          }}
          onSubmit={upsertCurrentTemplate}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box>
              <Typography variant={isMobileOrSmall ? 'h3' : 'h2'}>
                Edit suggested terms of use or add your own.
              </Typography>
              <Box mt={2} />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Typography variant="caption">
                  <Box fontWeight="fontWeightBold">
                    Any changes you make to the terms of use can be edited later.
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Tooltip
              title="We will add the terms text saved here to the Terms & Conditions section of your
              contract template. You can use our suggested terms or if you already have terms that
              you use, enter them here."
              disableHoverListener={false}
              placement="bottom"
              clone
              bgColor="purple500"
            >
              <Box
                my={1}
                color="common.basic900"
                display="flex"
                alignItems="center"
                style={{ cursor: 'default' }}
              >
                <HelpIcon />
                <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                  Why are you asking me this?
                </Typography>
              </Box>
            </Tooltip>
            <Divider />
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <Box mt={2} mb={3} mx={-4}>
              <Divider />
            </Box>
            <Typography variant="caption">
              <Box fontWeight="fontWeightBold">
                Any changes you make to the terms of use can be edited later.
              </Box>
            </Typography>
          </Box>
          <TosEditor suggestedTosSlateValue={initialTosSlateValue} />
          <Box mt={3} />
          <Box mt={4} className={classes.buttonBox}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant="text"
                buttonType="common.purple"
                onClick={prevStep}
                rounded
              >
                Back
              </Button>
            </Box>
            <SubmitButton
              variant="contained"
              buttonType="common.purple"
              rounded
              size="large"
              disableOnInitialValues={false}
              fullWidth={isMobileOrSmall}
            >
              Finish: Preview your template
            </SubmitButton>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <Button
                variant="text"
                buttonType="common.purple"
                onClick={prevStep}
                rounded
                fullWidth
                className={classes.backButton}
              >
                Back
              </Button>
            </Box>
          </Box>
        </Form>
      </WizardContent>
    </>
  );
};

export default TosStep;
