import { Mutex } from 'async-mutex';

import * as authApi from 'common/api/authApi';
import * as localStorage from 'common/services/localStorage';
import store from 'stores';
import { logoutDispatcher } from 'contractor/actions/auth';
import graphqlClient from 'common/graphql/apolloClient';

type PromiseArgs = {
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}

/**
 * The request mutex is a generic object that assists both the Axios & Graphql
 * client in refreshing the access token when the original token expires.
 *
 * It uses a mutex to ensure that only one refresh api call is being made to the
 * backend, and puts all other concurrent requests into the unresolvedCallbacks
 * array. Once the token is refreshed, all callbacks are then resolved.
 */
class RequestMutex {
  public mutex: Mutex;
  private unresolvedCallbacks: PromiseArgs[];

  constructor() {
    this.mutex = new Mutex();
    this.unresolvedCallbacks = [];
  }

  private processQueue(error?: Error) {
    this.unresolvedCallbacks.forEach((callback) => {
      if (error) {
        callback.reject();
      } else {
        callback.resolve();
      }
    });
  }

  public pushUnresolvedRequest(callback: PromiseArgs) {
    this.unresolvedCallbacks.push(callback);
  }

  public refreshAccessToken(): Promise<string | void> {
    return this.mutex.acquire().then((release) => {
      if (!localStorage.hasStorage()) {
        throw new Error('No local storage in browser');
      }

      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        throw new Error('Refresh token is not present');
      }

      return authApi.refreshToken({ token: refreshToken })
        .then(({ data }) => {
          localStorage.setItem('accessToken', data.accessToken);
          localStorage.setItem('refreshToken', data.refreshToken);

          this.processQueue();

          return data.accessToken;
        })
        .catch((error: Error) => {
          /**
           * @TODO: Remove this type cast asap. The current reducer
           * system does not how to correctly type redux thunk action types.
           * Therefore the types do not align with the actual types and the
           * logoutDispatcher.
           *
           * This command will likely be fully removed once we go 100% on oauth.
           *
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          store.dispatch(logoutDispatcher({}) as any);

          throw error;
        })
        .finally(() => {
          release();
        });
    }).catch((error: Error) => {
      this.processQueue(error);
      graphqlClient.cache.reset();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    });
  }
}

export default RequestMutex;
