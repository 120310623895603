import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Theme,
  List,
  ListItem,
  Box,
  Chip,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { darken } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import cx from 'classnames';
import mapValues from 'lodash/mapValues';

import useLogEvent from 'common/hooks/useLogEvent';
import { Link } from 'common/components/material-ui';
import { Smidge } from 'common/components/layouts';
import { WebsiteContent } from 'contractor/types/verticals';
import { organizationFeatureFlagSelectors } from 'contractor/selectors/organization';

import BannerSelection from './BannerSelection';
import ZeroPercentBannerSelection from './ZeroPercentBannerSelection';
import CalculatorWidgetSelection from './CalculatorWidgetSelection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '20px 40px 20px',
      borderRadius: '4px 4px 0 0',
      width: '100%',
    },
    heading: {
      fontWeight: 500,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    list: {
      margin: 20,
      listStyle: 'disc',
      padding: 0,
    },
    numberList: {
      listStyle: 'decimal',
    },
    listItem: {
      display: 'list-item',
      padding: 0,
      color: theme.palette.common.basic900,
    },
    expansionSummary: {
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingLeft: '40px',
      '&:hover': {
        backgroundColor: theme.palette.common.primary100,
      },
    },
    AccordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '40px',
    },
    colorSelector: {
      width: '28px',
      height: '28px',
      display: 'flex',
      color: theme.palette.common.warning500,
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: `1px solid ${theme.palette.common.basic1100}`,
    },
    noRightBorder: {
      borderRight: 'none',
    },
    colorRow: {
      marginLeft: '8px',
      border: `1px solid ${theme.palette.common.basic1100}`,
      borderRadius: '2px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    newChip: {
      marginLeft: '20px',
    },
  }),
);

const backgroundColors: Record<string, Color> = {
  darkblue: 'primary900',
  blue: 'primary500',
  grey: 'basic500',
  white: 'basic100',
  red: 'danger500',
  navy: 'basic1100',
};

const useColorStyles = makeStyles(theme => createStyles(
  mapValues(backgroundColors, (color: Color) => ({
    backgroundColor: theme.palette.common[color],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: darken(theme.palette.common[color], 0.15),
    },
  })),
));

const Chevron = (): JSX.Element => (
  <ExpandMoreIcon
    style={{
      width: 24,
      height: 24,
    }}
  />
);

const bannerInstallHelpLinks = {
  wix: 'https://support.wix.com/en/article/adding-html-code',
  godaddy: 'https://www.godaddy.com/help/add-html-or-custom-code-to-my-site-27252',
  squarespace: 'https://support.squarespace.com/hc/en-us/articles/206543167',
  wordpress: 'https://en.support.wordpress.com/widgets/',
};

type Props = {
  handleExpand: (index: number) => void;
  expandedPanel: number;
  content: WebsiteContent;
}

const WebsiteBannersNote = ({
  handleExpand,
  expandedPanel,
  content,
}: Props): JSX.Element => {
  const classes = useStyles();
  const colorClasses = useColorStyles();
  const zeroPercentCreditCards = useSelector(
    organizationFeatureFlagSelectors.zeroPercentCardsOffersPage);
  const itly = useLogEvent();

  const {
    banners: {
      bannerHelp,
      bannerSelection,
      colorOptions,
    },
    showFinancingContent,
  } = content;

  const [color, setColor] = useState(colorOptions[0]);

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="subtitle2">
          For your website
        </Typography>
        <Typography variant="h3" style={{ fontSize: '20px', lineHeight: '27px' }}>
          Website banners
        </Typography>
        <Smidge />
        <Typography style={{ fontSize: '14px', lineHeight: '19px' }}>
          Install banners to allow customers to easily request
          financing directly from your website.
        </Typography>
      </Paper>
      <Accordion expanded={expandedPanel === 0}>
        <AccordionSummary
          expandIcon={<Chevron />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.expansionSummary}
          onClick={() => {
            if (expandedPanel !== 0) {
              itly.openBannerDesign();
            }
            handleExpand(0);
          }}
        >
          <Typography variant="body1" className={classes.heading}>
            Choose banners that best match your website design and colors
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Box className={classes.row} flexDirection="row">
            <Typography variant="body1">
              Choose banner color:
            </Typography>
            <div className={cx(classes.colorRow, classes.row)}>
              {colorOptions.map((colorItem, index) => (
                <Box
                  key={colorItem}
                  className={cx(classes.colorSelector, colorClasses[colorItem], {
                    [classes.noRightBorder]: index === colorOptions.length - 1,
                  })}
                  onClick={() => setColor(colorItem)}
                >
                  {color === colorItem &&
                    <CheckIcon />
                  }
                </Box>
              ))}
            </div>
          </Box>
          <Smidge x={2} />
          <BannerSelection
            content={bannerSelection}
            color={color}
            handleExpand={handleExpand}
          />
        </AccordionDetails>
      </Accordion>
      {zeroPercentCreditCards &&
        <Accordion expanded={expandedPanel === 1}>
          <AccordionSummary
            expandIcon={<Chevron />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            className={classes.expansionSummary}
            onClick={() => handleExpand(1)}
          >
            <Typography variant="body1" className={classes.heading}>
              0% credit card banners
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <ZeroPercentBannerSelection onExpand={handleExpand} />
          </AccordionDetails>
        </Accordion>
      }
      <Accordion expanded={expandedPanel === 2}>
        <AccordionSummary
          expandIcon={<Chevron />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className={classes.expansionSummary}
          onClick={() => {
            if (expandedPanel !== 2) {
              itly.openWebsitePaymentCalculator();
            }
            handleExpand(2);
          }}
        >
          <Typography variant="body1" className={classes.heading}>
            Payment calculator for your website
            <Chip color="primary" label="NEW!" className={classes.newChip} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Box className={classes.row} flexDirection="row">
            <Typography variant="body1">
              Enable customers who visit your website to calculate payments for their
              project and click through to request financing options.
            </Typography>
          </Box>
          <Smidge x={2} />
          <CalculatorWidgetSelection onExpand={handleExpand} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedPanel === 3}>
        <AccordionSummary
          expandIcon={<Chevron />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          className={classes.expansionSummary}
          onClick={() => {
            if (expandedPanel !== 3) {
              itly.openBannerInstallationHelp();
            }
            handleExpand(3);
          }}
        >
          <Typography variant="body1" className={classes.heading}>Banner installation help</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Typography>{bannerHelp.descriptionHeader}</Typography>
          <List component="ol" className={cx(classes.list, classes.numberList)}>
            <ListItem className={classes.listItem}>
              <Typography>
                Select a banner size that fits your site layout.
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography>
                Choose the color that compliments your site.
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography>
                Consider adding financing content to accompany your banner (see section below)
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography>
                Copy the HTML code to add to your site or email the banner and instructions
                to your web admin.
              </Typography>
            </ListItem>
          </List>
          <Typography>
            For specific details for adding the banner, select your website host:
          </Typography>
          <List>
            <ListItem>
              <Typography>
                Wix:{' '}
                <Link
                  href={bannerInstallHelpLinks.wix}
                  onClick={() => itly
                    .viewBannerInstallationWebsiteLink({
                      linkName: bannerInstallHelpLinks.wix,
                    })}
                >
                  {bannerInstallHelpLinks.wix}
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                GoDaddy:{' '}
                <Link
                  href={bannerInstallHelpLinks.godaddy}
                  onClick={() => itly
                    .viewBannerInstallationWebsiteLink({
                      linkName: bannerInstallHelpLinks.godaddy,
                    })}
                >
                  {bannerInstallHelpLinks.godaddy}
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Squarespace:{' '}
                <Link
                  href={bannerInstallHelpLinks.squarespace}
                  onClick={() => itly
                    .viewBannerInstallationWebsiteLink({
                      linkName: bannerInstallHelpLinks.squarespace,
                    })}
                >
                  {bannerInstallHelpLinks.squarespace}
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                Wordpress:{' '}
                <Link
                  href={bannerInstallHelpLinks.wordpress}
                  onClick={() => itly
                    .viewBannerInstallationWebsiteLink({
                      linkName: bannerInstallHelpLinks.wordpress,
                    })}
                >
                  {bannerInstallHelpLinks.wordpress}
                </Link>
              </Typography>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      {showFinancingContent &&
        <Accordion expanded={expandedPanel === 4}>
          <AccordionSummary
            expandIcon={<Chevron />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            className={classes.expansionSummary}
            onClick={() => {
              if (expandedPanel !== 4) {
                itly.openContentForYourFinancingPage();
              }
              handleExpand(4);
            }}
          >
            <Typography variant="body1" className={classes.heading}>
              Content for your financing page
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <Typography>
              A short description about payment options alongside your banner can help seal
              the deal. We’ve included examples below that you can copy and paste onto
              your website:
            </Typography>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography>
                  See your personalized monthly payment options within minutes and without
                  affecting your credit score. No prepayment penalties. No home equity required.
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem} style={{ flexDirection: 'column' }}>
                <Typography>
                  Hearth makes it easy for you to find monthly payment options for your
                  project, with:
                </Typography>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <Typography>Loan amounts up to $100,000</Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Typography>Affordable monthly payment options</Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Typography>Funding within 1-3 days</Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Typography>No prepayment penalties</Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Typography>No home equity required</Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography>
                  Find convenient monthly payment options for your project.
                  See your personalized payment plans without affecting your credit score now.
                </Typography>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export default WebsiteBannersNote;
