import { SvgIconProps } from '../Icon';

const InvoiceIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path
      d="M56 2H8V60L16 55L24 60L32 55L40 60L48 55L56 60V2Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 44H34"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M42 44H46"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 36H34"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M42 36H46"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 28C36.4183 28 40 24.4183 40 20C40 15.5817 36.4183 12 32 12C27.5817 12 24 15.5817 24 20C24 24.4183 27.5817 28 32 28Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default InvoiceIcon;
