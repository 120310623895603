import { useState } from 'react';

import { Typography } from 'common/components/material-ui';
import CustomizeBrandModalContainer from 'contractor/modules/platform/CustomizeBrandModal/CustomizeBrandModalContainer';
import { GetStartedOrganizationFragment } from 'types';

import { GetStartedListItem, GetStartedButton } from '..';
import { Task } from '../../useGetStarted';

type Props = {
  organization: GetStartedOrganizationFragment;
};

const CustomizeBrandTask = ({ organization }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const { firstUploadedLogoAt } = organization;

  return (
    <>
      <CustomizeBrandModalContainer
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <GetStartedListItem
        taskCompleted={Boolean(firstUploadedLogoAt)}
        taskName={Task.CUSTOMIZE_BRAND}
      >
        <Typography variant="p2">
          Upload your logo for customer-facing documents.
        </Typography>
        <GetStartedButton
          sx={{ mt: 2, py: 1 }}
          onClick={() => setModalOpen(true)}
          data-testId="customize-brand-button"
        >
          Get Started
        </GetStartedButton>
      </GetStartedListItem>
    </>
  );
};

export default CustomizeBrandTask;
