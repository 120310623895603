import { Accordion as MuiAccordion, AccordionProps as MuiAccordionProps } from '@mui/material';

import { styled } from '@mui/material/styles';

export type AccordionProps = MuiAccordionProps;

const BaseAccordion = styled(MuiAccordion, {
  name: 'BaseAccordion',
})(({ theme }) => ({
  backgroundColor: theme.palette.common.basic100,
}));

const Accordion = ({
  children,
  ...props
}: AccordionProps): JSX.Element => (
  <BaseAccordion disableGutters {...props}>
    {children}
  </BaseAccordion>
);

export default Accordion;
