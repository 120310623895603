import {
  Box,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: theme.palette.common.basic100,
    border: '3px solid',
    height: 24,
    width: 24,
    borderRadius: 12,
    [theme.breakpoints.up('md')]: {
      height: 28,
      width: 28,
      borderRadius: 14,
    },
  },
}));

type Props = {
  number: number;
  total: number;
}

const WizardStepper = ({ number, total }: Props): JSX.Element => {
  const classes = useStyles();
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1.5}>
        <Typography variant={isMobileOrSmall ? 'subtitle1' : 'h5'}>
          Step:
        </Typography>
      </Box>
      {Array.from({ length: total }, (_, index) => {
        const currentNumber = index + 1;
        const selected = currentNumber === number;
        const completed = currentNumber < number;

        return (
          <Box
            key={index}
            color={selected ? 'common.purple500' : 'common.basic100'}
            bgcolor={selected ? 'common.basic100' : 'common.purple500'}
            className={classes.root}
            ml={(index > 0) ? 1.5 : 0}
            style={{
              opacity: completed ? 0.4 : 1,
            }}
          >
            {completed ?
              <CheckIcon fontSize={isMobileOrSmall ? 'small' : 'medium'} /> :
              <Typography variant={isMobileOrSmall ? 'body2' : 'subtitle2'}>
                {currentNumber}
              </Typography>
            }
          </Box>
        );
      })}
    </Box>
  );
};

export default WizardStepper;
