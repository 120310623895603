import { SvgIconProps } from '../Icon';

const DocumentIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 44 44" fill="none" {...otherProps}>
    <path
      d="M5 43.177c-1.06 0-2.078-.42-2.828-1.166A3.967 3.967 0 011 39.2V23.287h3M9 1.409v37.79a3.967 3.967 0 01-1.172 2.813A4.011 4.011 0 015 43.176h34c1.06 0 2.078-.42 2.828-1.166A3.967 3.967 0 0043 39.2V1.409H9zM16 25.276h20M16 33.232h20"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 9.364H16v7.956h20V9.364z"
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
