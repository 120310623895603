import * as React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

type Props = {
  children: React.ReactNode;
  component?: React.ReactNode;
  to?: RouterLinkProps['to'];
} & LinkProps;

const Link = ({
  children,
  onClick,
  ...otherProps
}: Props): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <MuiLink
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
