import { Suspense } from 'react';
import {
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { parse } from 'query-string';

import { LoginContainer } from 'contractor/components/containers';
import { Loader } from 'common/components/widgets';
import { useUrl } from 'common/hooks';
import App from 'App';
import AuthenticatedRoute from 'common/components/routes/AuthenticatedRoute';
import AutologinPage from 'contractor/pages/AutologinPage';
import OauthAutologinPage from 'contractor/pages/OauthAutologinPage';
import ForgotPasswordPage from 'contractor/pages/ForgotPasswordPage';
import LoginPage from 'contractor/pages/LoginPage';
import NotFoundPage from 'main/pages/NotFoundPage';
import lazyRetry from 'common/utils/lazyRetry';

const ContractorMapPage = lazyRetry(() => import('contractor/pages/ContractorMapPage'));
const DashboardRoute = lazyRetry(() => import('./DashboardRoute'));
const HomeownerFlowPage = lazyRetry(() => import('contractor/pages/HomeownerFlowPage'));
const LoanRoute = lazyRetry(() => import('./LoanRoute'));
const PresalesCoursePage = lazyRetry(() => import('contractor/pages/PresalesCoursePage'));
const QuickbooksSetupPage = lazyRetry(() => import('contractor/pages/QuickbooksSetupPage'));
const OnboardingPage = lazyRetry(() => import('contractor/pages/OnboardingPage'));
const SignupPage = lazyRetry(() => import('contractor/pages/SignupPage'));
const HomeownerInvoice = lazyRetry(() => import('contractor/pages/HomeownerInvoicePage'));
const HomeownerQuoteViewPage = lazyRetry(() => import('contractor/pages/HomeownerQuoteViewPage'));
const HomeownerContractPage = lazyRetry(() => import('contractor/pages/HomeownerContractPage'));
const HomeownerFeedback = lazyRetry(() => import('contractor/pages/HomeownerFeedbackPage'));
const HomeownerInvoices = lazyRetry(() => import('contractor/pages/HomeownerInvoicesPage'));
const ChangePasswordPage = lazyRetry(() => import('contractor/pages/ChangePasswordPage'));
const InvoicePreviewPage = lazyRetry(() => import('contractor/pages/InvoicePreviewPage'));
const CreditCardFlowPage = lazyRetry(() => import('main/pages/CreditCardFlowPage'));
const PlaidHomeownerOAuthRedirectPage = lazyRetry(() => import('main/pages/PlaidHomeownerOAuthRedirectPage'));

export default (
  <Route
    path="/"
    render={props => (
      <App {...props}>
        <Suspense fallback={<Loader isOverlay />}>
          <Switch>
            <Route path="/financing/:organizationId/:contractorId?/prequalify" component={HomeownerFlowPage} />
            <Route path="/financing/:organizationId/:contractorId?/estimate" component={HomeownerFlowPage} />
            <Route path="/financing/:organizationId/:contractorId?/learn" component={HomeownerFlowPage} />
            <Route path="/financing/:organizationId/:contractorId?" component={HomeownerFlowPage} />
            <Redirect
              from="/contractor-financing/:organizationId/:contractorId?"
              to="/financing/:organizationId/:contractorId?"
            />
            <Redirect exact path="/" to="/contractors/login" />
            <Route exact path="/credit-cards/apply" component={CreditCardFlowPage} />
            <Route path="/loans">
              <LoanRoute />
            </Route>

            {/* Contractors */}

            <Redirect exact from="/contractors/password" to="/password" />
            <Redirect exact from="/contractors/dashboard" to="/dashboard" />
            <Route exact path="/contractors/autologin" component={AutologinPage} />
            {/* Need this so it doesn't deep link back into the app */}
            <Route exact path="/contractors/web-autologin" component={AutologinPage} />
            <Route exact path="/oauth" component={OauthAutologinPage} />
            <Redirect exact from="/contractors/edit_password" to="/edit_password" />
            {/* ~Todo: remove once the gamification badges are deleted from the mobile app */}
            <Redirect exact from="/contractors/dashboard/banner-selection" to="/dashboard/tools" />
            <Redirect exact from="/contractors/login" to="/login" />
            <Route
              path={['/login', '/edit_password', '/password']}
              render={() => (
                <LoginContainer>
                  <Switch>
                    <AuthenticatedRoute exact path="/login" component={LoginPage} />
                    <Route exact path="/password" component={ForgotPasswordPage} />
                    <Route exact path="/edit_password" component={ChangePasswordPage} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </LoginContainer>
              )}
            />
            <AuthenticatedRoute
              exact
              path="/seats/signup"
              component={SignupPage}
            />
            <Redirect exact path="/dashboard/settings" to="/dashboard/settings/notifications" />
            <Redirect exact path="/dashboard/reporting" to="/dashboard/reporting/financing" />
            <Redirect exact path="/dashboard/reviews" to="/dashboard/reviews/financing-feedback" />
            <Redirect exact path="/dashboard/tools" to="/dashboard/tools/marketing" />
            <AuthenticatedRoute
              path="/dashboard"
              render={innerProps => (
                <DashboardRoute {...innerProps} />
              )}
            />
            <AuthenticatedRoute
              exact
              path="/setup"
              component={OnboardingPage}
            />
            <AuthenticatedRoute
              exact
              path="/quickbooks/setup"
              component={QuickbooksSetupPage}
            />
            <AuthenticatedRoute
              exact
              path="/invoice-preview"
              component={InvoicePreviewPage}
            />
            <Route
              path="/contractors/signup"
              component={({ location }: RouteProps) => {
                const { marketingUrl, signupHost } = useUrl();
                const query = location ? parse(location.search) : undefined;
                window.location.href = query?.organization_id ?
                  marketingUrl('/seats/activate', query) :
                  signupHost;
                return null;
              }}
            />
            <Route exact path="/map" component={ContractorMapPage} />
            <Route exact path="/hearth-course" component={PresalesCoursePage} />
            <Route exact path="/homeowner-invoices/:homeownerId/:orgId?" component={HomeownerInvoices} />
            <Route exact path="/invoices/:invoiceId/:orgId?" component={HomeownerInvoice} />
            <Route exact path="/contracts/:contractServiceSignatureId" component={HomeownerContractPage} />
            <Route exact path="/homeowner-feedback/:formId" component={HomeownerFeedback} />
            <Route exact path="/plaid-homeowner-oauth" component={PlaidHomeownerOAuthRedirectPage} />
            <Route exact path="/quotes/:quoteId" component={HomeownerQuoteViewPage} />
            <Route exact path="/404" component={NotFoundPage} />

            { /* Catch all route to render 404 */}
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </App>
    )}
  />
);
