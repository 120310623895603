import { Component } from 'react';
import PropTypes from 'prop-types';

import constants from 'common/utils/constants';

import './Loader.scss';

export default class Loader extends Component {
  static propTypes = {
    withText: PropTypes.bool,
    isOverlay: PropTypes.bool,
    customText: PropTypes.string,
    loadingBlocks: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    withText: false,
    isOverlay: false,
    customText: '',
    loadingBlocks: [],
  };

  state = {
    textIndex: 0,
    textBlocks: constants.loadingBlocks,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadingBlocks } = this.props;
    this.setState({
      textBlocks: loadingBlocks.length > 0 ? loadingBlocks : constants.loadingBlocks,
    });
  }

  componentDidMount() {
    const { withText } = this.props;
    if (withText) {
      this.interval = setInterval(() => {
        this.setState({
          textIndex: (this.state.textIndex + 1) % this.state.textBlocks.length,
        });
      }, 3000);
    }
  }

  componentWillUnmount() {
    if (this.props.withText) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { textIndex, textBlocks } = this.state;
    const text = textBlocks[textIndex];
    const { isOverlay, withText, customText } = this.props;

    return (
      <div className={`loader-container ${isOverlay ? 'translucent' : ''}`}>
        <div className="loader-three-dots" />
        <div className="loading-text">{withText && text}{!withText && customText}</div>
      </div>
    );
  }
}
