import { styled } from '@mui/material/styles';

const Preview = styled('img', {
  name: 'Preview',
})(({ theme }) => ({
  backgroundColor: theme.palette.common.basic100,
  margin: 1,
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  zIndex: 2,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

type Props = {
  imageUrl: string;
  imageAlt: string;
}

const LogoPreview = ({ imageUrl, imageAlt, ...otherProps }: Props): JSX.Element => (
  <Preview
    alt={imageAlt}
    src={imageUrl}
    {...otherProps}
  />
);

export default LogoPreview;
