import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { NavDrawerSecondaryItem } from 'common/components/material-ui';
import { WalkthroughStep } from 'contractor/modules/OnboardingWalkthrough/useOnboardingWalkthrough';
import WalkthroughStepWrapper from 'contractor/modules/OnboardingWalkthrough';

import { useDashboardNavigation, NavigationOption } from '../useDashboardNavigation';

const SecondaryLinks = (): JSX.Element => {
  const {
    contractor,
    uiStates: {
      activeTab,
      changeAccountModalOpen,
      setChangeAccountModalOpen,
      affiliateModalOpen,
      setAffiliateModalOpen,
    },
  } = useDashboardNavigation();
  const history = useHistory();

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start">
      <WalkthroughStepWrapper
        placement="right"
        step={WalkthroughStep.HELP}
        id={`walkthrough-step-${WalkthroughStep.HELP}`}
      >
        <NavDrawerSecondaryItem
          title="Help / Learn"
          color="basic900"
          onClick={() => history.push('/dashboard/learn')}
          active={activeTab === NavigationOption.HELP}
        />
      </WalkthroughStepWrapper>
      <NavDrawerSecondaryItem
        title="Refer a Friend"
        color="basic900"
        onClick={() => setAffiliateModalOpen(true)}
        active={affiliateModalOpen}
      />
      {contractor?.enterpriseAdmin &&
        <NavDrawerSecondaryItem
          title="Change Accounts"
          color="basic900"
          onClick={() => setChangeAccountModalOpen(true)}
          active={changeAccountModalOpen}
        />
      }
    </Box>
  );
};

export default SecondaryLinks;
