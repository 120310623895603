import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ButtonBase, { ButtonBaseProps } from 'common/components/material-ui/ButtonBase';
import FeatureStatusPill from 'contractor/components/widgets/FeatureStatusPill';

const useStyles = makeStyles(theme => ({
  buttonbase: {
    display: 'inline-block',
    width: '100%',
    minHeight: 40,
    paddingLeft: 40,
    borderRadius: 8,
    '&:hover': {
      background: theme.palette.common.basic300,
      bgRadius: '8px',
    },
  },
  active: {
    fontWeight: 700,
  },
}));

type Props = {
  title: React.ReactNode;
  color: Color;
  onClick?: () => void;
  active: boolean;
  featureStatus?: string;
} & Partial<ButtonBaseProps>;

const NavDrawerSecondaryItem = ({
  title,
  color,
  onClick,
  active,
  featureStatus,
  ...otherProps
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.buttonbase}
      onClick={onClick}
      {...otherProps}
    >
      {active ?
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            ml={-2}
          >
            <Box
              width={8}
              height={8}
              borderRadius="2px"
              mr={1}
              bgcolor="common.primary500"
            />
            <Box color="common.primary500" display="flex" alignItems="center">
              <Typography variant={active ? 'subtitle2' : 'body2'} color="inherit" align="left">
                {title}
              </Typography>
              {featureStatus &&
                <Box ml={1}>
                  <FeatureStatusPill
                    variant="caption"
                    title={featureStatus}
                    fontStyleProps={{ fontSize: 10 }}
                  />
                </Box>
              }
            </Box>
          </Box>
          <Box
            width={4}
            height={38}
            borderRadius="2px"
            bgcolor="common.primary500"
          />
        </Box> :
        <Box color={`common.${color}`} display="flex" alignItems="center">
          <Typography variant={active ? 'subtitle2' : 'body2'} color="inherit" align="left">
            {title}
          </Typography>
          {featureStatus &&
            <Box ml={1}>
              <FeatureStatusPill
                variant="caption"
                title={featureStatus}
                fontStyleProps={{ fontSize: 10 }}
              />
            </Box>
          }
        </Box>
      }
    </ButtonBase>
  );
};

export default NavDrawerSecondaryItem;
