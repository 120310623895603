import { SvgIconProps } from '../Icon';

const TransactionIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 22" fill="none" {...otherProps}>
    <path
      d="M4.125 6.5a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zM10.5 14.75C6.531 13.016 6.75 9.5 3.75 9.5a3.106 3.106 0 00-3 3v8.25h6V15.5M13.5 14.75c3.969-1.734 3.75-5.25 6.75-5.25a3.106 3.106 0 013 3v8.25h-6V15.5M19.875 6.5a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TransactionIcon;
