import { SvgIconProps } from '../Icon';

const DownloadIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 44 44" fill="none" {...otherProps}>
    <path
      d="M42 28.667v8.889A4.445 4.445 0 0137.556 42H6.444A4.445 4.445 0 012 37.556v-8.89M10.89 17.555L22 28.666l11.111-11.11M22 28.667V2"
      stroke={color}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadIcon;
