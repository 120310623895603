import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './BaseContract.scss';

class BaseContract extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    size: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  getCopy() {
    // Get contract inner html and react comment annotations
    return this._innerContractElement.innerHTML.replace(/(<!--.*?-->)/g, '');
  }

  contractRef = (element) => {
    this._innerContractElement = element;
  };

  render() {
    const { className, children, size } = this.props;

    return (
      <div
        className={
          cx('base-contract', className, {
            'base-contract--small': size === 'small',
            'base-contract--medium': size === 'medium',
            'base-contract--large': size === 'large',
          })
        }
        ref={this.contractRef}
      >
        <div className="contract-content">
          {children}
        </div>
      </div>
    );
  }
}

export default BaseContract;
