import { Helmet } from 'react-helmet';

import Login from 'contractor/modules/Login';
import { useVerticalState } from 'common/hooks';

const LoginPage = (): JSX.Element => {
  const [{ brandMetadata }] = useVerticalState();

  return (
    <div>
      <Helmet>
        <title>Login | {brandMetadata.label}</title>
      </Helmet>
      <Login />
    </div>
  );
};

export default LoginPage;
