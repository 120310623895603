import {
  Box,
  Divider,
  Typography,
  TypographyProps,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { Background } from 'types';
import { currencyFormatFromCents } from 'common/utils/stringFormatters';

import ToolsBackground from './ToolsBackground';
import {
  colors,
  StyleProps,
  TemplateProps,
} from './theme';
import { Attachments } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.basic100,
  },
  invoiceTitle: {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0',
  },
  themeColor: {
    color: ({ themeColor }: StyleProps) => colors[themeColor],
  },
  organizationInfo: {
    color: theme.palette.common.basic900,
  },
  descriptionBox: {
    border: `1px solid ${theme.palette.common.primary100}`,
    borderRadius: 8,
    padding: '16px 24px',
    marginTop: 36,
    marginBottom: 32,
    minHeight: 100,
  },
  logo: {
    height: 124,
    maxHeight: '100%',
    width: 'auto',
  },
  modernHeader: {
    backgroundColor: ({ themeColor }: StyleProps) => colors[themeColor],
    width: '100%',
    height: 120,
  },
}));

type BodyTextProps = {
  align?: TypographyProps['align'];
  color?: TypographyProps['color'];
  children: React.ReactNode;
}

const BodyText = ({ children, color = 'textPrimary', align = 'left' }: BodyTextProps) => (
  <Box mt="4px">
    <Typography variant="body2" color={color} align={align}>
      {children}
    </Typography>
  </Box>
);

const MobileTemplate = ({
  settings,
  organization,
  invoice,
  links,
  attachments,
}: TemplateProps): JSX.Element => {
  const classes = useStyles({
    themeColor: settings.primaryColor,
    fillBackground: settings.background === Background.SOLID,
  });

  return (
    <Box bgcolor="common.basic100">
      {settings.background === Background.PATTERN &&
        <ToolsBackground width="100%" height="auto" color={colors[settings.primaryColor]} />
      }
      {settings.background === Background.SOLID &&
        <div className={classes.modernHeader} />
      }
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" mb={2}>
          {settings.logo && organization.logoUrl &&
          <img
            src={organization.logoUrl}
            className={classes.logo}
            alt="Company logo"
          />
          }
        </Box>
        <Box className={classes.invoiceTitle}>
          <Box className={classes.themeColor}>
            <Typography variant="h3" color="inherit">
              Invoice #{invoice.invoiceNumber}
            </Typography>
          </Box>
          <Box mt={2} />
          {invoice.sentAt &&
            <BodyText>
              Sent: {format(Date.parse(invoice.sentAt), 'MMMM d, yyyy')}
            </BodyText>
          }
          {invoice.dueDate &&
            <BodyText>
              Due: {format(Date.parse(invoice.dueDate), 'MMMM d, yyyy')}
            </BodyText>
          }
        </Box>
        <Divider variant="fullWidth" />
        <Box my={2} className={classes.organizationInfo}>
          <Typography variant="subtitle2">
            {organization.companyName}
          </Typography>
          <Box mt={1} />
          <Typography variant="body2">
            {organization.fullAddress}
          </Typography>
          <Typography variant="body2">
            {organization.businessPhone}
          </Typography>
          <Typography variant="body2">
            {organization.businessEmail}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="caption">
            <Box display="flex" flexDirection="column">
              {links.map(link => (
                <Box mb={1} key={link}>
                  <Link href={link}>
                    {link}
                  </Link>
                </Box>
              ))}
            </Box>
          </Typography>
        </Box>
        <Divider variant="fullWidth" />
        <Box my={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Invoice To:
          </Typography>
          <Box mt={1} />
          <BodyText>
            {invoice.fullName}
          </BodyText>
          <BodyText>
            {invoice.phoneNumber}
          </BodyText>
          <BodyText>
            {invoice.email}
          </BodyText>
        </Box>
        <Box className={classes.descriptionBox}>
          <Typography variant="subtitle2" color="textPrimary">
            Description:
          </Typography>
          <Box mt={1} />
          <Typography variant="body2" color="textPrimary">
            {invoice.description}
          </Typography>
        </Box>
        <Box>
          {invoice.personalNote &&
            <>
              <Typography variant="subtitle2" color="textPrimary">
                Note:
              </Typography>
              <Box mt={1} />
              <Typography variant="body2" color="textPrimary">
                {invoice.personalNote}
              </Typography>
            </>
          }
        </Box>
        <Box mt={4} mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Total
          </Typography>
          <Box className={classes.themeColor}>
            <Typography variant="h2" color="inherit">
              {currencyFormatFromCents(invoice.amountRequested, 2)}
            </Typography>
          </Box>
        </Box>
        {attachments.length > 0 &&
          <Box mb={2} mx={1}>
            <Attachments attachments={attachments} />
          </Box>
        }
      </Box>
    </Box>
  );
};

export default MobileTemplate;
