import { animateScroll } from 'react-scroll';
import { Box, Typography, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import { ButtonBase } from 'common/components/material-ui';
import { Loader } from 'common/components/widgets';

import { useSortingOffers } from '../useSortingOffers';
import LoanOfferBox from './LoanOfferBox';

const useStyles = makeStyles(theme => ({
  moreOffers: {
    display: 'flex',
    color: theme.palette.common.basic1100,
    backgroundColor: theme.palette.common.basic300,
    borderRadius: 4,
    width: '100%',
    justifyContent: 'center',
    margin: '20px 0',
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
  },
  icon: {
    height: 32,
    width: 32,
    color: theme.palette.common.basic1100,
  },
}));

const OffersList = (): JSX.Element => {
  const classes = useStyles();

  const {
    socialProof: {
      socialProofOffer,
      hasSocialProof,
    },
    actions: {
      toggleShowTopOffers,
    },
    offersState: {
      offers,
      showTopOffers,
      offersToShow,
      topOffers,
      bottomOffers,
    },
    processState: {
      growIn,
    },
  } = useSortingOffers();

  return (
    <>
      {!growIn &&
        <Box my={4}>
          <Loader />
        </Box>
      }
      <Typography variant="body2">
        <Box mt={2} color="common.basic900">
          {offers.length} total options
        </Box>
      </Typography>
      {topOffers.map(offer => (
        <Grow in={growIn} timeout={500} key={offer.id}>
          <Box my={2} key={offer.id}>
            <LoanOfferBox
              offer={offer}
              socialProof={hasSocialProof && offer.id === socialProofOffer.id}
              topOffer
            />
          </Box>
        </Grow>
      ))}
      {!showTopOffers &&
        bottomOffers.map(offer => (
          <Grow in={!showTopOffers} timeout={1000} key={offer.id}>
            <Box my={2}>
              <LoanOfferBox
                offer={offer}
                socialProof={hasSocialProof && offer.id === socialProofOffer.id}
              />
            </Box>
          </Grow>
        ))
      }
      {topOffers.length >= offersToShow &&
        <ButtonBase
          className={classes.moreOffers}
          onClick={() => {
            toggleShowTopOffers();
            if (!showTopOffers) {
              animateScroll.scrollToTop();
            }
          }}
        >
          {showTopOffers && bottomOffers.length > 0 &&
            <>
              <Typography variant="body1" color="textPrimary">
                SHOW {bottomOffers.length} ADDITIONAL OFFER{bottomOffers.length > 1 ? 'S' : ''}
              </Typography>
              <ArrowDropDownRoundedIcon className={classes.icon} />
            </>
          }
          {!showTopOffers && bottomOffers.length > 0 &&
            <>
              <Typography variant="body1" color="textPrimary">
                SHOW {bottomOffers.length} LESS OFFER{bottomOffers.length > 1 ? 'S' : ''}
              </Typography>
              <ArrowDropUpRoundedIcon className={classes.icon} />
            </>
          }
        </ButtonBase>
      }
    </>
  );
};

export default OffersList;
