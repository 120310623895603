import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  Dialog,
  Button,
  ButtonBase,
  Link,
} from 'common/components/material-ui';
import { useUrl } from 'common/hooks';

const useStyles = makeStyles({
  dialogRoot: {
    padding: 0,
  },
});

type Props = {
  modalOpen: boolean
  onClose: () => void
}

const LoginModal = ({ modalOpen, onClose }: Props): JSX.Element => {
  const classes = useStyles();
  const { appUrl } = useUrl();
  return (
    <Dialog
      open={modalOpen}
      onClose={onClose}
      showCloseButton={false}
      contentClassName={classes.dialogRoot}
      scroll="body"
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={4}
          py={5}
        >
          <img
            height="24px"
            src="https://hearth.imgix.net/contractor-v2/mobile_redirect/logo.svg"
            alt="hearth-logo"
          />
          <Box
            mt={2}
          >
            <Typography variant="subtitle2" color="textPrimary" align="center"> Use the Hearth App for the best experience </Typography>
          </Box>
          <img
            width="100%"
            src="https://hearth.imgix.net/contractor-v2/mobile_redirect/mobile_preview.svg"
            alt="mobile"
          />
          <Box
            color="common.basic900"
            mb={5}
          >
            <Typography variant="caption" color="inherit" align="center"> • Stay on top of your business from anywhere </Typography>
          </Box>
          <Button
            variant="contained"
            buttonType="common.primary"
            fullWidth
            component="a"
            href={appUrl('/deep')}
          >
            <Typography variant="subtitle2" color="inherit"> Open in the Hearth App </Typography>
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="common.primary100"
          p={3}
          alignItems="center"
        >
          <Typography variant="subtitle2" color="textPrimary"> Don’t have the Hearth App? </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
            mb={3}
          >
            <ButtonBase
              component="a"
              href="https://itunes.apple.com/us/app/hearth-for-contractors/id1383073333?mt="
            >
              <img
                height="42px"
                src="https://hearth.imgix.net/contractor-v2/mobile_redirect/app_store.png"
                alt="app-store"
              />
            </ButtonBase>
            <ButtonBase
              component="a"
              href={'https://play.google.com/store/apps/details?id=com.gethearth.katana' +
                '&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'}
            >
              <img
                height="42px"
                src="https://hearth.imgix.net/contractor-v2/mobile_redirect/play_store.svg"
                alt="play-store"
              />
            </ButtonBase>
          </Box>
          <Box
            color="common.primary500"
            mb={8}
          >
            <Link
              variant="subtitle2"
              color="inherit"
              onClick={onClose}
              underline="always"
            >
              Continue on Web Browser
            </Link>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LoginModal;
