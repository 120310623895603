import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 8px;
  background-color: ${props => props.theme.palette.common.basic300};
  border: 1px solid ${props => props.theme.palette.common.basic700};
  border-radius: 3px;

  &:focus-within {
    border-color: ${props => props.theme.palette.common.primary500};
  }

  input, select {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${props => props.theme.palette.common.basic300} inset !important;
    }
  }
`;

export default (Component) => {
  const Wrapper = ({
    prefix,
    suffix,
    style,
    wrapperStyle,
    ...otherProps
  }) => (
    <Container style={style}>
      {prefix}
      <div style={{ flex: 1, ...wrapperStyle }}>
        <Component {...otherProps} />
      </div>
      {suffix}
    </Container>
  );

  Wrapper.propTypes = {
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    style: PropTypes.shape({}),
    wrapperStyle: PropTypes.shape({}),
  };

  Wrapper.defaultProps = {
    prefix: null,
    suffix: null,
    style: undefined,
    wrapperStyle: {},
  };

  return Wrapper;
};
