import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ELEMENT_OL, ELEMENT_UL, ELEMENT_LIC } from '@udecode/plate-list';
import { ELEMENT_H1, ELEMENT_H2 } from '@udecode/plate-heading';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { TRenderElementProps } from '@udecode/plate-core';

const useStyles = makeStyles({
  blockQuote: {
    marginBottom: 4,
  },
  h1: {
    marginTop: 0,
    marginBottom: 6,
  },
  h2: {
    marginTop: 0,
    marginBottom: 4,
  },
  ul: {
    marginBottom: 4,
  },
  ol: {
    marginBottom: 4,
  },
});

const Element = ({ attributes, children, element }: TRenderElementProps): JSX.Element => {
  const classes = useStyles();

  switch (element.type) {
  case ELEMENT_BLOCKQUOTE:
    return (
      <Typography
        variant="body1"
        component="blockquote"
        className={classes.blockQuote}
        {...attributes}
      >
        {children}
      </Typography>
    );
  case ELEMENT_H1:
    return (
      <Typography
        variant="h2"
        className={classes.h1}
        {...attributes}
      >
        {children}
      </Typography>
    );
  case ELEMENT_H2:
    return (
      <Typography
        variant="h3"
        className={classes.h2}
        {...attributes}
      >
        {children}
      </Typography>
    );
  case ELEMENT_LIC:
    return (
      <Typography
        variant="body1"
        component="li"
        {...attributes}
      >
        {children}
      </Typography>
    );
  case ELEMENT_UL:
    return (
      <Typography
        variant="body1"
        component="ul"
        className={classes.ul}
        {...attributes}
      >
        {children}
      </Typography>
    );
  case ELEMENT_OL:
    return (
      <Typography
        variant="body1"
        component="ol"
        className={classes.ol}
        {...attributes}
      >
        {children}
      </Typography>
    );
  default:
    return (
      <Typography
        variant="body1"
        {...attributes}
      >
        {children}
      </Typography>
    );
  }
};

export default Element;
