import { SvgIconProps } from '../Icon';

const DashboardIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M.5 4.887a2 2 0 01.916-1.68l3.5-2.259a2 2 0 012.169 0l3.5 2.258a2 2 0 01.915 1.681v4.362a2 2 0 01-2 2h-7a2 2 0 01-2-2V4.887z"
      fill={color}
    />
    <path
      d="M3.938 7.349c.147.473.766 1.419 2.062 1.419a2.134 2.134 0 002.063-1.42"
      stroke="#fff"
      strokeLinecap="round"
    />
  </svg>
);

export default DashboardIcon;
