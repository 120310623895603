import { useUrl, useVerticalState } from 'common/hooks';

type Props = {
  agreeButtonText: string;
  listStyleType?: string;
}

const UserTerms = ({ agreeButtonText, listStyleType = 'none' }: Props): JSX.Element => {
  const { marketingUrl } = useUrl();
  const [{ brandMetadata }] = useVerticalState();

  return (
    <ul style={{
      listStyleType,
      paddingLeft: 0,
    }}
    >
      <li className="terms">
        I have read and consent to the{' '}
        <a
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={marketingUrl('/econsent')}
        >
          Terms for Electronic Transactions, Signatures and Records
        </a>
        ; and I confirm I have read and agree to Hearth’s{' '}
        <a
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={marketingUrl('/privacy')}
        >
          Privacy Policy
        </a>
        ,{' '}
        <a
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={marketingUrl('/terms')}
        >
          Terms of Use
        </a>
        , and{' '}
        <a
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={marketingUrl('/callconsent')}
        >
          Communications Policy
        </a>
        .
      </li>
      <li className="terms">
        I electronically{' '}
        <a
          style={{ textDecoration: 'underline' }}
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={`${marketingUrl('/callconsent')}`}
        >
          authorize
        </a>
        {` ${brandMetadata.label}`} to use automated or nonautomated technology to call or
        text me at the number I provide with information or offers. Consent is not required.
        Message and data rates may apply.
      </li>
      <li className="terms">
        Hearth may use analytics software to collect and send user and device data, including
        records of user actions, to third parties to help improve our services. I consent to
        Hearth’s sharing such data with third parties.
      </li>
      <li className="terms" style={{ listStyle: 'none' }}>
        By clicking “{agreeButtonText}”, you expressly agree to the terms above.
      </li>
    </ul>
  );
};

export default UserTerms;
