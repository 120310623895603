import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import store from 'stores';
import { hearthUidSelector, deviceIdSelector } from 'common/selectors/user';
import { ReactotronType } from 'common/utils/reactotronConfig';
import * as localStorage from 'common/services/localStorage';

import RequestMutex from './requestMutex';
import { isAxiosError } from './utils';

let reactotron: ReactotronType | undefined;
if (process.env.REACT_APP_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  reactotron = require('common/utils/reactotronConfig').default;
}

export const requestMutex = new RequestMutex();

/* eslint-disable no-param-reassign */
const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v2`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  transformRequest: [(data, headers) => {
    const state = store.getState();

    // Identification
    const hearthUid = hearthUidSelector(state);
    if (hearthUid) {
      headers['X-Hearth-Uid'] = hearthUid;
    }
    const deviceId = deviceIdSelector(state);
    if (deviceId) {
      headers['X-Device-Id'] = deviceIdSelector(state);
    }

    if (localStorage.hasStorage() && localStorage.getItem('accessToken')) {
      headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }

    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
      return data;
    }
    return JSON.stringify(decamelizeKeys(data));
  }],
  transformResponse: [(data) => {
    try {
      return camelizeKeys(JSON.parse(data));
    } catch (e) {
      return data;
    }
  }],
});
/* eslint-enable no-param-reassign */

const shouldIntercept = (error: AxiosError) => {
  try {
    if (error?.response?.status !== 401) {
      return false;
    }
    const headers = error?.response?.headers;
    return headers && headers['www-authenticate']?.indexOf('invalid_token') !== -1;
  } catch (e) {
    return false;
  }
};

type ModifiedRequestConfig = AxiosRequestConfig & {
  _queued: boolean;
  _retry: boolean;
}

function isModifiedRequestConfig(
  config: AxiosRequestConfig | ModifiedRequestConfig,
): config is ModifiedRequestConfig {
  if ((config as ModifiedRequestConfig)._retry !== undefined) {
    return true;
  }

  if ((config as ModifiedRequestConfig)._queued !== undefined) {
    return true;
  }

  return false;
}

/**
 * Custom interceptor to refresh oauth tokens
 *
 * Inspired by:
 * https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
 * https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
 *
 * @param error - An Axios error
 */
const authInterceptor = (error: Error | AxiosError): Promise<unknown> => {
  if (!isAxiosError(error)) {
    return Promise.reject(error);
  }

  if (!shouldIntercept(error)) {
    return Promise.reject(error);
  }

  if (isModifiedRequestConfig(error.config) && ((error.config._retry || error.config._queued))) {
    return Promise.reject(error);
  }

  // @TODO: do we need this?
  // if (localStorage.hasStorage() &&
  //   !localStorage.getItem('refreshToken') &&
  //   !localStorage.getItem('accessToken')
  // ) {
  //   return Promise.reject(error);
  // }

  const originalRequest = error.config as ModifiedRequestConfig;

  if (requestMutex.mutex.isLocked()) {
    return new Promise(((resolve, reject) => {
      requestMutex.pushUnresolvedRequest({ resolve, reject });
    }))
      .then((_value: unknown) => {
        originalRequest._queued = true;
        return client.request(originalRequest);
      })
      .catch(_err =>
      // Ignore refresh token request's "err" and return actual "error" for the original request
        Promise.reject(error),
      );
  }

  originalRequest._retry = true;
  return requestMutex.refreshAccessToken();
};

client.interceptors.response.use(response => response, authInterceptor);

// Add reactotron interceptors
if (reactotron?.apiResponse) {
  const { apiResponse } = reactotron;
  client.interceptors.request.use(
    config => ({
      ...config,
      requestStartTime: Date.now(),
    }),
    error => Promise.reject(error),
  );

  client.interceptors.response.use(
    (response) => {
      apiResponse(
        response.config,
        { ...response, body: response.data },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Date.now() - (response.config as any).requestStartTime,
      );
      return response;
    },
    (error) => {
      const response = error.response;
      apiResponse(
        error.config,
        { ...response, body: response.data },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Date.now() - (response.config as any).requestStartTime,
      );
      return Promise.reject(error);
    },
  );
}

export default client;
