import client from './client';

// @TODO: Remove any types with correct types
export default {
  inviteMembers: (organizationId: string, newMembers: any) => client.request({
    method: 'POST',
    url: `/organizations/${organizationId}/invite`,
    data: { memberInvites: newMembers },
  }),
  resendMemberInvite: (organizationId: string, inviteEmail: any) => client.request({
    method: 'POST',
    url: `/organizations/${organizationId}/resend_member_invite`,
    data: { inviteEmail },
  }),
};
