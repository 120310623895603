import { string, object, date } from 'yup';
import moment from 'moment';
import { Box } from '@mui/material';

import { Button } from 'common/components/material-ui';
import { Form, InputField, DatePickerField } from 'common/components/formik';

export type Values = {
  email?: string;
  birthday?: Date;
};

type Props = {
  onSubmit: (value: Pick<Values, 'email' | 'birthday'>) => void;
  loading: boolean;
};

const SeeOffersForm = ({ onSubmit, loading }: Props): JSX.Element => (
  <Form<Values>
    initialValues={{
      email: undefined,
    }}
    onSubmit={values => onSubmit({
      email: values.email,
      birthday: values.birthday,
    })}
    validateOnChange={false}
    validateOnBlur
    validationSchema={object().shape({
      email: string().label('Email').email().required(),
      birthday: date().typeError('Date is invalid').label('Birthdate')
        .max(moment().subtract(18, 'years').toDate(), 'You must be at least 18 years old')
        .required(),
    })}
  >
    <InputField name="email" label="Email address" />
    <Box m={3} />
    <DatePickerField
      name="birthday"
      label="Birthdate"
      textFieldProps={{ fullWidth: true, defaultValue: '' }}
    />
    <Box display="flex" justifyContent="flex-end" mt={4}>
      <Button
        variant="contained"
        buttonType="primary"
        disabled={loading}
        type="submit"
      >
        Find previous offers
      </Button>
    </Box>
  </Form>
);

export default SeeOffersForm;
