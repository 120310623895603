import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Divider,
} from '@mui/material';

import { Link } from 'common/components/material-ui';
import { AppLogos, TrustIcons } from 'common/components/widgets';
import { useUrl, useVerticalState } from 'common/hooks';

/*
  Breadcrumbs are a list of links leading back up to the root page.
  Usually they are used for navigation, Google also likes to see them
  for SEO purposes. In the interest of not changing our
  website's flow, yet, we are generating this list of links
  and tags for google to read and use, but keeping it hidden.
  Breadcrumbs should be a list of objects each with an 'href' and 'text' key.

  For more, check this out: https://developers.google.com/search/docs/data-types/breadcrumbs
*/

type FooterProps = {
  breadcrumbs?: null | {
    href: string;
    text: string;
  }[];
  showAppButtons?: boolean;
};

const Footer = ({ breadcrumbs = null, showAppButtons = true }: FooterProps): JSX.Element => {
  const { marketingUrl } = useUrl();
  const [{ verticalMetadata }] = useVerticalState();
  const {
    dashboardHome: { support },
    footer: { showAppLinks, logos },
  } = verticalMetadata;

  return (
    <Box bgcolor="common.basic100" px={3} py={4} borderTop={1} borderColor="common.basic300">
      <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12} md={8}>
          <Typography variant="h5">
            <Box fontWeight="fontWeightLight">
              Questions? Call us at{' '}
              <Link
                color="primary"
                href={`tel:${support.phone}`}
              >
                {support.displayPhone}
              </Link>
            </Box>
          </Typography>
        </Grid>
        {showAppLinks && showAppButtons &&
          <Grid item xs={12} md={8}>
            <AppLogos />
          </Grid>
        }
      </Grid>
      <Box my={4}>
        <Divider />
      </Box>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item sm={6}>
          <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
            <Link
              component="a"
              variant="subtitle1"
              color="textPrimary"
              href={`mailto:${support.email}`}
            >
              <Box fontWeight="fontWeightRegular" mx={2}>
                Contact
              </Box>
            </Link>
            <Link
              component="a"
              variant="subtitle1"
              color="textPrimary"
              rel="noopener noreferrer"
              target="_blank"
              href={marketingUrl('/privacy')}
            >
              <Box fontWeight="fontWeightRegular" mx={2}>
                Privacy Policy
              </Box>
            </Link>
            <Link
              component="a"
              variant="subtitle1"
              color="textPrimary"
              rel="noopener noreferrer"
              target="_blank"
              href={marketingUrl('/terms')}
            >
              <Box fontWeight="fontWeightRegular" mx={2}>
                Terms of Use
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <TrustIcons
            logos={logos}
            noMargin
          />
        </Grid>
      </Grid>
      {/* The 'is' keyword lets us use Microdata tags without breaking things. */}
      <div style={{ display: 'none' }}>
        <ol itemScope itemType="http://schema.org/BreadcrumbList">
          {
            breadcrumbs &&
              breadcrumbs.map(crumb => (
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  key={'breadcrumb' + crumb.href}
                >
                  <RouterLink itemProp="item" to={crumb.href}>
                    <span itemProp="name">{crumb.text}</span>
                  </RouterLink>
                </li>
              ))
          }
        </ol>
      </div>
    </Box>
  );
};

export default Footer;

