import { Box, Typography } from '@mui/material';

const formatCaption = (caption: React.ReactNode): React.ReactNode => {
  if (typeof caption === 'string') {
    return (
      <Typography
        variant="caption"
        data-testid="form-field-caption-line"
      >
        {caption}
      </Typography>
    );
  }
  // if caption is an array, return a bulleted list
  if (Array.isArray(caption)) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {caption.map(msg => (
          <Typography
            variant="caption"
            sx={{
              display: 'list-item',
              listStyleType: 'disc',
              listStylePosition: 'inside',
            }}
            key={msg}
            data-testid="form-field-caption-line"
          >
            {msg}
          </Typography>
        ))}
      </Box>
    );
  }
  return caption;
};

export default formatCaption;
