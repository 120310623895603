import React from 'react';
import {
  Box,
  InputAdornment,
  InputAdornmentProps,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import CheckedInputField from './CheckedInputField';
import { useCustomizeInvoiceActions } from '../useCustomizeInvoice';

export enum SocialMediaKeys {
  FACEBOOK_URL = 'facebookUrl',
  TWITTER_URL = 'twitterUrl',
  INSTAGRAM_URL = 'instagramUrl',
  LINKEDIN_URL = 'linkedinUrl',
}

export const socialMediaMap = [
  {
    key: SocialMediaKeys.FACEBOOK_URL,
    adornment: 'facebook.com/',
    Icon: FacebookIcon,
  },
  {
    key: SocialMediaKeys.TWITTER_URL,
    adornment: 'twitter.com/',
    Icon: TwitterIcon,
  },
  {
    key: SocialMediaKeys.INSTAGRAM_URL,
    adornment: 'instagram.com/',
    Icon: InstagramIcon,
  },
  {
    key: SocialMediaKeys.LINKEDIN_URL,
    adornment: 'linkedin.com/in/',
    Icon: LinkedInIcon,
  },
];

const useStyles = makeStyles({
  inputAdornment: {
    margin: 0,
  },
  inputAdornedStart: {
    paddingLeft: 0,
  },
  icon: {
    paddingRight: 8,
    height: 24,
    width: 24,
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
    width: '100%',
  },
});

type SocialMediaAdornmentProps = {
  adornment: string;
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
} & InputAdornmentProps;

const SocialMediaAdornment = ({
  Icon,
  adornment,
  ...otherProps
}: SocialMediaAdornmentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <InputAdornment
      classes={{
        positionStart: classes.inputAdornment,
      }}
      {...otherProps}
    >
      <Icon className={classes.icon} />
      {adornment}
    </InputAdornment>
  );
};

const SocialMediaModule = (): JSX.Element => {
  const classes = useStyles();
  const { updateOrganizationInvoiceTemplateInfo } = useCustomizeInvoiceActions();

  return (
    <>
      <Typography variant="subtitle1">
        <Box fontWeight="fontWeightBold">
          Social Media
        </Box>
      </Typography>
      <Box m={2} />
      {socialMediaMap.map(({ key, adornment, Icon }) => (
        <Box key={key} className={classes.inputWrapper}>
          <CheckedInputField
            name={key}
            label=""
            InputProps={{
              startAdornment: <SocialMediaAdornment adornment={adornment} Icon={Icon} position="start" />,
              classes: {
                inputAdornedStart: classes.inputAdornedStart,
              },
              fullWidth: true,
            }}
            size="small"
            style={{
              paddingLeft: 0,
            }}
            onBlur={(e) => {
              updateOrganizationInvoiceTemplateInfo(
                { [key]: e.target.value },
                e.target.value !== '',
              );
            }}
          />
        </Box>
      ))
      }
    </>
  );
};

export default SocialMediaModule;
