import { Fragment, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useField } from 'formik';

import { Button, Input } from 'common/components/material-ui';
import { useLogEvent } from 'common/hooks';

import { useEditContractTemplateUIStates } from '../useEditContractTemplate';

const TextEntryList = (): JSX.Element => {
  const { setEditingTextEntryList } = useEditContractTemplateUIStates();
  const itly = useLogEvent();
  const [{ value: textEntries }, , { setValue: setTextEntries }] =
    useField<string[][]>('customTextEntries');

  const [editTextEntry, setEditTextEntry] = useState<number | null>(null);
  const [tempValue, setTempValue] = useState<string[]>([]);

  return (
    <Box mt={textEntries.length < 1 ? 4 : 3} px={2} mb={5}>
      {textEntries.length < 1 ?
        <Button
          variant="text"
          buttonType="common.primary"
          onClick={() => {
            setTextEntries([['Detail 1', '']]);
            itly.addTextEntryListInContractTemplate();
          }}
        >
          + Add a text entry list
        </Button> :
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">
              <Box fontWeight={600}>
                Custom text entry list
              </Box>
            </Typography>
            <Button
              variant="text"
              buttonType="common.primary"
              onClick={() => setTextEntries([])}
            >
              Remove
            </Button>
          </Box>
          <Divider style={{ margin: '16px 0' }} />
          {textEntries.map((entry, i) => (
            <Fragment key={entry[0] || `Detail ${i + 1}`}>
              {editTextEntry !== i ?
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1">
                    {entry[0] || `Detail ${i + 1}`}
                  </Typography>
                  <Box display="flex">
                    <IconButton
                      onClick={() => {
                        setEditingTextEntryList(true);
                        setTempValue(textEntries[i]);
                        setEditTextEntry(i);
                      }}
                      size="large"
                    >
                      <Box display="flex" alignItems="center" color="common.primary500">
                        <EditOutlinedIcon fontSize="large" />
                      </Box>
                    </IconButton>
                    <IconButton
                      onClick={() => setTextEntries(
                        textEntries.slice(0, i).concat(textEntries.slice(i + 1)),
                      )}
                      size="large"
                    >
                      <Box display="flex" alignItems="center" color="common.basic900">
                        <CloseIcon fontSize="large" />
                      </Box>
                    </IconButton>
                  </Box>
                </Box> :
                <Box px={2} pt={3} pb={2}>
                  <Input
                    name="textEntryName"
                    label="Detail"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTempValue([e.target.value, tempValue[1]])}
                    value={tempValue[0]}
                    fullWidth
                    autoFocus
                  />
                  <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setEditTextEntry(null);
                        setTempValue([]);
                        setEditingTextEntryList(false);
                      }}
                    >
                      <Typography variant="subtitle2">
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      buttonType="common.primary"
                      onClick={() => {
                        const textEntryCopy = [...textEntries];
                        textEntryCopy[editTextEntry] = tempValue;
                        setEditTextEntry(null);
                        setTextEntries(textEntryCopy);
                        setTempValue([]);
                        setEditingTextEntryList(false);
                      }}
                      disabled={textEntries[editTextEntry] === tempValue}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              }
              <Divider style={{ margin: '16px 0' }} />
            </Fragment>
          ))}
          <Box pt={1}>
            <Button
              variant="text"
              buttonType="common.primary"
              onClick={() =>
                setTextEntries([...textEntries, ['', '']])
              }
              disabled={editTextEntry !== null}
              style={{
                width: 'fit-content',
              }}
            >
              + Add another text entry list item
            </Button>
          </Box>
        </Box>
      }
    </Box>
  );
};

export default TextEntryList;
