import { useCallback } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { gql } from '@apollo/client';

import { Button } from 'common/components/material-ui';
import { Icon } from 'common/components';
import { useSetFirstTemplateBannerDismissedMutation } from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql, useLogEvent } from 'common/hooks';

const FirstTemplateBanner = (): JSX.Element => {
  const theme = useTheme();
  const { handleMutationError } = useGql();
  const itly = useLogEvent();

  const [setFirstTemplateBannerDismissedMutation] = useSetFirstTemplateBannerDismissedMutation({
    refetchQueries: ['GetFirstTemplateBannerData'],
  });

  const setFirstTemplateBannerDismissed = useCallback(async () => {
    try {
      const response = await setFirstTemplateBannerDismissedMutation();

      parseGql<PayloadType<typeof response, 'upsertContractor'>>(
        'upsertContractor',
        response,
        'UpsertContractorSuccess',
        'UpsertContractorFailure',
      );
      itly.dismissFirstContractTemplateBanner();
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [setFirstTemplateBannerDismissedMutation, handleMutationError, itly]);

  return (
    <Box
      display="flex"
      py={3}
      pl={2}
      pr={7}
      borderRadius="8px"
      bgcolor="common.success100"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {/* without the wrapping box, the icon is too low/small */}
      <Box mr={2}>
        <Icon name="tada" color="success900" size={24} />
      </Box>
      <Box display="flex" flexDirection="column" color="common.success900">
        <Typography variant="body2" align="justify">
          <b>Congratulations on setting up your first contract template!</b> This is the Template
          Editor. You can keep customizing the template here and add a standard description of
          services. Changes made here will apply to any contract sent with this template. You{'\''}ll
          be able to modify the details for a specific customer when you send the contract.
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          mt={1}
        >
          <Typography variant="body2">
            Ready to go? Use “Send new” to send your first contract now.
          </Typography>
          <Button
            variant="contained"
            buttonType="common.success"
            onClick={setFirstTemplateBannerDismissed}
            style={{
              backgroundColor: theme.palette.common.success700,
              padding: '8px 32px',
            }}
          >
            <Typography variant="subtitle2">
              Got it, dismiss
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

FirstTemplateBanner.SET_FIRST_TEMPLATE_BANNER_DISMISSED = gql`
  mutation SetFirstTemplateBannerDismissed {
    upsertContractor(customSettings: { firstTemplateBannerDismissed: true }) {
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default FirstTemplateBanner;
