import { NavDrawerItem } from 'common/components/material-ui';

import {
  useDashboardNavigation,
  NavigationOption,
  useDashboardNavActions,
} from '../useDashboardNavigation';

/**
 * NAV: Dashboard
 * Always appears, is the 'home' tab
 */

const DashboardTab = (): JSX.Element => {
  const { uiStates: { activeTab } } = useDashboardNavigation();
  const { onNavItemClick } = useDashboardNavActions();

  return (
    <NavDrawerItem
      title="Dashboard"
      icon={activeTab === NavigationOption.DASHBOARD ? 'dashboard' : 'dashboard-outline'}
      color={activeTab === NavigationOption.DASHBOARD ? 'primary500' : 'basic1100'}
      active={activeTab === NavigationOption.DASHBOARD}
      onClick={() => onNavItemClick('/dashboard')}
    />
  );
};

export default DashboardTab;
