import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import classNames from 'classnames';

import logger, { LogGroup } from 'common/services/logger';
import { Smidge } from 'common/components/layouts';
import { componentName, parentComponentName } from 'common/utils/react';

import './Popup.scss';

class Popup extends Component {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClosePopup: PropTypes.func,
    gradientBackground: PropTypes.bool,
    type: PropTypes.oneOf(['normal', 'exit']),
    theme: PropTypes.oneOf(['dark', 'light']),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    closable: PropTypes.bool,
    contentRef: PropTypes.func,
    topMargin: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    onClosePopup: () => {},
    gradientBackground: false,
    className: '',
    type: 'normal',
    theme: 'light',
    closable: true,
    contentRef: () => {},
    topMargin: false,
  };

  state = {
    isRealtorWhitelabel: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { location, isOpen } = this.props;
    const { pathname } = location;
    this.setState({ isRealtorWhitelabel: pathname.indexOf('/white-label/realtor') !== -1 });
  }

  handleClosePopup = () => {
    const { onClosePopup } = this.props;
    onClosePopup();
  };

  handleStopPropagation = (event) => {
    // Do not close popup on click inside popup content.
    event.stopPropagation();
  };

  render() {
    const {
      isOpen,
      children,
      gradientBackground,
      theme,
      type,
      className,
      closable,
      contentRef,
      topMargin,
    } = this.props;
    const { isRealtorWhitelabel } = this.state;
    return !isRealtorWhitelabel && isOpen && (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClosePopup}
        shouldCloseOnOverlayClick={closable}
        shouldCloseOnEsc={closable}
        ariaHideApp={false}
        overlayClassName="popup-overlay"
        contentRef={contentRef}
        className={
          classNames(
            'popup-modal',
            className,
            { 'exit-popup': type === 'exit' },
          )
        }
      >
        {topMargin &&
          <Smidge x={12} />
        }
        <div className="popup-container" onClick={closable ? this.handleClosePopup : () => {}}>
          <div
            className={
              classNames(
                'popup',
                `theme-${theme}`,
                { 'gradient-background': gradientBackground },
              )}
            onClick={this.handleStopPropagation}
          >
            { closable && (
              <button
                className="close-button"
                onClick={this.handleClosePopup}
                type="button"
              >
                <img
                  src="https://hearth.imgix.net/icons/close.svg"
                  alt="Pop-up close icon"
                />
              </button>
            )}
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(Popup);
