import { styled } from '@mui/material/styles';
import {
  Box,
  Dialog,
  Typography,
  CardMedia,
} from '@mui/material';

import { Button } from 'common/components/material-ui';
import constants from 'common/utils/constants';

const Lock = styled('div', { name: 'Lock' })({
  margin: '50px auto',
  width: '10%',
});

const Title = styled('div', { name: 'Title' })({
  display: 'flex',
  margin: '20px 50px',
});

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BlockedModal = ({ open, setOpen }: Props): JSX.Element => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <Lock>
      <CardMedia
        component="img"
        image="https://hearth.imgix.net/kitchen_sink/lock.svg"
        alt="Lock"
      />
    </Lock>
    <Title>
      <Typography variant="h2" align="center">
        Complete your training call to unlock financing
      </Typography>
    </Title>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="50px auto 20px"
    >
      <Typography variant="body1">
        Have a client that needs financing now?
      </Typography>
      <Typography variant="body1">
        Call us:
      </Typography>
      <Typography variant="body1" color="primary">
        {constants.supportNumberDisplay}
      </Typography>
    </Box>
    <Box display="flex" margin="20px" justifyContent="center">
      <Button
        onClick={() => setOpen(false)}
        variant="contained"
        buttonType="primary"
        fullWidth
      >
        Ok
      </Button>
    </Box>
  </Dialog>
);

export default BlockedModal;
