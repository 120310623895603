import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { InvoiceTemplateType } from 'types';
import { socialMediaMap } from 'contractor/modules/CustomizeInvoice/components/SocialMediaModule';

import { ClassicTemplate, FunTemplate, ModernTemplate } from './templates';
import { TemplateProps } from './templates/theme';
import MobileTemplate from './templates/MobileTemplate';

const templateMapping = {
  [InvoiceTemplateType.CLASSIC_TEMPLATE]: ClassicTemplate,
  [InvoiceTemplateType.FUN_TEMPLATE]: FunTemplate,
  [InvoiceTemplateType.MODERN_TEMPLATE]: ModernTemplate,
};

export type CustomInvoiceProps = Omit<TemplateProps, 'links'>;

const CustomInvoice = ({
  settings,
  organization,
  invoice,
  attachments,
}: CustomInvoiceProps): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const InvoiceTemplate = templateMapping[settings.templateType];

  const links = useMemo((): string[] => {
    const { socialLinks, website: showWebsite } = settings;
    const website = organization?.kycBusiness?.url || organization.website;

    const linkArr = (website && showWebsite) ? [website] : [];

    if (socialLinks != null) {
      socialMediaMap.forEach(({ adornment, key }) => {
        if (socialLinks[key] && organization[key]) {
          linkArr.push(`https://${adornment}${organization[key]}`);
        }
      });
    }

    return linkArr;
  }, [organization, settings]);

  if (matches) {
    return (
      <MobileTemplate
        settings={settings}
        organization={organization}
        invoice={invoice}
        links={links}
        attachments={attachments}
      />
    );
  }

  return (
    <InvoiceTemplate
      settings={settings}
      organization={organization}
      invoice={invoice}
      links={links}
      attachments={attachments}
    />
  );
};

export default CustomInvoice;
