import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Box,
  Typography,
  Skeleton,
} from '@mui/material';

import { useReloadingContainer, useContainer } from 'bentoo/hooks';

import { Button } from 'common/components/material-ui';
import { idSelector } from 'contractor/selectors/organization';
import { fetchQuestions, fetchAnswerOptions } from 'contractor/actions/questionnaire';
import { fetch, submitProjectLeadsQuestionnaire } from 'contractor/actions/organization';
import { useSnack } from 'common/utils/snackCart';

import ProjectLeadsModal from './ProjectLeadsModal';

const ProjectLeadsCard = (): JSX.Element => {
  const { successSnack, errorSnack } = useSnack();
  const organizationId = useSelector(idSelector);
  const fetchQuestionsApi = useReloadingContainer(fetchQuestions, ['project_leads_questionnaire']);
  const fetchAnswerOptionsApi = useContainer(fetchAnswerOptions);
  const submitAnswer = useContainer(submitProjectLeadsQuestionnaire);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  if (fetchQuestionsApi.isLoading) {
    return <Skeleton variant="rectangular" height="100%" />;
  }

  const [interestQuestionId, extraQuestionId] = fetchQuestionsApi.data;

  const actions = {
    showInterest: async () => {
      await submitAnswer.callApi(organizationId, { [interestQuestionId]: 'yes' });
      await fetchAnswerOptionsApi.callApi(extraQuestionId);
      setModalOpen(true);
    },
    answerExtraQuestion: async (answer: string) => {
      setModalOpen(false);
      try {
        await submitAnswer.callApi(organizationId, { [extraQuestionId]: answer });
        successSnack('Thank you! You have been added to the waitlist.');
        dispatch(fetch());
      } catch (e) {
        errorSnack();
      }
    },
  };

  return (
    <Paper style={{ height: '100%' }}>
      {fetchAnswerOptionsApi.data &&
        <ProjectLeadsModal
          question={fetchAnswerOptionsApi.data}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSubmit={actions.answerExtraQuestion}
        />
      }
      <Box display="flex" flexDirection="column" pl={5} pr={4} py={2.5} flex={1} height="100%">
        <Typography variant="body2">
          <Box fontWeight="fontWeightMedium" color="common.green">NEW!</Box>
        </Typography>
        <Typography variant="body1" color="textPrimary">
          <Box fontWeight="fontWeightMedium">Get leads</Box>
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            Win more business with pre-qualified project leads
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flex={1} mt={4}>
          <Box width={1 / 2}>
            <Button
              variant="contained"
              buttonType="primary"
              onClick={actions.showInterest}
              fullWidth
            >
              Get started
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ProjectLeadsCard;
