import { Box, CardMedia, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help } from '@mui/icons-material';
import { currencyFormat, percentFormat } from 'common/utils/stringFormatters';

import { OfferDetailsFragment } from 'types';
import { Tooltip } from 'common/components/modals';

import { OfferInfoItem, OfferInfoItemProps } from './LoanOfferBox';
import OfferBox from './OfferBox';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    '&:hover': {
      boxShadow: '0px 3px 8px rgba(38, 53, 71, 0.2)',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 4,
    },
  },
  offerInfoItem: {
    justifyContent: 'center',
  },
  mobileInfoRow: {
    border: `1px solid ${theme.palette.common.basic300}`,
    display: 'flex',
    flex: 1,
    padding: '8px 16px',
  },
  mobileItem: {
    flex: 1,
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flex: 1,
    margin: '16px 0',
  },
  lenderLogo: {
    height: 48,
    maxWidth: 100,
    objectFit: 'contain',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      objectFit: 'scale-down',
    },
    [theme.breakpoints.down('sm')]: {
      height: 32,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  credit: {
    color: theme.palette.common.basic700,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 8,
    },
  },
  tooltip: {
    marginTop: 5,
  },
}));

const UpgradeHeader = () => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-end">
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <CardMedia
          className={classes.lenderLogo}
          component="img"
          image="https://hearth.imgix.net/creditcard/upgrade_credit_card.png?auto=compress"
          alt="lender logo"
        />
      </Box>
      <div className={classes.header}>
        <Typography variant="h3">
          Upgrade Card
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1" className={classes.credit}>
            Line of Credit
          </Typography>
          <Box m={0.5} />
          <Tooltip
            placement="bottom-right"
            className={classes.tooltip}
            title="More Info"
            text="A Personal Credit Line combines flexibility with the
              predictability and affordability of a personal loan.
              It is unsecured, and you can make draw requests for
              when you need money. Upon approval, you can use as
              much of your Personal Credit Line as you need. With
              each draw, you would have a fixed rate and fixed
              term, which allows you to budget with predictable
              monthly payments."
          >
            <Box color="common.basic300">
              <Help />
            </Box>
          </Tooltip>
        </Box>
      </div>
    </Box>
  );
};

export type UpgradeCreditCardBoxProps = {
  offer: OfferDetailsFragment;
}

const getUpgradeCardData = (offer: OfferDetailsFragment): OfferInfoItemProps['infoItem'][] => [
  {
    header: 'Annual Fee',
    // Yes, this is supposed to be hard coded as 0
    desc: currencyFormat(0, 0),
  },
  {
    header: 'Line Amount',
    desc: `Up to ${currencyFormat(offer.loanAmount, 0)}`,
  },
  {
    header: 'APR',
    desc: percentFormat(offer.apr, 2),
  },
  {
    header: 'Term',
    desc: `${offer.loanTermInMonths} months`,
  },
];

const UpgradeCreditCardBox = ({ offer }: UpgradeCreditCardBoxProps): JSX.Element => {
  const classes = useStyles();

  const upgradeData = getUpgradeCardData(offer);

  return (
    <OfferBox
      header={<UpgradeHeader />}
      className={classes.root}
      offerMetaData={{ loanAmount: offer.loanAmount }}
      chooseLink={`${offer.redirectUrl}?utm_medium=web`}
    >
      <Box display="flex" flex={1}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Box display="flex" justifyContent="space-between" my={2} flex={1}>
            <Box display="flex" alignSelf="center">
              <CardMedia
                className={classes.lenderLogo}
                component="img"
                image="https://hearth.imgix.net/creditcard/upgrade_credit_card.png?auto=compress"
                alt="lender logo"
              />
            </Box>
            {upgradeData.map(infoItem => (
              <OfferInfoItem
                key={infoItem.header}
                infoItem={infoItem}
                className={classes.offerInfoItem}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <div className={classes.mobileContainer}>
            <Box className={classes.mobileInfoRow}>
              <OfferInfoItem infoItem={upgradeData[0]} className={classes.mobileItem} />
              <OfferInfoItem infoItem={upgradeData[1]} className={classes.mobileItem} />
            </Box>
            <Box className={classes.mobileInfoRow} my={1}>
              <OfferInfoItem infoItem={upgradeData[2]} className={classes.mobileItem} />
              <OfferInfoItem infoItem={upgradeData[3]} className={classes.mobileItem} />
            </Box>
          </div>
        </Box>
      </Box>
    </OfferBox>
  );
};

export default UpgradeCreditCardBox;
