import uuid from 'uuid/v1';
import { Severity } from '@sentry/react';

import { analytics } from 'common/services';
import { isDebug, isProduction, isTest } from 'config/app';

export enum LogGroup {
  EVENT = 'EVENT',
  CONSOLE = 'CONSOLE',
  OTHER = 'OTHER',
}

export enum LogLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export class Log {
  public group: LogGroup;
  public level: LogLevel;
  public message?: Error | string;
  public params: unknown[] | undefined;
  public key: string;
  public timestamp: number;

  public constructor(
    level: LogLevel,
    group: LogGroup,
    message?: Error | string,
    params?: unknown[],
  ) {
    this.group = group;
    this.level = level;
    this.message = message;
    this.params = params;
    this.key = uuid();
    this.timestamp = Date.now();
  }
}

const logs: Log[] = [];

function addLog(log: Log): void {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable no-console */
  if (console.tron) {
    switch (log.level) {
    case LogLevel.WARNING:
      console.tron.warn!(`[${log.group}] ${log.message}`);
      break;
    case LogLevel.ERROR:
      console.tron.error!(`[${log.group}] ${log.message}`, {});
      break;
    default:
      console.tron.log!(`[${log.group}] ${log.message}`);
      break;
    }
  }
  /* eslint-enable no-console */
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
  logs.push(log);
}

export const hookConsoleProxies = (): void => {
  if (!isDebug && !isTest) return;

  /* eslint-disable no-console */
  const origConsoleInfo = console.info;
  const origConsoleWarn = console.warn;
  const origConsoleError = console.error;

  console.info = (message?: Error | string, ...args: unknown[]) => {
    addLog(new Log(LogLevel.INFO, LogGroup.CONSOLE, message, args));
    origConsoleInfo.apply(console, [message, ...args]);
  };
  console.warn = (message?: Error | string, ...args: unknown[]) => {
    addLog(new Log(LogLevel.WARNING, LogGroup.CONSOLE, message, args));
    origConsoleWarn.apply(console, [message, ...args]);
  };
  console.error = (message?: Error | string, ...args: unknown[]) => {
    addLog(new Log(LogLevel.ERROR, LogGroup.CONSOLE, message, args));
    origConsoleError.apply(console, [message, ...args]);
  };
  /* eslint-enable no-console */
};

export default {
  info: (group: LogGroup, message:string, params?: unknown[]): void => {
    if (isProduction) return;
    addLog(new Log(LogLevel.INFO, group, message, params));
  },
  warn: (group: LogGroup, message:string, params?: unknown[]): void => {
    if (!isDebug && !isTest) {
      analytics.trackMessage(message, Severity.Warning);
    }
    addLog(new Log(LogLevel.WARNING, group, message, params));
  },
  error: (group: LogGroup, message:string, params?: unknown[]): void => {
    if (!isDebug && !isTest) {
      analytics.trackMessage(message, Severity.Warning);
      return;
    }
    /* eslint-disable no-console */
    if (console.tron != null) {
      console.tron.display({
        name: message,
        value: {
          group,
          extra: params,
        },
      });
    }
    /* eslint-enable no-console */
    addLog(new Log(LogLevel.ERROR, group, message, params));
    // @TODO: uncomment to throw development errors
    // throw new Error(message);
  },
  // Interval callback to avoid setState on render errors.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  watch: (listener: (arg0: Log[]) => void) => setInterval(() => listener(logs), 1000),
  clear: () => {
    logs.length = 0;
  },
  getLogs: () => [...logs],
};
