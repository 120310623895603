import { useMemo } from 'react';
import {
  Drawer,
  Typography,
  Toolbar,
  Box,
  Divider,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useHistory, Prompt } from 'react-router-dom';
import { useFormikContext } from 'formik';
import {
  object,
  SchemaOf,
  string,
  boolean,
  mixed,
} from 'yup';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useBeforeUnload } from 'react-use';

import { Form, SubmitButton } from 'common/components/formik';
import { Link } from 'common/components/material-ui';
import CustomInvoice from 'contractor/modules/CustomInvoice';
import {
  Background,
  CustomizedInvoiceTemplateAttributes,
  InvoiceTemplateType,
  OrganizationAttributes,
  OrganizationTheme,
} from 'types';
import { SmallScreenFilter } from 'common/components/elements';

import {
  CustomizeInvoiceProvider,
  useCustomizeInvoice,
  useCustomizeInvoiceActions,
  getTemplateValues,
  fakeInvoiceData,
} from './useCustomizeInvoice';
import {
  SignatureModule,
  WebsiteModule,
  SocialMediaModule,
  ColorPicker,
  InvoiceLogoUpload,
  TemplatePicker,
} from './components';
import InvoiceSavedModal from './InvoiceSavedModal';

const customizationsDrawerWidth = 464;

const useStyles = makeStyles(theme => ({
  invoicePreviewContainer: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    paddingRight: customizationsDrawerWidth,
  },
  invoicePreview: {
    width: 640,
    minWidth: 640,
    height: 900,
    flexShrink: 0,
    alignSelf: 'center',
    margin: 24,
    resize: 'none',
  },
  drawer: {
    width: customizationsDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: 4,
    width: customizationsDrawerWidth,
  },
  drawerContent: {
    overflow: 'auto',
    padding: '32px 32px 200px',
  },
  saveSection: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    width: customizationsDrawerWidth,
    padding: '40px 32px',
    zIndex: 100,
    borderTop: `2px solid ${theme.palette.common.basic300}`,
    boxShadow: '0px -4px 3px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.common.basic100,
  },
}));

export type InvoiceValidationType = CustomizedInvoiceTemplateAttributes & {
  organization?: Pick<OrganizationAttributes, 'facebookUrl' | 'twitterUrl' | 'linkedinUrl' | 'instagramUrl'>;
}

const validation: SchemaOf<InvoiceValidationType> = object({
  templateType: mixed().oneOf(Object.values(InvoiceTemplateType)).defined(),
  primaryColor: mixed().oneOf(Object.values(OrganizationTheme)).defined(),
  background: mixed().oneOf(Object.values(Background)).default(Background.SOLID),
  socialLinks: object({
    facebookUrl: boolean().nullable(),
    instagramUrl: boolean().nullable(),
    linkedinUrl: boolean().nullable(),
    twitterUrl: boolean().nullable(),
  }),
  logo: boolean().notRequired().default(false),
  website: boolean().defined(),
  signatureMessage: mixed(),
  organization: object({
    facebookUrl: string().nullable(),
    instagramUrl: string().nullable(),
    linkedinUrl: string().nullable(),
    twitterUrl: string().nullable(),
  }),
}).defined();

const InvoicePreview = () => {
  const { values } = useFormikContext<CustomizedInvoiceTemplateAttributes>();
  const { activeTemplate, organization } = useCustomizeInvoice();

  const block = useMemo(() => {
    if (!activeTemplate) return false;

    const currentValues = getTemplateValues(activeTemplate);
    const formValues = getTemplateValues(values);
    return !isEqual(formValues, currentValues);
  }, [activeTemplate, values]);

  useBeforeUnload(block, 'Leave without saving? Your progress will be discarded.');

  if (organization == null) return <Skeleton variant="rectangular" height="100%" />;

  return (
    <>
      <Prompt
        when={block}
        message="Leave without saving? Your progress will be discarded."
      />
      <CustomInvoice
        settings={values}
        organization={organization}
        invoice={fakeInvoiceData}
        attachments={[]}
      />
    </>
  );
};

// various entry points to this page, where "URL" refers to someone
// going directly to the /tools/customize-invoice url
type EntryPoint = 'MarketingTools' | 'Dashboard' | 'RequestFlow' | 'URL';

const CustomizeInvoice = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory<{ from: EntryPoint }>();
  const {
    activeTemplate,
    organization,
    uiStates: {
      invoiceSavedModalOpen,
      setInvoiceSavedModalOpen,
    },
  } = useCustomizeInvoice();
  const { generateCustomInvoiceTemplate } = useCustomizeInvoiceActions();

  if (!activeTemplate || !organization) return <Skeleton variant="rectangular" height="100%" />;

  return (
    <>
      {invoiceSavedModalOpen &&
        <InvoiceSavedModal
          open={invoiceSavedModalOpen}
          onClose={() => setInvoiceSavedModalOpen(false)}
        />
      }
      <Form<InvoiceValidationType>
        initialValues={{
          templateType: activeTemplate.templateType,
          primaryColor: activeTemplate.primaryColor,
          background: activeTemplate.background,
          socialLinks: {
            facebookUrl: activeTemplate.socialLinks?.facebookUrl,
            instagramUrl: activeTemplate.socialLinks?.instagramUrl,
            linkedinUrl: activeTemplate.socialLinks?.linkedinUrl,
            twitterUrl: activeTemplate.socialLinks?.twitterUrl,
          },
          logo: activeTemplate.logo,
          website: activeTemplate.website,
          signatureMessage: activeTemplate.signatureMessage,
          organization: {
            facebookUrl: organization?.facebookUrl,
            instagramUrl: organization?.instagramUrl,
            linkedinUrl: organization?.linkedinUrl,
            twitterUrl: organization?.twitterUrl,
          },
        }}
        validationSchema={validation}
        onSubmit={values => generateCustomInvoiceTemplate(omit(values, 'organization'))}
      >
        <div className={classes.invoicePreviewContainer}>
          <div className={classes.invoicePreview}>
            <InvoicePreview />
          </div>
        </div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          <Toolbar />
          <div className={classes.drawerContent}>
            <TemplatePicker />
            <Box mb={4} />
            <Divider variant="fullWidth" />
            <Box mb={2} />
            <InvoiceLogoUpload />
            <Box mb={2} />
            <ColorPicker />
            <Box mb={5} />
            <Divider variant="fullWidth" />
            <Box my={2} color="common.primary500">
              <Typography variant="h3" color="inherit">
                Add-ons
              </Typography>
            </Box>
            <SocialMediaModule />
            <Box mb={5} />
            <WebsiteModule />
            <Box mb={5} />
            <SignatureModule />
          </div>
          <div className={classes.saveSection}>
            <Typography variant="subtitle2" color="textPrimary">
              Want to change your company info?
            </Typography>
            <Link
              variant="subtitle2"
              color="primary"
              onClick={() => history.push('/dashboard/profile')}
            >
              Go to Profile
            </Link>
            <Box mt={2} />
            <SubmitButton
              disableOnInitialValues={false}
              variant="contained"
              buttonType="common.primary"
              fullWidth
            >
              Save
            </SubmitButton>
          </div>
        </Drawer>
      </Form>
    </>
  );
};

export default (): JSX.Element => (
  <SmallScreenFilter>
    <CustomizeInvoiceProvider>
      <CustomizeInvoice />
    </CustomizeInvoiceProvider>
  </SmallScreenFilter>
);
