import { useRef, useMemo } from 'react';
import cx from 'classnames';
import { Slider as MaterialSlider, Tooltip, ValueLabelProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import { SliderProps } from '@mui/material/Slider';
import { TooltipProps } from '@mui/material/Tooltip';

const useSliderStyles = makeStyles(theme => ({
  thumb: {
    backgroundColor: theme.palette.common.basic1100,
    border: `4px solid ${theme.palette.primary.dark}`,
    height: 20,
    width: 20,
    marginLeft: -10,
    marginTop: -8,
    '&$focusVisible,&:hover': {
      boxShadow: `0px 0px 0px 6px ${alpha(theme.palette.primary.dark, 0.16)}`,
      '@media (hover: none)': {
        boxShadow: 'none',
      },
    },
    '&$active': {
      boxShadow: `0px 0px 0px 9px ${alpha(theme.palette.primary.dark, 0.16)}`,
    },
  },
  markActive: {
    opacity: 1,
  },
  mark: {
    height: 12,
    marginTop: -5,
    backgroundColor: theme.palette.primary.dark,
  },
  markLabel: {
    ...theme.typography.caption,
    fontFamily: 'Lora',
    color: theme.palette.text.primary,
    '&:nth-of-type(4)': {
      transform: 'translateX(10%)',
    },
    '&:nth-last-of-type(2)': {
      transform: 'translateX(-110%)',
    },
  },
  valueLabel: {},
  active: {},
  track: {
    opacity: 0,
  },
  rail: {
    backgroundColor: theme.palette.primary.dark,
    opacity: 1,
  },
}));

const useValueLabelStyles = makeStyles(theme => ({
  arrow: {
    position: 'absolute',
    margin: '0 auto',
    display: 'block',
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: `15px solid ${theme.palette.common.success500}`,
    borderTop: `15px solid ${theme.palette.common.success500}`,
    left: 'calc(50% - 15px)',
  },
  arrowTop: {
    top: 0,
    borderTopWidth: 0,
  },
  arrowBottom: {
    bottom: 0,
    borderBottomWidth: 0,
  },
  tooltip: {
    marginTop: 14,
    backgroundColor: theme.palette.common.success500,
    color: theme.palette.common.basic100,
    minWidth: 180,
    borderRadius: 4,
    boxShadow: theme.shadows[1],
    textAlign: 'center',
  },
}));

type LabelProps = Omit<ValueLabelProps, 'title'> & Partial<TooltipProps>;

const Label = ({
  children,
  open,
  value,
  placement = 'top',
  ...otherProps
}: LabelProps): JSX.Element => {
  const classes = useValueLabelStyles();
  const arrowRef = useRef(null);

  return (
    <Tooltip
      placement={placement}
      classes={classes}
      PopperProps={{
        disablePortal: true,
        modifiers: [
          {
            name: 'flip',
            options: {
              enabled: false,
            },
          },
          {
            name: 'arrow',
            options: {
              enabled: true,
              element: arrowRef.current,
            },
          },
        ],
      }}
      open={open}
      title={
        <>
          {value}
          <span
            className={
              cx(classes.arrow, {
                [classes.arrowTop]: placement === 'bottom',
                [classes.arrowBottom]: placement === 'top',
              })
            }
            ref={arrowRef}
          />
        </>
      }
      disableHoverListener
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};

export type Props = Omit<SliderProps, 'onChange'> & {
  tooltipProps?: Partial<TooltipProps>;
  onChange: (event: Event, value: number | number[]) => void;
};

const Slider = ({ tooltipProps = {}, ...otherProps }: Props): JSX.Element => {
  const classes = useSliderStyles();
  const ValueLabel = useMemo(
    () => (props: ValueLabelProps) => <Label {...props} {...tooltipProps} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(tooltipProps)],
  );

  return (
    <MaterialSlider
      classes={classes}
      components={{ ValueLabel }}
      {...otherProps}
    />
  );
};

export default Slider;
