import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { Dialog, Button } from 'common/components/material-ui';

type Props = {
  onClose: () => void;
  open: boolean;
}

const PaymentsSetupDialog = ({
  onClose,
  open,
}: Props): JSX.Element => (
  <Dialog
    maxWidth="md"
    open={open}
    onClose={onClose}
    titleBgColor="primary500"
    dialogTitle={
      <Box
        color="common.basic100"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Typography variant="h2">
          Finish Hearth Pay Setup
        </Typography>
      </Box>
    }
  >
    <Box my={5}>
      <Typography variant="h4">
        To access this feature, please finish setting up your payments account.
      </Typography>
      <Box mt={4} display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          buttonType="primary"
          onClick={onClose}
        >
          Remind me later
        </Button>
        <Box m={1} />
        <Button
          variant="contained"
          buttonType="primary"
          component={Link}
          to="/dashboard/payments"
        >
          Finish setup
        </Button>
      </Box>
    </Box>
  </Dialog>
);

export default PaymentsSetupDialog;
