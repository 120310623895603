// eslint-disable-next-line import/prefer-default-export
export function sanitizePhone(phoneNumber: string) {
  let phoneNumberValue: string;

  if (phoneNumber) {
    // strip phone to return just digits
    const phoneStripped = phoneNumber.replace(/\D/g, '');

    // display with format ###-###-####
    phoneNumberValue = `${phoneStripped.substr(0, 3)}`;
    if (phoneStripped.length > 3) {
      phoneNumberValue += `-${phoneStripped.substr(3, 3)}`;
    }
    if (phoneStripped.length > 6) {
      phoneNumberValue += `-${phoneStripped.substr(6, 4)}`;
    }
  } else {
    phoneNumberValue = phoneNumber;
  }
  return phoneNumberValue;
}
