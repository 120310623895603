import React, { useState, useCallback } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { CreateOutlined } from '@mui/icons-material';
import { useField } from 'formik';
import { sentenceCase } from 'change-case';

import { Button, Input } from 'common/components/material-ui';

type Props = {
  open: boolean;
  setOpen: (arg0: boolean) => void;
  name: string;
  placeHolder?: string;
  prefix?: string;
}

const EditInputField = ({
  open,
  setOpen,
  name,
  placeHolder,
  prefix,
}: Props): JSX.Element => {
  const [{ value }, , { setValue }] = useField<string>(name);
  const [tempValue, setTempValue] = useState(value);

  const onCancel = useCallback(() => {
    setTempValue(value);
    setOpen(false);
  }, [tempValue, setValue, setOpen]);

  const onConfirm = useCallback(() => {
    setValue(tempValue);
    setOpen(false);
  }, [tempValue, setValue, setOpen]);

  let displayValue = prefix ? `${prefix} ${value}` : value;
  if (placeHolder && (value == null || value === '')) {
    displayValue = placeHolder;
  }
  if (open) {
    return (
      <Box px={2} pt={3} pb={2}>
        <Input
          name={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTempValue(e.target.value)}
          label={sentenceCase(name)}
          value={tempValue}
          fullWidth
        />
        <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={onCancel}
          >
            <Typography variant="subtitle2">
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            buttonType="common.primary"
            onClick={onConfirm}
            disabled={value === tempValue}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      px={3}
      py={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"

    >
      <Typography variant="subtitle2">
        {displayValue}
      </Typography>
      <IconButton
        edge="end"
        size="small"
        onClick={() => setOpen(true)}
      >
        <Box color="common.primary500">
          <CreateOutlined fontSize="large" />
        </Box>
      </IconButton>
    </Box>
  );
};

export default EditInputField;
