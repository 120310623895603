import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Severity } from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { DisclosureFooter, Footer } from 'common/components/widgets';
import { Link } from 'common/components/material-ui';
import { Col, Row } from 'common/components/layouts';
import { useUrl, useTerms, useVerticalState } from 'common/hooks';
import { analytics } from 'common/services';

import './NotFoundPage.scss';

type LearnMoreProps = {
  href: string;
}

const LearnMoreButton = ({ href }: LearnMoreProps) => (
  <Link
    component="a"
    className="btn btn-default"
    href={href}
  >
    Learn more
  </Link>
);

const NotFoundPage = (): JSX.Element => {
  const { pathname } = useLocation();
  const { marketingUrl } = useUrl();
  const [{ brandMetadata, verticalMetadata }] = useVerticalState();
  const { getDisclosures } = useTerms();
  const disclosures = getDisclosures();

  useEffect(() => {
    const path = (pathname.length > 1) ? pathname.replace(/\/+$/, '') : pathname;

    analytics.trackMessage('/404', Severity.Info, {
      path,
    });
  }, [pathname]);

  const { support: { email } } = verticalMetadata.dashboardHome;

  return (
    <div className="not-found-page">
      <Helmet>
        <title>Nail the financials for your renovation | {brandMetadata.label}</title>
        <meta
          name="description"
          content={`${brandMetadata.label} helps you make financially smart decisions. From giving
            you advice to finding you financing, we’re with you at every step.`}
        />
      </Helmet>
      <section className="container-fluid theme-dark not-found-section not-found-top">
        <div className="container">
          <Row>
            <Col xs={12}>
              <h1>We’re sorry.</h1>
            </Col>
            <Col xs={12}>
              <h3>We were unable to find the page you were looking for.</h3>
            </Col>
            <Col xs={12} sm={4} md={3}>
              <Link
                component="a"
                className="btn btn-block btn-lg btn-primary"
                href={marketingUrl('/')}
              >
                Visit our homepage
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      <section className="container not-found-section info-section info-first">
        <Row>
          <Col xs={12}>
            <h3>Looking for financing?</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} smOffset={2} sm={4}>
            <img
              src="https://hearth.imgix.net/icons/cash_icon.svg?auto=compress"
              alt="personal-loan"
              className="not-found-image"
            />
            <p>Personal loans</p>
            <LearnMoreButton href={marketingUrl('/loans/')} />
          </Col>
          <Col xs={12} sm={4}>
            <img
              src="https://hearth.imgix.net/icons/credit_card.svg?auto=compress"
              alt="credit-card"
              className="not-found-image"
            />
            <p>Credit cards</p>
            <LearnMoreButton href={marketingUrl('/home-improvement-credit-cards/')} />
          </Col>
        </Row>
      </section>
      <section className="container not-found-section info-section info-last">
        <Row>
          <Col xs={12}>
            <h3>Need help?</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p>
              Email <a href={`mailto:${email}`}><u>{email}</u></a>
              &nbsp;and we will get in touch with you.
            </p>
          </Col>
        </Row>
      </section>
      <Footer
        breadcrumbs={[{ href: '/', text: brandMetadata.label }]}
      />
      <DisclosureFooter
        disclosures={[disclosures.notALender, disclosures.broker, disclosures.trademark]}
      />
    </div>
  );
};

export default NotFoundPage;
