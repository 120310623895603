import { SvgIconProps } from '../Icon';

const MoneyStubIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <path
      d="M9.5 11.5H0.5V1L2 2L3.5 1L5 2L6.5 1L8 2L9.5 1V11.5Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M3.524 8.5C3.637 9.0765 4.1355 9.5 4.75 9.5H5.25C5.95 9.5 6.5 8.95 6.5 8.25C6.5 6.5 3.5 7.5 3.5 5.75C3.5 5.05 4.05 4.5 4.75 4.5H5.25C5.864 4.5 6.3625 4.923 6.476 5.4995"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M5 4V10"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default MoneyStubIcon;
