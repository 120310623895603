import { useState } from 'react';
import { gql, useReactiveVar } from '@apollo/client';
import { Check } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Typography,
  MenuItem,
  Menu,
  Theme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import * as localStorage from 'common/services/localStorage';
import {
  useClientViewMenuQuery,
  useSetClientViewViewBySeenMutation,
  PaymentsRole,
} from 'types';
import { Button, ButtonBase, Tooltip } from 'common/components/material-ui';
import userFeatureFlags from 'common/graphql/featureFlags';

export enum ViewMode {
  TEAM,
  INDIVIDUAL,
  INVOICE,
  CONTRACT
}

type StyleProps = {
  color: Color;
  backgroundColor: Color;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  clientViewButton: ({ color }) => ({
    color: theme.palette.common[color],
  }),
  checkBox: {
    width: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menu: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
  background: ({ backgroundColor }) => ({
    backgroundColor: theme.palette.common[backgroundColor],
    padding: '10px 16px',
    borderRadius: 8,
  }),
}));

type Props = {
  buttonText: string;
  color: Color;
  backgroundColor: Color;
  onSelectMyClients: () => void;
  onSelectTeamClients?: () => void;
  onSelectInvoices?: () => void;
  onSelectContracts?: () => void;
  viewMode: ViewMode;
}

const MenuItems = ({
  buttonText,
  color,
  backgroundColor,
  onSelectMyClients,
  onSelectTeamClients,
  onSelectInvoices,
  onSelectContracts,
  viewMode,
}: Props): JSX.Element | null => {
  const { contracts } = useReactiveVar(userFeatureFlags);
  const classes = useStyles({ color, backgroundColor });
  const [viewModeAnchorEl, setViewModeAnchorEl] = useState<HTMLElement>();
  const { data } = useClientViewMenuQuery();
  const [setViewBySeen] = useSetClientViewViewBySeenMutation();

  const contractor = data?.contractor;
  const stripeConnectAccount = data?.organization?.stripeConnectAccount;
  const contractServiceAccount = data?.organization?.contractServiceAccount;
  const hasPaymentsAccess = stripeConnectAccount?.fullAccess;
  if (!contractor) {
    return null;
  }

  return (
    <Box alignItems="center" display="flex" color="common.basic100">
      {(
        contractor.admin ||
        (contractor.paymentsRole !== PaymentsRole.BLOCKED && hasPaymentsAccess)
      ) ?
        <Tooltip
          title={
            <Typography variant="body1">
              <Box color="common.basic100">
                Click here to change your view from Clients to Invoices
              </Box>
            </Typography>
          }
          isOpen={
            !contractor?.customSettings?.clientViewViewByTooltipSeen &&
              hasPaymentsAccess
          }
          placement="bottom"
        >
          <Box>
            <Typography variant="subtitle1">
              <Box color="common.basic100">
                View:
              </Box>
            </Typography>
            <Box m={0.5} />
            <ButtonBase
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setViewModeAnchorEl(event.currentTarget);
                if (!contractor?.customSettings?.clientViewViewByTooltipSeen) {
                  setViewBySeen({
                    variables: {
                      customSettings: {
                        clientViewViewByTooltipSeen: true,
                      },
                    },
                  });
                }
              }}
              className={classes.background}
            >
              <Typography variant="h2" color="secondary">
                {buttonText}
              </Typography>
              <ArrowDropDownIcon fontSize="large" alignmentBaseline="central" />
            </ButtonBase>
          </Box>
        </Tooltip> :
        <Typography variant="h2" color="secondary">
          {buttonText}
        </Typography>
      }
      <Menu
        id="view-mode-menu"
        anchorEl={viewModeAnchorEl}
        keepMounted
        open={Boolean(viewModeAnchorEl)}
        onClose={() => setViewModeAnchorEl(undefined)}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.menu }}
        style={{ marginLeft: 112 }}
      >
        <MenuItem>
          <Button
            onClick={() => {
              localStorage.setItem('clientView', 'my_clients');
              onSelectMyClients();
              setViewModeAnchorEl(undefined);
            }}
            variant="text"
            className={classes.clientViewButton}
          >
            <Box className={classes.checkBox}>
              {viewMode === ViewMode.INDIVIDUAL &&
                <Check style={{ fontSize: 20 }} />
              }
            </Box>
            My Clients
          </Button>
        </MenuItem>
        {contractor.admin &&
          <MenuItem>
            <Button
              onClick={() => {
                localStorage.setItem('clientView', 'all_clients');
                if (onSelectTeamClients) {
                  onSelectTeamClients();
                }
                setViewModeAnchorEl(undefined);
              }}
              variant="text"
              className={classes.clientViewButton}
            >
              <Box className={classes.checkBox}>
                {viewMode === ViewMode.TEAM &&
                  <Check style={{ fontSize: 20 }} />
                }
              </Box>
              All Clients
            </Button>
          </MenuItem>
        }
        {hasPaymentsAccess && contractor.paymentsRole !== PaymentsRole.BLOCKED &&
          <MenuItem>
            <Button
              onClick={() => {
                localStorage.setItem('clientView', 'all_invoices');
                if (onSelectInvoices) {
                  onSelectInvoices();
                }
                setViewModeAnchorEl(undefined);
              }}
              variant="text"
              className={classes.clientViewButton}
            >
              <Box className={classes.checkBox}>
                {viewMode === ViewMode.INVOICE &&
                  <Check style={{ fontSize: 20 }} />
                }
              </Box>
              All Invoices
            </Button>
          </MenuItem>
        }
        {contracts && contractServiceAccount?.termsAgreedAt &&
          <MenuItem>
            <Button
              onClick={() => {
                localStorage.setItem('clientView', 'contracts');
                if (onSelectContracts) {
                  onSelectContracts();
                }
                setViewModeAnchorEl(undefined);
              }}
              variant="text"
              className={classes.clientViewButton}
            >
              <Box className={classes.checkBox}>
                {viewMode === ViewMode.CONTRACT &&
                  <Check style={{ fontSize: 20 }} />
                }
              </Box>
              All Contracts
            </Button>
          </MenuItem>
        }
      </Menu>
    </Box>
  );
};

MenuItems.CLIENT_VIEW_MENU_QUERY = gql`
  query ClientViewMenu {
    contractor {
      id
      admin
      paymentsRole
      customSettings {
        clientViewViewByTooltipSeen
      }
    }
    organization {
      id
      stripeConnectAccount {
        id
        fullAccess
      }
      contractServiceAccount {
        id
        termsAgreedAt
      }
    }
  }
`;

MenuItems.UPDATE_CLIENT_VIEW_TOOLTIP = gql`
  mutation SetClientViewViewBySeen($customSettings: CustomContractorSettingsAttributes!) {
    upsertContractor(customSettings: $customSettings) {
      ... on UpsertContractorSuccess {
        contractor {
          id
          customSettings {
            clientViewViewByTooltipSeen
          }
        }
      }
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default MenuItems;
