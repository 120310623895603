import {
  Card,
  Typography,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Box,
} from '@mui/material';

import { Button } from 'common/components/material-ui';
import { currencyFormat } from 'common/utils/stringFormatters';

type Props = {
  recommendedAmount: number;
  onClick: () => void;
};

const AdjustAmountCard = ({ recommendedAmount, onClick }: Props): JSX.Element => (
  <Card>
    <CardActionArea>
      <CardMedia
        component="img"
        alt="corporate"
        image="https://hearth.imgix.net/nooffers/corporate.jpg"
        height="180"
        title="Corporate"
      />
    </CardActionArea>
    <CardContent>
      <Box px={3} pt={1}>
        <Typography variant="h3" gutterBottom>
          Adjust the amount you’re asking for
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          Debt to income ratio is a significant factor in pre-qualifying for payment options.
          Consider asking for a lower amount.
        </Typography>
        <Box mt={2} bgcolor="common.success500" boxShadow={1} borderRadius="2px" p={1.5}>
          <Typography variant="caption" align="center">
            <Box lineHeight={1.3} color="common.basic100" fontWeight="fontWeightMedium">
              Homeowners similar to you who ask for {currencyFormat(recommendedAmount)} have
              higher likelihood of pre-qualification*
            </Box>
          </Typography>
        </Box>
      </Box>
    </CardContent>
    <CardActions disableSpacing>
      <Box px={3} mb={1} width="100%" display="flex" justifyContent="flex-end">
        <Button
          buttonType="primary"
          onClick={onClick}
          variant="contained"
        >
          Change amount
        </Button>
      </Box>
    </CardActions>
    <Box bgcolor="common.basic300" px={3} py={2}>
      <Typography variant="caption">
        <Box lineHeight={1.1} color="common.basic900">
          *Likelihood of pre-qualification is calculated based on historical pre-qualification
          rates seen by past Hearth homeowners
        </Box>
      </Typography>
    </Box>
  </Card>
);

export default AdjustAmountCard;
