import { stringify } from 'query-string';

import useVerticalState from './useVerticalState';

type Options = { devHostOverride?: string };
type Query = Record<string, unknown>;

const buildUrl = (host: string | undefined, path: string, query: Query = {}): string => {
  if (path.indexOf('/') !== 0) {
    throw new Error('path must start with a /');
  }
  const queryString = stringify(query);
  return `${host}${path}${queryString && '?'}${queryString}`;
};

const apiUrl = (path: string, query: Query = {}): string => {
  if (path.indexOf('/api/v2/') !== 0) {
    throw new Error('path must start with a /api/v2');
  }
  return buildUrl(process.env.REACT_APP_API_HOST, path, query);
};

const appUrl = (
  path: string,
  query: Query = {},
  options: Options = {},
): string =>
  buildUrl(
    (window.location.hostname === 'localhost' ?
      (options.devHostOverride || 'http://localhost:4002') :
      `https://${window.location.host}`
    ),
    path,
    query,
  );

const marketingUrl = (host: string, path: string, query = {}): string =>
  buildUrl(host, path, query);

export const useUrl = (): {
  trainingVideoUrl: string;
  popPageVideoUrl: string;
  signupHost: string;
  apiUrl(path: string, query?: Query): string;
  appUrl(path: string, query?: Query, options?: Options): string;
  marketingUrl(path: string, query?: Query): string;
} => {
  const [{ verticalMetadata }] = useVerticalState();
  const {
    hostnames: {
      marketingHost,
      signupHost,
    },
    urls: {
      trainingVideoUrl,
      popPageVideoUrl,
    },
  } = verticalMetadata;

  return {
    trainingVideoUrl,
    popPageVideoUrl,
    signupHost,
    apiUrl,
    appUrl,
    marketingUrl: (path: string, query?: Query) => marketingUrl(marketingHost, path, query),
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export default useUrl;
