import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  fromPromise,
  Observable,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { OperationDefinitionNode } from 'graphql';
import { relayStylePagination } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { RestLink } from 'apollo-link-rest';

import store from 'stores';
import { hearthUidSelector, deviceIdSelector } from 'common/selectors/user';
import { analytics } from 'common/services';
import * as localStorage from 'common/services/localStorage';
import { requestMutex } from 'common/api/client';

import introspectionResult from './introspection-result';
import resolvers, { resolverDefs } from './resolvers';
import { TypedTypePolicies } from './apolloHelpers';
import { featureFlagsMergePolicy } from './featureFlags';
import { isServerError } from './utils';

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      homeownerSearch: relayStylePagination(['admin', 'type', 'orderBy']),
      featureFlags: featureFlagsMergePolicy,
    },
  },
};

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies,
});

enum HearthClientTypes {
  WEB_APP = 'WEB_APP',
  MOBILE_APP = 'MOBILE_APP',
}

type HeaderType = {
  'X-Hearth-Uid'?: string;
  'X-Device-Id'?: string;
  'X-Hearth-Client-Type'?: HearthClientTypes;
  'Authorization'?: string;
}

const setAuthorizationLink = setContext((_request, _previousContext) => {
  const headers: HeaderType = {
    'X-Hearth-Client-Type': HearthClientTypes.WEB_APP,
  };
  const state = store.getState();

  const hearthUid = hearthUidSelector(state);
  if (hearthUid) {
    headers['X-Hearth-Uid'] = hearthUid;
  }
  const deviceId = deviceIdSelector(state);
  if (deviceId) {
    headers['X-Device-Id'] = deviceId;
  }

  if (localStorage.hasStorage() && localStorage.getItem('accessToken')) {
    headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      // @TODO: investigate best practices in accordance with apollo-link-sentry.
      // apollo-link-sentry adds important context to a Sentry scope. Once it has done
      // so, we need to actually track the exception. There is still no clear way
      // to use the library in parallel with an error link.
      analytics.trackException(err);
    });
  }
  if (networkError) {
    analytics.trackApolloNetworkException(networkError);
  }
});

/**
 * A custom link to refresh oauth tokens when requests fail
 *
 * Based on: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 */
const oauthLink = onError(({
  networkError,
  operation,
  forward,
}) => {
  if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
    let forward$: Observable<unknown>;

    if (!requestMutex.mutex.isLocked()) {
      forward$ = fromPromise(
        requestMutex.refreshAccessToken(),
      ).filter(value => Boolean(value));
    }

    forward$ = fromPromise(
      new Promise((resolve, reject) => {
        requestMutex.pushUnresolvedRequest({ resolve, reject });
      }),
    );

    return forward$.flatMap(() => forward(operation));
  }
  return forward(operation);
});

const loggerLink = new ApolloLink((operation, forward) => (
  forward(operation).map((result) => {
    const definition = operation.query.definitions[0] as OperationDefinitionNode;

    if (process.env.REACT_APP_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.tron.display?.({
        name: `${definition.operation}.${operation.operationName}`,
        value: {
          operation,
          result,
          definition,
        },
      });
    }
    return result;
  })
));

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
});

const restLink = new RestLink({
  endpoints: {
    contractGenerator: 'https://9t51a25p0l.execute-api.us-west-2.amazonaws.com/production',
  },
  responseTransformer: async response => response.json(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    loggerLink,
    sentryLink,
    errorLink,
    restLink,
    oauthLink,
    setAuthorizationLink,
    new HttpLink({
      uri: `${process.env.REACT_APP_API_HOST}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache,
  resolvers,
  typeDefs: resolverDefs,
});

export default client;
