import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FeedbackBox } from './components';
import useReporting from './useReporting';

const HearthPayFeedback = (): JSX.Element => {
  const history = useHistory();
  const {
    homeownerPaymentsFeedbackMetrics,
    homeownerPaymentsFeedback,
    hearthPayFeedbackUrl,
  } = useReporting();

  const onRowClick = useCallback((homeownerId: string) => {
    history.push(
      `${hearthPayFeedbackUrl}/${homeownerId}`, {
        background: {
          pathname: `${hearthPayFeedbackUrl}`,
        },
      },
    );
  }, [hearthPayFeedbackUrl, history]);

  return (
    <FeedbackBox
      title="Hearth Pay Customer Feedback"
      tooltipText="We sent a survey to your customers who recently
        paid digitally through Hearth Pay, asking them to rate
        their experience. We've aggregated that feedback in this dashboard."
      contractorRating={homeownerPaymentsFeedbackMetrics?.avgContractorSatisfaction}
      contractorRatingDifference={
        homeownerPaymentsFeedbackMetrics?.avgContractorSatisfactionDifference
      }
      hearthRating={homeownerPaymentsFeedbackMetrics?.avgHearthSatisfaction}
      hearthRatingDifference={homeownerPaymentsFeedbackMetrics?.avgHearthSatisfactionDifference}
      data={homeownerPaymentsFeedback}
      onRowClick={onRowClick}
      feedbackType="hearth_pay"
    />
  );
};

export default HearthPayFeedback;
