import moment from 'moment';

export function percentFormat(num: number | string, fixed = 0): string {
  return `${(Number(num) * 100).toFixed(fixed)}%`;
}

export function currencyFormat(num: number | string, fixed = 0): string {
  if (num != null) {
    let output = `$${Number(num).toFixed(fixed).replace(/-/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    if (num < 0) {
      output = `-${output}`;
    }
    return output;
  }
  return '';
}

/**
 * Given an amount in cents, will return it in currency format
 */
export const currencyFormatFromCents = (num: number | string, fixed = 0): string => {
  const number = typeof num === 'string' ? parseFloat(num) : num;
  return currencyFormat(number / 100, fixed);
};

export const currencyFormatFromCentsNegative = (num: number | string, fixed = 0): string => {
  const number = typeof num === 'string' ? parseFloat(num) : num;
  if (number < 0) {
    return `(${currencyFormatFromCents(-1 * number, fixed)})`;
  }
  return currencyFormatFromCents(number, fixed);
};

export function dateFormat(date: string, includeYear = false): string {
  return moment(date).format(includeYear ? 'MMM D, YYYY' : 'MMM D');
}

export function splitDecimal(num: number): { beforeDecimalFormat: string; decimalFormat: string } {
  const beforeDecimal = Math.trunc(num);
  const decimal = Number(((num - beforeDecimal) * 100).toFixed(0));
  const beforeDecimalFormat = beforeDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const decimalFormat = decimal < 10 ? `0${decimal.toString()}` : decimal.toString();
  return { beforeDecimalFormat, decimalFormat };
}

export const arrayToSentence = (arr: string[]): string =>
  [(arr).slice(0, -1).join(', '), arr.pop()]
    .filter(word => word !== '')
    .join(' and ');

/**
 * Get the ordinal formatting for a number. i.e. 1st, 2nd, 3rd, etc
 *
 * @param {number} n
 */
export const getOrdinalNum = (n: number) =>
  n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');

/**
 * Pluralize a word based on a count.
 *
 * Example: If you want to print "11 options", you can call `maybePluralize(11, 'option')`
 *
 * @param count
 * @param noun
 * @param suffix
 */
export const maybePluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

/**
 * Get initials for a name
 *
 * For example, John Alhadi => JA
 * @param name - string
 */
export const getInitials = (name: string) => name.split(' ').map((l: string) => l.charAt(0)).join('');
