import { useHistory } from 'react-router-dom';

import { Typography } from 'common/components/material-ui';
import { GetStartedOrganizationFragment } from 'types';

import { GetStartedListItem, GetStartedButton } from '..';
import { Task } from '../../useGetStarted';

type Props = {
  organization: GetStartedOrganizationFragment;
};

const InviteMembersTask = ({ organization }: Props): JSX.Element => {
  const history = useHistory();
  const { memberInvites: { totalCount } } = organization;

  return (
    <GetStartedListItem
      taskCompleted={totalCount > 0}
      taskName={Task.INVITE_MEMBERS}
    >
      <Typography variant="p2">
        Send invitations to others to join your Hearth account.
      </Typography>
      <GetStartedButton
        sx={{ mt: 2, py: 1 }}
        onClick={() => history.push('/dashboard/team/?source=get_started')}
        data-testid="invite-members-button"
      >
        Invite now
      </GetStartedButton>
    </GetStartedListItem>
  );
};

export default InviteMembersTask;
