import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { camelizeKeys } from 'humps';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { useAsync } from 'react-use';

import * as localStorage from 'common/services/localStorage';
import { Loader } from 'common/components/widgets';
import { isLoggedInSelector } from 'contractor/selectors/contractor';
import { logoutDispatcher } from 'contractor/actions/auth';
import { refreshData } from 'contractor/actions/contractor';

type Search = Partial<{
  accessToken: string;
}>;

const OauthAutologinPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const query = camelizeKeys(parse(search)) as Search;
  const isLoggedIn = useSelector(isLoggedInSelector);

  useAsync(async () => {
    if (isLoggedIn) {
      await dispatch(logoutDispatcher({}));
    }

    if (query.accessToken) {
      localStorage.setItem('accessToken', query.accessToken);
      await dispatch({
        type: 'Auth/LOGIN_SUCCESS',
        payload: {
          accessToken: query.accessToken,
        },
      });
      await dispatch(refreshData('network-only'));
      history.push('/dashboard');
    }
  }, []);

  if (!query.accessToken) {
    return (
      <Redirect to="/404" />
    );
  }

  return (
    <Loader />
  );
};

export default OauthAutologinPage;
