import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Select } from 'common/components/material-ui';

import { SortingOffersSortType, useSortingOffers } from '../useSortingOffers';

const useStyles = makeStyles(theme => ({
  sortSelect: {
    backgroundColor: theme.palette.common.basic100,
    paddingLeft: -8,
    [theme.breakpoints.up('sm')]: {
      height: 48,
    },
  },
  sortContainer: {
    maxWidth: 280,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },
}));

const sortingOptions = [
  {
    label: 'LOWEST APR',
    value: 'apr',
  },
  {
    label: 'LOWEST PAYMENT',
    value: 'monthlyPayment',
  },
  {
    label: 'LOWEST AMOUNT',
    value: 'loanAmount',
  },
  {
    label: 'SHORTEST TERM',
    value: 'loanTermInMonths',
  },
  {
    label: 'RECOMMENDED',
    value: 'score',
  },
];

const OffersSort = (): JSX.Element => {
  const classes = useStyles();
  const {
    processState: {
      sortType,
    },
    actions: {
      setSortType,
      setGrowIn,
    },
  } = useSortingOffers();

  return (
    <Box className={classes.sortContainer}>
      <Box flexGrow={1} mr={1}>
        <Typography variant="body2" color="textSecondary">
          SORT BY:
        </Typography>
      </Box>
      <Box maxWidth={200}>
        <Select
          label=""
          className={classes.sortSelect}
          name="sortOffersBy"
          items={sortingOptions}
          value={sortType}
          onChange={(e) => {
            setSortType(e.target.value as SortingOffersSortType);
            setTimeout(() => {
              setGrowIn(true);
            }, 800);
          }}
          onClose={() => {
            setGrowIn(false);
            setTimeout(() => {
              setGrowIn(true);
            }, 800);
          }}
          onBlur={() => setGrowIn(true)}
        />
      </Box>
    </Box>
  );
};

export default OffersSort;
