import {
  Fragment,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, useLazyQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { isFilled } from 'ts-is-present';
import moment from 'moment';

import { Note, Button } from 'common/components/material-ui';
import { currencyFormat } from 'common/utils/stringFormatters';
import { Loader } from 'common/components/widgets';
import { useUrl } from 'common/hooks';
import { FindPreviousOffersQuery } from 'types';
import constants from 'common/utils/constants';

import SeeOffersForm, { Values } from './SeeOffersForm';

// get all loan inquiries for this email, and then offers for all those inquiries
const FIND_PREVIOUS_OFFERS = gql`
  query FindPreviousOffers($email: String!, $date: ISO8601DateTime!) {
    qualifiedLoanInquiries(email: $email, birthday: $date) {
      nodes {
        id
        projectType
        hearthUid
        loanPrincipal
        createdAt
        contractor {
          id
          urlPath
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  inquiriesList: {
    margin: '24px 0',
    alignItems: 'center',
  },
});

type Props = {
  showStartNote?: boolean;
}

const SeeOffersNote = ({ showStartNote = true }: Props): JSX.Element => {
  const classes = useStyles();
  const [offersFound, setOffersFound] = useState(false);
  const { appUrl } = useUrl();

  const [fetchInfo, setFetchInfo] = useState<Pick<Values, 'email' | 'birthday'>>({
    email: undefined,
    birthday: undefined,
  });
  const [fetchPreviousOffers, { data, loading }] = useLazyQuery<FindPreviousOffersQuery>(
    FIND_PREVIOUS_OFFERS,
  );

  useEffect(() => {
    const { email, birthday } = fetchInfo;
    if (email != null && birthday != null) {
      fetchPreviousOffers({
        variables: {
          email,
          date: birthday,
        },
      });
      setOffersFound(true);
    }
  }, [data, fetchInfo, fetchPreviousOffers]);

  const onSubmit = (newValues: Pick<Values, 'email' | 'birthday'>) => setFetchInfo({ ...fetchInfo, ...newValues });

  const qualifiedInquiries = useMemo(() => (
    (data?.qualifiedLoanInquiries?.nodes != null && !loading) ?
      data?.qualifiedLoanInquiries?.nodes?.filter(isFilled) : null
  ), [data, loading]);

  const contractor = useMemo(() => {
    const contractorInquiry = qualifiedInquiries?.find(inquiry => inquiry.contractor != null);
    return contractorInquiry != null ? contractorInquiry.contractor : null;
  }, [qualifiedInquiries]);

  const buttonLinkProps = useMemo(() => (
    contractor == null ? { component: RouterLink, to: 'apply' } :
      { component: 'a', href: appUrl(`/partners/${contractor.urlPath}`) }
  ), [contractor, appUrl]);

  return (
    <>
      <Note
        subtitle="Welcome back."
        description="Enter your email address and birthdate below to go back to your offers page."
      >
        <SeeOffersForm
          onSubmit={onSubmit}
          loading={false}
        />
        {loading &&
          <Loader />
        }
        {(offersFound && qualifiedInquiries?.length === 0) &&
          <Typography variant="h5">
            <Box color="common.danger500" mt={2}>
              The email and/or birthdate entered are incorrect.
              Please try again or call us at {constants.supportNumberDisplay}
            </Box>
          </Typography>
        }
        {qualifiedInquiries != null && qualifiedInquiries.length > 0 &&
          <Box mt={4}>
            <Divider />
            <Box mt={2}>
              <Grid container className={classes.inquiriesList}>
                <Grid item xs={4} alignItems="center">
                  <Typography variant="body2">
                    Request date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    View
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Grid container className={classes.inquiriesList}>
              {qualifiedInquiries.map(loanInquiry => (
                <Fragment key={loanInquiry.id}>
                  <Grid item xs={4} alignItems="center">
                    <Typography variant="h5">{
                      moment(loanInquiry.createdAt).format('MM/DD/YY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5">
                      {currencyFormat(Number(loanInquiry.loanPrincipal))}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      component="a"
                      variant="contained"
                      buttonType="primary"
                      target="_blank"
                      href={`/loans/${loanInquiry.id}/offers?hearth_uid=${loanInquiry.hearthUid}`}
                    >
                      See offers
                    </Button>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>
        }
      </Note>
      {showStartNote &&
        <Box my={5}>
          <Note
            subtitle="New to Hearth Financing?"
            description="Get pre-qualified up to $100,000 in home improvement financing."
            headerImage="https://hearth.imgix.net/contractor-v2/see_offers/porch.png"
            headerImageAlt="porch image"
          >
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                variant="outlined"
                buttonType="primary"
                {...buttonLinkProps}
              >
                Start here
              </Button>
            </Box>
          </Note>
        </Box>
      }
    </>
  );
};

export default SeeOffersNote;
