import { stringify } from 'query-string';

import client from './client';

export default {
  seeOffers: (email: string, birthday: string) => client.request({
    method: 'GET',
    url: `/loan_inquiries/find?${stringify({ email, birthday })}`,
    baseURL: `${process.env.REACT_APP_API_HOST}/v1`,
  }),
  getOffers: (phoneNumber: string, loanInquiryUuid: string) => client.request({
    method: 'GET',
    url: `/loan_inquiries/${loanInquiryUuid}/offers?${stringify({ phoneNumber })}`,
    baseURL: `${process.env.REACT_APP_API_HOST}/v1`,
  }),
  sendEmail: (loanInquiryUuid: string, email: string) => client.request({
    method: 'POST',
    url: `/loan_application/${loanInquiryUuid}/perform_email`,
    data: { loanApplication: { addressTo: email } },
  }),
  getTestOffers: (data: any) => client.request({
    method: 'POST',
    url: '/offers/test_offers',
    data,
  }),
};
