import { SvgIconProps } from '../Icon';

const WarningOutlineIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 23"
    fill="none"
    {...otherProps}
  >
    <path
      d="M2 22L12 3l10 19H2zM12 11v4"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path d="M12 19a1 1 0 100-2 1 1 0 000 2z" fill={color} />
  </svg>
);

export default WarningOutlineIcon;
