import { SvgIconProps } from '../Icon';

const FillFormIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 21 23" fill="none" {...otherProps}>
    <path
      d="M9.158 12.848H3.374V8.536h5.631M.5 17.16h7.904M.5 4.225h14.37"
      stroke={color}
      strokeWidth={0.503}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.434 22.189H1.937c-.79 0-1.437-.647-1.437-1.437V2.069c0-.79.646-1.437 1.437-1.437h11.497c.79 0 1.437.647 1.437 1.437v9.36"
      stroke={color}
      strokeWidth={0.503}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.062 20.752L8.68 12.369a2.515 2.515 0 113.557-3.557l8.383 8.383M10.965 11.606l.508-.508"
      stroke={color}
      strokeWidth={0.503}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FillFormIcon;
