import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import { useHistory } from 'react-router-dom';

import {
  useGetHomeownerFeedbackInfoQuery,
  PaymentsFeedbackFragment,
  FinancingFeedbackFragment,
} from 'types';

const HOMEOWNER_FEEDBACK_FRAGMENTS = gql`
  fragment FinancingFeedback on HomeownerFeedbackResponse {
    id
    hearthRating
    contractorExperience
    additionalFinancingFeedback
    submittedAt
    averageFinancingRating
  }

  fragment PaymentsFeedback on HomeownerFeedbackResponse {
    id
    digitalPayments
    workQuality
    customerService
    additionalPaymentsFeedback
    submittedAt
    averagePaymentsRating
  }
`;

export const GET_HOMEOWNER_FEEDBACK_INFO = gql`
  query GetHomeownerFeedbackInfo($homeownerId: String!) {
    homeowner(id: $homeownerId) {
      id
      fullName
      homeownerPaymentsFeedback {
        id
        ...PaymentsFeedback
      }
      homeownerFinancingFeedback {
        id
        ...FinancingFeedback
      }
    }
  }
  ${HOMEOWNER_FEEDBACK_FRAGMENTS}
`;

type HearthPayRatingType =
  'digitalPayments' |
  'workQuality' |
  'customerService';

type FinancingRatingType =
  'contractorExperience' |
  'hearthRating';

export type Rating = {
  name: FinancingRatingType | HearthPayRatingType;
  label: string;
  rating: number;
}

export const hearthPayRatings = (feedback: PaymentsFeedbackFragment): Rating[] => ([
  { name: 'digitalPayments', label: 'Paying digitally', rating: feedback.digitalPayments || 0 },
  { name: 'workQuality', label: 'Quality of work', rating: feedback.workQuality || 0 },
  { name: 'customerService', label: 'Customer service', rating: feedback.customerService || 0 },
]);

export const hearthFinancingRatings = (feedback: FinancingFeedbackFragment): Rating[] => ([
  {
    name: 'contractorExperience',
    label: 'Contractor experience',
    rating: feedback.contractorExperience || 0,
  },
  {
    name: 'hearthRating',
    label: 'Hearth rating',
    rating: feedback.hearthRating || 0,
  },
]);

export enum FeedbackType {
  FINANCING = 'financing-feedback',
  PAYMENTS = 'hearth-pay-feedback',
}

export type HomeownerFeedbackParams = {
  homeownerId?: string;
  feedbackType: FeedbackType;
}

type Props = {
  homeownerId: string;
  feedbackType: FeedbackType;
}

const useBaseContext = ({ homeownerId, feedbackType }: Props) => {
  const query = useGetHomeownerFeedbackInfoQuery({
    variables: { homeownerId },
    skip: !homeownerId,
  });

  const homeowner = useMemo(() => query?.data?.homeowner, [query.data]);

  const paymentsFeedback = useMemo(() => {
    const feedback = homeowner?.homeownerPaymentsFeedback;
    if (!feedback) return null;

    return {
      ratings: hearthPayRatings(feedback),
      additionalFeedback: feedback.additionalPaymentsFeedback,
      submittedAt: feedback.submittedAt,
      overallRating: feedback.averagePaymentsRating,
    };
  }, [homeowner]);

  const financingFeedback = useMemo(() => {
    const feedback = homeowner?.homeownerFinancingFeedback;

    if (!feedback) return null;

    return {
      ratings: hearthFinancingRatings(feedback),
      additionalFeedback: feedback.additionalFinancingFeedback,
      submittedAt: feedback.submittedAt,
      overallRating: feedback.averageFinancingRating,
    };
  }, [homeowner]);

  const feedback = useMemo(() => {
    switch (feedbackType) {
    case FeedbackType.PAYMENTS:
      return paymentsFeedback;
    case FeedbackType.FINANCING:
      return financingFeedback;
    default: return null;
    }
  }, [feedbackType, financingFeedback, paymentsFeedback]);

  return {
    query,
    homeowner,
    feedback,
  };
};

export const [HomeownerFeedbackProvider, useHomeownerFeedback] = constate(useBaseContext);

export default useHomeownerFeedback;

type HomeownerFeedbackActions = {
  navigateToClientProfile: (homeownerId: string) => void;
}

export const useHomeownerFeedbackActions = (): HomeownerFeedbackActions => {
  const history = useHistory();

  /*
    Action: Navigate to homeowner's profile
  */
  const navigateToClientProfile = useCallback((homeownerId: string) =>
    history.push(`/dashboard/clients/${homeownerId}`), [history]);

  return {
    navigateToClientProfile,
  };
};
