
import AccountSwitcher from 'contractor/modules/AccountSwitcher';
import { AffiliateModal } from 'contractor/components/widgets';
import { useUrl } from 'common/hooks';

import { useDashboardNavigation } from './useDashboardNavigation';

const referralModalCopy = {
  emailSubject: 'Use my link for a $99 waived setup fee Hearth discount!',
  title: 'Refer your buddy!',
  subtitle: 'Refer a friend. They’ll get the $99 setup fee waived on their ' +
    'subscription, and you’ll get at least $200.',
};

const DashboardModals = (): JSX.Element => {
  const { appUrl } = useUrl();
  const {
    contractor,
    uiStates: {
      changeAccountModalOpen,
      setChangeAccountModalOpen,
      affiliateModalOpen,
      setAffiliateModalOpen,
    },
  } = useDashboardNavigation();

  return (
    <>
      {changeAccountModalOpen &&
        <AccountSwitcher
          switcherOpen={changeAccountModalOpen}
          onClose={() => setChangeAccountModalOpen(false)}
        />
      }
      {affiliateModalOpen && contractor && contractor.affiliateReferralCode &&
        <AffiliateModal
          isOpen={affiliateModalOpen}
          onClosePopup={() => setAffiliateModalOpen(false)}
          referralCoupon={contractor?.affiliateReferralCode}
          copyContent={referralModalCopy}
          referralLink={appUrl(`/r/${contractor.affiliateReferralCode}`)}
          contractorId={parseInt(contractor.id, 10)}
        />
      }
    </>
  );
};

export default DashboardModals;
