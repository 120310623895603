import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
  Divider,
} from '@mui/material';
import { object, boolean, string } from 'yup';
import { useDropzone } from 'react-dropzone';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useField } from 'formik';

import { Button } from 'common/components/material-ui';
import {
  Form,
  InputField,
  AddressField,
  SubmitButton,
  SelectField,
} from 'common/components/formik';
import options from 'common/utils/options';
import { OrganizationContractWizardInfoFragment } from 'types';
import { ContractTemplateActionType } from 'contractor/hooks/useEditContractTemplateReducer';

import {
  useWizard,
  useOrganization,
  useContractWizardActions,
  useContractTemplate,
  wizardStepDetails,
} from '../useContractWizard';
import { WizardHeader, WizardContent } from '../components';

type Values = Partial<OrganizationContractWizardInfoFragment> & {
  includeLogo: boolean;
}

const LogoUpload = () => {
  const [logoField] = useField<string>('logo');
  const organization = useOrganization();
  const { updateCompanyInfo } = useContractWizardActions();

  const [includeLogoField] = useField<boolean>('includeLogo');
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5000000,
    accept: [
      'image/jpeg',
      'image/jpg',
      'image/png',
    ],
    onDrop: (acceptedFiles: File[]) => {
      const reader = new FileReader();

      reader.onload = async () => {
        const binaryStr = reader.result;
        if (typeof binaryStr === 'string') {
          logoField.onChange({
            target: {
              name: 'logo',
              value: binaryStr,
            },
          });
          updateCompanyInfo({ logo: binaryStr });
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    multiple: false,
  });

  const rootProps = getRootProps();
  // can't pass in css to a Box
  const { css, ...boxProps } = rootProps;

  return (
    <Box display="flex" flexDirection="column" color="common.basic900" height="100%">
      <Typography variant="body2">
        Optional
      </Typography>
      <Box
        borderColor="common.basic900"
        border="1px solid"
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        mt={0.5}
        minHeight={250}
        style={{
          cursor: 'pointer',
        }}
        {...boxProps}
      >
        <input {...getInputProps()} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          position="relative"
        >
          {!includeLogoField.value &&
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              bgcolor="rgba(255, 255, 255, 0.75)"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color="common.basic1100"
            >
              <VisibilityOffIcon style={{ fontSize: 48 }} />
              <Typography variant="subtitle2">
                Logo Hidden
              </Typography>
            </Box>
          }
          {organization?.logoUrl ?
            <div
              style={{
                backgroundImage: `url(${organization?.logoUrl})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                width: '60%',
                height: '60%',
              }}
            /> :
            <>
              <SystemUpdateAltIcon fontSize="large" />
              <Typography variant="body2">
                Drag and drop logo
              </Typography>
            </>
          }
        </Box>
      </Box>
      {organization?.logoUrl &&
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            buttonType="common.purple"
            onClick={() => {
              includeLogoField.onChange({
                target: {
                  name: 'includeLogo',
                  value: !includeLogoField.value,
                },
              });
            }}
          >
            {includeLogoField.value ?
              <VisibilityOffIcon /> :
              <VisibilityIcon />
            }
            <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
              {includeLogoField.value ?
                'Hide logo on my contract' :
                'Show logo on my contract'
              }
            </Typography>
          </Button>
        </Box>
      }
    </Box>
  );
};

const Separator = () => (
  <Box my={3} mx={-4}>
    <Divider />
  </Box>
);

const CompanyStep = (): JSX.Element | null => {
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { stepIndex, nextStep, steps } = useWizard();
  const { updateCompanyInfo } = useContractWizardActions();
  const { dispatch } = useContractTemplate();
  const organization = useOrganization();

  if (!organization) return null;

  const nextStepTitle = wizardStepDetails[steps[stepIndex + 1]].title;

  return (
    <>
      <WizardHeader title={`Step ${stepIndex}: Company Details`} stepIndex={stepIndex} />
      <WizardContent>
        <Typography variant={isMobileOrSmall ? 'h3' : 'h2'}>
          Your company information that appears in the contract header.
        </Typography>
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <Separator />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box mt={5} />
        </Box>
        <Form<Values>
          initialValues={{
            companyName: organization.companyName,
            logo: organization.logo,
            address: organization.address,
            state: organization.state,
            city: organization.city,
            zipCode: organization.zipCode,
            businessLicenseNumber: organization.businessLicenseNumber,
            includeLogo: true,
          }}
          validationSchema={object().shape({
            companyName: string().label('Company name').required(),
            address: string().label('Address').required(),
            state: string().label('State').required(),
            city: string().label('City').required(),
            zipCode: string().label('Zip code').required(),
            businessLicenseNumber: string().label('Business license number').nullable(),
            includeLogo: boolean().label('Include logo').required(),
            logo: string().label('Logo').nullable(),
          })}
          onSubmit={(values) => {
            nextStep();
            // TODO(contracts): don't remove these fields after it is added
            const { includeLogo, logo, ...orgValues } = values;
            if (includeLogo) {
              dispatch({
                type: ContractTemplateActionType.UPDATE_IMAGE_ATTRIBUTE,
                payload: {
                  includeImage: includeLogo,
                },
              });
            }
            updateCompanyInfo(orgValues);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                bgcolor="common.basic100"
                borderRadius="16px"
                pt={{ xs: 0, md: 3 }}
                pb={{ xs: 0, md: 6 }}
                px={{ xs: 0, md: 4 }}
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Typography variant={isMobileOrSmall ? 'h5' : 'h3'}>
                  Your company logo
                </Typography>
                <Box mt={{ xs: 2, md: 3 }} flex={1}>
                  <LogoUpload />
                </Box>
              </Box>
            </Grid>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <Grid item xs={12}>
                <Separator />
              </Grid>
            </Box>
            <Grid item xs={12} md={6}>
              <Box
                bgcolor="common.basic100"
                borderRadius="16px"
                pt={{ xs: 0, md: 3 }}
                pb={{ xs: 0, md: 6 }}
                px={{ xs: 0, md: 4 }}
              >
                <Typography variant={isMobileOrSmall ? 'h5' : 'h3'}>
                  Your company details
                </Typography>
                <Box mt={{ xs: 2, md: 4 }} />
                <InputField name="companyName" />
                <Box m={4} />
                <AddressField
                  name="address"
                  cityName="city"
                  stateName="state"
                  zipCodeName="zipCode"
                />
                <Box m={4} />
                <InputField name="city" />
                <Box m={4} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SelectField
                      name="state"
                      items={options.states}
                      native
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField name="zipCode" />
                  </Grid>
                </Grid>
                <Box m={4} />
                <InputField
                  name="businessLicenseNumber"
                  label="Business license number (optional)"
                />
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <SubmitButton
              variant="contained"
              buttonType="common.purple"
              disableOnInitialValues={false}
              disableOnError
              rounded
              size="large"
              fullWidth={isMobileOrSmall}
            >
              Next step: {nextStepTitle}
            </SubmitButton>
          </Box>
        </Form>
      </WizardContent>
    </>
  );
};

export default CompanyStep;
