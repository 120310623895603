import { useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, CardMedia } from '@mui/material';

import { Button } from 'common/components/material-ui';

// Needed to include this because react-modal does not properly overlay
// onto MaterialUI's styles
import './PaywallBanner.scss';

type Props = {
  children: React.ReactNode;
  ctaText: string;
  ctaLink: string;
  pathname?: string;
  onClick?: () => void;
  onClose?: () => void;
  closable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: '16px 48px 48px',
  },
  bannerBody: {
    pointerEvents: 'none',
  },
  banner: {
    position: 'fixed',
    pointerEvents: 'auto',
    overflow: 'none',
    width: '100%',
    height: 'auto',
    minHeight: 200,
    zIndex: 5,
    padding: '40px 0px 64px',
    [theme.breakpoints.up('md')]: {
      top: theme.navbarHeight,
    },
  },
  bannerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    zIndex: 101,
    minHeight: 200,
    [theme.breakpoints.up('md')]: {
      zIndex: 90,
    },
  },
  button: {
    padding: '8px 24px',
    borderRadius: 5,
    position: 'absolute',
    marginTop: 24,
    marginBottom: 24,
  },
  exit: {
    padding: '8px 32px',
  },
  exitButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

const PaywallBanner = ({
  children,
  ctaText,
  ctaLink,
  pathname,
  onClick = () => {},
  onClose = () => {},
  closable = false,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(true);

  return showModal ? (
    <Modal
      isOpen={ctaLink !== pathname}
      bodyOpenClassName={classes.bannerBody}
      ariaHideApp={false}
      overlayClassName={classes.bannerOverlay}
      className={`${classes.banner} theme-dark gradient-background`}
    >
      {closable && (
        <Box className={classes.exit}>
          <Button
            className={classes.exitButton}
            onClick={() => {
              onClose();
              setShowModal(false);
            }}
            buttonType="primary"
          >
            <CardMedia
              className={classes.icon}
              alt="Pop-up close icon"
              src="https://hearth.imgix.net/icons/close_transparent_background.svg"
              component="img"
            />
          </Button>
        </Box>
      )}
      <Box
        className={classes.content}
        alignItems="center"
        flexDirection="column"
      >
        {children}
        <Button
          variant="contained"
          buttonType="primary"
          component={Link}
          to={ctaLink}
          className={classes.button}
          onClick={onClick}
        >
          {ctaText}
        </Button>
      </Box>
    </Modal>
  ) : <div />;
};

export default PaywallBanner;
