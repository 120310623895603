import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

import userFeatureFlags from 'common/graphql/featureFlags';
import { NavDrawerItem } from 'common/components/material-ui';

import {
  useDashboardNavigation,
  NavigationOption,
  useDashboardNavActions,
} from '../useDashboardNavigation';

/**
 *
 * NAV: Clients
 *
 *
 * Visibility requirements:
 *
 * - Feature flags: customerDetails
 */

const ClientsTab = (): JSX.Element | null => {
  const { customerDetails } = useReactiveVar(userFeatureFlags);
  const { uiStates: { activeTab } } = useDashboardNavigation();
  const { onNavItemClick } = useDashboardNavActions();

  if (!customerDetails) return null;

  return (
    <Box my={2}>
      <NavDrawerItem
        title="Clients"
        icon={
          <Box mt="2px">
            <ListRoundedIcon />
          </Box>
        }
        color="basic1100"
        active={activeTab === NavigationOption.CLIENTS}
        onClick={() => onNavItemClick('/dashboard/clients')}
      />
    </Box>
  );
};

export default ClientsTab;
