import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { ButtonBase } from 'common/components/material-ui';
import { GetStartedContractorFragment } from 'types';

import { GetStartedListItem, GetStartedButton } from '..';
import { Task } from '../../useGetStarted';

type Props = {
  contractor: GetStartedContractorFragment
};

const CompleteTrainingTask = ({ contractor }: Props): JSX.Element => {
  const { trainingCompleted } = contractor;
  return (
    <GetStartedListItem
      taskCompleted={trainingCompleted}
      taskName={Task.COMPLETE_TRAINING}
    >
      <ButtonBase
        component={Link}
        to={location => ({
          pathname: '/dashboard/training',
          search: '?source=dashboard',
          state: {
            background: location,
          },
        })}
        sx={{ width: '100%', height: '100%' }}
        data-testid="wistia-thumbnail-button"
      >
        <Box
          sx={{
            backgroundImage: 'url(https://hearth.imgix.net/contractor-v2/get_started/complete_training_thumbnail.png)',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
            minHeight: 150,
            '&:hover': {
              filter: 'contrast(100%) brightness(90%)',
              cursor: 'pointer',
            },
          }}
        />
      </ButtonBase>
      <GetStartedButton
        sx={{ mt: 2, py: 1 }}
        component={Link}
        to={location => ({
          pathname: '/dashboard/training',
          search: '?source=dashboard',
          state: {
            background: location,
          },
        })}
        data-testid="complete-training-button"
      >
        Watch now
      </GetStartedButton>
    </GetStartedListItem>
  );
};
export default CompleteTrainingTask;
