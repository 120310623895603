import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';

import { StarRating } from 'common/components/material-ui';

const useStyles = makeStyles(theme => ({
  scaleText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
}));

type Props = {
  label?: string;
  maxRating?: number;
  name: string;
}

const StarRatingField = ({
  name,
  label,
  maxRating = 5,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [{ value }, , { setValue }] = useField(name);

  const handleChange = useCallback((val) => {
    setValue(val);
  }, [setValue]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h5" color="textPrimary">
        <Box className={classes.scaleText}>
          {label}
        </Box>
      </Typography>
      <StarRating
        rating={value}
        maxRating={maxRating}
        onChange={handleChange}
      />
    </Box>
  );
};

export default StarRatingField;
