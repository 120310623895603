import makeStyles from '@mui/styles/makeStyles';

import { Link } from 'common/components/material-ui';

type Props = {
  color: 'blue' | 'white';
  type: '' |'a_plus_';
  style: Record<string, unknown>;
}

const useStyles = makeStyles({
  bbbLink: {
    position: 'relative',
  },
  bbbLogoSmVariable: {
    width: '100%',
    maxWidth: '79px',
  },
});

const BBBIcon = ({
  color = 'blue',
  type = '',
  style,
}: Props): JSX.Element => {
  const styles = useStyles();

  return (
    <Link
      href="https://www.bbb.org/greater-san-francisco/business-reviews/financial-services/get-hearth-in-san-francisco-ca-876648/#sealclick"
      target="_blank"
      rel="noopener noreferrer nofollow"
      className={styles.bbbLink}
    >
      <img
        src={`https://hearth.imgix.net/bbb/bbb_${type + color}.png?auto=compress&w=400`}
        alt="Get Hearth BBB Business Review"
        className={styles.bbbLogoSmVariable}
        style={style}
      />
    </Link>
  );
};

export default BBBIcon;
