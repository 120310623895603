import { sentenceCase } from 'change-case';
import { FormikHandlers, FormikErrors } from 'formik';

type InputParams = {
  label: string;
  name: string;
  errorMessage: string;
  value: string | number | boolean | undefined;
  showErrors: boolean;
  onChange?: any; // @TODO(any)
}

export function buildInputParams(
  key: string,
  model: Record<string, any>,
  validations: Record<string, any>,
  showErrors: Record<string, boolean>,
): InputParams {
  return {
    label: sentenceCase(key),
    name: key,
    errorMessage: validations[key],
    value: model[key] == null ? undefined : model[key],
    showErrors: Boolean(showErrors[key] && validations[key]),
  };
}

export function buildInputParamsFormik(
  key: string,
  model: Record<string, any>,
  validations: Record<string, any>,
  onChange: any, // @TODO(any)
): InputParams {
  return {
    label: sentenceCase(key),
    name: key,
    errorMessage: validations[key],
    value: model[key] == null ? undefined : model[key],
    showErrors: Boolean(validations[key]),
    onChange,
  };
}

type MaterialParams = {
  label: string;
  error: boolean;
  value: string | number | boolean | undefined;
  helperText?: string;
  onChange: FormikHandlers['handleChange'];
  name: string;
}

export function buildMaterialInputParams<T>(
  key: string,
  model: T,
  errors: FormikErrors<T>,
  onChange: FormikHandlers['handleChange'],
): MaterialParams {
  return {
    label: sentenceCase(key),
    error: Boolean(errors[key]),
    onChange: onChange(key),
    value: model[key] == null ? undefined : model[key],
    helperText: errors[key] ? errors[key] : undefined,
    name: key,
  };
}
