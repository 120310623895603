import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { decamelizeKeys } from 'humps';

import client from './client';

export default {
  fetchQuestions: (id: string): Promise<AxiosResponse> => client.request({
    method: 'GET',
    url: `/questionnaire/${id}`,
  }),
  fetchAnswerOptions: (questionId: string): Promise<AxiosResponse> => client.request({
    method: 'GET',
    url: `/questionnaire/answer_options?${stringify(decamelizeKeys({ questionId }))}`,
  }),
};
