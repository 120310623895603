import React from 'react';
import {
  Radio,
  Typography,
  FormControlLabel,
  Theme,
  useRadioGroup,
  FormControlLabelProps,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export type RadioOptionComponentProps = {
  value: string;
  control: React.ReactElement;
  label: React.ReactNode;
  checked: boolean;
}

type Props = {
  value: string;
  label: React.ReactElement;
  color?: Color;
  size?: number;
} & Partial<FormControlLabelProps>;

type StyleProps = {
  color: Color;
  size: number;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    '& .MuiSvgIcon-root': {
      color: ({ color }) => theme.palette.common[color],
      width: ({ size }) => size,
      height: ({ size }) => size,
    },
  },
}));

const RadioOption = ({
  value,
  label,
  // TODO(style_guide): currently doesn't look prettiest with FormControlLabel, might need to
  // adjust some css.
  color = 'primary500',
  size = 24,
}: Props): JSX.Element => {
  const radioClasses = useStyles({ color, size });
  const radioGroupState = useRadioGroup();

  return (
    // label htmlFor is not semantically required because it contains a radio input
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <FormControlLabel
        value={value}
        checked={radioGroupState?.value === value}
        control={
          <Radio
            value={value}
            classes={radioClasses}
          />
        }
        label={typeof label === 'string' ?
          <Typography variant="body2">{label}</Typography> :
          label
        }
      />
    </label>
  );
};

export default RadioOption;
