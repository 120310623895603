import { useEffect } from 'react';
import * as React from 'react';
import { useDebounce } from 'react-use';
import {
  Form as FormikForm,
  Formik,
  useFormikContext,
  FormikValues,
  FormikConfig,
} from 'formik';

type InnerFormProps<Values> = {
  children: React.ReactNode;
  extraErrors?: Partial<Record<keyof Values, string | string[]>>;
  onChange?: (values: Values) => void;
};

function InnerForm<
  Values extends FormikValues = FormikValues
>({
  children,
  extraErrors,
  onChange,
}: InnerFormProps<Values>): JSX.Element {
  const { setErrors, values } = useFormikContext();

  useEffect(() => {
    if (extraErrors) {
      setErrors(extraErrors);
    }
  }, [extraErrors, setErrors]);

  useDebounce(() => {
    if (onChange) {
      onChange(values as Values);
    }
  }, 1000, [values]);

  return (
    <>
      {children}
    </>
  );
}

export type FormProps<Values> = Omit<FormikConfig<Values>, 'children'> & InnerFormProps<Values>;

export default function Form<
  Values extends FormikValues = FormikValues
>({
  children,
  extraErrors,
  onSubmit,
  onChange,
  ...otherProps
}: FormProps<Values>): JSX.Element {
  return (
    <Formik
      onSubmit={
        async (values, helpers) => {
          // workaround for https://github.com/formium/formik/issues/739
          await onSubmit(values, helpers);
        }
      }
      {...otherProps}
    >
      <FormikForm>
        <InnerForm extraErrors={extraErrors} onChange={onChange}>
          {children}
        </InnerForm>
      </FormikForm>
    </Formik>
  );
}
