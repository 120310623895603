import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import constate from 'constate';
import { isPresent } from 'ts-is-present';

import {
  ContactMedium,
  useGetClientInvoiceQuery,
  useCancelInvoiceMutation,
  useManuallyPayInvoiceMutation,
  useSendPaymentReminderMutation,
} from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';

// TODO(standardize_client_views): move gql to useInvoiceDetails upon 100% rollout
const CLIENT_INVOICE_FRAGMENT = gql`
  fragment ConnectInvoiceAttachment on InvoiceAttachment {
    id
    attachmentFileName
    link
  }

  fragment ClientInvoice on ConnectInvoice {
    id
    invoiceNumber
    achFee
    amountRequested
    cardFee
    customerPaysAchFee
    customerPaysCardFee
    debitCardFee
    description
    dueDate
    email
    fullName
    firstName
    lastRefundedAt
    paidWith
    paidWithCardType
    personalNote
    phoneNumber
    postRefundAmount
    refundableAmount
    refundedAmount
    status
    sentAt
    scheduledFor
    syncedToQuickbooks
    creditCardEnabled
    debitCardEnabled
    achEnabled
    paymentsCapabilityStatus
    viewableActivities {
      nodes {
        id
        ...ClientInvoiceViewableActivity
      }
    }
    invoiceAttachments {
      nodes {
        id
        ...ConnectInvoiceAttachment
      }
    }
  }

  fragment ClientInvoiceViewableActivity on ConnectActivity {
    id
    activityDate
    content
    connectActivityTypeId
  }
`;

export const GET_CLIENT_INVOICE = gql`
  query GetClientInvoice($invoiceId: ID!) {
    connectInvoice(id: $invoiceId) {
      id
      ...ClientInvoice
      homeowner {
        id
        primaryLoanInquiry {
          id
          address
          city
          state
          zipCode
        }
      }
      contractor {
        id
        fullName
      }
    }
  }
  ${CLIENT_INVOICE_FRAGMENT}
`;

export const MANUALLY_PAY_INVOICE = gql`
  mutation ManuallyPayInvoice(
    $connectInvoiceId: ID!
    $paymentType: String!
    $paymentDate: ISO8601DateTime!
  ) {
    manuallyPayInvoice(
      connectInvoiceId: $connectInvoiceId
      paymentType: $paymentType
      paymentDate: $paymentDate
    ) {
      ... on ManuallyPayInvoiceSuccess {
        connectInvoice {
          id
        }
      }
      ... on ManuallyPayInvoiceFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export const SEND_PAYMENT_REMINDER = gql`
  mutation SendPaymentReminder(
    $invoiceId: ID!
    $mediums: [ContactMedium!]!
  ) {
    sendHomeownerPaymentReminder(
      connectInvoiceId: $invoiceId
      mediums: $mediums
    ) {
      connectInvoice {
        id
      }
    }
  }
`;

export const CANCEL_CONNECT_INVOICE = gql`
  mutation CancelInvoice($connectInvoiceId: ID!) {
    cancelInvoice(connectInvoiceId: $connectInvoiceId) {
      ... on CancelInvoiceSuccess {
        connectInvoice {
          id
        }
      }
      ... on CancelInvoiceFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

type Props = {
  invoiceId: string;
}

const useContext = ({ invoiceId }: Props) => {
  const query = useGetClientInvoiceQuery({
    variables: {
      invoiceId,
    },
    skip: !invoiceId,
  });

  const invoice = useMemo(() => query?.data?.connectInvoice, [query]);
  const invoiceAttachments = useMemo(() =>
    invoice?.invoiceAttachments?.nodes?.filter(isPresent) || [],
  [invoice]);

  const homeowner = useMemo(() => invoice?.homeowner, [invoice]);

  return {
    invoiceId,
    invoice,
    invoiceAttachments,
    homeowner,
    query,
  };
};

export const [ClientInvoiceProvider, useClientInvoice] = constate(useContext);

export type ManualPaymentValues = {
  paymentDate: string;
  paymentType: string;
}

export type SendReminderValues = {
  email: boolean;
  text: boolean;
}

export const useClientInvoiceActions = () => {
  const { handleMutationError } = useGql();
  const { invoiceId } = useClientInvoice();
  const [cancelConnectInvoice] = useCancelInvoiceMutation();
  const [manuallyPayInvoice] = useManuallyPayInvoiceMutation();
  const [sendPaymentReminder] = useSendPaymentReminderMutation();

  const cancelInvoice = useCallback(async () => {
    try {
      const response = await cancelConnectInvoice({
        variables: {
          connectInvoiceId: invoiceId,
        },
        refetchQueries: ['GetClientInvoice', 'GetClientProfile'],
        awaitRefetchQueries: true,
      });

      parseGql<PayloadType<typeof response, 'cancelInvoice'>>(
        'cancelInvoice',
        response,
        'CancelInvoiceSuccess',
        'CancelInvoiceFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [invoiceId, cancelConnectInvoice, handleMutationError]);

  const markCollected = useCallback(async ({
    paymentType,
    paymentDate,
  }: ManualPaymentValues) => {
    try {
      const response = await manuallyPayInvoice({
        variables: {
          connectInvoiceId: invoiceId,
          paymentType,
          paymentDate: new Date(paymentDate).toISOString(),
        },
        refetchQueries: ['GetClientInvoice', 'GetClientProfile'],
        awaitRefetchQueries: true,
      });

      parseGql<PayloadType<typeof response, 'manuallyPayInvoice'>>(
        'manuallyPayInvoice',
        response,
        'ManuallyPayInvoiceSuccess',
        'ManuallyPayInvoiceFailure',
      );
    } catch (e) {
      handleMutationError(e, {});
    }
  }, [invoiceId, manuallyPayInvoice, handleMutationError]);

  const sendReminder = useCallback(async ({ email, text }: SendReminderValues) => {
    const mediums: ContactMedium[] = [];

    if (email) {
      mediums.push(ContactMedium.EMAIL);
    }
    if (text) {
      mediums.push(ContactMedium.TEXT);
    }

    await sendPaymentReminder({
      variables: {
        invoiceId,
        mediums,
      },
      refetchQueries: ['GetClientInvoice', 'GetClientProfile'],
      awaitRefetchQueries: true,
    });
  }, [invoiceId, sendPaymentReminder]);

  return {
    cancelInvoice,
    markCollected,
    sendReminder,
  };
};

export default useClientInvoice;
