import { string, object } from 'yup';
import { Box, Typography } from '@mui/material';

import { RouterLink } from 'common/components/material-ui';
import { Form, InputField, SubmitButton } from 'common/components/formik';

export type Values = {
  email: string;
  password: string;
};

type Props = {
  onSubmit: (values: Values) => void;
};

const LoginForm = ({ onSubmit }: Props): JSX.Element => (
  <Form<Values>
    isInitialValid
    initialValues={{
      email: '',
      password: '',
    }}
    validateOnChange={false}
    validationSchema={object().shape({
      email: string()
        .label('Email')
        .email()
        .required(),
      password: string()
        .label('Password')
        .min(8, 'Password needs to be at least 8 characters')
        .required(),
    })}
    validateOnBlur
    onSubmit={onSubmit}
  >
    <Box my={2}>
      <InputField
        label="Email address"
        name="email"
        type="email"
        autoComplete="email"
      />
    </Box>
    <Box my={2}>
      <InputField
        name="password"
        type="password"
        autoComplete="current-password"
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <RouterLink to="/password">
          <Typography variant="subtitle2" color="primary">
            Forgot your password?
          </Typography>
        </RouterLink>
      </Box>
    </Box>
    <Box display="flex" justifyContent="flex-end" mt={4}>
      <Box width={{ md: 1 / 3 }}>
        <SubmitButton
          variant="contained"
          buttonType="primary"
          type="submit"
          fullWidth
        >
          Log in
        </SubmitButton>
      </Box>
    </Box>
  </Form>
);

export default LoginForm;
