import { useCallback } from 'react';
import {
  Snackbar,
  SnackbarContent,
  Box,
  Typography,
  Theme,
} from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';

import { Link } from 'common/components/material-ui';
import { useGetDemoModeQuery, useUpsertDemoModeMutation } from 'types';
import parseGql, { PayloadType } from 'common/api/parseGql';
import { useGql } from 'common/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    bottom: '16px',
    // use zIndex.drawer instead of zIndex.snack so it stays below the left navbar on mobile
    zIndex: theme.zIndex.drawer,
  },
  warning: {
    backgroundColor: theme.customPalette.warning.color,
    color: theme.customPalette.warning.contrast,
    [theme.breakpoints.up('md')]: {
      width: '45vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
  },
  exit: {
    color: theme.palette.common.basic100,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.common.basic100,
      textDecoration: 'none',
    },
  },
}));

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionDown: React.ComponentType<TransitionProps> =
  (props: TransitionProps): JSX.Element => <Slide {...props} direction="up" />;

const DemoModeSnack = (): JSX.Element => {
  const { data } = useGetDemoModeQuery();
  const [upsertDemoMode] = useUpsertDemoModeMutation();
  const classes = useStyles();
  const { handleMutationError } = useGql();

  const disableDemoMode = useCallback(
    async (): Promise<void> => {
      try {
        const response = await upsertDemoMode({
          variables: {
            attributes: {
              demoMode: false,
            },
          },
        });

        parseGql<PayloadType<typeof response, 'upsertContractor'>>(
          'upsertContractor',
          response,
          'UpsertContractorSuccess',
          'UpsertContractorFailure',
        );
      } catch (e) {
        handleMutationError(e, {});
      }
    },
    [handleMutationError, upsertDemoMode],
  );

  const action = (
    <Link
      className={classes.exit}
      variant="body2"
      onClick={disableDemoMode}
    >
      Exit demo mode
    </Link>
  );

  return (
    <Snackbar
      open={data?.contractor?.demoMode}
      TransitionComponent={TransitionDown}
      className={classes.root}
    >
      <SnackbarContent
        className={classes.warning}
        message={
          <Box>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Demo mode: ON
            </Typography>
            <Typography variant="body2">
              Info entered will not return actual offers.
            </Typography>
          </Box>
        }
        action={action}
      />
    </Snackbar>
  );
};

DemoModeSnack.GET_DEMO_MODE = gql`
  query GetDemoMode {
    contractor {
      id
      demoMode
    }
  }
`;

DemoModeSnack.UPSERT_DEMO_MODE = gql`
  mutation UpsertDemoMode($attributes: ContractorAttributes!) {
    upsertContractor(attributes: $attributes) {
      ... on UpsertContractorSuccess {
        contractor {
          id
          demoMode
        }
      }
      ... on UpsertContractorFailure {
        errors {
          message
          path
          code
        }
      }
    }
  }
`;

export default DemoModeSnack;
