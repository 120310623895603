import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { Actions } from 'common/actions';
import { AppState } from 'stores';

export type ServerConstantsState = DeepReadonly<{
  minimumAppVersion: string;
  lenders: string[];
  paymentPlansRates: {
    [key: string]: {
      [key: string]: number;
    };
  };
  liveStates: string[];
  securedStates: string[];
  unlicensedStates: string[];
}>

// Initial state
const defaultState: ServerConstantsState = {
  minimumAppVersion: '0.0.0',
  lenders: [],
  paymentPlansRates: {},
  liveStates: [],
  securedStates: [],
  unlicensedStates: [],
};

const constantsSelector = (state: AppState): ServerConstantsState => state.serverConstants;

export const lendersSelector = createSelector(
  constantsSelector,
  serverConstants => serverConstants.lenders,
);

export const lenderCountSelector = createSelector(
  constantsSelector,
  serverConstants => serverConstants.lenders.length,
);

export default function ServerConstantsStateReducer(
  state = defaultState,
  action: Actions,
): ServerConstantsState {
  switch (action.type) {
  case 'ServerConstants/FETCH_SUCCESS':
    return {
      ...state,
      ...action.payload,
    };

  default:
    return state;
  }
}
