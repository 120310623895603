import {
  Box,
  Divider,
  Typography,
  TypographyProps,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { Background } from 'types';
import { currencyFormatFromCents } from 'common/utils/stringFormatters';

import {
  colors,
  StyleProps,
  TemplateProps,
} from './theme';
import ToolsBackground from './ToolsBackground';
import { Attachments, Links, SignatureMessage } from './components';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
    borderRadius: 16,
    zIndex: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.basic100,
    color: ({ themeColor }: StyleProps) => colors[themeColor],
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
  },
  root: {
    position: 'relative',
    padding: '80px 40px 40px',
    minHeight: 792,
    backgroundColor: ({ themeColor, fillBackground }: StyleProps) =>
      (fillBackground ? colors[themeColor] : theme.palette.common.basic100),
  },
  header: {
    padding: '0 16px',
    marginBottom: 32,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 100,
    width: 'auto',
    height: 'auto',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    height: 2,
  },
  toolsBackground: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
  },
  homeownerInvoiceDetails: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  linksContainer: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: 40,
  },
}));

type BodyTextProps = {
  align?: TypographyProps['align'];
  color?: TypographyProps['color'];
  children: React.ReactNode;
}

const BodyText = ({ children, color = 'textPrimary', align = 'left' }: BodyTextProps) => (
  <Box mt="4px" textOverflow="clip" style={{ wordBreak: 'break-word' }}>
    <Typography variant="body2" color={color} align={align}>
      {children}
    </Typography>
  </Box>
);

const FunTemplate = ({
  settings,
  organization,
  invoice,
  links,
  attachments,
}: TemplateProps): JSX.Element => {
  const classes = useStyles({
    themeColor: settings.primaryColor,
    fillBackground: settings.background === Background.SOLID,
  });

  return (
    <Box className={classes.root}>
      {/* TODO(customize_invoice): get PNGs of each color to use here from S3 instead? */}
      {settings.background === Background.PATTERN &&
        <Box className={classes.toolsBackground}>
          <ToolsBackground width="100%" height="auto" color={colors[settings.primaryColor]} />
        </Box>
      }
      <Box className={classes.container}>
        <Grid container spacing={2} className={classes.header}>
          <Grid item xs={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent={(settings.logo && organization.logoUrl) ? 'space-between' : 'flex-end'}
              height="100%"
            >
              {settings.logo && organization.logoUrl &&
              <Box mb={2}>
                <img
                  src={organization.logoUrl}
                  className={classes.logo}
                  alt="Company logo"
                />
              </Box>
              }
              <Typography variant="h3" color="inherit">
                Invoice #{invoice.invoiceNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="inherit" align="right">
              <Box fontWeight="fontWeightBold">
                {organization.companyName}
              </Box>
            </Typography>
            <BodyText color="inherit" align="right">
              {organization.fullAddress}
            </BodyText>
            <BodyText color="inherit" align="right">
              {organization.businessPhone}
            </BodyText>
            <BodyText color="inherit" align="right">
              {organization.businessEmail}
            </BodyText>
            {organization.website &&
              <BodyText color="inherit" align="right">
                {organization.website}
              </BodyText>
            }
          </Grid>
        </Grid>
        <Divider light variant="fullWidth" className={classes.divider} />
        <Box px={2} mb={3}>
          <Box className={classes.homeownerInvoiceDetails}>
            <Box className={classes.column} color="common.basic900">
              <Typography variant="caption" color="inherit">
                Billed to
              </Typography>
              <Box mt={1} />
              <BodyText>
                {invoice.fullName}
              </BodyText>
              <BodyText>
                {invoice.phoneNumber}
              </BodyText>
              <BodyText>
                {invoice.email}
              </BodyText>
            </Box>
            <Box className={classes.column}>
              <Typography variant="caption" align="right">
                <Box color="common.basic900">
                  Amount Due
                </Box>
              </Typography>
              <Typography variant="h2" color="inherit" align="right">
                <Box fontWeight="fontWeightMedium">
                  {currencyFormatFromCents(invoice.amountRequested, 2)}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box mt={3} />
          {(invoice.sentAt || invoice.dueDate) &&
            <Box className={classes.column} color="common.basic900">
              {invoice.sentAt &&
                <>
                  <Typography variant="caption" color="inherit">
                    Sent
                  </Typography>
                  <BodyText>
                    {format(Date.parse(invoice.sentAt), 'MMMM d, yyyy')}
                  </BodyText>
                </>
              }
              {invoice.dueDate &&
                <Box mt={2}>
                  <Typography variant="caption" color="inherit">
                    Due
                  </Typography>
                  <BodyText>
                    {format(new Date(), 'MMMM d, yyyy')}
                  </BodyText>
                </Box>
              }
            </Box>
          }
        </Box>
        <Divider light variant="fullWidth" className={classes.divider} />
        <Box mt={3} px={2}>
          <Box className={classes.column} color="common.basic900">
            <Typography variant="caption" color="inherit">
              Description:
            </Typography>
            <Box mt={1} />
            <BodyText>
              {invoice.description}
            </BodyText>
            {invoice.personalNote &&
              <Box mt={3}>
                <Typography variant="caption" color="inherit">
                  Note:
                </Typography>
                <BodyText>
                  {invoice.personalNote}
                </BodyText>
              </Box>
            }
          </Box>
          <SignatureMessage signatureMessage={settings.signatureMessage} />
          <Box height={30} />
        </Box>
        {attachments.length > 0 &&
          <Attachments attachments={attachments} />
        }
        <Box className={classes.linksContainer} flexShrink={0}>
          <Links links={links} themeColor={settings.primaryColor} />
        </Box>
      </Box>
    </Box>
  );
};

export default FunTemplate;
