import { Box, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Check } from '@mui/icons-material';

import { ButtonBase } from 'common/components/material-ui';
import { Icon } from 'common/components';

type StyleProps = {
  disabled: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  button: ({ disabled }) => ({
    border: `1px solid ${theme.palette.common.primary500}`,
    borderRadius: 8,
    padding: 16,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.common.primary500,
    opacity: disabled ? 0.3 : 1,
  }),
}));

type Props = {
  disabled: boolean;
  onMarkCollected: () => void;
  onSendReminder: () => void;
}

const InvoiceActionButtons = ({
  disabled,
  onMarkCollected,
  onSendReminder,
}: Props): JSX.Element => {
  const classes = useStyles({ disabled });
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <ButtonBase
        onClick={onMarkCollected}
        disabled={disabled}
        className={classes.button}
      >
        <Check fontSize="large" style={{ height: 24, width: 24 }} />
        <Box m={0.5} />
        <Typography variant="subtitle2">
          <Box color="common.primary500">
            Mark Collected
          </Box>
        </Typography>
      </ButtonBase>
      <Box m={1} />
      <ButtonBase
        onClick={onSendReminder}
        disabled={disabled}
        className={classes.button}
      >
        <Icon
          name="paper-plane"
          color="primary500"
          size={24}
        />
        <Box m={0.5} />
        <Typography variant="subtitle2">
          <Box color="common.primary500">
            Send Reminder
          </Box>
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default InvoiceActionButtons;
