import { SvgIconProps } from '../Icon';

const DashboardOutlineIcon = ({ color, ...otherProps }: SvgIconProps): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M1 4.887c0-.51.259-.984.687-1.26l3.5-2.259a1.5 1.5 0 011.626 0l3.5 2.259A1.5 1.5 0 0111 4.887v4.362a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 01-1.5-1.5V4.887z"
      stroke={color}
    />
    <path
      d="M3.938 7.349c.147.473.766 1.419 2.062 1.419a2.134 2.134 0 002.063-1.42"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

export default DashboardOutlineIcon;
