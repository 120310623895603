import { useState, useCallback } from 'react';
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import QuickActionsMenu from './QuickActionsMenu';

const SendNewFloatingActionButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = useCallback(() => setAnchorEl(null), []);

  return (
    <Box
      sx={{
        bottom: 24,
        right: 24,
        zIndex: 10000,
        position: 'fixed',
      }}
    >
      <Fab
        aria-label="add"
        onClick={handleOnClick}
        sx={{
          bgcolor: 'common.primary500',
          color: 'common.basic100',
          '&:hover': {
            bgcolor: 'common.primary700',
          },
        }}
        data-testid="send-new-floating-action-button"
      >
        <AddIcon fontSize="large" color="inherit" />
      </Fab>
      <QuickActionsMenu
        anchorEl={anchorEl}
        onClose={onClose}
        isMobile
        sx={{
          '& .MuiPaper-root': {
            mt: -1,
          },
        }}
      />
    </Box>
  );
};

export default SendNewFloatingActionButton;
