import { useState } from 'react';
import constate from 'constate';

export enum Task {
  COMPLETE_TRAINING = 'Complete Training',
  CUSTOMIZE_BRAND = 'Customize your brand',
  INVITE_MEMBERS = 'Invite team members',
  MARKETING_TOOL = 'Add marketing tools',
  MOBILE_APP = 'Log in to the mobile app',
}

const useContext = () => {
  const [currentTask, setCurrentTask] = useState(''); // TODO(get_started): change initial state?

  return {
    uiState: {
      currentTask,
      setCurrentTask,
    },
  };
};

export const [GetStartedProvider, useGetStarted] = constate(useContext);

