import { CSSProperties } from 'react';
import { Box, Skeleton } from '@mui/material';

const LoadingRow = ({ style }: { style?: CSSProperties }) => (
  <Box display="flex" flex={1} flexDirection="row" py={2} style={style}>
    <Box display="flex" flex={0} alignItems="center">
      <Skeleton variant="circular" width={48} height={48} />
    </Box>
    <Box display="flex" flex={4} flexDirection="column" ml={4}>
      <Skeleton variant="text" width={300} />
      <Skeleton variant="text" width={300} />
    </Box>
    <Box display="flex" flex={0} alignItems="center" mr={2}>
      <Skeleton variant="rectangular" width={40} height={20} />
    </Box>
  </Box>
);

export default LoadingRow;
