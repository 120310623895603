import { createSelector } from 'reselect';
import { ArticleState } from 'contractor/reducers/article';
import { AppState } from 'stores';

export const resourceSelector = (state: AppState): ArticleState => state.article;

export const sectionOrderSelector = createSelector(
  resourceSelector,
  resourceCenter => resourceCenter.sectionOrder,
);

export const articlesSelector = createSelector(
  resourceSelector,
  resourceCenter => resourceCenter.articles,
);

export const sectionsSelector = createSelector(
  resourceSelector,
  resourceCenter => resourceCenter.sections,
);

export const lifecycleSelector = createSelector(
  resourceSelector,
  resourceCenter => resourceCenter.lifecycles,
);

export const contractorDataSelector = createSelector(
  resourceSelector,
  resourceCenter => resourceCenter.contractorData,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const articleSelector = (articleId: string) =>
  createSelector(
    articlesSelector,
    articles => articles[articleId],
  );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const contractorArticleSelector = (articleId: string) =>
  createSelector(
    contractorDataSelector,
    articles => articles[articleId],
  );
