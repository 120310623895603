import { apiPromiseAction, DispatchAction } from 'promise-action';

import * as api from 'common/api/sessionApi';

export const fetchUserSession = apiPromiseAction
  .create(api.fetchUserSession)
  .normalizeResponse(({ data }) => data);

/*
** Dispatchers
*/

export const fetchUserSessionDispatcher = fetchUserSession.dispatcher(
  'Session/FETCH_USER_SESSION_PENDING',
  'Session/FETCH_USER_SESSION_SUCCESS',
  'Session/FETCH_USER_SESSION_ERROR',
);

export type DispatchActions =
  DispatchAction<typeof fetchUserSessionDispatcher>;
