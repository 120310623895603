import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { compose, defaultProps, withProps } from 'recompose';
import { camelizeKeys } from 'humps';
import pick from 'lodash/pick';

import options from 'common/utils/options';
import { Link } from 'common/components/material-ui';

import Select from './Select';
import Input from './Input';

const AddressInput = ({
  values,
  onChangeValues,
  errorMessages,
  hasErrors,
  fullAddress,
  ...otherProps
}) => {
  const [manualEdit, setManualEdit] = useState(!window.google);
  const [searchFocused, setSearchFocused] = useState(false);
  const [queryText, setQueryText] = useState('');
  const searchInputEl = useRef(null);

  useEffect(() => {
    if (hasErrors) {
      setManualEdit(true);
    }
  }, [hasErrors]);

  useEffect(() => {
    if (searchInputEl.current && window.google) {
      const autocomplete =
        new window.google.maps.places.Autocomplete(searchInputEl.current, { types: ['geocode'] });

      autocomplete.setComponentRestrictions({ country: 'us' });
      autocomplete.addListener('place_changed', () => {
        const { addressComponents } = camelizeKeys(autocomplete.getPlace());
        if (addressComponents) {
          const info = camelizeKeys(addressComponents.reduce(
            (acc, { longName, shortName, types }) => ({
              ...acc,
              ...types.reduce(
                (typeAcc, type) => ({
                  ...typeAcc,
                  [type]: { longName, shortName },
                }),
                {},
              ),
            }),
            {},
          ));

          onChangeValues({
            address: [
              info.streetNumber && info.streetNumber.shortName,
              info.route && info.route.longName,
            ].filter(Boolean).join(' '),
            city: info.locality ? info.locality.longName :
              (info.neighborhood && info.neighborhood.longName) || '',
            state: info.administrativeAreaLevel1.shortName,
            zipCode: info.postalCode ? info.postalCode.shortName : '',
          });
        }
        setQueryText('');
        setManualEdit(true);
      });
    }
  }, [onChangeValues, searchInputEl]);

  const onChange = event => onChangeValues({ [event.target.name]: event.target.value });

  return (
    <>
      <Input
        name="search"
        value={queryText || (!searchFocused && fullAddress)}
        onBlur={() => setSearchFocused(false)}
        onFocus={() => setSearchFocused(true)}
        onChange={event => setQueryText(event.target.value)}
        label="Address"
        inputRef={searchInputEl}
        rightLabel={
          <Link
            component="button"
            onClick={() => setManualEdit(true)}
            noDecoration
          >
            <Typography variant="body2">
              <Box color="common.success500">Can’t find your address?</Box>
            </Typography>
          </Link>
        }
        placeholder=""
        style={{ display: manualEdit ? 'none' : 'block' }}
        {...otherProps}
      />
      {manualEdit &&
        <>
          <Input
            name="address"
            onChange={onChange}
            label="Street"
            errorMessage={errorMessages.address}
            value={values.address}
            rightLabel={
              window.google ?
                <Link
                  component="button"
                  onClick={() => setManualEdit(false)}
                  noDecoration
                >
                  <Typography variant="body2">
                    <Box color="common.success500">Search for address</Box>
                  </Typography>
                </Link> :
                null
            }
          />
          <Box mb={3} />
          <Input
            name="city"
            onChange={onChange}
            label="City"
            errorMessage={errorMessages.city}
            value={values.city}
          />
          <Box mb={3} />
          <Select
            name="state"
            onChange={onChange}
            label="State"
            options={options.states}
            errorMessage={errorMessages.state}
            value={values.state}
          />
          <Box mb={3} />
          <Input
            name="zipCode"
            onChange={onChange}
            label="Zip code"
            errorMessage={errorMessages.zipCode}
            value={values.zipCode}
          />
        </>
      }
    </>
  );
};

AddressInput.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  onChangeValues: PropTypes.func.isRequired,
  errorMessages: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  hasErrors: PropTypes.bool.isRequired,
  fullAddress: PropTypes.string.isRequired,
};

const fields = ['address', 'city', 'state', 'zipCode'];

export default compose(
  defaultProps({ errorMessages: {} }),
  withProps(({ errorMessages, values }) => ({
    hasErrors: Object.keys(pick(errorMessages, fields)).length > 0,
    fullAddress: Object.values(pick(values, fields)).filter(Boolean).length === fields.length ?
      `${values.address}, ${values.city}, ${values.state} ${values.zipCode}` :
      '',
  })),
)(AddressInput);
