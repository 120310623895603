import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHover } from 'react-use';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useField } from 'formik';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&:focus': {
      outline: 'none',
    },
  },
  textButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: 4,
    color: theme.palette.common.primary500,
    '&:hover': {
      backgroundColor: theme.palette.common.primaryTransparent100,
      cursor: 'pointer',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 24px',
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.common.basic100,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
    },
  },
  files: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: 5,
    minHeight: 100,
    width: '100%',
  },
}));

const useFilePreviewStyles = makeStyles(theme => ({
  attachmentBox: {
    borderRadius: 4,
    border: `1px ${theme.palette.common.basic500} solid`,
    height: 84,
    minWidth: 84,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 16,
    '&:hover': {
      boxShadow: '0px 3px 8px rgba(38, 53, 71, 0.2)',
    },
  },
  icon: {
    position: 'absolute',
    top: -2,
    right: 0,
    marginRight: -32,
    marginTop: -4,
    zIndex: 4,
  },
  uploadedFile: {
    width: 72,
    height: 84,
    maxHeight: '100%',
    opacity: 0.8,
    objectFit: 'scale-down',
  },
  fileName: {
    position: 'relative',
    width: '90%',
  },
}));

type PreviewVariant = 'button' | 'text';

type Props = {
  name: string;
  variant?: PreviewVariant;
  onFileAttached?: () => void;
}

const isImageFile = (fileName: string): boolean => {
  const imgExtensions = ['jpeg', 'jpg', 'png'];
  const nameSplit = fileName.split('.');

  return imgExtensions.includes(nameSplit[nameSplit.length - 1]);
};

type FilePreviewProps = {
  file: File;
  filePreview: string;
  onRemove: () => void;
}

export const FilePreview = ({
  file,
  filePreview,
  onRemove,
}: FilePreviewProps): JSX.Element => {
  const classes = useFilePreviewStyles();
  const theme = useTheme();
  const [hoverable] = useHover(hovered => (
    <Box key={file.name} className={classes.attachmentBox}>
      <Box position="relative">
        <Tooltip title="DELETE" open={hovered}>
          <IconButton
            size="small"
            className={classes.icon}
            onClick={(event) => {
              onRemove();
              event.stopPropagation();
            }}
          >
            {hovered ?
              <CloseIcon style={{ color: theme.palette.common.basic1100 }} /> :
              <CheckIcon style={{ color: theme.palette.common.success500 }} />
            }
          </IconButton>
        </Tooltip>
      </Box>
      {isImageFile(file.name) ?
        <img
          src={filePreview}
          className={classes.uploadedFile}
          alt={`Preview for ${file.name}`}
        /> :
        <Box mt={-1}>
          <InsertDriveFileIcon fontSize="large" />
        </Box>
      }
      {!isImageFile(file.name) &&
        <Box className={classes.fileName}>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          >
            {file.name}
          </Typography>
        </Box>
      }
    </Box>
  ));

  return hoverable;
};

const FileAttachmentPreviewList = ({
  name,
  variant = 'button',
  onFileAttached = () => {},
}: Props): JSX.Element => {
  const classes = useStyles();
  const [{ value: files }, , { setValue }] = useField<File[]>(name);

  const filePreviews = useMemo(() => files.map(file => URL.createObjectURL(file)), [files]);

  const removeFileAt = useCallback((index: number) => {
    const filesCopy = [...files];
    filesCopy.splice(index, 1);
    setValue(filesCopy);
  }, [files, setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 5000000,
    accept: [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/pdf',
      'application/msword',
    ],
    onDrop: (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        if (files.find(existingFile => existingFile.name === file.name)) {
          return;
        }

        setValue([...files, file]);
      });
      onFileAttached();
    },
  });

  const rootProps = getRootProps({ style: { border: 'none' } });
  const { css, onClick, ...boxProps } = rootProps;

  const onAttachFile = useCallback((e) => {
    if (onClick) {
      onClick(e);
    }
  }, [onClick]);

  return (
    <Box
      {...boxProps}
      display="flex"
      flexDirection="column"
      className={classes.container}
    >
      {variant === 'button' &&
        <>
          <Box
            // Using our button component does not work.
            // Seems like it may be some ref conflict
            // with material-ui, so just using a box/div for now.
            className={classes.button}
            onClick={onAttachFile}
          >
            <AttachFileIcon />
            <Box m={0.5} />
            <Typography variant="subtitle2">
              Attach file
            </Typography>
          </Box>
          <input {...getInputProps()} />
        </>
      }
      {variant === 'text' &&
        <Box display="flex" alignItems="center" mt={1}>
          <Box className={classes.textButton} onClick={onAttachFile}>
            <Box display="flex" alignItems="center">
              <Box
                mr={2}
                display="flex"
                alignItems="center"
                fontSize={20}
              >
                <AddCircleIcon fontSize="inherit" />
                <input {...getInputProps()} />
              </Box>
              <Typography variant="subtitle1">
                Add attachments
              </Typography>
            </Box>
          </Box>
          <Box ml={1} color="common.basic900" alignItems="center">
            <Typography variant="subtitle1">
              Optional
            </Typography>
          </Box>
        </Box>
      }
      {files.length > 0 &&
        <Box
          minHeight={100}
          bgcolor={isDragActive ? 'rgba(0, 0, 0, 0.2)' : 'transparent'}
          borderRadius={1}
          className={classes.files}
          style={{ outlineWidth: 0, opacity: (isDragActive ? 0.5 : 1) }}
        >
          {files.map((file, index) => (
            <FilePreview
              file={file}
              filePreview={filePreviews[index]}
              onRemove={() => removeFileAt(index)}
            />
          ))}
        </Box>
      }
    </Box>
  );
};

export default FileAttachmentPreviewList;
